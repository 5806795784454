import { useEffect, useState } from "react";
import axios from "axios";
export default function UpdateCustomizedExercise(props) {
  var userid = props.userid;
  const [close, setClose] = useState(false);
  const [isclose, setisclose] = useState(true);
  const [allValues, setallValues] = useState("");
  function closeTabBox() {
    setisclose(false);
  }
  function changeHandler(e) {
    setallValues(e.target.value);
  }
  const submitValue = (e) => {
    e.preventDefault();
    const symptom = allValues;

    const { name } = e.target.dataset;
    const Data = {
      url: allValues,
      customer: userid.id,
      module: "exercise",
      //   // stage: "stage9",
      //   notes: notes,
    };
    console.log(allValues);
    //setAllValues({ ...all
    const headers = {
      Authorization: `token ` + localStorage.getItem("doc_token"),
    };
    axios
      .post("consultant/add-customized-plan/", Data, {
        headers: headers,
      })
      .then((response) => {
        alert(JSON.stringify(response.data));
        // alert(response);
      })
      .catch((error) => {
        alert("Error submitting url try again");
      });
  };
  return (
    <>
      {isclose ? (
        <div style={styles.wrapper} className="updatepop">
          <i
            onClick={closeTabBox}
            className="fa fa-close"
            style={styles.closebtn}
          ></i>
          <form onSubmit={submitValue}>
            <h5 style={{ margin: "0px 2px" }}>Update Customised Exercise</h5>
            <div style={styles.flexer}>
              <input
                style={styles.loader}
                onChange={changeHandler}
                type="text"
              />
              <br />
              <button type="submit" style={styles.ubtn}>
                Upload
              </button>
            </div>
          </form>
        </div>
      ) : null}
    </>
  );
}

const styles = {
  closebtn: { position: "absolute", top: "0px", right: "3px" },
  wrapper: {
    background: "#862D8B",
    padding: "1rem",
    zIndex: 2,
    color: "white",
    position: "fixed",
    transform: "translate(18rem, 2.5rem)",
    width: "30rem",
  },

  flexer: {
    textAlign: "center",
  },

  loader: {
    border: "none",
    background: "#A465B5",
  },

  ubtn: {
    background: "#E81A7F",
    border: "1px solid #E81A7F",
    color: "#fff",
    padding: "5px 20px",
    margin: "5px 0px",
  },
};
