import React from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const Searchclients = () => {
  const [showResults, setShowResults] = React.useState(true);
  const hideShow = () => setShowResults(false);
  const [docname, setDocname] = React.useState();
  const [hunt, setHunt] = React.useState();
  //const [ result, setResult ] = React.useState();

  React.useEffect(() => {
    // request the data to search

    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("doc_token"),
      },
    };

    axios
      .get("doctor/view-my-patients/", config)
      .then((res) =>
        //console.log(res.data);
        setDocname(res.data.customers)
      )
      .catch((err) => {
        console.log(err.message);
      });

    console.log(docname);
  }, []);

  function findDoc(e) {
    e.preventDefault();
    var val = e.target.value;
    var st = docname.find((f) => f.firstname == val);
    setHunt(st);

    console.log("result from state:", st);
  }

  return (
    <div className="note-container" style={styles.card}>
      <div className="search-form" style={styles.inner}>
        <p style={{ textAlign: "left" }}>Search Clients</p>
        <form style={{ display: "flex" }}>
          {showResults ? (
            <FontAwesomeIcon style={{ marginRight: "-22px" }} icon={faSearch} />
          ) : null}

          <input
            onFocus={hideShow}
            onChange={findDoc}
            style={styles.input}
            type="Search"
          />
        </form>
        <div style={{ textAlign: "left" }}>{hunt ? hunt.firstname : ""}</div>
      </div>
    </div>
  );
};

const styles = {
  card: {
    display: "flex",
    justifyContent: "space-between",
    background: "#ffffff1c 0% 0% no-repeat padding-box",
    padding: "20px",
    borderRadius: "10px",
    margin: "10px",
  },
  inner: {
    textDecoration: "none",
    color: "#fff",
  },

  input: {
    width: "40rem",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderBottomColor: "1px solid #fff",
    background: "transparent",
    padding: "8px",
  },
};

export default Searchclients;
