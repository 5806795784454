import axios from "axios";
import React from "react";
import { useEffect, useState } from "react";

export default function ExerciseTracker(props) {
  var pikerdate = props.dataFromParent;
  const [exersise, setexersise] = useState([]);
  const [custom, setcustom] = useState([]);
  const [Calories, setCalories] = useState("");
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("token"),
      },
    };

    axios
      .get("customer/exercise-get/?date=" + pikerdate, config)
      .then((res) => {
        let filteredData = [];
        let filteredData2 = [];
        let filteredData3 = [];

        res.data.exercises.forEach((e) => {
          if (e.completed === true) filteredData.push(e);
        });

        res.data.custom.forEach((e) => {
          if (e.completed === true) filteredData2.push(e);
        });

        setCalories(res.data.calorieBurnt);
        setexersise(filteredData);
        setcustom(filteredData2);

        //console.log(res.data);
      });
  }, []);

  return (
    <table
      style={{
        width: "100%",
        borderCollapse: "collapse",
        background: "#ffffff1c 0% 0% no-repeat padding-box",
      }}
    >
      <thead style={{ borderBottom: "5px solid #5c0048" }}>
        <tr style={{ background: "#ffffff1c 0% 0% no-repeat padding-box" }}>
          <th style={styles.th}>Date</th>
          <th style={styles.th}>Exercises</th>
        </tr>
      </thead>

      <tbody>
        <tr style={{}}>
          <td style={styles.tdFirst}>{pikerdate}</td>
          <td style={styles.td}>
            {exersise.map((personData, index) => {
              return <div key={index}>{personData.name}</div>;
            })}
            {custom.map((personData, index) => {
              return <div key={index}>{personData.name}</div>;
            })}
          </td>
        </tr>
        <tr style={{}}>
          <td style={styles.tdFirst}> Calories Burned</td>
          <td style={styles.td}>{Calories}</td>
        </tr>
      </tbody>
    </table>
  );
}

const styles = {
  th: {
    fontSize: "17px",
    whiteSpace: "nowrap",
    padding: "5px 10px",
    margin: "10px 0px",
  },

  td: {
    borderLeft: "1px solid #fff",
    padding: "5px 0px",
    fontSize: "12px",
  },

  tdFirst: {
    padding: "5px 10px",
    fontSize: "12px",
  },
};
