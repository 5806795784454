import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import Sidebar from "../components/sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faUser, faBars } from "@fortawesome/free-solid-svg-icons";
import AToggler from "../toggle";
import ATogProfile from "../tog_profile";
import TodayAppointmentTable from "../components/appointment_today";

import ApprovalRequest from "../components/approval_requests";
import TodayAppointment from "../components/today_appointments";
import CurrentStatus from "../components/current_status";
import MonthlyPatients from "../components/monthly_patients";
import AllTimePatients from "../components/patients_all_time";

export default class AppointmentOverview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isToggleOn: true,
      onProfile: true,
      items: [],
      sidebar: true,
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleProfile = this.handleProfile.bind(this);
  }

  handleClick() {
    this.setState((prevState) => ({
      isToggleOn: !prevState.isToggleOn,
    }));
  }

  handleProfile() {
    this.setState((prevState) => ({
      onProfile: !prevState.onProfile,
    }));
  }
  componentDidMount() {
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("doc_token"),
      },
    };

    axios.get("/doctor/doctor-profile/", config).then((res) => {
      console.log(res.data);
      this.setState({ items: res.data });
    });
  }
  render() {
    function openMenu() {
      document.querySelector(".sidebar").classList.toggle("show");
    }

    return (
      <div className="App">
        <Sidebar siderbarcolor={this.state.sidebar} />
        <header className="overview">
          <div
            className="top-header grandiant"
            ref={this.setWrap}
            style={{ padding: "2% 10%", textAlign: "right" }}
          >
            {" "}
            <span onClick={openMenu} className="menu">
              <FontAwesomeIcon
                style={{ marginRight: "20px" }}
                icon={faBars}
                className="menu-icon"
              />
            </span>
            {/* <p style={{ paddingRight: "10%", textAlign: "right" }}> */}{" "}
            <div>{this.state.isToggleOn ? null : <AToggler />}</div>
            <span className="iconsofgradiant">
              <FontAwesomeIcon
                className="iconsofprofile"
                onClick={this.handleClick}
                style={{ marginRight: "1rem", cursor: "pointer" }}
                icon={faBell}
              />
            </span>
            <span className="iconsofgradiant">
              <FontAwesomeIcon
                className="iconsofprofile"
                style={{ cursor: "pointer" }}
                icon={faUser}
                onClick={this.handleProfile}
              />
            </span>{" "}
            {this.state.items.firstname} {this.props.childern}
            <div>{this.state.onProfile ? null : <ATogProfile />}</div>
          </div>

          <div
            style={{ display: "flex", paddingLeft: "10px" }}
            className=" toperpc"
          >
            <span style={{ whiteSpace: "nowrap" }}>Todays Appointments</span>
            <div style={{ padding: "4px", width: "100%" }}>
              <hr
                style={{ background: "white", color: "white" }}
                className="hr-80"
              />
            </div>
          </div>
          <div className="mwrapper">
            <div className="mleft">
              <TodayAppointmentTable />

              <div className="counter-collect" style={styles.counterCollection}>
                <MonthlyPatients />
                <AllTimePatients />
              </div>
              {/* <div className="flexer" style={styles.counterCollection}>
                <MonthlyPatients />
                <AllTimePatients />
              </div> */}
            </div>
            <div className="mright">
              <ApprovalRequest />
              <TodayAppointment />
              <CurrentStatus />
            </div>
          </div>
        </header>
      </div>
    );
  }
}

AppointmentOverview.propTypes = {
  children: PropTypes.element.isRequired,
};

const styles = {
  counterCollection: {
    display: "flex",
    flexDirection: "space-between",
  },
};
