import { Checkbox } from "@material-ui/core";
import { Link } from "@react-navigation/web";
import axios from "axios";
import { useEffect, useState } from "react";
import { Accordion, Card, Button } from "react-bootstrap";

export default function List_brain(props) {
   
    const [hearing, sethearing] = useState([])
    const [vision, setvision] = useState([])
    const [touch, settouch] = useState([])
    const [smell, setsmell] = useState([])
    const [taste, settaste] = useState([])
    let id = props.id;

    useEffect(() => {
        const headers = {
            Authorization: `token ` + localStorage.getItem("postclient"),
        };
        let url = 'http://127.0.0.1:8000/customer/get-brain-stimulation?month=' + id;

        axios.get(url, {
            headers: headers,
        }).then((val) => {
         

            const hearingItems = val.data.data.filter(item => item.sense && item.sense.toString() === "hearing");
            sethearing(hearingItems);
            const visionItems = val.data.data.filter(item => item.sense && item.sense.toString() === "vision");
            setvision(visionItems);
            const smellItem = val.data.data.filter(item => item.sense && item.sense.toString() === "smell");
            setsmell(smellItem);
            const tasteItem = val.data.data.filter(item => item.sense && item.sense.toString() === "taste");
            settaste(tasteItem);
            const touchItem = val.data.data.filter(item => item.sense && item.sense.toString() === "touch");
            settouch(touchItem);
        });
    }, [id]);
    const handlechange = (itemid) => {
        const headers = {
            Authorization: `token ` + localStorage.getItem("postclient"),
        };
        const bool = !itemid.ans;
        const data = {
            id: parseInt(itemid.id),
            ans: bool,
        };
        let url = 'http://127.0.0.1:8000/customer/patch-babybrain';
    
        axios.patch(url, data, { headers: headers })
            .then(() => {
                // Update the state after successful patch request
                const updatedHearing = hearing.map(item => {
                    if (item.id === itemid.id) {
                        return { ...item, ans: bool };
                    }
                    return item;
                });
                sethearing(updatedHearing);
                const updatedvision = vision.map(item => {
                    if (item.id === itemid.id) {
                        return { ...item, ans: bool };
                    }
                    return item;
                });
                setvision(updatedvision);
                const updatedtouch = touch.map(item => {
                    if (item.id === itemid.id) {
                        return { ...item, ans: bool };
                    }
                    return item;
                });
                settouch(updatedtouch);
                const uptaste = taste.map(item => {
                    if (item.id === itemid.id) {
                        return { ...item, ans: bool };
                    }
                    return item;
                });
                settaste(uptaste);
                const upsmell = smell.map(item => {
                    if (item.id === itemid.id) {
                        return { ...item, ans: bool };
                    }
                    return item;
                });
                setsmell(upsmell);
                
                alert('updated');
            })
            .catch(() => {
                alert('notdone');
            });
    };
    
    return (
        <div style={styles.out}>
            <h5 style={{ marginTop: '20px' }}>
                {id}'st month

            </h5>
            {/* final update here for onclick function */}
            <div style={styles.outer}>

           vision <hr/>
            {vision.map((item, index) => (
                <div>
                      

                    <label  style={styles.question} >{item.question}</label>
                        
                    <input style={styles.checkbox} onChange={(e)=>{handlechange(item)}} type="checkbox" checked={item.ans }></input><br/>

                </div>
            ))} 
            </div>
            <div style={styles.outer}>
            hearing <hr/>
            {hearing.map((item, index) => (
                <div>

                    <label style={styles.question} >{item.question}</label>
                    <input style={styles.checkbox} onChange={(e)=>{handlechange(item)}} type="checkbox" checked={item.ans }></input><br/>

                </div>
            ))} </div>
              <div style={styles.outer}>
            touch <hr/>
            {touch.map((item, index) => (
                <div>

                    <label style={styles.question} >{item.question}</label>
                    <input style={styles.checkbox} onChange={(e)=>{handlechange(item)}} type="checkbox" checked={item.ans }></input><br/>

                </div>
            ))} </div>

            <div style={styles.outer}>
                smell <hr></hr>
            {smell.map((item, index) => (
                <div>

                    <label style={styles.question} >{item.question}</label>
                    <input style={styles.checkbox} onChange={(e)=>{handlechange(item)}} type="checkbox" checked={item.ans }></input><br/>

                </div>
            ))} </div>
              <div style={styles.outer}>
                taste <hr/>
            {taste.map((item, index) => (
                <div>

                    <label style={styles.question} >{item.question}</label>
                    <input style={styles.checkbox} onChange={(e)=>{handlechange(item)}} type="checkbox" checked={item.ans }></input><br/>

                </div>
            ))} </div>


        </div>
    );
}

const styles = {
    outer: {
        marginTop: '15px',
        padding:'9px',
        borderRadius: '20px',
        background: '#ffffff1d',
        margin: '10px'
    },
    out:{
        height:'750px',
        overflow:'auto',
    },
    question: {
        fontSize: '20px',
        


    },
    checkbox:{
        marginLeft:'60px'
    }
};
