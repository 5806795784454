import React from "react";
import PropTypes from "prop-types";
import Sidebar from "../components/sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faUser, faBars } from "@fortawesome/free-solid-svg-icons";
import Toggler from "../toggle";
// import Client from "./components/clients";
// import Doctor from "./components/doctors";
// import Search from "./components/search";
import Banner from "./nopay";
import ClientProfile from "../toggleprofile";
//import Clist from "./components/client_list";
// import Hospital from "./components/hospitals";
// import Salesteam from "./components/salesteams";
// import Consultant from "./components/consultants";
// import Dcstatus from "./components/dcstatus";
import axios from "axios";
import { Redirect } from "react-router";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

export default class Overviewadmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isToggleOn: true,
      list: [],
      count: "",
      isProfile: true,
      redirect: null,
      searchval: "",
    };
    this.handleClick = this.handleClick.bind(this);
    // this.setwRef = this.setwRef.bind(this);
    this.profileHandler = this.profileHandler.bind(this);
    this.clickedOutside = this.clickedOutside.bind(this);
  }

  handleClick() {
    this.setState((prevState) => ({
      isToggleOn: !prevState.isToggleOn,
    }));
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.clickedOutside);
    const verify = sessionStorage.getItem("token");

    /*  if(verify == null){
     window.location = '/admin/login';
  }*/

    const token = sessionStorage.getItem("token");

    if (!token) {
      this.setState({ redirect: "/admin/login" });
    }

    axios
      .get("admin-panel/admin-dashboard-details/", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Token ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        this.setState({ count: res.data.counts });
        this.setState({ list: res.data.clientDetails });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.clickedOutside);
  }

  // setwRef(node) {
  //   this.wapRef = node;
  // }
  profileHandler() {
    this.setState({ isProfile: !this.state.isProfile });
  }
  clickedOutside(e) {
    if (this.wapRef && !this.wapRef.contains(e.target)) {
      return this.setState({ isToggleOn: true });
    }
  }

  render() {
    function openMenu() {
      document.querySelector(".sidebar").classList.toggle("show");
    }

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <>
        <div className="App">
          <Sidebar />
          <header className="overview ">
            <p style={{ paddingRight: "10%", textAlign: "right" }}>
              {" "}
              <div>{this.state.isToggleOn ? null : <Toggler />}</div>
              <span ref={this.setwRef}>
                <FontAwesomeIcon
                  onClick={this.handleClick}
                  style={{ marginRight: "1rem", cursor: "pointer" }}
                  icon={faBell}
                />
                {this.props.children}
              </span>
              <FontAwesomeIcon
                onClick={this.profileHandler}
                style={{ cursor: "pointer" }}
                icon={faUser}
              />{" "}
              Admin
            </p>
            <div style={{ width: "90%", display: "flex", paddingLeft: "10px" }}>
              <span onClick={openMenu} className="menu">
                <FontAwesomeIcon
                  style={{ marginRight: "20px" }}
                  icon={faBars}
                  className="menu-icon"
                />
              </span>{" "}
              {this.state.isProfile ? null : <ClientProfile />}{" "}
              <div style={{ padding: "4px", width: "100%" }}>
                <hr style={{ background: "white", color: "white" }} />
              </div>
            </div>
            <div className="mwrapper">
              <div className="mleft " style={{ width: "90%" }}>
                <div
                  className="over-cover"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {/* <Client totalClients={this.state.count.totalClients} /> */}

                  {/* <Doctor totalDoctors={this.state.count.totalDoctors} /> */}
                </div>
                <Banner />
                {/* <Clist
                  clientDetails={this.state.list}
                  searchval={this.state.searchval}
                /> */}
              </div>
              <div className="mright">
                {/* <Hospital totalHospitals={this.state.count.totalHospitals} />
                <Salesteam totalSalesTeam={this.state.count.totalSalesTeam} />
                <Consultant
                  totalConsultant={this.state.count.totalConsultant}
                />
                <Dcstatus
                  activeClients={this.state.count.activeClients}
                  disabledDoctors={this.state.count.disabledDoctors}
                /> */}
              </div>
            </div>
          </header>
        </div>
      </>
    );
  }
}

Overviewadmin.propTypes = {
  clildren: PropTypes.element.isRequired,
};

const styles = {
  card: {
    display: "flex",
    justifyContent: "space-between",
    background: "#ffffff1c 0% 0% no-repeat padding-box",
    padding: "20px",
    borderRadius: "10px",
    margin: "10px",
  },
  inner: {
    textDecoration: "none",
    color: "#fff",
  },

  input: {
    width: "40rem",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderBottomColor: "1px solid #fff",
    background: "transparent",
    padding: "8px",
    marginLeft: "30px",
    outline: "none",
    color: "white",
  },
};
