import React from "react";
import { getAllMedicalDetails } from "../../../../helpers/service";

export default function MedicalDetail({ customer }) {
  const [data, setData] = React.useState({});

  const fetchApi = () => {
    const request = getAllMedicalDetails();

    request
      .api({
        params: {
          customer,
        },
      })
      .then((response) => {
        if (response) {
          setData(response);
        }
      });

    return request;
  };

  const Row = (item, index) => {
    return (
      <tr
        style={{
          background:
            "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
        }}
        key={item.id || index}
      >
        <td style={styles.tdFirst}>
          {new Date(item.date).toLocaleDateString()}
          {/* {item.date} */}
        </td>
        <td style={styles.td}>{item.hb_value}</td>
        <td style={styles.td}>{item.hb_normal}</td>
        <td style={styles.td}>{item.ict_value}</td>
        <td style={styles.td}>{item.ict_normal}</td>
        <td style={styles.td}>{item.urineRE_value}</td>
        <td style={styles.td}>{item.urineRE_normal}</td>
        <td style={styles.td}>{item.urineCS_value}</td>
        <td style={styles.td}>{item.urineCS_normal}</td>
        <td style={styles.td}>{item.vdrl_value}</td>
        <td style={styles.td}>{item.vdrl_normal}</td>
        <td style={styles.td}>{item.hiv_value}</td>
        <td style={styles.td}>{item.hiv_normal}</td>
        <td style={styles.td}>{item.rbs_first_trimester_value}</td>
        <td style={styles.td}>{item.rbs_first_trimester_normal}</td>
        <td style={styles.td}>{item.ogct_second_trimester_value}</td>
        <td style={styles.td}>{item.ogct_second_trimester_normal}</td>
        <td style={styles.td}>{item.ogtt_second_trimester_value}</td>
        <td style={styles.td}>{item.ogtt_second_trimester_normal}</td>
        <td style={styles.td}>{item.hcv_value}</td>
        <td style={styles.td}>{item.hcv_normal}</td>
        <td style={styles.td}>{item.creatine_value}</td>
        <td style={styles.td}>{item.creatine_normal}</td>
        <td style={styles.td}>{item.double_marker_value}</td>
        <td style={styles.td}>{item.double_marker_normal}</td>
        <td style={styles.td}>{item.tft_value}</td>
        <td style={styles.td}>{item.tft_normal}</td>
        <td style={styles.td}>{item.tft_description}</td>
        <td style={styles.td}>{item.others_value}</td>
        <td style={styles.td}>{item.others_normal}</td>
        <td style={styles.td}>{item.others_description}</td>
        <td style={styles.td}>{item.scan}</td>
      </tr>
    );
  };

  React.useEffect(() => {
    if (!!customer) {
      const request = fetchApi();

      return () => {
        request.cancel();
      };
    }
  }, [customer]);

  return (
    <div className="scrollerbar">
      <table
        style={{
          width: "100%",
          borderSpacing: "0px 1em",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr
            style={{
              background:
                "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
            }}
            className="font-blue"
          >
            <th style={styles.th}>Date</th>
            <th style={styles.th}>Hb</th>
            <th style={styles.th}>Hb Normal</th>
            <th style={styles.th}>ICT</th>
            <th style={styles.th}>ICT Normal</th>
            <th style={styles.th}>Urine R/E</th>
            <th style={styles.th}>Urine R/E Normal</th>
            <th style={styles.th}>Urine C/S</th>
            <th style={styles.th}>Urine C/S Normal</th>
            <th style={styles.th}>vdrl</th>
            <th style={styles.th}>vdrl Normal</th>
            <th style={styles.th}>HIV</th>
            <th style={styles.th}>HIV Normal</th>
            <th style={styles.th}>RBS first trimester</th>
            <th style={styles.th}>RBS first trimester Normal</th>
            <th style={styles.th}>OGCT 2nd trimester - if raised</th>
            <th style={styles.th}>OGCT 2nd trimester - if raised Normal</th>
            <th style={styles.th}>OGTT+</th>
            <th style={styles.th}>OGTT+ Normal</th>
            <th style={styles.th}>HCV</th>
            <th style={styles.th}>HCV Normal</th>
            <th style={styles.th}>Creatine</th>
            <th style={styles.th}>Creatine Normal</th>
            <th style={styles.th}>Double Marker</th>
            <th style={styles.th}>Double Marker Normal</th>
            <th style={styles.th}>TFT </th>
            <th style={styles.th}>TFT Normal</th>
            <th style={styles.th}>TFT Description</th>
            <th style={styles.th}>Others </th>
            <th style={styles.th}>Others Normal</th>
            <th style={styles.th}>Others Description</th>
            <th style={styles.th}>Scan</th>
          </tr>
        </thead>

        <tbody>
          {data.investigation_data && data.investigation_data.map(Row)}
        </tbody>
      </table>
    </div>
  );
}

const styles = {
  th: {
    fontSize: "17px",
    whiteSpace: "nowrap",
    padding: "5px 10px",
    margin: "10px 0px",
  },

  td: {
    border: "1px solid black",
    padding: "5px 10px",
    fontSize: "12px",
    textAlign: "left",
    background:
      "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",

    lineHeight: "20px",
  },

  tdFirst: { border: "1px solid black", padding: "5px 10px", fontSize: "12px" },
};
