import React, { useState, useEffect } from "react";
import axios from "axios";

export default function ActivityTracker(props) {
  const [predefined, setpredefined] = useState([]);
  const [custom, setcustom] = useState([]);
  var pikerdate = props.dataFromParent;
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("token"),
      },
    };

    axios
      .get("customer/get-activity-tasks/?date=" + pikerdate, config)
      .then((res) => {
        console.log(res.data);
        let filteredData = [];
        let filteredData2 = [];

        res.data.custom.forEach((e) => {
          if (e.completed === true) filteredData.push(e);
        });

        // res.data.predefined.sub_module.forEach((e) => {
        //   if (e.completed === true) filteredData2.push(e);
        // });
        // setsymtoms(filteredData);
        setcustom(filteredData);
        setpredefined(res.data.predefined);

        // setsymptomsWithIputs(res.data.symptomsWithIputs);
      });
  }, []);

  return (
    <div style={{ display: "flex" }}>
      {predefined.map((personData, index) => {
        return (
          <thead>
            <tr>
              <th style={styles.th}>{personData.mainModule}</th>
            </tr>
            {personData.sub_module
              .filter((e) => e.completed == true)
              .map((subModule) => {
                return (
                  <>
                    <tr>
                      <td style={styles.td}>{subModule.subModuleName}</td>
                    </tr>
                  </>
                );
              })}
          </thead>
        );
      })}

      <thead>
        <tr>
          <th style={styles.th}>custom</th>
        </tr>

        {custom.map((personData, index) => {
          return (
            <tr>
              <td style={styles.td}>{personData.name}</td>{" "}
            </tr>
          );
        })}
      </thead>
    </div>
    // <table style={{ width: "100%", borderCollapse: "collapse" }}>
    //   <thead style={{ borderBottom: "5px solid #5c0048" }}>
    //     <tr style={{ background: "#a23698" }}>
    //       <th style={styles.th}>Date</th>
    //       {predefined.map((personData, index) => {
    //         return <th style={styles.th}>{personData.mainModule}</th>;
    //       })}
    //     </tr>
    //   </thead>

    //   <tbody>
    //     <tr style={{ background: "#a23698" }}>
    //       <td style={styles.tdFirst}>02/08/2021</td>
    //       <td style={styles.td}>
    //         {/* {predefined.map((personData, index) => {
    //           {
    //             personData.sub_module.map((subModule) => {
    //               return <td style={styles.td}>{subModule.subModuleName}</td>;
    //             });
    //           }
    //         })} */}
    //         I am healthy <br /> I am happy <br />
    //         I give birth at comfort and at ease <br />
    //       </td>
    //       <td style={styles.td}>Meditation</td>
    //       <td style={styles.td}>
    //         Easy Stage <br /> Transition
    //       </td>
    //       <td style={styles.td}>
    //         Talking to the baby <br /> Food names
    //       </td>
    //       <td style={styles.td}>
    //         {" "}
    //         Story reading <br /> Walking{" "}
    //       </td>
    //     </tr>
    //   </tbody>
    // </table>
  );
}

const styles = {
  th: {
    fontSize: "17px",
    whiteSpace: "nowrap",
    padding: "5px 10px",
    margin: "10px 0px",
  },

  td: {
    borderLeft: "1px solid #fff",
    padding: "5px 10px",
    fontSize: "12px",
    textAlign: "left",
    lineHeight: "20px",
  },

  tdFirst: {
    padding: "5px 10px",
    fontSize: "12px",
  },
};
