import { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

export default function AlltimeClientsTable(searchval) {
  const [clientDetails, setClientDetails] = useState([]);
  const [tracker, setTracker] = useState(false);
  const [profile, setProfile] = useState(false);
  const [clientid, setClientid] = useState("");

  function openDailyTracker(id) {
    setTracker(!tracker);
    setClientid(id);
  }
  function openProfile(id) {
    setProfile(!profile);
    setClientid(id);
  }

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Token ${sessionStorage.getItem("token")}`,
      },
    };
    // Define a function to fetch data from the API
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "/admin-panel/all-customer-call-responses/",
          config
        );
        setClientDetails(response.data); // Assuming the API returns an array of client details under 'all_clients'
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  // Assuming clientDetails is an array of objects with a 'date' property

  // Sort the clientDetails array by date in ascending order
  const sortedClientDetails = clientDetails.sort((a, b) => {
    // Convert the date strings to Date objects for comparison
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);

    // Compare dates in descending order
    return dateB - dateA;

    // Compare dates in ascending order
  });

  // Now, sortedClientDetails contains the sorted array

  const updateStatus = (id) => {
    const config = {
      headers: {
        Authorization: `Token ${sessionStorage.getItem("token")}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    const requestData = { id: id };

    axios
      .post(`activateOrDeactivate/`, requestData, config)
      .then((response) => {
        alert(response.data.Success);
      })
      .catch((error) => {
        alert("An error occurred while updating the status.");
      });
  };

  return (
    <>
      <div style={styles.card}>
        <div style={styles.inner}>
          <p>
            <strong>Call List</strong>
          </p>
          <hr style={{ color: "#fff" }} />
          <div className="formobile lightgradient" style={{ height: "680px" }}>
            <table className="nospace">
              <thead>
                <tr className="gradiantblur">
                  <td>Name</td> <td>call status</td>
                  <td>date</td>
                  <td>notes</td>
                </tr>
              </thead>
              <tbody>
                {" "}
                {console.log(clientDetails)}
                {sortedClientDetails.map((client) => (
                  <tr key={client.id} className="gradiantblur">
                    <td
                      style={styles.usernav}
                      onClick={() => openProfile(client.id)}
                    >
                      <Link to={`/admin/client/${client.id}`}>
                        {client.name}
                      </Link>
                    </td>
                    <td>{client.response}</td>
                    <td>{client.date}</td>
                    <td>{client.note}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

const styles = {
  card: {
    // display: "flex",
    justifyContent: "space-between",
    // background: "#4c003d",
    padding: "20px",
    borderRadius: "10px",
    margin: "10px",
  },
  inner: {
    textDecoration: "none",
    color: "#fff",
  },

  usernav: {
    // background: "#4c005d",
    padding: "5px 10px",
    cursor: "pointer",
  },
  red: { background: "red", border: "1px solid red", padding: "8% 30%" },
  green: { background: "green", border: "1px solid green", padding: "8% 30%" },
  yellow: {
    background: "yellow",
    border: "1px solid yellow",
    padding: "8% 30%",
  },
};
