import React from "react";
// import { filterForTableByJsonKey } from "../../../../helpers/functions";
// import { getAllDietDetails } from "../../../../helpers/service";
//import DietTrackerUpdate from './update_customized_diet';

export default function DietTracker({}) {
  // const [data, setData] = React.useState({});

  // const fetchApi = () => {
  //   const request = getAllDietDetails();

  //   request
  //     .api({
  //       params: {
  //         id: customer,
  //       },
  //     })
  //     .then((response) => {
  //       if (response) {
  //         setData(filterForTableByJsonKey(response, "mealName"));
  //       }
  //     });

  //   return request;
  // };

  // const Row = ([date, item], index) => {
  //   return (
  //     <tr
  //       key={date}
  //       style={{
  //         background:
  //           "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
  //       }}
  //     >
  //       <td style={styles.tdFirst}>{date}</td>
  //       <td style={styles.td}>
  //         {item.early_morning?.food} <br /> {item.early_morning?.time}
  //       </td>
  //       <td style={styles.td}>
  //         {item.breakfast?.food} <br /> {item.breakfast?.time}
  //       </td>
  //       <td style={styles.td}>
  //         {item.mid_day_snack?.food} <br /> {item.mid_day_snack?.time}
  //       </td>
  //       <td style={styles.td}>
  //         {item.lunch?.food} <br /> {item.lunch?.time}
  //       </td>
  //       <td style={styles.td}>
  //         {item.afternoon_snack?.food} <br /> {item.afternoon_snack?.time}
  //       </td>
  //       <td style={styles.td}>
  //         {item.dinner?.food} <br /> {item.dinner?.time}
  //       </td>
  //       <td style={styles.td}>
  //         {item.dinner_drink?.food} <br /> {item.dinner_drink?.time}
  //       </td>
  //     </tr>
  //   );
  // };

  // React.useEffect(() => {
  //   if (!!customer) {
  //     const request = fetchApi();

  //     return () => {
  //       request.cancel();
  //     };
  //   }
  // }, [customer]);

  return (
    <>
      <div className="scrollerbar">
        <table style={{ borderSpacing: "0px 1em" }}>
          <thead style={{ borderBottom: "5px solid #5c0048" }}>
            <tr
              style={{ background: "#ffffff1c 0% 0% no-repeat padding-box" }}
              className="font-blue"
            >
              <th style={styles.th}>Date</th>
              <th style={styles.th}>Early Morning Energy</th>
              <th style={styles.th}>Breakfast</th>
              <th style={styles.th}>Mid-Day Snack</th>
              <th style={styles.th}>Lunch</th>
              <th style={styles.th}>Afternoon Snack</th>
              <th style={styles.th}>Dinner</th>
              <th style={styles.th}>Dinner Drink</th>
            </tr>
          </thead>

          {/* <tbody>{Object.entries(data).map(Row)}</tbody> */}
        </table>
      </div>
    </>
  );
}

const styles = {
  th: {
    fontSize: "17px",
    whiteSpace: "nowrap",
    padding: "5px 10px",
    margin: "10px 0px",
  },

  td: {
    borderLeft: "1px solid #fff",
    // padding: "5px 0px",
    fontSize: "12px",
    background:
      "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",

    padding: "5px 10px",
    textAlign: "left",
  },

  tdFirst: {
    padding: "5px 10px",
    fontSize: "12px",
  },

  sbtn: {
    border: "none",
    margin: "5px 0px",
    color: "#fff",
    background: "#E81A7F",
    padding: "8px",
    fontWeight: "bold",
    cursor: "pointer",
  },
};
