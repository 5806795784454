import React from "react";
import Image from "../img/small_image.jpeg";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
export default function DoctorProfile() {
  const [Items, setItems] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    const token = sessionStorage.getItem("hospitalManager");
    if (!token) {
      window.location = "/login";
    }

    axios
      .get(`/doctor/doctor-profile/`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Token ${sessionStorage.getItem("hospitalManager")}`,
        },
        params: {
          doctor: id,
        },
      })
      .then((res) => {
        setItems(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // export default class DoctorProfile extends React.Component {
  //   constructor(props) {
  //     super(props);
  //     const { id } = useParams();

  //     this.state = { isProfile: true };
  //     this.clickHandler = this.clickHandler.bind(this);
  //   }

  //   clickHandler() {
  //     this.setState({ isProfile: false });
  //   }

  return (
    <>
      {console.table(Items)}
      {/* {this.state.isProfile ? ( */}
      <div style={styles.dprofile} className=" lightgradient">
        <div style={styles.titlePlate}>
          <strong>Doctor Profile</strong>
        </div>

        <table>
          <tr>
            <td>
              <img
                src={Items.profile_full_url}
                height="75px"
                width="75px"
                style={{ borderRadius: "50%" }}
                alt=""
              />
              <br />
              <span style={styles.money}> &#8377; {Items.price}</span>
            </td>
            <td style={styles.col}>
              <strong style={{ fontSize: "20px" }}>
                Dr. {Items.firstname} {Items.lastname}
              </strong>
              <br />
              <div style={{ display: "flex", flexDirection: "column" }}>
                <small
                  style={{
                    padding: "3px 0px",
                    borderBottom: "1px solid #fff",
                  }}
                >
                  Email: <span>{Items.name}</span>
                </small>
                {/* <small
                  style={{
                    padding: "3px 0px",
                    borderBottom: "1px solid #fff",
                  }}
                >
                  Password : 123456{" "}
                </small> */}
                <br />
              </div>
            </td>

            <td style={styles.col}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <small
                  style={{
                    marginTop: "5px",
                    padding: "3px 0px",
                    borderBottom: "1px solid #fff",
                  }}
                >
                  Contact Number : <span>{Items.mobile}</span>
                </small>
                <small
                  style={{
                    padding: "3px 0px",
                    borderBottom: "1px solid #fff",
                  }}
                >
                  Years Of Experience : <span>{Items.experience} yr</span>
                </small>
              </div>
            </td>
          </tr>
          <tr>
            <td></td>
            <td style={styles.co}>Fullname</td>
            <td>
              <input type="text" value={Items.firstname} />
            </td>
          </tr>
          <tr>
            <td></td>
            <td style={styles.co}>Speciality</td>
            <td>
              <input type="text" value={Items.speciality} />
            </td>
          </tr>
          <tr>
            <td></td>
            <td style={styles.co}>Qualification</td>
            <td>
              <input type="text" value={Items.qualification} />
            </td>
          </tr>
          <tr>
            <td></td>
            <td style={styles.co}>Medical council required?</td>
            <td>
              <input type="text" value={Items.medicalCouncil} />
            </td>
          </tr>
          <tr>
            <td></td>
            <td style={styles.co}>Area of interest</td>
            <td>
              <input type="text" value={Items.interests} />
            </td>
          </tr>
          <tr>
            <td></td>
            <td style={styles.co}>Refrence Code</td>
            <td>
              <input type="text" value={Items.referalId} />
            </td>
          </tr>
          <tr>
            <td></td>
            <td style={styles.co}>Hospital/Hospital working</td>
            <td>
              <input type="Apple" value={Items.hospitals} />
            </td>
          </tr>
          <tr>
            <td></td>
            <td style={styles.co}>Place of Work</td>
            <td>
              <input type="text" value={Items.placeOfWork} />
            </td>
          </tr>
          <tr>
            <td></td>
            <td style={styles.co}>
              Are you willing to give Online consultation for your Patient?
            </td>
            <td>
              <input type="text" value={Items.onlineConsultation} />
            </td>
          </tr>
          <tr>
            <td></td>
            <td style={styles.col}></td>
            <td></td>
          </tr>
        </table>
      </div>
      {/* ) : null} */}
    </>
  );
}

const styles = {
  dprofile: {
    // background: "rgb(76, 0, 61) none repeat scroll 0% 0%",
    width: "41.3rem",
    borderRadius: "10px",
    padding: "10px",
    margin: "auto",
  },

  titlePlate: {
    padding: "10px",
    color: "#fff",
  },
  flex: {
    display: "flex",
    justifyContent: "space-around",
    padding: "10px",
  },
  col: {
    padding: "10px",
    textAlign: "left",
  },
  co: {
    padding: "10px",
    textAlign: "left",
    // border: "1px solid #85388E",
    // background: "#85388E",
  },
  money: {
    color: "#fff",
  },

  btn: {
    padding: "3px",
    background: "#85388E",
    border: "none",
  },

  fee: {
    padding: "5px",
    background: "transparent",
    outline: "1px solid #85388E",
  },

  ftext: {
    fontSize: "12px",
    padding: "5px",
    background: "#85388E",
    marginRight: "2rem",
  },
};
