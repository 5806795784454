import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Datedata from "./datedata";
import Moment from "moment";
export default function DietTracker(props) {
  const [breakfast, setbreakfast] = useState({});
  const [earlymoring, setearlymoring] = useState({});
  const [midday, setmidday] = useState({});
  const [afternoon, setafternoon] = useState({});
  const [dinner, setDinner] = useState({});
  const [allValues, setAllValues] = useState({});
  const [lunch, setlunch] = useState({});
  const [dinnerdrink, setdinnerdrink] = useState({});
  var pikerdate = props.dataFromParent;

  console.log(pikerdate);
  const changeHandler = (e) => {
    setAllValues({ ...allValues, [e.target.name]: e.target.value });
  };

  // const handleSubmit = (event) => {};

  const submitValue = (pikerdate) => (e) => {
    e.preventDefault();

    //setAllValues({ ...allValues, [e.target.name]: e.target.value });

    //new.................................................................
    // const formData = new FormData(e.currentTarget);

    // var object = {};
    // formData.forEach(function (value, key) {
    //   object[key] = value;
    // });
    // var json = JSON.stringify(object);
    // //console.log(json);
    // formData.append("data", json);
    // //new.................................................................
    //JSON = JSON.stringify(json);
    // var changeddata = [];
    // for (var data of formData.entries()) {
    //   //  console.log(data);

    //   //formData.append(key, value);
    //   changeddata.push(data);
    // }

    // //  consolethis.state.items.video;
    // var myJsonString = JSON.stringify(changeddata);

    // console.log(myJsonString);

    // const Data = {
    //   module: "DELIVERY PROCESS",
    //   // stage: "stage9",
    //   notes: notes,
    // }

    // const headers = {
    //   "Content-Type": "application/x-www-form-urlencoded",

    //   Authorization: `token ` + localStorage.getItem("token"),

    //   //Authorization: "JWT fefege...",
    // };

    // axios
    //   .post(
    //     "https://shebirth.herokuapp.com/customer/diet-tracker-add-POST/",
    //     formData,
    //     {
    //       headers: headers,
    //     }
    //   )
    //   .then(() => {
    //     // setincrement([...increment,res.data])
    //     // console.log(json);
    //     alert("  success submited");
    //     //window.location.reload();
    //   })
    //   .catch((error) => {
    //     alert("  already submited");
    //   });

    // // event.preventDefault();
    // // alert("notes was submitted");
    // // event.preventDefault();
  };
  var date = Moment().format("YYYY-MM-DD");

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("token"),
      },
    };
    //remove this date after setting up the admin pannel

    axios
      .get(
        "customer/diet-tracker-GET/?date=" + pikerdate,
        config

        // config
      )
      .then((res) => {
        let obj = res.data.Diet.find((o) => o.mealName === "lunch");
        let breakfast = res.data.Diet.find((o) => o.mealName === "breakfast");
        let earlymorning1 = res.data.Diet.find(
          (o) => o.mealName === "early morning"
        );
        let dinner = res.data.Diet.find((o) => o.mealName === "dinner");
        let afternoonsnack = res.data.Diet.find(
          (o) => o.mealName === "afternoon snack"
        );
        let middaysnack = res.data.Diet.find(
          (o) => o.mealName === "mid day snack"
        );
        let dinnerdrink = res.data.Diet.find(
          (o) => o.mealName === "dinner drink"
        );

        // if (earlymorning=)
        // {

        // }
        // console.log(obj);
        //setdata(obj);
        // setbreakType(res.data.Diet[5].food);
        // setbreakfast(res.data.Diet.food);
        // setbreakfastTime(res.data.Diet.time);

        // setdetails(res.data.details);
        setearlymoring(earlymorning1);
        setDinner(dinner);
        setafternoon(afternoonsnack);
        setmidday(middaysnack);
        setdinnerdrink(dinnerdrink);
        //  setlunch(res.data.Diet[6].food);
        setbreakfast(breakfast);
        setlunch(obj);
        // setlunchtime(res.data.Diet[6].time);
      });
  }, []);

  return (
    <>
      <form>
        <table style={{ borderCollapse: "collapse" }}>
          <thead style={{ borderBottom: "5px solid #5c0048" }}>
            <tr style={{ background: "#a23698" }}>
              <th style={styles.th}>Date</th>
              <th style={styles.th}>Early Morning Energy</th>
              <th style={styles.th}>Breakfast</th>
              <th style={styles.th}>Mid-Day Snack</th>
              <th style={styles.th}>Lunch</th>
              <th style={styles.th}>Afternoon Snack</th>
              <th style={styles.th}>Dinner</th>
              <th style={styles.th}>Dinner Drink</th>
            </tr>
          </thead>

          <tbody>
            <tr style={{ background: "#a23698" }}>
              <td style={styles.tdFirst}>{pikerdate}</td>
              <td style={styles.td}>
                <input
                  name="mealType"
                  onChange={changeHandler}
                  style={styles.inputstyle}
                  defaultValue={earlymoring && earlymoring.food}
                  placeholder={earlymoring && earlymoring.food}
                />
                <br />{" "}
                <input
                  name="mealType"
                  onChange={changeHandler}
                  style={styles.inputstyle}
                  defaultValue={earlymoring && earlymoring.time}
                  placeholder={earlymoring && earlymoring.time}
                />
              </td>
              <td style={styles.td}>
                <input
                  name="mealType"
                  onChange={changeHandler}
                  style={styles.inputstyle}
                  defaultValue={breakfast && breakfast.food}
                  placeholder={breakfast && breakfast.food}
                />
                <br />{" "}
                <input
                  name="mealType"
                  onChange={changeHandler}
                  style={styles.inputstyle}
                  defaultValue={breakfast && breakfast.time}
                  placeholder={breakfast && breakfast.time}
                />
              </td>
              <td style={styles.td}>
                <input
                  name="mealType"
                  onChange={changeHandler}
                  style={styles.inputstyle}
                  defaultValue={midday && midday.food}
                  placeholder={midday && midday.food}
                />{" "}
                <br />{" "}
                <input
                  name="mealType"
                  onChange={changeHandler}
                  style={styles.inputstyle}
                  defaultValue={midday && midday.time}
                  placeholder={midday && midday.time}
                />
              </td>
              <td style={styles.td}>
                <input
                  name="mealType"
                  onChange={changeHandler}
                  style={styles.inputstyle}
                  defaultValue={lunch && lunch.food}
                  placeholder={lunch && lunch.food}
                />{" "}
                <br />
                <input
                  name="mealType"
                  onChange={changeHandler}
                  style={styles.inputstyle}
                  defaultValue={lunch && lunch.time}
                  placeholder={lunch && lunch.time}
                />
              </td>
              <td style={styles.td}>
                <input
                  name="mealType"
                  onChange={changeHandler}
                  style={styles.inputstyle}
                  defaultValue={afternoon && afternoon.food}
                  placeholder={afternoon && afternoon.food}
                />{" "}
                <br />
                <input
                  name="mealType"
                  onChange={changeHandler}
                  style={styles.inputstyle}
                  defaultValue={afternoon && afternoon.time}
                  placeholder={afternoon && afternoon.time}
                />
              </td>
              <td style={styles.td}>
                <input
                  name="mealType"
                  onChange={changeHandler}
                  style={styles.inputstyle}
                  defaultValue={dinner && dinner.food}
                  placeholder={dinner && dinner.food}
                />
                <br />
                <input
                  name="mealType"
                  onChange={changeHandler}
                  style={styles.inputstyle}
                  defaultValue={dinner && dinner.time}
                  placeholder={dinner && dinner.time}
                />
              </td>
              <td style={styles.td}>
                <input
                  name="mealType"
                  onChange={changeHandler}
                  style={styles.inputstyle}
                  defaultValue={dinnerdrink && dinnerdrink.food}
                  placeholder={dinnerdrink && dinnerdrink.food}
                />
                <br />
                <input
                  name="mealType"
                  onChange={changeHandler}
                  style={styles.inputstyle}
                  defaultValue={dinnerdrink && dinnerdrink.time}
                  placeholder={dinnerdrink && dinnerdrink.time}
                />
              </td>
            </tr>
          </tbody>
        </table>

        {/* <button onClick={submitValue(pikerdate)}>Edit</button> */}
      </form>
    </>
  );
}

const styles = {
  th: {
    fontSize: "17px",
    whiteSpace: "nowrap",
    padding: "5px 10px",
    margin: "10px 0px",
  },

  td: {
    borderLeft: "1px solid #fff",
    padding: "5px 0px",
    fontSize: "12px",
  },

  tdFirst: {
    padding: "5px 10px",
    fontSize: "12px",
  },
  inputstyle: {
    background: "#a23698",
    width: "130px",
    border: "none",
    textAlign: "center",
    color: "white",
  },
};
