import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons'

export default function Consultant(){
return (

<div style={styles.card}>
<div style={styles.left}>
<p><strong style={{ whiteSpace:'nowrap', fontSize:'16px' }}>Total Consultants</strong>
<hr style={{ color:'#fff' }}/>
</p>
</div>
<div style={styles.right}>
<h2 style={styles.counter}>11</h2>
<button style={styles.link}>Add Now <FontAwesomeIcon icon={faChevronCircleRight}/></button>
</div>

</div>

);

}

const styles = {
card:{
display:'flex',
flexDirection:'column',
background:'#4c003d',
width:'13.5rem',
height:'10rem',
padding:'20px',
borderRadius:'10px',
margin:'10px',
lineHeight:'10px',
},
link:{
fontSize:'13px',
textDecoration:'none',
color:'#fff',
background:'#5a0f2c',
padding:'10px',
borderRadius:'10px'
},

left:{
padding:'0px',
},
right:{
padding:'0px',
},
counter:{
color:'#fff'
}

}


