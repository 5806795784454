
import "react-calendar/dist/Calendar.css"
import brestfeeding from '../img/icons/Group -2.png'
import exercise from '../img/icons/Path 1647.png'
import foodchart from '../img/icons/Group 781.png'
import diapering from '../img/icons/Group 772.png'
import sleeppattern from '../img/icons/Group 778.png'
import brain from '../img/icons/Group 779.png'
import medicine from '../img/icons/Path 1661.png'
import vaccine from '../img/icons/Path 1662.png'
import { useState } from "react";
import axios from "axios"
export default function LearningPortal(props) {
  const [startdate, setstartdate] = useState();
  const [enddate, setenddate] = useState();
  const [starttime, setstarttime] = useState();
  const [endtime, setendtime] = useState();
  const [wet, setwet] = useState(true);
  const [diapershow, setdiapershow] = useState(false)
  const [sleepshow,setsleepshow]=useState(false)
  const [isclose, setisclose] = useState(false);
  const [inuput, setinuput] = useState(false);
  const [selectdateandtime, setselectdateandtime] = useState(false);
  const [dateState, setDateState] = useState(new Date());
  const toggle = () => setisclose((value2) => !value2);
  function getselectdateandtime() {
    setselectdateandtime(!selectdateandtime);
  }
  const toggle2 = () => {
    setinuput((value2) => !value2);
    setisclose((value2) => !value2);
  };

  const [isdate, setDateSelect] = useState(true);
  function closeDailyTracker() {
    setDateSelect(!isdate);
  }
  const onChange = (e) => {
    setDateState(e);
  };
const update=()=>{
  const data={
      st:starttime,
      sd:startdate,
      et:endtime,
      ed:enddate,
      wet:wet,
  }
  const headers = {
    Authorization: `token ` + localStorage.getItem("postclient"),
  };
  const url='http://127.0.0.1:8000/customer/post-diaper'
  axios.post(url,data,{
    headers: headers,
  }).then(()=>alert('updated')).catch(()=>alert('not updated'))
}
const updatesleep=()=>{
  const data={
      st:starttime,
      sd:startdate,
      et:endtime,
      ed:enddate,
      left:wet,
  }
  const headers = {
    Authorization: `token ` + localStorage.getItem("postclient"),
  };
  const url='http://127.0.0.1:8000/customer/post-sleep-pattern'
  axios.post(url,data,{
    headers: headers,
  }).then(()=>alert('updated')).catch(()=>alert('not updated'))
}
  return (
    <>
      {diapershow && <div style={styles.window}>
        diaper<hr></hr>
        <div>
          <span onClick={() => setwet(true)} style={wet ? styles.true : styles.false} >wet</span> <span onClick={() => setwet(false)} style={wet ? styles.false : styles.true} >soiled</span>
        </div>

        <div style={styles.start} >
          <input onChange={(e)=>setstartdate(e.target.value)} style={styles.input} type="date"></input> <span  style={styles.txt}> start </span> <input style={styles.input} onChange={(e)=>setstarttime(e.target.value)} type="time"></input>
        </div>
        <div style={styles.start} >
          <input onChange={(e)=>setenddate(e.target.value)} style={styles.input} type="date"></input> <span  style={styles.txt}>end</span> <input onChange={(e)=>setendtime(e.target.value)} style={styles.input} type="time"></input>
        </div>
        <div><br></br><br></br>
          <button onClick={()=>update()}>save</button>

          <span onClick={() => setdiapershow(false)} style={styles.closebtn}>&times;</span>
        </div>
      </div>}
      {sleepshow && <div style={styles.window}>
        sleep Pattern<hr></hr>
        <div>
          <span onClick={() => setwet(true)} style={wet ? styles.true : styles.false} >left</span> <span onClick={() => setwet(false)} style={wet ? styles.false : styles.true} >right</span>
        </div>

        <div style={styles.start} >
          <input onChange={(e)=>setstartdate(e.target.value)} style={styles.input} type="date"></input> <span  style={styles.txt}> start </span> <input style={styles.input} onChange={(e)=>setstarttime(e.target.value)} type="time"></input>
        </div>
        <div style={styles.start} >
          <input onChange={(e)=>setenddate(e.target.value)} style={styles.input} type="date"></input> <span  style={styles.txt}>end</span> <input onChange={(e)=>setendtime(e.target.value)} style={styles.input} type="time"></input>
        </div>
        <div><br></br><br></br>
          <button onClick={()=>updatesleep()}>save</button>

          <span onClick={() => setsleepshow(false)} style={styles.closebtn}>&times;</span>
        </div>
      </div>}

      <div
        className=""
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
        }}
      >
        <div
          className="innerbox"
          style={styles.innerbox}
          onClick={() => window.location.href = 'brestfeedingoverview'}
        >
          <img src={brestfeeding} style={styles.icon} alt="" />
          {/* <FontAwesomeIcon icon={faUtensils} style={styles.icon} /> */}
          <br />
          <span className="Titlemobile" style={styles.title}>
            Breastfeeding
          </span>
        </div>

        <div
          className="innerbox"
          style={styles.innerbox}
          onClick={() => window.location.href = 'exersidetrackerpost'}
        >
          <img src={exercise} style={styles.icon} alt="" />
          {/* <FontAwesomeIcon icon={faUtensils} style={styles.icon} /> */}
          <br />
          <span className="Titlemobile" style={styles.title}>
            Exercise
          </span>
        </div>

        <div
          className="innerbox"
          style={styles.innerbox}
          onClick={() => window.location.href='/diettrackeroverview'}
        >
          <img src={foodchart} style={styles.icon} alt="" />
          {/* <FontAwesomeIcon icon={faUtensils} style={styles.icon} /> */}
          <br />
          <span className="Titlemobile" style={styles.title}>
            Food Chart
          </span>
        </div>

        <div
          className="innerbox"
          style={styles.innerbox}
          onClick={() => setdiapershow(true)}
        >
          <img src={diapering} style={styles.icon} alt="" />
          {/* <FontAwesomeIcon icon={faUtensils} style={styles.icon} /> */}
          <br />
          <span className="Titlemobile" style={styles.title}>
            Diapering
          </span>
        </div>

        <div
          className="innerbox"
          style={styles.innerbox}
          onClick={() => setsleepshow(true)}
        >
          <img src={sleeppattern} style={styles.icon} alt="" />
          {/* <FontAwesomeIcon icon={faUtensils} style={styles.icon} /> */}
          <br />
          <span className="Titlemobile" style={styles.title}>
            Sleep Pattern
          </span>
        </div>

        <div
          className="innerbox"
          style={styles.innerbox}
          onClick={() => window.location.href = '/brainnav'}
        >
          <img src={brain} style={styles.icon} alt="" />
          {/* <FontAwesomeIcon icon={faUtensils} style={styles.icon} /> */}
          <br />
          <span className="Titlemobile" style={styles.title}>
            Baby Brain Stimulation
          </span>
        </div>

        <div
          className="innerbox"
          style={styles.innerbox}
          onClick={() => window.location.href = '/postmedicine'}
        >
          <img style={{ cursor: 'pointer' }} src={medicine} alt="" />
          {/* <FontAwesomeIcon icon={faUtensils} style={styles.icon} /> */}
          <br />
          <span className="Titlemobile" style={styles.title}>
            Medicines
          </span>
        </div>

        <div
          className="innerbox"
          style={styles.innerbox}
          onClick={() => window.location.href = '/vaccination'}
        >
          <img src={vaccine} style={styles.icon} alt="" />
          {/* <FontAwesomeIcon icon={faUtensils} style={styles.icon} /> */}
          <br />
          <span className="Titlemobile" style={styles.title}>
            Vaccination Chart
          </span>
        </div>
      </div>
    </>
  );
}

const styles = {
  pleft: { textAlign: "left", fontSize: "14px", margin: "auto" },
  box: {
    overflowY: "scroll",
    scrollbarWidth: "none",
    padding: "10px",
    height: "500px",
    margin: "auto 10px",
    width: "83%",
    // margin: "10px",
  },
  innerbox: {
    padding: "10px 0px",
    margin: "35px",
    // float: "left",
    width: "250px",
    height: "100px",
    background: "#ffffff1c 0% 0% no-repeat padding-box",
    borderRadius: "10px",
  },
  innerbox2: {
    background: "0% 0% no-repeat padding-box padding-box rgb(255 255 255 / 0%)",
    border: "none",
    color: "white",
    borderBottom: "1px solid white",
  },

  icon: {
    width: "4.6rem",
    cursor: "pointer",
  },


  title: {
    display: "inline-block",
    minWidth: "100%",
    background: "#e81a7f",
    marginTop: "1.2rem",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    textTransform: "uppercase",
    fontSize: "14px",
    padding: "5px 0px",
  },

  module: {
    position: "absolute",
  },

  dateshow: {
    position: "absolute",
    width: "300px",
    background: "black",
    // transform: "translate(25rem, 2rem)",
    padding: "10px 30px",
    borderRadius: '20px'
  },

  ubtn: {
    border: "none",
    margin: "5px 0px",
    color: "#fff",
    background: "#E81A7F",
    padding: "5px 20px",
    fontWeight: "bold",
    cursor: "pointer",
  },
  closebtn: {
    position: "absolute",
    right: "15px",
    top: "5px",
    cursor: "pointer",
  },
  window: {
    margin: 'auto',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '999', // Set a higher z-index to ensure it appears on top
    background: 'rgba(0,0,0,.6)',
    width: '400px',
    height: '420px',
    borderRadius: '20px',
    paddingTop: '7px',
  },
  true: {

    fontSize: '16px',
    border: '1px solid #E81A7F',
    padding: '5px',
    background: '#E81A7F',
    borderRadius: '10px',
    textTransform: 'lowercase',
    cursor: 'pointer',


  },
  false: {
    fontSize: '16px',
    textTransform: 'lowercase',
    border: '3px solid #E81A7F',
    padding: '5px',
    borderRadius: '10px',
    cursor: 'pointer',

  },
  input: {
    border: 'none',
    outline: 'none',
    padding: '0',
    margin: '0',
    background: 'none',
    borderBottom: '1px solid white',
    color: 'white',

  },
  start: {
    marginTop: '50px',
    textTransform: 'lowercase',

  },
  txt: {
    fontSize: '23px',
    padding: '20px'
  }

};
