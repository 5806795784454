import axios from "axios";
import React from "react";
import { useEffect, useState } from "react";
import ball from "../image/pregnant.png";

function Remadies() {
  const [isClose, setisclose] = useState(true);
  const [data3, setdata3] = useState();
  const [ramadies, setramadies] = useState(false);
  const [passremadie, setpassremadie] = useState();
  const [passsymtum, setpasssymtum] = useState();
  function toggleremadies(e, t, r, s) {
    console.log(e, t);
    setramadies(t);
    setisclose(true);
    setpassremadie(r);
    setpasssymtum(s);
  }

  function closeTabBox() {
    setisclose(false);
    setramadies(false);
  }
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `token ` + sessionStorage.getItem("dad"),
      },
    };
    //remove this date after setting up the admin pannel

    axios
      .get(
        "symptom-remedy/",
        config

        // config
      )
      .then((res) => {
        console.log(res.data);
        setdata3(res.data.data);
      });
  }, []);

  return (
    <div>
      {ramadies === true ? (
        <>
          {isClose ? (
            <div style={styles.card} className="lightgradient">
              <div className="pinkbutton2">
                Remedies
                <i
                  onClick={closeTabBox}
                  className="fa fa-close"
                  style={styles.closebtn}
                ></i>
              </div>
              <h6 style={{ textAlign: "initial" }}>{passremadie}</h6>
              <li style={styles.li}>{passsymtum}</li>
              <br />
              <button className="pinkbutton2">buy package</button>
            </div>
          ) : null}
        </>
      ) : null}
      <> {ramadies}</>
      {data3 &&
        data3.map((ele) => {
          return (
            <div
              className="innerbox2 "
              style={styles.innerbox}
              onClick={() =>
                toggleremadies(ele.id, true, ele.symptom, ele.remedy)
              }
            >
              <img className="preg" src={ball} />
              {/* <img src={imag7} style={styles.icon} alt="" />{" "} */}
              {/* <FontAwesomeIcon icon={faBirthdayCake} style={styles.icon} /> */}
              <br />
              <span className="Titlemobile" style={styles.title}>
                {ele.symptom}
              </span>
            </div>
          );
        })}
    </div>
  );
}

const styles = {
  box: {
    overflowY: "scroll",
    scrollbarWidth: "none",
    padding: "10px",
    height: "500px",
    margin: "auto",
    width: "83%",
    // margin: "10px",
  },
  li: { fontSize: "14px" },
  closebtn: { float: "right" },
  card: {
    // display: "flex",
    position: "absolute",
    padding: "1%",
    zIndex: "5",
    // width: "47%",
    justifyContent: "space-between",
    // background: "#4c003d",
    // padding: "20px",
    borderRadius: "10px",
    margin: "10px",

    background: "rgba( 255, 255, 255, 0.2 );",
    boxShadow: " 0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
    backdropFilter: "blur( 20px )",

    borderRadius: "10px",
    border: "1px solid rgba( 255, 255, 255, 0.18 )",
  },
  inner: {
    textDecoration: "none",
    color: "#fff",
  },
  innerbox: {
    padding: "10px 0px",
    margin: "25px",
    float: "left",
    width: "140px",
    height: "118px",
    background: "#ffffff1c 0% 0% no-repeat padding-box",
    borderRadius: "10px",
  },

  icon: {
    width: "4.6rem",
    cursor: "pointer",
  },

  title: {
    display: "inline-block",
    minWidth: "100%",
    background: "#e81a7f",
    marginTop: "1.2rem",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    textTransform: "uppercase",
    fontSize: "14px",
    padding: "5px 0px",
  },

  module: {
    position: "absolute",
  },
};

export default Remadies;
