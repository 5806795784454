import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons'




const Searchclients = ()=>{
const [showResults, setShowResults] = React.useState(true);	
const hideShow = () => setShowResults(false);	
	
	
return (

<div style={styles.card}>
<div style={styles.inner}>
<p style={{ textAlign:'left' }}>Search Doctors</p>
<form style={{ display:'flex' }}>
{ showResults ? <FontAwesomeIcon style={{ marginRight:'-22px' }} icon={faSearch}/> : null }

<input onFocus={ hideShow } style={styles.input} type="Search"/>
</form>
</div>
</div>

);



}

const styles = {
card:{
display:'flex',
justifyContent:'space-between',
background:'#85378a',
padding:'20px',
borderRadius:'10px',
margin:'10px'
},
inner:{
textDecoration:'none',
color:'#fff'
},

input:{
width:'40rem',
borderTop:'none',
borderLeft:'none',
borderRight:'none',
borderBottomColor:'1px solid #fff',
background:'transparent',
padding:'8px'
}


}


export default Searchclients;


