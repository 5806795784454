import React, { useState } from "react";
import { useEffect } from "react";
import axios from "axios";

export default function ExerciseTracker({ id }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("doc_token"),
      },
    };
    //remove this date after setting up the admin pannel

    axios
      .get(`http://127.0.0.1:8000/customer/get-all-dates-exercises/`, {
        headers: {
          Authorization: `Token ${localStorage.getItem("con_token")}`,
        },
        params: {
          customer: id,
        },
      })
      .then((res) => {
        const data = res.data.exercises.concat(res.data.calories);
        const maindata = data.concat(res.data.customExericises);

        const groupedByDate = maindata.reduce((exercises, exercise) => {
          if (exercise.date in exercises) {
            exercises[exercise.date].push(exercise);
          } else {
            exercises[exercise.date] = [exercise];
          }
          return exercises;
        }, {});

        console.log(groupedByDate);
        const sortedOnDate = Object.values(groupedByDate).sort((arr1, arr2) => {
          const ymdDate1 = arr1[0].date.split("-").reverse().join("-");
          const ymdDate2 = arr2[0].date.split("-").reverse().join("-");
          if (ymdDate1 < ymdDate2) {
            return 1;
          } else if (ymdDate1 > ymdDate2) {
            return -1;
          }
          return 0;
        });
        console.log(sortedOnDate);
        setData(sortedOnDate);
      });
  }, []);
  return (
    <>
      <div className="scrollerbar">
        <table
          style={{
            width: "100%",
            borderSpacing: "0px 1em",
            borderCollapse: "collapse",
          }}
        >
          <thead style={{ borderBottom: "5px solid #5c0048" }}>
            <tr
              style={{
                background:
                  "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
              }}
              className="font-blue"
            >
              <th style={styles.th}>Date</th>
              <th style={styles.th}>Exercises</th>
              <th style={styles.th}>Calories</th>
            </tr>
          </thead>

          <tbody>
            {data.map((ele) => {
              console.log(ele);
              return (
                <tr
                  style={{
                    background:
                      "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
                  }}
                  key={ele.id}
                >
                  <td style={styles.tdFirst}> {ele[0].date}</td>
                  <td style={styles.td}>
                    {ele.map((ele) => {
                      return <div>{ele.exercise}</div>;
                    })}
                  </td>
                  <td style={styles.td}>
                    {ele.map((ele) => {
                      return <div>{ele.value}</div>;
                    })}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}

const styles = {
  th: {
    fontSize: "17px",
    whiteSpace: "nowrap",
    padding: "5px 10px",
    background:
      "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
    margin: "10px 0px",
  },

  td: {
    border: "1px solid black",
    padding: "5px 10px",
    textAlign: "left",
    fontSize: "12px",
    background:
      "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
  },

  tdFirst: {
    padding: "5px 10px",
    border: "1px solid black",
    fontSize: "12px",
  },

  sbtn: {
    border: "none",
    margin: "5px 0px",
    color: "#fff",
    background: "#E81A7F",
    padding: "8px",
    fontWeight: "bold",
    cursor: "pointer",
  },
};
