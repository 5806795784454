import React from "react";
import PropTypes from "prop-types";
import Sidebar from "../components/sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faUser, faBars } from "@fortawesome/free-solid-svg-icons";
import AToggler from "../toggle";
import axios from "axios";
import ATogProfile from "../tog_profile";
import Calendar from "../components/calendar";
import Experts from "../components/experts";
import Tabs from "../components/appointment_tabs";

export default class AppointmentOverview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isToggleOn: true,
      onProfile: true,
      sidebar: true,
      items: [],
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleProfile = this.handleProfile.bind(this);
  }

  handleClick() {
    this.setState((prevState) => ({
      isToggleOn: !prevState.isToggleOn,
    }));
  }

  componentDidMount() {
    // document.addEventListener("mousedown", this.clickedOutside);

    if (localStorage.getItem("token") === null) {
      localStorage.clear();
      window.location = "/clogin";
    }
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("token"),
      },
    };
    const dashboardData=JSON.parse(localStorage.getItem('dashboard_data'))
    if (dashboardData){
      this.setState({items:dashboardData});
    }
    else{

      axios.get("customer/customer-dashboard-details/", config).then((res) => {

        this.setState({ items: res.data });
        localStorage.setItem('dashboard_data',JSON.stringify(res.data))
      });
    }
  }

  handleProfile() {
    this.setState((prevState) => ({
      onProfile: !prevState.onProfile,
    }));
  }

  render() {
    function openMenu() {
      document.querySelector(".sidebar").classList.toggle("show");
    }

    return (
      <div className="App">
        <Sidebar siderbarcolor={this.state.sidebar} />
        <header className="overview">
          <div
            //  ref={this.setWapRef}
            className="grandiant"
            style={{ padding: "2% 10%", textAlign: "right" }}
          >
            <span onClick={openMenu} className="menu">
              <FontAwesomeIcon
                style={{ marginRight: "20px" }}
                icon={faBars}
                className="menu-icon"
              />
            </span>{" "}
            <div>{this.state.isToggleOn ? null : <AToggler />}</div>
            <span className="iconsofgradiant">
              <FontAwesomeIcon
                className="iconsofprofile"
                onClick={this.handleClick}
                style={{ marginRight: "1rem", cursor: "pointer" }}
                icon={faBell}
              />
            </span>
            <span className="iconsofgradiant">
              <FontAwesomeIcon
                className="iconsofprofile"
                onClick={this.handleClick}
                style={{ cursor: "pointer" }}
                icon={faUser}
              />
            </span>{" "}
            {this.state.items.firstname} {this.props.childern}
            <div>{this.state.onProfile ? null : <ATogProfile />}</div>
          </div>

          <div
            style={{ width: "89%", display: "flex", paddingLeft: "10px" }}
          ></div>
          <div className="mwrapper">
            {/* <span style={{ whiteSpace: "nowrap" }}>My Appointments</span>
            <div style={{ padding: "4px", width: "100%" }}>
              <hr style={{ background: "white", color: "white" }} />
            </div> */}
            <div className="mleft">
              <div
                style={{
                  display: "flex",
                  padding: "3% 1%",
                }}
              >
                <span style={{ whiteSpace: "nowrap" }}>My Appointments</span>
                <div style={{ padding: "4px", width: "100%" }}>
                  <hr style={{ background: "white", color: "white" }} />
                </div>
              </div>
              <Tabs />
            </div>
            <div className="mright">
              <Calendar />
              <div
                style={{
                  fontWeight: "bold",
                  textAlign: "left",
                  paddingLeft: "10px",
                }}
              >
                {/* <small style={{ textTransform: "uppercase" }}>Experts</small> */}
              </div>

              {/* <Experts /> */}
            </div>
          </div>
        </header>
      </div>
    );
  }
}

AppointmentOverview.propTypes = {
  children: PropTypes.element.isRequired,
};
