import { Link } from "react-router-dom";
import axios from "axios";
import showPwdImg from "../../client/login/show-password.svg";
import hidePwdImg from "../../client/login/hide-password.svg";
import { useForm } from "react-hook-form";
import { useState } from "react";
export default function Form(props) {
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [showrefid, setshowrefid] = useState(true);
  const [SelectedFile, setSelectedFile] = useState();

  const [isRevealPwd2, setIsRevealPwd2] = useState(false);
  const [allValues, setAllValues] = useState(
    showrefid ? { referalId: "Dhivya86" } : {}
  );
  //   const {
  //     register,
  //     handleSubmit,
  //     formState: { errors },
  //   } = useForm();
  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    // setIsSelected(true);
  };
  const handleChange = (e) => {
    setAllValues({ ...allValues, [e.target.name]: e.target.value });
  };
  const submitValue = (e) => {
    const formData = new FormData();
    console.log(SelectedFile);

    formData.append("profile_img", SelectedFile);
    formData.append("firstname", allValues.firstname);
    formData.append("lastname", allValues.lastname);
    formData.append("age", allValues.age);
    formData.append("wife_email", allValues.wife_email);
    formData.append("wife_name", allValues.wife_name);
    formData.append("email", allValues.email);
    formData.append("mobile", allValues.mobile);
    formData.append("password", allValues.password);
    // e.preventDefault();
    // props.state.patient = "true";
    console.log(formData.get("profile_img"));
    // const headers = {
    //   headers: {
    //     "Api-Key": `g7eWwFm9.va3HxJiPD1OzMs5I1UntS2gAwXj99Kuq`,
    //   },
    // };

    // const data = props.state;
    axios({
      method: "POST",
      url: "dad-registration/",
      //digiatl
      headers: { "Api-Key": `aU60ViOC.02hdAGZ0gy4avx0oBCkhErSJK55anMuC` },
      //heroku
      // headers: { "Api-Key": `g7eWwFm9.va3HxJiPD1OzMs5I1UntS2gAwXj99Kuq` },
      data: formData,
    })
      // axios
      //   .post("client-registration/", allValues, {
      //     headers: headers,
      //   })
      .then((res) => {
        alert(res.data.message);
        // window.location = "/clogin";
      })
      .catch((error) => {
        //var my_obj_str = JSON.stringify(error.response.data);
        alert(JSON.stringify(error.response.data));
      });
  };

  //currentStep = 3;
  //   nextButton = () => {
  //     let currentStep = this.state.currentStep;
  //     if (currentStep < 3) {
  //       return (
  //         <button
  //           className="btn btn-primary float-right"
  //           type="button"
  //           //  onClick={this._next}
  //         >
  //           Next
  //         </button>
  //       );
  //     }
  //     return null;
  //   };

  //   {
  //     console.log(props.state);
  //   }
  return (
    <>
      {console.log(SelectedFile)}
      <div
        className="innerclintsignup"
        style={{
          display: "flex",
          flexDirection: "column",
          color: "#fff",
          padding: "20px",
          width: "40%",
          borderRadius: "20px",
          boxShadow: "12px 10px 60px #00000029",
          backdropFilter: "blur(39px)",
          WebkitBackdropFilter: "blur(39px)",
          background: "#00000029",
        }}
      >
        <p style={{ textAlign: "center" }}>
          <small>Let us know You</small>
        </p>
        <form
          //   onSubmit={handleSubmit(submitValue)}
          style={{
            display: "flex",
            flexDirection: "column",
            paddingBottom: "22%",
            lineHeight: "70px",
          }}
        >
          <div style={{ display: "flex" }}>
            <input
              name="firstname"
              onChange={handleChange}
              style={styles.finput}
              type="text"
              placeholder="First Name "
            />

            <input
              name="lastname"
              onChange={handleChange}
              style={styles.finput}
              type="text"
              placeholder="Last Name"
            />
          </div>
          <input
            name="age"
            onChange={handleChange}
            style={styles.input}
            type="number"
            placeholder="Age"
          />
          <input
            name="email"
            onChange={handleChange}
            style={styles.input}
            type="email"
            placeholder="Email"
          />
          <input
            // {...register("mobile", { required: true })}
            name="mobile"
            // value={props.getState("mobile")}
            onChange={handleChange}
            style={styles.input}
            type="tel"
            required
            maxlength="12"
            placeholder="Mobile Number"
          />
          <div style={{ display: "flex" }}>
            {/* <input
              //   name="name"
              //   value={props.getState("firstname")}
              //   onChange={props.handleChange}
              style={styles.input}
              type=""
              placeholder="Individual ID proof with photo"
            /> */}
            <small style={{ width: "50%" }}>
              Individual ID proof with photo
            </small>
            <input
              name="profile_img"
              //   {...register("idproof", { required: true })}
              //   value={props.getState("firstname")}
              //   onChange={props.handleChange}
              onChange={changeHandler}
              style={styles.file}
              type="file"
            />
          </div>
          <input
            // {...register("address", { required: true })}
            name="wife_name"
            // value={props.getState("address")}
            onChange={handleChange}
            style={styles.input}
            type="text"
            required
            placeholder="Wife Name"
          />

          {/* {showrefid ? (
            <input
              // {...register("referalId", { required: true })}
              name="referalId"
              // value={props.getState("referalId")}
              onChange={handleChange}
              style={styles.input}
              type="text"
              placeholder="Doctor Referral Code"
            />
          ) : (
            <input
              // {...register("referalId", { required: true })}
              name="referalId"
              value={allValues.referalId}
              // value={props.getState("referalId")}
              onChange={handleChange}
              style={styles.input}
              type="text"
              readonly="readonly"
              // placeholder="Default Referral Code"
            />
          )} */}

          {/* <button
            type="button"
            style={{
              background:
                "transparent linear-gradient(266deg, #FF006C 0%, #293247 100%) 0% 0% no-repeat padding-box",
              width: "267px",
              height: "32px",
              border: "none",
              color: "#fff",
              boxShadow: "0px 9px 10px #00000029",
              borderRadius: "4px",
              cursor: "pointer",
            }}
            onClick={() => setshowrefid((prevState) => !prevState)}
            // onClick={setshowrefid(true)}
          >
            {showrefid
              ? "Dont have a Referral Code? use Default"
              : "use  Referral id"}
          </button> */}

          <input
            name="wife_email"
            onChange={handleChange}
            style={styles.input}
            type="Wifeemail"
            placeholder=" Wife's Email ID"
          />
          {/* <input
            // {...register("Menstruation_date", { required: true })}
            name="Menstruation_date"
            // value={props.getState("Menstruation_date")}
            onChange={handleChange}
            style={styles.input}
            type="date"
            placeholder="Last Menstrual Period Date"
          /> */}

          <div className="pwd-container">
            <input
              // {...register("password", { required: true })}
              name="password"
              //   /  value={props.getState("password")}
              onChange={handleChange}
              style={styles.input}
              type={isRevealPwd ? "text" : "password"}
              placeholder="Password"
            />
            <img
              title={isRevealPwd ? "Hide password" : "Show password"}
              src={isRevealPwd ? hidePwdImg : showPwdImg}
              onClick={() => setIsRevealPwd((prevState) => !prevState)}
            />
          </div>
          {/* <input
            // {...register("password", { required: true })}
            name="password"
            //   /  value={props.getState("password")}
            onChange={handleChange}
            style={styles.input}
            type="password"
            placeholder="Password"
          /> */}

          <div className="pwd-container">
            <input
              // {...register("password2", { required: true })}
              name="password2"
              //    / value={props.getState("password2")}
              onChange={handleChange}
              style={styles.input}
              type={isRevealPwd2 ? "text" : "password"}
              placeholder="Confirm Password"
            />
            <img
              title={isRevealPwd2 ? "Hide password" : "Show password"}
              src={isRevealPwd2 ? hidePwdImg : showPwdImg}
              onClick={() => setIsRevealPwd2((prevState) => !prevState)}
            />
          </div>
          {/* <input
            // {...register("password2", { required: true })}
            name="password2"
            //    / value={props.getState("password2")}
            onChange={handleChange}
            style={styles.input}
            type="password"
            placeholder="Confirm Password"
          /> */}

          <div
            style={{
              display: "grid",
              // justifyContent: "space-between",
            }}
          >
            {/* {this.nextButton()} */}
            {/* {props.current == "3" ? ( */}
            <div style={{}}>
              {/* <div>
                <input
                  style={styles.input}
                  type="checkbox"
                  value="true"
                  placeholder="Your Expected Counsulting Fee"
                  // onChange={(e) => setDoctor(e.target.value)}
                />{" "}
                <span className="font-13">I agree to the T & C</span>
              </div> */}

              {/* <input
                style={{
                  background:
                    "transparent linear-gradient(266deg, #FF006C 0%, #293247 100%) 0% 0% no-repeat padding-box",
                  width: "102px",
                  height: "32px",
                  border: "none",
                  color: "#fff",
                  boxShadow: "0px 9px 10px #00000029",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
                type="primary"
                onClick={props.prev}
                //style={{ marginRight: 10 }}
                value="  Previous"
              /> */}
            </div>
            {/* <br /> */}

            {/* <hr /> */}
            <Link to="/clogin" style={{ margin: "auto" }}>
              <small>Already have an account?</small>
            </Link>
            <button
              style={{
                background:
                  "transparent linear-gradient(266deg, #FF006C 0%, #293247 100%) 0% 0% no-repeat padding-box",
                width: "102px",
                height: "32px",
                border: "none",
                color: "#fff",
                boxShadow: "0px 9px 10px #00000029",
                borderRadius: "4px",
                cursor: "pointer",
                margin: "auto",
              }}
              //disabled={!enabled}
              type="button"
              onClick={submitValue}
              //    / value="Finish"
            >
              Signup
            </button>
            {/* <button
              style={{
                background:
                  "transparent linear-gradient(266deg, #FF006C 0%, #293247 100%) 0% 0% no-repeat padding-box",
                width: "102px",
                height: "32px",
                border: "none",
                color: "#fff",
                boxShadow: "0px 9px 10px #00000029",
                borderRadius: "4px",
                cursor: "pointer",
              }}
            >
              Sign in
            </button> */}

            <div style={styles.terms}>
              <small>
                <Link to="/privacy">
                  <small> privacy policy</small>
                </Link>
              </small>
              <small>
                <Link to="/terms">
                  <small> terms and conditions</small>
                </Link>
              </small>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

const styles = {
  input: {
    background: "none",
    padding: "10px",

    marginBottom: "5%",
    color: "#fff",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderColor: "aliceblue",
  },
  danger: {
    color: "red",
  },
  terms: {
    display: "flex",
    justifyContent: "space-around",
  },
  finput: {
    background: "none",
    padding: "10px",
    color: "#fff",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderColor: "aliceblue",
    width: "50%",
  },

  file: {
    background: "none",
    padding: "10px",
    color: "#fff",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    width: "44.8%",
    borderBottom: "2px solid rgb(255, 255, 255)",
  },
};
