import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";

import ExpImage from "../img/small_image.jpeg";

export default function Salesteam() {
  return (
    <>
      <div style={styles.card}>
        <div style={styles.left}>
          <div>
            <div style={{ display: "flex" }}>
              <div>
                <img src={ExpImage} style={styles.left} />
              </div>
              <div style={styles.right}>
                <p style={{ fontSize: "16px", marginBottom: "-7px" }}>
                  Dr. someone
                </p>
                <p>
                  <small style={{ fontSize: "12px" }}>gynacologist</small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={styles.card}>
        <div style={styles.left}>
          <div>
            <div style={{ display: "flex" }}>
              <div>
                <img src={ExpImage} style={styles.left} />
              </div>
              <div style={styles.right}>
                <p style={{ fontSize: "16px", marginBottom: "-7px" }}>
                  Dr. someone
                </p>
                <p>
                  <small style={{ fontSize: "12px" }}>gynacologist</small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const styles = {
  card: {
    display: "flex",
    flexDirection: "column",
    background: "#4c003d",
    width: "13.5rem",
    padding: "10px 20px",
    borderRadius: "10px",
    margin: "10px",
    lineHeight: "10px",
  },

  left: {
    padding: "0px",
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    marginRight: "20px",
  },
  right: {
    padding: "0px",
    minWidth: "10rem",
    lineHeight: "1px",
  },
  counter: {
    color: "#fff",
  },
};
