import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  createInvestigationReport,
  deleteCustomInvestigation,
  getInvestigationReport,
  updateInvestigationReport,
} from "../../helpers/service";
import AddNewReport from "./innercom/add_new_investigation";

export default function InvestigationReport({ customerId, closeReporter }) {
  const [investigate, setInvestigation] = useState(false);
  const history = useHistory();
  const [customFields, setCustomFields] = useState([]);

  const [investigationDetails, setInvestigationDetails] = useState({});

  const [date, setDate] = useState(new Date());
  const [todaysdate, settodaysdate] = useState();

  const inputRef = React.useRef({});
  console.log(investigationDetails);
  var date2 = moment(date).format("YYYY-MM-DD");
  // settodaysdate(date2);
  const updateReport = React.useMemo(() => {
    return updateInvestigationReport();
  }, [JSON.stringify(investigationDetails)]);

  const customInputRef = React.useRef({});

  const formRef = React.useRef();

  function addNewReport() {
    setInvestigation(!investigate);
  }

  function closeDialog() {
    setInvestigation(false);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log(inputRef.current);

    let inputValues = Object.entries(inputRef.current).reduce(
      (obj, [name, input]) => {
        if (name && input) {
          obj[name] = input.value;
        }

        return obj;
      },
      {}
    );

    let customInputValues = Object.entries(customInputRef.current)
      .map(([name, items]) => {
        if (name && items) {
          let obj = {};

          console.log("obj", name, items);

          Object.entries(items).map(([inputName, input]) => {
            let [key, _] = inputName.split("-");

            console.log("key", key);

            if (key && _) {
              obj[key] = input?.value || "";
            }
          });

          obj["name"] = name;

          return obj;
        }
      })
      .filter((each) => !!each);

    if (
      !!investigationDetails.investigation_data &&
      Object.keys(investigationDetails.investigation_data).length > 0
    ) {
      let custom = [];
      let existing_custom = [];

      if (
        investigationDetails.custom_investigation &&
        investigationDetails.custom_investigation.length > 0
      ) {
        customInputValues.map((each) => {
          let item = investigationDetails.custom_investigation.find(
            (eachC) => eachC.name === each.name
          );

          if (item) {
            existing_custom.push({
              ...each,
              id: item.id,
            });
          } else {
            custom.push({
              ...each,
            });
          }
        });
      }

      updateReport
        .api({
          data: {
            ...inputValues,
            customer: customerId,
            custom,
            existing_custom,
            date: date2,
          },
        })
        .then((response) => {
          if (response) {
            alert("Investigation report updated successfully ");
            fetchInvestigationReport();
          } else {
            alert("Cannot submit report. Please try again later2");
          }
        });
    } else {
      const createReport = createInvestigationReport();

      createReport
        .api({
          data: {
            ...inputValues,
            customer: customerId,
            custom: customInputValues.length > 0 ? customInputValues : [],
            date: date2,
          },
        })
        .then((response) => {
          if (response) {
            alert("Investigation report created successfully ");
            formRef.current && formRef.current.reset();
            setDate("");
          } else {
            alert("Cannot submit report. Please try again later");
          }
        });
    }
  };

  const handleDateChange = (e) => {
    setDate(e.target.value);
  };
  const updateStatus = (e) => {
    // e.preventDefault();
    console.log("hello");
    console.log(e);
    history.push(`/inital/${customerId} `);
    window.location.reload();
  };

  const fetchInvestigationReport = () => {
    const getReport = getInvestigationReport();

    console.log(date2);
    console.log(date);
    getReport
      .api({
        params: {
          date: date2,
          customer: customerId,
        },
      })
      .then((response) => {
        // console.log(date2);
        if (response) {
          if (Object.keys(response.investigation_data).length > 0) {
            setInvestigationDetails(response);

            Object.entries(inputRef.current).map(([name, input]) => {
              if (name && input && response.investigation_data[name]) {
                if (name === "criticallity") {
                  input.value =
                    response.investigation_data["criticallity_value"];
                } else {
                  input.value = response.investigation_data[name];
                }
              }
            });

            if (
              response.custom_investigation &&
              response.custom_investigation.length > 0
            ) {
              const customInputs = response.custom_investigation.map(
                (each) => ({
                  name: each.name,
                  [`value-${each.name}`]: each.value,
                  [`normal_range-${each.name}`]: each.normal_range,

                  key: String(each.id),
                  // WARNING: These are used for json keys in req body, value and normal_range
                  valueInputName: `value-${each.name}`,
                  normalRangeInputName: `normal_range-${each.name}`,
                  id: each.id,
                })
              );
              console.log("customInputs", customInputs);

              setCustomFields(customInputs);
            }
          } else {
            if (
              !!investigationDetails.investigation_data &&
              Object.keys(investigationDetails.investigation_data).length > 0
            ) {
              setInvestigationDetails({});
              formRef.current && formRef.current.reset();
              setCustomFields([]);
            }
          }
        }
      });

    return getReport;
  };

  const addNewCustomInvestigateField =
    (fieldName = "") =>
    () => {
      if (fieldName.length > 0) {
        if (customFields.findIndex((each) => each.name === fieldName) > -1) {
          alert("Field already added. Please choose a different name");
          return;
        }

        setCustomFields(
          customFields.concat({
            key: String(customFields.length + 1),
            name: fieldName,
            // WARNING: These are used for json keys in req body, value and normal_range
            valueInputName: `value-${fieldName}`,
            normalRangeInputName: `normal_range-${fieldName}`,
          })
        );
      }
    };

  // TODO: add delete API here

  const removeCustomInvestigateField = (index, id) => () => {
    if (id) {
      const deleteCustom = deleteCustomInvestigation();

      deleteCustom.api({
        data: {
          id,
        },
      });
    }

    const newFieldArray = customFields.concat();

    const removed = newFieldArray.splice(index, 1).concat().pop();

    console.log(
      newFieldArray,
      "newFieldArray",
      removed,
      customInputRef.current[removed.name]
    );

    if (customInputRef.current) {
      delete customInputRef.current[removed.name];
    }

    setCustomFields(newFieldArray);
  };

  const Input = (item, index) => {
    customInputRef.current[item.name] = {};

    console.log(customInputRef.current[item.name], customInputRef.current);

    return (
      <tr key={item.key || index}>
        <td>{item.name}</td>
        <td>
          <input
            name={item.valueInputName}
            ref={(el) => {
              if (customInputRef.current[item.name]) {
                customInputRef.current[item.name][el?.name] = el;
              }
            }}
            style={styles.input}
            type="text"
          />
        </td>
        <td>
          <input
            name={item.normalRangeInputName}
            ref={(el) => {
              if (customInputRef.current[item.name]) {
                customInputRef.current[item.name][el?.name] = el;
              }
            }}
            style={styles.input}
            type="text"
          />
        </td>
        <td>
          <button
            type="button"
            onClick={removeCustomInvestigateField(index, item.id)}
          >
            Del
          </button>
        </td>
      </tr>
    );
  };

  React.useEffect(() => {
    if (customerId && date2) {
      console.log("date");
      const requestToken = fetchInvestigationReport();

      return () => {
        requestToken.cancel();
      };
    }
  }, [date, customerId, date2]);

  React.useEffect(() => {
    if (
      customFields.length > 0 &&
      investigationDetails.custom_investigation &&
      investigationDetails.custom_investigation.length > 0
    ) {
      if (customInputRef.current) {
        // console.log("effrt");
        investigationDetails.custom_investigation.map((each) => {
          if (customInputRef.current[each.name]) {
            const inputValue =
              customInputRef.current[each.name][`value-${each.name}`];

            const inputNormalRange =
              customInputRef.current[each.name][`normal_range-${each.name}`];

            if (inputValue) {
              inputValue.value = each.value;
            }

            if (inputNormalRange) {
              inputNormalRange.value = each.normal_range;
            }
          }
        });
      }
    }
  }, [JSON.stringify(customFields), date2]);

  return (
    <>
      <form
        ref={formRef}
        action="#"
        onSubmit={handleSubmit}
        style={styles.margiiner}
      >
        <div style={styles.wrapper} className="lightgradient2">
          <p className=" margin-toper">
            <strong>Investigation Report</strong>
          </p>

          {investigate ? (
            <AddNewReport
              open={!!investigate}
              addNewField={addNewCustomInvestigateField}
              closeDialog={closeDialog}
            />
          ) : null}
          <table>
            <tr>
              <th>
                <input
                  className="calanderbutton"
                  name="date"
                  onChange={handleDateChange}
                  value={date2}
                  style={{
                    textTransform: "uppercase",
                    // background: "#540156",
                    color: "#fff",
                  }}
                  type="date"
                />
              </th>
              <th>
                <button className="pinkbutton" onClick={() => updateStatus()}>
                  Initial Follow Up
                </button>
              </th>
              <th>
                <Link
                  to={{
                    pathname: "/othersymptoms/" + customerId,
                  }}
                  // onClick={OpenInner}
                >
                  {" "}
                  <button className="pinkbutton">Other Symptoms</button>
                </Link>
              </th>
              <br />
              {/* <th>
                <Link
                  to={{
                    pathname: "/medcalrecords/" + customerId,
                  }}
                  // onClick={OpenInner}
                >
                  <button className="pinkbutton">View medical Data</button>
                </Link>
              </th> */}
            </tr>
            <Link
              to={{
                pathname: "/medcalrecords/" + customerId,
              }}
              // onClick={OpenInner}
            >
              <button className="pinkbutton">View Medical Data</button>
            </Link>
            <tr>
              <th></th>
              <th>Enter Value</th>
              <th>Normal Range</th>
            </tr>
            <tr>
              <td>Hb</td>

              <td>
                <input
                  name="hb_value"
                  ref={(el) => {
                    inputRef.current[el?.name] = el;
                  }}
                  style={styles.input}
                  type="text"
                />
              </td>
              <td>
                <input
                  name="hb_normal"
                  ref={(el) => {
                    inputRef.current[el?.name] = el;
                  }}
                  style={styles.input}
                  type="text"
                />
              </td>
            </tr>

            <tr>
              <td>ICT</td>

              <td>
                <input
                  name="ict_value"
                  ref={(el) => {
                    inputRef.current[el?.name] = el;
                  }}
                  style={styles.input}
                  type="text"
                />
              </td>
              <td>
                <input
                  name="ict_normal"
                  ref={(el) => {
                    inputRef.current[el?.name] = el;
                  }}
                  style={styles.input}
                  type="text"
                />
              </td>
            </tr>

            <tr>
              <td>Urine R/E</td>
              <td>
                <input
                  name="urineRE_value"
                  ref={(el) => {
                    inputRef.current[el?.name] = el;
                  }}
                  style={styles.input}
                  type="text"
                />
              </td>
              <td>
                <input
                  name="urineRE_normal"
                  ref={(el) => {
                    inputRef.current[el?.name] = el;
                  }}
                  style={styles.input}
                  type="text"
                />
              </td>
            </tr>

            <tr>
              <td>Urine C/S</td>
              <td>
                <input
                  name="urineCS_value"
                  ref={(el) => {
                    inputRef.current[el?.name] = el;
                  }}
                  style={styles.input}
                  type="text"
                />
              </td>
              <td>
                <input
                  name="urineCS_normal"
                  ref={(el) => {
                    inputRef.current[el?.name] = el;
                  }}
                  style={styles.input}
                  type="text"
                />
              </td>
            </tr>

            <tr>
              <td>VDRL</td>
              <td>
                <input
                  name="vdrl_value"
                  ref={(el) => {
                    inputRef.current[el?.name] = el;
                  }}
                  style={styles.input}
                  type="text"
                />
              </td>
              <td>
                <input
                  name="vdrl_normal"
                  ref={(el) => {
                    inputRef.current[el?.name] = el;
                  }}
                  style={styles.input}
                  type="text"
                />
              </td>
            </tr>

            <tr>
              <td>HIV</td>
              <td>
                <input
                  name="hiv_value"
                  ref={(el) => {
                    inputRef.current[el?.name] = el;
                  }}
                  style={styles.input}
                  type="text"
                />
              </td>
              <td>
                <input
                  name="hiv_normal"
                  ref={(el) => {
                    inputRef.current[el?.name] = el;
                  }}
                  style={styles.input}
                  type="text"
                />
              </td>
            </tr>

            <tr>
              <td>RBS first trimester</td>
              <td>
                <input
                  name="rbs_first_trimester_value"
                  ref={(el) => {
                    inputRef.current[el?.name] = el;
                  }}
                  style={styles.input}
                  type="text"
                />
              </td>
              <td>
                <input
                  name="rbs_first_trimester_normal"
                  ref={(el) => {
                    inputRef.current[el?.name] = el;
                  }}
                  style={styles.input}
                  type="text"
                />
              </td>
            </tr>
            <tr>
              <td>OGCT 2nd trimester- if raised</td>
              <td>
                <input
                  name="ogct_second_trimester_value"
                  ref={(el) => {
                    inputRef.current[el?.name] = el;
                  }}
                  style={styles.input}
                  type="text"
                />
              </td>
              <td>
                <input
                  name="ogct_second_trimester_normal"
                  ref={(el) => {
                    inputRef.current[el?.name] = el;
                  }}
                  style={styles.input}
                  type="text"
                />
              </td>
            </tr>

            <tr>
              <td>OGTT +/- FBS-PPBS</td>
              <td>
                <input
                  name="ogtt_second_trimester_value"
                  ref={(el) => {
                    inputRef.current[el?.name] = el;
                  }}
                  style={styles.input}
                  type="text"
                />
              </td>
              <td>
                <input
                  name="ogtt_second_trimester_normal"
                  ref={(el) => {
                    inputRef.current[el?.name] = el;
                  }}
                  style={styles.input}
                  type="text"
                />
              </td>
            </tr>

            <tr>
              <td>Hcv</td>
              <td>
                <input
                  name="hcv_value"
                  ref={(el) => {
                    inputRef.current[el?.name] = el;
                  }}
                  style={styles.input}
                  type="text"
                />
              </td>
              <td>
                <input
                  name="hcv_normal"
                  ref={(el) => {
                    inputRef.current[el?.name] = el;
                  }}
                  style={styles.input}
                  type="text"
                />
              </td>
            </tr>

            <tr>
              <td>Creatinine</td>
              <td>
                <input
                  name="creatine_value"
                  ref={(el) => {
                    inputRef.current[el?.name] = el;
                  }}
                  style={styles.input}
                  type="text"
                />
              </td>
              <td>
                <input
                  name="creatine_normal"
                  ref={(el) => {
                    inputRef.current[el?.name] = el;
                  }}
                  style={styles.input}
                  type="text"
                />
              </td>
            </tr>

            <tr>
              <td>Double Marker</td>
              <td>
                <input
                  name="double_marker_value"
                  ref={(el) => {
                    inputRef.current[el?.name] = el;
                  }}
                  style={styles.input}
                  type="text"
                />
              </td>
              <td>
                <input
                  name="double_marker_normal"
                  ref={(el) => {
                    inputRef.current[el?.name] = el;
                  }}
                  style={styles.input}
                  type="text"
                />
              </td>
            </tr>

            <tr>
              <td>TFT</td>
              <td>
                <input
                  name="tft_value"
                  ref={(el) => {
                    inputRef.current[el?.name] = el;
                  }}
                  style={styles.input}
                  type="text"
                />
              </td>
              <td>
                <input
                  name="tft_normal"
                  ref={(el) => {
                    inputRef.current[el?.name] = el;
                  }}
                  style={styles.input}
                  type="text"
                />
              </td>
            </tr>
            <tr>
              <td></td>
              <textarea
                name="tft_description"
                ref={(el) => {
                  inputRef.current[el?.name] = el;
                }}
                style={styles.input}
              >
                Description
              </textarea>
            </tr>

            <tr>
              <td>Other</td>
              <td>
                <input
                  name="others_value"
                  ref={(el) => {
                    inputRef.current[el?.name] = el;
                  }}
                  style={styles.input}
                  type="text"
                  placeholder="Enter value"
                />
              </td>
              <td>
                <input
                  name="others_normal"
                  ref={(el) => {
                    inputRef.current[el?.name] = el;
                  }}
                  style={styles.input}
                  type="text"
                />
              </td>
            </tr>
            {/* <tr>
              <td></td>
              <textarea
                name="others_description"
                ref={(el) => {
                  inputRef.current[el?.name] = el;
                }}
                style={styles.textarea}
              >
                {" "}
                Description..
              </textarea>
            </tr> */}

            <tr>
              <td>Criticality</td>
              <td>
                <select
                  name="criticallity"
                  ref={(el) => {
                    inputRef.current[el?.name] = el;
                  }}
                >
                  <option value="stable" style={styles.green}>
                    Stable
                  </option>
                  <option value="low risk" style={styles.yellow}>
                    Low Risk
                  </option>
                  <option value="high risk" style={styles.red}>
                    High Risk
                  </option>
                </select>
              </td>
              <td></td>
            </tr>

            <tr>
              <td>type of scan</td>
              <td>
                <input
                  name="scan"
                  ref={(el) => {
                    inputRef.current[el?.name] = el;
                  }}
                  style={styles.input}
                  type="text"
                />
              </td>
            </tr>
            <tr>
              <td>result</td>
              <td>
                <textarea
                  name="scanDescription"
                  ref={(el) => {
                    inputRef.current[el?.name] = el;
                  }}
                  style={styles.input}
                ></textarea>
              </td>
            </tr>

            {customFields.map(Input)}
          </table>

          <hr style={{ color: "#fff" }} />
          <div style={{ cursor: "pointer" }} onClick={addNewReport}>
            <span>Add new Investigation</span>
          </div>
          <button className="pinkbutton" type="submit">
            Submit
          </button>
          <div>
            <button style={styles.sbtn} type="button" onClick={closeReporter}>
              Close
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

const styles = {
  input: {
    background:
      "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
    border: "none",
    color: "#fff",
    padding: "8px",
  },
  margiiner: { margin: "auto 10px" },

  wrapper: {
    height: "40rem",
    overflowY: "scroll",
    scrollbarWidth: "none",
    // background: "#540156",
    borderRadius: "10px",
  },
  green: { background: "green", color: "green" },
  red: { background: "red", color: "red" },
  yellow: { background: "yellow", color: "yellow" },

  sbtn: {
    margin: "1rem 0rem",
    textTransform: "uppercase",
    background: "rgb(116 110 201 / 0%)",
    border: "1px solid rgb(255 255 255)",
    padding: "10px 30px",
    cursor: "pointer",
    // background: "#746EC9",
    // border: "1px solid #746EC9",
    color: "#fff",
    borderRadius: "10px",
  },

  textarea: {
    width: "97%",
    background: "#540156",
    color: "#fff",
    resize: "none",
  },
};
