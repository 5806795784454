import React, { useState, createContext } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import TrackerByDate from "./traker_by_date/tabs";
import { useEffect } from "react";
import axios from "axios";

export default function Calender() {
  // const [value] = useState(new Date());
  const [show, setTracker] = useState(false);
  const [dateer, setdateer] = useState();
  const [dateState, setDateState] = useState(new Date());
  const [membership, setMembership] = useState(null);
  const [sub, setsub] = useState();

  useEffect(() => {
    setsub(localStorage.getItem("subscibtion"));
    // Define your axios configuration here, if needed
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("token"),
      },
    };

    // Call the API using axios.get in useEffect
    const dashboardData=JSON.parse(localStorage.getItem('dashboard_data'))
    if (dashboardData){
      setMembership(dashboardData.subscription_package)
    }
    else{
    axios
      .get("customer/customer-dashboard-details/", config)
      .then((res) => {
        setMembership(res.data.subscription_package.membership);
        localStorage.setItem('dashboard_data',JSON.stringify(res.data))
      })
      .catch((error) => {
        // Handle errors if needed
        console.error("Error fetching data:", error);
      });}
  }, []);
  const changeDate = (e) => {
    setDateState(e);
    // setdateer(moment(dateState).format("YYYY-MM-DD"));
  };

  //console.log(dateState);
  var pikerdate = moment(dateer).format("YYYY-MM-DD");
  //console.log(dateState);
  function clickTracker(e) {
    //  setdateer(moment(dateState).format("YYYY-MM-DD"));
    setTracker(!show);
    setdateer(e);
  }

  return (
    <>
      {show ? <TrackerByDate dataFromParent={pikerdate} /> : null}
      {/* {membership === "Trial Plan" ? null : ( */}
      {sub === "false" ? null : (
        <div style={{ width: "260px" }} className="res-calendar">
          <Calendar
            className={["c1", "c2"]}
            onChange={changeDate}
            onClickDay={clickTracker}
            value={dateState}
          
          />
        </div>
      )}
    </>
  );
}
