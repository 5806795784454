import React from "react";
import axios from "axios";
//import { Redirect } from 'react-router-dom';

import Sidebar from "../components/sidebar";
import { getSalesDashboardDetails } from "../../helpers/service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faUser, faBars } from "@fortawesome/free-solid-svg-icons";
import Toggler from "../toggle";

import ClientProfile from "../toggle_profile";
//import DailyTrackerTabs from './components/daily_tracker';

//================ Importing Components ======
import Doclist from "./appointments";
// import Greet from "../../components/greet";
import ClientNoupdates from "../components/clients_no_updates";
import AlltimeClients from "../components/alltime_clients";
import TClients from "../components/clients";

export default class DoctorOverview extends React.Component {
  constructor(props) {
    super(props);
    this.fetchSalesDashboardDetails = getSalesDashboardDetails();
    this.state = {
      isToggleOn: true,
      isProfile: true,
      token: "",
      items: [],
      details: {},
    };
    this.handleClick = this.handleClick.bind(this);
    this.setWrap = this.setWrap.bind(this);
    this.clickedOutside = this.clickedOutside.bind(this);
    this.profileHandler = this.profileHandler.bind(this);
  }

  handleClick() {
    this.setState((prevState) => ({
      isToggleOn: !prevState.isToggleOn,
    }));
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.clickedOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.clickedOutside);
  }

  profileHandler() {
    this.setState({ isProfile: !this.state.isProfile });
  }

  setWrap(node) {
    this.isWap = node;
  }

  clickedOutside(e) {
    if (this.isWap && !this.isWap.contains(e.target)) {
      this.setState({ isToggleOn: true });
      this.setState({ isProfile: true });
    }
  }

  componentDidMount() {
    if (!localStorage.getItem("token")) {
      localStorage.clear();
      window.location = "/clogin";
      return;
    }

    this.fetchSalesDashboardDetails.api().then((response) => {
      if (response) {
        this.setState((prevState) => ({
          ...prevState,
          details: response,
          filteredTableData: response.totalPatients_details,
        }));
      }
    });
  }

  componentWillUnmount() {
    // Cancels the API while cleanup
    this.fetchSalesDashboardDetails.cancel();
  }
  render() {
    function openMenu() {
      document.querySelector(".sidebar").classList.toggle("show");
    }

    // if (localStorage.getItem("doc_token") === null) {
    //   localStorage.clear();
    //   window.location = "/clogin";
    // }

    return (
      <>
        <div className="App">
          <Sidebar />
          <header className="overview">
            <div
              className="grandiant"
              ref={this.setWrap}
              style={{ padding: "2% 10%", textAlign: "right" }}
            >
              {" "}
              <div>{this.state.isToggleOn ? null : <Toggler />}</div>
              <span className="iconsofgradiant">
                <FontAwesomeIcon
                  className="iconsofprofile"
                  onClick={this.handleClick}
                  style={{ marginRight: "1rem", cursor: "pointer" }}
                  icon={faBell}
                />
              </span>
              <span className="iconsofgradiant">
                <FontAwesomeIcon
                  className="iconsofprofile"
                  onClick={this.profileHandler}
                  style={{ cursor: "pointer" }}
                  icon={faUser}
                />
              </span>{" "}
              <span>
                {`${
                  !!this.state.details.firstname
                    ? this.state.details.firstname
                    : ""
                } ${
                  !!this.state.details.lastname
                    ? this.state.details.lastname
                    : ""
                }`.trim()}
              </span>
              {this.state.isProfile ? null : <ClientProfile />}{" "}
            </div>

            <div style={{ width: "89%", display: "flex", paddingLeft: "10px" }}>
              <span onClick={openMenu} className="menu">
                <FontAwesomeIcon
                  style={{ marginRight: "20px" }}
                  icon={faBars}
                  className="menu-icon"
                />
              </span>

              <span style={{ whiteSpace: "nowrap" }}>appointments</span>
              <div style={{ padding: "4px", width: "100%" }}>
                <hr style={{ background: "white", color: "white" }} />
              </div>
            </div>
            <div className="mwrapper">
              <div className="mleft docmright">
                {" "}
                <Doclist />{" "}
              </div>
              <div className="mright ">
                <ClientNoupdates
                  count={this.state.details.noUpdateFromClientsCount}
                />
                <TClients
                  count={this.state.details.this_month_patients_count}
                />
                <AlltimeClients
                  count={this.state.details.total_patients_count}
                />
              </div>
            </div>
          </header>
        </div>
      </>
    );
  }
}
