import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUtensils,
  faPills,
  faRunning,
  faHiking,
  faCompressArrowsAlt,
  faHeadSideCough,
  faFileMedical,
} from "@fortawesome/free-solid-svg-icons";

import imag1 from "./dailyimg/AC308FA7.png";
import imag2 from "./dailyimg/activity.png";
import imag3 from "./dailyimg/contraction.png";
import imag4 from "./dailyimg/med.png";
import imag5 from "./dailyimg/medical.png";
import imag7 from "./dailyimg/Exersie.png";
import imag6 from "./dailyimg/Symptoms.png";
// import imag1 from "./img/execise.svg";

import { Link } from "react-router-dom";

export default function Dailytracker() {
  return (
    <>
      <div className="box boxstyle" style={styles.box}>
        <Link style={styles.links} to="/cdiet">
          <div className="innerbox" style={styles.innerbox}>
            <img src={imag1} style={styles.icon} alt="" />
            {/* <FontAwesomeIcon icon={faUtensils} style={styles.icon} /> */}
            <br />
            <span className="Titlemobile" style={styles.title}>
              Diet
            </span>
          </div>
        </Link>

        <Link style={styles.links} to="/cmedicine">
          <div className="innerbox" style={styles.innerbox}>
            <img src={imag4} style={styles.icon} alt="" />
            {/* <FontAwesomeIcon icon={faPills} style={styles.icon} /> */}
            <br />
            <span className="Titlemobile" style={styles.title}>
              Medicine
            </span>
          </div>
        </Link>

        <Link style={styles.links} to="/cexercise">
          <div className="innerbox" style={styles.innerbox}>
            <img src={imag7} style={styles.icon} alt="" />
            {/* <FontAwesomeIcon icon={faRunning} style={styles.icon} /> */}
            <br />
            <span className="Titlemobile" style={styles.title}>
              Exercise
            </span>
          </div>
        </Link>

        <Link style={styles.links} to="/cactivity">
          <div className="innerbox" style={styles.innerbox}>
            <img src={imag2} style={styles.icon} alt="" />
            {/* <FontAwesomeIcon icon={faHiking} style={styles.icon} /> */}
            <br />
            <span className="Titlemobile" style={styles.title}>
              Activity
            </span>
          </div>
        </Link>

        <Link style={styles.links} to="/ccontraction">
          <div className="innerbox" style={styles.innerbox}>
            <img src={imag3} style={styles.icon} alt="" />
            {/* <FontAwesomeIcon icon={faCompressArrowsAlt} style={styles.icon} /> */}
            <br />
            <span className="Titlemobile" style={styles.title}>
              Contraction
            </span>
          </div>
        </Link>

        <Link style={styles.links} to="/csymptoms">
          <div className="innerbox" style={styles.innerbox}>
            <img src={imag6} style={styles.icon} alt="" />
            {/* <FontAwesomeIcon icon={faHeadSideCough} style={styles.icon} /> */}
            <br />
            <span className="Titlemobile" style={styles.title}>
              Symptoms
            </span>
          </div>
        </Link>

        <Link style={styles.links} to="/cmedical">
          <div className="innerbox" style={styles.innerbox}>
            <img src={imag5} style={styles.icon} alt="" />
            {/* <FontAwesomeIcon icon={faFileMedical} style={styles.icon} /> */}
            <br />
            <span className="Titlemobile" style={styles.title}>
              Medical records
            </span>
          </div>
        </Link>
      </div>
    </>
  );
}

const styles = {
  box: {
    overflowY: "scroll",
    scrollbarWidth: "none",
    padding: "10px",
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    margin: "auto",
    width: "83%",
  },

  innerbox: {
    padding: "10px 0px",
    margin: "30px",
    flex: "1 0 10%",
    // float: "left",
    width: "144px",
    height: "118px",
    background: "#ffffff1c 0% 0% no-repeat padding-box",
    borderRadius: "10px",
  },

  icon: {
    width: "4.6rem",
    // margin: "0 auto",
    cursor: "pointer",
  },

  title: {
    display: "inline-block",
    minWidth: "100%",
    background: "#e81a7f",
    marginTop: "1.2rem",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    textTransform: "uppercase",
    fontSize: "14px",
    padding: "5px 0px",
  },

  module: {
    position: "absolute",
  },
};
