import axios from "axios";
import React, { useState, useEffect } from "react";
export default function EditPersonal(props) {
  const { id } = props;

  // Console.log the 'id'
  console.log("ID:", id);
  const [data, setdata] = useState([]);
  const [details, setdetails] = useState([]);
  const [allValues, setAllValues] = useState();
  const [image, setImage] = useState();
  const changeHandler = (e) => {
    setAllValues({ ...allValues, [e.target.name]: e.target.value });
  };
  // const onChange = (e) => {
  //   // let files = files;
  //   setAllValues({ ...allValues, [e.target.name]: e.target.files[0] });
  // };

  const FileHandler = (e) => {
    // let files = files;
    setImage(e.target.files[0]);
    console.log(e.target.files[0]);
    // setImage(e.target.files[0]);
  };

  const submitValue = (e) => {
    console.log(allValues);
    e.preventDefault();
    const formData = new FormData();
    if (image != undefined) {
      formData.append("prescription", image);
    }
    if (allValues != null) {
      Object.entries(allValues).forEach(([key, value]) =>
        formData.append(key, value)
      );
    }
    // setAllValues({ ...allValues, formData });
    const config = {
      headers: {
        Authorization: `Token ${sessionStorage.getItem("token")}`,
        // "Content-type": "multipart/form-data",
        // "Content-Type": "application/json",
        // "Content-Type": "application/json",
        // "Content-Type": "application/x-www-form-urlencoded",
        // Accept: "application/json",

        // "Content-Type": "application/json",
        // "Content-Type": "application/json;odata=verbose",
      },
    };
    console.log(image);
    console.log(allValues);
    axios
      .patch("profile-update/", formData, config)
      .then(() => {
        alert("updated data");
      })
      .catch((error) => {
        alert(JSON.stringify(error.response));
      });

    // console.log(allValues);
  };
  useEffect(() => {
    axios
      .get(`http://127.0.0.1:8000/customer-profile/`, {
        headers: {
          Authorization: `Token ${localStorage.getItem("admin_token")}`,
        },
        params: {
          customer: localStorage.getItem('id'),
        },
      })
      .then((res) => {
        //  console.log(res.data.details);
        setdata(res.data.customer);
        setdetails(res.data.details);
      });
  }, []);
  return (
    <>
      <div style={styles.wrapper}>
        {/* {files} */}
        <form className="medical-edit-form" style={styles.form}>
          <small style={styles.datehelp}>
            <label>Married Since</label>
            <input
              name="marriedSince"
              style={styles.date}
              onChange={changeHandler}
              defaultValue={details.marriedSince}
              type="date"
            />
          </small>
          <small style={styles.datehelp}>
            <label>How Many Baby Do You Have?</label>
            <input
              name="babies_number"
              onChange={changeHandler}
              style={styles.date}
              type="text"
              defaultValue={details.babies_number}
              placeholder=""
            />
          </small>
          <small style={styles.datehelp}>
            <label>Any History Of Abortions? Yes or No</label>
            <input
              onChange={changeHandler}
              name="abortions"
              style={styles.date}
              type="text"
              defaultValue={details.abortions}
              placeholder="Any History Of Abortions? Yes or No"
            />
          </small>
          <small style={styles.datehelp}>
            <label>Any History of twins in Family? Yes or No</label>
            <input
              onChange={changeHandler}
              name="twins"
              style={styles.date}
              type="text"
              defaultValue={details.twins}
              placeholder="Any History of twins in Family? Yes or No"
            />
          </small>
          <small style={styles.datehelp}>
            <label> Any Family History of Diabetes ? Yes or No</label>
            <input
              onChange={changeHandler}
              name="diabetes"
              style={styles.date}
              type="text"
              defaultValue={details.diabetes}
              placeholder="Any Family History of Diabetes ? Yes or No"
            />
          </small>
          <small style={styles.datehelp}>
            <label>
              {" "}
              Any History Of allergic reaction? If Yes please Specify.
            </label>

            <input
              onChange={changeHandler}
              name="allergic_reaction"
              style={styles.date}
              type="text"
              defaultValue={details.allergic_reaction}
              placeholder="Any History Of allergic reaction? If Yes please Specify."
            />
          </small>
          <small style={styles.datehelp}>
            <label>Any History Of Surgery? If Yes, please Specify.</label>
            <input
              onChange={changeHandler}
              name="surgery"
              style={styles.date}
              type="text"
              defaultValue={details.surgery}
              placeholder="Any History Of Surgery? If Yes, please Specify."
            />
          </small>
          <small style={styles.datehelp}>
            <label>How many days Menstruation Period cycle</label>
            <input
              onChange={changeHandler}
              name="Menstruation"
              style={styles.date}
              type="number"
              defaultValue={details.Menstruation}
            />
          </small>
          <small style={styles.datehelp}>
            <label>last Menstruation date</label>
            <input
              name="Menstruation_date"
              onChange={changeHandler}
              defaultValue={details.Menstruation_date}
              style={styles.date}
              type="date"
            />
          </small>
          <small style={styles.datehelp}>
            <label> Any Heridity related complications?</label>
            <input
              onChange={changeHandler}
              name="hereditory"
              style={styles.date}
              defaultValue={details.hereditory}
              type="text"
              placeholder="Any Heridity related complications?"
            />
          </small>
          <small style={styles.datehelp}>
            <label>
              Any History Of Gynacology or Fertility Treatment? If Yes, please
              Specify.
            </label>

            <input
              onChange={changeHandler}
              name="gynacology"
              style={styles.date}
              type="text"
              defaultValue={details.gynacology}
              placeholder="Any History Of Gynacology or Fertility Treatment? If Yes, please Specify."
            />
          </small>
          <small style={styles.datehelp}>
            <label>How many baby are you pregnent with currently?</label>
            <input
              onChange={changeHandler}
              name="no_of_babies_pregnant_with"
              style={styles.date}
              type="text"
              defaultValue={details.no_of_babies_pregnant_with}
              placeholder="How many baby are you pregnent with currently?"
            />
          </small>
          <small style={styles.datehelp}>
            <label> When did you visit doctor finally?</label>
            <input
              onChange={changeHandler}
              name="doctor_final_visit"
              style={styles.date}
              defaultValue={details.doctor_final_visit}
              type="date"
            />
          </small>
          <small style={styles.datehelp}>
            <label>
              {" "}
              Upload latest prescription report or any blood report that clearly
              indicates that you are pregnant{" "}
            </label>{" "}
            <label style={styles.date} for="file">
              {details.Idproof ? details.Idproof : "Choose a file"}
            </label>
            {/* <input
              type="file"
              name="file"
              onChange={FileHandler}
              className="inputfile"
              style={{ background: "rgb(139 72 143 / 0%)" }}
            /> */}
          </small>
          <div>
            <input
              onClick={submitValue}
              style={styles.ebtn}
              className="pinkbutton2"
              type="submit"
              value="Update"
            />
          </div>{" "}
          <input
            id="file"
            style={styles.file}
            name="prescription"
            onChange={FileHandler}
            type="file"
            className="inputfile"
          />
        </form>
      </div>
    </>
  );
}

const styles = {
  wrapper: {
    padding: "5px",
  },

  form: {
    marginTop: "5%",
    position: "relative",
    height: "274px",
    // overflowY: "scroll",
    scrollbarWidth: "none",
    // width: "400px",
    display: "flex",
    flexDirection: "column",
    zIndex: 10,
    top: "10rem",
    right: "25rem",

    left: "50%",
    transform: "translate(-50%, -50%)",
    // background: "#ffffff1c 0% 0% no-repeat padding-box",
    padding: "20px",
  },

  date: {
    textAlign: "center",
    border: "none",
    color: "#fff",
    float: "right",

    outline: "none",
    // background: "transparent",
    background:
      "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
  },

  fileP: {
    width: "80%",
    display: "block",
  },
  fileP1: {
    width: "60%",
    display: "block",
  },

  file: {
    border: "none",
    background: "transparent",
    background:
      "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
    float: "right",
  },

  datehelp: {
    marginBottom: "20px",
    padding: "8px",
    textAlign: "left",
    fontSize: "12px",
    // background: "#530e73",
    color: "#fff",
  },

  filehelp: {
    marginBottom: "20px",
    padding: "8px",
    textAlign: "left",
    fontSize: "12px",
    background: "#530e73",
    color: "#fff",
    display: "flex",
  },

  filehelp1: {
    marginBottom: "20px",
    padding: "8px",

    textAlign: "left",
    fontSize: "12px",
    background: "#530e73",
    color: "#fff",
    display: "flex",
  },

  input: {
    marginBottom: "20px",
    // background: "#530e73",
    background: "rgb(83 14 115 / 39%)",
    color: "#fff",
    padding: "5px",
  },

  ebtn: {
    marginBottom: "2rem",

    // background: "linear-gradient(90deg, #3a51a7 30%, #b53899)",
    padding: "10px 30px",
    border: "none",
    borderRadius: "10px",
    color: "#fff",
    cursor: "pointer",
  },
  input2: {
    marginBottom: "20px",
    // background: "#530e73",
    width: "25%",
    background:
      "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
    color: "#fff",
    padding: "5px",
    borderBottom: "1px solid #fff",
    borderTop: "none",
    borderLeft: "none",
    textAlign: "left",
    fontSize: "14px",
    borderRight: "none",
  },
};
