import Image from "../../img/small_image.jpeg";
import { useEffect, useState } from "react";
import DailyTracker from "../traker_by_date/table_list";
import axios from "axios";

export default function PersonalDetail({ id }) {
  const [tracker, setTracker] = useState(false);
  const [customer, setCustomer] = useState("");
  const [details, setDetails] = useState("");

  function openTracker() {
    setTracker(!tracker);
  }
  useEffect(() => {
    axios
      .get(`customer-profile/`, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
        params: {
          customer: id,
        },
      })
      .then((res) => {
        console.log(res.data);
        setCustomer(res.data.customer);
        setDetails(res.data.details);
      });
  }, []);

  // destructure data from states
  let { firstname, lastname, email, mobile, doctor, patient } = customer;
  let {
    address,
    Idproof,
    husband,
    job,
    age,
    marriedSince,
    babies_number,
    abortions,
    twins,
    diabetes,
    allergic_reaction,
    surgery,
    Menstruation,
    Menstruation_date,
    hereditory,
  } = details;

  return (
    <>
      {tracker ? <DailyTracker id={id} /> : null}
      <table className="personal-detail ">
        <tr>
          <td>
            {customer.profile_pic !=
            "https://sukhprasavam.shebirth.com/media//ProfilePic/default.jpg" ? (
              <img
                height="75px"
                width="75px"
                style={{ borderRadius: "50%" }}
                className="avatar"
                src={customer.profile_pic}
                alt=""
              />
            ) : customer.profile_pic ==
              "https://sukhprasavam.shebirth.com/media//ProfilePic/default.jpg" ? (
              <button
                className="avatar aquamarine "
                style={{ maxWidth: "65px", border: "none" }}
              ></button>
            ) : (
              <button
                className="avatar aquamarine "
                style={{ maxWidth: "65px", border: "none" }}
              ></button>
            )}
            {/* {customer.profile_pic ? (
              <img
                src={customer.profile_pic}
                className="avatar"
                height="75px"
                width="75px"
                style={{ borderRadius: "50%" }}
              />
            ) : (
              <img
                src={Image}
                height="75px"
                width="75px"
                style={{ borderRadius: "50%" }}
                alt=""
              />
            )} */}
          </td>
          <td style={styles.col}>
            <strong style={{ fontSize: "20px" }}>
              {firstname} {lastname}
            </strong>
            <br />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <small
                style={{ padding: "3px 0px", borderBottom: "1px solid #fff" }}
              >
                Username:{" "}
                <span style={{ marginLeft: "10px" }}>{firstname}</span>
              </small>
              <small
                style={{ padding: "3px 0px", borderBottom: "1px solid #fff" }}
              >
                Female | {age}{" "}
                <span style={{ marginLeft: "10px" }}>{mobile}</span>
              </small>
              <br />
            </div>
          </td>

          <td style={styles.col}>
            {/* <span style={styles.ftext} onClick={openTracker}>
              Daily Tracker Details
            </span> */}
            <br />
          </td>
        </tr>
        <tr>
          <td className="removetd"></td>
          <td className="  " style={styles.co}>
            Fullname
          </td>
          <td className=" cpersonaldeatils ">
            <input
              style={styles.co1}
              type="text"
              value={`${firstname} ${lastname}`}
            />
          </td>
        </tr>
        <tr>
          <td className="removetd"></td>
          <td className="  " style={styles.co}>
            Age
          </td>
          <td className=" cpersonaldeatils ">
            <input style={styles.co1} type="text" value={age} />
          </td>
        </tr>
        <tr>
          <td className="removetd"></td>
          <td className="  " style={styles.co}>
            Email
          </td>
          <td className=" cpersonaldeatils ">
            <input style={styles.co1} type="text" value={email} />
          </td>
        </tr>
        <tr>
          <td className="removetd"></td>
          <td className="  " style={styles.co}>
            Address
          </td>
          <td className=" cpersonaldeatils ">
            <input style={styles.co1} type="text" value={address} />
          </td>
        </tr>
        <tr>
          <td className="removetd"></td>
          <td style={styles.co}>Mobile</td>
          <td className=" cpersonaldeatils ">
            <input style={styles.co1} type="text" value={mobile} />
          </td>
        </tr>
        <tr>
          <td className="removetd"></td>
          <td className="  " style={styles.co}>
            Individual ID proof with photo
          </td>
          <td className=" cpersonaldeatils ">
            <input style={styles.co1} type="text" value={Idproof} />
          </td>
        </tr>
        <tr>
          <td className="removetd"></td>
          <td className="  " style={styles.co}>
            Husband Name
          </td>
          <td className=" cpersonaldeatils ">
            <input style={styles.co1} type="text" value={husband} />
          </td>
        </tr>
        <tr>
          <td className="removetd"></td>
          <td className="  " style={styles.co}>
            Your Job
          </td>
          <td className=" cpersonaldeatils ">
            <input style={styles.co1} type="text" value={job} />
          </td>
        </tr>
      </table>
    </>
  );
}

const styles = {
  co1: {
    border: "none",
    color: "white",
    background: "0% 0% no-repeat padding-box padding-box rgb(255 255 255 / 0%)",
  },
  dprofile: {
    background: "rgb(76, 0, 61) none repeat scroll 0% 0%",
    width: "41.3rem",
    borderRadius: "10px",
    padding: "10px",
  },

  titlePlate: {
    padding: "10px",
    color: "#fff",
  },
  flex: {
    display: "flex",
    justifyContent: "space-around",
    padding: "10px",
  },
  col: {
    padding: "10px",

    textAlign: "left",
  },
  co: {
    padding: "10px",
    textAlign: "left",

    // border: "1px solid #85388E",
    // background: "#85388E",
  },
  c01: { background: "#faebd700", border: "none", color: "white" },
  money: {
    color: "#fff",
  },

  btn: {
    padding: "3px",
    background: "#85388E",
    border: "none",
  },

  fee: {
    padding: "5px",
    background: "transparent",
    outline: "1px solid #85388E",
  },

  ftext: {
    fontSize: "12px",
    padding: "5px",
    background: "#85388E",
    marginRight: "2rem",
    outline: "1px solid #fff",
    cursor: "pointer",
  },
};
