import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Datedata from "./datedata";
import Moment from "moment";
import Customized_diet from "../../innercom/customized_diet";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
export default function DietTracker(props) {
  const [breakfast, setbreakfast] = useState({});
  const [earlymoring, setearlymoring] = useState({});
  const [midday, setmidday] = useState({});
  const [afternoon, setafternoon] = useState({});
  const [dinner, setDinner] = useState({});
  const [allValues, setAllValues] = useState({});
  const [lunch, setlunch] = useState({});
  const [dinnerdrink, setdinnerdrink] = useState({});
  var pikerdate = props.dataFromParent;
  const history = useHistory();
  
  const changeHandler = (e) => {
    setAllValues({ ...allValues, [e.target.name]: e.target.value });
  };
function submit(e){
  
  let url='/diet-date/555'
history.push(url);

}
  const submitValue = (pikerdate) => (e) => {
    e.preventDefault();
  };
  var date = Moment().format("YYYY-MM-DD");

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("token"),
      },
    };

    axios
      .get("customer/diet-tracker-GET/?date=" + pikerdate, config)
      .then((res) => {
        let obj = res.data.Diet.find((o) => o.mealName === "lunch");
        let breakfast = res.data.Diet.find((o) => o.mealName === "breakfast");
        let earlymorning1 = res.data.Diet.find(
          (o) => o.mealName === "early morning"
        );
        let dinner = res.data.Diet.find((o) => o.mealName === "dinner");
        let afternoonsnack = res.data.Diet.find(
          (o) => o.mealName === "afternoon snack"
        );
        let middaysnack = res.data.Diet.find(
          (o) => o.mealName === "mid day snack"
        );
        let dinnerdrink = res.data.Diet.find(
          (o) => o.mealName === "dinner drink"
        );

        setearlymoring(earlymorning1);
        setDinner(dinner);
        setafternoon(afternoonsnack);
        setmidday(middaysnack);
        setdinnerdrink(dinnerdrink);

        setbreakfast(breakfast);
        setlunch(obj);
      });
  }, []);

  return (
    <>
      <div className="scrollerbar">
        
          <table style={{ borderCollapse: "collapse" }}>
            <thead style={{ borderBottom: "5px solid #5c0048" }}>
              <tr
                style={{ background: "#ffffff1c 0% 0% no-repeat padding-box" }}
              >
                <th style={styles.th}>Date</th>
                <th style={styles.th}>Early Morning Energy</th>
                <th style={styles.th}>Breakfast</th>
                <th style={styles.th}>Mid-Day Snack</th>
                <th style={styles.th}>Lunch</th>
                <th style={styles.th}>Afternoon Snack</th>
                <th style={styles.th}>Dinner</th>
                <th style={styles.th}>Dinner Drink</th>
              </tr>
            </thead>

            <tbody>
              <tr
                style={{ background: "#ffffff1c 0% 0% no-repeat padding-box" }}
              >
                <td style={styles.tdFirst}>{pikerdate}</td>
                <td style={styles.td}>
                  <input
                    name="mealType"
                    type="text"
                    onChange={changeHandler}
                    style={styles.inputstyle}
                    defaultValue={earlymoring && earlymoring.food}
                    placeholder={earlymoring && earlymoring.food}
                  />
                  <br />{" "}
                  <input
                    name="mealType"
                    type="time"
                    onChange={changeHandler}
                    style={styles.inputstyle}
                    defaultValue={earlymoring && earlymoring.time}
                    placeholder={earlymoring && earlymoring.time}
                    
                  />
                  <button style={styles.btn} onClick={()=>submit(1)}>Add</button>
                </td>
                <td style={styles.td}>
                  <input
                    name="mealType"
                    type="text"
                    onChange={changeHandler}
                    style={styles.inputstyle}
                    defaultValue={breakfast && breakfast.food}
                    placeholder={breakfast && breakfast.food}
                  />
                  <br />{" "}
                  <input
                    name="mealType"
                    type="time"
                    onChange={changeHandler}
                    style={styles.inputstyle}
                    defaultValue={breakfast && breakfast.time}
                    placeholder={breakfast && breakfast.time}
                  />
                   <button style={styles.btn} onClick={()=>submit(2)}>Add</button>
                </td>
                <td style={styles.td}>
                  <input
                    name="mealType"
                    type="text"
                    onChange={changeHandler}
                    style={styles.inputstyle}
                    defaultValue={midday && midday.food}
                    placeholder={midday && midday.food}
                  />{" "}
                  <br />{" "}
                  <input
                    name="mealType"
                    type="time"
                    onChange={changeHandler}
                    style={styles.inputstyle}
                    defaultValue={midday && midday.time}
                    placeholder={midday && midday.time}
                  />
                  <button style={styles.btn} onClick={()=>submit(3)}>Add</button>
                </td>
                <td style={styles.td}>
                  <input
                    name="mealType"
                    type="text"
                    onChange={changeHandler}
                    style={styles.inputstyle}
                    defaultValue={lunch && lunch.food}
                    placeholder={lunch && lunch.food}
                  />{" "}
                  <br />
                  <input
                    name="mealType"
                    type="time"
                    onChange={changeHandler}
                    style={styles.inputstyle}
                    defaultValue={lunch && lunch.time}
                    placeholder={lunch && lunch.time}
                  />
                  <button style={styles.btn} onClick={()=>submit(4)}>Add</button>
                </td>
                <td style={styles.td}>
                  <input
                    name="mealType"
                    type="text"
                    onChange={changeHandler}
                    style={styles.inputstyle}
                    defaultValue={afternoon && afternoon.food}
                    placeholder={afternoon && afternoon.food}
                  />{" "}
                  <br />
                  <input
                    name="mealType"
                    type="time"
                    onChange={changeHandler}
                    style={styles.inputstyle}
                    defaultValue={afternoon && afternoon.time}
                    placeholder={afternoon && afternoon.time}
                  />
                  <button style={styles.btn} onClick={()=>submit(5)}>Add</button>
                </td>
                <td style={styles.td}>
                  <input
                    name="mealType"
                    type="text"
                    onChange={changeHandler}
                    style={styles.inputstyle}
                    defaultValue={dinner && dinner.food}
                    placeholder={dinner && dinner.food}
                  />
                  <br />
                  <input
                    name="mealType"
                    type="time"
                    onChange={changeHandler}
                    style={styles.inputstyle}
                    defaultValue={dinner && dinner.time}
                    placeholder={dinner && dinner.time}
                  />
                  <button style={styles.btn} onClick={()=>submit(6)}>Add</button>
                </td>
                <td style={styles.td}>
                  <input
                    name="mealType"
                    type="text"
                    onChange={changeHandler}
                    style={styles.inputstyle}
                    defaultValue={dinnerdrink && dinnerdrink.food}
                    placeholder={dinnerdrink && dinnerdrink.food}
                  />
                  <br />
                  <input
                    name="mealType"
                    type="time"
                    onChange={changeHandler}
                    style={styles.inputstyle}
                    defaultValue={dinnerdrink && dinnerdrink.time}
                    placeholder={dinnerdrink && dinnerdrink.time}
                  />
                  <button style={styles.btn} onClick={()=>submit(7)}>Add</button>
                </td>
              </tr>
            </tbody>
          </table>
      
      </div>
    </>
  );
}

const styles = {
  btn:{

  },
  th: {
    fontSize: "17px",
    whiteSpace: "nowrap",
    color: "black",
    padding: "5px 10px",
    margin: "10px 0px",
  },

  td: {
    // borderLeft: "1px solid #fff",
    border: "1px solid black",
    padding: "5px 0px",
    // margin: "0 auto",
    color: "black",
    fontSize: "12px",
  },

  tdFirst: {
    padding: "5px 10px",
    color: "black",
    border: "1px solid black",
    fontSize: "12px",
  },
  inputstyle: {
    background: "#ffffff1c 0% 0% no-repeat padding-box",
    width: "130px",
    border: "none",
    textAlign: "center",
    color: "black",
  },
};
