import Tab from './tabs';

export default function Tablelist(props) {
    return (

        <Tab {...props} />

    );

}




