
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faBook, faCalendarCheck, faCreditCard, faSignOutAlt, faChevronRight } from '@fortawesome/free-solid-svg-icons';


export default function Togprofile (){

return (
<>
<ul style={style.list} className="profile">
<li style={style.listItem}><strong style={ style.listItem}>Alexa</strong></li>
<li style={style.listItem }>
<small>User Name: <span style={{ marginLeft:'10px' }}>Alexa1234</span></small><br/>
<small>Female | 25: <span style={{ marginLeft:'10px' }}>9207060242</span></small>
</li>

<li style={style.listItem}>
<span><FontAwesomeIcon style={{ cursor:'pointer'  }} icon={faUser}/> <strong style={{ marginLeft:'5px' }}>Manage Profile</strong></span> 
<FontAwesomeIcon style={{ float:'right'  }} icon={faChevronRight}/>
</li>
<li style={style.listItem}><FontAwesomeIcon icon={faBook}/><span><strong style={{ marginLeft:'5px' }}>My Orders</strong></span>
<FontAwesomeIcon style={{ float:'right'  }} icon={faChevronRight}/>
</li>
<li style={style.listItem}><FontAwesomeIcon icon={faCalendarCheck}/><span><strong style={{ marginLeft:'5px' }}>My Appointments</strong></span>
<FontAwesomeIcon style={{ float:'right'  }} icon={faChevronRight}/>
</li>
<li style={style.listItem}><FontAwesomeIcon icon={faCreditCard}/><span><strong style={{ marginLeft:'5px' }}>My Payments</strong></span>
<FontAwesomeIcon style={{ float:'right'  }} icon={faChevronRight}/>
</li>
<li style={style.listItem}><FontAwesomeIcon icon={faSignOutAlt} style={{ transform:'rotate(180deg)' }} /><span><strong style={{ marginLeft:'5px' }}>Logout</strong></span></li>
</ul>
</>
);

}


const style = {
list:{
height:'13rem', 
borderRadius:'10px', 
boxShadow:'2px 2px 1px #333',
fontSize:'12px', 
padding:'10px', 
background:'linear-gradient(90deg, #b53899 60%, #3a51a7)', 
textAlign:'left', 
listStyle:'none', 
position:'absolute', 
right:'8.6%', 
top:'7%',
width:'170px',
zIndex:'5'
},

listItem:{
padding:'5px',
borderBottom:'1px solid #fff'

}

}
