import { Chart } from "chart.js";
import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { Bar } from "react-chartjs-2";
// import ChartjsPluginStacked100 from "chartjs-plugin-stacked100";
import "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useParams } from "react-router-dom";

export default function Bar2() {
  const { id } = useParams(0);

  var inipath = window.location.pathname;

  var path = inipath.split("/login");
  // const path2 = path.split("/cdoctors/");

  // var idvalue = path[3];
  // if (id % 1 == 0) {
  //   var idvalue = id;
  // } else {
  var idvalue = path[2];
  // }

  const [predefined, setpredefined] = useState([]);
  var pikerdate = moment(idvalue, "DD/MM/YYYY").format("YYYY-MM-DD");
  console.log(idvalue);
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("doc_token"),
      },
    };

    axios
      .get(`analytics/get-medical-analysis/?customer=` + id, config)
      .then((res) => {
        console.log(res.data);
        //     let filteredData = [];
        //     let filteredData2 = [];

        //     res.data.custom.forEach((e) => {
        //       if (e.completed === true) filteredData.push(e);
        //     });

        //     // res.data.predefined.sub_module.forEach((e) => {
        //     //   if (e.completed === true) filteredData2.push(e);
        //     // });
        //     // setsymtoms(filteredData);
        //     setcustom(filteredData);
        setpredefined(res.data);

        //     // setsymptomsWithIputs(res.data.symptomsWithIputs);
      });
  }, []);

  console.log(predefined.HELLP);
  Chart.register(ChartDataLabels);

  const ExampleChart = () => (
    <Bar
      data={{
        labels: [
          "Anemia",
          "Cholestatsis",
          "Diabetes",
          "Early Abortion",
          "Fluid Leak",
          "HELLP",
          "Hyperemesis Gravidarum",
          "IUGR",
          "PIH",
          "Placenta abruption",
          "Placenta preveria",
          "Polyhydramnios",
          "Preterm",
          "Still birth",
          "UTI",
          "Yeast Infections",
        ],
        datasets: [
          {
            label: "Criticality Analysis",
            backgroundColor: "#00acc8",

            borderColor: "#00acc8",
            borderWidth: 1,
            hoverBackgroundColor: "#00acc8",
            hoverBorderColor: "#00acc8",
            data: [
              predefined.Anemia || 0,
              predefined.cholestatsis || 0,
              predefined.diabetes || 0,
              predefined.early_abortion || 0,
              predefined.Fluid_Leak || 0,
              predefined.HELLP || 0,
              predefined.hyperemesis_gravidarum || 0,
              predefined.IUGR || 0,
              predefined.PIH || 0,
              predefined.placenta_abruption || 0,
              predefined.placenta_preveria || 0,
              predefined.polyhydramnios || 0,
              predefined.preterm || 0,
              predefined.still_birth || 0,
              predefined.uti || 0,
              predefined.Yeast_Infections || 0,
            ],
            borderRadius: 50, // Make Rectangle rounded
          },
        ],
      }}
      options={{
        maintainAspectRatio: false,
        legend: {
          color: "white",
        },
        indexAxis: "y", // Horizontal bar
        plugins: {
          legend: {
            labels: {
              color: "white", // not 'fontColor:' anymore
              // fontSize: 18  // not 'fontSize:' anymore
              font: {
                size: 14, // 'size' now within object 'font {}'
              },
            },
          },
          datalabels: {
            color: "white",
            formatter: (value) => value + " %", // Add the percentage after the value
            align: "end",
            anchor: "end",
            clip: true, // Hide label if outside of the chart
          },
        },
        scales: {
          x: {
            ticks: {
              color: "white",
              beginAtZero: true,
            },

            grid: {
              display: false, // Hide x grid
            },
            max: 100,
          },
          y: {
            ticks: {
              color: "white",
            },
            grid: {
              display: false, // Hide y grid
            },
          },
        },
      }}
    />
  );

  return (
    <div className="lightgradient">
      {/* {pikerdate} */}
      <h3> analysis 1</h3>
      <p>based on life style and history</p>
      <hr />
      <div className="leftbar ">
        <ExampleChart height="200px" width="200px" />
      </div>
    </div>
  );
}
