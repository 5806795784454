
import imag1 from "./imglern/Asset.png";
import lactationimg from "./imglern/lacation.png";
import diaper from '../img/icons/Group 792.png';
import sleep from '../img/icons/Group 806.png';
import brain from '../img/icons/Group 779.png';
import postpartum from '../img/icons/Path 1626.png';
import warning from '../img/icons/Group 804.png';
import father from '../img/icons/Path 1481.png';
import family from '../img/icons/Group 788.png';
import parenting from '../img/icons/Group 787.png'
import newborn2img from "./imglern/NEwborn2.png";
import { useState } from "react";
import Postall from "../PostDelivery/PostdeliveryRecovery";
export default function LearningPortal() {
  const [toggle, settoggle] = useState(false);
  const [pagename, setpagename] = useState();

  function handleSort(e) {
    setpagename(e);
    window.localStorage.setItem('learnit',e)
    settoggle((current) => !current);
    // const [toggle, settoggle] = useState(false);
  }

  return (
    <>
      {toggle ? (
        <Postall page={pagename} />
      ) : (
        <div className="box boxstyle" style={styles.box}>
          <div className="display-flex">
            {/* <Link to="/PostDeliveryRecovery"> */}
            <div
              className="innerbox"
              style={styles.innerbox}
              onClick={() => handleSort("post delivery recovery")}
            >
              <img src={imag1} style={styles.icon} alt="" />
              {/* <FontAwesomeIcon icon={faUtensils} style={styles.icon} /> */}
              <br />
              <span className="Titlemobile" style={styles.title}>
                Post Delivery Recovery
              </span>
            </div>
            {/* </Link> */}

            <p style={styles.pleft}>
              Everything About Post Delivery Recovery, Diet, Exercise And How To
              Plan The Day{" "}
            </p>
          </div>
          <hr />
          <div className="display-flex">
            <div
              className="innerbox"
              style={styles.innerbox}
              onClick={() => handleSort("lactation")}
            >
              <img src={lactationimg} style={styles.icon} alt="" />
              {/* <FontAwesomeIcon icon={faUtensils} style={styles.icon} /> */}
              <br />
              <span className="Titlemobile" style={styles.title}>
                Lactation
              </span>
            </div>

            <p style={styles.pleft}>
              Feeding Is Kind Of Bonding Between The Mom And Kid And Its Easy If
              You Know How To Keep Your Flow Intact For You Little One.
            </p>
          </div>
          <hr />
          <div className="display-flex">
            <div
              className="innerbox"
              style={styles.innerbox}
              onClick={() => handleSort("new born")}
            >
              <img src={newborn2img} style={styles.icon} alt="" />
              {/* <FontAwesomeIcon icon={faUtensils} style={styles.icon} /> */}
              <br />
              <span className="Titlemobile" style={styles.title}>
                New Born
              </span>
            </div>

            <p style={styles.pleft}>
              Every Baby Is Different. Handling New Borns Is Always An Anxiety
              Filled Job. Everyday Is A Doubt Filled Journey When It Come
              Newborn. Here Is A Guide On What To Worry And What Not To Worry
              About.
            </p>
          </div>
          <hr />
          <div className="display-flex">
            <div
              className="innerbox"
              style={styles.innerbox}
              onClick={() => handleSort("diapering techniques")}
            >
              <img src={diaper} style={styles.icon} alt="" />
              {/* <FontAwesomeIcon icon={faUtensils} style={styles.icon} /> */}
              <br />
              <span className="Titlemobile" style={styles.title}>
                Diapering Techniques
              </span>
            </div>

            <p style={styles.pleft}>
              Are You Aware That There Are Lots Of Options For Reusable Diapers
              That Protects The Environment And Helps The Baby To Stay Healthy.
            </p>
          </div>
          <hr />
          <div className="display-flex">
            <div
              className="innerbox"
              style={styles.innerbox}
              onClick={() => handleSort("sleep pattern setting")}
            >
              <img src={sleep} style={styles.icon} alt="" />
              {/* <FontAwesomeIcon icon={faUtensils} style={styles.icon} /> */}
              <br />
              <span className="Titlemobile" style={styles.title}>
                Sleep Pattern Setting
              </span>
            </div>

            <p style={styles.pleft}>
              90% Of The Babies Don’t Sleep At Night, Do You Want To Know The
              Secret Behind Making The Baby Sleep Right.
            </p>
          </div>
          <hr />
          <div className="display-flex">
            <div
              className="innerbox"
              style={styles.innerbox}
              onClick={() => handleSort("baby brain stimulation")}
            >
              <img src={brain} style={styles.icon} alt="" />
              {/* <FontAwesomeIcon icon={faUtensils} style={styles.icon} /> */}
              <br />
              <span className="Titlemobile" style={styles.title}>
                Baby Brain Stimulation
              </span>
            </div>

            <p style={styles.pleft}>
              95% Of Baby Brain Growth Is Over By The Age Of 8 Years. Stimulate
              The Sense Organs To Have A Brainy Baby.
            </p>
          </div>
          <hr />
          <div className="display-flex">
            <div
              className="innerbox"
              style={styles.innerbox}
              onClick={() => handleSort("postpartum depression")}
            >
              <img src={postpartum} style={styles.icon} alt="" />
              {/* <FontAwesomeIcon icon={faUtensils} style={styles.icon} /> */}
              <br />
              <span className="Titlemobile" style={styles.title}>
                Postpartum Depression
              </span>
            </div>

            <p style={styles.pleft}>
              Postpartum Depression Are Common But Its Equally Dangerous Too.
            </p>
          </div>
          <hr />
          <div className="display-flex">
            <div
              className="innerbox"
              style={styles.innerbox}
              onClick={() => handleSort("weaning and introduction of foods")}
            >
              <img src={warning} style={styles.icon} alt="" />
              {/* <FontAwesomeIcon icon={faUtensils} style={styles.icon} /> */}
              <br />
              <span className="Titlemobile" style={styles.title}>
                Weaning And Introduction Of Foods
              </span>
            </div>

            <p style={styles.pleft}>
              Introducing Foods Can Be Confusing Always. Introduce Right Food At
              The Right Time.
            </p>
          </div>
          <hr />
          <div className="display-flex">
            <div
              className="innerbox"
              style={styles.innerbox}
              onClick={() => handleSort("role of a father")}
            >
              <img src={father} style={styles.icon} alt="" />
              {/* <FontAwesomeIcon icon={faUtensils} style={styles.icon} /> */}
              <br />
              <span className="Titlemobile" style={styles.title}>
                Role Of A Father
              </span>
            </div>

            <p style={styles.pleft}>
              Its Always We Are Pregnant And Not I Am Pregnant Also It Is Always
              Parents And Parent, Thus Implies To Responsibility Too.
            </p>
          </div>
          <hr />
          <div className="display-flex">
            <div
              className="innerbox"
              style={styles.innerbox}
               onClick={() => {window.localStorage.setItem('learnit','family planning');window.location.href='/postldiet'}}
            >
              <img src={family} style={styles.icon} alt="" />
              {/* <FontAwesomeIcon icon={faUtensils} style={styles.icon} /> */}
              <br />
              <span className="Titlemobile" style={styles.title}>
                Family Planning
              </span>
            </div>

            <p style={styles.pleft}>
              It Is Very Important To Plan About Your Next Conception To Have A
              Healthy Baby, Also It Is Important To Avoid Unwanted Pregnancy.
            </p>
          </div>
          <hr />
          <div className="display-flex">
            <div
              className="innerbox"
              style={styles.innerbox}
              // onClick={() => handleSort("Lactation")}<Link to="/postldiet">
              onClick={()=>{window.localStorage.setItem('learnit','parenting');window.location.href='/postldiet'}}
            >
              <img src={parenting} style={styles.icon} alt="" />
              {/* <FontAwesomeIcon icon={faUtensils} style={styles.icon} /> */}
              <br />
              <span className="Titlemobile" style={styles.title}>
                Parenting
              </span>
            </div>

            <p style={styles.pleft}>
              Parents Are Always Confused Whether They Are Raising The Kids
              Right. Are You Aware Of Conscious Parenting? Kids Are Not Prone To
              Punishments, They Are Not Part Of You Anymore And You Have No
              Rights To Control Them.
            </p>
          </div>
          <hr />
        </div>
      )}
    </>
  );
}

const styles = {
  pleft: { textAlign: "left", fontSize: "14px", margin: "auto" },
  box: {
    overflowY: "scroll",
    scrollbarWidth: "none",
    padding: "10px",
    height: "800px",
    margin: "auto 10px",
    width: "92%",
    // margin: "10px",
  },
  innerbox: {
    padding: "10px 0px",
    margin: "35px",
    // float: "left",
    minWidth: "250px",
    height: "100px",
    background: "#ffffff1c 0% 0% no-repeat padding-box",
    borderRadius: "10px",
  },

  icon: {
    width: "4.6rem",
    cursor: "pointer",
  },

  title: {
    display: "inline-block",
    minWidth: "100%",
    background: "#e81a7f",
    marginTop: "1.2rem",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    textTransform: "uppercase",
    fontSize: "14px",
    padding: "5px 0px",
  },

  module: {
    position: "absolute",
  },
};
