export default function Learnit(){

return (

<form style={ styles.form }>
<div>
<strong>Select Module</strong>
<select>
 <option disabled>Select</option>
 <option>Diet</option>
 <option>Pregnancy Concerns</option>
 <option>Stay fit</option>
 <option>Meditation</option>
 <option>Relaxation</option>
 <option>Birth Plan</option>
 <option>Delivery Process</option>
 <option>Natural Coping</option>
 <option>Breast Feading</option>
 <option>Newborn Care</option>
 <option>Skill Training</option>
</select>
</div>

<div>
<strong>Select Period</strong>
<select>
 <option disabled>Select</option>
 <option>0-4</option>
 <option>4-8</option>
 <option>8-12</option>
 <option>12-16</option>
 <option>16-20</option>
 <option>20-24</option>
 <option>24-28</option>
 <option>28-32</option>
 <option>32-36</option>
 <option>36-40</option>
 <option></option>
</select>
</div>


</form>

);



}


const styles = {

form:{
display:'flex',
justifyContent:'space-between',
margin:'30px'

}



};

