import React from "react";
import Sidebar from "./components/sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faUser, faBars } from "@fortawesome/free-solid-svg-icons";
import Toggler from "./toggleprofile";
import Client from "./components/clients";
import axios from "axios";
//import Doctor from './components/doctors';
import Table from "./components/alldoctor_specific_table";
//import Dcstatus from './components/dcstatus';
// import Searchdoctor from "./components/search";
//import Hospitals from './components/hospitals';
import Hospital from "./components/hospital";
//import Salesteams from './components/salesteams';
//import Consultants from './components/consultants';

export default class Overview extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isToggleOn: true, items: [] };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState((prevState) => ({
      isToggleOn: !prevState.isToggleOn,
    }));
  }

  componentDidMount() {
    if (sessionStorage.getItem("hospitalManager") === null) {
      sessionStorage.clear();
      window.location = "/clogin";
    }
    const config = {
      headers: {
        Authorization: `token ` + sessionStorage.getItem("hospitalManager"),
      },
    };

    axios.get("login-user-data/", config).then((res) => {
      console.log(res.data);
      this.setState({ items: res.data });
      // this.setState({ details: res.data.details });
    });
  }

  render() {
    function openMenu() {
      document.querySelector(".sidebar").classList.toggle("show");
    }

    return (
      <div className="App">
        <Sidebar />
        <header className="overview">
          <div
            className="top-header grandiant"
            ref={this.setWrap}
            style={{ padding: "2% 10%", textAlign: "right" }}
          >
            {" "}
            <div>{this.state.isToggleOn ? null : <Toggler />}</div>
            <span className="iconsofgradiant">
              <FontAwesomeIcon
                className="iconsofprofile"
                // onClick={this.handleClick}
                style={{ marginRight: "1rem", cursor: "pointer" }}
                icon={faBell}
              />
            </span>
            <span className="iconsofgradiant">
              {/* {this.state.details.profile_img ? (
                <img
                  style={{ cursor: "pointer" }}
                  onClick={this.profileHandler}
                  src={this.state.items.profile_img}
                  // alt="Avatar"
                  class="avatar"
                />
              ) : ( */}
              <FontAwesomeIcon
                className="iconsofprofile"
                onClick={this.handleClick}
                style={{ cursor: "pointer" }}
                icon={faUser}
              />
              {/* )} */}
            </span>
            {this.state.items.firstname}
            {/* {this.state.isProfile ? null : <ClientProfile />}{" "} */}
          </div>
          {/* <p style={{ paddingRight: "10%", textAlign: "right" }}>
            {" "}
            <div>{this.state.isToggleOn ? null : <Toggler />}</div>
            <FontAwesomeIcon
              onClick={this.handleClick}
              style={{ marginRight: "1rem", cursor: "pointer" }}
              icon={faBell}
            />
            <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faUser} /> Alex
          </p> */}

          <div style={{ width: "89%", display: "flex", paddingLeft: "10px" }}>
            <span onClick={openMenu} className="menu">
              <FontAwesomeIcon
                style={{ marginRight: "20px" }}
                icon={faBars}
                className="menu-icon"
              />
            </span>

            <span style={{ whiteSpace: "nowrap" }}>Doctors</span>
            <div style={{ padding: "4px", width: "100%" }}>
              <hr style={{ background: "white", color: "white" }} />
            </div>
          </div>
          <div style={{ width: "89%" }}>
            <div className="mleft">
              <div
                className="over-cover"
                style={{ display: "flex", justifyContent: "space-around" }}
              >
                <Client />
                <Hospital />
              </div>
              {/* <Searchdoctor /> */}
              <Table />
            </div>
          </div>
        </header>
      </div>
    );
  }
}
