import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDDrqVCZ7k8JvJMAFcT8QoIcZB5Dd2qBlk",
  authDomain: "shebirth-56315.firebaseapp.com",
  projectId: "shebirth-56315",
  storageBucket: "shebirth-56315.appspot.com",
  messagingSenderId: "863574233401",
  appId: "1:863574233401:web:25894efddd5fc5805058e8",
  measurementId: "G-RYDPEVQEH0",
};

export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
Notification.requestPermission().then((permission) => {
  if (permission === "granted") {
    // Set up the messaging event listener
    messaging.onMessage((payload) => {
      const notification = payload.notification;
      const { title, body } = notification;

      // Redirect the user to a specific page
      window.location.href = "/path-to-redirect";
    });
  }
});
