import React from "react";
import { withRouter } from "react-router";
import "../../App.css";
import LoginGirl from "../../privacyicon.png";
// import Form from "./form";
// import "./login.css";

export default class ClientLogin extends React.Component {
  render() {
    return (
      <>
        <div className="privacyback">
          <div style={styles.content}>
            <div class="entry-content clearfix">
              <h2>
                <strong>Website Terms and Conditions of Use</strong>
              </h2>
              <hr />

              <h3>
                <strong>1. Terms</strong>
              </h3>

              <p>
                By accessing this Website, accessible from Sukhprasavam.com, you
                are agreeing to be bound by these Website Terms and Conditions
                of Use and agree that you are responsible for the agreement with
                any applicable local laws. If you disagree with any of these
                terms, you are prohibited from accessing this site. The
                materials contained in this Website are protected by copyright
                and trade mark law.
              </p>
              <h3>
                <strong>2. Use License</strong>
              </h3>
              <p>
                Permission is granted to temporarily download one copy of the
                materials on Sukh Prasavam’s Website for personal,
                non-commercial transitory viewing only. This is the grant of a
                license, not a transfer of title, and under this license you may
                not:
              </p>

              <ul>
                <li>modify or copy the materials;</li>
                <li>
                  use the materials for any commercial purpose or for any public
                  display;
                </li>
                <li>
                  remove any copyright or other proprietary notations from the
                  materials; or
                </li>
                <li>
                  transferring the materials to another person or “mirror” the
                  materials on any other server.
                </li>
              </ul>

              <p>
                This will let Sukh Prasavam to terminate upon violations of any
                of these restrictions. Upon termination, your viewing right will
                also be terminated and you should destroy any downloaded
                materials in your possession whether it is printed or electronic
                format.
              </p>

              <h3>
                <strong>3. Disclaimer</strong>
              </h3>

              <p>
                All the materials on Sukh Prasavam’s Website are provided “as
                is”. Sukh Prasavam makes no warranties, may it be expressed or
                implied, therefore negates all other warranties. Furthermore,
                Sukh Prasavam does not make any representations concerning the
                accuracy or reliability of the use of the materials on its
                Website or otherwise relating to such materials or any sites
                linked to this Website.
              </p>
              <h3>
                <strong>4. Limitations</strong>
              </h3>

              <p>
                Sukh Prasavam or its suppliers will not be hold accountable for
                any damages that will arise with the use or inability to use the
                materials on Sukh Prasavam’s Website, even if Sukh Prasavam or
                an authorize representative of this Website has been notified,
                orally or written, of the possibility of such damage. Some
                jurisdiction does not allow limitations on implied warranties or
                limitations of liability for incidental damages, these
                limitations may not apply to you.
              </p>

              <h3>
                <strong>5. Revisions and Errata</strong>
              </h3>

              <p>
                The materials appearing on Sukh Prasavam’s Website may include
                technical, typographical, or photographic errors. Sukh Prasavam
                will not promise that any of the materials in this Website are
                accurate, complete, or current. Sukh Prasavam may change the
                materials contained on its Website at any time without notice.
                Sukh Prasavam does not make any commitment to update the
                materials.
              </p>
              <h3>
                <strong>6. Links</strong>
              </h3>
              <p>
                Sukh Prasavam has not reviewed all of the sites linked to its
                Website and is not responsible for the contents of any such
                linked site. The presence of any link does not imply endorsement
                by Sukh Prasavam of the site. The use of any linked website is
                at the user’s own risk.
              </p>

              <h3>
                <strong>7. Site Terms of Use Modifications</strong>
              </h3>

              <p>
                Sukh Prasavam may revise these Terms of Use for its Website at
                any time without prior notice. By using this Website, you are
                agreeing to be bound by the current version of these Terms and
                Conditions of Use.
              </p>
              <h3>
                <strong>8. Your Privacy</strong>
              </h3>
              <p>Please read our Privacy Policy.</p>
              <h3>
                <strong>9. Governing Law</strong>
              </h3>
              <p>
                Any claim related to Sukh Prasavam’s Website shall be governed
                by the laws of in without regards to its conflict of law
                provisions.
              </p>
            </div>
          </div>

          <div
            className="loginimage"
            style={{ float: "right", color: "#fff", margin: " 100px auto" }}
          >
            <img
              src={LoginGirl}
              alt=""
              style={styles.imagep}
              className="imgp"
            />
          </div>
        </div>
      </>
    );
  }
}

const styles = {
  content: {
    width: "70%",
    color: "white",
    // textAlign: "center",
  },

  imagep: { width: "100%" },
  flex: {
    display: "flex",
    justifyContent: "space-between",
    width: "70%",
    margin: "auto",
  },
};
