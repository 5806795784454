import React from 'react';

export default function MedicineTracker(){

return (

<table style={{ width:'100%', borderCollapse:'collapse' }}>
<thead style={{ borderBottom:'5px solid #5c0048' }}>
<tr style={{ background:'#a23698' }}>
<th style={ styles.th} >Date</th>
<th style={ styles.th} >Morning Before<br/> Food</th>
<th style={ styles.th} >Afternoon Before<br/> Food</th>
<th style={ styles.th} >Adternoon After<br/> Food</th>
<th style={ styles.th} >Night Before<br/> Food</th>
<th style={ styles.th} >Night After<br/> Food</th>
</tr>
</thead>

<tbody>
<tr style={{ background:'#a23698' }}>
<td style={styles.tdFirst}>02/08/2021</td>
<td style={styles.td}>Medicine A <br/> Medicine B</td>
<td style={styles.td}>Medicine A <br/> Medicine B</td>
<td style={styles.td}>Medicine A <br/> Medicine B</td>
<td style={styles.td}>Medicine A <br/> Medicine B</td>
<td style={styles.td}>Medicine A <br/> Medicine B</td>
</tr>


</tbody>


</table>


);

}


const styles = {

th:{
fontSize:'17px',
whiteSpace:'nowrap',
padding:'5px 10px',
margin:'10px 0px',
},

td:{
borderLeft:'1px solid #fff',
padding:'5px 0px',
fontSize:'12px'
},

tdFirst:{
padding:'5px 10px',
fontSize:'12px'
},



}





