import React, { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router";
export default function SymptomsTracker(props) {
  const [data, setData] = useState([]);
  const { id } = useParams();
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("doc_token"),
      },
    };
    //remove this date after setting up the admin pannel

    axios
      .get(`customer/get-all-dates-symptoms/`, {
        headers: {
          Authorization: `Token ` + sessionStorage.getItem("token"),
        },
        params: {
          customer: props.userid1,
        },
      })
      .then((res) => {
        const apidata = res.data.symptoms.concat(res.data.symptoms_with_text);
        const maindata = apidata.concat(res.data.custom_symptoms);
        const groupedByDate = maindata.reduce((symptoms, symptom) => {
          if (symptom.date in symptoms) {
            symptoms[symptom.date].push(symptom);
          } else {
            symptoms[symptom.date] = [symptom];
          }
          return symptoms;
        }, {});

        console.log(groupedByDate);
        const sortedOnDate = Object.values(groupedByDate).sort((arr1, arr2) => {
          const ymdDate1 = arr1[0].date.split("-").reverse().join("-");
          const ymdDate2 = arr2[0].date.split("-").reverse().join("-");
          if (ymdDate1 < ymdDate2) {
            return 1;
          } else if (ymdDate1 > ymdDate2) {
            return -1;
          }
          return 0;
        });
        console.log(sortedOnDate);
        setData(sortedOnDate);
      });
  }, []);
  return (
    <div className="table-cover">
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead style={{ borderBottom: "5px solid #5c0048" }}>
          <tr
            style={{
              background:
                "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
            }}
          >
            <th style={styles.th}>Date</th>
            <th style={styles.th}>Symptoms</th>
            <th style={styles.th}>Others Symptoms</th>
            <th style={styles.th}>Blood Sugar Level</th>
            <th style={styles.th}>Blood PressureLevel</th>
            <th style={styles.th}>Last Week Report</th>
          </tr>
        </thead>
        <tbody>
          {data.map((ele) => {
            return (
              <tr
                style={{
                  background:
                    "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
                }}
                key={ele.date}
              >
                <td style={styles.tdFirst}> {ele[0].date}</td>
                <td style={styles.td}>
                  <>
                    {ele.map((e) => {
                      return (
                        <>
                          <div>{e.symptom}</div>
                        </>
                      );
                    })}
                  </>
                </td>
                <td style={styles.td}>
                  {ele.map((e) => {
                    return <div>{e.others}</div>;
                  })}
                </td>
                <td style={styles.td}>
                  {ele.map((ele) => {
                    return <div>{ele.bloodSugar}</div>;
                  })}
                </td>
                <td style={styles.td}>
                  {ele.map((ele) => {
                    return <div>{ele.bloodPressure}</div>;
                  })}
                </td>

                <td style={styles.td}>
                  {ele.map((ele) => {
                    return <div>{ele.report}</div>;
                  })}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

const styles = {
  th: {
    fontSize: "17px",
    whiteSpace: "nowrap",
    padding: "5px 10px",
    margin: "10px 0px",
  },

  td: {
    borderLeft: "1px solid #fff",
    padding: "5px 10px",
    fontSize: "12px",
    textAlign: "left",
    lineHeight: "20px",
  },

  tdFirst: {
    padding: "5px 10px",
    fontSize: "12px",
  },
};
