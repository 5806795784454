import React from "react";
import Doctorprofile from "./doctorprofile";
import axios from "axios";

export default class Tablelist extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isDialog: true, background: "#fff", list: [] };
    this.handleClick = this.handleClick.bind(this);
    this.colorChange = this.colorChange.bind(this);
  }

  componentDidMount() {
    axios
      .get(`http://127.0.0.1:8000/sales/clients-under-sales-team/?sales=${this.props.salesid}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Token ${localStorage.getItem("admin_token")}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        this.setState({ list: res.data });
      });
  }

  colorChange() {
    const color = this.state.background === "#fff" ? "red" : "#fff";

    this.setState({ background: color });
  }

  handleClick() {
    this.setState({
      isDialog: !this.state.isDialog,
    });
  }

  render() {
    return (
      <div style={styles.card} className="lightgradient2">
        <div style={styles.inner}>
          <input style={styles.date} type="date" />
          <p>
            <strong>Client of a specific sales team</strong>
          </p>
          <hr style={{ color: "#fff" }} />
          <table>
            <thead>
              <tr>
                <td>Client</td>
                <td>Didnt call</td>
                <td>Didnt responed</td>
                <td>Called</td>

                <td>Notes</td>
              </tr>
            </thead>
            <tbody>
              {this.state.list &&
                this.state.list
                  .filter((ele) => {
                    if (this.props.searchval) {
                      // console.log(search);
                      if (ele && ele.firstname) {
                        return `${ele.firstname} ${ele.lastname}`
                          .toLowerCase()
                          .includes(this.props.searchval.toLowerCase());
                      }
                    } else return ele;
                  })
                  .map((ele) => {
                    return (
                      <tr>
                        <td>{`${ele.firstname} ${ele.lastname}`}</td>
                        <td>
                          <input type="checkbox" />
                        </td>
                        <td>
                          {" "}
                          <input type="checkbox" />
                        </td>

                        <td>
                          {" "}
                          <input type="checkbox" />
                        </td>
                        <td>
                          <button>Notes</button>
                          {/* <select
                    onChange={this.colorChange}
                    style={{
                      background: this.state.background,
                      border: "none",
                    }}
                  >
                    <option>Active</option>
                    <option>Disabled</option>
                  </select> */}
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </table>
          {this.state.isDialog ? null : <Doctorprofile />}
        </div>
      </div>
    );
  }
}

const styles = {
  card: {
    // display: "flex",
    justifyContent: "space-between",
    // backdropFilter: "blur(31px) brightness(-15%) opacity(.3)",
    padding: "20px",
    borderRadius: "10px",
    margin: "10px",
  },
  inner: {
    textDecoration: "none",
    color: "#fff",
  },
  date: {
    float: "left",
    background: "#822668",
    color: "white",
    border: "none",
  },
  buttons: { background: "blue" },
};
