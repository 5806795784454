import React from "react";
import PropTypes from "prop-types";
import Sidebar from "../components/sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faUser, faBars } from "@fortawesome/free-solid-svg-icons";
import Toggler from "../toggle";
//import Client from './components/clients';
//import Doctor from './components/doctors';
import Table from "./components/hospital_table";
import Dcstatus from "../components/dcstatus";
import Searchdoctor from "./components/search";
import Hospitals from "../components/hospitals";
import ClientProfile from "../toggleprofile";
import Salesteams from "../components/salesteams";
import Consultants from "../components/consultants";
import { Redirect } from "react-router";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

export default class Overviewdoc extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isToggleOn: true,
      redirect: null,
      list: [],
      searchval: "",
      count: "",
    };
    this.handleClick = this.handleClick.bind(this);
    this.setWapRef = this.setWapRef.bind(this);
    this.clickedOutside = this.clickedOutside.bind(this);
  }

  handleClick() {
    this.setState((prevState) => ({
      isToggleOn: !prevState.isToggleOn,
    }));
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.clickedOutside);

    const token = sessionStorage.getItem("token");
    if (!token) {
      this.setState({ redirect: "/admin/login" });
    }

    axios
      .get("admin-panel/admin-dashboard-details/", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Token ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        this.setState({ count: res.data.counts });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.clickedOutside);
  }

  setWapRef(node) {
    this.wapRef = node;
  }

  clickedOutside(e) {
    if (this.wapRef && !this.wapRef.contains(e.target)) {
      return this.setState({ isToggleOn: true });
    }
  }

  render() {
    function openMenu() {
      document.querySelector(".sidebar").classList.toggle("show");
    }
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    return (
      <div className="App">
        <Sidebar />
        <header className="overview">
          {this.state.isToggleOn ? null : <Toggler />}
          <p style={{ paddingRight: "10%", textAlign: "right" }}>
            {" "}
            <div>{this.state.isToggleOn ? null : <Toggler />}</div>
            <span ref={this.setWapRef}>
              <FontAwesomeIcon
                onClick={this.handleClick}
                style={{ marginRight: "1rem", cursor: "pointer" }}
                icon={faBell}
              />
              {this.props.children}
            </span>
            <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faUser} />{" "}
            Admin
          </p>

          <div style={{ width: "89%", display: "flex", paddingLeft: "10px" }}>
            <span onClick={openMenu} className="menu">
              <FontAwesomeIcon
                style={{ marginRight: "20px" }}
                icon={faBars}
                className="menu-icon"
              />
            </span>

            <span style={{ whiteSpace: "nowrap" }}>Sales Team</span>
            <div style={{ padding: "4px", width: "100%" }}>
              <hr style={{ background: "white", color: "white" }} />
            </div>
          </div>
          <div className="mwrapper">
            <div className="mleft">
              <div style={styles.card}>
                <div style={styles.inner}>
                  <p style={{ textAlign: "left" }}>Search Client</p>
                  <FontAwesomeIcon
                    style={{ marginRight: "-22px" }}
                    icon={faSearch}
                  />
                  <input
                    style={styles.input}
                    onChange={(e) => {
                      this.setState({ searchval: e.target.value });
                    }}
                    type="Search"
                  />
                </div>
              </div>
              <Table searchval={this.state.searchval} salesid={this.props.id} />
            </div>
            <div className="mright">
              <div
                style={{
                  fontWeight: "bold",
                  textAlign: "left",
                  paddingLeft: "10px",
                }}
              ></div>
              <Hospitals totalHospitals={this.state.count.totalHospitals} />
              <Salesteams totalSalesTeam={this.state.count.totalSalesTeam} />
              <Consultants totalConsultant={this.state.count.totalConsultant} />
              <Dcstatus
                activeClients={this.state.count.activeClients}
                disabledDoctors={this.state.count.disabledDoctors}
              />
            </div>
          </div>
        </header>
      </div>
    );
  }
}

Overviewdoc.propTypes = {
  children: PropTypes.element.isRequired,
};

const styles = {
  card: {
    display: "flex",
    justifyContent: "space-between",
    background: "#ffffff1c 0% 0% no-repeat padding-box",
    padding: "20px",
    borderRadius: "10px",
    margin: "10px",
  },
  inner: {
    textDecoration: "none",
    color: "#fff",
  },

  input: {
    width: "40rem",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderBottomColor: "1px solid #fff",
    background: "transparent",
    padding: "8px",
    marginLeft: "30px",
    outline: "none",
    color: "white",
  },
};
