export default function UpdateCustomizedExercise(){

return (

<>
<div style={styles.wrapper}>
<form>
<h5 style={{ margin:'0px 2px' }}>Update Customised Exercise</h5>
<hr/>
<div style={styles.flexer}>
<input style={ styles.loader } type="file"/><br/>
<button style={ styles.ubtn }>Upload</button>
</div>
</form>
</div>
</>
);

}


const styles = {
wrapper:{
background:'#862D8B',
padding:'1rem',
position:'fixed',
transform:'translate(18rem, 2.5rem)',
width:'30rem'
},

flexer:{
textAlign:'center'

},

loader:{
border:'none',
background:'#A465B5'
},

ubtn:{
background:'#E81A7F',
border:'1px solid #E81A7F',
color:'#fff',
padding:'5px 20px',
margin:'5px 0px'
}


};
