import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown, faSort } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import SearchClients from "./search";
import ResheduleDialog from "./reshedule_appointment";
import ClientProfile from "./client_profile";

export default function RequestApprovalTable() {
  const [shedule, setShedule] = useState(false);
  const [client, setClient] = useState(false);
  const [ID, setID] = useState([]);
  const [showSerch, setShowSerch] = useState([]);
  function reject(id) {
    const Data = {
      appointmentID: id,

      // notes: notes,
    };
    const headers = {
      //"Content-Type": "application/x-www-form-urlencoded",

      Authorization: `token ` + localStorage.getItem("doc_token"),

      //Authorization: "JWT fefege...",
    };

    axios
      .post("appointments/reject-appointment/", Data, {
        headers: headers,
      })
      .then(() => {
        // setincrement([...increment,res.data])
        alert("rejected appointment");
        window.location.reload();
      })
      .catch((error) => {
        console.log("error in rejected ", error);
      });
  }

  function openShedule(id) {
    setShedule(!shedule);
    const Data = {
      appointmentID: id,

      // notes: notes,
    };
    const headers = {
      //"Content-Type": "application/x-www-form-urlencoded",

      Authorization: `token ` + localStorage.getItem("doc_token"),

      //Authorization: "JWT fefege...",
    };

    axios
      .post("appointments/approve-appointment/", Data, {
        headers: headers,
      })
      .then(() => {
        // setincrement([...increment,res.data])
        alert("Schedule appointment");
        window.location.reload();
      })
      .catch((error) => {
        console.log("error in sheduling ", error);
      });

    // setID(id);
  }

  function openClinetProfile() {
    setClient(!client);
  }
  const config = {
    headers: {
      Authorization: `token ` + localStorage.getItem("doc_token"),
    },
  };

  useEffect(() => {
    axios.get("doctor/approval-pending-appointments/", config).then((res) => {
      const data = res.data;
      // pnName(data.data);

      //  var stringdata = data;
      setShowSerch(data);

      // console.log(data);
    });
    //  setShowSerch(data);
  }, []);

  return (
    <>
      {/* {shedule ? <ResheduleDialog userid={ID} /> : null} */}
      {client ? <ClientProfile /> : null}
      <SearchClients />

      <div style={styles.card} className="gradiantblur">
        <div style={styles.inner}>
          <p>
            <strong>Approval Requests</strong>
          </p>
          <hr style={{ color: "#fff" }} />

          <table
            style={{ borderCollapse: "collapse", width: "41.6rem" }}
            className="fontof12"
          >
            <thead>
              <tr>
                <td>Name</td>
                <td>Location</td>
                <td>Date</td>
                <td>Preferred Slot</td>
                <td>Reject</td>
                <td>Schedule Consultation</td>
              </tr>
            </thead>
            <tbody>
              {/* {console.log(showSerch)} */}
              {showSerch.map((user) => (
                <tr style={styles.tr} className="lightgradient">
                  <td style={styles.tolink} onClick={openClinetProfile}>
                    {user.clientName}
                  </td>
                  <td> {user.Location}</td>
                  <td> {user.date}</td>
                  <td> {user.formated_time}</td>
                  <td>
                    <button
                      style={styles.sbtn}
                      //   onClick={openShedule}
                      onClick={() => reject(user.id)}
                    >
                      Reject
                    </button>
                  </td>
                  <td>
                    <button
                      style={styles.sbtn}
                      //   onClick={openShedule}
                      onClick={() =>
                        openShedule(
                          user.id,
                          user.clientName,
                          user.date,
                          user.formated_time
                        )
                      }
                    >
                      Approve
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

const styles = {
  card: {
    display: "flex",
    justifyContent: "space-between",
    // background: "#4c003d",
    padding: "0px",
    borderRadius: "10px",
    margin: "10px",
  },
  inner: {
    width: "100%",
    textDecoration: "none",
    color: "#fff",
  },

  sortit: {
    background: "rgb(133, 55, 138) none repeat scroll 0% 0%",
    margin: "10px",
    borderRadius: "10px",
    textAlign: "left",
    padding: "10px",
  },

  option: {
    background: "transparent",
    border: "none",
    appearance: "none",
    color: "#fff",
  },

  tolink: {
    // background: "#60124D",
    textDecoration: "none",
    padding: "5px",
    color: "#fff",
  },

  sbtn: {
    background: "#E81A7F",
    border: "1px solid #E81A7F",
  },

  tr: {
    // background: "#772068",
    cursor: "pointer",
  },
};
