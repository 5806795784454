import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";

export default function SymptomsTracker(props) {
  var userid = props.userid1;
  const [data, setData] = useState([]);
  const [week, setweek] = useState([]);
  const [update, setUpdate] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const history = useHistory();

  const handleButtonClick = () => {
    setShowModal(true);
  };
  const handleButtonClick2 = () => {
    // Navigate to the graph screen
    history.push(`/Sysgraph/${userid}`);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleCloseModal2 = () => {
    setShowModal2(false);
  };

  function updateOpen() {
    setUpdate(true);
  }
  // const handleDateChange = (event) => {
  //   setSelectedDate(event.target.value);
  //   fetchData(event.target.value); // call your API with the selected date
  // };
  const handleDateChange = (e) => {
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("doc_token"),
      },
    };
    const selectedDate = e.target.value;
    setSelectedDate(selectedDate);
    axios
      .get(
        `customer/Symptoms-GET/?customer=${userid.id}&date=${selectedDate}`,
        config
      )
      .then((response) => {
        setweek(response.data.last_week_symptom_report);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    // if (localStorage.getItem("token") === null) {
    //   localStorage.clear();
    //   //window.location = "/clogin";
    // }
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("doc_token"),
      },
    };
    //remove this date after setting up the admin pannel

    axios
      .get(
        "customer/get-all-dates-symptoms/?customer=" + userid.id,
        config

        // config
      )
      //
      .then((res) => {
        const apidata = res.data.symptoms.concat(res.data.symptoms_with_text);
        const maindata = apidata.concat(res.data.custom_symptoms);
        const groupedByDate = maindata.reduce((symptoms, symptom) => {
          if (symptom.date in symptoms) {
            symptoms[symptom.date].push(symptom);
          } else {
            symptoms[symptom.date] = [symptom];
          }
          return symptoms;
        }, {});

        console.log(groupedByDate);
        const sortedOnDate = Object.values(groupedByDate).sort((arr1, arr2) => {
          const ymdDate1 = arr1[0].date.split("-").reverse().join("-");
          const ymdDate2 = arr2[0].date.split("-").reverse().join("-");
          if (ymdDate1 < ymdDate2) {
            return 1;
          } else if (ymdDate1 > ymdDate2) {
            return -1;
          }
          return 0;
        });
        console.log(sortedOnDate);
        setData(sortedOnDate);
      });
  }, []);
  return (
    <div className="scrollerbar">
      <button className="pinkbutton" onClick={handleButtonClick}>
        Last Week Report
      </button>
      {/* <button className="pinkbutton" onClick={handleButtonClick2}>
        View Graph
      </button> */}
      {showModal && (
        <div className="modal-content">
          <span className="close1" onClick={handleCloseModal}>
            &times;
          </span>
          <p> Last Week Report</p>

          <div className="date-input-container">
            <input
              type="date"
              value={selectedDate}
              onChange={handleDateChange}
            />
            <i className="fa fa-calendar" aria-hidden="true"></i>
          </div>
          <p className="font-16">
            {" "}
            this Week &nbsp; &nbsp;
            {week.map((item) => (
              <>
                {" "}
                &nbsp; &nbsp;
                {item.count} &nbsp; times &nbsp;
                {item.symptom}
              </>
            ))}{" "}
          </p>
          {/* {console.log(week.last_week_symptom_report)} */}
        </div>
      )}

      {/* {showModal2 && (
        <div className="modal ">
          <span className="close1" onClick={handleCloseModal2}>
            &times;
          </span>
          <div className="chart-container">
            <MyChart data={chartData} />
          </div>
        </div>
      )} */}

      <table
        style={{
          width: "100%",
          // borderCollapse: "seprate",
          width: "41.6rem",
          borderSpacing: "0 1em",
        }}
      >
        <thead style={{ borderBottom: "5px solid #5c0048" }}>
          <tr
            style={{
              background:
                "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
            }}
            className="font-blue"
          >
            <th style={styles.th}>Date</th>
            <th style={styles.th}>Symptoms</th>
            <th style={styles.th}>Weight</th>
            {/* <th style={styles.th}>Weight</th> */}
            <th style={styles.th}>Blood Sugar Level</th>
            <th style={styles.th}>Blood Pressure Level</th>
            <th style={styles.th}>Last Week Report</th>
          </tr>
        </thead>

        <tbody>
          {data.map((ele) => {
            return (
              <tr
                style={{
                  background:
                    "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
                }}
                key={ele.date}
              >
                <td style={styles.tdFirst}> {ele[0].date}</td>
                <td style={styles.td}>
                  <>
                    {ele.map((e) => {
                      return (
                        <>
                          <div>{e.symptom}</div>
                          {/* <div>{e.others}</div> */}
                        </>
                      );
                    })}
                  </>
                </td>
                <td style={styles.td}>
                  {ele.map((e) => {
                    return (
                      <>
                        <div>{e.others}</div>
                      </>
                    );
                  })}
                </td>
                <td style={styles.td}>
                  {ele.map((ele) => {
                    return <div>{ele.bloodSugar}</div>;
                  })}
                </td>
                <td style={styles.td}>
                  {ele.map((ele) => {
                    return <div>{ele.bloodPressure}</div>;
                  })}
                </td>

                <td style={styles.td}>
                  {ele.map((ele) => {
                    return <div>{ele.report}</div>;
                  })}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

const styles = {
  th: {
    fontSize: "17px",
    whiteSpace: "nowrap",
    padding: "5px 10px",
    margin: "10px 0px",
  },

  td: {
    border: "1px solid black",
    padding: "5px 10px",
    background:
      "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
    fontSize: "12px",
    textAlign: "left",
    lineHeight: "20px",
  },

  tdFirst: {
    border: "1px solid black",
    padding: "5px 10px",
    fontSize: "12px",
  },
};
