import React from "react";
import { Link, NavLink } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faThLarge,
  faUserMd,
  faBriefcaseMedical,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { faAndroid, faApple } from "@fortawesome/free-brands-svg-icons";

import Logo from "../../logo.png";

export default class Sidebar extends React.Component {
  render() {
    return (
      <div className="sidebar lightgradient" style={styles.header}>
        <img src={Logo} alt="" className="mainlogo" />
        <button className="backButton" onClick={() => window.history.back()}>
          Back
        </button>
        <ul style={styles.lists}>
          <Link to="/hmanager">
            <li style={styles.listItemActive}>
              <div>
                <FontAwesomeIcon
                  icon={faThLarge}
                  style={{ marginRight: "1rem" }}
                />
              </div>
              <div> Overview</div>
            </li>
          </Link>
          <NavLink to="/hmsignup" activeClassName="active3">
            <li style={styles.listItem}>
              <div>
                <FontAwesomeIcon
                  icon={faUser}
                  style={{ marginRight: "1rem" }}
                />
              </div>
              <div>Add New Client </div>
            </li>
          </NavLink>
          <NavLink to="/reglist" activeClassName="active3">
            <li style={styles.listItem}>
              <div>
                <FontAwesomeIcon
                  icon={faUser}
                  style={{ marginRight: "1rem" }}
                />
              </div>
              <div>Registerd Clients </div>
            </li>
          </NavLink>
          <NavLink to="/adddoctor" activeClassName="active3">
            <li style={styles.listItem}>
              <div>
                <FontAwesomeIcon
                  icon={faUserMd}
                  style={{ marginRight: "1rem" }}
                />
              </div>
              <div>Add New Doctor </div>
            </li>
          </NavLink>
        </ul>

        <div
          className="hide-mob"
          style={{
            position: "absolute",
            bottom: "3rem",
            left: "30px",
            width: "15%",
          }}
        >
          <p style={{ fontSize: "14px" }}>
            {" "}
            to enjoy services provided by shebirth on mobile
            <br />
            download our mobile app
          </p>
          <div>
            <button
              style={{
                color: "#fff",
                textDecoration: "none",
                background: "transparent",
                border: "none",
              }}
            >
              <a href="https://play.google.com/store/apps/details?id=com.smartbirth.shebirth">
                <FontAwesomeIcon icon={faAndroid} />
              </a>
              <a style={{marginLeft:'16px',fontSize:'30px'}} href="https://apps.apple.com/in/app/shebirth-sukh-prasavam/id6461416949">
                <FontAwesomeIcon icon={faApple} />
              </a>
              {/* <FontAwesomeIcon icon={faCog} style={{ marginRight: "1rem" }} /> */}
              {/* Settings */}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  header: {
    // background: "linear-gradient(25deg, #3a51a7, #7f1f54)",
    padding: "2rem",
    minHeight: "100%",
    width: "20%",
    margin: "0",
    color: "white",
  },
  lists: {
    marginTop: "5rem",
    color: "#fff",
    paddingLeft: "0",
    listStyle: "none",
  },
  listItem: {
    background: "transparent",
    padding: "10px 50px",
    borderRadius: "10px",
    cursor: "pointer",
    display: "flex",
  },
  listItemActive: {
    background: "#6a0f4b",
    padding: "10px 50px",
    borderRadius: "10px",
    cursor: "pointer",
    display: "flex",
  },
};
