import React from "react";
import PropTypes from "prop-types";
import Sidebar from "../components/sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faUser } from "@fortawesome/free-solid-svg-icons";
import AToggler from "../toggle";
import ATogProfile from "../tog_profile";
import Calendar from "../components/calendar";
import Experts from "../components/experts";
import Payment from "../components/my_payment";

export default class PaymentOverview extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isToggleOn: true, onProfile: true };
    this.handleClick = this.handleClick.bind(this);
    this.handleProfile = this.handleProfile.bind(this);
  }

  handleClick() {
    this.setState((prevState) => ({
      isToggleOn: !prevState.isToggleOn,
    }));
  }

  handleProfile() {
    this.setState((prevState) => ({
      onProfile: !prevState.onProfile,
    }));
  }

  render() {
    return (
      <div className="App">
        <Sidebar />
        <header className="overview">
          <p style={{ paddingRight: "10%", textAlign: "right" }}>
            {" "}
            <div>{this.state.isToggleOn ? null : <AToggler />}</div>
            <span>
              <FontAwesomeIcon
                onClick={this.handleClick}
                style={{ marginRight: "1rem", cursor: "pointer" }}
                icon={faBell}
              />
            </span>
            <span>
              <FontAwesomeIcon
                style={{ cursor: "pointer" }}
                icon={faUser}
                onClick={this.handleProfile}
              />
              Alex
            </span>{" "}
            <div>{this.state.onProfile ? null : <ATogProfile />}</div>
          </p>

          <div style={{ width: "89%", display: "flex", paddingLeft: "10px" }}>
            <span style={{ whiteSpace: "nowrap" }}>My Payments</span>
            <div style={{ padding: "4px", width: "100%" }}>
              <hr style={{ background: "white", color: "white" }} />
            </div>
          </div>
          <div className="mwrapper">
            <div className="mleft">
              <Payment />
            </div>
            <div className="mright">
              <Calendar />
              <div
                style={{
                  fontWeight: "bold",
                  textAlign: "left",
                  paddingLeft: "10px",
                }}
              >
                <small style={{ textTransform: "uppercase" }}>Experts</small>
              </div>

              <Experts />
            </div>
          </div>
        </header>
      </div>
    );
  }
}

PaymentOverview.propTypes = {
  children: PropTypes.element.isRequired,
};
