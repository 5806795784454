import { SheBirthService } from "./interceptors"

// Sales API starts ---

// See Docs: https://documenter.getpostman.com/view/15623430/UV5f9EWk#32187bef-09ba-4cb5-9764-fdf0d84767ec

export const registerSalesTeam = (config) => {
    return SheBirthService.post('/sales/registration/', config)
}

export const getLoggedInUser = (config) => {
    return SheBirthService.get('/login-user-data/', config)
}

export const getSalesDashboardDetails = (config) => {
    return SheBirthService.get('/sales/sales-dashboard-details/', config)
}

export const getNoUpdateClientsDetails = (config) => {
    return SheBirthService.get('/sales/no-update-clients/', config)
}

export const getAllClientForSales = (config) => {
    return SheBirthService.get('/all-clients/', config)
}

export const getThisMonthClient = (config) => {
    return SheBirthService.get('/sales/client-this-month/', config)
}

export const createInvestigationReport = (config) => {
    return SheBirthService.post('/sales/investigation-report/', config)
}

export const deleteCustomInvestigation = (config) => {
    return SheBirthService.delete('/sales/delete-custom-investigation/', config)
}

export const getInvestigationReport = (config) => {
    return SheBirthService.get('/customer/get-medical/', config)
}

export const updateInvestigationReport = (config) => {
    return SheBirthService.patch('/sales/investigation-report/', config)
}

export const respondToClientRequest = (config) => {
    return SheBirthService.post('/sales/request-response/', config)
}

export const viewAllDetailsRequest = (config) => {
    return SheBirthService.get('/sales/view-all-details-request/', config)
}

export const getLastUpdateBefore24hours = (config) => {
    return SheBirthService.get('/sales/last-update-before-24-hours/', config)
}

export const addCallResponse = (config) => {
    return SheBirthService.post('/sales/call-response/', config)
}

export const updateCallResponse = (config) => {
    return SheBirthService.patch('/sales/call-response/', config)
}

export const getCallResponseStatus = (config) => {
    return SheBirthService.get('/sales/get-call-response/', config)
}

export const getCustomerDetails = (config) => {
    return SheBirthService.get('/customer-profile/', config)
}

export const createCustomInvestigationReport = (config) => {
    return SheBirthService.post('/sales/custom-investigation-report/', config)
}

//  Daily Tracker API

export const getMedicineDetails = (config) => {
    return SheBirthService.get('/customer/full-medicine-details/', config)
}

export const getMedicineDetailsByDate = (config) => {
    return SheBirthService.get('/customer/medicine-GET/', config)
}

export const getSymptomsDetails = (config) => {
    return SheBirthService.get('/customer/get-all-dates-symptoms/', config)
}

export const getSymptomsDetailsByDate = (config) => {
    return SheBirthService.get('/customer/Symptoms-GET/', config)
}

export const getAllActivityData = (config) => {
    return SheBirthService.get('/customer/get-all-activity-data/', config)
}

export const getAllActivityDataByDate = (config) => {
    return SheBirthService.get('/customer/get-activity-tasks/', config)
}

export const getExerciseByDate = (config) => {
    return SheBirthService.get('/customer/exercise-get/', config)
}

export const getAllExercise = (config) => {
    return SheBirthService.get('/customer/get-all-dates-exercises/', config)
}

export const getAllDateContractions = (config) => {
    return SheBirthService.get('/customer/all-dates-contractions/', config)
}

export const getAllDietDetails = (config) => {
    return SheBirthService.get('customer/all-dates-diet/', config)
}

export const getDietDetailsByDate = (config) => {
    return SheBirthService.get('customer/diet-tracker-GET/', config)
}

export const updateDietDetails = (config) => {
    return SheBirthService.patch('customer/diet-tracker-add-POST/', config)
}

export const getAllMedicalDetails = (config) => {
    return SheBirthService.get('/customer/medical-get-all/', config)
}

// --- end
