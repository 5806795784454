import React from "react";
import axios from "axios";
import "../scans/scan.css";
/*import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';*/
/*import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';*/
//import EditPersonalDetail from './innercom/edit_personal';
//import AMan from '../img/man.jpg';

import Payment from "../pricing/inbuildpay";

// import DietTracker from '../img/balance_diet.png';

class UpcomingScan extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      upcoming: [],
      subscription_package: [],
      sub: null, // Initialize 'sub' state to null or an appropriate default value
    };
    // this.openEditor = this.openEditor.bind(this);
  }

  // openEditor(){

  // this.setState({ isEditor: !this.state.isEditor });

  // }

  componentDidMount() {
    const subscription = localStorage.getItem("subscibtion");
    this.setState({ sub: subscription });
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("token"),
      },
    };

    axios.get("customer/scan-dates/", config).then((res) => {
      console.log(res.data);
      //   console.log("api called");
      //   res.data;
      this.setState({ upcoming: res.data });
    });

    axios.get("customer/customer-dashboard-details/", config).then((res) => {
      console.log(res.data);
      this.setState({
        subscription_package: res.data.subscription_package.membership,
      });
    });
  }

  render() {
    return (
      <div className="upcoming-scan">
        <div className="card" style={styles.card}>
          <div style={styles.inner}>
            {/* {this.state.subscription_package &&
            this.state.subscription_package === "Trial Plan" ? ( */}
            {this.state.sub === "false" ? (
              <>
                <h1> purchase a membership</h1>
                <Payment />
              </>
            ) : (
              <table>
                <tr>
                  <td>Consulting Doctor:</td>
                  <td id="outline">Dr {this.state.upcoming.doctor}</td>
                </tr>
                <tr>
                  <td>
                    Estimated Due Date:
                    <br />
                    (40 Weeks)
                  </td>
                  <td id="outline">{this.state.upcoming.dueDate}</td>
                </tr>
                <tr>
                  <td>Last Menstrual Period:</td>
                  <td id="outline">{this.state.upcoming.lastMenstrualDate}</td>
                </tr>
                <tr>
                  <td>
                    Probable date of conception: <br />( about two weeks after
                    last menstrual period )
                  </td>
                  <td id="outline">{this.state.upcoming.probableDate}</td>
                </tr>
                <tr>
                  <td>Fetal Age Today: </td>
                  <td id="outline">
                    {this.state.upcoming.foetal_week} Week{" "}
                    {this.state.upcoming.foetal_days} Days old
                  </td>
                </tr>
                <tr>
                  <td>
                    Best date range for Dating Scan:
                    <br />
                    (8th Week)
                  </td>
                  <td id="outline">{this.state.upcoming.growthScan}</td>
                </tr>
                <tr>
                  <td>
                    Best Date range for NT scan: <br />( 12 wks 3 days to 13 wks
                    3 days )
                  </td>
                  <td id="outline">{this.state.upcoming.ntScan}</td>
                </tr>
                <tr>
                  <td>
                    Best date range for Morphology Scan: <br />
                    (19th week)
                  </td>
                  <td id="outline">{this.state.upcoming.morphologyScan}</td>
                </tr>
                <tr>
                  <td>
                    Best date range for Anomaly Scan: <br />
                    (20-22 week)
                  </td>
                  <td id="outline">{this.state.upcoming.anomalyScan}</td>
                </tr>
                <tr>
                  <td>
                    Best date range for Growth Scan: <br />
                    (32-34 week)
                  </td>
                  <td id="outline">{this.state.upcoming.growthScan}</td>
                </tr>
                <tr>
                  <td>previous ultrasound date and fetal on that day:</td>
                  <td id="outline">{this.state.upcoming.ultraSound}</td>
                </tr>
              </table>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  card: {
    // display: "flex",
    justifyContent: "space-between",
    background: "#ffffff1c 0% 0% no-repeat padding-box",
    padding: "20px",
    borderRadius: "10px",
    margin: "10px",
  },

  inner: {
    textDecoration: "none",
    color: "#fff",
  },
};

export default UpcomingScan;
