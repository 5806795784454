import React from "react";
import axios from "axios";
import "../tabs.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
/*import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';*/
//import EditPersonalDetail from './innercom/edit_personal';

import ASummery from "./innercom/open_summery";
import Reshedule from "./reshedule_appointment";

class Appointment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSummery: false,
      userid: "",
      isShedule: false,
      approved: [],
      completed: [],
      rejected: [],
      username: "",
    };
    this.openSummery = this.openSummery.bind(this);
    this.openShedule = this.openShedule.bind(this);
  }

  componentDidMount() {
    var data = {
      Authorization: localStorage.getItem("doc_token"),
    };

    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("doc_token"),
      },
      params: {
        doctor: localStorage.getItem("doc_id"),
      },
      data: data,
    };

    axios
      .get("appointments/full-appointment/", config)
      .then((res) => {
        console.log(res.data);
        this.setState({ approved: res.data.Approved });
        this.setState({ completed: res.data.Completed });
        this.setState({ rejected: res.data.Rejected });
      })
      .catch((err) => {
        // err.response.status;
        // if (err.response.status == 404) {
        //   console.log("data not found.");
        // }
        console.log(err.message);
      });
  }

  openSummery(id, clientName) {
    this.setState({
      isSummery: !this.state.isSummery,
      userid: id,
      username: clientName,
    });
  }

  //   function openClinetProfile(id) {
  //   setUserId(id);
  //   setClient(!client);
  // }
  openShedule(id) {
    console.log(id);
    this.setState({ isShedule: !this.state.isShedule, userid: id });
  }

  render() {
    return (
      <>
        <div className="appointment-card" style={styles.card}>
          <div style={styles.inner}>
            {this.state.isSummery ? (
              <ASummery
                userid={this.state.userid}
                username={this.state.username}
              />
            ) : (
              <>
                {this.state.isShedule ? (
                  <Reshedule userid={this.state.userid} />
                ) : null}

                <Tabs>
                  <TabList style={styles.tablist2}>
                    <Tab>
                      <strong style={{ padding: "10px", cursor: "pointer" }}>
                        Approved {console.log(this.state.userid)}
                      </strong>
                    </Tab>

                    <Tab>
                      <strong style={{ padding: "10px", cursor: "pointer" }}>
                        Completed
                      </strong>
                    </Tab>
                    <Tab>
                      <strong style={{ padding: "10px", cursor: "pointer" }}>
                        Rejected
                      </strong>
                    </Tab>
                  </TabList>

                  <TabPanel className="scrooler" style={styles.outerscr}>
                    <table
                      style={{
                        overflow: "scroll",
                        borderCollapse: "seperate",
                        borderSpacing: "0rem 1rem",
                      }}
                    >
                      <thead>
                        <tr>
                          <td>Name</td>
                          <td>Location</td>
                          <td>Date</td>
                          <td>Approved Slot</td>
                          <td>join</td>
                          <td>Schedule Consultation</td>
                        </tr>
                      </thead>
                      <tbody>
                        {console.log(this.state.approved)}

                        {this.state.approved.map((e) => (
                          <tr style={styles.tr} className="gradiantblur">
                            <td style={styles.tolink}>{e.clientName}</td>
                            <td>{e.location}</td>
                            <td>{e.date}</td>
                            <td>{e.formated_time}</td>

                            <td>
                              <a
                                href={e.meeting_url}
                                // id={e.id}
                                style={styles.sbtn}
                                // onClick={() => this.openShedule(e.id)}
                                // onClick={this.openShedule(e.id)}
                                // onClick={this.openShedule}
                              >
                                join
                                {/* Reshedule Slot */}
                              </a>
                            </td>
                            <td>
                              <button
                                id={e.id}
                                style={styles.sbtn}
                                onClick={() => this.openShedule(e.id)}
                                // onClick={this.openShedule(e.id)}
                                // onClick={this.openShedule}
                              >
                                Reschedule Slot
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </TabPanel>

                  <TabPanel className="scrooler ">
                    <div className="newscrooler">
                      <table
                        style={{
                          maxHeight: "500px",

                          borderCollapse: "seperate",
                          borderSpacing: "0rem 1rem",
                        }}
                      >
                        <thead>
                          <tr>
                            <td>Name</td>
                            <td>Location</td>
                            <td>Date</td>
                            <td>Approved Slot</td>
                            <td>Summary/prescription</td>
                          </tr>
                        </thead>
                        <tbody>
                          {/* Completed appointment from endpoint */}
                          {this.state.completed.map((e) => (
                            <tr style={styles.tr} className="gradiantblur">
                              <td style={styles.tolink}>{e.clientName}</td>
                              <td>{e.location}</td>
                              <td>{e.date}</td>
                              <td>{e.formated_time}</td>
                              <td>
                                <button
                                  id={e.id}
                                  // onClick={this.openSummery}
                                  onClick={() =>
                                    this.openSummery(e.id, e.clientName)
                                  }
                                >
                                  Summary
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </TabPanel>

                  <TabPanel className="scrooler">
                    <table
                      style={{
                        borderCollapse: "seperate",
                        borderSpacing: "0rem 1rem",
                      }}
                    >
                      <thead>
                        <tr>
                          <td>Name</td>
                          <td>Location</td>
                          <td>Date</td>
                          <td>Rejected Slot</td>
                          {/* <td>Summary/prescription</td> */}
                        </tr>
                      </thead>
                      <tbody>
                        {/* Rejected appointment from endpoint */}
                        {this.state.rejected.map((e) => (
                          <tr style={styles.tr} className="gradiantblur">
                            <td style={styles.tolink}>{e.clientName}</td>
                            <td>{e.location}</td>
                            <td>{e.date}</td>
                            <td>{e.formated_time}</td>
                            {/* <td>
                              <button
                                id={e.id}
                                onClick={() =>
                                  this.openSummery(e.id, e.clientName)
                                }
                                // onClick={this.openSummery}
                              >
                                Summary
                              </button>
                            </td> */}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </TabPanel>
                </Tabs>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

const styles = {
  // outerscr: { height: "500px" },
  tablist2: { width: "100%" },
  card: {
    // display: "flex",
    // justifyContent: "space-between",
    //background:'#9038a3',
    //backdropFilter: "blur(31px) brightness(-15%) opacity(.3)",
    padding: "20px",
    borderRadius: "10px",
    margin: "10px",
  },

  inner: {
    textDecoration: "none",
    color: "#fff",
  },

  info: {
    fontSize: "12px",
  },

  editbtn: {
    border: "1px solid #fff",
    background: "transparent",
    padding: "5px 10px",
    color: "#fff",
    cursor: "pointer",
    borderRadius: "7px",
  },

  manpic: {
    width: "80px",
    height: "80px",
    borderRadius: "50%",
    marginRight: "10px",
  },

  rbtn: {
    background: "#e81a7f",
    color: "#fff",
    padding: "7px 40px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },

  upcom: {
    marginBottom: "2rem",
    display: "flex",
    justifyContent: "space-between",
    width: "auto",
    background: "#9038a3",
    padding: "30px",
  },

  sbtn: {
    background: "#E81A7F",
    border: "1px solid #E81A7F",
  },

  tr: {
    // background: "#772068",
  },
};

export default Appointment;
