import React from "react";
import PropTypes from "prop-types";
import Sidebar from "../components/sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faUser, faBars } from "@fortawesome/free-solid-svg-icons";
import Toggler from "../toggle";
//import Client from './components/clients';
//import Doctor from './components/doctors';
import ClientProfile from "../toggleprofile";
//import Table from "./components/doctor_table";
import Dcstatus from "./components/dcstatus";

import LearningForm from "./components/Learning_form";
//import Searchdoctor from "./components/search";
import Hospitals from "./components/hospitals";
import Salesteams from "./components/salesteams";
import Consultants from "./components/consultants";
import { Redirect } from "react-router";

export default class Overviewpostlearning extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isToggleOn: true, redirect: null, isProfile: true };
    this.profileHandler = this.profileHandler.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.setWapRef = this.setWapRef.bind(this);
    this.clickedOutside = this.clickedOutside.bind(this);
  }

  handleClick() {
    this.setState((prevState) => ({
      isToggleOn: !prevState.isToggleOn,
    }));
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.clickedOutside);

    const token = localStorage.getItem("admin_token");
    if (!token) {
      this.setState({ redirect: "/admin/login" });
    }
  }

  componentWillUnmount() {
    document.addEventListener("mousedown", this.clickedOutside);
  }

  setWapRef(node) {
    this.wapRef = node;
  }

  clickedOutside(e) {
    if (this.wapRef && !this.wapRef.contains(e.target)) {
      return this.setState({ isToggleOn: true });
    }
  }
  profileHandler() {
    this.setState({ isProfile: !this.state.isProfile });
  }
  render() {
    function openMenu() {
      document.querySelector(".sidebar").classList.toggle("show");
    }
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    return (
      <div className="App">
        <Sidebar />
        <header className="overview">
          {this.state.isToggleOn ? null : <Toggler />}
          <p style={{ paddingRight: "10%", textAlign: "right" }}>
            {" "}
            <div>{this.state.isToggleOn ? null : <Toggler />}</div>
            <span ref={this.setWapRef}>
              <FontAwesomeIcon
                onClick={this.handleClick}
                style={{ marginRight: "1rem", cursor: "pointer" }}
                icon={faBell}
              />
              {this.props.children}
            </span>
            <FontAwesomeIcon
              style={{ cursor: "pointer" }}
              icon={faUser}
              onClick={this.profileHandler}
            />{" "}
            Admin
          </p>

          <div style={{ width: "89%", display: "flex", paddingLeft: "10px" }}>
            <span onClick={openMenu} className="menu">
              <FontAwesomeIcon
                style={{ marginRight: "20px" }}
                icon={faBars}
                className="menu-icon"
              />
            </span>
            {this.state.isProfile ? null : <ClientProfile />}
            <span style={{ whiteSpace: "nowrap" }}>Learn it</span>
            <div style={{ padding: "4px", width: "100%" }}>
              <hr style={{ background: "white", color: "white" }} />
            </div>
          </div>
          <div className="mwrapper">
            <div className="mleft">
              {" "}
              <LearningForm />
            </div>
            <div className="mright">
              <div
                style={{
                  fontWeight: "bold",
                  textAlign: "left",
                  paddingLeft: "10px",
                }}
              ></div>
              {/* <Hospitals />
              hello
              <Salesteams />
              <Consultants />
              <Dcstatus /> */}
            </div>
          </div>
        </header>
      </div>
    );
  }
}

Overviewpostlearning.propTypes = {
  children: PropTypes.element.isRequired,
};
