import React from "react";
// import Doctorprofile from "./doctorprofile";
// import Viewlist from "./viewlist";
import axios from "axios";
import { Link } from "react-router-dom";
export default class Tablelist extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isDialog: true,
      background: "#fff",
      islist: false,
      list: [],
    };
    this.handleClick = this.handleClick.bind(this);
    this.colorChange = this.colorChange.bind(this);
  }

  componentDidMount() {
    axios
      .get("doctor/get-doctors/", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Token ${localStorage.getItem("sales")}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        this.setState({ list: res.data.data });
      });
  }

  updateStatus(id) {
    fetch(`https://sukhprasavam.shebirth.com/activateOrDeactivate/`, {
      body: JSON.stringify({ id: id }),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Token ${sessionStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        alert(res.Success);
      });
  }

  showlist() {
    this.setState({
      islist: !this.state.islist,
    });
  }
  colorChange() {
    const color = this.state.background === "#fff" ? "red" : "#fff";

    this.setState({ background: color });
  }

  handleClick() {
    this.setState({
      isDialog: !this.state.isDialog,
    });
  }

  render() {
    return (
      <>
        {/* {this.state.islist ? <Viewlist /> : null} */}

        <div style={styles.card} className="lightgradient2">
          <div style={styles.inner}>
            <p>
              <strong>All doctors List</strong>
            </p>
            <hr style={{ color: "#fff" }} />
            <table style={{ borderSpacing: " 0px 1em" }}>
              <thead>
                <tr style={{ fontWeight: "bold" }}>
                  <td>Name</td>
                  <td>age</td>
                  <td>Hospital</td>
                  <td> Reference Code</td>
                  <td>appointment</td>
                </tr>
              </thead>
              <tbody>
                {console.log(this.state.list)}
                {this.state.list &&
                  this.state.list
                    .filter((ele) => {
                      if (this.props.searchval) {
                        // console.log(search);
                        if (ele && ele.firstname) {
                          return `${ele.firstname}`
                            .toLowerCase()
                            .includes(this.props.searchval.toLowerCase());
                        }
                      } else return ele;
                    })
                    .map((ele) => {
                      return (
                        <tr key={ele.id} className="trfortable">
                          <td>{ele.firstname}</td>
                          <td>{ele.age}</td>
                          <td style={{ textAlign: "left", padding: "10px" }}>
                            {ele.hospitals}
                          </td>

                          <td>{ele.referalId}</td>
                          {/* <td>
                            <select
                              onChange={() => this.updateStatus(ele.id)}
                              style={{
                                background: this.state.background,
                                border: "none",
                              }}
                            >
                              {ele.accountStatus ? (
                                <>
                                  <option>Enable</option>{" "}
                                  <option>Disabled</option>
                                </>
                              ) : (
                                <>
                                  <option>Disabled</option>
                                  <option>Enable</option>
                                </>
                              )}
                            </select>
                          </td> */}
                          <td>
                            <Link to={`/sale/appointlist/${ele.id}`}>
                              <button>Details</button>
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </table>
            {/* {this.state.isDialog ? null : <Doctorprofile />} */}
          </div>
        </div>
      </>
    );
  }
}

const styles = {
  card: {
    // display: "flex",
    justifyContent: "space-between",
    // backdropFilter: "blur(31px) brightness(-15%) opacity(.3)",
    padding: "20px",
    borderRadius: "10px",
    margin: "10px",
  },
  inner: {
    textDecoration: "none",
    color: "#fff",
  },
};
