import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";

export default function Dcstatus() {
  const [count, setcount] = useState();
  const [count2, setcount2] = useState();
  useEffect(() => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Token ${sessionStorage.getItem("token")}`,
      },
    };

    axios
      .get("admin-panel/subscriptions-tab/", config)
      .then((res) => {
        setcount(res.data.activeClients);
        setcount2(res.data.disabledClients);

        //console.log(res.data);0
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);
  return (
    <div style={styles.card} className="lightgradient2">
      <div style={styles.left}>
        <div style={{ display: "flex" }}>
          <div style={{ padding: "10px" }}>
            <h2 style={styles.counter}>{count}</h2>
          </div>
          <div style={{ padding: "10px" }}>
            <p>
              <strong style={{ whiteSpace: "nowrap", fontSize: "16px" }}>
                Active Clients
              </strong>
            </p>
          </div>
        </div>
      </div>
      <div style={styles.right}>
        <div style={{ display: "flex" }}>
          <div style={{ padding: "10px" }}>
            <h2 style={styles.counter}>{count2}</h2>
          </div>
          <div style={{ padding: "10px" }}>
            <p>
              <strong style={{ whiteSpace: "nowrap", fontSize: "16px" }}>
                Disabled Clients
              </strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

const styles = {
  card: {
    display: "flex",
    flexDirection: "column",
    // backdropFilter: "blur(31px) brightness(-15%) opacity(.3)",
    width: "14.8rem",
    height: "7rem",
    padding: "10px",
    borderRadius: "10px",
    margin: "10px",
    lineHeight: "1px",
  },

  left: {
    padding: "0px",
    height: "3.5rem",
    lineHeight: "1px",
    borderBottom: "1px solid #fff",
  },
  right: {
    padding: "0px",
    height: "3.5rem",
    lineHeight: "1px",
    marginTop: "-20px",
  },
  counter: {
    color: "#fff",
  },
};
