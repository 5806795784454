import Tab from './tabs';

export default function Tablelist(){
return (

<Tab/>

);

}




