import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'



class Addconsultant extends React.Component{
constructor(props){
super(props);

this.state = { isOpen:true };
this.handleClick = this.handleClick.bind(this);
}

handleClick(){
this.setState({ isOpen:false });
//window.location ='/login';

}



render(){

return (

<>
{ this.state.isOpen ? 
<div style={styles.model}>

<div style={styles.formmaker}>

<i style={{ marginLeft:'100%' }}><FontAwesomeIcon icon={faTimesCircle} onClick={this.handleClick}/></i>
<h2>Add New Consultant </h2>
<form>
<div style={ styles.plate }>
<div>
<p>Name <input style={ styles.formcontrol } type="text" /></p>
<p>User Name<input style={ styles.formcontrol }  type="text" /></p>
</div>

<div>
<p>Location <input style={ styles.formcontrol }  type="text" /></p>
<p>Password <input style={ styles.formcontrol }  type="text" /></p>
</div>


</div>
<button style={ styles.btn }>Submit</button>

</form>
</div>


</div>
: null }
</>

);


}


} 



const styles = {
model:{
position:'fixed',
top:'0',
bottom:'0',
right:'0',
left:'0',
},

formmaker:{
margin:'22% 10% 20% 21.3%', 
width:'48.8%',
background:'rgb(76, 0, 61) none repeat scroll 0% 0%',
padding:'10px',
borderRadius:'10px'
},

plate:{
display:'flex',
justifyContent:'space-between',
fontSize:'16px',
textAlign:'right'
},

formcontrol:{
marginLeft:'15px',
background:'transparent',
color:'#fff',
border:'1px solid #fff',
padding:'5px'
},

btn:{
padding:'10px',
color:'#fff',
background:'red',
border:'1px solid red'
}


}












export default Addconsultant;
