import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import ExerciseVideo from "../videos/video.mp4";
import AddExercise from "./innercom/add_exersie";
import moment from "moment";
import { withAlert } from "react-alert";
import axios from "axios";
class Exercisetracker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      medicineOpen: true,
      medicineReady: true,
      items: [],
      calories: "",
      dateState: [],
      custom: [],
      url: [],
      addcalorei: "",
      today: "",
    };
    this.handleCalories = this.handleCalories.bind(this);
    this.closeMedicine = this.closeMedicine.bind(this);
    this.addMedicine = this.addMedicine.bind(this);
    this.handleCheckClick = this.handleCheckClick.bind(this);
  }

  closeMedicine() {
    this.setState({ medicineOpen: !this.state.medicineOpen });
  }

  addMedicine() {
    this.setState({ medicineReady: !this.state.medicineReady });
  }
  handleCalories(e) {
    var calorieBurnt = e.target.value;
    this.setState({ addcalorei: e.target.value });
  }

  dateHandler = (e) => {
    var dateselected = e.target.value;

    var date = moment(e.target.value).format("YYYY-MM-DD");
    console.log(date);
    this.setState({ dateState: date }, this.getData.bind(this));
  };
  submitCalories = (e) => {
    e.preventDefault();
    const date = moment(this.state.dateState).format("YYYY-MM-DD");
    const Data = {
      value: this.state.addcalorei,
      date: date,
      //exercise: id,
    };
    const headers = {
      Authorization: `token ` + localStorage.getItem("token"),
    };
    axios
      .post("customer/calories-burnt-add/", Data, {
        headers: headers,
      })
      .then(() => {
        alert("calories was submitted");
        window.location.reload();
      })
      .catch((error) => {
        alert("error try again");
      });
  };

  componentWillMount() {
    this.getData();
  }
  getData() {
    if (localStorage.getItem("token") === null) {
      localStorage.clear();
      window.location = "/clogin";
    }
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("token"),
      },
    };
    const date = moment(this.state.dateState).format("YYYY-MM-DD");

    this.setState({ today: date });
    axios.get("customer/exercise-get/?date=" + date, config).then((res) => {
      let ytUrl = res.data.url;
      if (res.data.url && ytUrl) {
        let newurl = ytUrl.replace("/watch?v=", "/embed/");
        this.setState({ url: newurl });
      } else {
        let newurl = " https://www.youtube.com/embed/NLOp_6uPccQ";
        this.setState({ url: newurl });
      }
      console.log(res.data);
      //this.setState({ url: newurl });
      this.setState({ calories: res.data.calorieBurnt });
      this.setState({ items: res.data.exercises });

      this.setState({ custom: res.data.custom });
    });
  }

  handleCheckClick = (e, stateVal, index, alert) => {
    //modifying the state based on chec value

    //
    let prevState = [...this.state[stateVal]];
    // console.log(prevState);
    prevState[index].completed = e.target.checked;
    this.setState({ [stateVal]: prevState });
    var date = moment(this.state.dateState).format("YYYY-MM-DD");
    const { id, checked } = e.target.dataset;

    // var checkbox = e.target.checked;
    if (e.target.checked) {
      var checkbox = "True";
    } else {
      var checkbox = "False";
    }
    const Data = {
      completed: checkbox,
      date: date,
      exercise: id,
    };
    const headers = {
      Authorization: `token ` + localStorage.getItem("token"),
    };
    axios
      .post("customer/exercise-submit/", Data, {
        headers: headers,
      })
      .then(() => {
        alert.show("Exercise was submitted!");
      })
      .catch((error) => {
        alert.show(error);
      });

    //  if (e.target.checked === "true") {
    //    console.log(name, checked);
    //  } else {
    //    console.log(name, checked);
    //  }
  };

  CustumhandleCheckClick = (e, stateVal, index, alert) => {
    //modifying the state based on chec value

    //
    let prevState = [...this.state[stateVal]];
    console.log(prevState);
    prevState[index].completed = e.target.checked;
    console.log(prevState[index].completed);
    this.setState({ [stateVal]: prevState });
    var date = moment(this.state.dateState).format("YYYY-MM-DD");
    const { id, checked } = e.target.dataset;

    if (e.target.checked) {
      var checkbox = "True";
    } else {
      var checkbox = "False";
    }

    const Data = {
      completed: checkbox,
      date: date,
      exercise: id,
    };
    const headers = {
      Authorization: `token ` + localStorage.getItem("token"),
    };
    axios
      .post("customer/custom-exercise-submit/", Data, {
        headers: headers,
      })
      .then(() => {
        alert.show("Exercise was submitted!");
      })
      .catch((error) => {
        alert.show(error);
      });

    //  if (e.target.checked === "true") {
    //    console.log(name, checked);
    //  } else {
    //    console.log(name, checked);
    //  }
  };
  render() {
    const alert = this.props.alert;
    return (
      <>
        {this.state.medicineOpen ? (
          <div style={styles.card}>
            <div style={styles.inner}>
              <Tabs>
                <TabList
                  style={{
                    width: "100%",
                    listStyle: "none",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Tab>
                    <strong style={{ padding: "10px", cursor: "pointer" }}>
                      Exercise Tracker
                    </strong>
                  </Tab>
                  <Tab>
                    <strong style={{ padding: "10px", cursor: "pointer" }}>
                      Customized Exercise
                    </strong>
                  </Tab>
                </TabList>

                <TabPanel>
                  <div style={styles.backgroundcol}>
                    <iframe
                      width="560"
                      height="315"
                      class="videoexerisise"
                      src={this.state.url}
                      frameborder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                  {/* <video
                    src={this.state.url }
                    alt=""
                    height="350rem"
                    width="600rem"
                    controls
                  ></video> */}
                  <div className="table-wrapper">
                    <table
                      style={{
                        marginTop: "20px",
                        background: "#ffffff1c 0% 0% no-repeat padding-box",
                      }}
                    >
                      <tr>
                        <td>
                          {" "}
                          <input
                            type="date"
                            className="calanderbutton"
                            onChange={this.dateHandler}
                            defaultValue={this.state.today}
                          />
                        </td>
                      </tr>
                      <tr className="exereriscegrid">
                        {this.state.items.map((personData, key) => {
                          return (
                            <>
                              <td key={personData.id}>{personData.name}</td>{" "}
                              <td>
                                <input
                                  type="checkbox"
                                  data-id={personData.id}
                                  checked={personData && personData.completed}
                                  onChange={(e) =>
                                    this.handleCheckClick(
                                      e,
                                      "items",
                                      key,
                                      alert
                                    )
                                  }
                                  // onChange={this.handleCheckClick}
                                />
                              </td>
                            </>
                          );
                        })}
                      </tr>

                      <tr className="exereriscegrid">
                        {this.state.custom.map((personData, key) => {
                          return (
                            <>
                              <td key={personData.id}>{personData.name}</td>{" "}
                              <td>
                                <input
                                  type="checkbox"
                                  data-id={personData.id}
                                  checked={personData && personData.completed}
                                  // onChange={this.CustumhandleCheckClick}

                                  onChange={(e) =>
                                    this.CustumhandleCheckClick(
                                      e,
                                      "custom",
                                      key,
                                      alert
                                    )
                                  }
                                />
                              </td>
                            </>
                          );
                        })}
                      </tr>
                    </table>
                  </div>
                  <br />
                  <div style={styles.caloribackgroundcol} className="calori">
                    <input
                      style={styles.inputpink}
                      type="number"
                      // data-id={personData.id}
                      defaultValue={this.state.calories}
                      placeholder="kcal"
                      onChange={this.handleCalories}
                    />
                    <p style={{ margin: "5%", fontSize: "16px" }}>
                      {" "}
                      Total Calories Burned
                    </p>
                    <button
                      type="submit"
                      onClick={this.submitCalories}
                      style={styles.greenbtn}
                      className="bluebutton2"
                    >
                      Submit
                    </button>
                  </div>
                  <br />
                  {this.state.medicineReady ? null : <AddExercise />}
                  <button
                    style={styles.subbtn}
                    className="pinkbutton"
                    onClick={this.addMedicine}
                  >
                    Add New User Defined Exercise Here
                  </button>
                  {/* <span>Add new suser defined exercise here</span> */}
                  <hr />
                  {/* <button style={styles.subbtn} className="pinkbutton">
                    Submit
                  </button> */}
                </TabPanel>

                <TabPanel style={styles.cdiet}></TabPanel>
              </Tabs>
            </div>
          </div>
        ) : null}
      </>
    );
  }
}
export default withAlert()(Exercisetracker);

const styles = {
  backgroundcol: { background: "#ffffff1c 0% 0% no-repeat padding-box" },
  caloribackgroundcol: {
    background: "#ffffff1c 0% 0% no-repeat padding-box",
    display: "flex",
    justifyContent: "space-around",
  },
  module: {
    position: "absolute",
    background: "rgb(76, 0, 61) none repeat scroll 0% 0%",
    margin: "10px",
    borderRadius: "10px",
    // padding:'20px 10px'
  },

  subbtn: {
    // background: "linear-gradient(90deg, #3a51a7 30%, #b53899)",
    padding: "10px 30px",
    border: "none",
    borderRadius: "10px",
    color: "#fff",
    cursor: "pointer",
  },

  btn: {
    background: "linear-gradient(90deg, #3a51a7 30%, #b53899)",
    padding: "10px 20px",
    border: "none",
    borderRadius: "10px",
    color: "#fff",
    cursor: "pointer",
    margin: "10px 0px",
  },

  card: {
    height: "550px",
    overflowY: "scroll",
    scrollbarWidth: "none",
    display: "flex",
    justifyContent: "space-between",
    //background: "#8B3598",
    padding: "0px",
    borderRadius: "10px",
    margin: "10px",
  },

  inner: {
    textDecoration: "none",
    color: "#fff",
    width: "100%",
  },
  trgrid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
  },
  cdiet: {
    height: "18rem",
    overflowY: "scroll",

    scrollbarWidth: "none",
    boxSizing: "content-box",
  },

  inputpink: {
    background:
      "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11",
    border: "none",
    color: "white",
    margin: "5%",
  },
  greenbtn: {
    // background: "rgb(0, 200, 52)",
    border: "none",
    borderRadius: "0px; margin: 5%",
    margin: "5%",
    color: "white",
  },
};
