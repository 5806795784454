import { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

export default function AlltimeClientsTable(searchval) {
  const [clientDetails, setClientDetails] = useState([]);
  const [tracker, setTracker] = useState(false);
  const [profile, setProfile] = useState(false);
  const [clientid, setClientid] = useState("");

  function openDailyTracker(id) {
    setTracker(!tracker);
    setClientid(id);
  }
  function openProfile(id) {
    setProfile(!profile);
    setClientid(id);
  }

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Token ${localStorage.getItem("admin_token")}`,
      },
    };
    // Define a function to fetch data from the API
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "http://127.0.0.1:8000/admin_dashboard_details/",
          config
        );
        setClientDetails(response.data.clientDetails); // Assuming the API returns an array of client details under 'all_clients'
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const updateStatus = (id) => {
    const config = {
      headers: {
        Authorization: `Token ${localStorage.getItem("admin_token")}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    const requestData = { id: id };

    axios
      .post(`http://127.0.0.1:8000/activateOrDeactivate/`, requestData, config)
      .then((response) => {
        alert(response.data.Success);
      })
      .catch((error) => {
        alert("An error occurred while updating the status.");
      });
  };

  return (
    <>
      <div style={styles.card}>
        <div style={styles.inner}>
          <p>
            <strong>Free Users</strong>
          </p>
          <hr style={{ color: "#fff" }} />
          <div className="formobile lightgradient" style={{ height: "680px" }}>
            <table className="nospace">
              <thead>
                <tr className="gradiantblur">
                  <td>Name</td>
                  <td>Age</td>
                  <td>Location</td>
                  <td>Due Date</td>
                  <td>Current Week</td>
                  <td>membership</td> <td>Account Status</td>
                </tr>
              </thead>
              <tbody>
                {clientDetails.map((client) => {
                  const {
                    id,
                    firstname,
                    lastname,
                    age,
                    location,
                    dueDate,
                    week,
                    subscription,
                    is_active,
                  } = client;

                  const isSubscribed =
                    subscription &&
                    subscription.trim().toLowerCase() === "no plans";

                  if (isSubscribed) {
                    return (
                      <tr key={id} className="gradiantblur">
                        <td
                          style={styles.usernav}
                          onClick={() => openProfile(id)}
                        >
                          {" "}
                          <Link
                            to={`/admin/client/${id}`}
                            // style={styles.tolink}
                          >
                            {firstname} {lastname}
                          </Link>
                          {/* {firstname} {lastname} */}
                        </td>
                        <td>{age}</td>
                        <td>{location}</td>
                        <td>{dueDate}</td>
                        <td>{week}</td>
                        <td>{subscription}</td>
                        <td>
                          <select
                            // onChange={() => handleColor()}
                            // style={{ background, border: "none" }}
                            onChange={() => updateStatus(id)}
                          >
                            {is_active == true ? (
                              <>
                                <option>Enable</option>{" "}
                                <option>Disabled</option>
                              </>
                            ) : (
                              <>
                                <option>Disabled</option>
                                <option>Enable</option>
                              </>
                            )}
                          </select>
                        </td>
                      </tr>
                    );
                  }

                  return null;
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

const styles = {
  card: {
    // display: "flex",
    justifyContent: "space-between",
    // background: "#4c003d",
    padding: "20px",
    borderRadius: "10px",
    margin: "10px",
  },
  inner: {
    textDecoration: "none",
    color: "#fff",
  },

  usernav: {
    // background: "#4c005d",
    padding: "5px 10px",
    cursor: "pointer",
  },
  red: { background: "red", border: "1px solid red", padding: "8% 30%" },
  green: { background: "green", border: "1px solid green", padding: "8% 30%" },
  yellow: {
    background: "yellow",
    border: "1px solid yellow",
    padding: "8% 30%",
  },
};
