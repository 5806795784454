import React from "react";
import moment from "moment";
import axios from "axios";
export default class AddExercise extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isDone: true, allValues: "" };
    this.medicineClose = this.medicineClose.bind(this);
  }

  medicineClose() {
    this.setState({ isDone: false });
  }
  changeHandler = (e) => {
    this.setState({ allValues: e.target.value });
  };

  submitValue = (e) => {
    e.preventDefault();

    const Data = {
      name: this.state.allValues,
      // module: "exercise",
    };

    // const formData = new FormData(e.currentTarget);
    //const formData = this.allValues.;
    // var object = {};
    // formData.forEach(function (value, key) {
    //   object[key] = value;
    // });
    // var json = JSON.stringify(object);

    // formData.append("data", json);

    const headers = {
      Authorization: `token ` + localStorage.getItem("sales"),
    };
    axios
      .post("customer/add-custom-exercise/", Data, {
        headers: headers,
      })
      .then(() => {
        alert("Exersise was submitted");
      })
      .catch((error) => {
        alert("Cannot add Medicine again");
      });
  };

  render() {
    return (
      <>
        {this.state.isDone ? (
          <div style={styles.module} className="newexersice">
            <h4 style={{ margin: "0px" }}>Add new user defined exercise</h4>
            <hr style={{ color: "#fff" }} />
            <form onSubmit={this.submitValue}>
              <table
                style={{ borderCollapse: "seperate", borderSpacing: "0em 1em" }}
              >
                <tr style={styles.tr}>
                  <td style={styles.tdstyle}>Exercise</td>
                  <td>
                    <input
                      type="text"
                      name="name"
                      style={styles.label}
                      onChange={this.changeHandler}
                      value={this.state.value}
                    />
                  </td>
                  <td style={styles.tdstyle}>
                    <button style={styles.greenbutton} type="submit">
                      Add
                    </button>
                  </td>
                </tr>
              </table>
            </form>

            <button
              style={styles.button}
              className="pinkbutton"
              onClick={this.medicineClose}
            >
              Done
            </button>
          </div>
        ) : null}
      </>
    );
  }
}

const styles = {
  tdstyle: { textAlign: "center" },
  module: {
    padding: "10px 0px",
    position: "absolute",
    // background:
    // " 0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
    background: "#ad6db0",
    zIndex: "10",
    top: "100%",
    right: "40%",
    //borderRadius: "10px",
  },

  tr: {
    display: "grid",
    gridTemplateColumns: "2fr 2fr 1fr",
    // background: "#86337d",
    // boxShadow: "inset 20px 20px 60px #722b6a",
    //             "inset -20px -20px 60px #9a3b90",
    textAlign: "left",
  },
  greenbutton: {
    // background: "#48b648",
    borderRadius: "0px",
    border: "none",
  },
  button: {
    // background: "linear-gradient(90deg, #3a51a7 30%, #b53899)",
    border: "none",
    color: "#fff",
    padding: "10px 30px",
    cursor: "pointer",
    borderRadius: "0px!important",
  },
};
