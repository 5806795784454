import React from "react";
import axios from "axios";
import AddMedicine from "./innercom/add_medicine";
import AddSymptom from "./innercom/add_symptom";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import img1 from "./img/symp/Group1123.png";
import img2 from "./img/symp/Group1124.png";

import img3 from "./img/symp/Group1125.png";
import img4 from "./img/symp/Group1126.png";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";

export default class SymptomTracker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      medicineOpen: true,
      medicineReady: true,
      symptomReady: false,
      items: [],
      dateState: [],
      custum: [],
      navStatus: false,
      Pelvis: false,
      Legs: false,
      today: "",
      report: [],
      Other: false,
      custumclose: false,
      Mental: false,
      symptomsWithIputs: [],
      allValues: {
        others: "",
        bloodSugar: "",
        bloodPressure: "",
      },
    };
    this.closeMedicine = this.closeMedicine.bind(this);
    this.addMedicine = this.addMedicine.bind(this);
    this.navClose = this.navClose.bind(this);
    this.addSymptom = this.addSymptom.bind(this);
  }

  closeMedicine() {
    this.setState({ medicineOpen: !this.state.medicineOpen });
  }

  addMedicine() {
    this.setState({ medicineReady: !this.state.medicineReady });
  }

  addSymptom() {
    this.setState({ symptomReady: !this.state.symptomReady });
  }

  dateHandler = (e) => {
    var dateselected = e.target.value;

    var date = moment(e.target.value).format("YYYY-MM-DD");
    console.log(date);
    this.setState({ dateState: date }, this.getData.bind(this));
  };

  componentWillMount() {
    this.getData();
  }
  getData() {
    var date = moment(this.state.dateState).format("YYYY-MM-DD");
    this.setState({ today: date });
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("token"),
      },
    };
    // const date = moment().format("YYYY-MM-DD");
    axios.get("customer/Symptoms-GET/?date=" + date, config).then((res) => {
      console.log(res.data);
      this.setState({ items: res.data.Symptoms });
      this.setState({ custum: res.data.customSymptom });
      this.setState({ allValues: res.data.symptomsWithIputs });
      this.setState({ report: res.data.last_week_symptom_report });
    });
  }

  navClose(e) {
    if (e == "Head") {
      if (this.state.navStatus == true)
        this.setState({
          navStatus: false,
        });
      else {
        this.setState({
          navStatus: true,
        });
      }
    }
    if (e == "Pelvis") {
      if (this.state.Pelvis == true)
        this.setState({
          Pelvis: false,
        });
      else {
        this.setState({
          Pelvis: true,
        });
      }
    }
    if (e == "Legs") {
      if (this.state.Legs == true)
        this.setState({
          Legs: false,
        });
      else {
        this.setState({
          Legs: true,
        });
      }
    }
    if (e == "Other") {
      if (this.state.Other == true)
        this.setState({
          Other: false,
        });
      else {
        this.setState({
          Other: true,
        });
      }
    }
    if (e == "Abdomen") {
      if (this.state.Abdomen == true)
        this.setState({
          Abdomen: false,
        });
      else {
        this.setState({
          Abdomen: true,
        });
      }
    }
    if (e == "Mental") {
      if (this.state.Mental == true)
        this.setState({
          Mental: false,
        });
      else {
        this.setState({
          Mental: true,
        });
      }
    }
    if (e == "custumclose") {
      if (this.state.custumclose == true)
        this.setState({
          custumclose: false,
        });
      else {
        this.setState({
          custumclose: true,
        });
      }
    }
  }
  custumhandleCheckClick = (e, stateVal, index) => {
    //modifying the state based on chec value
    let prevState = [...this.state[stateVal]];
    prevState[index].positive = e.target.checked;
    this.setState({ [stateVal]: prevState });
    var date = moment(this.state.dateState).format("YYYY-MM-DD");
    const { id, checked } = e.target.dataset;

    if (e.target.checked) {
      var checkbox = "True";
    } else {
      var checkbox = "False";
    }

    const Data = {
      positive: checkbox,
      date: date,
      symptom: id,
    };
    const headers = {
      Authorization: `token ` + localStorage.getItem("token"),
    };
    axios
      .post("customer/submit-custom-symptom/", Data, {
        headers: headers,
      })
      .then(() => {
        alert("symptom was submitted");
      })
      .catch((error) => {
        alert("Cannot add symptom again");
      });

    //  if (e.target.checked === "true") {
    //    console.log(name, checked);
    //  } else {
    //    console.log(name, checked);
    //  }
  };

  handleCheckClick = (e, stateVal, index) => {
    //modifying the state based on chec value
    let prevState = [...this.state[stateVal]];
    prevState[index].positive = e.target.checked;
    console.log(index);
    this.setState({ [stateVal]: prevState });
    var date = moment(this.state.dateState).format("YYYY-MM-DD");
    const { id, checked } = e.target.dataset;
    console.log(stateVal);
    if (e.target.checked) {
      var checkbox = "True";
    } else {
      var checkbox = "False";
    }

    const Data = {
      positive: checkbox,
      date: date,
      symptom: id,
    };
    const headers = {
      Authorization: `token ` + localStorage.getItem("token"),
    };
    axios
      .post("customer/symptoms-submit/", Data, {
        headers: headers,
      })
      .then(() => {
        alert("symptom was submitted");
        this.getData();
      })
      .catch((error) => {
        alert("Cannot add symptoms again");
      });

    //  if (e.target.checked === "true") {
    //    console.log(name, checked);
    //  } else {
    //    console.log(name, checked);
    //  }
  };
  changeHandler = (e) => {
    this.setState({
      allValues: { ...this.state.allValues, [e.target.name]: e.target.value },
    });
  };
  onsubmbit = (e) => {
    var pikerdate2 = moment(this.state.dateState).format("YYYY-MM-DD");
    e.preventDefault();

    //console.log(pikerdate2);
    const Data = {
      others: this.state.allValues.others,
      bloodSugar: this.state.allValues.bloodSugar,
      bloodPressure: this.state.allValues.bloodPressure,
      date: pikerdate2,
    };
    // console.log(this.state.allValues.others);
    // const Data = this.state.allValues;
    const headers = {
      Authorization: `token ` + localStorage.getItem("token"),
    };
    axios
      .patch("customer/submit-symptoms-with-input/", Data, {
        headers: headers,
      })
      .then(() => {
        alert("symptom was submitted");
        window.location.reload();
      })
      .catch((error) => {
        alert("Cannot add symptoms again");
      });
  };

  render() {
    return (
      <>
        {/* {console.log(this.state.navStatus)} */}
        {/* {console.log(this.state.items.nausea)} */}
        {this.state.medicineOpen ? (
          <div className="symptom-container" style={styles.module}>
            {this.state.medicineReady ? null : <AddMedicine />}

            <h2
              className="lightgradient"
              style={{
                borderBottom: "1px solid #fff",
                padding: "10px",
                margin: "0px",
                fontSize: "24px",
                // background: "#6f1f6d",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
              }}
            >
              Symptoms Tracker
            </h2>
            {/* <table>
              <tr>
                <div>
                  <FontAwesomeIcon icon={faSearch} /> Symtoms
                </div>
              </tr>
              <tr>
                <td></td>
              </tr>{" "}
              <tr>
                <td></td>
              </tr>
              <tr>
                {this.state.items.nausea}
                <td>
                  {this.state.items.nausea}
                  <input
                    type="checkbox"
                    defaultChecked={this.state.items.nausea}
                  />
                  <strong>Nausea</strong>
                </td>
                <td style={styles.left}>
                  {" "}
                  <strong>
                    <input
                      type="checkbox"
                      defaultChecked={this.state.items.vomiting}
                    />
                    Vomiting
                  </strong>{" "}
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    defaultChecked={this.state.items.backpain}
                  />
                  <strong>Backpain </strong>
                </td>
                <td style={styles.left}>
                  {" "}
                  <strong>
                    <input
                      type="checkbox"
                      defaultChecked={this.state.items.headache}
                    />
                    Headache
                  </strong>{" "}
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    defaultChecked={this.state.items.constipation}
                  />
                  <strong>Constipation</strong>{" "}
                </td>
                <td style={styles.left}>
                  <strong>
                    <input
                      type="checkbox"
                      defaultChecked={this.state.items.frequent_urination}
                    />
                    Frequent Urination
                  </strong>
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    defaultChecked={this.state.items.nausea}
                  />
                  <strong>Tingling and Aching Breasts</strong>{" "}
                </td>
                <td style={styles.left}>
                  <input
                    type="checkbox"
                    defaultChecked={this.state.items.nausea}
                  />{" "}
                  <strong>Bloating</strong>
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    defaultChecked={this.state.items.nausea}
                  />{" "}
                  <strong>Motion Sickness</strong>{" "}
                </td>
                <td style={styles.left}>
                  <input
                    type="checkbox"
                    defaultChecked={this.state.items.nausea}
                  />
                  <strong>High Blood pressure</strong>
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    defaultChecked={this.state.items.nausea}
                  />{" "}
                  <strong>Acne</strong>{" "}
                </td>
                <td style={styles.left}>
                  <input
                    type="checkbox"
                    defaultChecked={this.state.items.nausea}
                  />
                  <strong>Heartburn</strong>
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    defaultChecked={this.state.items.nausea}
                  />
                  <strong>Pregnency grow</strong>{" "}
                </td>
                <td style={styles.left}>
                  {" "}
                  <input
                    type="checkbox"
                    defaultChecked={this.state.items.nausea}
                  />
                  <strong>Dental Problem</strong>{" "}
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    defaultChecked={this.state.items.nausea}
                  />
                  <strong>Palpiataion</strong>{" "}
                </td>
                <td style={styles.left}>
                  <input
                    type="checkbox"
                    defaultChecked={this.state.items.nausea}
                  />
                  <strong>Giddiness</strong>
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    defaultChecked={this.state.items.nausea}
                  />
                  <strong>Blured Vision</strong>{" "}
                </td>
                <td style={styles.left}>
                  <input
                    type="checkbox"
                    defaultChecked={this.state.items.nausea}
                  />
                  <strong>Dizziness</strong>{" "}
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    defaultChecked={this.state.items.nausea}
                  />
                  <strong>Swilling</strong>{" "}
                </td>
                <td style={styles.left}>
                  <input
                    type="checkbox"
                    defaultChecked={this.state.items.nausea}
                  />{" "}
                  <strong>Stress</strong>
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    defaultChecked={this.state.items.nausea}
                  />{" "}
                  <strong>Froathy Urine</strong>
                </td>
                <td style={styles.left}>
                  <input
                    type="checkbox"
                    defaultChecked={this.state.items.nausea}
                  />
                  <strong>Anxiety</strong>{" "}
                </td>
              </tr>
            </table> */}

            <div style={styles.boxstyle}>
              {/* <div>
                <FontAwesomeIcon icon={faSearch} /> Symtoms
              </div> */}
              <span style={{ fontSize: "16px" }}> Enter Date</span>
              <input
                type="date"
                className="calanderbutton"
                onChange={this.dateHandler}
                defaultValue={this.state.today}
                //Value={pureDate[1]}
              />

              <>
                <div className="accordion">
                  <div className="accordion-item">
                    <div
                      className="accordion-title gradiantblur"
                      onClick={() => this.navClose("Head")}
                    >
                      {/* // onClick={setActiveCurrentIndex(item[0].date)}> */}
                      <div>Head</div>
                      <i class="fa fa-plus" aria-hidden="true"></i>
                    </div>
                    {this.state.navStatus ? (
                      <div className="accordion-content  tableforsymtm">
                        {this.state.items
                          .filter((person) => person.category == "Head")
                          .map((personData, key) => {
                            return (
                              <span className="trforsymtm trin ">
                                <td key={personData.id}>
                                  <input
                                    // className="invinsiveinput"
                                    data-id={personData.id}
                                    type="checkbox"
                                    id={personData.id}
                                    checked={personData && personData.positive}
                                    onChange={(e) =>
                                      this.handleCheckClick(e, "items", key)
                                    }
                                    // defaultChecked={personData && personData.positive}
                                    // onChange={this.handleCheckClick}
                                  />
                                  {/* <label for={personData.id}>{personData.name}</label> */}
                                </td>
                                <td className="tdoneline">{personData.name}</td>
                              </span>
                            );
                          })}
                      </div>
                    ) : null}
                  </div>
                  {/* ))} */}
                  <div className="accordion-item">
                    <div
                      className="accordion-title gradiantblur"
                      onClick={() => this.navClose("Pelvis")}
                      // onClick={setActiveCurrentIndex(item[0].date)}
                    >
                      <div>Pelvis</div>
                      <i class="fa fa-plus" aria-hidden="true"></i>
                    </div>
                    {this.state.Pelvis ? (
                      <div className="accordion-content  tableforsymtm">
                        {this.state.items
                          .filter((person) => person.category == "Pelvis")
                          .map((personData, key) => {
                            return (
                              <span className="trforsymtm trin">
                                <td key={personData.id}>
                                  <input
                                    // className="invinsiveinput"
                                    data-id={personData.id}
                                    type="checkbox"
                                    id={personData.id}
                                    checked={personData && personData.positive}
                                    onChange={(e) =>
                                      this.handleCheckClick(e, "items", key)
                                    }
                                    // defaultChecked={personData && personData.positive}
                                    // onChange={this.handleCheckClick}
                                  />
                                  {/* <label for={personData.id}>{personData.name}</label> */}
                                </td>
                                <td className="tdoneline">{personData.name}</td>
                              </span>
                            );
                          })}
                      </div>
                    ) : null}
                  </div>
                  {/* ))} */}
                  <div className="accordion-item">
                    <div
                      className="accordion-title gradiantblur"
                      onClick={() => this.navClose("Legs")}
                      // onClick={setActiveCurrentIndex(item[0].date)}
                    >
                      <div>Legs</div>{" "}
                      <i class="fa fa-plus" aria-hidden="true"></i>
                    </div>
                    {this.state.Legs ? (
                      <div className="accordion-content  tableforsymtm">
                        {this.state.items
                          .filter((person) => person.category == "Legs")
                          .map((personData, key) => {
                            return (
                              <span className="trforsymtm trin">
                                <td key={personData.id}>
                                  <input
                                    // className="invinsiveinput"
                                    data-id={personData.id}
                                    type="checkbox"
                                    id={personData.id}
                                    checked={personData && personData.positive}
                                    onChange={(e) =>
                                      this.handleCheckClick(e, "items", key)
                                    }
                                    // defaultChecked={personData && personData.positive}
                                    // onChange={this.handleCheckClick}
                                  />
                                  {/* <label for={personData.id}>{personData.name}</label> */}
                                </td>
                                <td className="tdoneline">{personData.name}</td>
                              </span>
                            );
                          })}
                      </div>
                    ) : null}
                  </div>
                  {/* ))} */}
                  <div className="accordion-item">
                    <div
                      className="accordion-title gradiantblur"
                      onClick={() => this.navClose("Other")}
                      // onClick={setActiveCurrentIndex(item[0].date)}
                    >
                      <div>Other</div>{" "}
                      <i class="fa fa-plus" aria-hidden="true"></i>
                    </div>
                    {this.state.Other ? (
                      <div className="accordion-content  tableforsymtm">
                        {this.state.items
                          .filter((person) => person.category == "Other")
                          .map((personData, key) => {
                            return (
                              <span className="trforsymtm trin">
                                <td key={personData.id}>
                                  <input
                                    // className="invinsiveinput"
                                    data-id={personData.id}
                                    type="checkbox"
                                    id={personData.id}
                                    checked={personData && personData.positive}
                                    onChange={(e) =>
                                      this.handleCheckClick(e, "items", key)
                                    }
                                    // defaultChecked={personData && personData.positive}
                                    // onChange={this.handleCheckClick}
                                  />
                                  {/* <label for={personData.id}>{personData.name}</label> */}
                                </td>
                                <td className="tdoneline">{personData.name}</td>
                              </span>
                            );
                          })}
                      </div>
                    ) : null}
                  </div>
                  {/* ))} */}
                  <div className="accordion-item">
                    <div
                      className="accordion-title gradiantblur"
                      onClick={() => this.navClose("Abdomen")}
                      // onClick={setActiveCurrentIndex(item[0].date)}
                    >
                      <div>Abdomen</div>{" "}
                      <i class="fa fa-plus" aria-hidden="true"></i>
                    </div>
                    {this.state.Abdomen ? (
                      <div className="accordion-content  tableforsymtm">
                        {this.state.items
                          .filter((person) => person.category == "Abdomen")
                          .map((personData, key) => {
                            return (
                              <span className="trforsymtm trin">
                                <td key={personData.id}>
                                  <input
                                    // className="invinsiveinput"
                                    data-id={personData.id}
                                    type="checkbox"
                                    id={personData.id}
                                    checked={personData && personData.positive}
                                    onChange={(e) =>
                                      this.handleCheckClick(e, "items", key)
                                    }
                                    // defaultChecked={personData && personData.positive}
                                    // onChange={this.handleCheckClick}
                                  />
                                  {/* <label for={personData.id}>{personData.name}</label> */}
                                </td>
                                <td className="tdoneline">{personData.name}</td>
                              </span>
                            );
                          })}
                      </div>
                    ) : null}
                  </div>
                  {/* ))} */}
                  <div className="accordion-item">
                    <div
                      className="accordion-title gradiantblur"
                      onClick={() => this.navClose("Mental")}
                      // onClick={setActiveCurrentIndex(item[0].date)}
                    >
                      <div>Mental Health</div>{" "}
                      <i class="fa fa-plus" aria-hidden="true"></i>
                    </div>
                    {this.state.Mental ? (
                      <div className="accordion-content  tableforsymtm">
                        {this.state.items
                          .filter(
                            (person) => person.category == "Mental Health"
                          )
                          .map((personData, key) => {
                            return (
                              <span className="trforsymtm trin">
                                <td key={personData.id}>
                                  <input
                                    // className="invinsiveinput"
                                    data-id={personData.id}
                                    type="checkbox"
                                    id={personData.id}
                                    checked={personData && personData.positive}
                                    onChange={(e) =>
                                      this.handleCheckClick(e, "items", key)
                                    }
                                    // defaultChecked={personData && personData.positive}
                                    // onChange={this.handleCheckClick}
                                  />
                                  {/* <label for={personData.id}>{personData.name}</label> */}
                                </td>
                                <td className="tdoneline">{personData.name}</td>
                              </span>
                            );
                          })}
                      </div>
                    ) : null}
                  </div>
                  {/* ))} */}
                  <div className="accordion-item">
                    <div
                      className="accordion-title gradiantblur"
                      onClick={() => this.navClose("custumclose")}
                      // onClick={setActiveCurrentIndex(item[0].date)}
                    >
                      <div>User Defined</div>{" "}
                      <i class="fa fa-plus" aria-hidden="true"></i>
                    </div>
                    {this.state.custumclose ? (
                      <div className="accordion-content  tableforsymtm">
                        {this.state.custum.map((personData, key) => {
                          return (
                            <span className="trforsymtm trin">
                              <td key={personData.id}>
                                <input
                                  data-id={personData.id}
                                  type="checkbox"
                                  checked={personData && personData.positive}
                                  onChange={(e) =>
                                    this.custumhandleCheckClick(
                                      e,
                                      "custum",
                                      key
                                    )
                                  }
                                  // defaultChecked={personData && personData.positive}
                                  // onChange={this.custumhandleCheckClick}
                                />
                              </td>
                              <td className="tdoneline">{personData.name}</td>
                            </span>
                          );
                        })}
                      </div>
                    ) : null}
                  </div>
                  {/* ))} */}
                </div>
              </>

              {/* <tr style={styles.trgrid}>
                {this.state.items
                  .filter((person) => person.category == "Chest")
                  .map((personData, key) => {
                    return (
                      <>
                        <td key={personData.id}>
                          <input
                            // className="invinsiveinput"
                            data-id={personData.id}
                            type="checkbox"
                            id={personData.id}
                            checked={personData && personData.positive}
                            onChange={(e) =>
                              this.handleCheckClick(e, "items", key)
                            }
                            // defaultChecked={personData && personData.positive}
                            // onChange={this.handleCheckClick}
                          />
                          {/* <label for={personData.id}>{personData.name}</label> */}
              {/* </td>
                        <td>
                          {personData.name}
                        </td>
                      </>
                    );
                  })}
                {this.state.custum.map((personData, key) => {
                  return (
                    <>
                      <td key={personData.id}>
                        <input
                          data-id={personData.id}
                          type="checkbox"
                          checked={personData && personData.positive}
                          onChange={(e) =>
                            this.custumhandleCheckClick(e, "custum", key)
                          }
                          // defaultChecked={personData && personData.positive}
                          // onChange={this.custumhandleCheckClick}
                        />
                      </td>
                      <td>
                        <strong> {personData.symptom}</strong>
                      </td>
                    </>
                  );
                })} */}
              {/* </tr>  */}
            </div>

            <form onSubmit={this.onsubmbit}>
              <div style={styles.flex}>
                <div
                  className="marign-5"
                  style={{
                    transform: "rotate(90deg)",
                    fontSize: "4rem",
                    padding: "15px",
                  }}
                >
                  <img src={img1} />
                  {/* <i className="fa fa-pencil"></i> */}
                </div>
                <div className="marign-5" style={{ padding: "10px" }}>
                  <strong style={{ fontSize: "18px" }}>
                    Enter your weight
                  </strong>
                  <br />
                  <br />

                  <input
                    className="inputofsymtum"
                    style={{ float: "left" }}
                    name="others"
                    placeholder="Kg"
                    onChange={this.changeHandler}
                    defaultValue={
                      this.state.allValues[0] && this.state.allValues[0].others
                    }
                    type="text"
                    // placeholder="Description"
                  />
                </div>
              </div>
              <div style={styles.flex}>
                <div
                  className="marign-5"
                  style={{ fontSize: "4rem", padding: "15px" }}
                >
                  <img src={img2} />
                  {/* <i className="fa fa-level-up"></i> */}
                </div>
                <div className="marign-5" style={{ padding: "10px" }}>
                  <strong style={{ fontSize: "18px" }}>
                    Enter your blood sugar level
                  </strong>
                  <br />
                  <br />
                  <input
                    className="inputofsymtum"
                    style={{ float: "left" }}
                    type="text"
                    name="bloodSugar"
                    placeholder="(mg/dL)"
                    onChange={this.changeHandler}
                    defaultValue={
                      this.state.allValues[0] &&
                      this.state.allValues[0].bloodSugar
                    }
                    // onChange={this.changeHandler}
                    // value={this.state.allValues.bloodSugar}
                  />
                </div>
              </div>
              <div style={styles.flex}>
                <div
                  className="marign-5"
                  style={{ fontSize: "4rem", padding: "15px" }}
                >
                  <img src={img3} />
                  {/* <i className="fa fa-area-chart"></i> */}
                </div>
                <div className="marign-5" style={{ padding: "10px" }}>
                  <strong style={{ fontSize: "18px" }}>
                    Enter your blood pressure level
                  </strong>
                  <br />
                  <br />
                  <input
                    className="inputofsymtum"
                    style={{ float: "left" }}
                    type="text"
                    placeholder="(mmHg)"
                    name="bloodPressure"
                    onChange={this.changeHandler}
                    defaultValue={
                      this.state.allValues[0] &&
                      this.state.allValues[0].bloodPressure
                    }
                    // onChange={this.changeHandler}
                    // value={this.state.allValues.bloodPressure}
                  />
                </div>
              </div>
              <div style={styles.flex}>
                <div
                  className="marign-5"
                  style={{
                    transform: "rotate(90deg)",
                    fontSize: "4rem",
                    padding: "15px",
                  }}
                >
                  <img src={img4} />
                  {/* <i className="fa fa-pie-chart"></i> */}
                </div>
                <div
                  className="marign-5"
                  style={{ padding: "10px", textAlign: "initial" }}
                >
                  <strong style={{ fontSize: "18px" }}>Last week report</strong>

                  <p style={{ fontSize: "14px", textAlign: "initial" }}>
                    Last week you have experienced &nbsp;
                    {this.state.report.map((personData, key) => {
                      return (
                        <>
                          {personData.count}&nbsp;times&nbsp;
                          {personData.symptom}&nbsp;,&nbsp;
                        </>
                      );
                    })}
                  </p>
                </div>
              </div>
              <div>
                <button type="submit" className="pinkbutton">
                  Submit
                </button>
              </div>
              <br />
              {this.state.symptomReady ? <AddSymptom /> : null}
              <span
                onClick={this.addSymptom}
                // style={styles.subbtn}
                className="pinkbutton"
                style={{ cursor: "pointer", fontSize: "16px" }}
              >
                Add User Defined Symptom
              </span>
              <hr />
            </form>
          </div>
        ) : null}
      </>
    );
  }
}

const styles = {
  trgrid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
  },
  module: {
    position: "relative",
    // background: "rgb(76, 0, 61) none repeat scroll 0% 0%",
    background: "#ffffff1c 0% 0% no-repeat padding-box",
    margin: "0px 30px",
    borderRadius: "10px",
    padding: "0px 0px 20px 0px",
    height: "40rem",
    overflowY: "scroll",
    scrollbarWidth: "thin",
    marginbottom: "10px",
  },
  txt: {
    textAlign: "left",
  },
  btn: {
    border: "1px solid #974cae",
    padding: "5px 30px",
    background: "#974cae",
    fontSize: "12px",
  },
  left: {
    textAlign: "left",
  },
  boxstyle: {
    background: "#ffffff1c 0% 0% no-repeat padding-box",
    marginTop: "10px",
  },
  flex: {
    background: "#ffffff1c 0% 0% no-repeat padding-box",
    marginTop: "10px",
    display: "flex",
    // justifyContent: "space-evenly",
    //background: "#971cae",
  },
  subbtn: {
    background: "linear-gradient(90deg, #3a51a7 30%, #b53899)",
    padding: "10px 30px",
    border: "none",
    borderRadius: "10px",
    color: "#fff",
    cursor: "pointer",
    margin: "10px",
  },
};
