import React from "react";
import { Link } from "react-router-dom";
import Doctorprofile from "./doctorprofile";
import axios from "axios";
export default class Tablelist extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isDialog: true, background: "#fff", list: [], docid: 0 };
    this.handleClick = this.handleClick.bind(this);
    this.colorChange = this.colorChange.bind(this);
    this.updateStatus = this.updateStatus.bind(this);
  }

  componentDidMount() {
    axios
      .get("http://127.0.0.1:8000/all-doctors-list/", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Token ${localStorage.getItem("admin_token")}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        this.setState({ list: res.data });
      });
  }

  updateStatus(id) {
    fetch(`http://127.0.0.1:8000/activateOrDeactivate/`, {
      body: JSON.stringify({ id: id }),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Token ${localStorage.getItem("admin_token")}`,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        alert(res.Success);
      });
  }

  colorChange() {
    const color = this.state.background === "#fff" ? "red" : "#fff";

    this.setState({ background: color });
  }

  handleClick(id) {
    console.log(id);
    this.setState({
      isDialog: !this.state.isDialog,
      docid: id,
    });
  }

  render() {
    return (
      <div style={styles.card} className="lightgradient2">
        <div style={styles.inner}>
          <p>
            <strong>All Doctors List</strong>
          </p>
          <hr style={{ color: "#fff" }} />
          <table style={{ borderSpacing: " 0px 1em" }}>
            <thead>
              <tr>
                <td>Name</td>
                <td>Age</td>
                <td>Hospital</td>
                <td>Ref ID</td>
                <td>clients</td>
                <td>Experience</td>
                <td>Profile</td>
                <td>Account Status</td>
              </tr>
            </thead>
            <tbody>
              {this.state.list &&
                this.state.list
                  .filter((ele) => {
                    if (this.props.searchval) {
                      // console.log(search);
                      if (ele && ele.firstname) {
                        return `${ele.firstname} ${ele.lastname}`
                          .toLowerCase()
                          .includes(this.props.searchval.toLowerCase());
                      }
                    } else return ele;
                  })
                  .map((ele) => {
                    return (
                      <tr key={ele.id} className="trfortable">
                        <td
                          onClick={() => this.handleClick(ele.id)}
                        >{`Dr. ${ele.firstname} ${ele.lastname}`}</td>
                        <td>{ele.age}</td>
                        <td>{ele.location}</td>
                        <td>{ele.referalId}</td>
                        <Link to={`/doc_details/${ele.id}`}>
                          <button>clients</button>
                        </Link>
                        <td>{ele.experience}</td>
                        <td>
                          <button onClick={() => this.handleClick(ele.id)}>
                            Details
                          </button>
                        </td>
                        <td>
                          <select
                            // onChange={this.colorChange}
                            onChange={() => this.updateStatus(ele.id)}
                            style={{
                              background: this.state.background,
                              border: "none",
                            }}
                          >
                            {ele.accountStatus ? (
                              <>
                                <option>Enable</option>{" "}
                                <option>Disabled</option>
                              </>
                            ) : (
                              <>
                                <option>Disabled</option>
                                <option>Enable</option>
                              </>
                            )}
                          </select>
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </table>
          {this.state.isDialog ? null : <Doctorprofile id={this.state.docid} />}
        </div>
      </div>
    );
  }
}

const styles = {
  card: {
    // display: "flex",
    justifyContent: "space-between",
    // background: "#4c003d",
    padding: "20px",
    borderRadius: "10px",
    margin: "10px",
  },
  inner: {
    textDecoration: "none",
    color: "#fff",
  },
};
