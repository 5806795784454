import React from "react";
import PropTypes from "prop-types";
import Sidebar from "../sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faUser, faBars } from "@fortawesome/free-solid-svg-icons";
import Toggler from "../../toggle";
import TogProfile from "../../tog_profile";
import ClientProfile from "../../../admin/toggleprofile";
import "./facebook.css";
import axios from "axios";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default class Plan_list extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isToggleOn: true,
      onProfile: true,
      items: [],
      isProfile: true,
      plans:[]
    };
    this.profileHandler = this.profileHandler.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleProfile = this.handleProfile.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  handleClick() {
    this.setState((prevState) => ({
      isToggleOn: !prevState.isToggleOn,
    }));
  }

  handleProfile() {
    this.setState((prevState) => ({
      onProfile: !prevState.onProfile,
    }));
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);

    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("token"),
      },
    };

    axios.get("course/get_plans",config).then((res)=>{
        this.setState({plans:res.data});
        console.log('data',res.data);
        console.log('owww')
    })
  }
 
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      return this.setState({ onProfile: true });
    }
  }
  profileHandler() {
    this.setState({ isProfile: !this.state.isProfile });
  }
  render() {
    function openMenu() {
      document.querySelector(".sidebar").classList.toggle("show");
    }

    return (
      <div className="App">
        <Sidebar className="vh-100" />
        <header className="overview">
          {/* <p style={{ paddingRight: "10%", textAlign: "right" }}> */}
          <div
            className="top-header grandiant"
            ref={this.setWrap}
            style={{ padding: "2% 10%", textAlign: "right" }}
          >
            {" "}
            <span onClick={openMenu} className="menu">
              <FontAwesomeIcon
                style={{ marginRight: "20px" }}
                icon={faBars}
                className="menu-icon"
              />
            </span>
            <div>{this.state.isToggleOn ? null : <Toggler />}</div>
            <span className="iconsofgradiant">
              <FontAwesomeIcon
                className="iconsofprofile"
                // onClick={this.handleClick}
                style={{ marginRight: "1rem", cursor: "pointer" }}
                icon={faBell}
              />
            </span>
            <span className="iconsofgradiant">
              <FontAwesomeIcon
                className="iconsofprofile"
                style={{ cursor: "pointer" }}
                icon={faUser}
                onClick={this.profileHandler}
              />
            </span>{" "}
            {this.state.items.firstname}
            <div>{this.state.onProfile ? null : <TogProfile />}</div>
            {/* </p>  */}
          </div>
          {this.state.isProfile ? null : <ClientProfile />}

          <div style={{ width: "89%", display: "flex", paddingLeft: "10px" }}>
            {/* <span style={{ whiteSpace: "nowrap" }}>My Profile</span>
            <div style={{ padding: "4px", width: "100%" }}>
              <hr style={{ background: "white", color: "white" }} />
            </div> */}
          </div>
          <div className="mwrapper">
            <div className="mleft">
              <div
                style={{
                  display: "flex",
                  padding: "3% 1%",
                }}
              >
                <span style={{ whiteSpace: "nowrap" }}>Plans</span>
                <div style={{ padding: "4px", width: "100%" }}>
                  <hr style={{ background: "white", color: "white" }} />
                </div>
              </div>

              {this.state.plans.map((data) => (
              <div className="box" >
               <Link to={`/course/${data.id}`}>
                  <p style={{fontSize:'20px'}} key={data.id}>{data.name}&nbsp;&nbsp; <span style={{float:'right',paddingRight:'30px',color:'green',fontSize:'bold'}}> </span></p>
                </Link>

                </div>
                ))}
            </div>
            <div className="mright calnderin">
            
              <div
                style={{
                  fontWeight: "bold",
                  textAlign: "left",
                  paddingLeft: "10px",
                }}
              ></div>
                
        
            </div>
          </div>
        </header>
      </div>
    );
  }
}

Plan_list.propTypes = {
  children: PropTypes.element.isRequired,
};
