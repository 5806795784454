import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../client/components/tabcss.css";
import { faBorderNone, faUser } from "@fortawesome/free-solid-svg-icons";
import EditPersonalDetail from "./edit_personal";
import EditMedicalDetail from "./edit_medical";
// import DietTracker from '../img/balance_diet.png';
import axios from "axios";
class Profile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isEditor: false,
      items: [],
      medicaldetails: [],
      files: "",
      baseurl: "https://sukhprasavam.shebirth.com/", // <- these keys should match the 'name' of the inputs
    };
    this.onChange = this.onChange.bind(this);
    this.openEditor = this.openEditor.bind(this);
  }
  submitPassword = (e) => {
    e.preventDefault();
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("token"),
      },
    };
    axios
      .patch("password-change/", this.state.passwordChange, config)
      .then(() => {
        alert("updated data");
        window.location.reload();
      })
      .catch((error) => {
        alert(JSON.stringify(error.response.data));
      });
  };
  changePassword = (e) => {
    this.setState({
      passwordChange: {
        ...this.state.passwordChange,
        [e.target.name]: e.target.value,
      },
    });
  };
  onChange(e) {
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("token"),
      },
    };

    this.setState({ files: e.target.files[0] });
    // let files = this.state.files;
    // files[e.target.name] = e.target.files[0];

    const formData = new FormData();
    // if (image != undefined) {
    console.log(this.state.files);
    formData.append("profile_img", e.target.files[0]);
    // }
    // if (allValues != null) {
    //   Object.entries(allValues).forEach(([key, value]) =>
    //     formData.append(key, value)
    //   );
    // }
    // e.target.files[0];

    // this.setState({
    axios.patch("profile-update/", formData, config).then(() => {
      alert("updated data");
      window.location.reload();
    });
    //   files: files,
    // });
  }

  openEditor() {
    this.setState({ isEditor: !this.state.isEditor });
  }

  componentDidMount() {
    // const config = {
    //   headers: {
    //     Authorization: `Token ${sessionStorage.getItem("token")}`,
    //   },
    // };
    axios
      .get(`customer-profile/`, {
        headers: {
          Authorization: `Token ${sessionStorage.getItem("token")}`,
        },
        params: {
          customer: this.props.id,
        },
      })
      .then((res) => {
        this.setState({ items: res.data.customer });
        this.setState({ medicaldetails: res.data.details });
      });
  }

  render() {
    return (
      <div style={styles.card}>
        <div style={styles.inner}>
          <Tabs style={styles.over2}>
            <TabList
              style={{
                width: "37rem",
                listStyle: "none",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Tab>
                <strong style={{ padding: "10px", cursor: "pointer" }}>
                  Personal Details
                </strong>
              </Tab>
              <Tab>
                <strong style={{ padding: "10px", cursor: "pointer" }}>
                  Medical Details
                </strong>
              </Tab>
            </TabList>

            <TabPanel style={styles.over}>
              <div>
                <div className="flex-100  clear-margin">
                  <div style={{ display: "grid" }}>
                    {console.log(this.state.items.profile_pic)}
                    {this.state.items.profile_pic ? (
                      <img
                        src={this.state.items.profile_pic}
                        className="avatar"
                      />
                    ) : (
                      <FontAwesomeIcon
                        style={{ fontSize: "5rem" }}
                        className="margin-profile"
                        icon={faUser}
                      />
                    )}
                    {/* <input
                      type="file"
                      id="files"
                      name="profile_img"
                      onChange={(e) => this.onChange(e)}
                      style={{ display: "none" }}
                    />
                    <label
                      for="files"
                      style={{
                        fontSize: "12px",
                        width: "66px",
                        marginTop: "5px",
                        textAlign: "center",
                        border: "1px solid white",
                        borderRadius: "5px",
                      }}
                    >
                      Select file
                    </label> */}
                  </div>
                  <div className="margin50">
                    <strong>{this.state.items.firstname}</strong>{" "}
                    <strong>{this.state.items.lastname}</strong>
                    <br />
                    {/* <small
                      style={{
                        fontSize: "14px",
                        padding: "3px 0px",
                        // borderBottom: "1px solid #fff",
                      }}
                    >
                      Password :
                      <input
                        type="password"
                        name="password"
                        onChange={this.changePassword}
                      />
                      <button
                        className="bluebutton2"
                        style={{ padding: "2px 25px" }}
                        onClick={this.submitPassword}
                      >
                        change{" "}
                      </button>
                 
                    </small> */}
                    <br />
                    <small style={styles.info}>
                      Female | {this.state.medicaldetails.age}{" "}
                      <span style={{ marginLeft: "10px" }}>
                        {this.state.items.mobile}
                      </span>
                    </small>
                  </div>
                </div>

                {/* <div style={{ display: "flex", alignItems: "center" }}>
                  <button style={styles.editbtn} onClick={this.openEditor}>
                    Edit Personal Details
                  </button>
                </div> */}
                <EditPersonalDetail id={this.props.id} />
              </div>
            </TabPanel>

            <TabPanel style={styles.over}>
              <div>
                <div className="flex-100  clear-margin">
                  <div style={{ display: "grid" }}>
                    {console.log(this.state.items.profile_pic)}
                    {this.state.items.profile_pic ? (
                      <img
                        src={this.state.items.profile_pic}
                        className="avatar"
                      />
                    ) : (
                      <FontAwesomeIcon
                        style={{ fontSize: "5rem" }}
                        className="margin-profile"
                        icon={faUser}
                      />
                    )}
                    {/* <input
                      type="file"
                      id="files"
                      name="profile_img"
                      onChange={(e) => this.onChange(e)}
                      style={{ display: "none" }}
                    />
                    <label
                      for="files"
                      style={{
                        fontSize: "12px",
                        width: "66px",
                        marginTop: "5px",
                        textAlign: "center",
                        border: "1px solid white",
                        borderRadius: "5px",
                      }}
                    >
                      Select file
                    </label> */}
                  </div>
                  <div className="margin50">
                    <strong>{this.state.items.firstname}</strong>{" "}
                    <strong>{this.state.items.lastname}</strong>
                    <br />
                    {/* <small
                      style={{
                        fontSize: "14px",
                        padding: "3px 0px",
                        // borderBottom: "1px solid #fff",
                      }}
                    >
                      Password :
                      <input
                        type="password"
                        name="password"
                        onChange={this.changePassword}
                      />
                      <button
                        className="bluebutton2"
                        style={{ padding: "2px 25px" }}
                        onClick={this.submitPassword}
                      >
                        change{" "}
                      </button>
           
                    </small> */}
                    <br />
                    <small style={styles.info}>
                      Female | {this.state.medicaldetails.age}{" "}
                      <span style={{ marginLeft: "10px" }}>
                        {this.state.items.mobile}
                      </span>
                    </small>
                  </div>
                </div>
                {/* <div style={{ display: "flex", alignItems: "center" }}>
                  <button style={styles.editbtn} onClick={this.openEditor}>
                    Edit Medical Details
                  </button>
                
                </div> */}
                <EditMedicalDetail id={this.props.id} />
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </div>
    );
  }
}

const styles = {
  over: {
    overflowX: "hidden",
    width: "100%",
    background: "#ffffff1c 0% 0% no-repeat padding-box",
  },
  card: {
    overFlow: "scroll",
    // display: "flex",
    justifyContent: "space-between",
    //background: "#9038a3",
    background: "#ffffff1c 0% 0% no-repeat padding-box",
    padding: "20px",
    borderRadius: "10px",
    margin: "10px",
    overFlow: "none",
  },

  inner: {
    overFlow: "scroll",
    textDecoration: "none",
    color: "#fff",
  },

  info: {
    fontSize: "12px",
  },
  over2: { overflowX: "hidden", width: "100%" },
  editbtn: {
    border: "1px solid #fff",
    background: "transparent",
    padding: "5px 10px",
    color: "#fff",
    cursor: "pointer",
    borderRadius: "7px",
  },
};

export default Profile;
