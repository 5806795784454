import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import qs from "qs";
import Notification from "../client/notification/notification";

export default class Toggle extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isNotify: true, dataa: [] };
    this.setWapRef = this.setWapRef.bind(this);
    this.clickedOutside = this.clickedOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.clickedOutside);

    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("doc_token"),
      },
    };

    var self = this;
    axios
      .get("doctor/doctor-profile/", config)
      .then(function (res) {
        console.log(JSON.stringify(res.data));

        self.setState({ dataa: res.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.clickedOutside);
  }

  setWapRef(node) {
    this.wapRef = node;
  }

  clickedOutside(e) {
    if (this.wapRef && !this.wapRef.contains(e.target)) {
      this.setState({ isNotify: false });
    }
  }

  //Approve appointment  ================

  approveAppointment(e) {
    e.preventDefault();
    const data = {
      appointmentID: e.target.id,
    };

    //alert(e.target.id);

    const config = {
      method: "post",
      url: "appointments/approve-appointment/",
      headers: {
        Authorization: `token ` + localStorage.getItem("doc_token"),
      },
      data: qs.stringify(data),
    };

    axios(config)
      .then(function (response) {
        console.log("posted", JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });

    this.setState({ id: e.target.id });
  }

  //REsheduling appointment  =========================
  resheduleAppointment(e) {
    const data = {
      appointmentID: e.target.id,
    };

    const config = {
      method: "post",
      // url: 'https://shebirth.herokuapp.com/appointments/reschedule-appointment/',
      url: "https://sukhprasavam.shebirth.com/appointments/reschedule-appointment/",
      headers: {
        Authorization: `token ` + localStorage.getItem("token"),
      },
      data: qs.stringify(data),
    };

    axios(config)
      .then(function (response) {
        console.log("posted", JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });

    this.setState({ id: e.target.id });
  }

  render() {
    return (
      <>
        {this.state.isNotify ? (
          <div ref={this.setWapRef}>
            {this.props.childern}
            <div style={style.list}>
              <Notification />
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

const style = {
  list: {
    borderRadius: "10px",
    // boxShadow: "-6px 2px 5px 0px rgba(202,70,70,0.75)",
    fontSize: "12px",
    padding: "0px",
    background: "#660051",
    textAlign: "left",
    listStyle: "none",
    position: "absolute",
    height: "400px",
    right: "15%",
    top: "6%",
    width: "260px",
    zIndex: "5",
    borderLeft: "3px solid #E81A7F",
  },

  listItem: {
    display: "flex",
    justifyContent: "space-between",
  },

  bell: {
    paddingLeft: "5px",
    display: "flex",
    alignItems: "center",
    fontSize: "2rem",
    color: "#E7AC6F",
  },

  uname: {
    fontSize: "14px",
  },

  nleft: {
    padding: "10px",
  },

  anotify: {
    padding: "10px",
    display: "block",
    borderLeft: "1px solid #fff",
    borderBottom: "1px solid #fff",
  },

  notify: {
    padding: "10px",
    display: "block",
    borderLeft: "1px solid #fff",
  },
};
