import React, { useState, useEffect } from "react";
import axios from "axios";

export default function Salecalllist({ id }) {
  const [salesData, setSalesData] = useState([]);
  console.log(id);
  useEffect(() => {
    // Fetch data from the API using axios
    axios
      .get("sales/sales-team-called/", {
        params: {
          customer_id: id,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Token ${sessionStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        // Assuming the data from the API is an array of objects representing sales calls
        setSalesData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching sales data:", error);
      });
  }, []);

  return (
    <div>
      <div className="table-cover ">
        <table style={{ borderSpacing: " 0px 1em" }}>
          <thead>
            <tr>
              <td>date</td>
              <td>notes</td>
              <td>callstatus</td>
            </tr>
          </thead>
          <tbody>
            {salesData.map((sale) => (
              <tr key={sale.id}>
                <td>{sale.date}</td>
                <td>{sale.note}</td>
                <td>{sale.response}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
