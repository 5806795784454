import React from "react";
import "../../App.css";
import LoginGirl from "../img/login_girl.png";
import slogo from "../../client/img/sukhprasavamlogo.png";
import Form from "./form";

export default class DoctorLogin extends React.Component {
  render() {
    return (
      <>
        <div
          style={{
            padding: "10% 0%",
          }}
        >
          <div className="signup-form newbs" style={styles.flex}>
            <div
              className="text-center"
              style={{
                color: "#fff",
              }}
            >
              <img src={LoginGirl} alt="" />
              <div style={styles.content} className="flexdisplay flexsign">
                {/* <h1 className="margin50">Launching </h1> */}
                <img className="slogo" src={slogo} />
                {/* <small>Who recommands only 15% c-sector rate in a nation</small> */}
                {/* <small style={{ textTransform: "capitalize", fontSize: "20px" }}>
                {" "}
                pleasing birth without fear
              </small> */}
              </div>
              {/* <small>Who recommands only 15% c-sector rate in a nation</small> */}
            </div>

            <Form />
          </div>
        </div>
      </>
    );
  }
}

const styles = {
  flex: {
    display: "flex",
    justifyContent: "space-between",
    width: "70%",
    margin: "auto 10%",
  },
};
