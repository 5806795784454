import React from "react";
import "./newtoggle.css";
import { useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import Toggle from "./togglecomponet";
export default function Inintailflow() {
  const [navStatus, setnavStatus] = useState(false);
  const [family, setfamily] = useState(false);
  const [Age, setAge] = useState();
  const [allValues, setAllValues] = useState();
  const [blood_group_mom, setblood_group_mom] = useState();
  const [bmi, setbmi] = useState();
  const [blood_group_dad, setblood_group_dad] = useState();
  const [domestic, setdomestic] = useState(true);
  const [textareaValue, setTextareaValue] = useState("");
  const [domestic_voilence, setdomestic_voilence] = useState();
  const [notes, setnotes] = useState();
  const [frequency_of_call, setfrequency_of_call] = useState();
  const [Toggler, setToggler] = useState();
  const [medical, setmedical] = useState(false);
  const [life, setlife] = useState(false);
  const [cheked, setCheked] = useState(true);
  const [Persoanal, setPersoanal] = useState([]);
  const [item, setitem] = useState([]);
  const { id } = useParams();
  console.log(textareaValue);
  //   state = {
  //   checked: true,
  //   size: "default",
  //   disabled: false
  // };

  const handleChange = (e) => {
    this.setState({ checked: e.target.checked });
  };

  const handleChangeTogle = (e) => {
    console.log(e);
    setdomestic_voilence(!domestic_voilence);
  };
  const changeHandler = (e) => {
    setAllValues({ ...allValues, [e.target.name]: e.target.value });
  };

  const SubmitData = () => {
    console.log(domestic_voilence);
    console.log(textareaValue);
    const formData = {
      ...allValues,
      notes: textareaValue, // Include textareaValue in the formData object
      domestic_voilence: domestic_voilence,
    };

    // formData.domestic_voilence = domestic_voilence;

    const customer = id ? parseInt(id) : null;

    console.log(customer);

    formData.customer = customer;

    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("sales"),
      },
    };

    console.log(formData);
    axios
      .post("analytics/submit-personal-details/", formData, config)
      .then(() => {
        alert("updated data");
      })
      .catch((error) => {
        alert(JSON.stringify(error.response));
      });
  };
  useEffect(() => {
    const token = localStorage.getItem("sales");
    if (!token) {
      window.location = "/login";
    }

    axios
      .get(`analytics/get-medical-data/?customer=` + id, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `token ` + localStorage.getItem("sales"),
        },
        // params: {
        //   doctor_id: id,
        // },
      })
      .then((res) => {
        // console.log(domestic_voilence);
        setitem(res.data);
      });
    axios
      .get(`analytics/get-personal-details/?customer=` + id, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `token ` + localStorage.getItem("sales"),
        },
      })
      .then((res) => {
        console.log(res.data);
        setPersoanal(res.data);
        // console.log(res.data[0].age);
        setAge(res.data[0].age);
        setbmi(res.data[0].bmi);
        setblood_group_mom(res.data[0].blood_group_mom);
        setblood_group_dad(res.data[0].blood_group_dad);
        setdomestic_voilence(res.data[0].domestic_voilence);
        setnotes(res.data[0].notes);
        setfrequency_of_call(res.data[0].frequency_of_call);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleCheckClick = (e, stateVal, index) => {
    //modifying the state based on chec value
    // let prevState = [...this.state[stateVal]];
    // prevState[index].positive = e.target.checked;
    // cone.target.dataset);
    // this.setState({ [stateVal]: prevState });

    const kar = e.target.dataset.id;
    // console.log(kar);
    if (e.target.checked) {
      var checkbox = true;
    } else {
      var checkbox = false;
    }
    const Data = {
      customer: id,
      id: kar,
      flag: checkbox,
      // date: date,
      // relatedPost: Relatedpost,
      // faq: freqentquestions,
    };

    // console.log(Data.painScale);

    const headers = {
      Authorization: `token ` + localStorage.getItem("sales"),
      //Authorization: "JWT fefege...",
    };

    axios
      .post("analytics/post-medical-data/", Data, {
        headers: headers,
      })

      .then(() => {
        // setincrement([...increment,res.data]) console.log(Data);
        //console.log("submited ");
        alert("  success submited");
        //console.log(Data);
        window.location.reload();
      })

      .catch((error) => {
        alert("error occured when updating");
        //console.log(Data);
        // window.location.reload();
        // console.log("handlesubmit error for blog ", error);
      });

    console.log(Data);
  };

  const navClose = (e) => {
    if (e === "Personal" && navStatus === true) {
      setnavStatus(false);
    } else if (e === "Personal" && navStatus === false) {
      setnavStatus(true);
    }
    if (e == "family" && family == true) {
      setfamily(false);
    } else if (e == "family" && family == false) {
      setfamily(true);
    }
    if (e == "medical" && medical == true) {
      setmedical(false);
    } else if (e == "medical" && medical == false) {
      setmedical(true);
    }
    if (e == "life" && life == true) {
      setlife(false);
    } else if (e == "life" && life == false) {
      setlife(true);
    }
  };

  return (
    <>
      <div className="  lightgradient2 marigin-auto">
        <h4>initial follow up</h4>
        <hr />
        {/* {console.log(domestic_voilence)} */}

        <div className="accordion">
          <div className="accordion-item">
            <div
              className="accordion-title gradiantblur"
              onClick={() => navClose("Personal")}
            >
              {/* // onClick={setActiveCurrentIndex(item[0].date)}> */}
              <div>Personal Details</div>
              <i class="fa fa-plus" aria-hidden="true"></i>
            </div>
            {navStatus ? (
              <>
                <div className="accordion-content  tableforsymtm  formobiletoggle">
                  <div
                    className="d6 displayintailmobile width-100"
                    style={styles.heihter2}
                  >
                    <div className=" display-flex " style={styles.ju}>
                      <p className="" style={styles.her}>
                        {" "}
                        Frequency of calls
                      </p>
                      <input
                        className="pink-input"
                        style={styles.her}
                        type="text"
                        name="frequency_of_call"
                        defaultValue={frequency_of_call}
                        // style={styles.heihter}
                        onChange={changeHandler}
                      />
                    </div>
                    <div className=" display-flex">
                      <p className=" margin50" style={styles.her}>
                        notes
                      </p>
                      <textarea
                        className="pink-input"
                        style={styles.hr}
                        height="100"
                        defaultValue={notes}
                        onChange={(e) => setTextareaValue(e.target.value)}
                      />
                    </div>
                  </div>

                  <>
                    <div style={styles.displayintail1} className="ovter oker">
                      <div className="inndert">
                        <p> age</p>
                        <input
                          className="pink-input"
                          name="age"
                          type="number"
                          onChange={changeHandler}
                          style={styles.heihter}
                          defaultValue={Age}
                        />
                      </div>
                      <div className="inndert">
                        <p>blood group-pregnant Dad</p>{" "}
                        <select
                          className="pink-input"
                          name="blood_group_dad"
                          onChange={changeHandler}
                          defaultValue={blood_group_dad}
                          required
                          // onChange={(e) => setPeriod(e.target.value)}
                          // value={optionsState}
                          style={styles.heihter}
                        >
                          <option style={styles.option} value="A+">
                            A+
                          </option>
                          <option style={styles.option} value="A-">
                            A-
                          </option>
                          <option style={styles.option} value="B+">
                            B+
                          </option>
                          <option style={styles.option} value="B-">
                            B-
                          </option>
                          <option style={styles.option} value="O+">
                            O+
                          </option>
                          <option style={styles.option} value="O-">
                            O-
                          </option>
                          <option style={styles.option} value="AB+">
                            AB+
                          </option>
                          <option style={styles.option} value="AB-)">
                            AB-
                          </option>
                        </select>
                      </div>
                      <div className="inndert">
                        <p>domestic violence</p>
                        <p>{domestic_voilence}</p>
                        <label class="switch">
                          <input
                            type="checkbox"
                            id="checked"
                            checked={domestic_voilence}
                            onChange={handleChangeTogle}
                          />

                          <span class="slider"></span>
                        </label>
                      </div>
                    </div>
                    <div style={styles.displayintail1} className="ovter exter">
                      <div className="inndert">
                        <p> BMI</p>

                        <select
                          className="pink-input"
                          name="bmi"
                          onChange={changeHandler}
                          defaultValue={bmi}
                          required
                          style={styles.heihter}
                        >
                          <option style={styles.option} value="underweight">
                            Underweight
                          </option>
                          <option style={styles.option} value="over weight">
                            Overweight
                          </option>
                          <option style={styles.option} value="normal weight">
                            Normalweight
                          </option>
                        </select>
                      </div>
                      <div className="inndert">
                        <p>blood group-pregnant Mom</p>{" "}
                        <select
                          className="pink-input"
                          onChange={changeHandler}
                          name="blood_group_mom"
                          defaultValue={blood_group_mom}
                          required
                          style={styles.heihter}
                        >
                          <option style={styles.option} value="A+">
                            A+
                          </option>
                          <option style={styles.option} value="A-">
                            A-
                          </option>
                          <option style={styles.option} value="B+">
                            B+
                          </option>
                          <option style={styles.option} value="B-">
                            B-
                          </option>
                          <option style={styles.option} value="O+">
                            O+
                          </option>
                          <option style={styles.option} value="O-">
                            O-
                          </option>
                          <option style={styles.option} value="AB+">
                            AB+
                          </option>
                          <option style={styles.option} value="AB-)">
                            AB-
                          </option>
                        </select>
                      </div>
                    </div>
                  </>
                </div>
                <div className="margin10">
                  <button className="pinkbutton" onClick={SubmitData}>
                    Submit
                  </button>
                </div>
              </>
            ) : null}
          </div>

          <div className="accordion-item">
            <div
              className="accordion-title gradiantblur"
              onClick={() => navClose("medical")}
            >
              <div>Medical history</div>
              <i class="fa fa-plus" aria-hidden="true"></i>
            </div>
            {medical ? (
              <div className="accordion-content  tableforsymtm">
                {item
                  .filter((person) => person.category == "Medical history")
                  .map((personData, key) => {
                    return (
                      <span className="trnew trin">
                        <td key={personData.id}>
                          <input
                            // className="invinsiveinput"
                            data-id={personData.id}
                            type="checkbox"
                            ids={personData.id}
                            checked={personData && personData.flag}
                            onChange={(e) => handleCheckClick(e, "items", key)}
                          />
                        </td>
                        <td className="tdoneline">{personData.name}</td>
                      </span>
                    );
                  })}
              </div>
            ) : null}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-title gradiantblur"
              onClick={() => navClose("family")}
            >
              {/* // onClick={setActiveCurrentIndex(item[0].date)}> */}
              <div>Family History</div>
              <i class="fa fa-plus" aria-hidden="true"></i>
            </div>
            {family ? (
              <div className="accordion-content  tableforsymtm">
                {item
                  .filter((person) => person.category == "Family History")
                  .map((personData, key) => {
                    return (
                      <span className="trnew trin">
                        <td key={personData.id}>
                          <input
                            // className="invinsiveinput"
                            data-id={personData.id}
                            type="checkbox"
                            ids={personData.id}
                            checked={personData && personData.flag}
                            onChange={(e) => handleCheckClick(e, "items", key)}
                            // defaultChecked={personData && personData.positive}
                            // onChange={this.handleCheckClick}
                          />
                          {/* <label for={personData.id}>{personData.name}</label> */}
                        </td>
                        <td className="tdoneline">{personData.name}</td>
                      </span>
                    );
                  })}
              </div>
            ) : null}
          </div>
          <div className="accordion-item">
            <div
              className="accordion-title gradiantblur"
              onClick={() => navClose("life")}
            >
              {/* // onClick={setActiveCurrentIndex(item[0].date)}> */}
              <div>Life Style</div>
              <i class="fa fa-plus" aria-hidden="true"></i>
            </div>
            {life ? (
              <div className="accordion-content  tableforsymtm">
                {item
                  .filter((person) => person.category == "Life Style")
                  .map((personData, key) => {
                    return (
                      <span className="trnew trin">
                        <td key={personData.id}>
                          <input
                            // className="invinsiveinput"
                            data-id={personData.id}
                            type="checkbox"
                            ids={personData.id}
                            checked={personData && personData.flag}
                            onChange={(e) => handleCheckClick(e, "items", key)}
                            // defaultChecked={personData && personData.positive}
                            // onChange={this.handleCheckClick}
                          />
                          {/* <label for={personData.id}>{personData.name}</label> */}
                        </td>
                        <td className="tdoneline">{personData.name}</td>
                      </span>
                    );
                  })}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}
const styles = {
  her: { margin: "3%" },
  heihter: {
    maxHeight: "22px",
    margin: "2em",
  },
  heihter2: {
    justifyContent: "space-around",
  },
  widther: { width: "100%" },
  hr: { width: "1004px", height: "123px", background: "#a05a8e" },

  ju: { justifyContent: "center" },
  displayintail1: {
    // display: "grid",
    // gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
  },
};
