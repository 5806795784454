import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";

export default function Clients() {
  const [Count, setCount] = useState();
  useEffect(() => {
    const token = sessionStorage.getItem("hospitalManager");
    if (!token) {
      window.location = "/login";
    }

    axios
      .get(`/hospital/dashboard-details/`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Token ${sessionStorage.getItem("hospitalManager")}`,
        },
        // params: {
        //   doctor: id,
        // },
      })
      .then((res) => {
        setCount(res.data.clientsCount);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div className="clients  font-16 lightgradient" style={styles.card}>
      <div style={styles.left}>
        <p>
          <strong style={{ whiteSpace: "nowrap" }}>Total Clients</strong>
        </p>
        <Link to="/hmAllclients">
          <button style={styles.link} className=" lightgradient">
            More <FontAwesomeIcon icon={faChevronCircleRight} />
          </button>
        </Link>
      </div>
      <div style={styles.right}>
        <h2 style={styles.counter}>{Count}</h2>
      </div>
    </div>
  );
}

const styles = {
  card: {
    display: "flex",
    justifyContent: "space-between",
    // background: "#4c003d",
    width: "20rem",
    padding: "20px",
    borderRadius: "10px",
    fontSize: "16px",
    margin: "10px",
  },
  link: {
    fontSize: "13px",
    textDecoration: "none",
    color: "#fff",
    // background: "#5a0f2c",
    padding: "10px",
    borderRadius: "10px",
    display: "block",
    width: "100px",
    border: "none",
    cursor: "pointer",
  },

  left: {
    padding: "10px",
  },
  right: {
    padding: "10px",
    borderLeft: "1px solid #fff",
  },
  counter: {
    color: "#fff",
  },
};
