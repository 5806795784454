import axios from "axios";
import React, { useState, useEffect } from "react";
export default function EditPersonal() {
  const [data, setdata] = useState([]);
  const [details, setdetails] = useState([]);
  const [image, setImage] = useState();

  const [allValues, setAllValues] = useState();

  const changeHandler = (e) => {
    setAllValues({ ...allValues, [e.target.name]: e.target.value });
  };
  const FileHandler = (e) => {
    // let files = files;
    setImage(e.target.files[0]);
    // setImage(e.target.files[0]);
  };
  const submitValue = (e) => {
    e.preventDefault();

    const formData = new FormData();
    if (image != undefined) {
      formData.append("Idproof", image);
    }
    if (allValues != null) {
      Object.entries(allValues).forEach(([key, value]) =>
        formData.append(key, value)
      );
    }
    console.log(allValues);
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };
    axios
      .patch("profile-update/", formData, config)
      .then(() => {
        alert("updated data");
      })
      .catch((error) => {
        // console.log(error.response.data);
        alert(JSON.stringify(error.response.data));
      });

    // console.log(allValues);
  };

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("token"),
      },
    };

    axios.get("customer-profile/", config).then((res) => {
      // console.log(res.data.details);
      setdata(res.data.customer);
      setdetails(res.data.details);
    });
  }, []);

  return (
    <>
      <div style={styles.wrapper}>
        <form className="personal-edit-form padding-0" style={styles.form}>
          <span
            className="flex-1001"
            // style={{
            //   display: "flex",
            //   justifyContent: "space-between",
            //   fontSize: "16px",
            // }}
          >
            <label>Name</label>
            {/* <div style={styles.helper}> */}
            <input
              name="firstname"
              onChange={changeHandler}
              style={styles.input}
              defaultValue={data.firstname}
              type="text"
              placeholder="Johan"
            />
          </span>
          <span
            className="flex-1001"
            // style={{
            //   display: "flex",
            //   justifyContent: "space-between",
            //   fontSize: "16px",
            // }}
          >
            <label>last name</label>
            <input
              name="lastname"
              onChange={changeHandler}
              style={styles.input}
              defaultValue={data.lastname}
              type="text"
              placeholder="doe"
            />
            {/* </div> */}
          </span>
          <span
            className="flex-1001"
            // style={{
            //   display: "flex",
            //   justifyContent: "space-between",
            //   fontSize: "16px",
            // }}
          >
            <label>Age</label>
            <input
              name="age"
              onChange={changeHandler}
              style={styles.input}
              defaultValue={details.age}
              type="text"
              placeholder="25"
            />{" "}
          </span>
          <span
            className="flex-1001"
            // style={{
            //   display: "flex",
            //   justifyContent: "space-between",
            //   fontSize: "16px",
            // }}
          >
            <label>Email</label>
            <input
              name="email"
              onChange={changeHandler}
              style={styles.input}
              defaultValue={data.email}
              type="text"
              placeholder="Alexa@gmail.com"
            />{" "}
          </span>
          <span
            className="flex-1001"
            // style={{
            //   display: "flex",
            //   justifyContent: "space-between",
            //   fontSize: "16px",
            // }}
          >
            <label>Address</label>
            <input
              name="address"
              onChange={changeHandler}
              style={styles.input}
              defaultValue={details.address}
              type="text"
              placeholder="address "
            />{" "}
          </span>
          <span
            className="flex-1001"
            // style={{
            //   display: "flex",
            //   justifyContent: "space-between",
            //   fontSize: "16px",
            // }}
          >
            <label>Location</label>
            <input
              name="location"
              onChange={changeHandler}
              style={styles.input}
              defaultValue={details.location}
              type="text"
              placeholder="location"
            />{" "}
          </span>
          <span
            className="flex-1001"
            // style={{
            //   display: "flex",
            //   justifyContent: "space-between",
            //   fontSize: "16px",
            // }}
          >
            <label>Mobile</label>
            <input
              name="mobile"
              onChange={changeHandler}
              style={styles.input}
              defaultValue={data.mobile}
              type="text"
              placeholder="XXXXXXXXX"
            />{" "}
          </span>
          <span className="flex-1001">
            <label>id proof</label>
            <input
              type="file"
              name="file"
              id="file"
              onChange={FileHandler}
              className="inputfile"
              style={styles.input}
            />
            <label style={styles.input} for="file">
              {details.idproof_filename
                ? details.idproof_filename
                : "Choose a file"}
            </label>
            {/* <input
              style={styles.input}
              name="Idproof"
              Value={details.Idproof}
              onChange={FileHandler}
              type="file"
            /> */}
          </span>
          <span
            className="flex-1001"
            // style={{
            //   display: "flex",
            //   justifyContent: "space-between",
            //   fontSize: "16px",
            // }}
          >
            <label>Husbands name</label>
            <input
              name="husband"
              onChange={changeHandler}
              style={styles.input}
              defaultValue={details.husband}
              type="text"
              placeholder="Husband Name"
            />{" "}
          </span>{" "}
          <span
            className="flex-1001"
            // style={{
            //   display: "flex",
            //   justifyContent: "space-between",
            //   fontSize: "16px",
            // }}
          >
            <label>Your job</label>
            <input
              name="job"
              onChange={changeHandler}
              style={styles.input}
              defaultValue={details.job}
              type="text"
              placeholder="your job"
            />{" "}
          </span>
          <div
            className="nobackground"
            // style={{ background: "rgb(139 72 143 / 0%)" }}
          >
            <input
              onClick={submitValue}
              style={styles.ebtn}
              className="pinkbutton"
              type="submit"
              value="Update"
            />
          </div>
        </form>
      </div>
    </>
  );
}

const styles = {
  wrapper: {
    padding: "5px",
  },

  form: {
    // position: "absolute",
    // height: "274px",

    // overflowY: "scroll",
    scrollbarWidth: "none",
    // zIndex: 10,
    // width: "400px",
    display: "flex",
    flexDirection: "column",
    top: "18rem",
    right: "25rem",
    // background: "#9038a3",
    // background: "#ffffff1c 0% 0% no-repeat padding-box",
    padding: "20px",
  },

  date: {
    border: "none",
    color: "#fff",
    outline: "none",
    background: "transparent",
  },

  fileP: {
    width: "80%",
    display: "block",
  },

  file: {
    border: "none",
    background: "transparent",
    float: "right",
  },

  datehelp: {
    marginBottom: "20px",
    padding: "8px",
    textAlign: "left",
    fontSize: "12px",
    background: "#530e73",
    color: "#fff",
  },

  helper: {
    background: "#530e73",
    display: "flex",
    padding: "5px",
    textAlign: "left",
    marginBottom: "1rem",
    borderBottom: "1px solid #fff",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
  },

  filehelp: {
    marginBottom: "20px",
    padding: "8px",
    textAlign: "left",
    fontSize: "12px",
    background: "#530e73",
    color: "#fff",
    display: "flex",
  },

  input: {
    marginBottom: "20px",
    // background: "#530e73",
    background: "rgb(83 14 115 / 39%)",
    color: "#fff",
    padding: "5px",
    borderBottom: "1px solid #fff",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
  },

  ebtn: {
    marginBottom: "2rem",
    // background: "linear-gradient(90deg, #3a51a7 30%, #b53899)",
    padding: "10px 30px",
    border: "none",
    borderRadius: "10px",
    color: "#fff",
    cursor: "pointer",
  },
};
