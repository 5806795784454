import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import PersonalDetail from "./innercom/personal_detail";
import MedicalDetail from "./innercom/medical_detail";
// import DietTracker from '../img/balance_diet.png';
//console.log(props.userId);
class Profile extends React.Component {
  constructor(props) {
    super(props);
    var userid = props.userId;
    this.state = { isClose: false, userid1: { id: props.userId } };
    this.closeProfile = this.closeProfile.bind(this);
  }

  closeProfile() {
    //  console.log(userId);
    this.setState({ isClose: !this.state.isClose });
  }
  // componentDidMount(userid) {
  //   this.setState({ userid1: userid });
  // }

  render() {
    return (
      <>
        {this.state.isClose ? null : (
          <div className="opentab doctorclint" style={styles.card}>
            <div className="innertab innerdoctorclint" style={styles.inner}>
              <i
                style={styles.closeit}
                onClick={this.closeProfile}
                className="fa fa-close"
              ></i>
              <Tabs>
                <TabList
                  style={{
                    width: "40rem",
                    listStyle: "none",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Tab>
                    <strong style={{ padding: "10px", cursor: "pointer" }}>
                      Personal Details
                    </strong>
                  </Tab>
                  <Tab>
                    <strong style={{ padding: "10px", cursor: "pointer" }}>
                      Medical Details
                    </strong>
                  </Tab>
                </TabList>

                <TabPanel style={styles.tabheight}>
                  <PersonalDetail userid1={this.state.userid1.id} />
                </TabPanel>

                <TabPanel style={styles.tabheight}>
                  <MedicalDetail userid1={this.state.userid1.id} />
                </TabPanel>
              </Tabs>
            </div>
          </div>
        )}
      </>
    );
  }
}

const styles = {
  card: {
    display: "flex",
    justifyContent: "space-between",
    backdropFilter: "blur(10px)",
    backgroundColor: " rgb(255 255 255 / 14%)",
    padding: "20px",
    zIndex: 10,
    borderRadius: "10px",
    margin: "10px",
    position: "absolute",
  },

  inner: {
    textDecoration: "none",
    color: "#fff",
  },

  info: {
    fontSize: "12px",
  },

  closeit: {
    position: "absolute",
    right: "0px",
    top: "-5px",
    cursor: "pointer",
  },

  tabheight: {
    scrollbarWidth: "none",
    height: "30rem",
    overflowY: "scroll",
  },
};

export default Profile;
