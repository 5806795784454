import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";

export default function Doctor({ totalDoctors }) {
  return (
    <div className="lightgradient2" style={styles.card}>
      <div style={styles.left}>
        <p>
          <strong style={{ whiteSpace: "nowrap", fontSize: "16px" }}>
            Total Doctors
          </strong>
        </p>
        <a href="/admin_docoverview" style={styles.link} className="lightgradient2">
          More <FontAwesomeIcon icon={faChevronCircleRight} />
        </a>
      </div>
      <div style={styles.right}>
        <h2 style={styles.counter}>{totalDoctors}</h2>
      </div>
    </div>
  );
}

const styles = {
  card: {
    display: "flex",
    justifyContent: "space-between",

    // backdropFilter: "blur(31px) brightness(-15%) opacity(.4)",

    width: "20rem",
    padding: "20px",
    borderRadius: "10px",
    margin: "10px",
  },
  link: {
    fontSize: "13px",
    textDecoration: "none",
    color: "#fff",
    background: "#5a0f2c",
    padding: "10px",
    borderRadius: "10px",
    display: "block",
    width: "100px",
    border: "none",
    cursor: "pointer",
  },

  left: {
    padding: "10px",
  },
  right: {
    padding: "10px",
    borderLeft: "1px solid #fff",
  },
  counter: {
    color: "#fff",
  },
};
