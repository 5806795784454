import React, { useState, useEffect } from "react";
import axios from "axios";

const API_URL = "";

function ContentList() {
  const [contentList, setContentList] = useState([]);

  useEffect(() => {
    async function fetchContent() {
      const headers = {
        Authorization: `Token ${sessionStorage.getItem("token")}`,
      };
      try {
        const response = await axios.get(
          "https://sukhprasavam.shebirth.com/admin-panel/free-content/",
          {
            headers: headers,
          }
        );
        setContentList(response.data.data);
      } catch (error) {
        console.log(error);
      }
    }
    fetchContent();
  }, []);
  const handleDelete = async (id) => {
    const headers = {
      Authorization: `Token ${sessionStorage.getItem("token")}`,
    };
    try {
      await axios.delete(
        "https://sukhprasavam.shebirth.com/admin-panel/free-content/",
        {
          headers: headers,
          data: {
            id: id,
          },
        }
      );
      setContentList(contentList.filter((content) => content.id !== id));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Video Thumbnail</th>
            <th>Crew</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {contentList.map((content) => (
            <tr key={content.id}>
              <td>{content.name} </td>
              <td>
                <a href={content.video_url} target="_blank" rel="noreferrer">
                  <img
                    src={`https://img.youtube.com/vi/${
                      content.video_url.split("/login").slice(-1)[0]
                    }/mqdefault.jpg`}
                    alt={content.name}
                  />
                </a>
              </td>
              <td>{content.crew.split(" ").slice(0, 5).join(" ")}...</td>
              <td>
                <button onClick={() => handleDelete(content.id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ContentList;
