import axios from "axios";
import Select from "react-select";
import React, { useState, useEffect } from "react";

export default function EditPersonal() {
  const [data, setdata] = useState({});
  const [details, setdetails] = useState([]);
  const [image, setImage] = useState();
  const [image2, setImage2] = useState();

  const [allValues, setAllValues] = useState({});
  const [allValues2, setAllValues2] = useState({
    abortions: "Yes",
    twins: "Yes",
    diabetes: "Yes",
    allergic_reaction: "Yes",
    surgery: "Yes",
    hereditory: "Yes",
    gynacology: "Yes",
    drugUse: "Yes",
  });
  const [countryCode, setCountryCode] = useState(""); // Set the default country code here
  const [showSecondForm, setShowSecondForm] = useState(true); // Updated initial state to hide the second form

  const [secondFormData, setSecondFormData] = useState({}); // Add state for the second form data

  const changeHandler = (e) => {
    setAllValues({ ...allValues, [e.target.name]: e.target.value });
  };

  const changeHandler2 = (e) => {
    setAllValues2({ ...allValues2, [e.target.name]: e.target.value });
  };

  const FileHandler = (e) => {
    setImage(e.target.files[0]);
  };

  const FileHandler2 = (e) => {
    setImage2(e.target.files[0]);
  };

  const submitFirstForm = (e) => {
    e.preventDefault();

    // Check if all text fields have data entered
    const textFields = [
      "age",
      "weight",
      "address",
      "location",
      "husband",
      "job",
    ];
    
    // Log the text field data
    console.log("First Form Data:", allValues);
  
    // Set the state to show the second form
    setShowSecondForm(false);
  };

  const submitSecondForm = (e) => {
    e.preventDefault();

    // Check if all fields of the second form have data entered
    const secondFormFields = [
      "marriedSince",
      "babies_number",
      "abortions",
      "twins",
      "diabetes",
      "allergic_reaction",
      "surgery",
      "Menstruation",
      // "Menstruation_date",
      "Heridity",
      "gynacology",
      "no_of_babies_pregnant_with",
      "doctor_final_visit",
      "drugUse",
    ];
    
    const mergedData = { ...allValues, ...allValues2 };

    const formData = new FormData();

    // Append the merged data if it is not empty
    Object.entries(mergedData).forEach(([key, value]) => {
      formData.append(key, value);
    });

    if (image) {
      console.log(image);
      formData.append("Idproof", image);
    }

    if (image2) {
      formData.append("prescription", image2);
    }

    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };
    axios
      .patch("/update-customer-data/", formData, config)
      .then(() => {
        alert("updated data");

        window.location.reload();
      })
      .catch((error) => {
        alert(JSON.stringify(error.response.data));
      });
  };

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("token"),
      },
    };

    axios.get("/get-customer-profile/", config).then((res) => {
      console.log(res.data.customer_details);
      setdata(res.data.customer_details);
      // setdetails(res.data.details);
    });
  }, []);

  return (
    <>
      <div style={styles.wrapper}>
        {showSecondForm ? (
          <form
            className="personal-edit-form padding-0 intialform"
            style={styles.form}
            onSubmit={submitFirstForm}
          >
            <span className="flex-1001">
              <label style={{ fontSize: "16px" }}>Age</label>
              <input
                name="age"
                onChange={changeHandler}
                style={styles.input}
                defaultValue={data.age}
                type="number"
                required
              />
            </span>
            <span className="flex-1001">
              <label style={{ fontSize: "16px" }}>Weight</label>
              <input
                name="weight"
                onChange={changeHandler}
                style={styles.input}
                defaultValue={data.weight}
                type="number"
                pattern="[0-9]*" // This pattern ensures only numbers are accepted
                title="Please enter only numbers" // This message will be displayed if the pattern is not matched
                required
              />
            </span>
            <span className="flex-1001">
              <label style={{ fontSize: "16px" }}>Address</label>
              <input
                name="address"
                onChange={changeHandler}
                style={styles.input}
                defaultValue={data.address}
                type="text"
                required
              />
            </span>
            <span className="flex-1001">
              <label style={{ fontSize: "16px" }}>Location</label>
              <input
                name="location"
                onChange={changeHandler}
                style={styles.input}
                defaultValue={data.location}
                type="text"
                required
              />
            </span>
            <span className="flex-1001">
              <label style={{ fontSize: "16px" }}>ID Proof</label>

              <label style={styles.input2} htmlFor="file" className="inputfile">
                {data.idproof_filename
                  ? data.idproof_filename
                  : "Choose a file"}
              </label>
            </span>
            <span className="flex-1001">
              <label style={{ fontSize: "16px" }}>Husband's Name</label>
              <input
                name="husband"
                onChange={changeHandler}
                style={styles.input}
                defaultValue={data.husband}
                type="text"
                required
              />
            </span>
            <span className="flex-1001">
              <label style={{ fontSize: "16px" }}>Your Job</label>
              <input
                name="job"
                onChange={changeHandler}
                style={styles.input}
                defaultValue={data.job}
                type="text"
                required
              />
            </span>
            <div className="nobackground text-center">
              <input
                // onClick={submitFirstForm}
                style={styles.ebtn}
                className="pinkbutton"
                type="submit"
                value="Continue"
                required
              />
            </div>
            <input
              type="file"
              name="file"
              id="file"
              onChange={FileHandler}
              className="inputfile"
              style={{ background: "rgb(139 72 143 / 0%)" }}
          
            />
          </form>
        ) : (
          <form
            className="medical-edit"
            style={styles.form}
            onSubmit={submitSecondForm}
          >
            <small style={styles.datehelp}>
              <label>Married Since</label>
              <input
                name="marriedSince"
                style={styles.date}
                onChange={changeHandler2}
                defaultValue={details.marriedSince}
                type="date"
                required
              />
            </small>

            <small style={styles.datehelp}>
              <label>How Many Baby Do You Have?</label>
              <input
                name="babies_number"
                onChange={changeHandler2}
                style={styles.date}
                type="number"
                defaultValue={details.babies_number}
                placeholder=""
                required
              />
            </small>
            <small style={styles.datehelp}>
              <label>Any History Of Abortions? Yes or No</label>
              <select
                onChange={changeHandler2}
                name="abortions"
                style={styles.date}
                defaultValue={"Yes"}
                required
              >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </small>
            <small style={styles.datehelp}>
              <label>Any History of twins in Family? Yes or No</label>
              <select
                onChange={changeHandler2}
                name="twins"
                style={styles.date}
                defaultValue={"Yes"}
                required
              >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </small>
            <small style={styles.datehelp}>
              <label>Any Family History of Diabetes? Yes or No</label>
              <select
                onChange={changeHandler2}
                name="diabetes"
                style={styles.date}
                defaultValue={"Yes"}
                required
              >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </small>
            <small style={styles.datehelp}>
              <label> Any History Of allergic reaction?</label>

              <select
                onChange={changeHandler2}
                name="allergic_reaction"
                style={styles.date}
                defaultValue={"Yes"}
                required
              >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </small>
            <small style={styles.datehelp}>
              <label>Any History Of Surgery?</label>
              <select
                onChange={changeHandler2}
                name="surgery"
                style={styles.date}
                defaultValue={"Yes"}
                required
              >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </small>
            <small style={styles.datehelp}>
              <label>How many days Menstruation Period cycle</label>
              <input
                onChange={changeHandler2}
                name="Menstruation"
                style={styles.date}
                type="number"
                defaultValue={details.Menstruation}
                required
              />
            </small>
            {/* <small style={styles.datehelp}>
              <label>last Menstruation date</label>
              <input
                name="Menstruation_date"
                onChange={changeHandler2}
                defaultValue={details.Menstruation_date}
                style={styles.date}
                type="date"
                required
              />
            </small> */}
            <small style={styles.datehelp}>
              <label> Any hereditory related complications?</label>
              <select
                onChange={changeHandler2}
                name="hereditory"
                style={styles.date}
                defaultValue={"Yes"}
                required
              >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </small>
            <small style={styles.datehelp}>
              <label>
                Any History Of Gynacology or Fertility Treatment? If Yes, please
                Specify.
              </label>
              <select
                onChange={changeHandler2}
                name="gynacology"
                style={styles.date}
                defaultValue={"Yes"}
                required
              >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </small>
            <small style={styles.datehelp}>
              <label>How many baby are you pregnent with currently?</label>
              <input
                onChange={changeHandler2}
                name="no_of_babies_pregnant_with"
                style={styles.date}
                type="number"
                defaultValue={details.no_of_babies_pregnant_with}
                required
                // placeholder="How many baby are you pregnent with currently?"
              />
            </small>
            <small style={styles.datehelp}>
              <label> When did you visit doctor finally?</label>
              <input
                onChange={changeHandler2}
                name="doctor_final_visit"
                style={styles.date}
                defaultValue={details.doctor_final_visit}
                type="date"
                required
              />
            </small>
            <small style={styles.datehelp}>
              <label>Any History Of Drug use?</label>
              <select
                onChange={changeHandler2}
                name="drugUse"
                style={styles.date}
                defaultValue={"Yes"}
                required
              >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </small>
            <small style={styles.datehelp}>
              <label>
                {" "}
                Upload latest prescription report or any blood report that
                clearly indicates that you are pregnant{" "}
              </label>{" "}
              <input
                style={styles.file}
                name="prescription"
                onChange={FileHandler2}
                type="file"
          
              />
            </small>
            <div className="finish-button">
              <input
                // onClick={submitSecondForm}
                style={styles.ebtn}
                className="pinkbutton2"
                type="submit"
                value="Update"
              />
            </div>
          </form>
        )}
      </div>
    </>
  );
}

const styles = {
  wrapper: {
    padding: "5px",
  },

  form: {
    // position: "absolute",
    // height: "274px",

    // overflowY: "scroll",
    scrollbarWidth: "none",
    // zIndex: 10,
    // width: "400px",
    display: "flex",
    flexDirection: "column",
    top: "18rem",
    right: "25rem",
    // background: "#9038a3",
    // background: "#ffffff1c 0% 0% no-repeat padding-box",
    padding: "20px",
  },

  date: {
    border: "none",
    color: "#fff",
    outline: "none",
    // background: "transparent",
  },

  fileP: {
    width: "80%",
    display: "block",
  },

  file: {
    border: "none",
    // background: "transparent",
    float: "right",
  },

  datehelp: {
    marginBottom: "20px",
    padding: "8px",
    textAlign: "left",
    fontSize: "12px",
    // background: "#530e73",
    color: "#fff",
  },

  helper: {
    // background: "#530e73",
    display: "flex",
    padding: "5px",
    textAlign: "left",
    marginBottom: "1rem",
    borderBottom: "1px solid #fff",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
  },

  filehelp: {
    marginBottom: "20px",
    padding: "8px",
    textAlign: "left",
    fontSize: "12px",
    // background: "#530e73",
    color: "#fff",
    display: "flex",
  },

  input: {
    marginBottom: "20px",
    // background: "#530e73",
    width: "25%",
    background:
      "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
    color: "#fff",
    padding: "5px",
  },
  input2: {
    marginBottom: "20px",
    // background: "#530e73",
    width: "25%",
    background:
      "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
    color: "#fff",
    padding: "5px",
    borderBottom: "1px solid #fff",
    borderTop: "none",
    borderLeft: "none",
    textAlign: "left",
    fontSize: "14px",
    borderRight: "none",
  },

  ebtn: {
    marginBottom: "2rem",
    // background: "linear-gradient(90deg, #3a51a7 30%, #b53899)",
    padding: "10px 30px",
    border: "none",
    borderRadius: "10px",
    color: "#fff",
    cursor: "pointer",
  },
  input6: {
    background:
      "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
  },
};
