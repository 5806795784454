import axios from "axios";
import qs from "qs";
import React from "react";
import QR from "./qrcode.png";
import correct from "./correct.png";
// import hidePwdImg from "./hide-password.svg";

import { Link } from "react-router-dom";

export default class Form extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isUser: false,
      username: "",
      password: "",
      items: [],
      isRevealPwd: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.usernameChange = this.usernameChange.bind(this);
    this.passwordChange = this.passwordChange.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();
    var data = {
      email: this.state.username,
      password: this.state.password,
    };

    axios({
      method: "POST",
      url: "login/",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      data: qs.stringify(data),
    })
      .then(function (response) {
        // localStorage.setItem('LoginToken', response.data.token);
        //const status = response;
        console.log(response.data);
        //redirect logic
        if (response.data.client == true) {
          //this.setState({ error: 0 });
          localStorage.setItem("token", response.data.token);
          window.location = "/client";
        } else if (response.data.sales == true) {
          //this.setState({ error: 0 });
          localStorage.setItem("sales", response.data.token);
          //  window.location = "/csteam";
          localStorage.setItem("token", response.data.token);
          window.location = "/csteam";
        } else if (response.data.consltant == true) {
          //this.setState({ error: 0 });
          localStorage.setItem("token", response.data.token);
          window.location = "/consultant";
        } else if (response.data.doctor == true) {
          //this.setState({ error: 0 });
          localStorage.setItem("doc_token", response.data.token);
          window.location = "/doctor";
        } else if (response.data.hospitalManager == true) {
          //this.setState({ error: 0 });
          sessionStorage.setItem("hospitalManager", response.data.token);
          window.location = "/hmanager";
        } else {
          //this.setState({ items: response.data });
          alert("User with the email not found!");

          console.log("this is error");
        }
      })
      .catch((error) => {
        alert(JSON.stringify(error.response.data));
      });

    //   .then((res) => {
    //     localStorage.setItem("LoginToken", res.data.token);
    //   });
  }

  usernameChange(e) {
    this.setState({ username: e.target.value });
  }

  passwordChange(e) {
    this.setState({ password: e.target.value });
  }
  signup = (e) => {
    // e.preventDefault();
    // localStorage.clear();
    window.location = "/csignup";
  };

  docsignup = (e) => {
    // e.preventDefault();
    // localStorage.clear();
    window.location = "/doc_signup";
  };

  render() {
    return (
      <>
        <div
          className="form-wrapper"
          style={{
            display: "flex",
            flexDirection: "column",
            color: "#fff",
            padding: "20px",
            width: "40%",
            borderRadius: "20px",

            boxShadow: "rgb(0 0 0 / 16%) 12px 10px 60px",
            backdropFilter: "blur(39px)",
            background: "rgba(0, 0, 0, 0.16)",
            // boxShadow: "12px 10px 60px #00000029",
            // backdropFilter: "blur(39px)",
            // WebkitBackdropFilter: "blur(39px)",
            // background: "#00000029",
          }}
        >
          {" "}
          <img className="width-10 pc-view-hidden" src={correct} />
          <h3 className="margin-auto">payment successful</h3>
          <p className="margin-auto text-center">
            your payment is successful. you will receive sms/email/call from our
            support team
          </p>
          <h1 className="margin-auto">Scan to download our app</h1>
          <p className="margin-auto">
            {" "}
            get the best of health & matarnity care all in one place
          </p>
          <p className="margin-auto">download app today!</p>
          <img className=" marginauto " src={QR} />
          <a
            href="https://play.google.com/store/apps/details?id=com.smartbirth.shebirth"
            style={{
              background: "#e20e88",
              borderRadius: "5px",
              border: "none",
              padding: "2% 13%",
              margin: "10%  auto",
              color: "#fff",
              boxShadow: "0px 9px 10px #00000029",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            Download App{" "}
          </a>
          <Link to="/login" className="margin-auto">
            <p>continue using website</p>
          </Link>
        </div>
      </>
    );
  }
}

const styles = {
  input: {
    background: "none",
    padding: "10px",
    color: "#fff",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
  },
};
