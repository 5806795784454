export default function Tablelist() {
  return (
    <div className="client-table" style={styles.card}>
      <div style={styles.inner}>
        <p>
          <strong>All Hospital List</strong>
        </p>
        <hr style={{ color: "#fff" }} />
        <div className="table-cover">
          <table style={{ width: "55rem" }}>
            <thead>
              <tr>
                <td>Name</td>
                <td>Location</td>
                <td>Doctor Under</td>
                <td>List Of Doctors Under</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Mathew</td>
                <td>Kochi</td>
                <td>5</td>
                <td>
                  <button>Details</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

const styles = {
  card: {
    display: "flex",
    justifyContent: "space-between",
    background: "#4c003d",
    padding: "5px",
    borderRadius: "10px",
    margin: "40px",
  },
  inner: {
    textDecoration: "none",
    color: "#fff",
  },
};
