import React from "react";
import axios from "axios";
import Sidebar from "../components/sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faUser, faBars } from "@fortawesome/free-solid-svg-icons";
import Toggler from "../toggle";

import ClientProfile from "../tog_profile";
//import DailyTrackerTabs from './components/daily_tracker';

import Graph from "./Graph.js";
import Greet from "../components/greet";
import ApprovalRequest from "../components/approval_requests";
import TodayAppointment from "../components/today_appointments";
import CurrentStatus from "../components/current_status";
// import { useParams } from "react-router-dom";

export default class ClientsAllOverview extends React.Component {
  constructor(props) {
    super(props);
    // const { id } = this.props.match.params;
    console.log(props);
    this.state = { isToggleOn: true, isProfile: true, items: [] };
    this.handleClick = this.handleClick.bind(this);
    this.setWrap = this.setWrap.bind(this);
    this.clickedOutside = this.clickedOutside.bind(this);
    this.profileHandler = this.profileHandler.bind(this);
  }

  handleClick() {
    this.setState((prevState) => ({
      isToggleOn: !prevState.isToggleOn,
    }));
  }

  componentDidMount() {
    const id = this.props.match.params.id; // access the id parameter
    console.log(id);
    document.addEventListener("mousedown", this.clickedOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.clickedOutside);
  }

  profileHandler() {
    this.setState({ isProfile: !this.state.isProfile });
  }

  setWrap(node) {
    this.isWap = node;
  }

  clickedOutside(e) {
    if (this.isWap && !this.isWap.contains(e.target)) {
      this.setState({ isToggleOn: true });
      this.setState({ isProfile: true });
    }
  }

  componentDidMount() {
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("doc_token"),
      },
    };

    axios.get("doctor/doctor-profile/", config).then((res) => {
      this.setState({ items: res.data });
    });
  }

  render() {
    const { id } = this.props.match.params;
    console.log(id);
    function openMenu() {
      document.querySelector(".sidebar").classList.toggle("show");
    }

    // console.log(id);

    return (
      <div className="App">
        <Sidebar />

        <header className="overview ">
          <div
            className="top-header grandiant"
            ref={this.setWrap}
            style={{ padding: "2% 10%", textAlign: "right" }}
          >
            <span onClick={openMenu} className="menu">
              <FontAwesomeIcon
                style={{ marginRight: "20px" }}
                icon={faBars}
                className="menu-icon"
              />
            </span>
            <div>{this.state.isToggleOn ? null : <Toggler />}</div>
            <span className="iconsofgradiant">
              <FontAwesomeIcon
                className="iconsofprofile"
                onClick={this.handleClick}
                style={{ marginRight: "1rem", cursor: "pointer" }}
                icon={faBell}
              />
            </span>
            <span className="iconsofgradiant">
              <FontAwesomeIcon
                className="iconsofprofile"
                onClick={this.profileHandler}
                style={{ cursor: "pointer" }}
                icon={faUser}
              />
            </span>
            {this.state.items.firstname}
            {this.props.childern}
            {this.state.isProfile ? null : <ClientProfile />}{" "}
          </div>

          <div
            className="top-header toperpc"
            style={{ display: "flex", paddingLeft: "10px" }}
          >
            <span style={{ whiteSpace: "nowrap", fontSize: "20px" }}>
              graph
            </span>
            <div style={{ padding: "4px", width: "100%" }}>
              <hr
                style={{ background: "white", color: "white" }}
                className="hr-80"
              />
            </div>
          </div>
          <div className="mwrapper">
            <div className="mleft docmright">
              <div
                style={{
                  display: "flex",
                  padding: "3% 1%",
                }}
              ></div>

              <div style={styles.prheight}>
                {" "}
                <Graph id={id} />
              </div>
            </div>
            <div className="mright">
              <ApprovalRequest />
              <TodayAppointment />
              <CurrentStatus />
            </div>
          </div>
        </header>
      </div>
    );
  }
}

const styles = {
  prheight: {
    //height: "35rem",
    //overflow:'scroll',
    scrollbarWidth: "none",
  },
};
