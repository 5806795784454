import React, { useState, useEffect } from "react";
import axios from "axios";
import "./facebook.css";
import icon from "./logo.png";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

function FacebookTimeline() {
  const [posts, setPosts] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Token ${token}`,
    };
    axios
      .get(`/course/get_video/?course_id=${id}`, { headers })
      .then((response) => {
        console.log('hai', response.data);
        setPosts(response.data);
        console.log('data', response.data);
      })
      .catch((error) => {
        console.error('Error fetching posts:', error);
      });
  }, [id]);

  return (
    <div className="timeline">
      {posts.length != 0 ?  (
        posts.map((post) => (
          <div key={post.id} className="timeline-post">
            <div className="timeline-post-header">
              <img src={icon} alt="profile" />
              <div className="timeline-post-info">
                <h3>{post.name}</h3>
                <p>{/* Add any additional info if needed */}</p>
              </div>
            </div>
            {post.video_url && (
              <div className="timeline-post-video">
                <iframe
                  width="560"
                  height="315"
                  className="videoexerisise2"
                  src={post.video_url}
                  
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
                <p style={{ fontSize: '13px' }}>{post.discreption}</p>
              </div>
            )}
          </div>
        ))
      ) :(
        <div className="no-posts-message">
          
          <h5>Kindly Contact</h5>
          <ul style={{listStyleType:" none"}}>
          <li><img width="35" height="35" src="https://img.icons8.com/ios-glyphs/30/phone-disconnected.png" alt="phone-disconnected"/>&nbsp;<img width="35" height="35" src="https://img.icons8.com/color/48/whatsapp--v1.png" alt="whatsapp--v1"/><a style={{fontSize:'24px'}}>&nbsp;&nbsp;<br></br>9778369032</a></li><br></br>
          <li><img width="30" height="30" src="https://img.icons8.com/ios-glyphs/30/new-post.png" alt="new-post"/>&nbsp;<a href="mailto:admin@shebirth.in" style={{fontSize:'24px',fontVariant:'all-small-caps'}}><br></br>admin@shebirth.in</a></li>
         </ul>
          
        
        </div>
      )}
    </div>
  );
}

export default FacebookTimeline;
