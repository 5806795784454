import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown, faSort } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import Dailytraker from "../tracker/components/tabs";
import { Link } from "react-router-dom";
import axios from "axios";

export default function Clist({ clientDetails, searchval }) {
  const [background, setColor] = useState("#fff");
  const [userId, setUserId] = useState();
  const [status, setStatus] = useState(false);
  const [category, setCategory] = useState(null);
  const [traker, settraker] = useState(false);
  function handleColor() {
    const nextColor = background === "#fff" ? "red" : "#fff";
    setColor(nextColor);
  }

  function openDailytraker(id) {
    setUserId(id);
    console.log("idis", id);
    settraker(!traker);
  }
  const byCategory = (user, category) => {
    if (category === "0-4") {
      var lowerweek = 0;
      var upperweek = 4;
      return user.currentWeek <= upperweek;
    } else if (category === "4-8") {
      var lowerweek = 4;
      var upperweek = 8;
      return user.currentWeek <= upperweek && user.currentWeek >= lowerweek;
    } else if (category === "8-12") {
      var lowerweek = 8;
      var upperweek = 12;
      return user.currentWeek <= upperweek && user.currentWeek >= lowerweek;
    } else if (category === "12-16") {
      var lowerweek = 12;
      var upperweek = 16;
      return user.currentWeek <= upperweek && user.currentWeek >= lowerweek;
    } else if (category === "16-20") {
      var lowerweek = 16;
      var upperweek = 20;
      return user.currentWeek <= upperweek && user.currentWeek >= lowerweek;
    } else if (category === "20-24") {
      var lowerweek = 20;
      var upperweek = 24;
      return user.currentWeek <= upperweek && user.currentWeek >= lowerweek;
    } else if (category === "24-28") {
      var lowerweek = 24;
      let upperweek = 28;
      return user.currentWeek <= upperweek && user.currentWeek >= lowerweek;
    } else if (category === "28-32") {
      var lowerweek = 4;
      var upperweek = 8;
      return user.currentWeek <= upperweek && user.currentWeek >= lowerweek;
    } else if (category === "32-36") {
      var lowerweek = 32;
      var upperweek = 36;
      return user.currentWeek <= upperweek && user.currentWeek >= lowerweek;
    } else if (category === "36-40") {
      var lowerweek = 36;
      var upperweek = 40;
      console.log(category);
      return user.currentWeek <= upperweek && user.currentWeek >= lowerweek;
    } else if (category === "40+") {
      var lowerweek = 40;
      var upperweek = 40;
      console.log(category);
      return user.currentWeek > upperweek;
    } else return user;
  };

  const bySearch = (user, search) => {
    if (search) {
      // console.log(search);
      if (user && user.firstname) {
        return user.firstname.toLowerCase().includes(search.toLowerCase());
      }
    } else return user;
  };

  const filteredList = (clientDetails, search) => {
    return clientDetails
      .filter((user) => byCategory(user, category))
      .filter((user) => bySearch(user, search));
  };
  const updateStatus = (id) => {
    axios
      .post(
        "activateOrDeactivate/",
        { id: id },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Token ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        const res = response.data;
        alert(res.Success);
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  };

  return (
    <>
      {/* {userId} */}
      {traker ? <Dailytraker userId={userId} /> : null}
      <div className="sort-container lightgradient2" style={styles.sortit}>
        <span style={{ fontSize: "12px" }}>
          <FontAwesomeIcon icon={faSort} /> Sort By:{" "}
          <select
            className="docoptions"
            style={styles.option}
            onChange={(e) => setCategory(e.target.value)}
          >
            <option>Week</option>
            <option value="0-4">0-4</option>
            <option value="4-8">4-8</option>
            <option value="8-12">8-12</option>
            <option value="12-16">12-16</option>
            <option value="16-20">16-20</option>
            <option value="20-24">20-24</option>
            <option value="24-28"> 24-28</option>
            <option value="28-32">28-32</option>
            <option value="32-36">32-36</option>
            <option value="36-40">36-40</option>
            <option value="40+">40+ </option>
          </select>
          <FontAwesomeIcon icon={faSortDown} />{" "}
        </span>
      </div>
      <div className="all-clients-table lightgradient2" style={styles.card}>
        <div style={styles.inner}>
          <p>
            <strong>All Clients List</strong>
          </p>
          <hr style={{ color: "#fff" }} />

          <div className="table-cover table-height">
            <table style={{ borderSpacing: " 0px 1em" }}>
              <thead>
                <tr>
                  <td>Name</td>
                  <td>Age</td>
                  <td>Location</td>
                  <td>Due Date</td>
                  <td>Doctor</td>
                  <td>Daily Tracker</td>
                  <td>Account Status</td>
                </tr>
              </thead>
              <tbody>
                {console.log(clientDetails)}
                {/* {filteredList(ele, search).map((ele) => ( */}
                {clientDetails &&
                  clientDetails
                    .filter((ele) => {
                      if (searchval) {
                        // console.log(search);
                        if (ele && ele.firstname) {
                          return `${ele.firstname} ${ele.lastname}`
                            .toLowerCase()
                            .includes(searchval.toLowerCase());
                        }
                      } else return ele;
                    })
                    .map((ele) => {
                      return (
                        <tr key={ele.id} className="trfortable">
                          <td>
                            <Link
                              to={`/admin/client/${ele.id}`}
                              // style={styles.tolink}
                            >
                              {ele.firstname} {ele.lastname}
                            </Link>
                          </td>
                          <td> {ele.age}</td>
                          <td>{ele.location}</td>
                          <td>{ele.dueDate}</td>
                          <td>{`Dr. ${ele.doctor_firstname} ${ele.doctor_lastname}`}</td>
                          <td>
                            <button
                              onClick={() => openDailytraker(ele.id)}
                              style={styles.sbtn}
                            >
                              Detail
                            </button>
                            {/* <Link to={`/admin/user_tracker/${ele.id}`}>
                              <button>Details</button>
                            </Link> */}
                          </td>
                          <td>
                            <select
                              // onChange={() => handleColor()}
                              style={{ background, border: "none" }}
                              onChange={() => updateStatus(ele.id)}
                            >
                              {ele.is_active == true ? (
                                <>
                                  <option>Enable</option>{" "}
                                  <option>Disabled</option>
                                </>
                              ) : (
                                <>
                                  <option>Disabled</option>
                                  <option>Enable</option>
                                </>
                              )}
                            </select>
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

const styles = {
  card: {
    // display: "flex",
    justifyContent: "space-between",

    // backdropFilter: "blur(31px) brightness(-15%) opacity(.3)",

    padding: "20px",
    borderRadius: "10px",
    margin: "10px",
  },
  inner: {
    textDecoration: "none",
    color: "#fff",
  },

  sortit: {
    background: "#ffffff1c 0% 0% no-repeat padding-box",
    margin: "10px",
    borderRadius: "10px",
    textAlign: "left",
    padding: "10px",
  },

  option: {
    background: "transparent",
    border: "none",
    appearance: "none",
    color: "#fff",
  },

  tolink: {
    background: " rgb(133, 55, 138) none repeat scroll 0% 0%",
    textDecoration: "none",
    padding: "5px",
    color: "#fff",
  },
};
