import React from "react";
import Sidebar from "../../components/sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faUser, faBars } from "@fortawesome/free-solid-svg-icons";
// import Toggler from "../../toggle";
// import ClientProfile from "../../tog_profile";
import { getSalesDashboardDetails } from "../../../helpers/service";
//import Client from './components/clients';
//import Doctor from './components/doctors';
// import Table from "./components/clients_table";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Bar2 from "./analatics_chart";
import TotalClients from "../../components/clients";
import TotalClientsAllTime from "../../components/alltime_clients";
// import Clients from "./components/client_lists";

import ClientProfile from "../../toggle_profile";
import axios from "axios";
import { Redirect } from "react-router";

export default class Overview extends React.Component {
  constructor(props) {
    super(props);
    this.fetchSalesDashboardDetails = getSalesDashboardDetails();

    this.state = {
      isToggleOn: true,
      isProfile: true,
      details: {},
      searchText: "",
      sortBy: "week",
      items4: [],
      filteredTableData: [],
    };
    this.clickedOutside = this.clickedOutside.bind(this);
    this.profileHandler = this.profileHandler.bind(this);

    // this.handleClick = this.handleClick.bind(this);
  }
  clickedOutside(e) {
    if (this.isWap && !this.isWap.contains(e.target)) {
      this.setState({ isToggleOn: true });
      this.setState({ isProfile: true });
    }
  }
  profileHandler() {
    this.setState({ isProfile: !this.state.isProfile });
  }
  componentDidMount() {
    if (localStorage.getItem("sales") === null) {
      localStorage.clear();
      window.location = "/clogin";
    }
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("sales"),
      },
    };

    axios.get("sales/sales-dashboard-details/", config).then((res) => {
      console.log(res.data);
      this.setState({ items4: res.data });
      this.setState({ details: res.data.details });
    });
  }

  componentDidMount() {
    if (!localStorage.getItem("token")) {
      localStorage.clear();
      window.location = "/clogin";
      return;
    }

    this.fetchSalesDashboardDetails.api().then((response) => {
      if (response) {
        this.setState((prevState) => ({
          ...prevState,
          details: response,
          filteredTableData: response.totalPatients_details,
        }));
      }
    });
  }

  componentWillUnmount() {
    // Cancels the API while cleanup
    this.fetchSalesDashboardDetails.cancel();
  }

  //   handleClick() {
  //     this.setState((prevState) => ({
  //       ...prevState,
  //       isToggleOn: !prevState.isToggleOn,
  //     }));
  //   }

  render() {
    function openMenu() {
      document.querySelector(".sidebar").classList.toggle("show");
    }

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    return (
      <div className="App">
        <Sidebar />
        <header className="overview">
          <p style={{ paddingRight: "10%", textAlign: "right" }}>
            {" "}
            {/* <div>{this.state.isToggleOn ? null : <Toggler />}</div> */}
            <FontAwesomeIcon
              style={{ marginRight: "1rem", cursor: "pointer" }}
              icon={faBell}
            />
            <FontAwesomeIcon
              onClick={this.profileHandler}
              style={{ cursor: "pointer" }}
              icon={faUser}
            />{" "}
            <span>
              {`${
                !!this.state.details.firstname
                  ? this.state.details.firstname
                  : ""
              } ${
                !!this.state.details.lastname ? this.state.details.lastname : ""
              }`.trim()}
            </span>
          </p>
          {/* {this.state.isProfile ? null : <ClientProfile />}{" "} */}
          <div style={{ width: "89%", display: "flex", paddingLeft: "10px" }}>
            <span onClick={openMenu} className="menu">
              <FontAwesomeIcon
                onClick={this.profileHandler}
                style={{ marginRight: "20px" }}
                icon={faBars}
                className="menu-icon"
              />
            </span>
            <div>
              {this.state.items4.firstname} {this.props.childern}
              {this.state.isProfile ? null : <ClientProfile />}
            </div>

            <span style={{ whiteSpace: "nowrap" }}>Analytics 2</span>
            <div style={{ padding: "4px", width: "100%" }}>
              <hr style={{ background: "white", color: "white" }} />
            </div>
          </div>
          <div className="mwrapper">
            <div className="mleft">
              {/* <div style={styles.card} className="search-form">
                <div style={styles.inner}>
                  <p style={{ textAlign: "left" }}>Search Clients</p>
                  <FontAwesomeIcon
                    style={{ marginRight: "-22px" }}
                    icon={faSearch}
                  />
                  <input
                    style={styles.input}
                    onChange={(e) => {
                      this.setState({ searchval: e.target.value });
                    }}
                    type="Search"
                  />
                </div>
              </div> */}
              <Bar2 />
            </div>
            <div className="mright">
              <div
                style={{
                  fontWeight: "bold",
                  textAlign: "left",
                  paddingLeft: "10px",
                }}
              ></div>
              {/* client this month */}
              {/* <TotalClients ccountmonth={this.state.ccountmonth} />
              <TotalClientsAllTime totalClients={this.state.count} /> */}
              {/* <div style={styles.client_list}>
                <strong>Clients</strong>
              </div>
              <Clients /> */}
            </div>
          </div>
        </header>
      </div>
    );
  }
}

const styles = {
  card: {
    display: "flex",
    justifyContent: "space-between",
    background: "#ffffff1c 0% 0% no-repeat padding-box",
    padding: "20px",
    borderRadius: "10px",
    margin: "10px",
  },
  inner: {
    textDecoration: "none",
    color: "#fff",
  },

  input: {
    width: "40rem",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderBottomColor: "1px solid #fff",
    background: "transparent",
    padding: "8px",
    marginLeft: "30px",
    outline: "none",
    color: "white",
  },
};
