import React from "react";
import { NavLink, NavNavLink } from "react-router-dom";
import Logo from "../../logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faThLarge,
  faBriefcaseMedical,
  faSms,
  faQrcode,
} from "@fortawesome/free-solid-svg-icons";
import { faAndroid, faAppStore } from "@fortawesome/free-brands-svg-icons";
// import Logo from "../../shelogo.png";

export default class Sidebar extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (localStorage.getItem("doc_token") === null) {
      localStorage.clear();
      window.location = "/clogin";
    }
  }
  // hideBar(e) {
  //   e.preventDefault();
  //   var sidebar = document.querySelector(".sidebar");
  //   sidebar.classList.remove("show");
  //   sidebar.classList.add("hide");
  // }
  render() {
    function hideBar() {
      var sidebar = document.querySelector(".sidebar");
      sidebar.classList.remove("show");
      sidebar.classList.add("hide");
    }
    return (
      <>
        {console.log(this.props.siderbarcoloroverview)}
        <div className="sidebar" style={styles.header}>
          <a class="close-icon" href="#" onClick={hideBar}>
            &#10006;
          </a>
          <img src={Logo} alt="" className="mainlogo" />
          <button className="backButton" onClick={() => window.history.back()}>
            Back
          </button>

          <ul style={styles.lists}>
            {this.props.siderbarcoloroverview ? (
              <NavLink
                className="porpssidebar"
                activeClassName="active3"
                to="/doctor"
              >
                <li style={styles.listItemActive}>
                  <div>
                    <FontAwesomeIcon
                      icon={faThLarge}
                      style={{ marginRight: "1rem" }}
                    />
                  </div>
                  <div> Overview</div>
                </li>
              </NavLink>
            ) : (
              <NavLink activeClassName="active3" to="/doctor">
                <li style={styles.listItemActive}>
                  <div>
                    <FontAwesomeIcon
                      icon={faThLarge}
                      style={{ marginRight: "1rem" }}
                    />
                  </div>
                  <div> Overview</div>
                </li>
              </NavLink>
            )}
            {this.props.siderbarcolor ? (
              <NavLink
              
                activeClassName="active3"
                className="porpssidebar"
                exact={true}
                to="/dappointment"
              >
                <li style={styles.listItemActive}>
                  <div>
                    <FontAwesomeIcon
                      icon={faThLarge}
                      style={{ marginRight: "1rem" }}
                    />
                  </div>
                  <div> Appointments</div>
                </li>
              </NavLink>
            ) : (
              <NavLink activeClassName="active3" to="/dappointment">
                <li style={styles.listItem}>
                  {" "}
                  <div>
                    <FontAwesomeIcon
                      icon={faBriefcaseMedical}
                      style={{ marginRight: "1rem" }}
                    />
                  </div>
                  <div> Appointments</div>
                </li>
              </NavLink>
            )}
            <NavLink activeClassName="active3" to="/dall_clients">
              <li style={styles.listItem}>
                <div>
                  <i className="fa fa-user" style={{ marginRight: "1rem" }}></i>
                </div>
                <div> All Clients List</div>
              </li>
            </NavLink>
            <NavLink activeClassName="active3" to="/docmessenger">
              <li style={styles.listItem}>
                <div>
                  <FontAwesomeIcon
                    icon={faSms}
                    style={{ marginRight: "1rem" }}
                  />
                </div>
                <div>Messages</div>
              </li>
            </NavLink>
          </ul>
          <div
            className="hide-mob"
            style={{
              position: "absolute",
              bottom: "3rem",
              left: "30px",
              width: "15%",
            }}
          >
            <p style={{ fontSize: "14px" }}>
              {" "}
              to enjoy services provided by shebirth on mobile
              <br />
              download our mobile app
            </p>
            <div>
              <button
                style={{
                  color: "#fff",
                  textDecoration: "none",
                  background: "transparent",
                  border: "none",
                }}
              >
                <a href="https://play.google.com/store/apps/details?id=com.smartbirth.shebirth">
                  <FontAwesomeIcon icon={faAndroid} />
                </a>
                <a style={{marginLeft:'9px',fontSize:'30px'}} href="https://apps.apple.com/in/app/shebirth-sukh-prasavam/id6461416949">
                <FontAwesomeIcon icon={faAppStore} />
              </a>
                {/* <FontAwesomeIcon icon={faCog} style={{ marginRight: "1rem" }} /> */}
                {/* Settings */}
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const styles = {
  header: {
    background: "#ffffff1c 0% 0% no-repeat padding-box",
    padding: "2rem",
    minHeight: "100%",
    width: "20%",
    margin: "0",
    color: "white",
  },
  lists: {
    marginTop: "5rem",
    color: "#fff",
    paddingLeft: "0",
    listStyle: "none",
  },
  listItem: {
    // background: "transparent",
    padding: "10px 50px",
    borderRadius: "10px",
    cursor: "pointer",
    display: "flex",
  },
  listItemActive: {
    // background: "#6a0f4b",
    padding: "10px 50px",
    borderRadius: "10px",
    cursor: "pointer",
    display: "flex",
  },
};
