import React from "react";
import { Bar } from "react-chartjs-2";
import { useEffect, useState } from "react";
import axios from "axios";
function Grap4() {
  const [graph, setgraph] = useState([]);
  const [appointment, setappointment] = useState([]);
  const [cancelled, setcancelled] = useState([]);
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("postdoc"),
      },
      param: {
        doctor: localStorage.getItem("doc_id"),
      },
    };

    axios.get("http://127.0.0.1:8000/doctor/doctor-dashboard-details/", config).then((res) => {
      console.log(res.data.graphData);
      let d = new Date("Y-M-D");
      let result = [];
      let appointments = [];
      for (const [k, v] of Object.entries(res.data.graphData)) {
        result.push(JSON.stringify(v.month));
        appointments.push(JSON.stringify(v.appointments));
        cancelled.push(JSON.stringify(v.cancelled));
      }
      setgraph(result);
      setappointment(appointments);
      setcancelled(cancelled);
      // console.log(`Here is key ${k} and here is value`, JSON.stringify());

      // setgraph(res.data.graphData);
      // console.log(res.data.Approved.length);
    });
  }, []);
  // for (const [k, v.month] of Object.entries(graph)) {
  //   console.log(`Here is key ${k} and here is value ${v}`);
  // }
  // const tifOptions = Object.keys(graph).map((key) => (
  //   <option value={key}>{key.graph}</option>

  // const propertyNames = Object.keys(graph).map((k) => k.month);

  // ));
  const data = {
    labels: graph,
    datasets: [
      {
        label: "Appointment",
        data: appointment,
        backgroundColor: "#6c8bff",
        color: "black",
        // hoverBackgroundColor: "rgba(255,99,132,0.4)",
        borderColor: "#6c8bff",
        borderWidth: 1,
      },

      {
        label: "Cancelled",
        color: "white",
        backgroundColor: "red",
        borderColor: "red",
        borderWidth: 1,
        // hoverBackgroundColor: "rgba(255,99,132,0.4)",
        // hoverBorderColor: "rgba(255,99,132,1)",
        data: cancelled,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        labels: {
          color: "white", // not 'fontColor:' anymore
          // fontSize: 18  // not 'fontSize:' anymore
          font: {
            size: 14, // 'size' now within object 'font {}'
          },
        },
      },
      datalabels: {
        color: "white",
        formatter: (value) => value + " %", // Add the percentage after the value
        align: "end",
        anchor: "end",
        clip: true, // Hide label if outside of the chart
      },
    },
    scales: {
      x: {
        ticks: {
          color: "white",
          beginAtZero: true,
        },

        grid: {
          display: false, // Hide x grid
        },
        max: 100,
      },
      y: {
        ticks: {
          color: "white",
        },
        grid: {
          display: false, // Hide y grid
        },
      },
    },
  };
  // console.log(propertyNames);
  return (
    <div style={{ background:'rgba(0,0,0,.4)',borderRadius:'15px', padding:'15px',}}>
      <Bar data={data} options={options} />
    </div>
  );
}
export default Grap4;
