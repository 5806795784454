import React, { useState, useEffect } from "react";
import HomeImage from "../img/Group774.png";
import DailyTrackerImg from "./img/dailytracker.png";
import Labour from "./img/laborportal.png";
import LearningPortal from "./img/learning.png";
import { Carousel } from "react-responsive-carousel";
import DailyTrackerDas from "./daily_tracker";

import axios from "axios";
import { Link } from "react-router-dom";
import Banner from "./imageslider";
import { set } from "react-hook-form";
export default function Home() {
  const [tracker, setDailyTracker] = useState(false);
  const [learn, setLearningPortal] = useState(false);
  const [images, setimages] = useState([]);
  // const [weight, setWeight] = useState([]);
  //const [describtion, setDescribtion] = useState([]);
  // const [length, setlength] = useState([]);
  const [babyDetails, setbabyDetails] = useState([]);
  const baseurl = "https://sukhprasavam.shebirth.com";
  function clickHandler() {
    setDailyTracker({ tracker: true });
  }

  function learningPortalOpen() {
    setLearningPortal({ learn: true });
  }

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("token"),
      },
    };

    axios.get("customer/customer-dashboard-details/", config).then((res) => {
      console.log(res.data);
      //  setWeight(res.data.babyDetails.weigth);
      // setlength(res.data.babyDetails.length);
      setimages(res.data.video[0]);
      setbabyDetails(res.data.babyDetails);
      // setDescribtion(res.data.babyDetails.description);
    });
  }, []);

  return (
    <>
      <div>
        <div className="outerhome" style={styles.outerhome}>
          <div className="wrapper" style={styles.wrapper}>
            <div
              style={{
                background: "#ffffff1c 0% 0% no-repeat padding-box",
                borderRadius: "10px",
              }}
            >
              {/* {babyDetails && babyDetails.image ? ( */}
              <img
                src={HomeImage}
                alt=""
                className="homeimage"
                style={{
                  width: "100%",
                }}
              />
              {/* ) : null} */}

              <div className="measure" style={{ fontSize: "14px" }}>
                What will your baby be able to do this week? She’ll be able to
                lift her head briefly when placed on her tummy. She’ll also be
                able to focus on objects that are within 8 to 12 inches away —
                exactly the distance your face will be when you’re gazing at
                her, something you’ll likely be doing a lot this week (and in
                the weeks to come)!
              </div>
            </div>

            <div
              className="homepurple"
              style={{
                padding: "20px 10px",
                // background: "#471358",
                borderRadius: "0 0 30px 30px",
              }}
            >
              <small style={{ fontSize: "14px" }}>
                {babyDetails && babyDetails.description}
              </small>
            </div>
          </div>
          <div className="wrapper">
            <Banner />
          </div>
          {/* <div className="bannerscreen">
                  {console.log(images)}
                  <>
                  <img src={images && baseurl + images.image} />
                </div> */}
          <div className="boxes" style={styles.boxes}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Link to="/postlearning">
                <div
                  className="box eqalboxmobile"
                  style={styles.box}
                  // onClick={learningPortalOpen}
                >
                  <img style={styles.imgrem} src={LearningPortal} alt="" />
                  <p style={styles.pp}>Learning Portal</p>
                </div>
              </Link>
              <Link to="/postdailytraker">
                <div
                  className="box eqalboxmobile"
                  style={styles.box}
                  onClick={clickHandler}
                >
                  <img style={styles.imgrem} src={DailyTrackerImg} alt="" />
                  <p style={styles.pp}>Daily Tracker</p>
                </div>
              </Link>
            </div>
          </div>
          <div
            style={{
              textAlign: "left",
              padding: "10px",
              fontSize: "19px",
              width: "95%",
            }}
          >
            <h3 className="margin-01">Extra care for your baby</h3>
            <p className="margin-01">
              Get that personal attantion from our Experts
            </p>
            <hr />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px",
              width: "90%",
              margin: "auto",
            }}
          >
            <div className="grad">
              <h6>Care Plan</h6>
              <p style={{ fontSize: "14px" }}>
                Get Care Plans From Experts For Your Baby Which Will Provide A
                Better Health Condition For You And Your Baby
              </p>
            </div>{" "}
            <div className="grad">
              <h6> Chat with Expert</h6>
              <p style={{ fontSize: "14px" }}>
                Ask our us for any health quaries and take help from your health
                coach
              </p>
            </div>
          </div>
          <div
            style={{
              textAlign: "left",
              padding: "10px",
              fontSize: "19px",
              width: "95%",
            }}
          >
            <h3 className="margin-01">Vaccinations</h3>
            <p className="margin-01">
              Get Notified About Your Babys Upcoming Vaccination Details
            </p>
            <hr />
          </div>
        </div>
      </div>
    </>
  );
}

const styles = {
  outerhome: { height: "600px", overflowY: "scroll", scrollbarWidth: "none" },
  wrapper: {
    padding: "0px",
    width: "90%",
    margin: "auto",
    // background: "#471358",
    borderRadius: "10px",
  },
  imgrem: {
    width: "4.6rem",
    marginTop: "5%",
  },
  images: {
    // display: "flex",
    alignItem: "center",
  },

  boxes: {
    padding: "10px",

    width: "90%",

    margin: "auto",
  },

  box: {
    height: "100px",
    width: "250px",
    background: "#ffffff1c 0% 0% no-repeat padding-box",
    margin: "20px 0px",
    borderRadius: "10px",
    cursor: "pointer",
  },

  pp: {
    background: "#f93580",
    margin: 0,
    fontSize: "16px",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
  },
};
