import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
//import ExerciseVideo from "../videos/video.mp4";
import AddExercise from "./add_exersie";
import moment from "moment";
import axios from "axios";
export default class Exercisetracker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      medicineOpen: true,
      medicineReady: true,
      items: [],
      calories: "",
      custom: [],
      url: [],
      addcalorei: "",
    };
    this.handleCalories = this.handleCalories.bind(this);
    this.closeMedicine = this.closeMedicine.bind(this);
    this.addMedicine = this.addMedicine.bind(this);
  }

  closeMedicine() {
    this.setState({ medicineOpen: !this.state.medicineOpen });
  }

  addMedicine() {
    this.setState({ medicineReady: !this.state.medicineReady });
  }
  handleCalories(e) {
    var calorieBurnt = e.target.value;
    this.setState({ addcalorei: e.target.value });
  }
  submitCalories = (e) => {
    e.preventDefault();
    const date = moment().format("YYYY-MM-DD");
    const Data = {
      value: this.state.addcalorei,
      customer: this.props.customer,
      date: date,
      //exercise: id,
    };
    const headers = {
      Authorization: `token ` + localStorage.getItem("sales"),
    };
    axios
      .post("customer/calories-burnt-add/", Data, {
        headers: headers,
      })
      .then(() => {
        alert("calories was submitted");
      })
      .catch((error) => {
        alert("error try again");
      });
  };
  componentDidMount() {
    if (localStorage.getItem("sales") === null) {
      localStorage.clear();
      window.location = "/clogin";
    }
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("sales"),
      },
    };
    const date = moment().format("YYYY-MM-DD");
    axios
      .get(
        "customer/exercise-get/?customer=" +
          this.props.customer +
          "&date=" +
          this.props.date,
        config
      )
      .then((res) => {
        let ytUrl = res.data.url;
        if (res.data.url && ytUrl) {
          let newurl = ytUrl.replace("/watch?v=", "/embed/");
          this.setState({ url: newurl });
        } else {
          let newurl = " https://www.youtube.com/embed/NLOp_6uPccQ";
          this.setState({ url: newurl });
        }
        console.log(res.data);
        //this.setState({ url: newurl });
        this.setState({ calories: res.data.calorieBurnt });
        this.setState({ items: res.data.exercises });

        this.setState({ custom: res.data.custom });
      });
  }

  handleCheckClick = (e) => {
    var date = moment().format("YYYY-MM-DD");
    const { id, checked } = e.target.dataset;

    if (e.target.checked) {
      var checkbox = "True";
    } else {
      var checkbox = "False";
    }

    const Data = {
      completed: checkbox,
      date: date,
      customer: this.props.customer,
      exercise: id,
    };
    const headers = {
      Authorization: `token ` + localStorage.getItem("sales"),
    };
    axios
      .post("customer/exercise-submit/", Data, {
        headers: headers,
      })
      .then(() => {
        alert("Exersise was submitted");
      })
      .catch((error) => {
        alert("Cannot add Exersise again");
      });

    //  if (e.target.checked === "true") {
    //    console.log(name, checked);
    //  } else {
    //    console.log(name, checked);
    //  }
  };

  CustumhandleCheckClick = (e) => {
    var date = moment().format("YYYY-MM-DD");
    const { id, checked } = e.target.dataset;

    if (e.target.checked) {
      var checkbox = "True";
    } else {
      var checkbox = "False";
    }

    const Data = {
      completed: checkbox,
      date: date,
      customer: this.props.customer,
      exercise: id,
    };
    const headers = {
      Authorization: `token ` + localStorage.getItem("sales"),
    };
    axios
      .post("customer/custom-exercise-submit/", Data, {
        headers: headers,
      })
      .then(() => {
        alert("Exersise was submitted");
      })
      .catch((error) => {
        alert("Cannot add Exersise again");
      });

    //  if (e.target.checked === "true") {
    //    console.log(name, checked);
    //  } else {
    //    console.log(name, checked);
    //  }
  };
  render() {
    return (
      <>
        {this.state.medicineOpen ? (
          <div style={styles.card}>
            {this.state.medicineReady ? null : <AddExercise />}
            <div style={styles.inner}>
              <h2
                className="lightgradient2"
                style={{
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  padding: "10px 0px",
                  margin: "0px",
                  fontSize: "26px",
                  // background: "#8c3186",
                }}
              >
                Update Exercise Tracker
              </h2>
              {/* <Tabs>
                <TabList
                  style={{
                    width: "100%",
                    listStyle: "none",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Tab>
                    <strong style={{ padding: "10px", cursor: "pointer" }}>
                      Exercise Tracker
                    </strong>
                  </Tab>
                  <Tab>
                    <strong style={{ padding: "10px", cursor: "pointer" }}>
                      Customized Exercise
                    </strong>
                  </Tab>
                </TabList>

                <TabPanel> */}

              {/* <video
                    src={this.state.url}
                    alt=""
                    height="350rem"
                    width="600rem"
                    controls
                  ></video> */}
              <div className="table-wrapper">
                <table
                  style={{
                    marginTop: "20px",
                    background: "#ffffff1c 0% 0% no-repeat padding-box",
                  }}
                >
                  <tr style={styles.trgrid} className="trgridexerseice">
                    {this.state.items.map((personData, index) => {
                      return (
                        <>
                          <td key={index}>{personData.name}</td>{" "}
                          <td>
                            <input
                              type="checkbox"
                              data-id={personData.id}
                              // type="checkbox"
                              defaultChecked={
                                personData && personData.completed
                              }
                              onChange={this.handleCheckClick}
                            />
                          </td>
                        </>
                      );
                    })}
                  </tr>

                  <tr style={styles.trgrid} className="trgridexerseice">
                    {this.state.custom.map((personData, index) => {
                      return (
                        <>
                          <td key={index}>{personData.name}</td>{" "}
                          <td>
                            <input
                              type="checkbox"
                              data-id={personData.id}
                              // type="checkbox"
                              defaultChecked={
                                personData && personData.completed
                              }
                              onChange={this.CustumhandleCheckClick}
                            />
                          </td>
                        </>
                      );
                    })}
                  </tr>
                </table>
              </div>
              <br />
              <div style={styles.caloribackgroundcol} className="mobcalories">
                <p style={{ margin: "5%", fontSize: "16px" }}>
                  {" "}
                  Total Calories Burned
                </p>
                <input
                  style={styles.inputpink}
                  type="number"
                  // data-id={personData.id}
                  defaultValue={this.state.calories}
                  onChange={this.handleCalories}
                />
                <button
                  type="submit"
                  onClick={this.submitCalories}
                  style={styles.greenbtn}
                  className="pinkbutton"
                >
                  Submit
                </button>
              </div>
              <br />

              <button
                style={styles.subbtn}
                className="pinkbutton"
                onClick={this.addMedicine}
              >
                Add new user defined exercise here
              </button>
              {/* <span>Add new suser defined exercise here</span> */}
              <hr />
              <button style={styles.subbtn} className="pinkbutton">
                Submit
              </button>
              {/* </TabPanel>

                <TabPanel style={styles.cdiet}></TabPanel>
              </Tabs> */}
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

const styles = {
  backgroundcol: { background: "#ffffff1c 0% 0% no-repeat padding-box" },
  caloribackgroundcol: {
    background: "#ffffff1c 0% 0% no-repeat padding-box",
    display: "flex",
  },
  module: {
    position: "absolute",
    background: "rgb(76, 0, 61) none repeat scroll 0% 0%",
    margin: "10px",
    borderRadius: "10px",
    // padding:'20px 10px'
  },

  subbtn: {
    // background: "linear-gradient(90deg, #3a51a7 30%, #b53899)",
    padding: "10px 30px",
    border: "none",
    borderRadius: "10px",
    color: "#fff",
    cursor: "pointer",
  },

  btn: {
    background: "linear-gradient(90deg, #3a51a7 30%, #b53899)",
    padding: "10px 20px",
    border: "none",
    borderRadius: "10px",
    color: "#fff",
    cursor: "pointer",
    margin: "10px 0px",
  },

  card: {
    display: "flex",
    justifyContent: "space-between",
    //background: "#8B3598",
    padding: "0px",
    borderRadius: "10px",
    margin: "10px",
  },

  inner: {
    textDecoration: "none",
    color: "#fff",
    width: "100%",
  },
  trgrid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
  },
  cdiet: {
    height: "18rem",
    overflowY: "scroll",

    scrollbarWidth: "none",
    boxSizing: "content-box",
  },

  inputpink: {
    background: "#ffffff1c 0% 0% no-repeat padding-box",
    border: "none",
    margin: "5%",
    color: "white",
  },
  greenbtn: {
    // background: "rgb(0, 200, 52)",
    border: "none",
    borderRadius: "0px; margin: 5%",
    margin: "5%",
    color: "white",
  },
};
