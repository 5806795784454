import React from "react";
import { Overlay } from "react-bootstrap";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Activitytracker from "./tabs_com/newactivity";
import Contractioncounter from "./tabs_com/contraction_counter";
import Dietracker from "./tabs_com/diet_tracker";
import Exercisetracker from "./tabs_com/newwxersise";
import Medicaldetail from "./tabs_com/medical_detail";
import Medicinetracker from "./tabs_com/medicine_tracker";
import Symptomstracker from "./tabs_com/symptoms_tracker";
import Kickdetail from "./tabs_com/kickdetails";

class Clist extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { customerId, closeDailyTracker } = this.props;

    return (
      <>
        {!!customerId ? (
          <div
            style={styles.card}
            className="multiple-tab dailytabmobile prupleblur width-85 takerfull"
          >
            <i
              onClick={closeDailyTracker}
              className="fa fa-close"
              style={styles.closebtn}
            ></i>
            <div style={styles.inner} className="w100">
              <Tabs className="selectedtaber">
                <TabList
                  style={{
                    width: "100%",
                    paddingLeft: "0px",
                    listStyle: "none",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Tab>
                    <strong style={styles.tabnav}>Diet Tracker</strong>
                  </Tab>
                  <Tab>
                    <strong style={styles.tabnav}>Medicine Tracker</strong>
                  </Tab>
                  <Tab>
                    <strong style={styles.tabnav}>Exercise Tracker</strong>
                  </Tab>
                  <Tab>
                    <strong style={styles.tabnav}>Symptoms Tracker</strong>
                  </Tab>
                  <Tab>
                    <strong style={styles.tabnav}>Activity Tracker</strong>
                  </Tab>
                  <Tab>
                    <strong style={styles.tabnav}>Contraction Counter</strong>
                  </Tab>
                  <Tab>
                    <strong style={styles.tabnav}>Medical Details</strong>
                  </Tab>
                  <Tab>
                    <strong style={styles.tabnav}>Kick details</strong>
                  </Tab>
                </TabList>

                <TabPanel>
                  <Dietracker customer={customerId} />
                </TabPanel>

                <TabPanel>
                  <Medicinetracker customer={customerId} />
                </TabPanel>

                <TabPanel>
                  <Exercisetracker customer={customerId} />
                </TabPanel>

                <TabPanel>
                  <Symptomstracker customer={customerId} />
                </TabPanel>

                <TabPanel>
                  <Activitytracker customer={customerId} />
                </TabPanel>

                <TabPanel>
                  <Contractioncounter customer={customerId} />
                </TabPanel>

                <TabPanel>
                  <Medicaldetail customer={customerId} />
                </TabPanel>
                <TabPanel>
                  <Kickdetail id={customerId} />
                </TabPanel>
              </Tabs>
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

const styles = {
  card: {
    display: "flex",
    justifyContent: "space-between",
    // background: "#4c003d",
    padding: "20px",
    borderRadius: "10px",
    margin: "10px",
    position: "fixed",
    transform: "translate(-10rem, -6rem)",
    zIndex: 10,
    height: "20rem",
    overflow: "overlay",
  },

  inner: {
    textDecoration: "none",
    color: "#fff",
  },

  tabnav: {
    cursor: "pointer",
    fontSize: "14.3px",
    whiteSpace: "nowrap",
    padding: "20px 10px",
  },

  closebtn: {
    position: "absolute",

    top: "0px",
    cursor: "pointer",
    color: "black",
    fontSize: "24px",

    right: "4px",
    top: "0px",
    cursor: "pointer",
  },
};

export default Clist;
