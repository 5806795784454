import React, { useState, useEffect } from "react";
// import { Chart } from "react-charts";
import axios from "axios";
import Barchart from "../components/BarChart";
// import { Line } from "react-chartjs-2";
import MonthlyPatients from "./monthly_patients";
import AllTimePatients from "./patients_all_time";

export default function Home() {
  const [tracker, setDailyTracker] = useState(false);
  const [learn, setLearningPortal] = useState(false);
  const [graph, setgraph] = useState([]);

  const data = {
    labels: ["a", "b"],
    dataSet: [{ label: "sales os 2020", data: [1, 2, 3, 4] }],
  };

  // const [describtion, setDescribtion] = useState([]);
  // const [length, setlength] = useState([]);
  // const [babyDetails, setbabyDetails] = useState([]);

  function clickHandler() {
    setDailyTracker({ tracker: true });
  }

  function learningPortalOpen() {
    setLearningPortal({ learn: true });
  }

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("doc_token"),
      },
      param: {
        doctor: localStorage.getItem("doc_id"),
      },
    };

    axios.get("appointments/full-appointment/", config).then((res) => {
      console.log(res.data);
      let d = new Date("Y-M-D");
      setgraph(res.data.graphData);
      console.log(res.data.Approved.length);
    });
  }, []);

  // const data = React.useMemo(
  //   () => [
  //     {
  //       labels: [
  //         "January",
  //         "February",
  //         "March",
  //         "April",
  //         "May",
  //         "June",
  //         "July",
  //       ],

  //       data: [
  //         [0, 10],
  //         [1, 3],
  //         [2, 4],
  //         [3, 3],
  //         [4, 7],
  //         [5, 6],
  //         [6, 4],
  //         [7, 3],
  //         [8, 5],
  //         [9, 6],
  //         [10, 2],
  //         [11, 2],
  //         [12, 4],
  //         [13, 0],
  //       ],
  //     },

  //     {
  //       label: "Appointment Approved",
  //       data: [
  //         [0, 3],
  //         [1, 3],
  //         [2, 5],
  //         [3, 4],
  //         [4, 5],
  //         [5, 5],
  //         [6, 3],
  //         [7, 4],
  //         [8, 7],
  //         [9, 6],
  //         [10, 3],
  //         [11, 8],
  //         [12, 8],
  //         [13, 0],
  //       ],
  //     },
  //   ],
  //   []
  // );

  // const series = React.useMemo(
  //   () => ({
  //     type: "bar",
  //   }),
  //   []
  // );

  // const axes = React.useMemo(
  //   () => [
  //     { primary: true, type: "linear", position: "bottom" },
  //     { type: "linear", position: "left" },
  //   ],
  //   []
  // );

  // const lineChart = (
  //   <div
  //     style={{
  //       width: "auto",
  //       height: "300px",
  //     }}
  //   >
  //     <Bar
  //       data={{
  //         labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
  //       }}
  //       width={400}
  //       height={300}
  //     />
  //   </div>
  // );

  return (
    <>
      <div style={styles.outerchart} className="lightgradient">
        <Barchart />
      </div>

      <div className="counter-collect" style={styles.counterCollection}>
        <MonthlyPatients />
        <AllTimePatients />
      </div>
    </>
  );
}

const styles = {
  outerchart: {
    // backdropFilter: "blur(31px) brightness(-15%) opacity(.3)",
    // backdropFilter: "blur(71px) brightness(-15%) opacity(0.5)",
    marginTop: "0px",
  },
  wrapper: {
    padding: "0px",
    width: "600px",
    margin: "auto",
    backdropFilter: "blur(31px) brightness(-15%) opacity(.2)",
    borderRadius: "10px",
  },

  images: {
    display: "flex",
    alignItem: "center",
  },

  boxes: {
    padding: "10px",
    width: "600px",
    margin: "auto",
  },

  box: {
    height: "100px",
    width: "250px",
    background: "#9d5ebe",
    margin: "20px 0px",
    borderRadius: "10px",
    cursor: "pointer",
  },

  pp: {
    background: "#f93580",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
  },

  counterCollection: {
    display: "flex",

    //  flexDirection: "space-between",
    justifyContent: "space-between",
  },
};
