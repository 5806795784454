import React, { useState } from 'react';
import a from './pic2.jpg';
import b from './pic3.jpg';
import c from './pic1.jpg';
import { useEffect } from 'react';
import './slider.css';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
const images = [
  
  b,
  c,
  
]; // Replace with your image URLs
const FullScreenSlider = () => {
    const slideInterval = 6000;
    const history=useHistory();

  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((currentSlide + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentSlide((currentSlide - 1 + images.length) % images.length);
  };
useEffect(() => {
    const autoSlide = setInterval(nextSlide, slideInterval);

    return () => {
      clearInterval(autoSlide); // Clear the interval when the component unmounts
    };
  }, [currentSlide]);

  return (
    <div className="full-screen-slider">
      <div className="slide" style={{ backgroundImage: `url(${images[currentSlide]})` }}>

        <span><img src="./logo.png" style={{width:'40px',padding:'10px',}} /> <h4 style={{color:'white',display:'inline',marginTop:'-15px'}}>sukhprasavam</h4></span>
       <h1 className='brandname' >Sukhprasavam</h1>
       <h1 className='label'>Pleasing Birth Without Fear</h1>
       <button onClick={()=>{history.push('/login')}} className='button'>Get&nbsp;Started</button>
       <button onClick={()=>{history.push('/loginpostnatal')}} className='button'>Post&nbsp;Natal</button>
       
      </div>
    </div>
  );
};

export default FullScreenSlider;
