import React from "react";
import { Line } from "react-chartjs-2";
import { useState, useEffect } from "react";
import axios from "axios";

export default function Graph({ id }) {
  const [data2, setData] = useState([]);
  useEffect(() => {
    // if (localStorage.getItem("token") === null) {
    //   localStorage.clear();
    //   //window.location = "/clogin";
    // }
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("doc_token"),
      },
    };
    //remove this date after setting up the admin pannel

    axios
      .get(
        "customer/get-all-dates-symptoms/?customer=" + id,
        config

        // config
      )
      //
      .then((res) => {
        console.log('data',res.data)
        const apidata = res.data.symptoms.concat(res.data.symptoms_with_text);
        const maindata = apidata.concat(res.data.custom_symptoms);
        const groupedByDate = maindata.reduce((symptoms, symptom) => {
          if (symptom.date in symptoms) {
            symptoms[symptom.date].push(symptom);
          } else {
            symptoms[symptom.date] = [symptom];
          }
          return symptoms;
        }, {});

        
        const sortedOnDate = Object.values(groupedByDate).sort((arr1, arr2) => {
          const ymdDate1 = arr1[0].date.split("-").reverse().join("-");
          const ymdDate2 = arr2[0].date.split("-").reverse().join("-");
          if (ymdDate1 < ymdDate2) {
            return 1;
          } else if (ymdDate1 > ymdDate2) {
            return -1;
          }
          return 0;
        });
       
        setData(sortedOnDate);
      });
  }, []);
  // Sample data for the line graph
  const chartData = data2.map((symptom) => ({
    x: symptom.symptom,
    y: new Date(symptom.date),
  }));

  const data = {
    datasets: [
      {
        label: "Symptom Timeline",
        data: chartData,
        borderColor: "rgb(75, 192, 192)",
        fill: false,
      },
    ],
  };

  const options = {
    scales: {
      xAxes: [
        {
          type: "category",
          labels: data2.map((symptom) => symptom.symptom),
          scaleLabel: {
            display: true,
            labelString: "Symptoms",
          },
        },
      ],
      yAxes: [
        {
          type: "time",
          time: {
            unit: "day",
          },
          scaleLabel: {
            display: true,
            labelString: "Date",
          },
        },
      ],
    },
  };

  return (
    <div>
      <Line data={data} options={options} />
    </div>
  );
}
