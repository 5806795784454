import React from "react";
import img from "../img/man.jpg";

import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
export default function Livechat(props) {
  const [inputField, setInputField] = useState();

  const [data, setdata] = useState();
  const [Message, setMessage] = useState([]);
  const [reload, setreload] = useState(0);
  console.log(props.chatid);
  var chatid = props.chatid;
  console.log(chatid);
  const inputsHandler = (e) => {
    setInputField(e.target.value);
  };

  function sentchat(e) {
    e.preventDefault();

    console.log(inputField);

    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("token"),
      },
    };
    const data = { receiver: props.chatid, message: inputField };
    axios
      .post("messages/send-message/", data, config)
      .then((res) => {
        console.log(res.receiverDetails);
        let reloder = 1;
        reloder = reload + reloder;
        setreload(reloder);
        document.getElementById("create-course-form").reset();

        console.log(reload);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("token"),
      },
    };

    axios
      .get(`messages/get-all-messages/?receiver=` + chatid, config)
      .then((res) => {
        console.log(res.receiverDetails);
        setdata(res.data.receiverDetails);
        setMessage(res.data.messages);
      });
  }, [props.id, reload, chatid]);

  function formatDate(dateString) {
    if (!dateString) {
      return ""; // Return an empty string if dateString is not provided
    }

    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = new Date(dateString).toLocaleDateString(
      undefined,
      options
    );

    return formattedDate;
  }

  function isToday(dateString) {
    const messageDate = new Date(dateString);
    const today = new Date();

    return (
      messageDate.getDate() === today.getDate() &&
      messageDate.getMonth() === today.getMonth() &&
      messageDate.getFullYear() === today.getFullYear()
    );
  }

  function formatTime(dateString) {
    const messageDate = new Date(dateString);
    const options = { hour: "numeric", minute: "numeric", hour12: true };
    const formattedTime = messageDate.toLocaleTimeString(undefined, options);

    return formattedTime;
  }

  return (
    <>
      <div className="mobileback bcak">
        {" "}
        <div className="chat-avatar ">
          <div>
            {data &&
            data.image_url !=
              "https://sukhprasavam.shebirth.com/media//ProfilePic/default.jpg" ? (
              <img className="avatar" src={data && data.image_url} alt="" />
            ) : data &&
              data.image_url ==
                "https://sukhprasavam.shebirth.com/media//ProfilePic/default.jpg" ? (
              <button
                className="avatar aquamarine "
                style={{ maxWidth: "65px", border: "none" }}
              ></button>
            ) : (
              <button
                className="avatar aquamarine "
                style={{ maxWidth: "65px", border: "none" }}
              ></button>
            )}
          </div>
          {console.log(data)}
          <div className="alignstart">
            {data && data.name}
            <p className="margin-0"> {data && data.speciality}</p>
          </div>
          <div className="timefont">
            {data &&
              (data.last_message_time
                ? isToday(data.last_message_time)
                  ? formatTime(data.last_message_time)
                  : formatDate(data.last_message_time)
                : formatDate(data.joining_date))}
          </div>
        </div>
        <div class="speech-wrapper gradiantblur">
          {Message.map((data) => (
            <div class="bubble">
              <div class="txt">
                <p class="name">{data.sender}</p>
                <p class="message">{data.message}</p>
                <span class="timestamp">{data.time}</span>
              </div>
              <div class="bubble-arrow"></div>
            </div>
          ))}
        </div>{" "}
      </div>
      <div class="chat__form chatformborder">
        <form id="create-course-form" onSubmit={sentchat}>
          <input
            className="chattype"
            id="text-message"
            onChange={inputsHandler}
            type="text"
            placeholder="Type your message here ..."
          />
          <button type="submit">Send</button>
        </form>
      </div>
    </>
  );
}
