import axios from "axios";
import React from "react";
import { useEffect, useState } from "react";

export default function SymptomsTracker(props) {
  var pikerdate = props.dataFromParent;
  const [symtoms, setsymtoms] = useState([]);
  const [customSymptom, setcustomSymptom] = useState([]);
  const [symptomsWithIputs, setsymptomsWithIputs] = useState([]);
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("token"),
      },
    };

    axios
      .get("customer/Symptoms-GET/?date=" + pikerdate, config)
      .then((res) => {
        console.log(res.data.symptomsWithIputs);
        let filteredData = [];
        let filteredData2 = [];

        res.data.Symptoms.forEach((e) => {
          if (e.positive === true) filteredData.push(e);
        });

        res.data.customSymptom.forEach((e) => {
          if (e.positive === true) filteredData2.push(e);
        });
        setsymtoms(filteredData);
        setcustomSymptom(filteredData2);
        setsymptomsWithIputs(res.data.symptomsWithIputs);
      });
  }, []);

  return (
    <table style={{ width: "100%", borderCollapse: "collapse" }}>
      <thead style={{ borderBottom: "5px solid #5c0048" }}>
        <tr style={{ background: "#ffffff1c 0% 0% no-repeat padding-box" }}>
          <th style={styles.th}>Date</th>
          <th style={styles.th}>Symptoms</th>
          <th style={styles.th}>Weight</th>
          <th style={styles.th}>Blood Sugar Level</th>
          <th style={styles.th}>Blood PressureLevel</th>
          <th style={styles.th}>Last Week Report</th>
        </tr>
      </thead>

      <tbody>
        <tr style={{ background: "#ffffff1c 0% 0% no-repeat padding-box" }}>
          <td style={styles.tdFirst}>{pikerdate}</td>
          <td style={styles.td}>
            {customSymptom.map((personData, index) => {
              return <div key={index}>{personData.symptom}</div>;
            })}
            {symtoms.map((personData, index) => {
              return <div key={index}>{personData.name}</div>;
            })}
          </td>
          {/* Nausea <br /> Backpain <br />
            Constipation <br />
            Tringling Or Aching Breasts <br />
            Motion SicknessymptomsWithIputs
          </td> */}

          <td style={styles.td}>
            {symptomsWithIputs[0] && symptomsWithIputs[0].others}
          </td>
          <td style={styles.td}>
            {symptomsWithIputs[0] && symptomsWithIputs[0].bloodSugar}
          </td>
          <td style={styles.td}>
            {symptomsWithIputs[0] && symptomsWithIputs[0].bloodPressure}
          </td>
          <td style={styles.td}>
            {symptomsWithIputs[0] && symptomsWithIputs[0].report}
          </td>
        </tr>
      </tbody>
    </table>
  );
}

const styles = {
  th: {
    fontSize: "17px",
    whiteSpace: "nowrap",
    padding: "5px 10px",
    margin: "10px 0px",
  },

  td: {
    borderLeft: "1px solid #fff",
    padding: "5px 10px",
    fontSize: "12px",
    textAlign: "left",
    lineHeight: "20px",
  },

  tdFirst: {
    padding: "5px 10px",
    fontSize: "12px",
  },
};
