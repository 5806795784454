import React, { useState } from "react";
import User from "./user";
import { useEffect } from "react";
import axios from "axios";
import slogo from "../img/sukhprasavamlogo.png";

export default function Initaledit() {
  const [showForm, setShowForm] = useState(false);
  const [data, setdata] = useState();

  const handleContinueClick = () => {
    setShowForm(true);
  };

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("token"),
      },
    };

    axios.get("/get-customer-profile/", config).then((res) => {
      console.log(res.data.flag);
      setdata(res.data.flag);
      if (res.data.flag) {
        // Replace "/target-location" with the actual URL where you want to redirect the client
        window.location.href = "/client";
      }
      // setdetails(res.data.details);
    });
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      {!showForm ? (
        <div className="success">
          <h1>Welcome to</h1>
          <img className="slogo3" src={slogo} />

          <br />
          <button className="pinkbutton2" onClick={handleContinueClick}>
            Continue
          </button>
        </div>
      ) : (
        <div className="width-50 blur">
          <div className="width-100 llogi">
            {" "}
            <img className="slogo" src={slogo} />
          </div>

          <h1
            className="text-center"
            style={{
              color: "white",
            }}
          >
            Tell us about You
          </h1>
          <User />
        </div>
      )}
    </div>
  );
}
