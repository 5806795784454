import { useEffect, useState } from "react";
import axios from "axios";
export default function Addactivity() {
  const [close, setClose] = useState(false);
  const [allValues, setallValues] = useState("");

  function closeDialog() {
    setClose(true);
  }
  function changeHandler(e) {
    setallValues(e.target.value);
  }
  const submitValue = (e) => {
    e.preventDefault();
    // const symptom = allValues;

    // const { name } = e.target.dataset;
    const Data = {
      name: allValues,
      //   // stage: "stage9",
      //   notes: notes,
    };
    console.log(allValues);
    //setAllValues({ ...all
    const headers = {
      Authorization: `token ` + localStorage.getItem("token"),
    };
    axios
      .post("customer/add-custom-activity/", Data, {
        headers: headers,
      })
      .then(() => {
        alert("activity was submitted");
      })
      .catch((error) => {
        alert("Cannot add activity again");
      });
  };
  return (
    <>
      {close ? null : (
        <div style={styles.cover}>
          <h3>Add New activity</h3>
          <hr />

          <form style={styles.form} onSubmit={submitValue}>
            <span>Activity</span>
            <input
              style={styles.input}
              type="text"
              className="calanderbutton"
              onChange={changeHandler}
              defaultValue={allValues}
              //value=""
            />
            <input style={styles.sbtn} type="submit" value="ADD" />
          </form>
          <button
            style={styles.dbtn}
            className="pinkbutton"
            onClick={closeDialog}
          >
            Done
          </button>
        </div>
      )}
    </>
  );
}

const styles = {
  cover: {
    background: "#ffffff1c 0% 0% no-repeat padding-box",
    padding: "5px 10px 30px 10px",
    margin: "5%",
  },

  form: {
    //display:'flex',
    //justifyContent:'space-between',
  },

  input: {
    margin: "0px 20px",
  },

  sbtn: {
    background: "#E81A7F",
    padding: "10px",
    border: "none",
    borderRadius: "10px",
    color: "rgb(255, 255, 255)",
    cursor: "pointer",
  },

  dbtn: {
    // background:
    // "rgba(0, 0, 0, 0) linear-gradient(90deg, rgb(58, 81, 167) 30%, rgb(181, 56, 153)) repeat scroll 0% 0%",
    padding: "10px 30px",
    border: "none",
    borderRadius: "10px",
    color: "rgb(255, 255, 255)",
    marginTop: "20px",
    cursor: "pointer",
  },
};
