import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
  faUtensils,
  faAirFreshener,
  faSeedling,
  faStreetView,
  faPlaneDeparture,
  faLeaf,
  faBirthdayCake,
  faCouch,
  faGrinStars,
  faSnowflake,
} from "@fortawesome/free-solid-svg-icons";
import DietLearning from "./learning/diet_learning_section";
import PregnencyLearning from "./learning/pregnency_learning_section";
import StayrtLearning from "./learning/stayrt_learning_section";
import RelaxationLearning from "./learning/relaxation_learning_section";
import PlanningLearning from "./learning/planning_learning_section";
import NaturalLearning from "./learning/natural_learning_section";
import DeliveryLearning from "./learning/delivery_learning_section";
import LocationLearning from "./learning/location_learning_section";
import NewbornLearning from "./learning/newborn_learning_section";
import SkillsetLearning from "./learning/skillset_learning_session";
import imag1 from "./imglern/Asset.png";
import imag2 from "./imglern/contraction.png";
import imag3 from "./imglern/lacation.png";
import imag4 from "./imglern/medical.png";
import imag5 from "./imglern/naturalcopping.png";
import imag6 from "./imglern/newborn.png";
import imag11 from "./imglern/NEwborn2.png";
import imag7 from "./imglern/planningbirth.png";
import imag8 from "./imglern/skill.png";
import imag9 from "./imglern/Relaxation.png";
import imag10 from "./imglern/Stayfit.png";
export default function LearningPortal() {
  return (
    <>
      <div className="box boxstyle" style={styles.box}>
        <Link to="/ldiet">
          <div className="innerbox" style={styles.innerbox}>
            <img src={imag1} style={styles.icon} alt="" />
            {/* <FontAwesomeIcon icon={faUtensils} style={styles.icon} /> */}
            <br />
            <span className="Titlemobile" style={styles.title}>
              Diet
            </span>
          </div>
        </Link>

        <Link to="/lstayrt">
          <div className="innerbox" style={styles.innerbox}>
            <img src={imag10} style={styles.icon} alt="" />{" "}
            {/* <FontAwesomeIcon icon={faGrinStars} style={styles.icon} /> */}
            <br />
            <span className="Titlemobile" style={styles.title}>
              Stay Fit
            </span>
          </div>
        </Link>

        <Link to="/lrelax">
          <div className="innerbox" style={styles.innerbox}>
            <img src={imag9} style={styles.icon} alt="" />{" "}
            {/* <FontAwesomeIcon icon={faCouch} style={styles.icon} /> */}
            <br />
            <span className="Titlemobile" style={styles.title}>
              Relaxation
            </span>
          </div>
        </Link>

        <Link to="/lplanning">
          <div className="innerbox" style={styles.innerbox}>
            <img src={imag7} style={styles.icon} alt="" />{" "}
            {/* <FontAwesomeIcon icon={faBirthdayCake} style={styles.icon} /> */}
            <br />
            <span className="Titlemobile" style={styles.title}>
              Planning Birth
            </span>
          </div>
        </Link>

        <Link to="/lnatural">
          <div className="innerbox" style={styles.innerbox}>
            <img src={imag5} style={styles.icon} alt="" />{" "}
            {/* <FontAwesomeIcon icon={faLeaf} style={styles.icon} /> */}
            <br />
            <span className="Titlemobile" style={styles.title}>
              Natural Coping
            </span>
          </div>
        </Link>

        <Link to="/ldelivery">
          <div className="innerbox" style={styles.innerbox}>
            <img src={imag6} style={styles.icon} alt="" />{" "}
            {/* <FontAwesomeIcon icon={faPlaneDeparture} style={styles.icon} /> */}
            <br />
            <span className="Titlemobile" style={styles.title}>
              Delivery Process
            </span>
          </div>
        </Link>

        <Link to="/llocation">
          <div className="innerbox" style={styles.innerbox}>
            <img src={imag3} style={styles.icon} alt="" />{" "}
            {/* <FontAwesomeIcon icon={faStreetView} style={styles.icon} /> */}
            <br />
            <span className="Titlemobile" style={styles.title}>
              Lactation
            </span>
          </div>
        </Link>

        <Link to="/lnewborn">
          <div className="innerbox" style={styles.innerbox}>
            <img src={imag11} style={styles.icon} alt="" />{" "}
            {/* <FontAwesomeIcon icon={faSeedling} style={styles.icon} /> */}
            <br />
            <span className="Titlemobile" style={styles.title}>
              New Born Care
            </span>
          </div>
        </Link>

        <Link to="/lskillset">
          <div className="innerbox" style={styles.innerbox}>
            <img src={imag8} style={styles.icon} alt="" />{" "}
            {/* <FontAwesomeIcon icon={faAirFreshener} style={styles.icon} /> */}
            <br />
            <span className="Titlemobile" style={styles.title}>
              Skill Set
            </span>
          </div>
        </Link>
      </div>
    </>
  );
}

const styles = {
  box: {
    overflowY: "scroll",
    scrollbarWidth: "none",
    padding: "10px",
    height: "500px",
    margin: "auto",
    width: "83%",
    // margin: "10px",
  },
  innerbox: {
    padding: "10px 0px",
    margin: "25px",
    float: "left",
    width: "140px",
    height: "118px",
    background: "#ffffff1c 0% 0% no-repeat padding-box",
    borderRadius: "10px",
  },

  icon: {
    width: "4.6rem",
    cursor: "pointer",
  },

  title: {
    display: "inline-block",
    minWidth: "100%",
    background: "#e81a7f",
    marginTop: "1.2rem",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    textTransform: "uppercase",
    fontSize: "14px",
    padding: "5px 0px",
  },

  module: {
    position: "absolute",
  },
};
