import React from "react";
import Doctorprofile from "../../doctor/components/doctorprofile";
import axios from "axios";

export default class Tablelist extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isDialog: true, list: [], docid: 0 };
    this.handleClick = this.handleClick.bind(this);
    this.updateStatus = this.updateStatus.bind(this);
  }

  handleClick(id) {
    console.log(id);
    this.setState({
      isDialog: !this.state.isDialog,
      docid: id,
    });
  }
  componentDidMount() {
    axios
      .get("http://127.0.0.1:8000/doctor-approval-requests/", {
        headers: {
          Authorization: `Token ${localStorage.getItem("admin_token")}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        this.setState({ list: res.data });
      });
  }

  updateStatus(id) {
    fetch(`http://127.0.0.1:8000/activateOrDeactivate/`, {
      body: JSON.stringify({ id: id }),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Token ${localStorage.getItem("admin_token")}`,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        alert(res.Success);
      });
  }

  render() {
    return (
      <div className="all-clients-table lightgradient2" style={styles.card}>
        <div style={styles.inner}>
          <p>
            <strong>Doctors Approval Requests</strong>
          </p>
          <hr style={{ color: "#fff" }} />

          <div className="table-cover">
            <table>
              <thead>
                <tr>
                  <td>Name</td>
                  <td>Age</td>
                  <td>Hospital</td>
                  <td>Ref ID</td>
                  <td>Experience</td>
                  <td>Profile</td>
                  <td>Approval Status</td>
                </tr>
              </thead>
              <tbody>
                {this.state.list &&
                  this.state.list
                    .filter((ele) => {
                      if (this.props.searchval) {
                        // console.log(search);
                        if (ele && ele.firstname) {
                          return `${ele.firstname} ${ele.lastname}`
                            .toLowerCase()
                            .includes(this.props.searchval.toLowerCase());
                        }
                      } else return ele;
                    })
                    .map((ele) => {
                      return (
                        <tr key={ele.id}>
                          <td>{`Dr. ${ele.firstname} ${ele.lastname}`}</td>
                          <td>{ele.age}</td>
                          <td>{ele.location}</td>
                          <td>{ele.referalId}</td>
                          <td>{ele.experience}</td>
                          <td>
                            <button onClick={() => this.handleClick(ele.id)}>
                              Details
                            </button>
                          </td>
                          <td>
                            <button
                              style={styles.approve}
                              onClick={() => this.updateStatus(ele.id)}
                            >
                              Approve
                            </button>
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </table>
          </div>
          {this.state.isDialog ? null : <Doctorprofile id={this.state.docid} />}
        </div>
      </div>
    );
  }
}

const styles = {
  card: {
    // display: "flex",
    justifyContent: "space-between",
    // backdropFilter: "blur(31px) brightness(-15%) opacity(.3)",
    padding: "20px",
    borderRadius: "10px",
    margin: "10px",
  },
  inner: {
    textDecoration: "none",
    color: "#fff",
  },

  approve: {
    background: "#f83484",
    border: "1px solid #f83484",
  },
};
