import React from "react";
import { faCommentsDollar, faSearch } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
// import Home from "./Home";
// import Sidebar2 from "./components/Sidebar";
// import Chat from "./Chat";
import img from "../img/man.jpg";
import Sidebar from "../components/sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faBars, faUser } from "@fortawesome/free-solid-svg-icons";
import Toggler from "../toggle";

import ClientProfile from "../toggle_profile";
import { CenturyView } from "react-calendar";
//import DailyTrackerTabs from './components/daily_tracker';
import Livechat from "./Livechat.js";
import Doctorlist from "./doctorchatlist";
// import Calendar from "./components/calendar";
// import Experts from "./components/experts";
// import Greet from "./components/greet";

export default class Overview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isToggleOn: true,
      isMenu: true,
      isProfile: true,
      items: [],
      list: [],
      serchinput: "",
      tablist: "doctor",
      details: [],
      recentChats: [],
      consulatnt: [],
      liveid: [],
      sales: [],
    };
    this.handleClick = this.handleClick.bind(this);
    this.setWrap = this.setWrap.bind(this);
    this.clickedOutside = this.clickedOutside.bind(this);
    this.profileHandler = this.profileHandler.bind(this);
    this.openMenu = this.openMenu.bind(this);
  }

  handleClick() {
    this.setState((prevState) => ({
      isToggleOn: !prevState.isToggleOn,
    }));
  }

  openMenu() {
    this.setState({ isMenu: !this.state.isMenu });
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.clickedOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.clickedOutside);
  }

  profileHandler() {
    this.setState({ isProfile: !this.state.isProfile });
  }

  setWrap(node) {
    this.isWap = node;
  }

  displaychatList(e) {
    if (e == "doctor") {
      this.setState({ tablist: "doctor" });
    } else if (e == "consultant") {
      this.setState({ tablist: "consultant" });
    } else if (e == "sales") {
      this.setState({ tablist: "sales" });
    } else this.setState({ tablist: "doctor" });
  }
  livechat(e) {
    this.setState({ liveid: e });
    console.log(e);
  }
  clickedOutside(e) {
    if (this.isWap && !this.isWap.contains(e.target)) {
      this.setState({ isToggleOn: true });
      this.setState({ isProfile: true });
    }
  }

  componentDidMount() {
    if (localStorage.getItem("sales") === null) {
      localStorage.clear();
      window.location = "/clogin";
    }
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("sales"),
      },
    };

    axios.get("sales/sales-dashboard-details/", config).then((res) => {
      console.log(res.data);
      this.setState({ items: res.data });
      this.setState({ details: res.data.details });
    });

    axios.get("messages/get-all-consultants/", config).then((res) => {
      // console.log(res.data);
      // this.setState({ items: res.data.customer });
      this.setState({ consulatnt: res.data.remainingChats });
    });
    axios.get("messages/get-all-sales/", config).then((res) => {
      // console.log(res.data);
      // this.setState({ items: res.data.customer });
      this.setState({ sales: res.data.remainingChats });
    });
    axios
      .get("messages/get-all-clients/", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `token ` + localStorage.getItem("sales"),
        },
      })
      .then((res) => {
        this.setState({ recentChats: res.data });
        // this.setState({ list: res.data.remainingChats });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  formatDateTime(dateTimeStr) {
    const messageDate = new Date(dateTimeStr);
    const currentDate = new Date();

    const isToday =
      messageDate.getDate() === currentDate.getDate() &&
      messageDate.getMonth() === currentDate.getMonth() &&
      messageDate.getFullYear() === currentDate.getFullYear();

    if (isToday) {
      return this.formatTime(messageDate);
    } else {
      return this.formatDate(messageDate);
    }
  }

  formatTime(date) {
    const options = { hour: "numeric", minute: "numeric", hour12: true };
    return date.toLocaleTimeString(undefined, options);
  }

  formatDate(date) {
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are 0-based
    const year = date.getFullYear().toString().slice(-2);

    return `${day}/${month}/${year}`;
  }

  render() {
    function openMenu() {
      document.querySelector(".sidebar").classList.toggle("show");
    }

    return (
      <div className="App">
        <Sidebar />
        <header className="overview ">
          <div
            className="top-header grandiant"
            ref={this.setWrap}
            style={{ padding: "2% 10%", textAlign: "right" }}
          >
            {" "}
            <div>{this.state.isToggleOn ? null : <Toggler />}</div>
            <span className="iconsofgradiant">
              <FontAwesomeIcon
                className="iconsofprofile"
                onClick={this.handleClick}
                style={{ marginRight: "1rem", cursor: "pointer" }}
                icon={faBell}
              />
            </span>
            <span className="iconsofgradiant">
              {/* {console.log("rderftwrtfwrf", this.state.details.profile_img)} */}
              {/* {this.state.details.profile_img ? (
                <img
                  style={{ cursor: "pointer" }}
                  onClick={this.profileHandler}
                  src={this.state.items.profile_img}
                  // alt="Avatar"
                  class="avatar"
                />
              ) : ( */}
              <FontAwesomeIcon
                className="iconsofprofile"
                onClick={this.profileHandler}
                style={{ cursor: "pointer" }}
                icon={faUser}
              />
              {/* )} */}
            </span>
            {this.state.items.firstname}
            {this.state.isProfile ? null : <ClientProfile />}
          </div>

          <div
            className="top-header"
            style={{ width: "89%", display: "flex", paddingLeft: "10px" }}
          >
            <span onClick={openMenu} className="menu">
              <FontAwesomeIcon
                style={{ marginRight: "20px" }}
                icon={faBars}
                className="menu-icon"
              />
            </span>
          </div>
          <div className="margin-4">
            <div className="w-100">
              <div className=" display-flex w-40">
                <label className="label6">Messages</label>{" "}
                <div className="wrapper6">
                  <FontAwesomeIcon className="icon6" icon={faSearch} />

                  <input
                    className="input6 gradiantblur"
                    onChange={(e) =>
                      this.setState({ serchinput: e.target.value })
                    }
                    type="Search"
                  />
                </div>
              </div>{" "}
              <div className="w-60"></div>
            </div>
            <div className="full">
              <div className="outterclass flex-100chat">
                <div className="chatcontats">
                  <div className="chat-header gradiantblur">
                    <h4>Inbox</h4>
                    {/* <div className="chatbuttons">
                      <button
                        className="pinkbutton"
                        onClick={() => this.displaychatList("doctor")}
                      >
                        doctor
                      </button>{" "}
                      <button
                        className="pinkbutton"
                        onClick={() => this.displaychatList("consultant")}
                      >
                        Consultant
                      </button>{" "}
                      <button
                        className="pinkbutton"
                        onClick={() => this.displaychatList("sales")}
                      >
                        sales
                      </button>
                    </div> */}
                  </div>

                  <div className="scrollerchatlist">
                    {this.state.recentChats
                      .filter((data) =>
                        data.firstname
                          .toLowerCase()
                          .includes(this.state.serchinput.toLowerCase())
                      )
                      .sort((a, b) => {
                        const timeA = new Date(a.custom_date);
                        const timeB = new Date(b.custom_date);
                        return timeB - timeA; // Reverse the comparison for descending order
                      })

                      .map((data) => (
                        <div
                          className="chat-avatar "
                          onClick={() => this.livechat(data.id)}
                        >
                          <div>
                            {data.image_url !=
                            "https://sukhprasavam.shebirth.com/media//ProfilePic/default.jpg" ? (
                              <img
                                style={{ maxWidth: "65px" }}
                                className="avatar"
                                src={data.image_url}
                                alt=""
                              />
                            ) : data.image_url ==
                              "https://sukhprasavam.shebirth.com/media//ProfilePic/default.jpg" ? (
                              <button
                                className="avatar aquamarine "
                                style={{ maxWidth: "65px", border: "none" }}
                              ></button>
                            ) : (
                              <button
                                className="avatar aquamarine "
                                style={{ maxWidth: "65px", border: "none" }}
                              ></button>
                            )}
                            {/* <img src={img} className="avatar" /> */}
                          </div>
                          <div className="alignstart">
                            {data.firstname}
                            {/* <p className="margin-0"> {data.firstname}</p> */}
                            <p className="margin-0">
                              {" "}
                              {/* &#10003; {data.firstname} */}
                            </p>
                          </div>
                          <div className="timefont">
                            {" "}
                            {this.formatDateTime(data.custom_date)}
                          </div>
                        </div>
                      ))}
                    {this.state.list
                      .filter((data) =>
                        data.firstname
                          .toLowerCase()
                          .includes(this.state.serchinput.toLowerCase())
                      )

                      .map((data) => (
                        <div
                          className="chat-avatar"
                          onClick={() => this.livechat(data.id)}
                        >
                          <div>
                            <img src={img} className="avatar" />
                          </div>
                          <div className="alignstart">
                            {data.firstname}
                            {/* <p className="margin-0"> {data.firstname}</p> */}
                            <p className="margin-0">
                              {" "}
                              {/* &#10003; {data.firstname} */}
                            </p>
                          </div>
                          <div className="timefont">12:11 </div>
                        </div>
                      ))}
                  </div>
                </div>
                <div className="livechat">
                  <Livechat id={this.state.liveid} />
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
    );
  }
}
