//import logo from './logo.svg';
import "./App.css";
import { useEffect } from "react";

import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";

//import 'bootstrap/dist/css/bootstrap.min.css';
import Navigator from "./navigator";
import { useState } from "react";
// import { messaging } from "./firebase";
// import { getToken } from "firebase/messaging";
import Axios from "axios";
// import { getToken } from firebase.js
const options = {
  timeout: 1000,
  position: positions.TOP_CENTER,
};

// inside the jsx being returned:

function App() {
  // async function requestPermission() {
  //   const permission = await Notification.requestPermission();
  //   if (permission === "granted") {
  //     // Generate Token
  //     const token = await getToken(messaging, {
  //       vapidKey:
  //         "BFMw-zuTSe1q9TewLeN6FeyWctsEM2k6TUT0BL6GQxybJZks9cxyK6nwanb9FIydtdijE15jag9uy-YvG7EwemA",
  //     });
  //     console.log("Token Gen", token);
  //     // Send this token  to server ( db)
  //   } else if (permission === "denied") {
  //     alert("You denied for the notification");
  //   }
  // }

  useEffect(() => {
    // Req user for notification permission
    // requestPermission();
  }, []);
  // Axios.defaults.baseURL = "http://139.59.80.235:8000/";
  // Axios.defaults.baseURL = "http://127.0.0.1:8000/";
  //orginal="https://sukhprasavam.shebirth.com/"

  Axios.defaults.baseURL = "https://sukhprasavam.shebirth.com/";
  return (
    <AlertProvider template={AlertTemplate} {...options}>
      <Navigator />
    </AlertProvider>
  );
}

export default App;
