import { Link } from "react-router-dom/cjs/react-router-dom.min";


export default function Braincomponent() {
  const timePeriods = [
    "One month",
    "Two months",
    "Three months",
    "Four months",
    "Five months",
    "Six months",
    "Baby Milestones",
  ];

  return (
    <div style={styles.box}>
      {timePeriods.map((period, index) => (
        <Link key={index} to={`/brainoverview/${index + 1}`}>
          <div className="innerbox" style={styles.innerbox}>
            <br />
            <span className="Titlemobile" style={styles.title}>
              {period}
            </span>
          </div>
        </Link>
      ))}
    </div>
  );


}
const styles = {
    pleft: { textAlign: "left", fontSize: "14px", margin: "auto" },
    box: {
      overflowY: "scroll",
      scrollbarWidth: "none",
      padding: "5px",
      height: "800px",
    
      width: "100%",
      display: 'flex',
      flexWrap: 'wrap',
      gap: '10px',
      margin:'auto'
    },
    innerbox: {
      padding: "10px 0px",
      margin: "35px",
      // float: "left",
      width: "250px",
      height: "100px",
      background: "#ffffff1c 0% 0% no-repeat padding-box",
      borderRadius: "10px",
      flex:'1 1 250px',
    },
  
    icon: {
      width: "3.7rem",
      cursor: "pointer",
     
    },
  
    title: {
      display: "inline-block",
      minWidth: "100%",
      background: "#e81a7f",
      marginTop: "1.2rem",
      borderBottomLeftRadius: "10px",
      borderBottomRightRadius: "10px",
      textTransform: "uppercase",
      fontSize: "14px",
      padding: "5px 0px",
    },
  
    module: {
      position: "absolute",
    },
  };
  