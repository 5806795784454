import React, { useState, useEffect } from "react";
import axios from "axios";

export default function MedicalDetail(props) {
  const [custom, setcustom] = useState([]);
  const [predefined, setpredefined] = useState([]);
  var pikerdate = props.dataFromParent;
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("token"),
      },
    };

    axios.get("customer/get-medical/?date=" + pikerdate, config).then((res) => {
      console.log(res.data);
      //     let filteredData = [];
      //     let filteredData2 = [];

      //     res.data.custom.forEach((e) => {
      //       if (e.completed === true) filteredData.push(e);
      //     });

      //     // res.data.predefined.sub_module.forEach((e) => {
      //     //   if (e.completed === true) filteredData2.push(e);
      //     // });
      //     // setsymtoms(filteredData);
      //     setcustom(filteredData);
      setpredefined(res.data.investigation_data);
      setcustom(res.data.custom_investigation);

      //     // setsymptomsWithIputs(res.data.symptomsWithIputs);
    });
  }, []);
  //   let mapped = predefined.reduce((r, a) => {
  //     r[a.date] = [...(r[a.pikerdate] || []), a];
  //     return r;
  //   }, {});

  return (
    <div className="tabview " style={{ overflow: "scroll" }}>
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr style={{ background: "#ffffff1c 0% 0% no-repeat padding-box" }}>
            <th style={styles.th}>Date</th>
            <th style={styles.th}>Hb</th>
            <th style={styles.th}>Hb normal</th>
            <th style={styles.th}>ICT</th>
            <th style={styles.th}>ICT normal</th>
            <th style={styles.th}>Urine R/E</th>
            <th style={styles.th}>Urine R/E normal</th>
            <th style={styles.th}>Urine C/S</th>
            <th style={styles.th}>Urine C/S normal</th>
            <th style={styles.th}>vdrl</th>
            <th style={styles.th}>vdrl normal</th>
            <th style={styles.th}>HIV</th>
            <th style={styles.th}>HIV normal</th>
            <th style={styles.th}>RBS first trimester</th>
            <th style={styles.th}>RBS first trimester normal</th>
            <th style={styles.th}>OGCT 2nd trimester - if raised</th>
            <th style={styles.th}>OGCT 2nd trimester - if raised normal</th>
            <th style={styles.th}>OGTT+</th>
            <th style={styles.th}>OGTT+ normal</th>
            <th style={styles.th}>hcv</th>
            <th style={styles.th}>hcv normal</th>
            <th style={styles.th}>creatine</th>
            <th style={styles.th}>creatine normal</th>
            <th style={styles.th}>double marker</th>
            <th style={styles.th}>double marker normal</th>
            <th style={styles.th}>tft </th>
            <th style={styles.th}>tft normal</th>
            <th style={styles.th}>tft description+</th>
            <th style={styles.th}>others</th>
            <th style={styles.th}>others normal</th>
            <th style={styles.th}>others description</th>
            <th style={styles.th}>scan</th>
            {custom.map((personData, index) => {
              return <th style={styles.th}>{personData.name}</th>;
            })}
          </tr>
        </thead>

        <tbody>
          {/* {Object.values(mapped).map((item, index) => (
          <div key={index}>
            <h1>{item[0].date}</h1>
            <div>
              {item.map((e) => {
                return <p key={e.criticallity}>{e.criticallity}</p>;
              })}
            </div>
          </div>
        ))} */}
          <tr>
            <td style={styles.tdFirst}>{pikerdate}</td>
            <td style={styles.td}> {predefined && predefined.hb_value}</td>
            <td style={styles.td}> {predefined && predefined.hb_normal}</td>
            <td style={styles.td}> {predefined && predefined.ict_value}</td>
            <td style={styles.td}> {predefined && predefined.ict_normal} </td>
            <td style={styles.td}> {predefined && predefined.urineRE_value}</td>
            <td style={styles.td}>
              {" "}
              {predefined && predefined.urineRE_normal}
            </td>
            <td style={styles.td}> {predefined && predefined.urineCS_value}</td>
            <td style={styles.td}>
              {predefined && predefined.urineCS_normal}{" "}
            </td>
            <td style={styles.td}> {predefined && predefined.vdrl_value}</td>
            <td style={styles.td}> {predefined && predefined.vdrl_normal}</td>
            <td style={styles.td}> {predefined && predefined.hiv_value}</td>
            <td style={styles.td}> {predefined && predefined.hiv_normal} </td>
            <td style={styles.td}>
              {" "}
              {predefined && predefined.rbs_first_trimester_value}
            </td>
            <td style={styles.td}>
              {" "}
              {predefined && predefined.rbs_first_trimester_normal}
            </td>
            <td style={styles.td}>
              {" "}
              {predefined && predefined.ogct_second_trimester_value}
            </td>
            <td style={styles.td}>
              {predefined && predefined.ogct_second_trimester_normal}{" "}
            </td>

            <td style={styles.td}>
              {" "}
              {predefined && predefined.ogtt_second_trimester_value}
            </td>
            <td style={styles.td}>
              {" "}
              {predefined && predefined.ogtt_second_trimester_normal}
            </td>
            <td style={styles.td}> {predefined && predefined.hcv_value} </td>
            <td style={styles.td}> {predefined && predefined.hcv_normal}</td>
            <td style={styles.td}>
              {" "}
              {predefined && predefined.creatine_value}
            </td>
            <td style={styles.td}>
              {" "}
              {predefined && predefined.creatine_normal}
            </td>
            <td style={styles.td}>
              {predefined && predefined.double_marker_value}{" "}
            </td>

            <td style={styles.td}>
              {" "}
              {predefined && predefined.double_marker_normal}
            </td>
            <td style={styles.td}> {predefined && predefined.tft_value}</td>
            <td style={styles.td}> {predefined && predefined.tft_normal} </td>
            <td style={styles.td}>
              {" "}
              {predefined && predefined.tft_description}
            </td>
            <td style={styles.td}> {predefined && predefined.others_value}</td>
            <td style={styles.td}> {predefined && predefined.others_normal}</td>
            <td style={styles.td}>
              {predefined && predefined.others_description}{" "}
            </td>

            <td style={styles.td}> {predefined && predefined.scan}</td>

            {custom.map((personData, index) => {
              return <td style={styles.td}> {personData.value}</td>;
            })}
          </tr>
        </tbody>
      </table>
    </div>
  );
}

const styles = {
  th: {
    fontSize: "17px",
    whiteSpace: "nowrap",
    padding: "5px 10px",
    margin: "10px 0px",
    color: "black",
  },

  td: {
    borderLeft: "1px solid #fff",
    padding: "5px 10px",
    fontSize: "12px",
    textAlign: "left",
    lineHeight: "20px",
    border: "1px solid black",
    color: "black",
  },

  tdFirst: {
    padding: "5px 10px",
    color: "black",
    fontSize: "12px",
    border: "1px solid black",
  },
};
