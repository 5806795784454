import { faSort, faSortDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import ClientProfile from "./client_profile";
import InvestigationReport from "./investigation_report";
import SearchClients from "./search";
import ClientDailyTracker from "./traker_by_date/table_list";

export default function Tablelist({
  data = [],
  handleSearch,
  searchText,
  handleSortBy,
  sortBy,
  weekOptions = {},
}) {
  const [tracker, setTracker] = useState(false);
  const [profile, setProfile] = useState(null);
  const [report, setReport] = useState(null);

  const openProfile = (customer) => () => {
    setProfile(customer);
  };

  const closeProfile = () => {
    setProfile(null);
  };

  const openDailyTracker = (customer) => () => {
    setTracker(customer);
  };

  const closeDailyTracker = () => {
    setTracker(null);
  };

  const openReporter = (customer) => () => {
    setReport(customer);
  };

  const closeReporter = () => {
    setReport(null);
  };

  const Row = (item, index) => (
    <tr key={item.id || index} style={styles.tr} className="lightgradient2">
      <td style={styles.usernav} onClick={openProfile(item.id)}>
        {/* {`${!!item.firstName ? item.firstName : ""} ${!!item.lastName ? item.lastName : ""}`.trim()} */}
        {`${!!item.firstName ? item.firstName : ""}`.trim()}
      </td>
      <td>{item.age}</td>
      <td>{item.location}</td>
      <td>{item.dueDate}</td>
      <td style={styles.docName}>
        {`${!!item.doctorFirstName ? "Dr. " + item.doctorFirstName : ""} ${
          !!item.doctorLastName ? item.doctorLastName : ""
        }`.trim()}
      </td>
      <td>
        <button onClick={openDailyTracker(item.id)}>Details</button>
      </td>
      <td>
        <button style={styles.sbtn} onClick={openReporter(item.id)}>
          Update
        </button>
      </td>
    </tr>
  );

  return (
    <>
      {report ? (
        <InvestigationReport
          customerId={report}
          closeReporter={closeReporter}
        />
      ) : (
        <div>
          {!!profile ? (
            <ClientProfile profileId={profile} closeProfile={closeProfile} />
          ) : null}

          <div>
            {tracker ? (
              <ClientDailyTracker
                customerId={tracker}
                closeDailyTracker={closeDailyTracker}
              />
            ) : null}
            <SearchClients onChange={handleSearch} value={searchText} />
            <div style={styles.sortit} className="lightgradient2">
              <span style={{ fontSize: "12px" }}>
                {" "}
                <FontAwesomeIcon icon={faSort} /> Sort By:{" "}
                <select
                  className="weekselctor"
                  value={sortBy}
                  onChange={handleSortBy}
                  style={styles.option}
                >
                  <option value="week" selected>
                    Week
                  </option>
                  {Object.keys(weekOptions).map((range) => (
                    <option value={range}>{range}</option>
                  ))}
                </select>
                {/* <FontAwesomeIcon icon={faSortDown} />{" "} */}
              </span>
            </div>
            <div className=" lightgradient2" style={styles.card}>
              <div style={styles.inner}>
                <p className="heading margin-toper">
                  <strong>Total Clients this month </strong>
                </p>
                <hr style={{ color: "#fff" }} />
                <div className="table-cover">
                  <table style={styles.table}>
                    <thead>
                      <tr>
                        <td>Name</td>
                        <td>Age</td>
                        <td>Location</td>
                        <td>Due Date</td>
                        <td>Doctor</td>
                        <td>Daily Tracker Details</td>
                        <td>Medical</td>
                      </tr>
                    </thead>
                    <tbody>{data.map(Row)}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const styles = {
  card: {
    // display: "flex",
    justifyContent: "space-between",
    // backdropFilter: "blur(31px) brightness(-15%) opacity(.3)",
    padding: "0px",
    borderRadius: "10px",
    margin: "10px",
  },
  inner: {
    textDecoration: "none",
    color: "#fff",
  },

  table: {
    borderCollapse: "seperate",
    borderSpacing: "0px 10px",
    width: "42.5rem",
  },

  tr: {
    // background: "#761F58",
  },
  sortit: {
    // background: "rgb(133, 55, 138) none repeat scroll 0% 0%",
    margin: "10px",
    borderRadius: "10px",
    textAlign: "left",
    padding: "10px",
  },
  usernav: {
    // background: "#4c005d",
    padding: "5px 10px",
    cursor: "pointer",
  },
  sbtn: {
    background: "#E81A7F",
    border: "1px solid #E81A7F",
  },
  docName: {
    paddingLeft: 12,
  },
};
