import React from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faBook,
  faCalendarCheck,
  faCreditCard,
  faSignOutAlt,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

//import ProfileDetail from './components/profile_tabs';

export default class Togprofile extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isProfile: true, items: [], profile: [], customer: [] };

    this.setWapRef = this.setWapRef.bind(this);
    this.clickedOutside = this.clickedOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.clickedOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.clickedOutside);
  }

  setWapRef(node) {
    this.wapRef = node;
  }

  clickedOutside(e) {
    if (this.wapRef && !this.wapRef.contains(e.target)) {
      this.setState({ isProfile: false });
    }
  }

  // componentDidMount() {
  //   const config = {
  //     headers: {
  //       Authorization: `token ` + localStorage.getItem("token"),
  //     },
  //   };

  //   axios
  //     .get(
  //       "https://shebirth.herokuapp.com/doctor/doctor-dashboard-details/",
  //       config
  //     )
  //     .then((res) => {
  //       // console.log(res.data);
  //       this.setState({ items: res.data });
  //     });

  //   axios
  //     .get("https://shebirth.herokuapp.com/doctor-profile/", config)
  //     .then((result) => {
  //       console.table(result.data);
  //       this.setState({ profile: result.data.details });
  //       this.setState({ customer: result.data.customer });
  //     });
  // }

  componentDidMount() {
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("postclient"),
      },
    };

    axios.get("http://127.0.0.1:8000/get-customer-detail/", config).then((result) => {
      console.table(result.data);
      this.setState({ profile: result.data.customer });
      this.setState({ customer: result.data.details });
    });
  }

  logOut = (e) => {
    e.preventDefault();
    localStorage.clear();
    window.location = "/loginpostnatal";
  };
  render() {
    return (
      <>
        {this.state.isProfile ? (
          <div ref={this.setWapRef}>
            {this.props.children}

            <ul style={style.list} className="profile">
              <li style={style.listItem}>
                <strong style={style.listItem}>
                  {this.state.profile.firstname}
                </strong>
              </li>
              <li style={style.listItem}>
                <small>
                  User Name:{" "}
                  <span style={{ marginLeft: "10px" }}>
                    {this.state.profile.firstname}
                  </span>
                </small>
                <br />
                <small>
                  Female | {this.state.customer.age}:{" "}
                  <span style={{ marginLeft: "10px" }}>
                    {this.state.profile.mobile}
                  </span>
                </small>
              </li>

              <Link to="/profileoverview">
                <li style={style.listItem}>
                  <FontAwesomeIcon
                    style={{ cursor: "pointer" }}
                    icon={faUser}
                  />{" "}
                  <strong style={{ marginLeft: "5px" }}>Manage Profile</strong>
                  <FontAwesomeIcon
                    style={{ float: "right" }}
                    icon={faChevronRight}
                  />
                </li>
              </Link>

              {/* <li style={style.listItem}>
                <FontAwesomeIcon icon={faBook} />
                <span>
                  <strong style={{ marginLeft: "5px" }}>My Orders</strong>
                </span>
                <FontAwesomeIcon
                  style={{ float: "right" }}
                  icon={faChevronRight}
                />
              </li> */}
              <Link to="/appointmentpost">
                <li style={style.listItem}>
                  <FontAwesomeIcon icon={faCalendarCheck} />
                  <span>
                    <strong style={{ marginLeft: "5px" }}>
                      My Appointments
                    </strong>
                  </span>
                  <FontAwesomeIcon
                    style={{ float: "right" }}
                    icon={faChevronRight}
                  />
                </li>
              </Link>
              {/* <Link to="/cpayment"> */}
              {/* <li style={style.listItem}>
                <FontAwesomeIcon icon={faCreditCard} />
                <span>
                  <strong style={{ marginLeft: "5px" }}>My Payments</strong>
                </span>
                <FontAwesomeIcon
                  style={{ float: "right" }}
                  icon={faChevronRight}
                />
              </li> */}
              {/* </Link> */}
              <li style={style.listItem}>
                <FontAwesomeIcon
                  icon={faSignOutAlt}
                  style={{ transform: "rotate(180deg)", color: "#00acc8" }}
                />
                <span>
                  <strong
                    onClick={this.logOut}
                    style={{
                      marginLeft: "5px",
                      cursor: "pointer",
                      color: "#00acc8",
                    }}
                  >
                    Logout
                  </strong>
                </span>
              </li>
            </ul>
          </div>
        ) : null}
      </>
    );
  }
}

const style = {
  list: {
    height: "13rem",
    borderRadius: "10px",
    boxShadow: "2px 2px 1px #333",
    fontSize: "12px",
    padding: "20px",
    height: "auto",
    background: "#ffffff1c 0% 0% no-repeat padding-box",
    // background: "linear-gradient(90deg, #b53899 60%, #3a51a7)",
    textAlign: "left",
    listStyle: "none",
    position: "absolute",
    right: "8.6%",
    top: "7%",
    width: "170px",
    zIndex: "5",
  },

  listItem: {
    padding: "10px",
    borderBottom: "1px solid #fff",
  },
};
