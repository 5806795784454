import React from 'react';
import Sidebar from './components/sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faUser } from '@fortawesome/free-solid-svg-icons';
import Toggler from './toggle';
//import Client from './components/clients';
//import Doctor from './components/doctors';
import Table from './components/doctor_table';
import Dcstatus from './components/dcstatus';
import Searchdoctor from './components/search';
import Hospitals from './components/hospitals';
import Salesteams from './components/salesteams';
import Consultants from './components/consultants';

export default class Overview extends React.Component{
constructor(props){
super(props)
this.state = {isToggleOn:true};
this.handleClick = this.handleClick.bind(this);
}


handleClick() {
	    this.setState(prevState => ({ 
	    isToggleOn: !prevState.isToggleOn   
	    }));  
	}

	
render(){	
return (
<div className="App" >
<Sidebar/>
  <header className="overview">       
       <p style={{ paddingRight:'10%', textAlign:'right' }}> <div>{ this.state.isToggleOn ? null : <Toggler/> }</div><FontAwesomeIcon onClick={ this.handleClick } style={{ marginRight:'1rem', cursor:'pointer' }} icon={faBell}/> 
       <FontAwesomeIcon style={{ cursor:'pointer'  }} icon={faUser}/> Alex</p>
       
       <div style={{ width:'89%', display:'flex', paddingLeft:'10px' }}><span style={{ whiteSpace:'nowrap' }}>Doctors</span><div style={{ padding:'4px', width:'100%' }}><hr style={{ background:'white', color:'white' }}/></div></div>      
      <div className="mwrapper">
      <div className="mleft">      
      <Searchdoctor/>
  
      <Table/>
      </div>
      <div className="mright">
      <div style={{ fontWeight:'bold', textAlign:'left', paddingLeft:'10px' }}>
      
      </div>
      <Hospitals/>
      <Salesteams/>
      <Consultants/>
      <Dcstatus/>
      </div>
      </div>
  </header>
</div>
);

}

}
