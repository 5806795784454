import React from "react";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
export default function Submodules(props) {
  var mainid = props.subid;
  const [Refresh, setRefresh] = useState(0);
  const [Items, setItems] = useState([]);
  const [close, setclose] = useState(true);
  const [ALLActivity, setAllActivity] = useState([]);
  const getActivity = (e) => {
    setAllActivity({ ...ALLActivity, [e.target.name]: e.target.value });
  };

  const submitnewActivity = (e) => {
    const Data = {
      ...ALLActivity,
      id: e,
    };
    const headers = {
      Authorization: `Token ${sessionStorage.getItem("token")}`,
    };

    console.log(e);
    axios
      .patch(
        "admin-panel/edit-activity-sub-module/",
        Data,

        {
          headers: headers,
        }
      )

      .then(() => {
        alert("success submited");
        setRefresh(Refresh + 1);
      })
      .catch((error) => {
        alert(error);
      });
  };

  const toggleactivitymain = (e) => {
    const Data = {
      id: e,
      //   // stage: "stage9",
      //   notes: notes,
    };
    const headers = {
      Authorization: `Token ${sessionStorage.getItem("token")}`,
    };

    // console.log(e);
    axios
      .patch("admin-panel/toggle-activity-sub-module/", Data, {
        headers: headers,
      })

      .then(() => {
        // setincrement([...increment,res.data]) console.log(Data);
        //console.log("submited ");
        alert("success submited");
        //console.log(Data);
        setRefresh(Refresh + 1);
        //window.location.reload();
      })
      .catch((error) => {
        alert(error);

        //console.log(Data);
        // console.log("handlesubmit error for blog ", error);
      });
  };

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (!token) {
      this.setState({ redirect: "/admin/login" });
    }

    axios
      .get(`/admin-panel/activity-sub-modules/?`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Token ${sessionStorage.getItem("token")}`,
        },
        params: {
          id: mainid,
        },
      })
      .then((res) => {
        setItems(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [mainid, Refresh]);

  return (
    <>
      {" "}
      {close ? (
        <div className="subactivitypop editform gradiantblur">
          <i
            onClick={() => setclose(!close)}
            className="fa fa-close"
            style={styles.closebtn}
          ></i>
          <table>
            <tr>
              <th>submodules</th>
              <th>edit</th>
              <th>status</th>
            </tr>
            {console.log(Items)}
            {Items.map((itm, key) => (
              <tr key={itm.id}>
                <td>
                  <input
                    type="text"
                    name="name"
                    defaultValue={itm.name}
                    onChange={(e) => getActivity(e)}
                  />
                </td>
                <td>
                  <button
                    type="button"
                    onClick={() => submitnewActivity(itm.id)}
                  >
                    edit
                  </button>
                </td>
                <td>
                  {itm.disabled == false ? (
                    <button
                      type="button"
                      className="pinkbutton"
                      onClick={() => toggleactivitymain(itm.id)}
                    >
                      Active
                    </button>
                  ) : (
                    <button
                      className="greenbutton"
                      type="button"
                      onClick={() => toggleactivitymain(itm.id)}
                    >
                      Disabled
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </table>
        </div>
      ) : null}
    </>
  );
}
const styles = {
  closebtn: {
    position: "absolute",
    right: "0px",
    top: "-20px",
    cursor: "pointer",
  },
};
