import React from "react";
import axios from "axios";

export default class AdminLoginpost extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      
      email: "",
      password: "",
      isAdmin: false,
      
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.emailChange = this.emailChange.bind(this);
    this.passChange = this.passChange.bind(this);
  }

  handleSubmit(e) {
    let currentToken; //
   
      
      


    const data={
      email:this.state.email,
      password:this.state.password,
    }

   
    axios.post('http://127.0.0.1:8000/login_admin/',data)
      .then((res) => {
        console.log(res.data);

       

        if (res.data) {
          window.localStorage.setItem("admin_token", res.data.token);
          
          window.location='/admin_overview'
        }
      })
      .catch(function (error) {
        //console.log('Show error notification!')
        return Promise.reject(error);
      });
    e.preventDefault();
  }

  emailChange(e) {
    this.setState({
      email: e.target.value,
    });
  }

  passChange(e) {
    this.setState({
      password: e.target.value,
    });
  }

  render() {
    return (
      <>
      <br></br>
      <div style={styles.box}>

        {this.state.isAdmin
          ? function () {
              window.location = "/admin";
            }
          : null}
        <div style={styles.outer}>
          <form style={styles.inner} onSubmit={this.handleSubmit}>
            <h3 style={{color:'white',textAlign:'center'}} >Postnatal</h3>
            <span style={{ color: "red" }}>
              {this.state.message ? this.state.message : null}
            </span>
            <input
              type="email"
              placeholder="Email"
              value={this.state.email}
              onChange={this.emailChange}
            />
            <br />
            <input
              type="password"
              placeholder="password"
              value={this.state.password}
              onChange={this.passChange}
            />
            <br />
            <button style={{marginTop:'10px'}} type="submit">Login</button>
          </form>
        </div>
            </div>
      </>
    );
  }
}

const styles = {


  inner: {
   padding:'20px'
  },
  box:{
    border:'2px solid black',
    width:'250px',
    margin:'auto',
    
    borderRadius:'20px',
    background: "#ffffff1c 0% 0% no-repeat padding-box",

  }
};
