import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
export default function Client() {
  const [count, setcount] = useState();
  useEffect(() => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Token ${sessionStorage.getItem("token")}`,
      },
    };

    axios
      .get("admin-panel/subscriptions-tab/", config)
      .then((res) => {
        setcount(res.data.BundleOfJoy);

        //console.log(res.data);0
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);
  return (
    <div className="clients lightgradient2" style={styles.card}>
      <div style={styles.left}>
        <p>
          <strong style={{ whiteSpace: "nowrap", fontSize: "16px" }}>
            Bundle Of Joy
          </strong>
        </p>
        {/* <button style={styles.link} className="lightgradient2">
          More <FontAwesomeIcon icon={faChevronCircleRight} />
        </button> */}
      </div>
      <div style={styles.right}>
        <h2 style={styles.counter}>{count}</h2>
      </div>
    </div>
  );
}

const styles = {
  card: {
    display: "flex",
    justifyContent: "space-between",
    // backdropFilter: "blur(31px) brightness(-15%) opacity(.3)",
    width: "20rem",
    padding: "20px",
    borderRadius: "10px",
    margin: "10px",
  },
  link: {
    fontSize: "13px",
    textDecoration: "none",
    color: "#fff",
    background: "#5a0f2c",
    padding: "10px",
    borderRadius: "10px",
    display: "block",
    width: "100px",
    border: "none",
    cursor: "pointer",
  },

  left: {
    padding: "10px",
  },
  right: {
    padding: "10px",
    borderLeft: "1px solid #fff",
  },
  counter: {
    color: "#fff",
  },
};
