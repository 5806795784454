import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";

export default function Kickcountsbutton() {
  const [kick,setkick]=useState(0)
  const [countApproval, setApprovalcount] = useState();

  // useEffect(() => {
  //   const config = {
  //     headers: {
  //       Authorization: `token ` + localStorage.getItem("doc_token"),
  //     },
  //   };

  //   axios.get("doctor/doctor-dashboard-details/", config).then((res) => {
  //     console.log(JSON.stringify(res.data));
  //     setApprovalcount(res.data.approvalRequests);
  //   });
  // }, []);

  return (
    
      
     
        <Link to="/addkick">
          <button style={styles.link} className="lightgradient">
            Add Kick <FontAwesomeIcon icon={faChevronCircleRight} />
          </button>
        </Link>
    

  );
}

const styles = {
  card: {
    display: "flex",
    flexDirection: "column",
    //background:'#4c003d',
    // backdropFilter: "blur(71px) brightness(-15%) opacity(.5)",
    width: "13.5rem",
    height: "13rem",
    padding: "5px 20px",
    borderRadius: "10px",
    marginLeft: "35%",
    lineHeight: "10px",
  },
  innerflex: {
    display: "flex",
    justifyContent: "space-between",
    height: "80px",
  },
  link: {
    fontSize: "13px",
    textDecoration: "none",
    color: "#fff",
    // background: "rgb(121 56 120 / 76%)",
    padding: "10px",
    borderRadius: "10px",
    border: "none",
    cursor: "pointer",
    marginTop: "1rem",
  },

  left: {
    padding: "0px",
  },
  right: {
    padding: "0px",
  },
  counter: {
    fontSize: "5rem",
    marginTop: "2rem",
    color: "#fff",
  },

  label: {
    margin: "auto",
    float: "right",
    fontSize: "12px",
  },
};
