import { useEffect, useState } from "react";
import { faSortDown, faSort } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ClientDailyTracker from "./traker_by_date/table_list";
import ClientProfile from "./client_profile";
import SearchClients from "./search";
import { useHistory } from "react-router-dom";
import Analsistab from "./analasis_tabs";
import axios from "axios";

export default function Tablelist({ clientDetails, searchval }) {
  const history = useHistory();
  const [tracker, setTracker] = useState(false);
  const [profile, setProfile] = useState(false);
  const [selectedWeekRange, setSelectedWeekRange] = useState(null);
  const [clientid, setClientid] = useState("");
  const [shedule, setShedule] = useState(false);
  const [cal, setcal] = useState([]);


  useEffect(() => {
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("token"),
      },
    };
    axios.get('consultant/cal-of-client', config)
      .then((re) => setcal(re.data))
  }, [])

  function openDailyTracker(id) {
    setTracker(!tracker);
    setClientid(id);
  }

  function openProfile(id) {
    setProfile(!profile);
    setClientid(id);
  }
  function openAnalasis(id) {
    setClientid(id);
    history.push(`/critical/${id}`);
    //  <Route path="/critical/:id" component={Pageanalasis} />;
    // setShedule(!shedule);
  }
  return (
    <>
      {shedule ? <Analsistab userId={clientid} /> : null}
      {profile ? <ClientProfile id={clientid} /> : null}

      <div>
        {tracker ? <ClientDailyTracker id={clientid} /> : null}
        <div className="all-clients-table" style={styles.card}>
          <div style={styles.inner}>
            <div
              className="sort-container clinetsort-container display-flex "
              style={styles.sortit}
            >
              <span style={{ fontSize: "12px" }}>
                {" "}
                <FontAwesomeIcon icon={faSort} /> Sort By:{" "}
                <select
                  className="docoptions"
                  style={styles.option}
                  onChange={(e) => setSelectedWeekRange(e.target.value)}
                >
                  <option value="0-100">Week</option>
                  <option value="0-4">0-4</option>
                  <option value="4-8">4-8</option>
                  <option value="8-12">8-12</option>
                  <option value="12-16">12-16</option>
                  <option value="16-20">16-20</option>
                  <option value="20-24">20-24</option>
                  <option value="24-28"> 24-28</option>
                  <option value="28-32">28-32</option>
                  <option value="32-36">32-36</option>
                  <option value="36-40">36-40</option>
                  <option value="40+">40+ </option>
                </select>
                {/* <FontAwesomeIcon icon={faSortDown} />{" "} */}
              </span>
            </div>
            <p>
              <strong>All Clients List</strong>
            </p>
            <hr style={{ color: "#fff" }} />
            <div className=" formobile lightgradient ">
              <table className="nospace">
                <thead>
                  <tr className="gradiantblur ">
                    <td>Name</td>
                    <td>join date</td>
                    <td>Age</td>
                    <td>calorie</td>
                    <td>Due Date</td>
                    <td>Current Week</td>
                    <td>Daily Tracker Details</td>
                    <td>Criticality</td>
                  </tr>
                </thead>
                <tbody>
                  {clientDetails &&
                    clientDetails
                      .filter((ele) => {
                        const weekMatches =
                          !selectedWeekRange ||
                          (ele.week >=
                            parseInt(selectedWeekRange.split("-")[0], 10) &&
                            ele.week <=
                            parseInt(selectedWeekRange.split("-")[1], 10));

                        if (searchval) {
                          const nameMatches =
                            ele.firstName
                              .toLowerCase()
                              .includes(searchval.toLowerCase()) ||
                            ele.lastName
                              .toLowerCase()
                              .includes(searchval.toLowerCase());
                          return weekMatches && nameMatches;
                        } else {
                          return weekMatches;
                        }
                      })
                      .map((ele) => {
                        return (
                          <tr key={ele.id} className="gradiantblur ">
                            <td
                              style={styles.usernav}
                              onClick={() => openProfile(ele.id)}
                            >
                              {" "}
                              {ele.firstName} {ele.lastName}
                            </td>
                            <td>
                              {ele.dateJoined ? (
                              
                                <span>{ele.dateJoined}</span>
                              ) : (
                            
                                <span>null</span>
                              )}
                            </td>
                            <td>{ele.age}</td>
                            <td>{cal.map((entry) => entry.find((c) => c.customer_id === ele.id)?.calorie).join(", ") || 0}</td>
                            <td>{ele.dueDate}</td>
                            <td>{ele.week}</td>
                            <td>
                              <button onClick={() => openDailyTracker(ele.id)}>
                                Details
                              </button>
                            </td>
                            <td>
                              {ele.criticality == "high risk" ? (
                                <button
                                  onClick={() => openAnalasis(ele.id)}
                                  // onClick={() => findgraph(ele.id)}
                                  style={styles.red}
                                ></button>
                              ) : ele.criticality == "stable" ? (
                                <button
                                  onClick={() => openAnalasis(ele.id)}
                                  // onClick={() => findgraph(ele.id)}
                                  style={styles.green}
                                ></button>
                              ) : (
                                <button
                                  onClick={() => openAnalasis(ele.id)}
                                  // onClick={() => findgraph(ele.id)}
                                  style={styles.yellow}
                                ></button>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const styles = {
  card: {
    // display: "flex",
    // justifyContent: "space-between",
    // background: "#4c003d",
    padding: "20px",
    borderRadius: "10px",
    margin: "10px",
  },
  inner: {
    textDecoration: "none",
    color: "#fff",
  },

  usernav: {
    // background: "#4c005d",
    // padding: "5px 10px",
    cursor: "pointer",
  },
  red: { background: "red", border: "1px solid red" },
  green: { background: "green", border: "1px solid green" },
  yellow: {
    background: "yellow",
    border: "1px solid yellow",
  },
};
