import React from "react";
import Sidebar from "../components/sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faUser, faBars } from "@fortawesome/free-solid-svg-icons";
import Toggler from "../toggle";
import SignupForm from "./newpricing";

class Overview extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isToggleOn: true };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState((prevState) => ({
      isToggleOn: !prevState.isToggleOn,
    }));
  }

  render() {
    // const { location } = this.props;
    // const id = location.pathname.split("/login").pop(); // Extract the id from the URL

    function openMenu() {
      document.querySelector(".sidebar").classList.toggle("show");
    }

    return (
      <div className="App">
        <Sidebar />
        <header className="overview">
          <div
            className="top-header grandiant"
            style={{ padding: "2% 10%", textAlign: "right" }}
          >
            <div>{this.state.isToggleOn ? null : <Toggler />}</div>
            <span className="iconsofgradiant">
              <FontAwesomeIcon
                className="iconsofprofile"
                style={{ marginRight: "1rem", cursor: "pointer" }}
                icon={faBell}
              />
            </span>
            <span className="iconsofgradiant">
              <FontAwesomeIcon
                className="iconsofprofile"
                onClick={this.profileHandler}
                style={{ cursor: "pointer" }}
                icon={faUser}
              />
            </span>
          </div>

          <div style={{ width: "89%", display: "flex", paddingLeft: "10px" }}>
            <span onClick={openMenu} className="menu">
              <FontAwesomeIcon
                style={{ marginRight: "20px" }}
                icon={faBars}
                className="menu-icon"
              />
            </span>
          </div>
          <div style={{ width: "89%" }}>
            <div className="mleft">
              <div
                className="over-cover"
                style={{ display: "flex", justifyContent: "space-around" }}
              ></div>
              <SignupForm />
            </div>
          </div>
        </header>
      </div>
    );
  }
}

export default Overview;
