import React from "react";

import axios from "axios";
import { useEffect, useState } from "react";
export default function Hospital_table(props) {
  const [stage, setstage] = useState("upcoming");
  const [list, setlist] = useState();
  const handleChange = (e) => {
    console.log(e);
    setstage(e);

    /// console.log(1);
  };

  useEffect(() => {
    axios
      .get(
        `/doctor/get-doctor-appointments/` + props.salesid,
        {
          params: {
            sort_by: stage,
          },
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Token ${localStorage.getItem("sales")}`,
          },
        }
        // {
        //   headers: {
        //     "Content-Type": "application/json",
        //     Accept: "application/json",
        //     Authorization: `Token ${sessionStorage.getItem("token")}`,
        //   },
        // }
      )
      .then((res) => {
        console.log(res.data);

        setlist(res.data.data.appointments);
      });
  }, [stage]);
  return (
    <div style={styles.card} className="lightgradient2">
      <div style={styles.inner}>
        <select
          style={styles.date}
          // onChange={(e) => onUpdateShelf(book, e.target.value)}
          // onChange={this.colorChange}
          onChange={(e) => handleChange(e.target.value)}
          // style={{
          //   background: this.state.background,
          //   border: "none",
          // }}
        >
          <option value="upcoming">Upcoming</option>
          <option value="completed">Completed</option>
        </select>
        <p>
          <strong>Appointments</strong>
        </p>
        <hr style={{ color: "#fff" }} />
        <div className="table-cover" style={{ height: "700px" }}>
          <table>
            <thead>
              <tr style={{ fontWeight: "bold" }}>
                <td style={{ fontWeight: "bold" }}>patient</td>
                <td style={{ fontWeight: "bold" }}>age</td>
                <td style={{ fontWeight: "bold" }}>date</td>
                <td style={{ fontWeight: "bold" }}>Time</td>

                <td style={{ fontWeight: "bold" }}>price</td>
                <td style={{ fontWeight: "bold" }}>payment method </td>
              </tr>
            </thead>
            <tbody>
              {/* {console.log(this.state.list)} */}
              {list &&
                list
                  .slice(0)
                  .reverse()
                  .map((ele) => {
                    // {this.state.list &&
                    //   this.state.list.map((ele) => {
                    return (
                      <tr>
                        <td>{`${ele.customer.user.firstname} ${ele.customer.user.lastname}`}</td>
                        <td>{`${ele.customer.age}`}</td>
                        <td>{`${ele.date}`}</td>
                        <td>{`${ele.time}`}</td>
                        <td>{`${ele.doctor.price}`}</td>

                        <td> null</td>
                        {/* <td>
                        <button>Notes</button>
                      </td> */}
                      </tr>
                    );
                  })}
            </tbody>
          </table>
        </div>
        {/* {this.state.isDialog ? null : <Doctorprofile />} */}
      </div>
    </div>
  );
}

const styles = {
  card: {
    // display: "flex",
    justifyContent: "space-between",
    // backdropFilter: "blur(31px) brightness(-15%) opacity(.3)",
    padding: "20px",
    borderRadius: "10px",
    margin: "10px",
  },
  inner: {
    textDecoration: "none",
    color: "#fff",
  },
  date: {
    float: "left",
    background: "#822668",
    color: "white",
    border: "none",
  },
  buttons: { background: "blue" },
};
