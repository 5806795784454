import { useState, useEffect } from "react";
import axios from "axios";
import ClientDailyTracker from "../components/traker_by_date/table_list";
import { faSortDown, faSort } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ClientProfile from "../components/client_profile";
import SearchClients from "../components/search";
import Analsistab from "../components/analasis_tabs";
import { useHistory } from "react-router-dom";
export default function AlltimeClientsTable({ searchval }) {
  const history = useHistory();
  const [clientDetails, setClientDetails] = useState([]);
  const [tracker, setTracker] = useState(false);
  const [profile, setProfile] = useState(false);
  const [shedule, setShedule] = useState(false);
  const [clientid, setClientid] = useState("");
  const [selectedWeekRange, setSelectedWeekRange] = useState("");

  function openDailyTracker(id) {
    setTracker(!tracker);
    setClientid(id);
  }
  function openProfile(id) {
    setProfile(!profile);
    setClientid(id);
  }

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Token ${localStorage.getItem("con_token")}`,
      },
    };
    // Define a function to fetch data from the API
    const data = axios.get(
      "http://127.0.0.1:8000/consultant/consultant-dashboard-details/",
      config
    ).then((res) => {setClientDetails(res.data.all_clients);
    console.log(clientDetails)
    } // Assuming the API returns an array of client details under 'all_clients'
    )




    // Call the fetch function

  }, []);
  function openAnalasis(id) {
    setClientid(id);
    history.push(`/critical/${id}`);
    //  <Route path="/critical/:id" component={Pageanalasis} />;
    // setShedule(!shedule);
  }

  const filteredClientDetails = clientDetails.filter(
    (client) =>
      client.membership !== "Trial Plan" && client.membership !== "No Plans"
  );
  return (
    <>
      {/* {shedule ? <Analsistab userId={clientid} /> : null} */}
      {profile ? <ClientProfile id={clientid} /> : null}
      {tracker ? <ClientDailyTracker id={clientid} /> : null}
      <div style={styles.card}>
        <div style={styles.inner}>

          <p>
            <strong>Paid Users</strong>
          </p>
          <hr style={{ color: "#fff" }} />
          <div className="formobile lightgradient">
            <table className="nospace">
              <thead>
                <tr className="gradiantblur">
                  <td>Name</td>
                  <td>Doctor</td>
                  <td>membership</td>


                  <td>Daily Tracker Details</td>

                </tr>
              </thead>
              <tbody>
                {filteredClientDetails
                  .filter((client) => {
                    const weekMatches =
                      !selectedWeekRange ||
                      (client.week >=
                        parseInt(selectedWeekRange.split("-")[0], 10) &&
                        client.week <=
                        parseInt(selectedWeekRange.split("-")[1], 10));

                    if (searchval) {
                      const nameMatches =
                        client.firstName
                          .toLowerCase()
                          .includes(searchval.toLowerCase()) ||
                        client.lastName
                          .toLowerCase()
                          .includes(searchval.toLowerCase());
                      return weekMatches && nameMatches;
                    } else {
                      return weekMatches;
                    }
                  })
                  .map((client) => (
                    <tr key={client.id} className="gradiantblur">
                      <td
                        style={styles.usernav}
                        onClick={() => openProfile(client.id)}
                      >
                        {client.firstName} {client.lastName}
                      </td>
                      <td>{client.doctorFirstName}</td>
                      <td>{client.membership}</td>

                      <td>
                        <button onClick={() => openDailyTracker(client.id)}>
                          Details
                        </button>
                      </td>

                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

const styles = {
  card: {
    // display: "flex",
    justifyContent: "space-between",
    // background: "#4c003d",
    padding: "20px",
    borderRadius: "10px",
    margin: "10px",
  },
  inner: {
    textDecoration: "none",
    color: "#fff",
  },

  usernav: {
    // background: "#4c005d",
    padding: "5px 10px",
    cursor: "pointer",
  },
  red: { background: "red", border: "1px solid red" },
  green: { background: "green", border: "1px solid green" },
  yellow: {
    background: "yellow",
    border: "1px solid yellow",
  },
};
