import { faBell, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  getNoUpdateClientsDetails,
  getSalesDashboardDetails,
} from "../../helpers/service";
import AlltimeClients from "../components/alltime_clients";
import TClients from "../components/clients";
import ClientNoupdates from "../components/clients_no_updates";
/*import Tlist from './components/traker_by_date/tabs';*/
import ClientsUpdateTable from "../components/clients_update_table";
import Sidebar from "../components/sidebar";
import Toggler from "../toggle";
import ClientProfile from "../toggle_profile";

// 'No Update From Client' Page
export default class Overview extends React.Component {
  constructor(props) {
    super(props);

    this.fetchData = getNoUpdateClientsDetails();
    this.fetchSalesDashboardDetails = getSalesDashboardDetails();
    this.weekOptions = {
      "0-4": {
        lower: 0,
        upper: 4,
      },
      "4-8": {
        lower: 4,
        upper: 8,
      },
      "8-12": {
        lower: 8,
        upper: 12,
      },
      "12-16": {
        lower: 12,
        upper: 16,
      },
      "16-20": {
        lower: 16,
        upper: 20,
      },
      "20-24": {
        lower: 20,
        upper: 24,
      },
      "24-28": {
        lower: 24,
        upper: 28,
      },
      "28-32": {
        lower: 28,
        upper: 32,
      },
      "32-36": {
        lower: 32,
        upper: 36,
      },
      "36-40": {
        lower: 36,
        upper: 40,
      },
      "40+": {
        lower: 40,
        upper: null,
      },
    };
    this.state = {
      isToggleOn: true,
      details: {},
      isProfile: true,
      count: {},
      searchText: "",
      sortBy: "week",
      filteredTableData: [],
    };
    this.handleClick = this.handleClick.bind(this);
    this.profileHandler = this.profileHandler.bind(this);
  }

  handleClick() {
    this.setState((prevState) => ({
      ...prevState,
      isToggleOn: !prevState.isToggleOn,
    }));
  }
  profileHandler() {
    this.setState({ isProfile: !this.state.isProfile });
  }
  handleSearch = (e) => {
    if (!!this.state.details.clients) {
      this.setState((prevState) => ({
        ...prevState,
        filteredTableData:
          this.state.sortBy === "week"
            ? this.state.details.clients.filter(
                (each) =>
                  (!!each.firstName &&
                    String(each.firstName)
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase())) ||
                  (!!each.age &&
                    String(each.age)
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase())) ||
                  (!!each.location &&
                    String(each.location)
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase())) ||
                  (!!each.dueDate &&
                    String(each.dueDate)
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase())) ||
                  (!!each.doctorFirstName &&
                    String(each.doctorFirstName)
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase())) ||
                  (!!each.doctorLastName &&
                    String(each.doctorLastName)
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase()))
              )
            : this.state.details.clients
                .filter(
                  (each) =>
                    (!!each.firstName &&
                      String(each.firstName)
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())) ||
                    (!!each.age &&
                      String(each.age)
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())) ||
                    (!!each.location &&
                      String(each.location)
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())) ||
                    (!!each.dueDate &&
                      String(each.dueDate)
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())) ||
                    (!!each.doctorFirstName &&
                      String(each.doctorFirstName)
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())) ||
                    (!!each.doctorLastName &&
                      String(each.doctorLastName)
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase()))
                )
                .filter((each) =>
                  this.weekOptions[this.state.sortBy].upper !== null
                    ? each.week >= this.weekOptions[this.state.sortBy].lower &&
                      each.week <= this.weekOptions[this.state.sortBy].upper
                    : each.week >= this.weekOptions[this.state.sortBy].lower
                ),
        searchText: e.target.value,
      }));
    }
  };

  handleSortBy = (e) => {
    if (e.target?.value === "week") {
      this.setState((prevState) => ({
        ...prevState,
        filteredTableData: this.state.details.clients.filter(
          (each) =>
            (!!each.firstName &&
              String(each.firstName)
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase())) ||
            (!!each.age &&
              String(each.age)
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase())) ||
            (!!each.location &&
              String(each.location)
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase())) ||
            (!!each.dueDate &&
              String(each.dueDate)
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase())) ||
            (!!each.doctorFirstName &&
              String(each.doctorFirstName)
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase())) ||
            (!!each.doctorLastName &&
              String(each.doctorLastName)
                .toLowerCase()
                .includes(this.state.searchText.toLowerCase()))
        ),
        sortBy: e.target?.value,
      }));

      return;
    }

    const sortedData = this.state.details.clients
      .filter(
        (each) =>
          (!!each.firstName &&
            String(each.firstName)
              .toLowerCase()
              .includes(this.state.searchText.toLowerCase())) ||
          (!!each.age &&
            String(each.age)
              .toLowerCase()
              .includes(this.state.searchText.toLowerCase())) ||
          (!!each.location &&
            String(each.location)
              .toLowerCase()
              .includes(this.state.searchText.toLowerCase())) ||
          (!!each.dueDate &&
            String(each.dueDate)
              .toLowerCase()
              .includes(this.state.searchText.toLowerCase())) ||
          (!!each.doctorFirstName &&
            String(each.doctorFirstName)
              .toLowerCase()
              .includes(this.state.searchText.toLowerCase())) ||
          (!!each.doctorLastName &&
            String(each.doctorLastName)
              .toLowerCase()
              .includes(this.state.searchText.toLowerCase()))
      )
      .filter((each) =>
        this.weekOptions[e.target?.value].upper !== null
          ? each.week >= this.weekOptions[e.target?.value].lower &&
            each.week <= this.weekOptions[e.target?.value].upper
          : each.week >= this.weekOptions[e.target?.value].lower
      );

    this.setState((prevState) => ({
      ...prevState,
      filteredTableData: sortedData,
      sortBy: e.target?.value,
    }));
  };

  componentDidMount() {
    if (!localStorage.getItem("token")) {
      localStorage.clear();
      window.location = "/clogin";
      return;
    }

    this.fetchData.api().then((response) => {
      if (response) {
        this.setState((prevState) => ({
          ...prevState,
          details: response,
          filteredTableData: response.clients,
        }));
      }
    });

    this.fetchSalesDashboardDetails.api().then((response) => {
      if (response) {
        this.setState((prevState) => ({
          ...prevState,
          count: {
            noUpdateFromClientsCount: response.noUpdateFromClientsCount,
            this_month_patients_count: response.this_month_patients_count,
            total_patients_count: response.total_patients_count,
          },
        }));
      }
    });
  }

  componentWillUnmount() {
    // Cancels the API while cleanup
    this.fetchData.cancel();
    this.fetchSalesDashboardDetails.cancel();
  }

  render() {
    return (
      <div className="App">
        <Sidebar />
        <header className="overview">
          <div
            ref={this.setWrap}
            style={{ padding: "2% 10%", textAlign: "right" }}
            className="grandiant"
          >
            {" "}
            <div>{this.state.isToggleOn ? null : <Toggler />}</div>
            <span>
              <FontAwesomeIcon
                onClick={this.handleClick}
                style={{ marginRight: "1rem", cursor: "pointer" }}
                icon={faBell}
              />
            </span>
            <span>
              <FontAwesomeIcon
                onClick={this.profileHandler}
                style={{ cursor: "pointer" }}
                icon={faUser}
              />
            </span>{" "}
            <span>
              {`${
                !!this.state.details.sales_firstname
                  ? this.state.details.sales_firstname
                  : ""
              } ${
                !!this.state.details.sales_lastname
                  ? this.state.details.sales_lastname
                  : ""
              }`.trim()}
            </span>
            {/*this.state.items.firstname} {this.props.childern*/}
            {this.state.isProfile ? null : <ClientProfile />}{" "}
          </div>

          <div style={{ width: "89%", display: "flex", paddingLeft: "10px" }}>
            <span style={{ whiteSpace: "nowrap" }}>Overview</span>
            <div style={{ padding: "4px", width: "100%" }}>
              <hr style={{ background: "white", color: "white" }} />
            </div>
          </div>
          <div className="mwrapper">
            <div className="mleft">
              <ClientsUpdateTable
                data={this.state.filteredTableData}
                handleSearch={this.handleSearch}
                handleSortBy={this.handleSortBy}
                weekOptions={this.weekOptions}
                sortBy={this.state.sortBy}
                searchText={this.state.searchText}
              />
            </div>
            <div className="mright">
              <ClientNoupdates
                count={this.state.count.noUpdateFromClientsCount}
              />
              <TClients count={this.state.count.this_month_patients_count} />
              <AlltimeClients count={this.state.count.total_patients_count} />
            </div>
          </div>
        </header>
      </div>
    );
  }
}
