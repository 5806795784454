import axios from "axios"
import { useState } from "react"
import { Fragment, useEffect } from "react"

function Kickdetail(props){
        const [data,setdata]=useState([])
    useEffect(()=>{
       
       let url='get_baby_kick_details?user='+props.id
        axios.get(url,)
        .then((e)=>{setdata(e.data)})
        
    },[])

    return <Fragment>
        <table>
            <thead>
                <th style={styles.th}>date</th>
                <th style={styles.th}>time</th>
                <th style={styles.th}>kick</th>
                
            </thead>
            <tbody>
                {data.map((val,index)=>{
                    return(

                        <tr key={index}>

                <td style={styles.td} >{val.date}</td>
                <td style={styles.td} >{val.time}</td>
                <td style={styles.td} >{val.kick_count}</td>
                    </tr>
                        )
                }
                )}
            </tbody>
        </table>
    </Fragment>
}

const styles = {
    th: {
      fontSize: "17px",
      whiteSpace: "nowrap",
      padding: "5px 10px",
      margin: "10px 0px",
      color:'#00ACC8'
    },
  
    td: {
      border: "1px solid black",
      padding: "5px 10px",
      fontSize: "12px",
      background:
        "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
      textAlign: "left",
      lineHeight: "20px",
      // height: "15rem",
    },
  
    tdFirst: {
      // padding: "5px 10px",
      border: "1px solid black",
      padding: "5px 10px",
      textAlign: "left",
      fontSize: "12px",
    },
  };
  
export default Kickdetail