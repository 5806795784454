import axios from "axios";
import React, { useState, useEffect } from "react";

export default function Greet() {
  const [data, setdata] = useState([]);

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `token ` +localStorage.getItem("postclient"),
      },
    };

    axios.get("http://127.0.0.1:8000/get-customer-details/", config).then((res) => {
      console.log(config)
      console.log(res.data);
      setdata(res.data);
    });
  }, []);

  return (
    <>
      <div
        className="greet"
        style={{ padding: "1rem 5rem", textAlign: "left", lineHeight: "10px" }}
      >
        <p>
          Hi <strong style={style.getsize}>welcome {data.first_name}</strong></p>
      </div>

      <div
        className="greet_mobile"
        style={{ padding: "1rem 5rem", textAlign: "left", lineHeight: "10px" }}
      >
        <p className="greet_p">
          welcome {data.first_name}
        </p>
      </div>
    </>
  );
}

const style = {
  getsize: {
    fontSize: "2rem",
  },
};
