export default function PersonalDetail({ data = {} }) {
  return (
    <table>
      <tr>
        <td></td>
        <td style={styles.co}>Married Since</td>
        <td>
          <input
            className="boderlessinsput"
            type="text"
            value={data.details.marriedSince}
          />
        </td>
      </tr>
      <tr>
        <td></td>
        <td style={styles.co}>How many Baby She has?</td>
        <td>
          <input
            className="boderlessinsput"
            type="text"
            value={data.details.babies_number}
          />
        </td>
      </tr>
      <tr>
        <td></td>
        <td style={styles.co}>Any History of Abortion?</td>
        <td>
          <input
            className="boderlessinsput"
            type="text"
            value={data.details.abortions}
          />
        </td>
      </tr>
      <tr>
        <td></td>
        <td style={styles.co}>Any history of twins in family?</td>
        <td>
          <input
            className="boderlessinsput"
            type="text"
            value={data.details.twins}
          />
        </td>
      </tr>
      <tr>
        <td></td>
        <td style={styles.co}>Any family history of Diabetes?</td>
        <td>
          <input
            className="boderlessinsput"
            type="text"
            value={data.details.diabetes}
          />
        </td>
      </tr>
      <tr>
        <td></td>
        <td style={styles.co}>
          Any History of allergic reaction? If any specify.
        </td>
        <td>
          <input
            className="boderlessinsput"
            type="text"
            value={data.details.allergic_reaction}
          />
        </td>
      </tr>
      <tr>
        <td></td>
        <td style={styles.co}>
          Any History of Surgery? If yes please Specify.
        </td>
        <td>
          <input
            className="boderlessinsput"
            type="Apple"
            value={data.details.surgery}
          />
        </td>
      </tr>
      <tr>
        <td></td>
        <td style={styles.co}>How many days menstrual cycle?</td>
        <td>
          <input
            className="boderlessinsput"
            type="text"
            value={data.details.Menstruation}
          />
        </td>
      </tr>
      <tr>
        <td></td>
        <td style={styles.co}>Last period Date</td>
        <td>
          <input
            className="boderlessinsput"
            type="text"
            value={data.details.Menstruation_date}
          />
        </td>
      </tr>
      <tr>
        <td></td>
        <td style={styles.co}>Any Heridity Related Complications?</td>
        <td>
          <input
            className="boderlessinsput"
            type="text"
            value={data.details.hereditory}
          />
        </td>
      </tr>
      <tr>
        <td></td>
        <td style={styles.co}>
          Any History of Gynaecology or Fertility Treatment? If so please
          Specify
        </td>
        <td>
          <input
            className="boderlessinsput"
            type="text"
            value={data.details.gynacology}
          />
        </td>
      </tr>
      <tr>
        <td></td>
        <td style={styles.co}>
          How many baby are you pregnent with currently?
        </td>
        <td>
          <input
            className="boderlessinsput"
            type="text"
            value={data.details.no_of_babies_pregnant_with}
          />
        </td>
      </tr>
      <tr>
        <td></td>
        <td style={styles.co}>When did you visit doctor finally?</td>
        <td>
          <input
            className="boderlessinsput"
            type="text"
            value={data.details.doctor_final_visit}
          />
        </td>
      </tr>
      <tr>
        <td></td>
        <td style={styles.co}>
          Upload latest prescription report or any blood report that clearly
          indicates that you are pregnent?
        </td>
        <td>
          <input
            className="boderlessinsput"
            type="text"
            value={data.details.prescription}
          />
        </td>
      </tr>
    </table>
  );
}

const styles = {
  dprofile: {
    background: "rgb(76, 0, 61) none repeat scroll 0% 0%",
    width: "41.3rem",
    borderRadius: "10px",
    padding: "10px",
  },

  titlePlate: {
    padding: "10px",
    color: "#fff",
  },
  flex: {
    display: "flex",
    justifyContent: "space-around",
    padding: "10px",
  },
  col: {
    padding: "10px",
    textAlign: "left",
  },
  co: {
    padding: "10px",
    color: "white",
    textAlign: "left",
    border: "1px solid rgb(231 22 255 / 3%)",
    // background: "#85388E",
    background:
      " 0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
  },
  money: {
    color: "#fff",
  },

  btn: {
    padding: "3px",
    background: "#85388E",
    border: "none",
  },

  fee: {
    padding: "5px",
    background: "transparent",
    outline: "1px solid #85388E",
  },

  ftext: {
    fontSize: "12px",
    padding: "5px",
    background: "#85388E",
    marginRight: "2rem",
  },
};
