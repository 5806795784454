import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import axios from "axios";

export default function AllTimePatients() {
  const [totalpatients, setTotalPatients] = useState();

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("postdoc"),
      },
    };

    axios.get("http://127.0.0.1:8000/doctor/doctor-dashboard-details/", config).then((res) => {
      console.log(JSON.stringify(res.data));
      setTotalPatients(res.data.totalClients);
    });
  });

  return (
    <div style={styles.card} className="counter-card lightgradient">
      <div className="leftbox" style={styles.left}>
        <p>Total Patients All Time</p>
        {/* <button style={styles.link}>
          More <FontAwesomeIcon icon={faChevronCircleRight} />
        </button> */}
      </div>
      <div className="minus-margin">
        <h2>{totalpatients}</h2>
      </div>
    </div>
  );
}

const styles = {
  card: {
    display: "flex",
    background:'rgba(0,0,0,.4)',
    justifyContent: "space-between",
    // backdropFilter: "blur(31px) brightness(-15%) opacity(.3)",
    width: "20rem",
    padding: "20px",
    borderRadius: "10px",
     margin: "10px",
  },
  link: {
    fontSize: "13px",
    textDecoration: "none",
    color: "#fff",
    background: "rgb(46 17 55)",
    padding: "10px",
    borderRadius: "10px",
    display: "block",
    width: "100px",
    border: "none",
    cursor: "pointer",
  },

  left: {
    margin: "auto",
  },
  right: { margin: "auto" },
  counter: {
    color: "#fff",
  },
};
