import React, { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router";
export default function MedicineTracker(props) {
  const [data, setData] = useState([]);
  const { id } = useParams();
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("doc_token"),
      },
    };
    //remove this date after setting up the admin pannel

    axios
      .get(`customer/full-medicine-details/`, {
        headers: {
          Authorization: `Token ` + sessionStorage.getItem("token"),
        },
        params: {
          customer: props.userid1,
        },
      })
      .then((res) => {
        const groupedByDate = res.data.reduce((medicines, medicine) => {
          if (medicine.date in medicines) {
            medicines[medicine.date].push(medicine);
          } else {
            medicines[medicine.date] = [medicine];
          }
          return medicines;
        }, {});

        console.log(groupedByDate);
        const sortedOnDate = Object.values(groupedByDate).sort((arr1, arr2) => {
          const ymdDate1 = arr1[0].date.split("-").reverse().join("-");
          const ymdDate2 = arr2[0].date.split("-").reverse().join("-");
          if (ymdDate1 < ymdDate2) {
            return 1;
          } else if (ymdDate1 > ymdDate2) {
            return -1;
          }
          return 0;
        });
        console.log(sortedOnDate);
        setData(sortedOnDate);
      });
  }, []);

  return (
    <div>
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead style={{ borderBottom: "5px solid #5c0048" }}>
          <tr style={{ background: "#ffffff1c 0% 0% no-repeat padding-box" }}>
            <th style={styles.th}>Date</th>
            <th style={styles.th}>
              Morning Before
              <br /> Food
            </th>
            <th style={styles.th}>
              Morning After
              <br /> Food
            </th>
            <th style={styles.th}>
              Afternoon Before
              <br /> Food
            </th>
            <th style={styles.th}>
              Afternoon After
              <br /> Food
            </th>
            <th style={styles.th}>
              Night Before
              <br /> Food
            </th>
            <th style={styles.th}>
              Night After
              <br /> Food
            </th>
          </tr>
        </thead>

        <tbody>
          {data.map((arr) => {
            return (
              <tr
                style={{
                  background: "#ffffff1c 0% 0% no-repeat padding-box",
                }}
                key={arr[0].date}
              >
                <td style={styles.tdFirst}> {arr[0].date}</td>

                <td style={styles.td}>
                  {arr.map((ele) => {
                    return (
                      <>
                        <div>
                          {ele.medicationTime == "morning before food" &&
                            ele.medicine}{" "}
                        </div>
                      </>
                    );
                  })}
                </td>
                <td style={styles.td}>
                  {arr.map((ele) => {
                    return (
                      <>
                        <div>
                          {ele.medicationTime == "morning after food" &&
                            ele.medicine}{" "}
                        </div>
                      </>
                    );
                  })}
                </td>
                <td style={styles.td}>
                  {arr.map((ele) => {
                    return (
                      <>
                        <div>
                          {ele.medicationTime == "afternoon before food" &&
                            ele.medicine}{" "}
                        </div>
                      </>
                    );
                  })}
                </td>
                <td style={styles.td}>
                  {arr.map((ele) => {
                    return (
                      <>
                        <div>
                          {ele.medicationTime == "afternoon after food" &&
                            ele.medicine}{" "}
                        </div>
                      </>
                    );
                  })}
                </td>
                <td style={styles.td}>
                  {arr.map((ele) => {
                    return (
                      <>
                        <div>
                          {ele.medicationTime == "night before food" &&
                            ele.medicine}{" "}
                        </div>
                      </>
                    );
                  })}
                </td>
                <td style={styles.td}>
                  {arr.map((ele) => {
                    return (
                      <>
                        <div>
                          {ele.medicationTime == "night after food" &&
                            ele.medicine}{" "}
                        </div>
                      </>
                    );
                  })}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

const styles = {
  th: {
    fontSize: "17px",
    whiteSpace: "nowrap",
    padding: "5px 10px",
    margin: "10px 0px",
  },

  td: {
    borderLeft: "1px solid #fff",
    padding: "5px 0px",
    fontSize: "12px",
  },

  tdFirst: {
    padding: "5px 10px",
    fontSize: "12px",
  },
};
