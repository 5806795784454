import React from 'react';

export default function ExerciseTracker(){

return (

<table style={{ width:'100%', borderCollapse:'collapse' }}>
<thead style={{ borderBottom:'5px solid #5c0048' }}>
<tr style={{ background:'#a23698' }}>
<th style={ styles.th} >Date</th>
<th style={ styles.th} >Exercises</th>
</tr>
</thead>

<tbody>
<tr style={{ background:'#a23698' }}>
<td style={styles.tdFirst}>02/08/2021</td>
<td style={styles.td}>Exercise A <br/> Exercise B</td>
</tr>


</tbody>


</table>


);

}


const styles = {

th:{
fontSize:'17px',
whiteSpace:'nowrap',
padding:'5px 10px',
margin:'10px 0px',
},

td:{
borderLeft:'1px solid #fff',
padding:'5px 0px',
fontSize:'12px'
},

tdFirst:{
padding:'5px 10px',
fontSize:'12px'
},



}






