import React, { useState } from "react";
import axios from "axios";
// import Calendar from "react-calendar";
import { useEffect } from "react";
// import ACalendar from "./calendar";
// import { useHistory } from "react-router-dom";
import moment from "moment";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
// import "../slot-tabs.css";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
export default function Reshedule(props) {
  const { id } = useParams();
  const [data, setdata] = useState();
  const [reload, setreload] = useState(0);
  const [respo, setreposnce] = useState();
  const [note, setCommentText] = useState("");
  const [notes, setnotes] = useState("");
  const [response, setresponse] = useState();
  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );

  var pikerdate = moment().format("YYYY-MM-DD");

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("sales"),
      },
    };

    axios
      .get(
        `/sales/sales-team-called/?customer_id=${id}&date=${selectedDate}`,
        config
      )
      .then((res) => {
        setresponse(res.data[0].response);
        setnotes(res.data[0].note);
        console.log(res.data[0]);
        setdata(res.data[0]);
      })
      .catch((error) => {
        console.error("Error in Axios API call:", error);
        setresponse("didn't called");

        setnotes("");
      });
  }, [reload, selectedDate]);
  const handleDateChange = (event) => {
    const selectedDateValue = event.target.value;
    setSelectedDate(selectedDateValue);
  };
  function handleNoteChange(e) {
    console.log("noted");
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("token"),
      },
    };
    const responseValue = respo !== null ? respo : "didn't called";
    // console.log(data.callResponse);
    const data2 = {
      customer: id,
      date: selectedDate,
      note: note,
      response: responseValue,
    };
    console.log(data2);
    axios
      .post("/sales/call-response/", data2, config)
      .then((res) => {
        alert("updated");

        let reloder = 1;
        reloder = reload + reloder;
        setreload(reloder);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleResponse(e) {
    setreposnce(e);
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("token"),
      },
    };

    const data2 = {
      customer: id,
      date: selectedDate,
      //   note: null,
      response: e,
    };
    console.log(data2);

    axios
      .post("/sales/call-response/", data2, config)
      .then((res) => {
        alert("updated");

        let reloder = 1;
        reloder = reload + reloder;
        setreload(reloder);
      })
      .catch((error) => {
        console.log(error);
      });
    // if (data.callResponse == undefined) {
    //   axios
    //     .post("/sales/call-response/", data2, config)
    //     .then((res) => {
    //       alert("updated");

    //       let reloder = 1;
    //       reloder = reload + reloder;
    //       setreload(reloder);
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // } else {
    //   axios
    //     .patch("/sales/call-response/", data2, config)
    //     .then((res) => {
    //       alert("updated");

    //       let reloder = 1;
    //       reloder = reload + reloder;
    //       setreload(reloder);
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // }
  }

  return (
    <>
      <div className="shedule-card  margin-auto2 " style={styles.cover}>
        <div style={styles.header}>
          <strong>Call response</strong>
        </div>

        <div className="reshedule-sepr " style={styles.sepr}>
          {" "}
          <div>
            <input
              className="pink-input"
              type="date"
              onChange={handleDateChange} // Attach the event handler to the input element
              value={selectedDate} // Bind the state value to the input's value attribute
            />{" "}
          </div>
          {/* {console.log(data && data.response)} */}
          {/* {data && data.response == undefined ? null : ( */}
          <>
            {/* {console.log(data)} */}
            <div>
              <input
                type="checkbox"
                checked={response === "didn't called"}
                onChange={() => handleResponse("2")}
              />
              Didn't Call
            </div>
            <div>
              <input
                type="checkbox"
                checked={response === "didn't respond"}
                onChange={() => handleResponse("1")}
              />
              Didn't Respond
            </div>
            <div>
              <input
                type="checkbox"
                checked={response === "called"}
                onChange={() => handleResponse("3")}
              />
              Called
            </div>
          </>
          {/* )}{" "} */}
        </div>
      </div>
      {/* {data && data.response == undefined ? null : ( */}
      <>
        <div className="shedule-card  margin-auto2" style={styles.cover}>
          <div style={styles.header}>
            <strong>Add notes</strong>
          </div>

          <div className="reshedule-sepr">
            <textarea
              className="pink-input"
              style={styles.hr}
              type="text"
              defaultValue={notes}
              onChange={(e) => setCommentText(e.target.value)}
            />
            <br />{" "}
            <button onClick={() => handleNoteChange()} className="pinkbutton">
              Add notes
            </button>{" "}
          </div>
        </div>
      </>
      {/* )} */}
    </>
  );
}

const styles = {
  header: {
    padding: "1rem",
    borderBottom: "1px solid #fff",
  },
  cover: {
    // width: "40.5rem",
    // position: "fixed",
    backdropFilter: "blur(10px)",
    backgroundColor: "rgba(255, 255, 255, 0.14)",
    // background: "#AB519A",
    zIndex: "6",
    borderRadius: "10px",
    fontSize: "16px",
    // top: "50%",
    // left: "auto",
  },

  close: {
    position: "absolute",
    top: "-25px",
    right: "-10px",
    color: "#333",
    borderRadius: "50%",
    background: "#fff",
    width: "30px",
    height: "30px",
    cursor: "pointer",
  },

  sepr: {
    display: "flex",
    height: "90px",

    margin: "75px auto",

    justifyContent: "space-around",
  },

  slot: {
    padding: "10px",
    width: "260px",
    background: "#4C003D",
    borderRadius: "10px",
    overflowX: "hidden",
    height: "260px",
  },

  sm: {
    fontSize: "12px",
    padding: "5px",
    color: "white",
    marginBottom: "5px",
    background: "#13002F",
    borderRadius: "5px",
  },

  p: {
    textAlign: "left",
    padding: "10px",
    background: "#661851",
    fontSize: "14px",
    borderRadius: "10px",
  },

  sbtn: {
    background: "#E81A7F",
    color: "#fff",
    padding: "7px 40px",
    border: "none",
    margin: " auto ",
    borderRadius: "5px",
    cursor: "pointer",
    marginBottom: "1rem",
  },
  hr: { width: "649px", height: "141px", background: "#a05a8e" },
};
