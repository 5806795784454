import { useEffect, useState } from "react";

import axios from "axios";
export default function PersonalDetail(props) {
  const [Patient, setPatient] = useState([]);
  const [Patientdetails, setPatientdetails] = useState([]);
  var userid = props.userid1;
  console.log(props.userid1);

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("doc_token"),
      },
    };

    axios
      .get("doctor/patient-details/?customer=" + userid, config)
      .then((res) => {
        // console.log(JSON.stringify(res.data));
        setPatient(res.data.customer);
        setPatientdetails(res.data.details);
      });
  }, []);

  return (
    <table>
      <tr>
        <td style={styles.co}>Married Since</td>
        <td>{Patientdetails.marriedSince}</td>
      </tr>
      <tr>
        <td style={styles.co}>How many Baby She has?</td>
        <td>{Patientdetails.babies_number}</td>
      </tr>
      <tr>
        <td style={styles.co}>Any History of Abortion?</td>
        <td>{Patientdetails.abortions}</td>
      </tr>
      <tr>
        <td style={styles.co}>Any history of twins in family?</td>
        <td>{Patientdetails.twins}</td>
      </tr>
      <tr>
        <td style={styles.co}>Any family history of Diabetes?</td>
        <td>{Patientdetails.diabetes}</td>
      </tr>
      <tr>
        <td style={styles.co}>
          Any History of allergic reaction? If any specify.
        </td>
        <td>{Patientdetails.allergic_reaction}</td>
      </tr>
      <tr>
        <td style={styles.co}>
          Any History of Surgery? If yes please Specify.
        </td>
        <td>{Patientdetails.surgery}</td>
      </tr>
      <tr>
        <td style={styles.co}>How many days menstrual cycle?</td>
        <td>{Patientdetails.Menstruation}</td>
      </tr>
      <tr>
        <td style={styles.co}>Last period Date</td>
        <td>{Patientdetails.Menstruation_date}</td>
      </tr>
      <tr>
        <td style={styles.co}>Any Heridity Related Complications?</td>
        <td>{Patientdetails.hereditory}</td>
      </tr>
      {/* <tr>
        <td style={styles.co}></td>
        <td>{Patientdetails.mobile}</td>
      </tr> */}
      <tr>
        <td style={styles.co}>
          How many baby are you pregnent with currently?
        </td>
        <td>{Patientdetails.no_of_babies_pregnant_with}</td>
      </tr>
      <tr>
        <td style={styles.co}>When did you visit doctor finally?</td>
        <td>{Patientdetails.doctor_final_visit}</td>
      </tr>
      <tr>
        <td style={styles.co}>
          Upload latest prescription report or any blood report that clearly
          indicates that you are pregnent?
        </td>
        {/* <td>{Patientdetails.mobile}</td> */}
      </tr>
    </table>
  );
}

const styles = {
  dprofile: {
    background: "rgb(76, 0, 61) none repeat scroll 0% 0%",
    width: "41.3rem",
    borderRadius: "10px",
    padding: "10px",
  },

  titlePlate: {
    padding: "10px",
    color: "#fff",
  },
  flex: {
    display: "flex",
    justifyContent: "space-around",
    padding: "10px",
  },
  col: {
    padding: "10px",
    textAlign: "left",
  },
  co: {
    width: "344px",
    padding: "10px",
    textAlign: "left",
    background:
      " 0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
  },
  money: {
    color: "#fff",
  },

  btn: {
    padding: "3px",
    background: "#85388E",
    border: "none",
  },

  fee: {
    padding: "5px",
    background: "transparent",
    outline: "1px solid #85388E",
  },

  ftext: {
    fontSize: "12px",
    padding: "5px",
    background: "#85388E",
    marginRight: "2rem",
  },
};
