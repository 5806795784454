import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

import hold from '../img/icons/Group -1.png';
import fitness from '../img/icons/Path 1647.png';
import recovery from '../img/icons/Path 1643.png';
import mommacare from '../img/icons/Group 784.png';
import mom from '../img/icons/Group 783.png'
import challenge from '../img/icons/Group 799.png';
import brest from '../img/icons/Group 782.png';
import burp from '../img/icons/Path 1481.png';
import pump from '../img/icons/Path 1537.png';
import warning from '../img/icons/Path 1538.png';
import clothing from '../img/icons/Path 1539.png';
import essentials from '../img/icons/Path 1478.png'
import product from '../img/icons/Group 794.png'
import diaper from '../img/icons/Group 770.png';
import dipaer2 from '../img/icons/Group 771.png'
import dipaer3 from '../img/icons/Group 792.png'
import daynight from '../img/icons/Group 795.png'
import notice from '../img/icons/Group 796.png'
import onemonth from '../img/icons/Group 770.png'
import twomonth from '../img/icons/Group 774.png'
import threemonth from '../img/icons/Path -1.png'
import fourmonth from '../img/icons/Path 1540.png'
import sixmonth from '../img/icons/Path 1532.png'
import milestone from '../img/icons/Group 797.png'
import liquid from '../img/icons/Group 799.png'
import solidfood from '../img/icons/Group 800.png'
import bonding from '../img/icons/Group 802.png'
import suportmother from '../img/icons/Group 803.png'

import { useState } from "react";
//import imag10 from "./imglern/Stayfit.png";
export default function LearningPortal(props) {
  console.log(props.page);
  const [toggle, settoggle] = useState(false);
  return (
    <>
      {props.page == "post delivery recovery" ? (
        <div
          className=""
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
          }}
        >
          <Link to="/postldiet">
            <div className="innerbox" style={styles.innerbox}
              onClick={()=>window.localStorage.setItem('learnit', 'baby holds')}
            >
              <img src={hold} style={styles.icon} alt="" />
              {/* <FontAwesomeIcon icon={faUtensils} style={styles.icon} /> */}
              <br />
              <span className="Titlemobile" style={styles.title}>
                Baby Holds
              </span>
            </div>
          </Link>
          <Link to="/postldiet">
            <div className="innerbox" onClick={()=>window.localStorage.setItem('learnit', 'postpartum fitness')} style={styles.innerbox}>
              <img src={fitness} style={styles.icon} alt="" />
              {/* <FontAwesomeIcon icon={faUtensils} style={styles.icon} /> */}
              <br />
              <span className="Titlemobile" style={styles.title}>
                Postpartum Fitness
              </span>
            </div>
          </Link>
          <Link to="/postldiet">
            <div className="innerbox" onClick={()=>window.localStorage.setItem('learnit', 'post partum recovery')} style={styles.innerbox}>
              <img src={recovery} style={styles.icon} alt="" />
              {/* <FontAwesomeIcon icon={faUtensils} style={styles.icon} /> */}
              <br />
              <span className="Titlemobile" style={styles.title}>
                Post Partum Recovery
              </span>
            </div>
          </Link>
          <Link to="/postldiet">
            <div className="innerbox" onClick={()=>window.localStorage.setItem('learnit', 'momma care essentials')} style={styles.innerbox}>
              <img src={mommacare} style={styles.icon} alt="" />
              {/* <FontAwesomeIcon icon={faUtensils} style={styles.icon} /> */}
              <br />
              <span className="Titlemobile" style={styles.title}>
                Momma Care Essentials
              </span>
            </div>
          </Link>
        </div>
      ) : props.page == "lactation" ? (
        <div
          className=""
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
          }}
        >
          <Link to="/postldiet">
            <div className="innerbox" onClick={()=>window.localStorage.setItem('learnit', 'positions for breastfeeding')} style={styles.innerbox}>
              <img src={hold} style={styles.icon} alt="" />
              {/* <FontAwesomeIcon icon={faUtensils} style={styles.icon} /> */}
              <br />
              <span className="Titlemobile" style={styles.title}>
                Positions For Breastfeeding
              </span>
            </div>
          </Link>
          <Link to="/postldiet">
            <div className="innerbox" onClick={()=>window.localStorage.setItem('learnit', 'moms diet during breasfeeding')} style={styles.innerbox}>
              <img src={mom} style={styles.icon} alt="" />
              {/* <FontAwesomeIcon icon={faUtensils} style={styles.icon} /> */}
              <br />
              <span className="Titlemobile" style={styles.title}>
                Moms Diet During Breastfeeding
              </span>
            </div>
          </Link>
          <Link to="/postldiet">
            <div onClick={()=>window.localStorage.setItem('learnit', 'challenges of breast feeding')} className="innerbox" style={styles.innerbox}>
              <img src={challenge} style={styles.icon} alt="" />
              {/* <FontAwesomeIcon icon={faUtensils} style={styles.icon} /> */}
              <br />
              <span className="Titlemobile" style={styles.title}>
                Challenges Of Breastfeeding
              </span>
            </div>
          </Link>
          <Link to="/postldiet">
            <div onClick={()=>window.localStorage.setItem('learnit', 'importance of breastfeeding')} className="innerbox" style={styles.innerbox}>
              <img src={brest} style={styles.icon} alt="" />
              {/* <FontAwesomeIcon icon={faUtensils} style={styles.icon} /> */}
              <br />
              <span className="Titlemobile" style={styles.title}>
                Importance Of Breastfeeding
              </span>
            </div>
          </Link>
          <Link to="/postldiet">
            <div onClick={()=>window.localStorage.setItem('learnit', 'burping and its importance')} className="innerbox" style={styles.innerbox}>
              <img src={burp} style={styles.icon} alt="" />
              {/* <FontAwesomeIcon icon={faUtensils} style={styles.icon} /> */}
              <br />
              <span className="Titlemobile" style={styles.title}>
                Burping And Its Importance
              </span>
            </div>
          </Link>
          <Link to="/postldiet">
            <div onClick={()=>window.localStorage.setItem('learnit', 'breast pump usage')} className="innerbox" style={styles.innerbox}>
              <img src={pump} style={styles.icon} alt="" />
              {/* <FontAwesomeIcon icon={faUtensils} style={styles.icon} /> */}
              <br />
              <span className="Titlemobile" style={styles.title}>
                Breast Pump Usage
              </span>
            </div>
          </Link>
        </div>
      ) : props.page == "new born" ? (
        <div
          className=""
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
          }}
        >
          <Link to="/postldiet">
            <div onClick={()=>window.localStorage.setItem('learnit', 'normal and warning signs')} className="innerbox" style={styles.innerbox}>
              <img src={warning} style={styles.icon} alt="" />
              {/* <FontAwesomeIcon icon={faUtensils} style={styles.icon} /> */}
              <br />
              <span className="Titlemobile" style={styles.title}>
                Normal And Warning Signs
              </span>
            </div>
          </Link>
          <Link to="/postldiet">
            <div onClick={()=>window.localStorage.setItem('learnit', 'new born essentials')} className="innerbox" style={styles.innerbox}>
              <img src={essentials} style={styles.icon} alt="" />
              {/* <FontAwesomeIcon icon={faUtensils} style={styles.icon} /> */}
              <br />
              <span className="Titlemobile" style={styles.title}>
                New Born Essentials
              </span>
            </div>
          </Link>
          <Link to="/postldiet">
            <div onClick={()=>window.localStorage.setItem('learnit', 'selection of baby clothing')} className="innerbox" style={styles.innerbox}>
              <img src={clothing} style={styles.icon} alt="" />
              {/* <FontAwesomeIcon icon={faUtensils} style={styles.icon} /> */}
              <br />
              <span className="Titlemobile" style={styles.title}>
                Selection Of Baby Clothing
              </span>
            </div>
          </Link>
          <Link to="/postldiet">
            <div onClick={()=>window.localStorage.setItem('learnit', 'how to select baby products')} className="innerbox" style={styles.innerbox}>
              <img src={product} style={styles.icon} alt="" />
              {/* <FontAwesomeIcon icon={faUtensils} style={styles.icon} /> */}
              <br />
              <span className="Titlemobile" style={styles.title}>
                How To Select Baby Products
              </span>
            </div>
          </Link>
        </div>
      ) : props.page == "diapering techniques" ? (
        <div
          className=""
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
          }}
        >
          <Link to="/postldiet">
            <div onClick={()=>window.localStorage.setItem('learnit', 'different types of diaper')} className="innerbox" style={styles.innerbox}>
              <img src={diaper} style={styles.icon} alt="" />
              {/* <FontAwesomeIcon icon={faUtensils} style={styles.icon} /> */}
              <br />
              <span className="Titlemobile" style={styles.title}>
                Different Types Of Diaper
              </span>
            </div>
          </Link>
          <Link to="/postldiet">
            <div onClick={()=>window.localStorage.setItem('learnit', 'how to prevent diaper rash and urinary infection')} className="innerbox" style={styles.innerbox}>
              <img src={dipaer2} style={styles.icon} alt="" />
              {/* <FontAwesomeIcon icon={faUtensils} style={styles.icon} /> */}
              <br />
              <span className="Titlemobile" style={styles.title}>
                How To Prevent Diaper Rash And Urinary Infection
              </span>
            </div>
          </Link>
          <Link to="/postldiet">
            <div onClick={()=>window.localStorage.setItem('learnit', 'make your own diaper')} className="innerbox" style={styles.innerbox}>
              <img src={dipaer3} style={styles.icon} alt="" />
              {/* <FontAwesomeIcon icon={faUtensils} style={styles.icon} /> */}
              <br />
              <span className="Titlemobile" style={styles.title}>
                Make Your Own Diaper
              </span>
            </div>
          </Link>
        </div>
      ) : props.page == "sleep pattern setting" ? (
        <div
          className=""
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
          }}
        >
          <Link to="/postldiet">
            <div className="innerbox" onClick={()=>window.localStorage.setItem('learnit', 'day night differentiation')} style={styles.innerbox}>
              <img src={daynight} style={styles.icon} alt="" />
              {/* <FontAwesomeIcon icon={faUtensils} style={styles.icon} /> */}
              <br />
              <span className="Titlemobile" style={styles.title}>
                Day Night Differentiation
              </span>
            </div>
          </Link>
          <Link to="/postldiet">
            <div onClick={()=>window.localStorage.setItem('learnit', 'things to notice')} className="innerbox" style={styles.innerbox}>
              <img src={notice} style={styles.icon} alt="" />
              {/* <FontAwesomeIcon icon={faUtensils} style={styles.icon} /> */}
              <br />
              <span className="Titlemobile" style={styles.title}>
                Things To Notice
              </span>
            </div>
          </Link>
        </div>
      ) : props.page == "baby brain stimulation" ? (
        <div
          className=""
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
          }}
        >
          <Link to="/postldiet">
            <div onClick={()=>window.localStorage.setItem('learnit', 'one month')} className="innerbox" style={styles.innerbox}>
              <img src={onemonth} style={styles.icon} alt="" />
              {/* <FontAwesomeIcon icon={faUtensils} style={styles.icon} /> */}
              <br />
              <span className="Titlemobile" style={styles.title}>
                One month
              </span>
            </div>
          </Link>
          <Link to="/postldiet">
            <div onClick={()=>window.localStorage.setItem('learnit', 'two month')} className="innerbox" style={styles.innerbox}>
              <img src={twomonth} style={styles.icon} alt="" />
              {/* <FontAwesomeIcon icon={faUtensils} style={styles.icon} /> */}
              <br />
              <span className="Titlemobile" style={styles.title}>
                Two month
              </span>
            </div>
          </Link>
          <Link to="/postldiet">
            <div onClick={()=>window.localStorage.setItem('learnit', 'three month')} className="innerbox" style={styles.innerbox}>
              <img src={threemonth} style={styles.icon} alt="" />
              {/* <FontAwesomeIcon icon={faUtensils} style={styles.icon} /> */}
              <br />
              <span className="Titlemobile" style={styles.title}>
                Three month
              </span>
            </div>
          </Link>
          <Link to="/postldiet">
            <div onClick={()=>window.localStorage.setItem('learnit', 'four month')} className="innerbox" style={styles.innerbox}>
              <img src={fourmonth} style={styles.icon} alt="" />
              {/* <FontAwesomeIcon icon={faUtensils} style={styles.icon} /> */}
              <br />
              <span className="Titlemobile" style={styles.title}>
                Four month
              </span>
            </div>
          </Link>
          <Link to="/postldiet">
            <div onClick={()=>window.localStorage.setItem('learnit', 'five month')} className="innerbox" style={styles.innerbox}>
              <img src={fourmonth} style={styles.icon} alt="" />
              {/* <FontAwesomeIcon icon={faUtensils} style={styles.icon} /> */}
              <br />
              <span className="Titlemobile" style={styles.title}>
                Five month
              </span>
            </div>
          </Link>
          <Link to="/postldiet">
            <div onClick={()=>window.localStorage.setItem('learnit', 'six month')} className="innerbox" style={styles.innerbox}>
              <img src={sixmonth} style={styles.icon} alt="" />
              {/* <FontAwesomeIcon icon={faUtensils} style={styles.icon} /> */}
              <br />
              <span className="Titlemobile" style={styles.title}>
                Six month
              </span>
            </div>
          </Link>
          <Link to="/postldiet">
            <div onClick={()=>window.localStorage.setItem('learnit', 'baby milestones')} className="innerbox" style={styles.innerbox}>
              <img src={milestone} style={styles.icon} alt="" />
              {/* <FontAwesomeIcon icon={faUtensils} style={styles.icon} /> */}
              <br />
              <span className="Titlemobile" style={styles.title}>
                Baby Milestones
              </span>
            </div>
          </Link>
        </div>
      ) : props.page == "postpartum depression" ? (
        <div
          className=""
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
          }}
        >
          <Link to="/postldiet">
            <div onClick={()=>window.localStorage.setItem('learnit', 'baby blues')} className="innerbox" style={styles.innerbox}>
              <img src={onemonth} style={styles.icon} alt="" />
              {/* <FontAwesomeIcon icon={faUtensils} style={styles.icon} /> */}
              <br />
              <span className="Titlemobile" style={styles.title}>
                Baby Blues
              </span>
            </div>
          </Link>
          <Link to="/postldiet">
            <div onClick={()=>window.localStorage.setItem('learnit', 'postpartum depression')} className="innerbox" style={styles.innerbox}>
              <img src={warning} style={styles.icon} alt="" />
              {/* <FontAwesomeIcon icon={faUtensils} style={styles.icon} /> */}
              <br />
              <span className="Titlemobile" style={styles.title}>
                Postpartum Depression
              </span>
            </div>
          </Link>
        </div>
      ) : props.page == "weaning and introduction of foods" ? (
        <div
          className=""
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
          }}
        >
          <Link to="/postldiet">
            <div onClick={()=>window.localStorage.setItem('learnit', 'introduction to liquids')} className="innerbox" style={styles.innerbox}>
              <img src={liquid} style={styles.icon} alt="" />
              {/* <FontAwesomeIcon icon={faUtensils} style={styles.icon} /> */}
              <br />
              <span className="Titlemobile" style={styles.title}>
                Introduction To Liquids
              </span>
            </div>
          </Link>
          <Link to="/postldiet">
            <div onClick={()=>window.localStorage.setItem('learnit', 'introduction to solids')} className="innerbox" style={styles.innerbox}>
              <img src={solidfood} style={styles.icon} alt="" />
              {/* <FontAwesomeIcon icon={faUtensils} style={styles.icon} /> */}
              <br />
              <span className="Titlemobile" style={styles.title}>
                Introduction To Solids
              </span>
            </div>
          </Link>
          <Link to="/postldiet">
            <div onClick={()=>window.localStorage.setItem('learnit', 'baby food recipes')} className="innerbox" style={styles.innerbox}>
              <img src={notice} style={styles.icon} alt="" />
              {/* <FontAwesomeIcon icon={faUtensils} style={styles.icon} /> */}
              <br />
              <span className="Titlemobile" style={styles.title}>
                Baby Food Recipes
              </span>
            </div>
          </Link>
        </div>
      ) : props.page == "role of a father" ? (
        <div
          className=""
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
          }}
        >
          <Link to="/postldiet">
            <div onClick={()=>window.localStorage.setItem('learnit', 'session on bonding with baby')} className="innerbox" style={styles.innerbox}>
              <img src={bonding} style={styles.icon} alt="" />
              {/* <FontAwesomeIcon icon={faUtensils} style={styles.icon} /> */}
              <br />
              <span className="Titlemobile" style={styles.title}>
                Session On Bonding With Baby
              </span>
            </div>
          </Link>
          <Link to="/postldiet">
            <div onClick={()=>window.localStorage.setItem('learnit', 'how to support the mother')} className="innerbox" style={styles.innerbox}>
              <img src={suportmother} style={styles.icon} alt="" />
              {/* <FontAwesomeIcon icon={faUtensils} style={styles.icon} /> */}
              <br />
              <span className="Titlemobile" style={styles.title}>
                How To Support The Mother
              </span>
            </div>
          </Link>
        </div>
      ) : props.page == "family planning" ? (
        <div
          className=""
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
          }}
        >
          <Link to="/postldiet">
            <div onClick={()=>window.localStorage.setItem('learnit', 'parenting')} className="innerbox" style={styles.innerbox}>
              {/* <img src={imag1} style={styles.icon} alt="" /> */}
              {/* <FontAwesomeIcon icon={faUtensils} style={styles.icon} /> */}
              <br />
              <span className="Titlemobile" style={styles.title}>
                Parenting
              </span>
            </div>
          </Link>
        </div>
      ) : null}
    </>
  );
}

const styles = {
  pleft: { textAlign: "left", fontSize: "14px", margin: "auto" },
  box: {
    overflowY: "scroll",
    scrollbarWidth: "none",
    padding: "10px",
    height: "500px",
    margin: "auto 10px",
    width: "83%",
    // margin: "10px",
  },
  innerbox: {
    padding: "10px 0px",
    margin: "35px",
    // float: "left",
    width: "250px",
    height: "100px",
    background: "#ffffff1c 0% 0% no-repeat padding-box",
    borderRadius: "10px",
  },

  icon: {
    width: "3.7rem",
    cursor: "pointer",

  },

  title: {
    display: "inline-block",
    minWidth: "100%",
    background: "#e81a7f",
    marginTop: "1.2rem",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    textTransform: "uppercase",
    fontSize: "14px",
    padding: "5px 0px",
  },

  module: {
    position: "absolute",
  },
};
