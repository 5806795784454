import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import UpdateCustomizedExercise from "./update_customized_exercise";
import ExersieTrackerUpdate from "./update_customized_exercise";

export default function ExerciseTracker(props) {
  var userid = props.userid1;
  const [data, setData] = useState([]);
  const [CaloriesData, setCaloriesData] = useState([]);
  const [update, setUpdate] = useState(false);

  function updateOpen() {
    setUpdate(true);
  }
  function openUpdater() {
    setUpdate(true);
  }
  useEffect(() => {
    // if (localStorage.getItem("token") === null) {
    //   localStorage.clear();
    //   //window.location = "/clogin";
    // }
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("doc_token"),
      },
    };
    //remove this date after setting up the admin pannel

    axios
      .get(
        "customer/get-all-dates-exercises/?customer=" + userid.id,
        config

        // config
      )
      //   .then((res) => {

      .then((res) => {
        const data = res.data.exercises.concat(res.data.calories);
        const maindata = data.concat(res.data.customExericises);

        const groupedByDate = maindata.reduce((exercises, exercise) => {
          if (exercise.date in exercises) {
            exercises[exercise.date].push(exercise);
          } else {
            exercises[exercise.date] = [exercise];
          }
          return exercises;
        }, {});

        console.log(groupedByDate);
        const sortedOnDate = Object.values(groupedByDate).sort((arr1, arr2) => {
          if (arr1[0].date < arr2[0].date) {
            return -1;
          } else if (arr1[0].date > arr2[0].date) {
            return 1;
          }
          return 0;
        });
        console.log(sortedOnDate);
        setData(sortedOnDate);
      });
  }, []);

  return (
    <>
      {update ? <ExersieTrackerUpdate userid={userid} /> : null}
      <div>
        <button style={styles.sbtn} onClick={openUpdater}>
          Update Customised Exercise
        </button>
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            borderCollapse: "seprate",
            width: "41.6rem",
            borderSpacing: "0 1em",
          }}
        >
          <thead style={{ borderBottom: "5px solid #5c0048" }}>
            <tr
              className="font-blue"
              style={{
                background:
                  " 0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11);",
              }}
            >
              <th style={styles.th}>Date</th>
              <th style={styles.th}>Exercises</th>
              <th style={styles.th}>Calories</th>
            </tr>
          </thead>

          <tbody>
            {data.map((ele) => {
              console.log(ele);
              return (
                <tr
                  style={{
                    background:
                      " 0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
                  }}
                  key={ele.id}
                >
                  <td style={styles.tdFirst}> {ele[0].date}</td>
                  <td style={styles.td}>
                    {ele.map((ele) => {
                      return <div>{ele.exercise}</div>;
                    })}
                  </td>
                  <td style={styles.td}>
                    {ele.map((ele) => {
                      return <div>{ele.value}</div>;
                    })}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}

const styles = {
  th: {
    fontSize: "17px",
    whiteSpace: "nowrap",
    padding: "5px 10px",
    margin: "10px 0px",
  },

  td: {
    background:
      "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
    borderLeft: "1px solid #fff",
    padding: "5px 0px",
    fontSize: "12px",
  },

  tdFirst: {
    padding: "5px 10px",
    fontSize: "12px",
  },

  sbtn: {
    border: "none",
    margin: "5px 0px",
    color: "#fff",
    background: "#E81A7F",
    padding: "8px",
    fontWeight: "bold",
    cursor: "pointer",
  },
};
