import React from "react";
import { withRouter } from "react-router";
import "../../App.css";
import LoginGirl from "../../privacyicon.png";
// import Form from "./form";
// import "./login.css";

export default class ClientLogin extends React.Component {
  render() {
    return (
      <>
        <div className="privacyback">
          <div style={styles.content}>
            <div class="entry-content clearfix">
              <h2>
                <strong>Privacy Policy for Sukh Prasavam</strong>
              </h2>
              <hr />

              {/* <p>Welcome to Shebirth!</p> */}

              <p>
                At Sukh Prasavam, accessible from Sukh Prasavam.com, one of our
                main priorities is the privacy of our visitors. This Privacy
                Policy document contains types of information that is collected
                and recorded by Sukh Prasavam and how we use it.
              </p>

              <p>
                If you have additional questions or require more information
                about our Privacy Policy, do not hesitate to contact us.
              </p>
              <p>
                This Privacy Policy applies only to our online activities and is
                valid for visitors to our website with regards to the
                information that they shared and/or collect in Sukh Prasavam.
                This policy is not applicable to any information collected
                offline or via channels other than this website.{" "}
              </p>

              <h3>
                <strong>Consent</strong>
              </h3>

              <p>
                By using our website, you hereby consent to our Privacy Policy
                and agree to its terms.
              </p>

              <h3>
                <strong>Changes to Privacy Policy</strong>
              </h3>

              <p>
                We may update our Privacy Policy from time to time. Thus, you
                are advised to review this page periodically for any changes. We
                will notify you of any changes by posting the new Privacy Policy
                on this page. These changes are effective immediately after they
                are posted on this page.
              </p>

              <h3>
                <strong>Information we collect</strong>
              </h3>

              <p>
                The personal information that you are asked to provide, and the
                reasons why you are asked to provide it, will be made clear to
                you at the point we ask you to provide your personal
                information.
              </p>
              <p>
                If you contact us directly, we may receive additional
                information about you such as your name, email address, phone
                number, the contents of the message and/or attachments you may
                send us, and any other information you may choose to provide.
              </p>
              <p>
                When you register for an Account, we may ask for your contact
                information, including items such as name, company name,
                address, email address, and telephone number.
              </p>
              <h3>
                <strong>How we use your information</strong>
              </h3>
              <p>
                We use the information we collect in various ways, including to:
              </p>

              <ul>
                <li>Provide, operate, and maintain our website</li>
                <li>Improve, personalize, and expand our website</li>
                <li>Understand and analyze how you use our website</li>
                <li>
                  Develop new products, services, features, and functionality
                </li>
                <li>
                  Communicate with you, either directly or through one of our
                  partners, including for customer service, to provide you with
                  updates and other information relating to the website, and for
                  marketing and promotional purposes
                </li>
                <li>Send you emails</li>
                <li>Find and prevent fraud</li>
              </ul>

              <h3>
                <strong>Log Files</strong>
              </h3>
              {/* <p>This Agreement shall begin on the date hereof.</p> */}

              <p>
                Sukh Prasavam follows a standard procedure of using log files.
                These files log visitors when they visit websites. All hosting
                companies do this and a part of hosting services’ analytics. The
                information collected by log files include internet protocol
                (IP) addresses, browser type, Internet Service Provider (ISP),
                date and time stamp, referring/exit pages, and possibly the
                number of clicks. These are not linked to any information that
                is personally identifiable. The purpose of the information is
                for analyzing trends, administering the site, tracking users’
                movement on the website, and gathering demographic information.
              </p>
              <h3>
                <strong>Cookies and Web Beacons</strong>
              </h3>
              <p>
                Like any other website, Sukh Prasavam uses ‘cookies’. These
                cookies are used to store information including visitors’
                preferences, and the pages on the website that the visitor
                accessed or visited. The information is used to optimize the
                users’ experience by customizing our web page content based on
                visitors’ browser type and/or other information.
              </p>
              <h3>
                <strong>Advertising Partners Privacy Policies</strong>
              </h3>
              <p>
                You may consult this list to find the Privacy Policy for each of
                the advertising partners of Sukh Prasavam.
              </p>
              <p>
                Third-party ad servers or ad networks uses technologies like
                cookies, JavaScript, or Web Beacons that are used in their
                respective advertisements and links that appear on Sukh
                Prasavam, which are sent directly to users’ browser. They
                automatically receive your IP address when this occurs. These
                technologies are used to measure the effectiveness of their
                advertising campaigns and/or to personalize the advertising
                content that you see on websites that you visit.{" "}
              </p>
              <p>
                Note that Sukh Prasavam has no access to or control over these
                cookies that are used by third-party advertisers.
              </p>
              <h3>
                <strong>Third Party Privacy Policies</strong>
              </h3>
              <p>
                Sukh Prasavam’s Privacy Policy does not apply to other
                advertisers or websites. Thus, we are advising you to consult
                the respective Privacy Policies of these third-party ad servers
                for more detailed information. It may include their practices
                and instructions about how to opt-out of certain options.
              </p>
              <p>
                You can choose to disable cookies through your individual
                browser options. To know more detailed information about cookie
                management with specific web browsers, it can be found at the
                browsers’ respective websites.
              </p>
              <h3>
                <strong>GDPR Data Protection Rights</strong>
              </h3>
              <p>
                We would like to make sure you are fully aware of all of your
                data protection rights. Every user is entitled to the following:
              </p>
              <p>
                The right to access – You have the right to request copies of
                your personal data. We may charge you a small fee for this
                service.
              </p>
              <p>
                The right to rectification – You have the right to request that
                we correct any information you believe is inaccurate. You also
                have the right to request that we complete the information you
                believe is incomplete.
              </p>
              <p>
                The right to erasure – You have the right to request that we
                erase your personal data, under certain conditions.{" "}
              </p>
              <p>
                The right to restrict processing – You have the right to request
                that we restrict the processing of your personal data, under
                certain conditions.
              </p>
              <p>
                The right to object to processing – You have the right to object
                to our processing of your personal data, under certain
                conditions.
              </p>
              <p>
                The right to data portability – You have the right to request
                that we transfer the data that we have collected to another
                organization, or directly to you, under certain conditions.
              </p>
              <p>
                If you make a request, we have one month to respond to you. If
                you would like to exercise any of these rights, please contact
                us.
              </p>

              <h3>
                <strong>Children’s Information</strong>
              </h3>
              <p>Sukh Prasavam is not for children below the age of 13.</p>
              <h3>
                <strong>Contact Us</strong>
              </h3>
              <p>
                If you have any questions or suggestions about our Privacy
                Policy, do not hesitate to contact us at
                smartbirth@shebirth.com.
              </p>
            </div>
          </div>

          <div
            className="loginimage"
            style={{ float: "right", color: "#fff", margin: " 100px auto" }}
          >
            <img
              src={LoginGirl}
              alt=""
              style={styles.imagep}
              className="imgp"
            />
          </div>
        </div>
      </>
    );
  }
}

const styles = {
  content: {
    width: "70%",
    color: "white",
    // textAlign: "center",
  },

  imagep: { width: "100%" },
  flex: {
    display: "flex",
    justifyContent: "space-between",
    width: "70%",
    margin: "auto",
  },
};
