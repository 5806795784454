import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faThLarge,
  faBriefcaseMedical,
  faUser,
  faSms,
} from "@fortawesome/free-solid-svg-icons";
import { Link, NavLink } from "react-router-dom";
import Logo from "../../logo.png";

export default class Sidebar extends React.Component {
  render() {
    function hideBar() {
      var sidebar = document.querySelector(".sidebar");
      sidebar.classList.remove("show");
      sidebar.classList.add("hide");
    }
    return (
      <div className="sidebar lightgradient2" style={styles.header}>
        <a class="close-icon" href="#" onClick={hideBar}>
          &#10006;
        </a>
        <Link to="/login">
          <img src={Logo} alt="" className="mainlogo" />
        </Link>
        <button className="backButton" onClick={() => window.history.back()}>
          Back
        </button>
        <ul style={styles.lists}>
          <NavLink to="/consultant" activeClassName='active3'>
            <li style={styles.listItemActive}>
              <div>
                <FontAwesomeIcon
                  icon={faThLarge}
                  style={{ marginRight: "1rem" }}
                />
              </div>
              <div> Overview</div>
            </li>
          </NavLink>
          <NavLink to="/ctmessages" activeClassName="active3">
            {/* <Link to="/consultant/messages"> */}
            <li style={styles.listItemActive}>
              <div>
                <FontAwesomeIcon icon={faSms} style={{ marginRight: "1rem" }} />
              </div>
              <div>Messages</div>
            </li>
          </NavLink>
          <NavLink to="/paid_users" activeClassName="active3">
            {/* <Link to="/consultant/messages"> */}
            <li style={styles.listItemActive}>
              <div>
                <FontAwesomeIcon
                  icon={faUser}
                  style={{ marginRight: "1rem" }}
                />
              </div>
              <div>Paid Users</div>
            </li>
          </NavLink>
        </ul>
        <div
          style={{
            position: "absolute",
            bottom: "3rem",
            left: "30px",
            width: "15%",
          }}
        >
          <div style={{ marginTop: "3rem" }}>
            {/* <button style={styles.setting}>
              <FontAwesomeIcon icon={faCog} style={{ marginRight: "1rem" }} />
              Settings
            </button> */}
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  header: {
    // background: "linear-gradient(25deg, #3a51a7, #7f1f54)",
    padding: "2rem",
    minHeight: "100%",
    width: "20%",
    margin: "0",
    color: "white",
  },
  lists: {
    marginTop: "5rem",
    color: "#fff",
    paddingLeft: "0",
    listStyle: "none",
  },
  listItem: {
    background: "transparent",
    padding: "10px 50px",
    borderRadius: "10px",
    cursor: "pointer",
    display: "flex",
  },
  listItemActive: {
    //background: "#6a0f4b",
    padding: "10px 50px",
    borderRadius: "10px",
    cursor: "pointer",
    display: "flex",
  },

  setting: {
    background: "transparent",
    border: "none",
    color: "#fff",
    textDecoration: "none",
    cursor: "pointer",
  },
};
