import React from "react";
import { faCommentsDollar, faSearch } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
// import Home from "./Home";
// import Sidebar2 from "./components/Sidebar";
// import Chat from "./Chat";
import img from "../img/man.jpg";
import Sidebar from "../components/sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faBars, faUser } from "@fortawesome/free-solid-svg-icons";
import Toggler from "../toggle";

import Intailflow from "./inintailflow";
import { CenturyView } from "react-calendar";
//import DailyTrackerTabs from './components/daily_tracker';
//import Livechat from "./Livechat.js";
//import Doctorlist from "./doctorchatlist";
// import Calendar from "./components/calendar";
// import Experts from "./components/experts";
// import Greet from "./components/greet";

export default class Overview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isToggleOn: true,
      isMenu: true,
      isProfile: true,
      items: [],
      list: [],
      serchinput: "",
      tablist: "doctor",
      details: [],
      recentChats: [],
      consulatnt: [],
      liveid: [],
      sales: [],
    };
    this.handleClick = this.handleClick.bind(this);
    this.setWrap = this.setWrap.bind(this);
    this.clickedOutside = this.clickedOutside.bind(this);
    this.profileHandler = this.profileHandler.bind(this);
    this.openMenu = this.openMenu.bind(this);
  }

  handleClick() {
    this.setState((prevState) => ({
      isToggleOn: !prevState.isToggleOn,
    }));
  }

  openMenu() {
    this.setState({ isMenu: !this.state.isMenu });
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.clickedOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.clickedOutside);
  }

  profileHandler() {
    this.setState({ isProfile: !this.state.isProfile });
  }

  setWrap(node) {
    this.isWap = node;
  }

  displaychatList(e) {
    if (e == "doctor") {
      this.setState({ tablist: "doctor" });
    } else if (e == "consultant") {
      this.setState({ tablist: "consultant" });
    } else if (e == "sales") {
      this.setState({ tablist: "sales" });
    } else this.setState({ tablist: "doctor" });
  }
  livechat(e) {
    this.setState({ liveid: e });
    console.log(e);
  }
  clickedOutside(e) {
    if (this.isWap && !this.isWap.contains(e.target)) {
      this.setState({ isToggleOn: true });
      this.setState({ isProfile: true });
    }
  }

  componentDidMount() {
    if (localStorage.getItem("sales") === null) {
      localStorage.clear();
      window.location = "/clogin";
    }
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("sales"),
      },
    };

    axios.get("sales/sales-dashboard-details/", config).then((res) => {
      this.setState({ items: res.data });
    });
  }

  render() {
    function openMenu() {
      document.querySelector(".sidebar").classList.toggle("show");
    }

    return (
      <div className="App">
        <Sidebar />
        <header className="overview ">
          <div
            className="top-header grandiant"
            ref={this.setWrap}
            style={{ padding: "2% 10%", textAlign: "right" }}
          >
            {" "}
            <div>{this.state.isToggleOn ? null : <Toggler />}</div>
            <span className="iconsofgradiant">
              <FontAwesomeIcon
                className="iconsofprofile"
                // onClick={this.handleClick}
                style={{ marginRight: "1rem", cursor: "pointer" }}
                icon={faBell}
              />
            </span>
            <span className="iconsofgradiant">
              {console.log("rderftwrtfwrf", this.state.details.profile_img)}
              {/* {this.state.details.profile_img ? (
                <img
                  style={{ cursor: "pointer" }}
                  onClick={this.profileHandler}
                  src={this.state.items.profile_img}
                  // alt="Avatar"
                  class="avatar"
                />
              ) : ( */}
              <FontAwesomeIcon
                className="iconsofprofile"
                onClick={this.profileHandler}
                style={{ cursor: "pointer" }}
                icon={faUser}
              />
              {/* )} */}
            </span>
            {this.state.items.firstname} {this.props.childern}
            {/* {this.state.isProfile ? null : <ClientProfile />}{" "} */}
          </div>

          <div
            className="top-header"
            style={{ width: "89%", display: "flex", paddingLeft: "10px" }}
          >
            <span onClick={openMenu} className="menu">
              <FontAwesomeIcon
                style={{ marginRight: "20px" }}
                icon={faBars}
                className="menu-icon"
              />
            </span>
          </div>
          <Intailflow />
        </header>
      </div>
    );
  }
}
