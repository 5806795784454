import React from "react";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
export default function Banner() {
  const [ALLActivity, setAllActivity] = useState();
  const [SelectedFile, setSelectedFile] = useState();

  const handleChange = (e) => {
    setAllActivity({ ...ALLActivity, [e.target.name]: e.target.value });
  };

  ///

  console.log(ALLActivity);

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    // setIsSelected(true);
  };
  const submitVideo = (e) => {
    axios({
      method: "POST",
      url: "http://127.0.0.1:8000/video/",

      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Token ${localStorage.getItem("admin_token")}`,
      },
      //digiatl
      // headers: { "Api-Key": `aU60ViOC.02hdAGZ0gy4avx0oBCkhErSJK55anMuC` },
      //heroku
      // headers: { "Api-Key": `g7eWwFm9.va3HxJiPD1OzMs5I1UntS2gAwXj99Kuq` },
      data: ALLActivity,
    })
      .then((res) => {
        alert(res.data.message);
        // window.location = "/clogin";
      })
      .catch((error) => {
        //var my_obj_str = JSON.stringify(error.response.data);
        alert(JSON.stringify(error.response.data));
      });

    // https://sukhprasavam.shebirth.com//banner/
    // Traker;
  };

  const submitValue = (e) => {
    const formData = new FormData();
    // console.log(SelectedFile);
    formData.append("image", SelectedFile);
    // formData.append("profile_img", SelectedFile);
    console.log(formData.get("images"));
    console.log(formData);
    axios({
      method: "POST",
      url: "http://127.0.0.1:8000/banner/",

      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Token ${localStorage.getItem("admin_token")}`,
      },
      //digiatl
      // headers: { "Api-Key": `aU60ViOC.02hdAGZ0gy4avx0oBCkhErSJK55anMuC` },
      //heroku
      // headers: { "Api-Key": `g7eWwFm9.va3HxJiPD1OzMs5I1UntS2gAwXj99Kuq` },
      data: formData,
    })
      .then((res) => {
        alert(res.data.message);
        // window.location = "/clogin";
      })
      .catch((error) => {
        //var my_obj_str = JSON.stringify(error.response.data);
        alert(JSON.stringify(error.response.data));
      });

    // https://sukhprasavam.shebirth.com//banner/
    // Traker;
  };
  return (
    <div>
      <div style={styles.card} className="editform gradiantblur">
        <div style={styles.inner}>
          <table className="learning-table-form">
            {/* <tr>
              <td style={styles.td}>Select Dashbord</td>
              <td>
                <select
                  onChange={(e) => handleTraker(e.target.value)}
                  required
                  style={styles.select}
                >
                  <option style={styles.option} value="antenatal">
                    antenatal
                  </option>
                  <option style={styles.option} value="Dads">
                    Dads
                  </option>
                </select>
              </td>
            </tr> */}
            <tr>
              <td style={styles.td}>Update banner</td>
              <td style={styles.td}>
                <input
                  type="file"
                  // accept="image/*"
                  onChange={changeHandler}
                  style={{ display: "none" }}
                  id="contained-button-file"
                />
                <label htmlFor="contained-button-file">
                  <Button
                    variant="contained"
                    className="mibutton"
                    style={styles.mibutton}
                    component="span"
                  >
                    Select file
                  </Button>
                </label>
                <br />
                <br />
                <button className="pinkbutton2" onClick={submitValue}>
                  {" "}
                  upload
                </button>
              </td>
              <td>
                <Link
                  to={{
                    pathname: "/viewbanners/",
                  }}
                  // onClick={OpenInner}
                >
                  {" "}
                  <button className="pinkbutton">View all banners</button>
                </Link>
              </td>
            </tr>

            <tr>
              <td style={styles.td}>Update Video</td>
              <td style={styles.td}>
                {/* <input
               
                  onChange={changeHandler}
                  type="file"
                  accept="video/*"
                  style={{ display: "none" }}
                  id="contained2-button-file"
                /> */}
                {/* <label htmlFor="contained2-button-file"> */}
                {/* <Button
                    variant="contained2"
                    style={styles.mibutton}
                    className="mibutton"
                    component="span"
                  >
                    Select file
                  </Button> */}
                <input
                  type="text"
                  name="link"
                  placeholder="Enter link"
                  onChange={(e) => handleChange(e)}
                  // onChange={(e) => handleChange(e.target.value)}
                />
                <input
                  type="text"
                  name="text"
                  placeholder="Enter text"
                  onChange={(e) => handleChange(e)}
                  // onChange={(e) => handleChange(e.target.value)}
                />
                {/* </label> */}
                <br />
                <br />

                <button className="pinkbutton2" onClick={submitVideo}>
                  {" "}
                  upload
                </button>
              </td>
              <td>
                <Link
                  to={{
                    pathname: "/viewvideo/",
                  }}
                  // onClick={OpenInner}
                >
                  {" "}
                  <button className="pinkbutton">View all videos</button>
                </Link>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  );
}
const styles = {
  mibutton: {
    background: "#ffffff1c 0% 0% no-repeat padding-box",
    color: "white",
    textTransform: "inherit",

    borderRadius: "16px",
  },
  card: {
    display: "flex",
    justifyContent: "space-between",
    background: "#ffffff1c 0% 0% no-repeat padding-box",
    padding: "20px",
    borderRadius: "10px",
    margin: "10px",
  },
  inner: {
    textDecoration: "none",
    color: "#fff",
  },
  invisble2: { background: "none", border: "1px solid black" },

  input: {
    width: "40rem",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderBottomColor: "1px solid #fff",
    background: "transparent",
    padding: "8px",
    marginLeft: "30px",
    outline: "none",
    color: "white",
  },
};
