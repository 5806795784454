import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import DietTracker from "../img/balance_diet.png";
import CustomizedDiet from "./innercom/customized_diet";
import axios from "axios";
export default class Clist extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isDisplay: true, items: [] };
    this.clickHandler = this.clickHandler.bind(this);
  }

  componentDidMount() {
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("token"),
      },
    };

    axios.get("customer/diet-tracker-GET/", config).then((res) => {
      this.setState({ items: res.data.CustomizedDiet });
    });
  }

  clickHandler() {
    this.setState({ isDisplay: !this.state.isDisplay });
  }

  render() {
    return (
      <>
        {this.state.isDisplay ? (
          <div className="cardoftraker lightgradient" style={styles.card}>
            <div style={styles.inner}>
              <Tabs>
                <TabList
                  style={{
                    width: "100%",
                    listStyle: "none",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Tab>
                    <strong style={{ padding: "10px", cursor: "pointer" }}>
                      Diet Tracker
                    </strong>
                  </Tab>
                  <Tab>
                    <strong style={{ padding: "10px", cursor: "pointer" }}>
                      Customized Diet
                    </strong>
                  </Tab>
                </TabList>

                <TabPanel>
                  <CustomizedDiet/>
                </TabPanel>

                <TabPanel>
                  <div style={{ margin: "auto", height: "500px" }}>
                    {" "}
                    <a
                      href={this.state.items.url}
                      style={{
                        position: "relative",
                        top: "50%",
                        fontSize: "18px",
                      }}
                    >
                      {" "}
                      {this.state.items.url}
                    </a>
                  </div>

                  {/* {this.state.items.url ? (
                    <img
                      src={this.state.items.url}
                      alt="diet image"
                      style={{ width: "80%" }}
                    />
                  ) : (
                    <img src={DietTracker} alt="diet image" />
                  )} */}
                </TabPanel>
              </Tabs>
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

const styles = {
  card: {
    height: "550px",
    overflowY: "scroll",
    scrollbarWidth: "none",
    display: "flex",
    justifyContent: "space-between",
    //background: "#4c003d",
    borderRadius: "10px",
    margin: "0px 30px",
  },

  inner: {
    textDecoration: "none",
    color: "#fff",
    width: "100%",
  },

  cdiet: {
    height: "18rem",
    overflow: "auto",
    // scrollbarWidth: "none",
    boxSizing: "content-box",
  },
  subbtn: {
    background: "linear-gradient(90deg, #3a51a7 30%, #b53899)",
    padding: "10px 30px",
    border: "none",
    borderRadius: "10px",
    color: "#fff",
    cursor: "pointer",
  },
};
