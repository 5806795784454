import axios from "axios";
export const baseUrl = "https://sukhprasavam.shebirth.com";
//
// export const baseUrl = "http://139.59.80.235:8000";
export const SheBirthService = new ApiService({
  baseURL: baseUrl,
});

export function ApiService(options) {
  const { onSuccess, onError, ...otherOptions } = options;

  this.onError = (customErrorCallback) => (error) => {
    if (customErrorCallback) {
      customErrorCallback(error);
    }
  };

  this.onSuccess = (customSuccessCallback) => (response) => {
    if (customSuccessCallback) {
      customSuccessCallback(response);
    }

    return response;
  };

  this.baseUrl = otherOptions.baseUrl;

  this.service = axios.create(otherOptions);

  // data, params will be submitted while invoking the returned function
  const makeRequest =
    (method = "GET", url, options = {}) =>
    async (body = {}) => {
      const {
        transformResponse = [],
        onSuccess = () => {},
        onError = (error = {}) => {
          console.log("ERROR", JSON.stringify(error?.response));
        },
        // Whether to hit API with token. Default is true
        checkToken = true,
        headers,
        cancelToken = this.getCancelInstance().token,
        ...config
      } = options;

      const { data, params, headers: customHeaders } = body;

      let response;

      try {
        switch (method.toUpperCase()) {
          case "GET":
            response = await this.service.get(url, {
              ...config,
              headers: {
                ...headers,
                ...customHeaders,
                ...(checkToken ? getAuthHeaderWithToken() : {}),
              },
              params,
              cancelToken: cancelToken,
            });

            break;

          case "POST":
            response = await this.service.post(url, data, {
              ...config,
              headers: {
                ...headers,
                ...customHeaders,
                ...(checkToken ? getAuthHeaderWithToken() : {}),
              },
              cancelToken: cancelToken,
              params,
            });

            break;

          case "PUT":
            response = await this.service.put(url, data, {
              ...config,
              headers: {
                ...headers,
                ...customHeaders,
                ...(checkToken ? getAuthHeaderWithToken() : {}),
              },
              cancelToken: cancelToken,
              params,
            });

            break;

          case "PATCH":
            response = await this.service.patch(url, data, {
              ...config,
              headers: {
                ...headers,
                ...customHeaders,
                ...(checkToken ? getAuthHeaderWithToken() : {}),
              },
              cancelToken: cancelToken,
              params,
            });

            break;
          case "DELETE":
            response = await this.service.delete(url, {
              ...config,
              data,
              headers: {
                ...headers,
                ...customHeaders,
                ...(checkToken ? getAuthHeaderWithToken() : {}),
              },
              params,
              cancelToken: cancelToken,
            });

            break;
        }

        if (response) {
          onSuccess(response.data);
          return response.data;
        }
      } catch (error) {
        console.log("URL ERRRO", url);
        console.warn(
          `An error occured while requesting a url : ${url} \n Error: ${JSON.stringify(
            error?.response?.data
          )}`
        );

        if (error.response) {
          // Request made and server responded

          if (error.response.status == 401 && error.response.status == 403) {
            localStorage.clear();

            window.location = "/clogin";
          }

          onError(error.response.data || {});
        } else if (error.request) {
          // The request was made but no response was received
          onError(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          onError(error.message);
        }
      }
    };

  const getAuthHeaderWithToken = () => {
    if (localStorage.getItem("token")) {
      return {
        Authorization: `Token ${localStorage.getItem("sales")}`,
      };
    } else {
      localStorage.clear();

      window.location = "/clogin";

      return {};
    }
  };

  this.getCancelInstance = () => {
    return axios.CancelToken.source();
  };

  this.getAxiosInstance = () => {
    return this.service;
  };

  this.getBaseUrl = () => {
    return this.baseUrl;
  };

  this.get = (url, options = {}) => {
    const cancelApi = this.getCancelInstance();

    return {
      api: makeRequest("GET", url, {
        cancelToken: cancelApi.token,
        ...options,
      }),
      cancel: cancelApi.cancel,
    };
  };

  this.post = (url, options = {}) => {
    const cancelApi = this.getCancelInstance();

    return {
      api: makeRequest("POST", url, {
        cancelToken: cancelApi.token,
        ...options,
      }),
      cancel: cancelApi.cancel,
    };
  };

  this.put = (url, options = {}) => {
    const cancelApi = this.getCancelInstance();

    return {
      api: makeRequest("PUT", url, {
        cancelToken: cancelApi.token,
        ...options,
      }),
      cancel: cancelApi.cancel,
    };
  };

  this.patch = (url, options = {}) => {
    const cancelApi = this.getCancelInstance();

    return {
      api: makeRequest("PATCH", url, {
        cancelToken: cancelApi.token,
        ...options,
      }),
      cancel: cancelApi.cancel,
    };
  };

  this.delete = (url, options = {}) => {
    const cancelApi = this.getCancelInstance();

    return {
      api: makeRequest("DELETE", url, {
        cancelToken: cancelApi.token,
        ...options,
      }),
      cancel: cancelApi.cancel,
    };
  };
}
