import { Link } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import qs from "qs";

export default function SignupForm() {
  const [email, setEmail] = useState();
  const [firstname, setFirstname] = useState();
  const [lastname, setLastname] = useState();
  const [mobile, setMobile] = useState();
  const [doctor, setDoctor] = useState();
  const [speciality, setSpeciality] = useState();
  const [qualification, setQualification] = useState();
  const [medicalCouncil, setMedicalCouncil] = useState();
  const [councilReg, setCouncilReg] = useState();
  const [hospitals, setHospitals] = useState();
  const [interests, setInterests] = useState();
  const [placeofwork, setPlaceofwork] = useState();
  const [onlineconsult, setOnlineconsult] = useState();
  const [password, setPassword] = useState();
  const [password2, setPassword2] = useState();
  const [refid, setRefid] = useState();
  const [age, setAge] = useState();
  const [experience, setExperience] = useState();

  var data = {
    email: email,
    firstname: firstname,
    lastname: lastname,
    mobile: mobile,
    doctor: "true",
    speciality: speciality,
    qualification: qualification,
    medicalCouncil: medicalCouncil,
    councilRegNo: councilReg,
    hospitals: hospitals,
    interests: interests,
    placeOfWork: placeofwork,
    onlineConsultation: onlineconsult,
    password: password,
    password2: password2,
    referalId: refid,
    age: age,
    experience: experience,
  };

  function submitForm(e) {
    // {
    //   console.log(doctor);
    // }
    // if (doctor !== "true") {
    //   alert("please agree to our Terms, Data Policy");
    // }

    console.log(data);
    e.preventDefault();
    var config = {
      method: "POST",
      url: "http://127.0.0.1:8000/hospital/hospital-doctor-registration/",
      // url: "https://sukhprasavam.shebirth.com/doctor-registration/",
      headers: {
        Authorization: `token ` + sessionStorage.getItem("hospitalManager"),
      },

      data: qs.stringify(data),
    };

    axios(config)
      .then(function (response) {
        alert("updated data");
        // window.location = "/clogin";
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        alert(JSON.stringify(error.response.data));
        console.log(error);
      });
  }

  return (
    <>
      <div
        className="form-wrapper2"
        style={{
          display: "flex",
          flexDirection: "column",
          color: "#fff",
          padding: "20px",
          // width: "40%",
          borderRadius: "20px",
          boxShadow: "12px 10px 60px #00000029",
          backdropFilter: "blur(39px)",
          WebkitBackdropFilter: "blur(39px)",
          background: "#00000029",
        }}
      >
        <p>
          {/* <small>
            Are you Pregnant? Kindly spend some time to help us understand you
            better
          </small> */}
        </p>
        <form
          method="post"
          onSubmit={submitForm}
          style={{
            display: "flex",
            flexDirection: "column",
            lineHeight: "70px",
          }}
        >
          <div className="form-input-flex" style={{ display: "flex" }}>
            <input
              style={styles.finput}
              onChange={(e) => setFirstname(e.target.value)}
              type="text"
              placeholder="First Name "
            />
            <input
              style={styles.finput}
              type="text"
              onChange={(e) => setLastname(e.target.value)}
              placeholder="Last Name"
            />
          </div>
          <input
            style={styles.input}
            type=""
            onChange={(e) => setAge(e.target.value)}
            placeholder="Age"
          />
          {/* <input
            style={styles.input}
            onChange={(e) => setSpeciality(e.target.value)}
            type="text"
            placeholder="Speciality"
          /> */}
          <select
            className="padselecrt"
            style={styles.input}
            // name="gender"

            onChange={(e) => setSpeciality(e.target.value)}
            // defaultValue={this.state.items.gender}
            required
            // style={styles.select}
          >
            <option style={styles.option} disabled selected value="" hidden>
              Speciality
            </option>
            <option style={styles.option} value="obstretician">
              Obstetrician
            </option>
            <option style={styles.option} value="pediatrician">
              Pediatrician
            </option>
            <option style={styles.option} value="psychiatrist">
              Psychiatrist
            </option>
            <option style={styles.option} value="deitician">
              Dietitian
            </option>
            <option style={styles.option} value="psychologist">
              Psychologist
            </option>
          </select>
          <input
            style={styles.input}
            onChange={(e) => setQualification(e.target.value)}
            type="text"
            placeholder="Qualification"
          />
          {/* <input
            style={styles.input}
            onChange={(e) => setMedicalCouncil(e.target.value)}
            type="text"
            placeholder="Medical council registered?"
          /> */}
          <select
            className="padselecrt"
            style={styles.input}
            // name="gender"

            onChange={(e) => setMedicalCouncil(e.target.value)}
            // defaultValue={this.state.items.gender}
            required
            // style={styles.select}
          >
            <option style={styles.option} disabled selected value="" hidden>
              Medical council registered?
            </option>
            <option style={styles.option} value="yes">
              Yes
            </option>
            <option style={styles.option} value="no">
              No
            </option>
          </select>
          <input
            style={styles.input}
            onChange={(e) => setCouncilReg(e.target.value)}
            type="text"
            placeholder="Council Registeration Number"
          />
          <input
            style={styles.input}
            onChange={(e) => setExperience(e.target.value)}
            type="text"
            placeholder="Year of Experience"
          />
          <input
            style={styles.input}
            onChange={(e) => setInterests(e.target.value)}
            type="text"
            placeholder="Area of interest"
          />
          <input
            style={styles.input}
            onChange={(e) => setRefid(e.target.value)}
            type="text"
            placeholder="Refrence Code"
          />
          <input
            style={styles.input}
            onChange={(e) => setHospitals(e.target.value)}
            type="text"
            placeholder="Hospital/hospitals working"
          />
          <input
            style={styles.input}
            onChange={(e) => setPlaceofwork(e.target.value)}
            type="text"
            placeholder="Place of Work"
          />
          {/* <input
            style={styles.input}
            onChange={(e) => setOnlineconsult(e.target.value)}
            type="text"
            placeholder="Are you willing to give Online consultation for your Patient?"
          /> */}
          <select
            className="padselecrt"
            style={styles.input}
            // name="gender"

            onChange={(e) => setOnlineconsult(e.target.value)}
            // defaultValue={this.state.items.gender}
            required
            // style={styles.select}
          >
            <option style={styles.option} disabled selected value="" hidden>
              Are you willing to give Online consultation for your Patient?
            </option>
            <option style={styles.option} value="yes">
              Yes
            </option>
            <option style={styles.option} value="no">
              No
            </option>
          </select>
          <input
            style={styles.input}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            placeholder="Email"
          />
          <input
            style={styles.input}
            onChange={(e) => setMobile(e.target.value)}
            type="text"
            placeholder="Contact Number"
          />
          <input
            style={styles.input}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            placeholder="Password"
          />
          <input
            style={styles.input}
            onChange={(e) => setPassword2(e.target.value)}
            type="password"
            placeholder="Confirm Password"
          />
          {/* Missing from api:  <input style={ styles.input } type="" placeholder="Username"/>
<input style={ styles.input } type="" placeholder="Your Expected Counsulting Fee"/> */}
          {/* <div className="display-flex">
            <input
              style={styles.input}
              type="checkbox"
              value="true"
              placeholder="Your Expected Counsulting Fee"
              onChange={(e) => setDoctor(e.target.value)}
            />{" "}
            <p className="font-13">I agree to the T & C</p>
          </div> */}

          <div
            style={{
              margin: "auto",
            }}
          >
            <input
              style={{
                background:
                  "transparent linear-gradient(266deg, #FF006C 0%, #293247 100%) 0% 0% no-repeat padding-box",
                width: "102px",
                height: "32px",
                border: "none",
                color: "#fff",
                boxShadow: "0px 9px 10px #00000029",
                borderRadius: "4px",
                cursor: "pointer",
              }}
              type="submit"
              value="Register"
            />
          </div>
        </form>
      </div>
    </>
  );
}

const styles = {
  input: {
    background: "none",
    padding: "10px",
    color: "#fff",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    outline: "none",
  },
  terms: {
    display: "flex",
    justifyContent: "space-around",
  },
  finput: {
    background: "none",
    padding: "10px",
    color: "#fff",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    width: "44.8%",
    outline: "none",
  },
  option: {
    background: "0% 0% no-repeat padding-box padding-box rgb(111 25 106)",
  },
};
