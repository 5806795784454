import { useState, useEffect } from "react";
import axios from "axios";
import ClientDailyTracker from "../components/traker_by_date/table_list";
import { faSortDown, faSort } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ClientProfile from "../components/client_profile";
import SearchClients from "../components/search";
import Analsistab from "../components/analasis_tabs";
import { useHistory } from "react-router-dom";
export default function AlltimeClientsTable({ searchval }) {
  const history = useHistory();
  const [clientDetails, setClientDetails] = useState([]);
  const [tracker, setTracker] = useState(false);
  const [profile, setProfile] = useState(false);
  const [shedule, setShedule] = useState(false);
  const [clientid, setClientid] = useState("");
  const [selectedWeekRange, setSelectedWeekRange] = useState("");

  function openDailyTracker(id) {
    setTracker(!tracker);
    setClientid(id);
  }
  function openProfile(id) {
    setProfile(!profile);
    setClientid(id);
  }

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    };
    // Define a function to fetch data from the API
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "/consultant/consultant-dashboard-details/",
          config
        );
        setClientDetails(response.data.all_clients); // Assuming the API returns an array of client details under 'all_clients'
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetch function
    fetchData();
  }, []);
  function openAnalasis(id) {
    setClientid(id);
    history.push(`/critical/${id}`);
    //  <Route path="/critical/:id" component={Pageanalasis} />;
    // setShedule(!shedule);
  }

  const filteredClientDetails = clientDetails.filter(
    (client) =>
      client.membership !== "Trial Plan" && client.membership !== "No Plans"
  );
  return (
    <>
      {/* {shedule ? <Analsistab userId={clientid} /> : null} */}
      {profile ? <ClientProfile id={clientid} /> : null}
      {tracker ? <ClientDailyTracker id={clientid} /> : null}
      <div style={styles.card}>
        <div style={styles.inner}>
          <div
            className="sort-container clinetsort-container display-flex "
            style={styles.sortit}
          >
            <span style={{ fontSize: "12px" }}>
              {" "}
              <FontAwesomeIcon icon={faSort} /> Sort By:{" "}
              <select
                className="docoptions"
                style={styles.option}
                onChange={(e) => setSelectedWeekRange(e.target.value)}
              >
                <option value="">Week</option>

                <option value="0-4">0-4</option>
                <option value="4-8">4-8</option>
                <option value="8-12">8-12</option>
                <option value="12-16">12-16</option>
                <option value="16-20">16-20</option>
                <option value="20-24">20-24</option>
                <option value="24-28"> 24-28</option>
                <option value="28-32">28-32</option>
                <option value="32-36">32-36</option>
                <option value="36-40">36-40</option>
                <option value="40+">40+ </option>
                {/* Add other week ranges */}
              </select>
              {/* <FontAwesomeIcon icon={faSortDown} />{" "} */}
            </span>
          </div>
          <p>
            <strong>Paid Users</strong>
          </p>
          <hr style={{ color: "#fff" }} />
          <div className="formobile lightgradient">
            <table className="nospace">
              <thead>
                <tr className="gradiantblur">
                  <td>Name</td>
                  <td>Age</td>
                  <td>Location</td>
                  <td>Due Date</td>
                  <td>Current Week</td>
                  <td>Daily Tracker Details</td>
                  <td>Criticality</td>
                </tr>
              </thead>
              <tbody>
                {filteredClientDetails
                  .filter((client) => {
                    const weekMatches =
                      !selectedWeekRange ||
                      (client.week >=
                        parseInt(selectedWeekRange.split("-")[0], 10) &&
                        client.week <=
                          parseInt(selectedWeekRange.split("-")[1], 10));

                    if (searchval) {
                      const nameMatches =
                        client.firstName
                          .toLowerCase()
                          .includes(searchval.toLowerCase()) ||
                        client.lastName
                          .toLowerCase()
                          .includes(searchval.toLowerCase());
                      return weekMatches && nameMatches;
                    } else {
                      return weekMatches;
                    }
                  })
                  .map((client) => (
                    <tr key={client.id} className="gradiantblur">
                      <td
                        style={styles.usernav}
                        onClick={() => openProfile(client.id)}
                      >
                        {client.firstName} {client.lastName}
                      </td>
                      <td>{client.age}</td>
                      <td>{client.location}</td>
                      <td>{client.dueDate}</td>
                      <td>{client.week}</td>
                      <td>
                        <button onClick={() => openDailyTracker(client.id)}>
                          Details
                        </button>
                      </td>
                      <td>
                        {client.criticality == "high risk" ? (
                          <button
                            onClick={() => openAnalasis(client.id)}
                            // onClick={() => findgraph(ele.id)}
                            style={styles.red}
                          ></button>
                        ) : client.criticality == "stable" ? (
                          <button
                            onClick={() => openAnalasis(client.id)}
                            // onClick={() => findgraph(ele.id)}
                            style={styles.green}
                          ></button>
                        ) : (
                          <button
                            onClick={() => openAnalasis(client.id)}
                            // onClick={() => findgraph(ele.id)}
                            style={styles.yellow}
                          ></button>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

const styles = {
  card: {
    // display: "flex",
    justifyContent: "space-between",
    // background: "#4c003d",
    padding: "20px",
    borderRadius: "10px",
    margin: "10px",
  },
  inner: {
    textDecoration: "none",
    color: "#fff",
  },

  usernav: {
    // background: "#4c005d",
    padding: "5px 10px",
    cursor: "pointer",
  },
  red: { background: "red", border: "1px solid red" },
  green: { background: "green", border: "1px solid green" },
  yellow: {
    background: "yellow",
    border: "1px solid yellow",
  },
};
