import {
  faBlender,
  faCookie,
  faHamburger,
  faMugHot,
  faUtensils,
  faWineBottle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import "react-calendar/dist/Calendar.css";
import {
  getDietDetailsByDate,
  updateDietDetails,
} from "../../../helpers/service";

export default function Customized_diet({ date, customer, ...props }) {
  const [data, setData] = useState({});

  const submitValue = (mealName) => (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);

    formData.append("customer", customer);

    // var json = {};

    // formData.forEach(function (value, key) {
    // 	json[key] = value;
    // });

    const request = updateDietDetails();

    request
      .api({
        data: formData,
        // data: json,
      })
      .then((response) => {
        if (response) {
          fetchData();
          alert("Data updated");
        } else {
          alert("Data not updated. Please try again later");
        }
      });
  };

  const fetchData = () => {
    const request = getDietDetailsByDate();

    request
      .api({
        params: {
          customer,
          date,
        },
      })
      .then((response) => {
        if (response) {
          setData(
            response.Diet?.reduce((obj, item) => {
              obj[item.mealName?.toLowerCase().split(" ").join("_")] = {
                ...item,
              };

              return obj;
            }, {})
          );
        }
      });

    return request;
  };
  React.useEffect(() => {
    if (date && customer) {
      const request = fetchData();

      return () => {
        request.cancel();
      };
    }
  }, [date, customer]);

  return (
    <>
      <form onSubmit={submitValue("early_morning")}>
        {/* <table> */}
        <h2
          className="lightgradient2"
          style={{
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            padding: "10px 0px",
            margin: "0px",
            fontSize: "26px",
            // background: "#8c3186",
          }}
        >
          Update Diet Tracker
        </h2>
        {/* </table> */}
      </form>

      <form onSubmit={submitValue("breakfast")} style={styles.formcolor}>
        <table className="tableoffood">
          {/* {data.map((dietData, index) => {
          return ( */}
          <tr>
            <td>
              <FontAwesomeIcon icon={faMugHot} style={styles.icon} />
            </td>
            <td style={styles.td}>Early Morning Energy</td>
            <td className="invisbileinput3">
              <input
                name="mealType"
                //
                style={styles.invinput}
                className="invisbileinpu2"
                value="early morning"
                hidden
              />

              <input name="date" style={styles.invinput} value={date} hidden />
            </td>
            <td>
              <input
                name="food"
                type="text"
                defaultValue={data.early_morning?.food}
                style={styles.label}
                className="inputofdiet"
              />
              <br />
              <input
                name="time"
                type="text"
                defaultValue={data.early_morning?.time}
                style={styles.label}
                className="inputofdiet"
              />
            </td>
            <td>
              <button style={styles.buttongreen} type="submit">
                Add
              </button>
            </td>
          </tr>
        </table>
      </form>
      <form onSubmit={submitValue("breakfast")} style={styles.formcolor}>
        <table className="tableoffood">
          <tr>
            <td>
              <FontAwesomeIcon icon={faHamburger} style={styles.icon} />
            </td>
            <td style={styles.td}>Breakfast</td>
            <td className="invisbileinput3">
              <input
                name="mealType"
                //
                style={styles.invinput}
                className="invisbileinpu2"
                value="breakfast"
                hidden
              />
              <input
                name="date"
                //
                style={styles.invinput}
                className="invisbileinpu2"
                value={date}
                hidden
              />
            </td>
            <td>
              <input
                // ref={register}
                type="text"
                name="food"
                defaultValue={data.breakfast?.food}
                style={styles.label}
                className="inputofdiet"
              />
              <br />
              <input
                name="time"
                style={styles.label}
                className="inputofdiet"
                defaultValue={data.breakfast?.time}
              />
              <input
                name="date"
                //
                style={styles.invinput}
                className="invisbileinpu2"
                value={date}
                hidden
              />
            </td>
            <td>
              <button style={styles.buttongreen} type="submit">
                Add
              </button>
            </td>
          </tr>
        </table>
      </form>
      <form onSubmit={submitValue("mid_day_snack")} style={styles.formcolor}>
        <table className="tableoffood">
          <tr>
            <td>
              <FontAwesomeIcon icon={faHamburger} style={styles.icon} />
            </td>
            <td style={styles.td}>Mid-Day Snack</td>
            <td className="invisbileinput3">
              <input
                name="mealType"
                //
                style={styles.invinput}
                className="invisbileinpu2"
                value="mid day snack"
                hidden
              />
              <input
                name="date"
                //
                style={styles.invinput}
                className="invisbileinpu2"
                value={date}
                hidden
              />
            </td>
            <td>
              <input
                name="food"
                style={styles.label}
                className="inputofdiet"
                defaultValue={data.mid_day_snack?.food}
              />
              <br />
              <input
                name="time"
                style={styles.label}
                className="inputofdiet"
                defaultValue={data.mid_day_snack?.time}
              />
            </td>
            <td>
              <button style={styles.buttongreen} type="submit">
                Add
              </button>
            </td>
          </tr>
        </table>
      </form>
      {/* <table>
          <tr>
            <td>
              <FontAwesomeIcon icon={faGlassMartini} style={styles.icon} />
            </td>
            <td>Mid-Day Snack</td>
            <td>
              <br />
            </td>
            <td>
                <button style={styles.buttongreen} type="submit">Add</button> */}
      {/* </td>
          </tr> */}
      <form onSubmit={submitValue("lunch")} style={styles.formcolor}>
        <table className="tableoffood">
          <tr>
            <td>
              <FontAwesomeIcon icon={faUtensils} style={styles.icon} />
            </td>
            <td style={styles.td}>lunch</td>
            <td className="invisbileinput3">
              <input
                name="mealType"
                //
                style={styles.invinput}
                className="invisbileinpu2"
                value="lunch"
                hidden
              />
              <input
                name="date"
                //
                style={styles.invinput}
                className="invisbileinpu2"
                value={date}
                hidden
              />
            </td>
            <td>
              <input
                name="food"
                style={styles.label}
                className="inputofdiet"
                defaultValue={data.lunch?.food}
              />
              <br />
              <input
                name="time"
                style={styles.label}
                className="inputofdiet"
                defaultValue={data.lunch?.time}
              />
            </td>
            <td>
              <button style={styles.buttongreen} type="submit">
                Add
              </button>
            </td>
          </tr>
        </table>
      </form>
      {/* start here/....................................................................... */}
      <form onSubmit={submitValue("afternoon_snack")} style={styles.formcolor}>
        <table className="tableoffood">
          <tr>
            <td>
              <FontAwesomeIcon icon={faBlender} style={styles.icon} />
            </td>
            <td style={styles.td}>Afternoon Snack</td>
            <td className="invisbileinput3">
              <input
                name="mealType"
                //
                style={styles.invinput}
                className="invisbileinpu2"
                value="afternoon snack"
                hidden
              />
              <input
                name="date"
                //
                style={styles.invinput}
                className="invisbileinpu2"
                value={date}
                hidden
              />
            </td>
            <td>
              <input
                name="food"
                style={styles.label}
                className="inputofdiet"
                defaultValue={data.afternoon_snack?.food}
              />
              <br />
              <input
                name="time"
                style={styles.label}
                className="inputofdiet"
                defaultValue={data.afternoon_snack?.time}
              />
            </td>
            <td>
              <button style={styles.buttongreen} type="submit">
                Add
              </button>
            </td>
          </tr>
        </table>
      </form>
      <form onSubmit={submitValue("dinner")} style={styles.formcolor}>
        <table className="tableoffood">
          <tr>
            <td>
              <FontAwesomeIcon icon={faCookie} style={styles.icon} />
            </td>
            <td style={styles.td}>Dinner</td>
            <td className="invisbileinput3">
              <input
                name="mealType"
                //
                style={styles.invinput}
                className="invisbileinpu2"
                value="dinner"
                hidden
              />
              <input
                name="date"
                //
                style={styles.invinput}
                className="invisbileinpu2"
                value={date}
                hidden
              />
            </td>
            <td>
              <input
                name="food"
                style={styles.label}
                className="inputofdiet"
                defaultValue={data.dinner?.food}
              />
              <br />
              <input
                name="time"
                style={styles.label}
                className="inputofdiet"
                defaultValue={data.dinner?.time}
              />
            </td>
            <td>
              <button style={styles.buttongreen} type="submit">
                Add
              </button>
            </td>
          </tr>
        </table>
      </form>
      <form onSubmit={submitValue("dinner_drink")} style={styles.formcolor}>
        <table className="tableoffood">
          <tr>
            <td>
              <FontAwesomeIcon
                icon={faWineBottle}
                style={{ fontSize: "2rem", transform: "rotate(-45deg)" }}
              />
            </td>
            <td style={styles.td}>Dinner Drink</td>
            <td className="invisbileinput3">
              <input
                name="mealType"
                style={styles.invinput}
                className="invisbileinpu2"
                value="dinner drink"
                hidden
              />
              <input
                name="date"
                //
                style={styles.invinput}
                className="invisbileinpu2"
                value={date}
                hidden
              />
            </td>
            <td>
              <input
                name="food"
                style={styles.label}
                className="inputofdiet"
                defaultValue={data.dinner_drink?.food}
              />
              <br />
              <input
                name="time"
                style={styles.label}
                className="inputofdiet"
                defaultValue={data.dinner_drink?.time}
              />
            </td>
            <td>
              <button style={styles.buttongreen} type="submit">
                Add
              </button>
            </td>
          </tr>
        </table>
      </form>
    </>
  );
}

const styles = {
  buttongreen: { background: "#00ACC8", border: "none", borderRadius: 0 },
  icon: {
    fontSize: "2rem",
  },
  tr: {
    borderCollapse: "separate",
    borderSpacing: "20px",
  },
  label: {
    fontSize: "12px",
    padding: "3px",
    width: "56px",
    display: "inline-block",
    background: "#fefe",
    color: "#333",
  },
  invinput: {
    fontSize: " 20px",
    background: "#4c003d",
    textAlign: "center",
    marginTop: "15px",
    border: "none",
  },
  td: {
    width: "100px",
  },
  t3: {
    width: "114px",
  },
  formcolor: {
    marginTop: "15px",
    background: "#ffffff1c 0% 0% no-repeat padding-box",
  },
};
