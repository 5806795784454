import { useState } from "react";
import Image from "../../img/profile.png";
import Callresponse from "./callreponse";
import { useHistory } from "react-router-dom";
export default function PersonalDetail({ data = {} }) {
  const [tracker, setTracker] = useState(false);
  const [userid, setuserid] = useState();
  const history = useHistory();

  function openTracker(e) {
    // e.preventDefault();

    setuserid(e);
    // console.log("hello");
    console.log(e);
    setTracker(!tracker);
    history.push(`/callresponse/${e} `);
    // window.location.reload();
  }
  // function openTracker(e) {
  //   console.log(e, "dsdjkskdks");
  //   setTracker(!tracker);
  // }

  return (
    <>
      {/* {tracker ? <Callresponse id={userid} /> : null} */}
      <table className="personal-detail  outerPerson">
        <tr>
          <td>
            {data.customer.profile_pic !=
            "https://sukhprasavam.shebirth.com/media//ProfilePic/default.jpg" ? (
              <img
                style={{ maxWidth: "65px" }}
                className="avatar"
                src={data.customer.profile_pic}
                alt=""
              />
            ) : data.customer.profile_pic ==
              "https://sukhprasavam.shebirth.com/media//ProfilePic/default.jpg" ? (
              <button
                className="avatar aquamarine "
                style={{ maxWidth: "65px", border: "none" }}
              ></button>
            ) : (
              <button
                className="avatar aquamarine "
                style={{ maxWidth: "65px", border: "none" }}
              ></button>
            )}
            {/* {data.customer.profile_pic ? (
              <img
                src={data.customer.profile_pic}
                className="avatar"
                height="75px"
                width="75px"
                style={{ borderRadius: "50%" }}
              />
            ) : (
              <img
                src={data.details?.profile_pic || Image}
                height="75px"
                width="75px"
                style={{ borderRadius: "50%" }}
                alt=""
              />
            )} */}
          </td>
          <td style={styles.col}>
            <strong style={{ fontSize: "20px" }}>
              {`${
                !!data.customer.firstname ? data.customer.firstname : ""
              }`.trim()}
            </strong>
            <br />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <small
                style={{ padding: "3px 0px", borderBottom: "1px solid #fff" }}
              >
                Username:{" "}
                <span style={{ marginLeft: "10px" }}>
                  {(data.customer?.firstname || "").toLowerCase()}
                </span>
              </small>
              <small
                style={{ padding: "3px 0px", borderBottom: "1px solid #fff" }}
              >
                {data.details.gender || "Female"} | {data.details.age}{" "}
                <span style={{ marginLeft: "10px" }}>
                  {data.details.mobile}
                </span>
              </small>
              <br />
            </div>
          </td>
          {/* {console.log(data.customer.id)} */}

          <td style={styles.col}>
            <span
              style={styles.ftext}
              onClick={() => openTracker(data.customer.id)}
            >
              Call response
            </span>
            <br />
          </td>
          <td style={styles.col}>
            <button
              onClick={() =>
                window.open(`https://wa.me/${data.customer.mobile}`, "_blank")
              }
            >
              Open WhatsApp
            </button>
            <br />
          </td>
        </tr>
        <tr>
          <td className="removetd"></td>
          <td style={styles.co1}>Fullname</td>
          <td>
            <input
              style={styles.co}
              type="text"
              value={`${
                !!data.customer.firstname ? data.customer.firstname : ""
              } ${
                !!data.customer.lastname ? data.customer.lastname : ""
              }`.trim()}
            />
          </td>
        </tr>
        <tr>
          <td className="removetd"></td>
          <td style={styles.co1}>Age</td>
          <td>
            <input type="text" style={styles.co} value={data.details.age} />
          </td>
        </tr>
        <tr>
          <td className="removetd"></td>
          <td style={styles.co1}>Email</td>
          <td>
            <input type="text" style={styles.co} value={data.customer.email} />
          </td>
        </tr>
        <tr>
          <td className="removetd"></td>
          <td style={styles.co1}>Address</td>
          <td>
            <input type="text" style={styles.co} value={data.details.address} />
          </td>
        </tr>
        <tr>
          <td className="removetd"></td>
          <td style={styles.co1}>Mobile</td>
          <td>
            <input type="text" style={styles.co} value={data.customer.mobile} />
          </td>
        </tr>
        <tr>
          <td className="removetd"></td>
          <td style={styles.co1}>Individual ID proof with photo</td>
          <td>
            <input type="text" style={styles.co} value={data.details.Idproof} />
          </td>
        </tr>
        <tr>
          <td className="removetd"></td>
          <td style={styles.co1}>Husband Name</td>
          <td>
            <input
              type="Apple"
              style={styles.co}
              value={data.details.husband}
            />
          </td>
        </tr>
        <tr>
          <td className="removetd"></td>
          <td style={styles.co1}>Your Job</td>
          <td>
            <input type="text" style={styles.co} value={data.details.job} />
          </td>
        </tr>
      </table>
    </>
  );
}

const styles = {
  dprofile: {
    background: "rgb(76, 0, 61) none repeat scroll 0% 0%",
    width: "41.3rem",
    borderRadius: "10px",
    padding: "10px",
  },

  titlePlate: {
    padding: "10px",
    color: "#fff",
  },
  flex: {
    display: "flex",
    justifyContent: "space-around",
    padding: "10px",
  },
  col: {
    padding: "10px",
    textAlign: "left",
  },
  co: {
    padding: "10px",
    color: "white",
    textAlign: "left",
    border: "1px solid rgb(231 22 255 / 3%)",
    // background: "#85388E",
    background:
      " 0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
  },
  money: {
    color: "#fff",
  },
  co1: {
    padding: "10px",
    color: "white",
    textAlign: "left",
    border: "1px solid rgb(231 22 255 / 3%)",
    // background: "#85388E",
  },

  btn: {
    padding: "3px",
    background: "#85388E",
    border: "none",
  },

  fee: {
    padding: "5px",
    background: "transparent",
    outline: "1px solid #85388E",
  },

  ftext: {
    fontSize: "12px",
    padding: "5px",

    marginRight: "2rem",
    outline: "1px solid #fff",
    cursor: "pointer",
  },
};
