import React, { useState, useEffect } from "react";
import axios from "axios";

export default function DietTracker({ id }) {
  const [data, setData] = useState([]);
  const [update, setUpdate] = useState(false);

  //   const [breakfast, setbreakfast] = useState({});
  //   const [earlymoring, setearlymoring] = useState([]);
  //   const [midday, setmidday] = useState({});
  //   const [afternoon, setafternoon] = useState({});
  //   const [dinner, setDinner] = useState({});
  //   const [allValues, setAllValues] = useState({});
  //   const [lunch, setlunch] = useState({});
  //   const [dinnerdrink, setdinnerdrink] = useState({});

  function openUpdater() {
    setUpdate(true);
  }
  console.log(id);
  useEffect(() => {
    //remove this date after setting up the admin pannel

    axios
      .get(`customer/all-dates-diet/`, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
        params: {
          id: id,
        },
      })
      .then((res) => {
        const groupedByDate = res.data.reduce((meals, meal) => {
          if (meal.date in meals) {
            meals[meal.date].push(meal);
          } else {
            meals[meal.date] = [meal];
          }
          return meals;
        }, {});

        console.log(groupedByDate);
        const sortedOnDate = Object.values(groupedByDate).sort((arr1, arr2) => {
          const ymdDate1 = arr1[0].date.split("-").reverse().join("-");
          const ymdDate2 = arr2[0].date.split("-").reverse().join("-");
          if (ymdDate1 < ymdDate2) {
            return 1;
          } else if (ymdDate1 > ymdDate2) {
            return -1;
          }
          return 0;
        });
        console.log(sortedOnDate);
        setData(sortedOnDate);
      });
  }, []);
  return (
    <>
      <div className="scrollerbar">
        <table
          style={{
            borderCollapse: "collapse",
            // borderSpacing: "2px 19px",
          }}
        >
          <thead style={{ borderBottom: "5px solid #5c0048" }}>
            <tr
              style={{
                background:
                  "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
              }}
              className="font-blue"
            >
              <th style={styles.th}>Date</th>
              <th style={styles.th}>Early Morning Energy</th>
              <th style={styles.th}>Breakfast</th>
              <th style={styles.th}>Mid-Day Snack</th>
              <th style={styles.th}>Lunch</th>
              <th style={styles.th}>Afternoon Snack</th>
              <th style={styles.th}>Dinner</th>
              <th style={styles.th}>Dinner Drink</th>
            </tr>
          </thead>

          {data.map((arr) => {
            let lunch = arr.find((o) => o.mealName === "lunch");
            let breakfast = arr.find((o) => o.mealName === "breakfast");
            let earlymorning = arr.find((o) => o.mealName === "early morning");
            let dinner = arr.find((o) => o.mealName === "dinner");
            let afternoon = arr.find((o) => o.mealName === "afternoon snack");
            let midday = arr.find((o) => o.mealName === "mid day snack");
            let dinnerdrink = arr.find((o) => o.mealName === "dinner drink");
            return (
              <tbody key={arr[0].date}>
                <tr
                  style={{
                    background:
                      "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
                  }}
                >
                  <td style={styles.tdFirst}> {arr[0].date}</td>
                  <td style={styles.td}>
                    {" "}
                    {earlymorning && earlymorning.food} <br />{" "}
                    {earlymorning && earlymorning.time}
                    
                  </td>
                  <td style={styles.td}>
                    {breakfast && breakfast.food}
                    <br /> {breakfast && breakfast.time}
                  </td>
                  <td style={styles.td}>
                    {midday && midday.food} <br /> {midday && midday.time}
                  </td>
                  <td style={styles.td}>
                    {lunch && lunch.food} <br /> {lunch && lunch.time}
                  </td>
                  <td style={styles.td}>
                    {afternoon && afternoon.food} <br />{" "}
                    {afternoon && afternoon.time}
                  </td>
                  <td style={styles.td}>
                    {dinner && dinner.food} <br /> {dinner && dinner.time}
                  </td>
                  <td style={styles.td}>
                    {dinnerdrink && dinnerdrink.food} <br />
                    {dinnerdrink && dinnerdrink.time}
                  </td>
                </tr>
              </tbody>
            );
          })}
        </table>
      </div>
    </>
  );
}

const styles = {
  th: {
    fontSize: "17px",
    whiteSpace: "nowrap",
    padding: "5px 10px",
    margin: "10px 0px",
  },

  td: {
    border: "1px solid black",
    padding: "5px 10px",
    textAlign: "left",
    borderLeft: "1px solid black",
    fontSize: "12px",

    background:
      "0% 0% no-repeat padding-box padding-box rgb(255 255 255 / 12%)",
  },

  tdFirst: {
    padding: "5px 10px",
    fontSize: "12px",
    border: "1px solid black",
  },

  sbtn: {
    border: "none",
    margin: "5px 0px",
    color: "#fff",
    background: "#E81A7F",
    padding: "8px",
    fontWeight: "bold",
    cursor: "pointer",
  },
};
