// import AMan from "../../img/man.jpg";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
export default function Summery(props, onClose) {
  console.log(props.notes);

  return (
    <>
      <div style={styles.card} className="gradiantblur">
        <button className="close-button" onClick={onClose}>
          Close
        </button>
        <strong style={styles.cardheader}>Notes</strong>

        <form>
          <div style={{ padding: "20px" }}>
            <div style={styles.disc} className="gradiantblur">
              <textarea
                className="gradiantblur"
                style={{
                  width: "100%",
                  height: "200px",
                  color: "white",
                  backdropFilter: "blur(10px)",
                  backgroundColor: "rgba(255, 255, 255, 0.14)",
                }}
                defaultValue={props.notes}
              ></textarea>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

const styles = {
  card: {
    backdropFilter: "blur(10px)",
    backgroundColor: "rgba(255, 255, 255, 0.14)",
    display: "flex",
    position: "absolute",
    width: "650px",

    justifyContent: "space-between",

    padding: "0px",
    borderRadius: "10px",
    margin: "10px",
    flexDirection: "column",
    zIndex: "9",
  },

  inner: {
    textDecoration: "none",
    color: "#fff",
  },

  info: {
    fontSize: "12px",
  },

  hr: {
    color: "#fff",
  },

  cardheader: {
    // background: "#55005C",
    textAlign: "center",
    padding: "10px 8px",
    width: "auto",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    borderBottom: "1px solid gray",
  },

  manpic: {
    width: "80px",
    height: "80px",
    borderRadius: "50%",
    marginRight: "10px",
  },

  upcom: {
    display: "flex",
    justifyContent: "space-between",
    width: "20rem",
    padding: "30px",
  },

  disc: {
    borderRadius: "10px",
    padding: "10px",
    textAlign: "left",
    // background: "#A460B4",
  },
};
