import axios from "axios";
import { useEffect, useState } from "react";

export default function Feedingform() {
  const [data, setData] = useState([]);
  const [date, setDate] = useState(new Date().toISOString().split('T')[0]); // Initialize the date state with a specific value

  const dateHandler=(event)=>{
    setDate(event.target.value);
  }

  const config = {
    headers: {
      Authorization: `token ` + localStorage.getItem("postclient"),
    },
  };
  console.log('post',config)
  useEffect(() => {
    axios
      .get(`http://127.0.0.1:8000/customer/get_breastfeeding_records/?date=${date}`, config)
      .then((res) => {
        setData(res.data.breastfeeding_records); // Set the state with the received data
        console.log(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [date]); // Add 'date' as a dependency so that the effect is re-run when the 'date' changes

  function handleCheckClick(index, isChecked,id) {
    const updatedData = [...data];
    updatedData[index].is_brestfed = isChecked;

    setData(updatedData);
    console.log('t0rf',isChecked)
    const Data = {
      date: date,
      is_breastfed: isChecked,
      feeding_number:id,
    };
    console.log(Data)
    axios
      .patch("http://127.0.0.1:8000/customer/submit_breastfeeding_records/", Data, config)
      .then((res) => {
        alert("Data updated successfully!");
        console.log(res.data)
      })
      .catch((error) => {
        alert('not done');
        console.log(error)
      });
  }

  return (
    <>
      <div style={{border:'5px solid #8A2659',borderRadius:'10px'}}>
    
        <h3>Breast Feeding</h3>
        <hr />
        <input
                    className="calanderbutton"
                    type="date"
                    id="myDate"
                    onChange={dateHandler}
                    defaultValue={date}
                  />
        <div style={{marginTop:'59px'}}>
          
          {data.map((record, index) => (
            <div style={{ }} key={record.breastfeeding_record}>
              <span>Breastfeeding Record: {record.breastfeeding_record}</span> &nbsp; &nbsp;
              <input
                type="checkbox"
                checked={record.is_breastfed} // Check the checkbox based on is_brestfed value
                onChange={(e) => {handleCheckClick(index, e.target.checked,record.breastfeeding_record);record.is_breastfed=true}} // Update the value on checkbox change
              /> <br></br><br></br>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
const styles = {
  module: {
    height: "550px",
    overflowY: "scroll",
    scrollbarWidth: "none",

    // background: "rgb(76, 0, 61) none repeat scroll 0% 0%",
    margin: "0px auto",
    borderRadius: "10px",
    padding: "0px 0px",
    width: "94%",
  },
  txt: {
    textAlign: "left",
  },
  txt1: {
    textTransform: "capitalize",
    marginLeft: "10px",
    textAlign: "left",
  },
  row: {
    background: "#ffffff1c 0% 0% no-repeat padding-box",
    borderRight: "50px",
  },
  addmedicine: {
    padding: "5px 60px",
    background: "none",
    borderLeft: "none",
    borderTop: "none",
    borderRight: "none",
    borderRadius: "0px",
    borderBottom: "1px solid #fff",
    color: "#fff",
  },
  sbtn: {
    padding: "1rem 5rem",
    // border: "1px solid #6984B0",
    // background: "linear-gradient(90deg, #6984B0, #B16DD4)",
    margin: "1rem 0rem",
    color: "#fff",
    borderRadius: "10px",
    cursor: "pointer",
  },

  gridrow: {
    display: "grid",
    // gridTemplateColumns: "1fr 1fr",
  },

  gridinner: { display: "grid", gridTemplateColumns: "3fr 1fr" },
};
