import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faThLarge, faBriefcaseMedical, faHistory, faCube } from '@fortawesome/free-solid-svg-icons'

import Logo from '../../shelogo.png';

export default class Sidebar extends React.Component{

render(){
return (
<div style={styles.header}>
<img src={ Logo } alt=""/>
<ul style={styles.lists}>
<li style={styles.listItemActive}><div><FontAwesomeIcon icon={faThLarge} style={{ marginRight:'1rem' }}/></div><div> Dashboard</div></li>
<li style={styles.listItem}> <div><FontAwesomeIcon icon={faBriefcaseMedical} style={{ marginRight:'1rem' }}/></div><div> Approve Doctor</div></li>
<li style={styles.listItem}><div><i className='fa fa-upload' style={{ marginRight:'1rem' }}></i></div><div> Learning Module</div></li>
<li style={styles.listItem}><div><FontAwesomeIcon icon={faHistory} style={{ marginRight:'1rem' }}/></div><div> Update Daily tracker</div></li>
<li style={styles.listItem}><div><FontAwesomeIcon icon={faCube} style={{ marginRight:'1rem' }}/></div><div>Subscriptions</div></li>
</ul>
<div style={{ position:'absolute', bottom:'3rem', left:'30px', width:'15%' }}>
<div style={{ marginTop:'3rem' }}>
<button style={{ color:'#fff', textDecoration:'none', background:'transparent', border:'none' }}><FontAwesomeIcon icon={faCog} style={{ marginRight:'1rem' }}/>Settings</button>
</div>
</div>
</div>
);
}
}


const styles = {
header:{
background: 'linear-gradient(25deg, #3a51a7, #7f1f54)',
padding:'2rem',
minHeight: '100%',
width: '20%',
margin: '0',
color:'white'
},
lists:{
marginTop:'5rem',
color:'#fff',
paddingLeft:'0',
listStyle:'none'
},
listItem:{
background:'transparent',
padding:'10px 50px',
borderRadius: '10px',
cursor:'pointer',
display:'flex',
},
listItemActive:{
background:'#6a0f4b',
padding:'10px 50px',
borderRadius: '10px',
cursor:'pointer',
display:'flex',
}




}
