import React from "react";
import axios from "axios";
//import { Redirect } from 'react-router-dom';
import Sidebar from "../../components/sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faUser, faBars } from "@fortawesome/free-solid-svg-icons";
import { getSalesDashboardDetails } from "../../../helpers/service";
//import Toggler from "../../toggle";
import ClientProfile from "../../toggle_profile";
//import DailyTrackerTabs from './components/daily_tracker';

//================ Importing Components ======
import Criticality from "./Criticality";
// import Greet from "../../components/greet";
// import ApprovalRequest from "../../components/approval_requests";
// import TodayAppointment from "../../components/today_appointments";
// import CurrentStatus from "../../components/current_status";
import Toggler from "../../toggle_profile";

export default class DoctorOverview extends React.Component {
  constructor(props) {
    super(props);
    this.fetchSalesDashboardDetails = getSalesDashboardDetails();

    this.state = {
      isToggleOn: true,
      isProfile: true,
      details: {},
      searchText: "",
      sortBy: "week",
      items4: [],
      filteredTableData: [],
    };
    this.clickedOutside = this.clickedOutside.bind(this);
    this.profileHandler = this.profileHandler.bind(this);

    // this.handleClick = this.handleClick.bind(this);
  }
  clickedOutside(e) {
    if (this.isWap && !this.isWap.contains(e.target)) {
      this.setState({ isToggleOn: true });
      this.setState({ isProfile: true });
    }
  }
  profileHandler() {
    this.setState({ isProfile: !this.state.isProfile });
  }
  componentDidMount() {
    if (localStorage.getItem("sales") === null) {
      localStorage.clear();
      window.location = "/clogin";
    }
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("sales"),
      },
    };

    axios.get("sales/sales-dashboard-details/", config).then((res) => {
      console.log(res.data);
      this.setState({ items4: res.data });
      this.setState({ details: res.data.details });
    });
  }

  componentDidMount() {
    if (!localStorage.getItem("token")) {
      localStorage.clear();
      window.location = "/clogin";
      return;
    }

    this.fetchSalesDashboardDetails.api().then((response) => {
      if (response) {
        this.setState((prevState) => ({
          ...prevState,
          details: response,
          filteredTableData: response.totalPatients_details,
        }));
      }
    });
  }

  componentWillUnmount() {
    // Cancels the API while cleanup
    this.fetchSalesDashboardDetails.cancel();
  }

  //   handleClick() {
  //     this.setState((prevState) => ({
  //       ...prevState,
  //       isToggleOn: !prevState.isToggleOn,
  //     }));
  //   }

  render() {
    function openMenu() {
      document.querySelector(".sidebar").classList.toggle("show");
    }

    if (localStorage.getItem("sales") === null) {
      localStorage.clear();
      window.location = "/clogin";
    }

    return (
      <>
        <div className="App">
          <Sidebar />
          <header className="overview">
            <div
              className="grandiant"
              ref={this.setWrap}
              style={{ padding: "2% 10%", textAlign: "right" }}
            >
              {" "}
              {/* <div>{this.state.isToggleOn ? null : <Toggler />}</div> */}
              <span className="iconsofgradiant">
                <FontAwesomeIcon
                  className="iconsofprofile"
                  // onClick={this.handleClick}
                  style={{ marginRight: "1rem", cursor: "pointer" }}
                  icon={faBell}
                />
              </span>
              <span className="iconsofgradiant">
                <FontAwesomeIcon
                  className="iconsofprofile"
                  onClick={this.profileHandler}
                  style={{ cursor: "pointer" }}
                  icon={faUser}
                />
              </span>{" "}
              <span>
                {`${
                  !!this.state.details.firstname
                    ? this.state.details.firstname
                    : ""
                } ${
                  !!this.state.details.lastname
                    ? this.state.details.lastname
                    : ""
                }`.trim()}
              </span>
              {this.state.isProfile ? null : <ClientProfile />}{" "}
            </div>

            <div style={{ width: "89%", display: "flex", paddingLeft: "10px" }}>
              <span onClick={openMenu} className="menu">
                <FontAwesomeIcon
                  style={{ marginRight: "20px" }}
                  icon={faBars}
                  className="menu-icon"
                />
              </span>

              <span style={{ whiteSpace: "nowrap" }}>Overview</span>
              <div style={{ padding: "4px", width: "100%" }}>
                <hr style={{ background: "white", color: "white" }} />
              </div>
            </div>
            <div className="mwrapper">
              <div className="mleft docmright">
                <Criticality />
              </div>
              <div className="mright ">
                {/* <ApprovalRequest />
                <TodayAppointment />
                <CurrentStatus /> */}
              </div>
            </div>
          </header>
        </div>
      </>
    );
  }
}
