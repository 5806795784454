import React from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { getCustomerDetails } from "../../helpers/service";
import MedicalDetail from "./innercom/medical_detail";
import PersonalDetail from "./innercom/personal_detail";
// import DietTracker from '../img/balance_diet.png';

class Profile extends React.Component {
  constructor(props) {
    super(props);

    this.fetchCustomer = getCustomerDetails();

    this.state = {
      data: {
        customer: {},
        details: {},
      },
    };
  }

  componentDidMount() {
    this.fetchCustomer
      .api({
        params: {
          customer: this.props.profileId,
        },
      })
      .then((response) => {
        if (response) {
          this.setState((prevState) => ({
            ...prevState,
            data: response,
          }));
        }
      });
  }

  componentWillUnmount() {
    // Cancels the API while cleanup
    this.fetchCustomer.cancel();
  }

  render() {
    const { closeProfile } = this.props;

    return (
      <>
        {!this.props.profileId ? null : (
          <div style={styles.card} className="opentab doctorclint">
            <div style={styles.inner} className="innertab innerdoctorclint">
              <i
                style={styles.closeit}
                onClick={closeProfile}
                className="fa fa-close"
              ></i>
              {/* <div className="innersales_profile"> */}
              <Tabs>
                <TabList
                  style={{
                    width: "40rem",
                    listStyle: "none",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Tab>
                    <strong style={{ padding: "10px", cursor: "pointer" }}>
                      Personal Details
                    </strong>
                  </Tab>
                  <Tab>
                    <strong style={{ padding: "10px", cursor: "pointer" }}>
                      Medical Details
                    </strong>
                  </Tab>
                </TabList>

                <TabPanel style={styles.tabheight}>
                  <PersonalDetail data={this.state.data} />
                </TabPanel>

                <TabPanel style={styles.tabheight}>
                  <MedicalDetail data={this.state.data} />
                </TabPanel>
              </Tabs>
              {/* </div> */}
            </div>
          </div>
        )}
      </>
    );
  }
}

const styles = {
  card: {
    display: "flex",
    justifyContent: "space-between",
    // background: "#9038a3",
    // background:
    // "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
    backdropFilter: "blur(20px)",
    backgroundColor: "rgba(255, 255, 255, 0.14)",
    padding: "20px",
    borderRadius: "10px",
    margin: "10px",
    // backdropFilter: "blur(16px) saturate(180%)",

    zIndex: 100,
    position: "absolute",
  },

  inner: {
    textDecoration: "none",
    color: "#fff",
  },

  info: {
    fontSize: "12px",
  },

  closeit: {
    position: "absolute",
    right: "0px",
    top: "-5px",
    cursor: "pointer",
  },

  tabheight: {
    scrollbarWidth: "none",
    height: "30rem",
    overflowY: "scroll",
  },
};

export default Profile;
