import React, { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router";
export default function ExerciseTracker(props) {
  const [data, setData] = useState([]);
  const { id } = useParams();
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("doc_token"),
      },
    };
    //remove this date after setting up the admin pannel

    axios
      .get(`customer/get-all-dates-exercises/`, {
        headers: {
          Authorization: `Token ` + sessionStorage.getItem("token"),
        },
        params: {
          customer: props.userid1,
        },
      })
      .then((res) => {
        const data = res.data.exercises.concat(res.data.calories);
        const maindata = data.concat(res.data.customExericises);

        const groupedByDate = maindata.reduce((exercises, exercise) => {
          if (exercise.date in exercises) {
            exercises[exercise.date].push(exercise);
          } else {
            exercises[exercise.date] = [exercise];
          }
          return exercises;
        }, {});

        console.log(groupedByDate);
        const sortedOnDate = Object.values(groupedByDate).sort((arr1, arr2) => {
          const ymdDate1 = arr1[0].date.split("-").reverse().join("-");
          const ymdDate2 = arr2[0].date.split("-").reverse().join("-");
          if (ymdDate1 < ymdDate2) {
            return 1;
          } else if (ymdDate1 > ymdDate2) {
            return -1;
          }
          return 0;
        });
        console.log(sortedOnDate);
        setData(sortedOnDate);
      });
  }, []);

  return (
    <>
      <div className="table-cover">
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead style={{ borderBottom: "5px solid #5c0048" }}>
            <tr
              className="gradiantblur"
              style={{
                background:
                  " 0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11);",
              }}
            >
              <th style={styles.th}>Date</th>
              <th style={styles.th}>Exercises</th>
              <th style={styles.th}>Calories</th>
            </tr>
          </thead>

          <tbody>
            {data.map((ele) => {
              console.log(ele);
              return (
                <tr
                  className="gradiantblur"
                  style={{
                    background:
                      " 0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11);",
                  }}
                  key={ele.id}
                >
                  <td style={styles.tdFirst}> {ele[0].date}</td>
                  <td style={styles.td}>
                    {ele.map((ele) => {
                      return <div>{ele.exercise}</div>;
                    })}
                  </td>
                  <td style={styles.td}>
                    {ele.map((ele) => {
                      return <div>{ele.value}</div>;
                    })}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}

const styles = {
  th: {
    fontSize: "17px",
    whiteSpace: "nowrap",
    padding: "5px 10px",
    margin: "10px 0px",
  },

  td: {
    borderLeft: "1px solid #fff",
    padding: "5px 0px",
    fontSize: "12px",
  },

  tdFirst: {
    padding: "5px 10px",
    fontSize: "12px",
  },
};
