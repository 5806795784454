import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Approvedoctor from "./admin/approve_doctors/approve_doctor_overview";
import ClientEdit from "./admin/clients/edit_client_overview";
import ClientsalesEdit from "./csteam/editprofile/editclientoverview";
import Consultantsoverview from "./admin/consultants/consultants_overview";
import Seedoctor from "./admin/doctor/see_doctor_overview";
import Hospitaloverview from "./admin/hospital/hospital_overview";
import LearningModule from "./admin/learing_module/learning_module";
import AdminLogin from "./admin/login/admin_login";
import Allcalls from "./admin/calllist/call_vierview";

//================  Admin
import Admin from "./admin/overview";

import Banner from "./admin/banner/banner_overview";
import Paid from "./admin/paidclinets/paid_overview";
import Saleslist from "./admin/saleslist/sales_overview";
import Salesviewlist from "./admin/salesviewlist/salesview_overview";
import Appointlist from "./admin/Appointlist/Appointlist_overview";
import Subscription from "./admin/subscription/subscription_overview";
import Tracker from "./admin/tracker/tracker_overview";
import UpdateTracker from "./admin/update_daily_tracker/daily_tracker_overview";
import EditTraker from "./admin/edittraker/edittraker_overview";
import Appointmentadmin from "./admin/appointments/apoointments_overview";
import Viewbanners from "./admin/banner/banner_viewoverview";
import Viewvideo from "./admin/banner/bannervideo_view";

import CAppointment from "./client/appointments/appointment_overview";
import ContentAdmin from "./admin/freecontent/freeconetnt";
import Calllist from "./admin/components/calllist";
import Docclient from "./admin/doctor/docclient";
//Main Users--
import Client from "./client/client_overview";
import Forgot from "./client/forgotpassword/forgot";
//========== Doctor ======
import InnerDetail from "./client/doctor/searchdetail.js";
import ClientDoctor from "./client/doctor/see_doctor_overview";
import ClientDoctors from "./client/doctor/seelist_doctor_overview";
import Analtics from "./doctor/analatics/twoanalasis";

//  Daily tracker inner pages import
import CActivityOverview from "./client/inner_pages/activity_overview";
import CContractionOverview from "./client/inner_pages/contraction_overview";
import CDietOverview from "./client/inner_pages/diet_overview";
import CExerciseOverview from "./client/inner_pages/exercise_overview";
import CMedicalOverview from "./client/inner_pages/medical_overview";
import CMedicineOverview from "./client/inner_pages/medicine_overview";
import CSymptomsOverview from "./client/inner_pages/symptoms_overview";
//import components for learning portal pages
import LdeliveryOverview from "./client/learning_inner_pages/learning_delivery_overview";
import LdietOverview from "./client/learning_inner_pages/learning_diet_overview";
import LlocationOverview from "./client/learning_inner_pages/learning_location_overview";
import LnaturalOverview from "./client/learning_inner_pages/learning_natural_overview";
import LnewbornOverview from "./client/learning_inner_pages/learning_newborn_overview";
import LplanningOverview from "./client/learning_inner_pages/learning_planning_overview";
import Lpregverview from "./client/learning_inner_pages/learning_preg_overview";
import LrelaxOverview from "./client/learning_inner_pages/learning_relax_overview";
import LskillsetOverview from "./client/learning_inner_pages/learning_skillset_overview";
import LstayrtOverview from "./client/learning_inner_pages/learning_stayrt_overview";
import ClientLogin from "./client/login/client_login";
import Download from "./client/mobileapp/downloadapp_overview";
import CPayment from "./client/payments/payments_overview";
import CProfile from "./client/profile/profile_overview";
import CScans from "./client/scans/scans_overview";
import ClientSignup from "./client/signup/client_signup";
import Support from "./client/support/support_overview";
import Directchat from "./client/support/diectchat_overview";
import Content from "./client/components/freecontent/freecontent_overview";

import CTotalAlltimeClients from "./consultant/clients/total_alltime_clients_overview";
import CTotalClients from "./consultant/clients/total_clients_overview";
import Inintail from "./csteam/initailfolow/inintalfolow_overview";
//============= Consultants ================//
import Consultant from "./consultant/consultant_overview";
import ClientsUpdate from "./csteam/client_updates/client_update_overview";
import Consultantmessgae from "./consultant/meesnger/messagerOverview";
import consultantanalatics from "./consultant/defaultanalatics/defaultconsulatnt";

import Paiduser from "./consultant/paideusers/paidusers_overview";
import NoPaid from "./admin/nopayclient/nopayoverview";

//============= Sales team ================//
import Analais from "./csteam/analatics/analatics_graph/analasisselect";
import Appointlistsale from "./csteam/Appointlist/Appointlist_overview";
import Appointmentsale from "./csteam/appointments/apoointments_overview";
import salesanalatics from "./csteam/analatics/analatics_graph/analaticsgraph_overview";
import Defaultsalesantanalatics from "./csteam/defaultanalatics/defaultanalaticsoverview";
import Csteam from "./csteam/csteam_overview";
import CliMedcalrecordsentDoctors from "./csteam/medicalrecords/medicalOverview";
import Cliothersymptoms from "./csteam/othersymtoms/medicalOverview";
import callresponse from "./csteam/callresponse/callreponseoverview";
// import Cstotalclints from "./csteam/totalclinets/totalclints_overview";
import CsteamLearn from "./csteam/learning/learnit_overview";
import Updatetraker_over from "./csteam/updatetrakerdate/updatetraker_overview";
import DoctorAllClients from "./doctor/all_clients/all_clients_overview";
import Dappointments from "./doctor/appointments/appointment_overview";
import DoctorTodayAppointment from "./doctor/appointments/today_appointmet_overview";
import Dapproval from "./doctor/approval/approval_overview";
import Csteammonthly from "./csteam/monthlyclints/montlyclints_overview";
import Doctor from "./doctor/doctor_overview";
import DoctorLogin from "./doctor/login/doc_login";
import Dprofile from "./doctor/profile/profile_overview";
import DoctorSignup from "./doctor/signup/doc_signup";
import Dconsult from "./doctorcon/doctor_overview";
import callststus from "./doctor/callststus/callststusoverview";
import Criticality from "./doctor/analatics/analatics_graph/cricalityOverview";
import Salescriticality from "./csteam/analatics/analatics_graph/cricalityOverview";
import consultantcriticality from "./consultant/analatics/analatics_graph/cricalityOverview";
import analaticsdaily from "./consultant/analatics/analatics_graph/analaticsgraph_overview";
import ConAnalaticsGraph from "./consultant/analatics/analatics_graph/analaticsgraph_overview";
import SalsesAnalaticsGraph from "./csteam/analatics/analatics_graph/analaticsgraph_overview";

import AnalaticsGraph from "./doctor/analatics/analatics_graph/analaticsgraph_overview";
import defaultAnalaticsGraph from "./doctor/defaultanalatics/defaultanalaticsoverview";

import HmanagerClients from "./hospitalman/clients/hmanager_clients_overview";
import HmanagerAllClients from "./hospitalman/clients/hmanager_Allclients_overview";
import HmDailyTrackerOverview from "./hospitalman/dailytracker/hmanager_daily_tracker_overview";
import HmanagerHospital from "./hospitalman/doctors/hmanager_doctor_overview";
import HmDoctorProfile from "./hospitalman/doctor_profile/hmanager_doctor_profile_overview";
import Hmsignup from "./hospitalman/clientsign/signupoverview";
import HMpayment from "./hospitalman/pricing/pricing";

import Reglist from "./hospitalman/registedclients/registeroverview";
//================= Hospital manager import   =====================//
import Hmanager from "./hospitalman/hmanager_overview";
import Hmpay from "./hospitalman/pricing/hmpay";
import Privacy from "./client/policy/newprivacy";
import Terms from "./client/policy/terms_condition";

import Pricing from "./client/pricing/newpay";
import LearningPortal from "./client/components/learning_portal";
import LearningPortalnew from "./client/components/lerningwith";
import DailyTrakernew from "./client/components/dailytrakernew";
import Message from "./client/meesnger/messagerOverview";
import Docmessenger from "./doctor/meesnger/messagerOverview";
import Salesmessages from "./csteam/meesnger/messagerOverview";
import Adddoctor from "./hospitalman/doc/adddoc_oveview";
//====================================clinet imports =====================//
import Yourdoc from "./client/doctor/yourdoc/yourdoctor";
import Appointment from "./client/components/appointment_tabs";
//===================================dad=================================//
import Dad from "./dad/dad_overview";
import DadSignup from "./dad/signup/dad_signup";
import Remadies from "./dad/timeline/remadies/remadiesoverview";
//------------------------------------------post------------------------//
import Postlearning from "./postdel/post_learning";
import PostDash from "./postdel/post_dashbord";
import PostDeliveryRecovery from "./postdel/PostDelivery/PostdeliveryRecovery_overview.js";
import Postdailytraker from "./postdel/postdailytraker/postdailytraker_overview.js";
import PostLdietOverview from "./postdel/learning/postlearing_overview";
//------------------------------------------postdoc------------------------//
import Postdoc from "./postdoc/postdoc_overview";
import Sysgraph from "./doctor/graph/graphoverview";
import Initaledit from "./client/intailedit/initaledit";
import Pageanalasis from "./consultant/analatics/analatics_graph/critical_overview";
import Babykick from "./client/components/babykickcount/babykick_add";
import Landingpage from "./landingpage/landingpage";
import Customized_diet from "./client/components/innercom/customized_diet";
import Loginpostnatal from "./postdel/authentication/login";
import Clientsignuppost from "./postdel/authentication/client_signup";
import Diettrackeroverview from "./postdel/dailydiettracker/diettrackeroverview.js";
import Medicineoverview from "./postdel/postdailytraker/medicine/medical_overview.js";
import Brestfeedingoverview from "./postdel/postdailytraker/brestfeeding/brestfeeding_overiview.js";
import Vaccinationoverview from "./postdel/postdailytraker/vaccination/vac_overview.js";
import Brain_navigation from "./postdel/postdailytraker/baby_brain/brain_nav.js";
import Brain_overview2 from "./postdel/postdailytraker/baby_brain/brain_overview2.js";
import Profileoverview from "./postdel/profile/profile_overview.js";
import DoctorLoginpost from "./postdoc/signup/doc_signup.js";
import ApprovalOverviewpost from "./postdoc/components/approval_overview.js";
import AppointmentOverviewpost from "./postdoc/components/appointment_overview.js";
import AppointmentOverviewpostdoc from "./postdoc/components/today_appointmet_overview.js";
import ClientsAllOverview from "./postdoc/components/all_clients/all_clients_overview.js";
import AppointmentOverviewclient from "./postdel/components/appointment_overview.js";
import DoctorOverviewpost from "./postdoc/components/profile/profile_overview.js";
import Overviewdocpost from "./postdel/doctor/see_doctor_overview.js";
import Overviewdocpostnatal from "./postdel/doctor/seelist_doctor_overview.js";
import Overviewdocpostdoc from "./postdel/doctor/searchdetail.js";
import Mydoctor from "./postdel/doctor/yourdoc/yourdoctor.js";
import Overview from "./postdel/csteam/csteam_overview.js";
import Overviewdocpostnataladmin from "./postdel/admin/doctor/see_doctor_overview.js";

// post admin
import Overviewadminpost from "./postdel/admin/overview.js";
import Overviewdoc from "./postdel/admin/doctor/see_doctor_overview.js";
import Overviewconpost from "./postdel/consultant/consultant_overview.js";
import Overviewconpaid from "./postdel/consultant/paideusers/paidusers_overview.js";
import Overviewdocclient from "./postdel/admin/doctor/docclient.js";
import Overviewsales from "./postdel/admin/saleslist/sales_overview.js";
import Overviewdocsales from "./postdel/admin/salesviewlist/salesview_overview.js";
import Overviewconpostnatal from "./postdel/admin/consultants/consultants_overview.js";
import Approve_doc from "./postdel/admin/approve_doctors/approve_doctor_overview.js";
import Overviewadminpaid_post from "./postdel/admin/paidclinets/paid_overview.js";
import Overviewnopay from "./postdel/admin/nopayclient/nopayoverview.js";
import Overviewpostlearning from "./postdel/admin/learing_module/learning_module.js";
import Overview_client from "./postdel/admin/clients/edit_client_overview.js";
import Banner_overview from "./postdel/admin/banner/banner_overview.js";
import Subs_post from "./postdel/admin/subscription/subscription_overview.js";
import Course from "./client/components/freecontent copy/course_overview.js";
import Plan_list from "./client/components/freecontent copy/plan_list.js";

class Navigator extends React.Component {
  render() {
    return (
      <Router>
        <div>
          <Switch>
            <Route exact path="/">
              <Landingpage />
            </Route>
            <Route exact path="/login">
              <ClientLogin />
            </Route>
            {/*/======================== Admin ==========================//*/}
            <Route exact path="/admin">
              <Admin />
            </Route>
            <Route exact path="/admin/allclients">
              <Admin />
            </Route>
            <Route
              path="/admin/client/:id"
              render={(props) => <ClientEdit id={props.match.params.id} />}
            />
            <Route
              path="/sales/client/:id"
              render={(props) => <ClientsalesEdit id={props.match.params.id} />}
            />
            <Route exact path="/admin/sales">
              <Saleslist />
            </Route>
            <Route
              path="/admin/salesview/:id"
              render={(props) => <Salesviewlist id={props.match.params.id} />}
            />
            <Route
              path="/admin/appointlist/:id"
              render={(props) => <Appointlist id={props.match.params.id} />}
            />
            <Route
              path="/admin/calllist/:id"
              render={(props) => <Calllist id={props.match.params.id} />}
            />
            <Route path="/admin/doctor">
              <Seedoctor />
            </Route>
            <Route path="/admin/paid">
              <Paid />
            </Route>
            <Route path="/admin/nopaid">
              <NoPaid />
            </Route>
            <Route path="/admin/edit">
              <EditTraker />
            </Route>
            <Route path="/admin/consultants">
              <Consultantsoverview />
            </Route>
            <Route path="/forget">
              <Forgot />
            </Route>
            <Route path="/admin/hospital">
              <Hospitaloverview />
            </Route>
            <Route path="/admin/approve_doctor">
              <Approvedoctor />
            </Route>
            <Route path="/admin/learning_module">
              <LearningModule />
            </Route>
            <Route
              path="/admin/user_tracker/:id"
              render={(props) => <Tracker id={props.match.params.id} />}
            />
            <Route path="/admin/daily_tracker">
              <UpdateTracker />
            </Route>
            <Route path="/admin/subscription">
              <Subscription />
            </Route>
            <Route path="/admin/login">
              <AdminLogin />
            </Route>
            <Route path="/admin/appointment">
              <Appointmentadmin />
            </Route>
            <Route path="/admin/Banner">
              <Banner />
            </Route>
            <Route path="/admin/content">
              <ContentAdmin />
            </Route>
            <Route path="/admin/allcalls">
              <Allcalls />
            </Route>
            {/* -------------------- main users ------------------ */}
            <Route path="/client">
              <Client />
            </Route>
            <Router path="/diet-date/:date">
              <Customized_diet />
            </Router>
            <Route path="/intailedit">
              <Initaledit />
            </Route>
            <Route path="/cdoctor">
              <ClientDoctor />
            </Route>
            <Route path="/clogin">
              <ClientLogin />
            </Route>
            <Route path="/download">
              <Download />
            </Route>
            <Route path="/csignup">
              <ClientSignup />
            </Route>
            <Route path="/cprofile">
              <CProfile />
            </Route>
            <Route path="/cappointment">
              <CAppointment />
            </Route>
            <Route path="/cpayment">
              <CPayment />
            </Route>
            <Route path="/cscan">
              <CScans />
            </Route>
            <Route path="/content">
              <Content />
            </Route>
            <Route path="/addkick">
              <Babykick />
            </Route>
            {/* ==========clients daily tracker inner pages =========   */}
            <Route path="/cactivity">
              <CActivityOverview />
            </Route>
            <Route path="/learningportal">
              <LearningPortalnew />
            </Route>
            <Route path="/dailytraker">
              <DailyTrakernew />
            </Route>
            <Route path="/privacy">
              <Privacy />
            </Route>
            <Route path="/terms">
              <Terms />
            </Route>
            Terms
            <Route path="/ccontraction">
              <CContractionOverview />
            </Route>
            <Route path="/Sysgraph/:id" component={Sysgraph} />
            <Route path="/cexercise">
              <CExerciseOverview />
            </Route>
            <Route path="/cmedical">
              <CMedicalOverview />
            </Route>
            <Route path="/cmedicine">
              <CMedicineOverview />
            </Route>
            <Route path="/csymptoms">
              <CSymptomsOverview />
            </Route>
            <Route path="/cdiet">
              <CDietOverview />
            </Route>
            <Route path="/price">
              <Pricing />
            </Route>
            {/* ==========clients learning portal pages =========   */}
            <Route path="/ldelivery">
              <LdeliveryOverview />
            </Route>
            <Route path="/ldiet">
              <LdietOverview />
            </Route>
            <Route path="/llocation">
              <LlocationOverview />
            </Route>
            <Route path="/lnatural">
              <LnaturalOverview />
            </Route>
            <Route path="/lnewborn">
              <LnewbornOverview />
            </Route>
            <Route path="/lplanning">
              <LplanningOverview />
            </Route>
            <Route path="/lpreg">
              <Lpregverview />
            </Route>
            <Route path="/lrelax">
              <LrelaxOverview />
            </Route>
            <Route path="/lskillset">
              <LskillsetOverview />
            </Route>
            <Route path="/lstayrt">
              <LstayrtOverview />
            </Route>
            <Route path="/meassger">
              <Message />
            </Route>
            {/* <Route path="/directchat">
              <Directchat />
            </Route> */}
            <Route path="/directchat/:id" component={Directchat} />
            <Route path="/yourdoc">
              <Yourdoc />
            </Route>
            <Route path="/support">
              <Support />
            </Route>
            {/* /=================== Doctor ====================////*/}
            <Route path="/cdoctors/:id" component={ClientDoctors} />
            <Route path="/detiled/:id" component={InnerDetail} />
            <Route path="/payment/:id" component={HMpayment} />
            <Route path="/inital/:id" component={Inintail} />
            <Route path="/analatics/:id" component={AnalaticsGraph} />
            <Route path="/analaticsdaily/:id" component={analaticsdaily} />
            <Route
              path="/defaultanalatics/:id"
              component={defaultAnalaticsGraph}
            />
            <Route path="/salesanalatics/:id" component={salesanalatics} />
            <Route
              path="/consultantanalatics/:id"
              component={consultantanalatics}
            />
            <Route path="/criticality/:id" component={Criticality} />
            <Route path="/callstatus/:id" component={callststus} />
            <Route
              path="/consultantcriticality/:id"
              component={consultantcriticality}
            />
            <Route path="/callresponse/:id" component={callresponse} />
            {/* <Route path="/:catId">
              render={(props) => <InnerDetail />
            </Route> */}
            <Route path="/doctor">
              <Doctor />
            </Route>
            <Route path="/dprofile">
              <Dprofile />
            </Route>
            <Route path="/dapproval">
              <Dapproval />
            </Route>
            <Route path="/dappointment">
              <Dappointments />
            </Route>
            <Route path="/dappointment_today">
              <DoctorTodayAppointment />
            </Route>
            <Route path="/dall_clients">
              <DoctorAllClients />
            </Route>
            <Route path="/doc_signup">
              <DoctorSignup />
            </Route>
            <Route path="/dad_signup">
              <DadSignup />
            </Route>
            <Route path="/doc_login">
              <DoctorLogin />
            </Route>
            <Route path="/docmessenger">
              <Docmessenger />
            </Route>
            {/* <Route path="/allcalls">
              <Allcalls />
            </Route> */}
            <Route path="/Analtics/:id" component={Analtics} />
            {/* ================  Consultants    ===================== */}
            <Route path="/critical/:id" component={Pageanalasis} />
            <Route path="/paid_users">
              <Paiduser />
              {/* CLIENTS of all time */}
            </Route>
            <Route path="/conanalatics/:id" component={ConAnalaticsGraph} />
            <Route
              path="/defaultsalesantanalatics/:id"
              component={Defaultsalesantanalatics}
            />
            <Route
              path="/salesantanalatics/:id"
              component={SalsesAnalaticsGraph}
            />
            <Route path="/consultant">
              <Consultant />
              {/* CLIENTS of all time */}
            </Route>
            <Route path="/ctmessages">
              <Consultantmessgae />
              {/* CLIENTS of all time */}
            </Route>
            <Route path="/con_this_month_clients">
              <CTotalAlltimeClients />
              {/* CLIENTS THIS MONth */}
            </Route>
            {/*  ============== sales team  ============       */}
            <Route path="/salesanalasis/:id" component={Analais} />
            {/* <Route path="/salesanalasis">
              <Analais />
            </Route> */}
            <Route
              path="/sale/appointlist/:id"
              render={(props) => <Appointlistsale id={props.match.params.id} />}
            />
            <Route path="/sales/appointments">
              <Appointmentsale />
            </Route>
            <Route path="/salescriticality/:id" component={Salescriticality} />
            <Route path="/csteam">
              <Csteam />
            </Route>
            <Route path="/salesmessages">
              <Salesmessages />
            </Route>
            <Route path="/cslearn">
              <CsteamLearn />
            </Route>
            <Route path="/monthlyoverview">
              <Csteammonthly />
            </Route>
            <Route path="/cs_client_update">
              <ClientsUpdate />
            </Route>
            <Route path="/update_traker">
              <Updatetraker_over />
            </Route>
            {/*======    =========*/}
            <Route path="/dconsult">
              <Dconsult />
            </Route>
            <Route
              path="/medcalrecords/:id"
              component={CliMedcalrecordsentDoctors}
            />
            <Route path="/docclients/:id" component={Docclient} />
            <Route path="/othersymptoms/:id" component={Cliothersymptoms} />
            {/* =======  hospital manager    ========= */}
            <Route path="/hmanager">
              <Hmanager />
            </Route>
            <Route path="/hmhospitals">
              <HmanagerHospital />
            </Route>
            {/* <Route path="/Hmpay">
              <Hmpay />
            </Route> */}
            <Route
              path="/Hmpay/:id"
              render={(props) => <Hmpay id={props.match.params.id} />}
            />
            <Route path="/reglist">
              <Reglist />
            </Route>
            <Route
              path="/hmclients/:id"
              render={(props) => <HmanagerClients id={props.match.params.id} />}
            />
            <Route
              path="/hmAllclients/"
              render={(props) => (
                <HmanagerAllClients id={props.match.params.id} />
              )}
            />
            <Route
              path="/hmdprofile/:id"
              render={(props) => <HmDoctorProfile id={props.match.params.id} />}
            />
            <Route path="/hmdaily_tracker">
              <HmDailyTrackerOverview />
            </Route>
            <Route path="/hmsignup">
              <Hmsignup />
            </Route>
            <Route path="/home">
              <Home />
            </Route>
            <Route path="/adddoctor">
              <Adddoctor />
            </Route>
            {/* ===============dad============ */}
            <Route path="/dad">
              <Dad />
            </Route>
            <Route path="/viewbanners">
              <Viewbanners />
            </Route>
            <Route path="/viewvideo">
              <Viewvideo />
            </Route>
            <Route path="/remadies">
              <Remadies />
            </Route>
            {/* =====================postnatal========= */}
            <Router path="/loginpostnatal">
              <Loginpostnatal />
            </Router>
            <Router path="/clientsignuppost">
              <Clientsignuppost />
            </Router>
            <Route path="/postdash">
              <PostDash />
            </Route>
            <Route path="/postlearning">
              <Postlearning />
            </Route>
            <Route path="/PostDeliveryRecovery">
              <PostDeliveryRecovery />
            </Route>
            <Route path="/postdailytraker">
              <Postdailytraker />
            </Route>
            <Route path="/postldiet">
              <PostLdietOverview />
            </Route>
            <Route path="/diettrackeroverview">
              <Diettrackeroverview />
            </Route>
            <Route path="/postmedicine">
              <Medicineoverview />
            </Route>
            <Route path="/postdoc">
              <Postdoc />
            </Route>
            <Route path="/brestfeedingoverview">
              <Brestfeedingoverview />
            </Route>
            <Route path="/vaccination">
              <Vaccinationoverview />
            </Route>
            <Route path="/Brainnav">
              <Brain_navigation />
            </Route>
            <Route
              path="/brainoverview/:id"
              render={(props) => <Brain_overview2 id={props.match.params.id} />}
            />
            <Route path="/profileoverview">
              <Profileoverview />
            </Route>
            <Route
              path="/hmclients/:id"
              render={(props) => <HmanagerClients id={props.match.params.id} />}
            />
            {/* ______________doctor__________________ */}
            <Route path="/doc_signup_post">
              <DoctorLoginpost />
            </Route>
            <Route path="/dapprovalpost">
              <ApprovalOverviewpost />
            </Route>
            <Route path="/dappointment_todaypost">
              <AppointmentOverviewpostdoc />
            </Route>
            <Route path="/appoinment">
              <AppointmentOverviewpost />
            </Route>
            <Route path="/allclientspost">
              <ClientsAllOverview />
            </Route>
            <Route path="/appointmentpost">
              <AppointmentOverviewclient />
            </Route>
            <Route path="/dprofilepost">
              <DoctorOverviewpost />
            </Route>
            <Route path="/postdoctor">
              <Overviewdocpost />
            </Route>
            <Route path="/cdoctorspost/:id" component={Overviewdocpostnatal} />
            <Route path="/detile/:id" component={Overviewdocpostdoc} />
            <Route path="/mydoct">
              <Mydoctor />
            </Route>
            <Route path="/postsales">
              <Overview />
            </Route>
            <Route path="/paiduserpost">
              <Overviewconpaid />
            </Route>
            {/* Admin of postnatal */}
            <Route path="/admin_overview">
              <Overviewadminpost />
            </Route>
            <Route path="/admin_docoverview">
              <Overviewdoc />
            </Route>
            <Route path="/doc_details/:id">
              <Overviewdocclient />
            </Route>
            <Route path="/sales_list_admin">
              <Overviewsales />
            </Route>
            <Route
              path="/post_salesview/:id"
              render={(props) => (
                <Overviewdocsales id={props.match.params.id} />
              )}
            ></Route>
            <Route path="/post_consultants">
              <Overviewconpostnatal />
            </Route>
            <Route path="/admin/post_doctor">
              <Overviewdocpostnataladmin />
            </Route>
            <Route path="/admin/post_approve_doc">
              <Approve_doc />
            </Route>
            <Route path="/admin/post_allclient">
              <Overviewadminpost />
            </Route>
            <Route path="/admin/paidclient">
              <Overviewadminpaid_post />
            </Route>
            <Route path="/admin/freeclient">
              <Overviewnopay />
            </Route>
            <Route path="/admin/learning_module_post">
              <Overviewpostlearning />
            </Route>
            <Route path="/conspost_overview">
              <Overviewconpost />
            </Route>
            <Route
              path="/admin/client_post/:id"
              render={(props) => <Overview_client />}
            />
            <Route path="/admin/Banner_post">
              <Banner_overview />
            </Route>
            <Route path="/subscription_post">
              <Subs_post/>
            </Route>
            
            <Route path="/course/:id">
              <Course/>
            </Route>
            <Route path="/plan_list">
              <Plan_list/>
            </Route>

          </Switch>
        </div>
      </Router>
    );
  }
}

function Home() {
  return (
    <div style={{ paddingTop: "20%", textAlign: "center" }}>
      <h1>Home</h1>
    </div>
  );
}

// function Pagenotfound(){
// return (
// <h1>Page Not found</h1>

// );
// }

export default Navigator;
