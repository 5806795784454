import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
export default function MedicineTracker(props) {
  const [medicinexc, setmedicine] = useState([]);
  const [medicine2, setmedicine2] = useState([]);
  const [medicine3, setmedicine3] = useState([]);
  const [medicine4, setmedicine4] = useState([]);
  const [medicine5, setmedicine5] = useState([]);
  const [medicine6, setmedicine6] = useState([]);
  var pikerdate = props.dataFromParent;
  console.log(medicinexc);
  useEffect(() => {
    if (localStorage.getItem("token") === null) {
      localStorage.clear();
      window.location = "/clogin";
    }
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("token"),
      },
    };
    //remove this date after setting up the admin pannel

    axios
      .get(
        "customer/medicine-GET/?date=" + pikerdate,
        config

        // config
      )
      .then((res) => {
        const morningbefore = res.data.find(
          (o) => o.MedicationTime === "morning before food"
        );
        const morningafter = res.data.find(
          (o) => o.MedicationTime === "morning after food"
        );

        const afternoonbefore = res.data.find(
          (o) => o.MedicationTime === "afternoon before food"
        );

        const afternoonafter = res.data.find(
          (o) => o.MedicationTime === "afternoon after food"
        );

        const nightafter = res.data.find(
          (o) => o.MedicationTime === "night after food"
        );

        const nightbefore = res.data.find(
          (o) => o.MedicationTime === "night before food"
        );
        ////////////////////..........................................................................
        let filteredData = [];
        console.log(morningbefore.Medicines);
        morningbefore.Medicines.forEach((e) => {
          if (e.taken === true) filteredData.push(e);
        });
        setmedicine(filteredData);
        ////////////////////...............................................................
        let filteredData2 = [];

        morningafter.Medicines.forEach((e) => {
          if (e.taken === true) filteredData2.push(e);
        });
        setmedicine2(filteredData2);
        ////////////////////...............................................................
        let filteredData3 = [];

        afternoonbefore.Medicines.forEach((e) => {
          if (e.taken === true) filteredData3.push(e);
        });
        setmedicine3(filteredData3);
        ////////////////////...............................................................
        let filteredData4 = [];

        afternoonafter.Medicines.forEach((e) => {
          if (e.taken === true) filteredData4.push(e);
        });
        setmedicine4(filteredData4);
        ////////////////////...............................................................
        let filteredData5 = [];

        nightafter.Medicines.forEach((e) => {
          if (e.taken === true) filteredData5.push(e);
        });
        setmedicine5(filteredData5);
        ////////////////////...............................................................
        let filteredData6 = [];

        nightbefore.Medicines.forEach((e) => {
          if (e.taken === true) filteredData6.push(e);
        });
        setmedicine6(filteredData6);
        // let obj = res.data.Diet.find((o) => o.mealName === "lunch");
        // let breakfast = res.data.Diet.find((o) => o.mealName === "breakfast");
        // let earlymorning1 = res.data.Diet.find(
        //   (o) => o.mealName === "early morning"
        // );
        // let dinner = res.data.Diet.find((o) => o.mealName === "dinner");
        // let afternoonsnack = res.data.Diet.find(
        //   (o) => o.mealName === "afternoon snack"
        // );
        // let middaysnack = res.data.Diet.find(
        //   (o) => o.mealName === "mid day snack"
        // );
        // let dinnerdrink = res.data.Diet.find(
        //   (o) => o.mealName === "dinner drink"
        // );

        // setearlymoring(earlymorning1);
        // setDinner(dinner);
        // setafternoon(afternoonsnack);
        // setmidday(middaysnack);
        // setdinnerdrink(dinnerdrink);
        // //  setlunch(res.data.Diet[6].food);
        // setbreakfast(breakfast);
        // setlunch(obj);
        // setlunchtime(res.data.Diet[6].time);
      });
  }, []);

  return (
    <div className="scrollerbar">
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead style={{ borderBottom: "5px solid #5c0048" }}>
          <tr style={{ background: "#ffffff1c 0% 0% no-repeat padding-box" }}>
            <th style={styles.th}>Date</th>
            <th style={styles.th}>
              Morning Before
              <br /> Food
            </th>
            <th style={styles.th}>
              Morning after
              <br /> Food
            </th>
            <th style={styles.th}>
              Afternoon Before
              <br /> Food
            </th>
            <th style={styles.th}>
              Afternoon After
              <br /> Food
            </th>
            <th style={styles.th}>
              Night Before
              <br /> Food
            </th>
            <th style={styles.th}>
              Night After
              <br /> Food
            </th>
          </tr>
        </thead>

        <tbody>
          <tr style={{ background: "#ffffff1c 0% 0% no-repeat padding-box" }}>
            <td style={styles.tdFirst}>{pikerdate}</td>
            <td style={styles.td}>
              {medicinexc.map((personData, index) => {
                return <div key={index}>{personData.name}</div>;
              })}
            </td>

            <td style={styles.td}>
              {medicine2.map((personData, index) => {
                return <div key={index}>{personData.name}</div>;
              })}
            </td>
            <td style={styles.td}>
              {medicine3.map((personData, index) => {
                return <div key={index}>{personData.name}</div>;
              })}
            </td>
            <td style={styles.td}>
              {medicine4.map((personData, index) => {
                return <div key={index}>{personData.name}</div>;
              })}
            </td>
            <td style={styles.td}>
              {medicine6.map((personData, index) => {
                return <div key={index}>{personData.name}</div>;
              })}
            </td>
            <td style={styles.td}>
              {medicine5.map((personData, index) => {
                return <div key={index}>{personData.name}</div>;
              })}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

const styles = {
  th: {
    fontSize: "17px",
    color: "black",
    whiteSpace: "nowrap",
    padding: "5px 10px",

    margin: "10px 0px",
  },

  td: {
    color: "black",
    // borderLeft: "1px solid #fff",
    padding: "5px 0px",
    border: "1px solid black",
    fontSize: "12px",
  },

  tdFirst: {
    color: "black",
    padding: "5px 10px",
    border: "1px solid black",
    fontSize: "12px",
  },
};
