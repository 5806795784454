import { Link } from "react-router-dom";
import axios from "axios";
import showPwdImg from "./show-password.svg";
import hidePwdImg from "./hide-password.svg";
import { useForm } from "react-hook-form";
import { useState } from "react";
export default function Form(props) {
  const [allValues, setAllValues] = useState([]);
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [isRevealPwd2, setIsRevealPwd2] = useState(false);
  //   const {
  //     register,
  //     handleSubmit,
  //     formState: { errors },
  //   } = useForm();
  const handleChange = (e) => {
    setAllValues({ ...allValues, [e.target.name]: e.target.value });
  };
  const submitValue = (e) => {
    // e.preventDefault();
    // props.state.patient = "true";
    // const headers = {
    //   headers: {
    //     "Api-Key": `g7eWwFm9.va3HxJiPD1OzMs5I1UntS2gAwXj99Kuq`,
    //   },
    // };

    // const data = props.state;
    axios({
      method: "POST",
      url: "client-registration/",
      //digiatl
      headers: { "Api-Key": `aU60ViOC.02hdAGZ0gy4avx0oBCkhErSJK55anMuC` },
      //heroku
      // headers: { "Api-Key": `g7eWwFm9.va3HxJiPD1OzMs5I1UntS2gAwXj99Kuq` },
      data: allValues,
    })
      // axios
      //   .post("client-registration/", allValues, {
      //     headers: headers,
      //   })
      .then(() => {
        alert("updated data");
        window.location = "/hmpay";
      })
      .catch((error) => {
        //var my_obj_str = JSON.stringify(error.response.data);
        alert(JSON.stringify(error.response.data));
      });
  };

  //currentStep = 3;
  //   nextButton = () => {
  //     let currentStep = this.state.currentStep;
  //     if (currentStep < 3) {
  //       return (
  //         <button
  //           className="btn btn-primary float-right"
  //           type="button"
  //           //  onClick={this._next}
  //         >
  //           Next
  //         </button>
  //       );
  //     }
  //     return null;
  //   };

  //   {
  //     console.log(props.state);
  //   }
  return (
    <>
      <div
        className="innerclintsignup"
        style={{
          display: "flex",
          flexDirection: "column",
          color: "#fff",
          margin: "auto",
          padding: "20px",
          width: "40%",
          borderRadius: "20px",
          boxShadow: "12px 10px 60px #00000029",
          backdropFilter: "blur(39px)",
          WebkitBackdropFilter: "blur(39px)",
          background: "#00000029",
        }}
      >
        <p>
          <small>
            Are you Pregnant? Kindly spend some time to help us understand you
            better
          </small>
        </p>
        <form
          //   onSubmit={handleSubmit(submitValue)}
          style={{
            display: "flex",
            flexDirection: "column",
            paddingBottom: "22%",
            lineHeight: "70px",
          }}
        >
          <div style={{ display: "flex" }}>
            <input
              name="firstname"
              onChange={handleChange}
              style={styles.finput}
              type="text"
              placeholder="First Name "
            />

            <input
              name="lastname"
              onChange={handleChange}
              style={styles.finput}
              type="text"
              placeholder="Last Name"
            />
          </div>
          <input
            name="age"
            onChange={handleChange}
            style={styles.input}
            type="number"
            placeholder="Age"
          />
          <input
            name="email"
            onChange={handleChange}
            style={styles.input}
            type="email"
            placeholder="Email"
          />
          <input
            // {...register("mobile", { required: true })}
            name="mobile"
            // value={props.getState("mobile")}
            onChange={handleChange}
            style={styles.input}
            type="tel"
            required
            maxlength="12"
            placeholder="Mobile Number"
          />
          <input
            // {...register("address", { required: true })}
            name="address"
            // value={props.getState("address")}
            onChange={handleChange}
            style={styles.input}
            type="text"
            required
            placeholder="Address"
          />
          <input
            // {...register("referalId", { required: true })}
            name="referalId"
            // value={props.getState("referalId")}
            onChange={handleChange}
            style={styles.input}
            type="text"
            placeholder="Doctor Referral Code"
          />

          <div style={{ display: "flex" }}>
            <small style={{ width: "50%", fontSize: "14px" }}>
              Last Menstrual Period Date
            </small>
            <input
              name="Menstruation_date"
              onChange={handleChange}
              style={styles.finput}
              type="date"
              placeholder="Last Menstrual Period Date"
            />
          </div>
          {/* <input
            // {...register("Menstruation_date", { required: true })}
            name="Menstruation_date"
            // value={props.getState("Menstruation_date")}
            onChange={handleChange}
            style={styles.input}
            type="date"
            placeholder="Last Menstrual Period Date"
          /> */}

          <div className="pwd-container">
            <input
              // {...register("password", { required: true })}
              name="password"
              //   /  value={props.getState("password")}
              onChange={handleChange}
              style={styles.input}
              type={isRevealPwd ? "text" : "password"}
              placeholder="Password"
            />
            <img
              title={isRevealPwd ? "Hide password" : "Show password"}
              src={isRevealPwd ? hidePwdImg : showPwdImg}
              onClick={() => setIsRevealPwd((prevState) => !prevState)}
            />
          </div>
          {/* <input
            // {...register("password", { required: true })}
            name="password"
            //   /  value={props.getState("password")}
            onChange={handleChange}
            style={styles.input}
            type="password"
            placeholder="Password"
          /> */}

          <div className="pwd-container">
            <input
              // {...register("password2", { required: true })}
              name="password2"
              //    / value={props.getState("password2")}
              onChange={handleChange}
              style={styles.input}
              type={isRevealPwd2 ? "text" : "password"}
              placeholder="Confirm Password"
            />
            <img
              title={isRevealPwd2 ? "Hide password" : "Show password"}
              src={isRevealPwd2 ? hidePwdImg : showPwdImg}
              onClick={() => setIsRevealPwd2((prevState) => !prevState)}
            />
          </div>
          {/* <input
            // {...register("password2", { required: true })}
            name="password2"
            //    / value={props.getState("password2")}
            onChange={handleChange}
            style={styles.input}
            type="password"
            placeholder="Confirm Password"
          /> */}

          <div
            style={{
              display: "grid",
              // justifyContent: "space-between",
            }}
          >
            {/* {this.nextButton()} */}
            {/* {props.current == "3" ? ( */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {/* <input
                style={{
                  background:
                    "transparent linear-gradient(266deg, #FF006C 0%, #293247 100%) 0% 0% no-repeat padding-box",
                  width: "102px",
                  height: "32px",
                  border: "none",
                  color: "#fff",
                  boxShadow: "0px 9px 10px #00000029",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
                type="primary"
                onClick={props.prev}
                //style={{ marginRight: 10 }}
                value="  Previous"
              /> */}
              <button
                style={{
                  background:
                    "transparent linear-gradient(266deg, #FF006C 0%, #293247 100%) 0% 0% no-repeat padding-box",
                  width: "102px",
                  height: "32px",
                  border: "none",
                  color: "#fff",
                  boxShadow: "0px 9px 10px #00000029",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
                //disabled={!enabled}
                type="button"
                onClick={submitValue}
                //    / value="Finish"
              >
                Finish
              </button>
            </div>
            {/* <small>
              Already have an account?
              <Link to="/clogin">
                <small>
                  <strong>Sign in</strong>
                </small>
              </Link>
            </small>
            <div style={styles.terms}>
              <small>
                <Link to="/privacy">
                  <small> privacy policy</small>
                </Link>
              </small>
              <small>
                <Link to="/terms">
                  <small> terms and conditions</small>
                </Link>
              </small>
            </div> */}
          </div>
        </form>
      </div>
    </>
  );
}

const styles = {
  input: {
    background: "none",
    padding: "10px",

    marginBottom: "5%",
    color: "#fff",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
  },
  danger: {
    color: "red",
  },
  terms: {
    display: "flex",
    justifyContent: "space-around",
  },
  finput: {
    background: "none",
    padding: "10px",
    color: "#fff",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    width: "50%",
  },

  file: {
    background: "none",
    padding: "10px",
    color: "#fff",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    width: "44.8%",
    borderBottom: "2px solid rgb(255, 255, 255)",
  },
};
