import axios from "axios";
import React, { useState, useEffect } from "react";
export default function EditPersonal(props) {
  const { id } = props;

  console.log("ID:", id);
  const [data, setdata] = useState([]);
  const [details, setdetails] = useState([]);
  const [allValues, setAllValues] = useState();
  const [image, setImage] = useState();
  const changeHandler = (e) => {
    setAllValues({ ...allValues, [e.target.name]: e.target.value });
  };

  const FileHandler = (e) => {
    setImage(e.target.files[0]);
    console.log(e.target.files[0]);
  };
  const submitValue = (e) => {
    e.preventDefault();
    const formData = new FormData();

    // Add the customer_id as id
    formData.append("customer_id", props.id);

    if (image !== undefined) {
      formData.append("prescription", image);
    }
    if (allValues !== null) {
      Object.entries(allValues).forEach(([key, value]) =>
        formData.append(key, value)
      );
    }

    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("token"),
      },
    };

    axios
      .patch("profile-update/", formData, config)
      .then(() => {
        alert("updated data");
      })
      .catch((error) => {
        alert(JSON.stringify(error.response));
      });
  };
  useEffect(() => {
    axios
      .get(`customer-profile/`, {
        headers: {
          Authorization: `token ` + localStorage.getItem("token"),
        },
        params: {
          customer: id,
        },
      })
      .then((res) => {
        console.log(res.data);
        setdata(res.data.customer);
        setdetails(res.data.details);
      });
  }, []);
  return (
    <>
      <div style={styles.wrapper}>
        <form className="medical-edit-form salesmedical" style={styles.form}>
          <table>
            <tbody>
              <tr>
                <td>
                  <label>Married Since</label>
                </td>
                <td>
                  <input
                    name="marriedSince"
                    style={styles.date}
                    onChange={changeHandler}
                    value={details.marriedSince}
                    type="date"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <label>How Many Babies Do You Have?</label>
                </td>
                <td>
                  <input
                    name="babies_number"
                    onChange={changeHandler}
                    style={styles.date}
                    type="text"
                    defaultValue={details.babies_number}
                    placeholder=""
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <label>Any History Of Abortions? Yes or No</label>
                </td>
                <td>
                  <select
                    className="medicalselect"
                    onChange={changeHandler}
                    name="abortions"
                    style={styles.date}
                    defaultValue={details.abortions}
                  >
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  <label>Any History of twins in Family? Yes or No</label>
                </td>
                <td>
                  <select
                    className="medicalselect"
                    onChange={changeHandler}
                    name="twins"
                    style={styles.date}
                    defaultValue={details.twins}
                  >
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  <label>Any Family History of Diabetes? Yes or No</label>
                </td>
                <td>
                  <select
                    className="medicalselect"
                    onChange={changeHandler}
                    name="diabetes"
                    style={styles.date}
                    defaultValue={details.diabetes}
                  >
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  <label>
                    Any History Of allergic reaction? If Yes please Specify.
                  </label>
                </td>
                <td>
                  <select
                    className="medicalselect"
                    onChange={changeHandler}
                    name="allergic_reaction"
                    style={styles.date}
                    defaultValue={details.allergic_reaction}
                  >
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  <label>Any History Of Surgery? If Yes, please Specify.</label>
                </td>
                <td>
                  <select
                    className="medicalselect"
                    onChange={changeHandler}
                    name="surgery"
                    style={styles.date}
                    defaultValue={details.surgery}
                  >
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  <label>How many days Menstruation Period cycle</label>
                </td>
                <td>
                  <input
                    onChange={changeHandler}
                    name="Menstruation"
                    style={styles.date}
                    type="number"
                    defaultValue={details.Menstruation}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <label>last Menstruation date</label>
                </td>
                <td>
                  <input
                    name="Menstruation_date"
                    onChange={changeHandler}
                    defaultValue={details.Menstruation_date}
                    style={styles.date}
                    type="date"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <label>Any Hereditary related complications?</label>
                </td>
                <td>
                  <select
                    className="medicalselect"
                    onChange={changeHandler}
                    name="hereditary"
                    style={styles.date}
                    defaultValue={details.hereditary}
                  >
                    <option value="Yes" style={styles.selectOption}>
                      Yes
                    </option>
                    <option value="No" style={styles.selectOption}>
                      No
                    </option>
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  <label>
                    Any History Of Gynecology or Fertility Treatment? If Yes,
                    please Specify.
                  </label>
                </td>
                <td>
                  <select
                    className="medicalselect"
                    onChange={changeHandler}
                    name="gynecology"
                    style={styles.date}
                    defaultValue={details.gynecology}
                  >
                    <option value="Yes" style={styles.selectOption}>
                      Yes
                    </option>
                    <option value="No" style={styles.selectOption}>
                      No
                    </option>
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  <label>
                    How many babies are you pregnant with currently?
                  </label>
                </td>
                <td>
                  <input
                    onChange={changeHandler}
                    name="no_of_babies_pregnant_with"
                    style={styles.date}
                    type="text"
                    defaultValue={details.no_of_babies_pregnant_with}
                    placeholder="How many babies are you pregnant with currently?"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <label>When did you visit the doctor finally?</label>
                </td>
                <td>
                  <input
                    onChange={changeHandler}
                    name="doctor_final_visit"
                    style={styles.date}
                    defaultValue={details.doctor_final_visit}
                    type="date"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <label>
                    Upload the latest prescription report or any blood report
                    that clearly indicates that you are pregnant
                  </label>
                </td>
                <td>
                  <label style={styles.date} htmlFor="file">
                    {details.Idproof ? details.Idproof : "Choose a file"}
                  </label>
                  {/* <input
                    type="file"
                    name="file"
                    onChange={FileHandler}
                    className="inputfile"
                    style={{ background: 'rgb(139 72 143 / 0%)' }}
                  /> */}
                </td>
              </tr>
            </tbody>
          </table>
          <div>
            <input
              onClick={submitValue}
              style={styles.ebtn}
              className="pinkbutton2"
              type="submit"
              value="Update"
            />
          </div>{" "}
          <input
            id="file"
            style={styles.file}
            name="prescription"
            onChange={FileHandler}
            type="file"
            className="inputfile"
          />
        </form>
      </div>
    </>
  );
}

const styles = {
  wrapper: {
    padding: "5px",
  },

  form: {
    marginTop: "5%",
    position: "relative",
    height: "274px",
    // overflowY: "scroll",
    scrollbarWidth: "none",
    // width: "400px",
    display: "flex",
    flexDirection: "column",
    zIndex: 10,
    top: "10rem",
    right: "25rem",

    left: "50%",
    transform: "translate(-50%, -50%)",
    // background: "#ffffff1c 0% 0% no-repeat padding-box",
    padding: "20px",
  },

  date: {
    textAlign: "center",
    border: "none",
    color: "#fff",
    float: "right",

    outline: "none",
    // background: "transparent",
    background:
      "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
  },

  fileP: {
    width: "80%",
    display: "block",
  },
  fileP1: {
    width: "60%",
    display: "block",
  },

  file: {
    border: "none",
    background: "transparent",
    background:
      "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
    float: "right",
    display: "none",
  },

  datehelp: {
    marginBottom: "20px",
    padding: "8px",
    textAlign: "left",
    fontSize: "12px",
    // background: "#530e73",
    color: "#fff",
  },

  filehelp: {
    marginBottom: "20px",
    padding: "8px",
    textAlign: "left",
    fontSize: "12px",
    background: "#530e73",
    color: "#fff",
    display: "flex",
  },

  filehelp1: {
    marginBottom: "20px",
    padding: "8px",

    textAlign: "left",
    fontSize: "12px",
    background: "#530e73",
    color: "#fff",
    display: "flex",
  },

  input: {
    marginBottom: "20px",
    // background: "#530e73",
    background: "rgb(83 14 115 / 39%)",
    color: "#fff",
    padding: "5px",
  },

  ebtn: {
    marginBottom: "2rem",

    // background: "linear-gradient(90deg, #3a51a7 30%, #b53899)",
    padding: "10px 30px",
    border: "none",
    borderRadius: "10px",
    color: "#fff",
    cursor: "pointer",
  },
  input2: {
    marginBottom: "20px",
    // background: "#530e73",
    width: "25%",
    background:
      "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
    color: "#fff",
    padding: "5px",
    borderBottom: "1px solid #fff",
    borderTop: "none",
    borderLeft: "none",
    textAlign: "left",
    fontSize: "14px",
    borderRight: "none",
  },
};
