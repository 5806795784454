import React from 'react';
import Image from '../img/small_image.jpeg';

export default class DoctorProfile extends React.Component{
constructor(props){
   super(props);

   this.state = {isProfile: true};
   this.clickHandler = this.clickHandler.bind(this);
}

clickHandler(){
	this.setState({isProfile: false});
}

render(){

return(
<>
{ this.state.isProfile ?  
<div style={styles.dprofile}>
<div style={styles.titlePlate}>
<strong>Doctor Profile</strong>
</div>

<table>
<tr><td><img src={Image} height="75px" width="75px" style={{ borderRadius:'50%' }} alt=""/><br/>
<span style={styles.money}>$600</span></td><td style={styles.col}><strong style={{ fontSize:'20px' }}>Dr. Avanish</strong><br/>
<div style={{ display:'flex', flexDirection:'column' }}>
<small style={{ padding:'3px 0px', borderBottom:'1px solid #fff' }}>Email : <span>avinash@gmail.com</span></small>
<small style={{ padding:'3px 0px', borderBottom:'1px solid #fff' }}>Password : <span>Abcd123</span></small><br/>
</div>
</td>

<td style={styles.col}>
<select style={{ fontSize:'10px', background:'#b53899', color:'#fff' }}>
<option>Select Hospital Manager</option>
</select><br/>
<div style={{ display:'flex', flexDirection:'column' }}>
<small style={{ padding:'3px 0px', borderBottom:'1px solid #fff' }}>Contact Number : <span>77339393663</span></small>
<small style={{ padding:'3px 0px', borderBottom:'1px solid #fff' }}>Years Of Experience  : <span>11 Yr</span></small>
</div>
</td></tr>
<tr><td></td><td style={styles.co}>Fullname</td><td><input type="text" value="Avanish"/></td></tr>
<tr><td></td><td style={styles.co}>Speciality</td><td><input type="text" value="Dietitian"/></td></tr>
<tr><td></td><td style={styles.co}>Qualification</td><td><input type="text" value="MBBS"/></td></tr>
<tr><td></td><td style={styles.co}>Medical council required?</td><td><input type="text" value="Yes"/></td></tr>
<tr><td></td><td style={styles.co}>Area of interest</td><td><input type="text" value=""/></td></tr>
<tr><td></td><td style={styles.co}>Refrence Code</td><td><input type="text" value="1234"/></td></tr>
<tr><td></td><td style={styles.co}>Hospital/Hospital working</td><td><input type="Apple" value="Avanish"/></td></tr>
<tr><td></td><td style={styles.co}>Place of Work</td><td><input type="text" value="Banglore"/></td></tr>
<tr><td></td><td style={styles.co}>Are you willing to give Online consultation for your Patient?</td><td><input type="text" value="Yes"/></td></tr>
<tr><td></td><td style={styles.col}><button onClick={ this.clickHandler }>Close</button></td><td><input type="submit" value="Update"/></td></tr>
</table>
</div>
: null }
</>
);

}


}

const styles = {
dprofile:{
  position:'absolute',
  background:'rgb(76, 0, 61) none repeat scroll 0% 0%',
  top:'18.5%',
  left:'22.4%',
  width:'41.3rem',
  borderRadius:'10px',
  padding:'10px'
},

titlePlate:{
  padding:'10px',
  color:'#fff'
},
flex:{
	display:'flex',
	justifyContent:'space-around',
	padding:'10px'
},
col:{
	padding:'10px',
	textAlign:'left',
},
co:{
	padding:'10px',
	textAlign:'left',
	border:'1px solid #b53899',
	background:'#b53899'
},
money:{
   color:'#fff'
}



}
