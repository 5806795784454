import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { Carousel } from "react-responsive-carousel";

function Imageslider() {
  const baseurl = "https://sukhprasavam.shebirth.com";
  const [images, setimages] = useState([]);
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("token"),
      },
    };

    axios.get("customer/customer-dashboard-details/", config).then((res) => {
      console.log(res.data);
      //  setWeight(res.data.babyDetails.weigth);
      // setlength(res.data.babyDetails.length);
      setimages(res.data.banners);

      // setDescribtion(res.data.babyDetails.description);
    });
  }, []);
  return (
    <>
      <Carousel autoPlay interval={2500} infiniteLoop={true}>
        {images.map((personData, key) => {
          return (
            <div style={{ padding: "10px" }}>
              <img src={baseurl + personData.image} alt="" />
            </div>
          );
        })}
      </Carousel>
    </>
  );
}

export default Imageslider;
