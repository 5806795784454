import React from 'react';
import Sidebar from '../components/sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faUser, faBars } from '@fortawesome/free-solid-svg-icons';
import Toggler from '../toggle';
/*import Tlist from './components/traker_by_date/tabs';*/

import CLearnit from '../components/learnit';
/*import ClientNoupdates from './components/clients_no_updates'
import TClients from './components/clients';
import AlltimeClients from './components/alltime_clients';*/


export default class LearnitOverview extends React.Component{
constructor(props){
super(props)
this.state = {isToggleOn:true};
this.handleClick = this.handleClick.bind(this);
}


handleClick() {
	    this.setState(prevState => ({
	    isToggleOn: !prevState.isToggleOn
	    }));
	}


render(){
      function openMenu() {
      document.querySelector(".sidebar").classList.toggle("show");
    }

return (
<div className="App" >
 <Sidebar />
        <header className="overview">
          <div
            ref={this.setWrap}
            style={{ padding: "2% 10%", textAlign: "right" }}
          >
            {" "}
            <div>{this.state.isToggleOn ? null : <Toggler />}</div>
            <span>
              <FontAwesomeIcon
                onClick={this.handleClick}
                style={{ marginRight: "1rem", cursor: "pointer" }}
                icon={faBell}
              />
            </span>
            <span>
              <FontAwesomeIcon
                onClick={this.profileHandler}
                style={{ cursor: "pointer" }}
                icon={faUser}
              />
            </span>{" "}
            {/*this.state.items.firstname} {this.props.childern*/}
            {/*this.state.isProfile ? null : <ClientProfile />  */}{" "}
          </div>

          <div style={{ width: "89%", display: "flex", paddingLeft: "10px" }}>
          <span onClick={openMenu} className="menu">
              <FontAwesomeIcon
                style={{ marginRight: "20px" }}
                icon={faBars}
                className="menu-icon"
              />
            </span>

            <span style={{ whiteSpace: "nowrap" }}>Learn It</span>
            <div style={{ padding: "4px", width: "100%" }}>
              <hr style={{ background: "white", color: "white" }} />
            </div>
          </div>
          <div className="mwrapper">
            <div className="mleft">
             <CLearnit/>
            </div>
           { /*<div className="mright">

            </div>*/}
            </div>



  </header>
</div>
);

}

}
