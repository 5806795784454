import AMan from "../../img/man.jpg";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
export default function Summery(props) {
  const [data, setdata] = useState([]);
  const [summary, setsummary] = useState();
  var doctorid = props.user;
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("token"),
      },
    };

    axios
      .get("/appointments/get-summary/?appointmentID=" + doctorid, config)
      .then((res) => {
        console.log(res.data);
        setdata(res.data.doctor_details);
        setsummary(res.data.summary);
      });
  }, []);
  return (
    <>
      <div style={styles.card} className="gradiantblur">
        <strong style={styles.cardheader} className="gradiantblur">
          Summary
        </strong>
        <div style={styles.inner}>
          <div style={styles.upcom}>
            <img src={data.profile_img} alt="" style={styles.manpic} />
            <div style={{ textAlign: "left" }}>
              <strong>
                Dr. {data.doctor_firstname}
                {data.doctor_lastname}
              </strong>
              <br />
              <small style={styles.info}>
                <span>{data.qualification}</span>
                <br />
                <hr style={styles.hr} />
                <span>{data.experience} years of experience</span>
                <hr style={styles.hr} />
              </small>
            </div>
          </div>
        </div>
        <div
          style={{
            padding: "20px",
            border:
              "20px solid 0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
          }}
        >
          <div style={styles.disc} className="homepurple">
            <small>{summary}</small>
          </div>
        </div>
      </div>
    </>
  );
}

const styles = {
  card: {
    display: "flex",
    justifyContent: "space-between",
    // background: "#55005C",
    padding: "0px",
    borderRadius: "10px",
    margin: "10px",
    flexDirection: "column",
  },

  inner: {
    textDecoration: "none",
    color: "#fff",
  },

  info: {
    fontSize: "12px",
  },

  hr: {
    color: "#fff",
  },

  cardheader: {
    // background: "#55005C",
    textAlign: "center",
    padding: "10px 8px",
    width: "auto",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    borderBottom: "1px solid gray",
  },

  manpic: {
    width: "80px",
    height: "80px",
    borderRadius: "50%",
    marginRight: "10px",
  },

  upcom: {
    display: "flex",
    justifyContent: "space-between",
    width: "20rem",
    padding: "30px",
  },

  disc: {
    borderRadius: "10px",
    padding: "10px",
    fontSize: "12px",
    textAlign: "left",
    background: "#6e196054 0% 0% no-repeat padding-box",
  },
};
//     /* box-shadow: 0px 6px 9px #00000029;",
//   },
// };
// }
