import React, { useState } from "react";
import Calendar from "react-calendar";
import "./booking.css";
import sun from "../img/sun.png";
import sunset from "../img/sunset.png";
import axios from "axios";
import moon from "../img/moon.png";
import { useEffect } from "react";
import sunrise from "../img/sunrise.png";
import "react-calendar/dist/Calendar.css";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

// const __DEV__ = document.domain === "localhost";
// import React, { useState } from "react";
// import Calendar from "react-calendar";
// import "./booking.css";
// import sun from "../img/sun.png";
// import sunset from "../img/sunset.png";
// import axios from "axios";
// import moment from "moment";
// import { v4 as uuidv4 } from "uuid";
// import { useEffect } from "react";
// import sunrise from "../img/sunrise.png";
// import "react-calendar/dist/Calendar.css";
// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

const __DEV__ = document.domain === "localhost";

export default function Booking(props) {
  var doctorid = props.dataFromParent;
  console.log(doctorid);
  const [Payments, setPayments] = useState();
  const [Payment, setPayment] = useState();
  const [col, setCol] = useState("black");
  const [time, settime] = useState();
  const [Appid, setAppid] = useState();
  const [isClose, setisclose] = useState(true);
  const [timer, settimer] = useState(false);
  const [dateState, setDateState] = useState(new Date());

  const changeDate = (e) => {
    setDateState(e);
  };

  function closeTabBox() {
    setisclose(false);
  }

  var buttons = document.getElementsByClassName("time-button");
  var click_listener = function (event) {
    //console.log("clicked " + event.target);
    for (var i = 0; i < buttons.length; i++) {
      buttons[i].classList.remove("clicked");
    }
    event.target.classList.add("clicked");
    if (event) {
      settimer(true);
    } else {
      settimer(false);
    }
  };
  for (var i = 0; i < buttons.length; i++) {
    buttons[i].addEventListener("click", click_listener, false);
  }
  function clickedtime(e) {
    var time = e.target.value;
    settime(time);
    console.log(e.target.value);
    setCol("red");
  }

  useEffect(() => {
    // Code for initializing the component
  }, []);

  function booking() {
    var datepiker = moment(dateState).format("YYYY-MM-DD");
    console.log(datepiker);
    const data = {
      date: datepiker,
      time: time,
      doctor: doctorid.id,
    };

    const headers = {
      Authorization: `token ` + localStorage.getItem("token"),
    };

    axios
      .post("appointments/customer-booking/", data, {
        headers: headers,
      })
      .then((response) => {
        const appid = response.data.id;
        setAppid(appid);
        const doctorPrice = response.data.doctor_info.doctor_price;
        if (doctorPrice > 0) {
          // First call the appointment-payments API
          callAppointmentPaymentsAPI(appid, doctorPrice);
        } else {
          alert("Appointment booked successfully!");
        }
      })
      .catch((error) => {
        alert("Cannot book appointment");
      });
  }

  const callAppointmentPaymentsAPI = (appointmentId, doctorPrice) => {
    const ui = uuidv4();
    const paymentData = {
      appointment_id: appointmentId,
      uid: ui,
    };

    const headers = {
      Authorization: `token ` + localStorage.getItem("token"),
    };

    axios
      .post("appointments/appointment-payments/", paymentData, {
        headers: headers,
      })
      .then((response) => {
        // Handle API response
        alert("booking compelte");
        // After successful appointment-payments API call, proceed to payment gateway
        initiatePayment(doctorPrice, ui);
      })
      .catch((error) => {
        // Handle error
      });
  };

  const initiatePayment = (doctorPrice, uid) => {
    const loadScript = (src) => {
      return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
          resolve(true);
        };
        script.onerror = () => {
          resolve(false);
        };
        document.body.appendChild(script);
      });
    };

    const handlePaymentSuccess = (response) => {
      // Payment success callback
      alert("Payment successful!");
      console.log(response);

      // Further processing if needed

      // Call the API function after successful payment
      // In this case, you can skip calling `callAppointmentPaymentsAPI()` again since it has already been called before the payment gateway.
    };

    const loadRazorpayScript = async () => {
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );
      if (res) {
        const options = {
          key: "rzp_live_ZzpnKBVjhdD60I",
          amount: doctorPrice * 100,
          currency: "INR",
          name: "shebirth",
          description: uid,
          image: "https://your-company-logo.png",
          handler: function (response) {
            // Payment success callback
            handlePaymentSuccess(response);

            // Further processing if needed
          },
          prefill: {
            name: "Customer Name",
            email: "customer@example.com",
            contact: "9999999999",
          },
          theme: {
            color: "#F37254",
          },
        };

        const razorpay = new window.Razorpay(options);
        razorpay.open();
      } else {
        console.log("Failed to load Razorpay script.");
      }
    };

    loadRazorpayScript();
  };

  return (
    <>
      {isClose ? (
        <div className="multiple-tab mobilebooking" style={styles.tabview}>
          <i
            onClick={closeTabBox}
            className="fa fa-close"
            style={styles.closebtn}
          ></i>
          <div className="callanderandtime" style={styles.gridstyle}>
            <div
              // style={{ borderRadius: "5%" }}
              style={styles.calendarstyle}
              className="res-calendar  containcallander"
            >
              <Calendar
                style={{ borderRadius: "18%" }}
                className={["c1", "c2"]}
                value={dateState}
                minDate={new Date()}
                onChange={changeDate}
              />
            </div>
            <div style={styles.calendarstyle2} className="timerslecter">
              <Tabs style={styles.headinglist}>
                <TabList
                  // style={styles.headinglist}
                  style={{
                    paddingLeft: "0px",
                    listStyle: "none",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Tab style={styles.headinglist}>
                    <img style={{ height: "" }} src={sunrise} alt="" />
                  </Tab>
                  <Tab style={styles.headinglist}>
                    <img style={{ height: "" }} src={sun} alt="" />
                  </Tab>
                  <Tab style={styles.headinglist}>
                    <img style={{ height: "" }} src={sunset} alt="" />
                  </Tab>
                  <Tab style={styles.headinglist}>
                    <img style={{ height: "" }} src={moon} alt="" />
                  </Tab>
                </TabList>
                <TabPanel className="tabcss" style={styles.tabpannel}>
                  <button
                    className="time-button"
                    onClick={clickedtime}
                    value="9:00"
                    style={{
                      border: " 1px solid #6b1572",
                      padding: "1%",
                      margin: "2px",
                    }}
                  >
                    9:00am
                  </button>
                  <button
                    className="time-button"
                    onClick={clickedtime}
                    value="9:30"
                    style={{
                      border: " 1px solid #6b1572",
                      padding: "1%",
                      margin: "2px",
                    }}
                  >
                    9:30am
                  </button>
                  <button
                    className="time-button"
                    onClick={clickedtime}
                    value="10:00"
                    style={{
                      border: " 1px solid #6b1572",
                      padding: "1%",
                      margin: "2px",
                    }}
                  >
                    10:00am
                  </button>
                  <button
                    className="time-button"
                    onClick={clickedtime}
                    value="10:30"
                    style={{
                      border: " 1px solid #6b1572",
                      padding: "1%",
                      margin: "2px",
                    }}
                  >
                    10:30am
                  </button>
                  <button
                    className="time-button"
                    onClick={clickedtime}
                    value="11:00"
                    style={{
                      border: " 1px solid #6b1572",
                      padding: "1%",
                      margin: "2px",
                    }}
                  >
                    11:00am
                  </button>
                  <button
                    className="time-button"
                    onClick={clickedtime}
                    value="11:30"
                    style={{
                      border: " 1px solid #6b1572",
                      padding: "1%",
                      margin: "2px",
                    }}
                  >
                    11:30am
                  </button>
                  <button
                    className="time-button"
                    onClick={clickedtime}
                    value="12:00"
                    style={{
                      border: " 1px solid #6b1572",
                      padding: "1%",
                      margin: "2px",
                    }}
                  >
                    12:00pm
                  </button>
                </TabPanel>
                <TabPanel className="tabcss" style={styles.tabpannel}>
                  <button
                    className="time-button"
                    onClick={clickedtime}
                    value="12:30"
                    style={{
                      border: " 1px solid #6b1572",
                      padding: "1%",
                      margin: "2px",
                    }}
                  >
                    12:30pm
                  </button>
                  <button
                    className="time-button"
                    onClick={clickedtime}
                    value="13:00"
                    style={{
                      border: " 1px solid #6b1572",
                      padding: "1%",
                      margin: "2px",
                    }}
                  >
                    1:00pm
                  </button>
                  <button
                    className="time-button"
                    onClick={clickedtime}
                    value="13:30"
                    style={{
                      border: " 1px solid #6b1572",
                      padding: "1%",
                      margin: "2px",
                    }}
                  >
                    1:30pm
                  </button>
                  <button
                    className="time-button"
                    onClick={clickedtime}
                    value="14:00"
                    style={{
                      border: " 1px solid #6b1572",
                      padding: "1%",
                      margin: "2px",
                    }}
                  >
                    2:00pm
                  </button>
                  <button
                    className="time-button"
                    onClick={clickedtime}
                    value="14:30"
                    style={{
                      border: " 1px solid #6b1572",
                      padding: "1%",
                      margin: "2px",
                    }}
                  >
                    2:30pm
                  </button>
                  <button
                    className="time-button"
                    onClick={clickedtime}
                    value="15:00"
                    style={{
                      border: " 1px solid #6b1572",
                      padding: "1%",
                      margin: "2px",
                    }}
                  >
                    3:00pm
                  </button>
                  <button
                    className="time-button"
                    onClick={clickedtime}
                    value="15:30"
                    style={{
                      border: " 1px solid #6b1572",
                      padding: "1%",
                      margin: "2px",
                    }}
                  >
                    3:30pm
                  </button>
                  <button
                    className="time-button"
                    onClick={clickedtime}
                    value="16:00"
                    style={{
                      border: " 1px solid #6b1572",
                      padding: "1%",
                      margin: "2px",
                    }}
                  >
                    4:00pm
                  </button>
                  <button
                    className="time-button"
                    onClick={clickedtime}
                    value="16:30"
                    style={{
                      border: " 1px solid #6b1572",
                      padding: "1%",
                      margin: "2px",
                    }}
                  >
                    4:30pm
                  </button>
                  <button
                    className="time-button"
                    onClick={clickedtime}
                    value="17:00"
                    style={{
                      border: " 1px solid #6b1572",
                      padding: "1%",
                      margin: "2px",
                    }}
                  >
                    5:00pm
                  </button>
                  <button
                    className="time-button"
                    onClick={clickedtime}
                    value="17:30"
                    style={{
                      border: " 1px solid #6b1572",
                      padding: "1%",
                      margin: "2px",
                    }}
                  >
                    5:30pm
                  </button>
                  <button
                    className="time-button"
                    onClick={clickedtime}
                    value="18:00"
                    style={{
                      border: " 1px solid #6b1572",
                      padding: "1%",
                      margin: "2px",
                    }}
                  >
                    6:00pm
                  </button>
                </TabPanel>
                <TabPanel className="tabcss" style={styles.tabpannel}>
                  <button
                    className="time-button"
                    onClick={clickedtime}
                    value="18:30"
                    style={{
                      border: " 1px solid #6b1572",
                      padding: "1%",
                      margin: "2px",
                    }}
                  >
                    6:30pm
                  </button>
                  <button
                    className="time-button"
                    onClick={clickedtime}
                    value="19:00"
                    style={{
                      border: " 1px solid #6b1572",
                      padding: "1%",
                      margin: "2px",
                    }}
                  >
                    7:00pm
                  </button>
                  <button
                    className="time-button"
                    onClick={clickedtime}
                    value="19:30"
                    style={{
                      border: " 1px solid #6b1572",
                      padding: "1%",
                      margin: "2px",
                    }}
                  >
                    7:30pm
                  </button>
                  <button
                    className="time-button"
                    onClick={clickedtime}
                    value="20:00"
                    style={{
                      border: " 1px solid #6b1572",
                      padding: "1%",
                      margin: "2px",
                    }}
                  >
                    8:00pm
                  </button>
                </TabPanel>
                <TabPanel className="tabcss" style={styles.tabpannel}>
                  <button
                    className="time-button"
                    onClick={clickedtime}
                    value="20:30"
                    style={{
                      border: " 1px solid #6b1572",
                      padding: "1%",
                      margin: "2px",
                    }}
                  >
                    8:30pm
                  </button>
                  <button
                    className="time-button"
                    onClick={clickedtime}
                    value="21:00"
                    style={{
                      border: " 1px solid #6b1572",
                      padding: "1%",
                      margin: "2px",
                    }}
                  >
                    9:00pm
                  </button>
                </TabPanel>
              </Tabs>
            </div>
            {/* //{" "}
            <p>
              // Current selected date is //{" "}
              <b>{moment(dateState).format("MMMM Do YYYY")}</b>
              //{" "}
            </p> */}
          </div>

          <div>
            <p
              style={{
                fontSize: "16px",
                background:
                  "linear-gradient(to right, rgb(133 38 104), rgb(101 28 106)",
                padding: "15px",
              }}
            >
              I have read and understood the Terms & Conditions of usage of
              Shebirth and consent to the same. I am voluntarily availing of the
              services provided on this platform. I am fully aware that I will
              not be undergoing a physical examination by a physician prior to a
              physician recommending medical tests and/or treatment and/or the
              prescribing of drugs. I am also aware that the consultation on
              Shebirth Application does not preclude my rights to visit a
              physician and opt for a physical examination at any point in time
              and I am available for consultation at any time during the
              consultation to request for the same.
            </p>
          </div>

          {timer ? (
            <div>
              <button onClick={booking} className="pinkbutton">
                Book Appointment
              </button>{" "}
              <button className="transparent56">
                {" "}
                Price : &#8377; {doctorid.price}{" "}
              </button>
            </div>
          ) : null}
        </div>
      ) : null}
    </>
  );
}
const styles = {
  button: {
    background: " #6b1572",
    border: " 1px solid #6b1572",
    padding: "1%",
    margin: "2px",
  },
  gridstyle: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    width: "100%",
  },
  closebtn: { float: "right" },
  headinglist: {
    borderRadius: "0",
    // width: "337px",

    fontSize: "16px",
    background: " rgb(133 38 104)",
  },
  calendarstyle: {
    width: "260px",
    marginLeft: "5%",
    background: "rgb(163 9 116)",
  },
  calendarstyle2: {
    width: "260px",
    marginLeft: "5%",
    background: " rgb(133 38 104)",
  },
  tabview: {
    position: "absolute",
    padding: "1%",
    zIndex: "5",
    width: "47%",
    backdropFilter: "blur(23px) saturate(127%)",
    background: "#ffffff1c 0% 0% no-repeat padding-box",

    //background: "#5b397f",
  },
  tabpannel: {
    // height: "230px",
    //overflowX: "hidden",
    border: "linear-gradient(to right, rgb(133 38 104), rgb(101 28 106)",
    // background: "linear-gradient(to right, rgb(133 38 104), rgb(101 28 106)",
  },
};
