import React from "react";

export default function ImageModal({ imageUrl, onClose }) {
  return (
    <div className="modalbody">
      <div className="modal-inner">
        <img src={imageUrl} alt="Enlarged" />
        <button onClick={onClose}>
          {" "}
          <i
            // onClick={this.closeTabBox}
            className="fa fa-close"
            // style={styles.closebtn}
          ></i>
        </button>
      </div>
    </div>
  );
}
