import React, { useEffect, useState } from 'react';
import { Accordion, Card, Button } from 'react-bootstrap';
import './vac.css'; // Import your CSS file
import axios from 'axios';

export default function VacComponent() {
    const [vaccines, setVaccines] = useState({});
    const [selectedMonth, setSelectedMonth] = useState(null);

    const handleToggleMonth = (month) => {
        setSelectedMonth(selectedMonth === month ? null : month);
    };
    const headers = {
        Authorization: `token ` + localStorage.getItem("postclient"),
    };
    useEffect(() => {
        axios.get('http://127.0.0.1:8000/customer/get-vaccine', {
            headers: headers,
        })
            .then((res) => {
                setVaccines(res.data); // Assuming res.data is structured as per your desired vaccines format
                console.log('Vaccine data', res.data);
            })
            .catch((error) => {
                console.error('Error fetching vaccine data:', error);
            });
    }, []); // Empty dependency array means this effect runs only once on component mount

    const handleDateChange = (vaccineId, newDate) => {
        const updatedVaccines = { ...vaccines }; // Create a copy of vaccines

        // Find the month containing the updated vaccine
        const updatedMonth = Object.keys(updatedVaccines).find(
            (month) => updatedVaccines[month].find((vaccine) => vaccine.id === vaccineId)
        );


        const updatedVaccine = updatedVaccines[updatedMonth].find((vaccine) => vaccine.id === vaccineId);


        updatedVaccine.date = newDate;

        updatedVaccine.status = true;

        setVaccines(updatedVaccines);
        const data = { vaccination_id: vaccineId, status: true, date: newDate }
        axios.patch(
            `http://127.0.0.1:8000/customer/post-vaccine`,
            data,
            { headers: headers }
        )
            .then((res) => {
                // Handle successful update
                console.log('Vaccine updated:', res.data);
            })
            .catch((error) => {
                console.error('Error updating vaccine:', error);
            });
    };

    return (
        <div className="box">
            <h4>Vaccinations</h4>
            {Object.keys(vaccines).map((month) => (
                <div key={month}>
                    <div
                        onClick={() => handleToggleMonth(month)}
                        variant="primary"
                        
                    >
                        <h5 className='vac-btn' >

                            At {month} months
                        </h5> <br></br>
                    </div>
                    {selectedMonth === month && (
                        <Accordion>
                            {vaccines[month].map((vaccine, index) => (
                                <Card key={index} style={{ height: 'auto', width: '90%', border: '.5px solid white' }} >
                                    <Accordion.Toggle as={Card.Header} eventKey={index.toString()}>
                                        <span style={{ fontSize: '22px' }}>

                                            Name: {vaccine.name}
                                        </span>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey={index.toString()}>
                                        <Card.Body>
                                            <div style={{ display: 'inline', fontSize: '17px' }} >
                                                <span>Age: {vaccine.age}</span>&nbsp;&nbsp;&nbsp;&nbsp;
                                                <span>Against: {vaccine.agent}</span>&nbsp;&nbsp;&nbsp;&nbsp;
                                                <span
                                                >Status: <span style={{
                                                    color: vaccine.status ? 'green' : 'red',
                                                    fontWeight: 'bold',
                                                }}>{vaccine.status ? 'Vaccinated' : 'Not Taken'} </span></span>&nbsp;&nbsp;&nbsp;&nbsp;
                                                <span >Date:  <input
                                                    style={{outline:'none',border:'none',background:'rgba(0,0,0,0)',color:'skyblue',}}
                                                    type='date'
                                                    defaultValue={vaccine.date}
                                                    onChange={(e) => { handleDateChange(vaccine.id, e.target.value); vaccine.status = true }}
                                                /></span>&nbsp;
                                            </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            ))}
                        </Accordion>
                    )}
                </div>
            ))}
        </div>
    );
}
