import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
// the id passed in url
export default function Tablelist() {
  const [Items, setItems] = useState([]);
  const [search, setSearch] = useState("");

  const [showResults, setShowResults] = React.useState(true);
  const hideShow = () => setShowResults(false);

  const { id } = useParams();
  const updateStatus = (id) => {
    const Data = {
      id: id,
    };
    const headers = {
      Authorization: `Token ${sessionStorage.getItem("hospitalManager")}`,
    };

    axios
      .post("activateOrDeactivate/", Data, {
        headers: headers,
      })
      .then(() => {
        // setincrement([...increment,res.data])
        alert("success");
        window.location.reload();
      })
      .catch((error) => {
        console.log("error in sheduling ", error);
      });
  };

  useEffect(() => {
    const token = sessionStorage.getItem("hospitalManager");
    if (!token) {
      window.location = "/login";
    }

    axios
      .get(`/hospital/customers-under-doctor/`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Token ${sessionStorage.getItem("hospitalManager")}`,
        },
        params: {
          doctor_id: id,
        },
      })
      .then((res) => {
        setItems(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <div className="search lightgradient" style={styles.card}>
        <div style={styles.inner}>
          <p style={{ textAlign: "left" }}>Search Clients</p>
          <form style={{ display: "flex" }}>
            {showResults ? (
              <FontAwesomeIcon
                style={{ marginRight: "-22px" }}
                icon={faSearch}
              />
            ) : null}

            <input
              onFocus={hideShow}
              style={styles.input}
              type="Search"
              onChange={(e) => setSearch(e.target.value)}
            />
          </form>
        </div>
      </div>
      <div className=" lightgradient" style={styles.card}>
        <div style={styles.inner}>
          <p>
            <strong>All Clients Under A Specific Doctor</strong>
          </p>
          <hr style={{ color: "#fff" }} />
          <table style={{ width: "55rem" }}>
            <thead>
              <tr>
                <td>Name</td>
                <td>Age</td>
                <td>Location</td>
                <td>Due Date</td>
                {/* <td>Doctor</td> */}
                {/* <td>Daily Tracker</td> */}
                {/* <td>Account Status</td> */}
              </tr>
            </thead>
            <tbody>
              {console.log(Items)}
              {Items.map((itm, key) => (
                <tr>
                  <td>
                    {itm.user_first_name}&nbsp;
                    {itm.user_last_name}
                  </td>
                  <td> {itm.age}</td>
                  <td> {itm.location}</td>
                  <td> {itm.Menstruation_date}</td>

                  {/* <td>
                    <select onChange={() => updateStatus(itm.id)}>
                      {itm.accountStatus == true ? (
                        <>
                          <option>Active</option> <option>Disabled</option>
                        </>
                      ) : (
                        <>
                          <option>Disabled</option>
                          <option>Active</option>
                        </>
                      )}
                    </select>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

const styles = {
  card: {
    // display: "flex",
    justifyContent: "space-between",
    // background: "#4c003d",
    padding: "20px",
    borderRadius: "10px",
    margin: "40px",
  },
  inner: {
    textDecoration: "none",
    color: "#fff",
  },
  input: {
    width: "55rem",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderBottomColor: "1px solid #fff",
    background: "transparent",
    padding: "8px",
  },
};
