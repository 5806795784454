import React from 'react';
import '../tabs.css';
/*import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';*/
/*import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';*/
//import EditPersonalDetail from './innercom/edit_personal';
import AMan from '../img/man.jpg';

// import DietTracker from '../img/balance_diet.png';

class MyPayment extends React.Component{
/*constructor(props){
super(props);

this.state = { isEditor: false };
this.openEditor = this.openEditor.bind(this);

}

openEditor(){

this.setState({ isEditor: !this.state.isEditor });

}
*/

render(){

return (
<>    
<div style={styles.card} className="payment-container">
<div style={styles.inner} className="inner">
<div style={styles.cardheader} className="card-header">
<strong style={{ textAlign:'center' }}>Payments</strong>
</div>

<div style={styles.upcom}>
<img src={AMan} alt="" style={ styles.manpic }/>
<div className="inner-flex">
<div>
<strong>Dr. Avinash Nambihar</strong><br/>
<small style={styles.info}>Paediatrician <span>11 years of experience</span> </small><br/>
<div style={{ textAlign:'right' }}>
</div>
</div>
<div>
<div style={{ textAlign:'left', padding:'8px 15px', background:'#651c6a' }}>
<strong>Paid RS. 600</strong>
</div>
</div>

</div>


</div>

<div style={styles.upcom}>
<img src={AMan} alt="" style={ styles.manpic }/>

<div className="inner-flex">
<div>
<strong>Dr. Avinash Nambihar</strong><br/>
<small style={styles.info}>Paediatrician <span>11 years of experience</span> </small><br/>
<div style={{ textAlign:'right' }}>
</div>
</div>
<div>
<div style={{ textAlign:'left', padding:'8px 15px', background:'#651c6a' }}>
<strong>Paid RS. 600</strong>
</div>
</div>
</div>

</div>
</div>

</div>
</>

);


}
}

const styles = {
card:{
display:'flex',
justifyContent:'space-between',
//background:'#9038a3',
background:'transparent',
padding:'20px',
borderRadius:'10px',
margin:'10px',
},

cardheader:{
background:'#9038a3',
textAlign:'center',
padding:'10px 8px',
width:'40rem',
borderTopLeftRadius:'10px',
borderTopRightRadius:'10px',
borderBottom:'1px solid gray'
},

inner:{
textDecoration:'none',
color:'#fff'
},

info:{
fontSize:'12px',
},

editbtn:{
border:'1px solid #fff',
background:'transparent',
padding:'5px 10px',
color:'#fff',
cursor:'pointer',
borderRadius:'7px'
},

manpic:{
width:'80px',
height:'80px',
borderRadius:'50%',
marginRight:'10px'
},

rbtn:{
background:'#e81a7f',
color:'#fff',
padding:'7px 40px',
border:'none',
borderRadius:'5px',
cursor:'pointer'
},

sbtn:{
background:'#00ACC8',
color:'#fff',
padding:'7px 40px',
border:'none',
borderRadius:'5px',
cursor:'pointer'
},

upcom:{
    marginBottom:'2rem',
    display:'flex',
    justifyContent:'space-between',
    width:'auto',
    background:'#9038a3',
    padding:'30px'
}




}

export default MyPayment;

