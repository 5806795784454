import React from "react";
import { Link, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faImage,
  faThLarge,
  faBriefcaseMedical,
  faCube,
  faCaretDown,
  faHistory,
  faCalendarCheck,
  faUserMd,
  faUser,
  faWheelchair,
  faMoneyBill,
  faFileAlt,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";
import Logo from "../../../logo.png";

export default class Sidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen1: false,
      isOpen2: false,
      isOpen3: false,
    };
  }

  toggleSubMenu = (subMenuNumber) => {
    this.setState((prevState) => ({
      [`isOpen${subMenuNumber}`]: !prevState[`isOpen${subMenuNumber}`],
    }));
  };

  render() {
    return (
      <div className="sidebar" style={styles.header}>
        <Link to="/admin">
          <img src={Logo} alt="" />
        </Link>
        <button className="backButton" onClick={() => window.history.back()}>
          Back
        </button>
        <ul style={styles.lists}>
          {/* Overview menu item */}
          <NavLink exact activeClassName="active3" to="/admin_overview">
            <li style={styles.listItem}>
              <FontAwesomeIcon
                icon={faThLarge}
                style={{ marginRight: "1rem" }}
              />
              Overview
            </li>
          </NavLink>

          {/* Internal users menu item */}
          <li
            style={styles.listItem}
            onClick={() => this.toggleSubMenu(1)}
            className={this.state.isOpen1 ? "admintabs" : ""}
          >
            <div>
              <FontAwesomeIcon
                icon={faUserMd}
                style={{ marginRight: "1rem" }}
              />
            </div>
            <div> Internal users</div>
            <div>
              <FontAwesomeIcon icon={faCaretDown} />
            </div>
          </li>
          {this.state.isOpen1 && (
            <>
              <NavLink
                to="/sales_list_admin"
                activeClassName="active3"
                onClick={() => this.toggleSubMenu(1)}
              >
                <li style={styles.listItem}>
                  <div>
                    <FontAwesomeIcon
                      icon={faCube}
                      style={{ marginRight: "1rem" }}
                    />
                  </div>
                  <div> Sales</div>
                </li>
              </NavLink>
              <NavLink
                to="/post_consultants"
                activeClassName="active3"
                onClick={() => this.toggleSubMenu(1)}
              >
                <li style={styles.listItem}>
                  <div>
                    <FontAwesomeIcon
                      icon={faBriefcaseMedical}
                      style={{ marginRight: "1rem" }}
                    />
                  </div>
                  <div> Consultants</div>
                </li>
              </NavLink>
            </>
          )}

          {/* External users menu item */}
          <li
            style={styles.listItem}
            onClick={() => this.toggleSubMenu(2)}
            className={this.state.isOpen2 ? "admintabs" : ""}
          >
            <div>
              <FontAwesomeIcon icon={faUser} style={{ marginRight: "1rem" }} />
            </div>
            <div> External users</div>{" "}
            <div>
              <FontAwesomeIcon icon={faCaretDown} />
            </div>
          </li>
          {this.state.isOpen2 && (
            <>
              <NavLink to="/admin/post_doctor" activeClassName="active3">
                <li style={styles.listItem}>
                  <div>
                    <FontAwesomeIcon
                      icon={faCube}
                      style={{ marginRight: "1rem" }}
                    />
                  </div>
                  <div> All Doctors</div>
                </li>
              </NavLink>
              <NavLink to="/admin/post_approve_doc" activeClassName="active3">
                <li style={styles.listItem}>
                  <div>
                    <FontAwesomeIcon
                      icon={faBriefcaseMedical}
                      style={{ marginRight: "1rem" }}
                    />
                  </div>
                  <div>Approve Doctors</div>
                </li>
              </NavLink>
              {/* <NavLink to="/admin/hospital" activeClassName="active3">
                <li style={styles.listItem}>
                  <div>
                    <FontAwesomeIcon
                      icon={faWheelchair}
                      style={{ marginRight: "1rem" }}
                    />
                  </div>
                  <div> Hospital</div>
                </li>
              </NavLink> */}
            </>
          )}

          {/* Patient menu item */}
          <li
            style={styles.listItem}
            onClick={() => this.toggleSubMenu(3)}
            className={this.state.isOpen3 ? "admintabs" : ""}
          >
            <div>
              <FontAwesomeIcon
                icon={faWheelchair}
                style={{ marginRight: "1rem" }}
              />
            </div>
            <div> patient</div>
            <div>
              <FontAwesomeIcon icon={faCaretDown} />
            </div>
          </li>
          {this.state.isOpen3 && (
            <>
              <NavLink to="/admin/post_allclient" activeClassName="active3">
                <li style={styles.listItem}>
                  <div>
                    <FontAwesomeIcon
                      icon={faCube}
                      style={{ marginRight: "1rem" }}
                    />
                  </div>
                  <div> All clients</div>
                </li>
              </NavLink>
              <NavLink to="/admin/paidclient" activeClassName="active3">
                <li style={styles.listItem}>
                  <div>
                    <FontAwesomeIcon
                      icon={faUser}
                      style={{ marginRight: "1rem" }}
                    />
                  </div>
                  <div>paid clients</div>
                </li>
              </NavLink>
              <NavLink to="/admin/freeclient" activeClassName="active3">
                <li style={styles.listItem}>
                  <div>
                    <FontAwesomeIcon
                      icon={faUser}
                      style={{ marginRight: "1rem" }}
                    />
                  </div>
                  <div>Free clients</div>
                </li>
              </NavLink>
              <NavLink to="/admin/learning_module_post" activeClassName="active3">
                <li style={styles.listItem}>
                  <div>
                    <i
                      className="fa fa-upload"
                      style={{ marginRight: "1rem" }}
                    ></i>
                  </div>
                  <div> Learning Module</div>
                </li>
              </NavLink>
              {/* <NavLink to="/admin/daily_tracker" activeClassName="active3">
                <li style={styles.listItem}>
                  <div>
                    <FontAwesomeIcon
                      icon={faHistory}
                      style={{ marginRight: "1rem" }}
                    />
                  </div>
                  <div> Update Tracker Module </div>
                </li>
              </NavLink>
              <NavLink to="/admin/edit" activeClassName="active3">
                <li style={styles.listItem}>
                  <div>
                    <FontAwesomeIcon
                      icon={faHistory}
                      style={{ marginRight: "1rem" }}
                    />
                  </div>
                  <div> Edit Daily Tracker</div>
                </li>
              </NavLink> */}
            </>
          )}

          {/* Banner menu item */}
          <NavLink to="/admin/Banner_post" activeClassName="active3">
            <li style={styles.listItem}>
              <div>
                <FontAwesomeIcon
                  icon={faImage}
                  style={{ marginRight: "1rem" }}
                />
              </div>
              <div> Banner </div>
            </li>
          </NavLink>

          {/* Subscriptions menu item */}
          <NavLink to="/subscription_post" activeClassName="active3">
            <li style={styles.listItem}>
              <div>
                <FontAwesomeIcon
                  icon={faMoneyBill}
                  style={{ marginRight: "1rem" }}
                />
              </div>
              <div> Subscriptions</div>
            </li>
          </NavLink>

          {/* Appointments menu item */}
          <NavLink to="/admin/appointment" activeClassName="active3">
            <li style={styles.listItem}>
              <div>
                <FontAwesomeIcon
                  icon={faCalendarCheck}
                  style={{ marginRight: "1rem" }}
                />
              </div>
              <div> appointments </div>
            </li>
          </NavLink>

          {/* Add content menu item */}
          <NavLink to="/admin/content" activeClassName="active3">
            <li style={styles.listItem}>
              <div>
                <FontAwesomeIcon
                  icon={faFileAlt}
                  style={{ marginRight: "1rem" }}
                />
              </div>
              <div> Add content </div>
            </li>
          </NavLink>
          <NavLink to="/admin/allcalls" activeClassName="active3">
            <li style={styles.listItem}>
              <div>
                <FontAwesomeIcon
                  icon={faPhoneAlt}
                  style={{ marginRight: "1rem" }}
                />
              </div>
              <div> Call list </div>
            </li>
          </NavLink>
        </ul>
      </div>
    );
  }
}

const styles = {
  header: {
    background:
      " 0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
    // background: "linear-gradient(25deg, #3a51a7, #7f1f54)",
    padding: "2rem",
    minHeight: "115vh",
    width: "20%",
    margin: "0",
    color: "white",
  },
  lists: {
    marginTop: "5rem",
    color: "#fff",
    paddingLeft: "0",
    listStyle: "none",
  },
  listItem: {
    // background: "#6a0f4b",
    // background: "transparent",
    padding: "10px 48px",
    borderRadius: "10px",
    cursor: "pointer",
    display: "grid",
    // display: "flex",    padding: 13px 47px;

    gridTemplateColumns: "1fr 9fr 1fr",
    // justifyContent: "center",
  },

  listItemActive: {
    // background: "#6a0f4b",
    padding: "10px 50px",
    borderRadius: "10px",
    cursor: "pointer",
    display: "flex",
  },
};
