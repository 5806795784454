import React, { useState, useEffect } from "react";
import HomeImage from "../img/group567.png";
import DailyTrackerImg from "./img/dailytracker.png";
import Labour from "./img/laborportal.png";
import LearningPortal from "./img/learning.png";
import { Carousel } from "react-responsive-carousel";
import DailyTrackerDas from "./daily_tracker";
import LearningPortalDas from "./learning_portal";
import axios from "axios";
import { Link } from "react-router-dom";
import Banner from "./imageslider";
import { set } from "react-hook-form";
export default function Home() {
  const [tracker, setDailyTracker] = useState(false);
  const [learn, setLearningPortal] = useState(false);
  const [images, setimages] = useState([]);
  const [Package, setPackage] = useState();
  // const [weight, setWeight] = useState([]);
  //const [describtion, setDescribtion] = useState([]);
  // const [length, setlength] = useState([]);
  const [babyDetails, setbabyDetails] = useState([]);
  const [sub, setsub] = useState();
  const baseurl = "https://sukhprasavam.shebirth.com";
  function clickHandler() {
    setDailyTracker({ tracker: true });
  }

  function learningPortalOpen() {
    setLearningPortal({ learn: true });
  }

  useEffect(() => {
    setsub(localStorage.getItem("subscibtion"));
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("token"),
      },
    };

    axios.get("customer/customer-dashboard-details/", config).then((res) => {
      console.log(res.data.subscription_package);
      //  setWeight(res.data.babyDetails.weigth);

      // setlength(res.data.babyDetails.length);
      setimages(res.data.video[0]);
      setbabyDetails(res.data.babyDetails);
      setPackage(res.data.subscription_package);
    });
  }, []);

  return (
    <>
      {learn ? (
        <LearningPortalDas />
      ) : (
        <>
          {tracker ? (
            <DailyTrackerDas />
          ) : (
            <div>
              <div className="outerhome" style={styles.outerhome}>
                <div className="wrapper" style={styles.wrapper}>
                  <div
                    style={{
                      background: "#ffffff1c 0% 0% no-repeat padding-box",
                      borderRadius: "10px",
                    }}
                  >
                    <div style={styles.images}>
                      {babyDetails && babyDetails.image ? (
                        <img
                          src={babyDetails.image}
                          alt=""
                          className="homeimage"
                        />
                      ) : null}
                    </div>

                    <div
                      className="measure"
                      style={{ padding: "30px 0px", display: "flex" }}
                    >
                      <div className="size clintdaher">
                        <p
                          className="innerclintdaher"
                          style={{ padding: "10px 70px" }}
                        >
                          Length
                        </p>
                        <p
                          className="innerclintdaher"
                          style={{
                            fontSize: "16px",
                            textTransform: "capitalize",
                          }}
                        >
                          {" "}
                          {babyDetails && babyDetails.length}
                        </p>{" "}
                      </div>
                      <div className="size clintdaher">
                        <p
                          className="innerclintdaher"
                          style={{ padding: "10px 70px" }}
                        >
                          Weight
                        </p>
                        <p
                          className="innerclintdaher"
                          style={{
                            fontSize: "16px",
                            textTransform: "capitalize",
                          }}
                        >
                          {" "}
                          {babyDetails && babyDetails.weigth}
                        </p>
                      </div>
                      <div className="size clintdaher">
                        <p
                          className="innerclintdaher"
                          style={{ padding: "10px 70px" }}
                        >
                          Size
                        </p>
                        <p
                          className="innerclintdaher"
                          style={{
                            fontSize: "16px",
                            textTransform: "capitalize",
                          }}
                        >
                          {" "}
                          {babyDetails && babyDetails.size}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    className="homepurple"
                    style={{
                      padding: "20px 10px",
                      // background: "#471358",
                      borderRadius: "0 0 30px 30px",
                    }}
                  >
                    <small style={{ fontSize: "14px" }}>
                      {babyDetails && babyDetails.description}
                    </small>
                  </div>
                </div>
                <br />
                <br />
                <iframe
                  width="560"
                  height="315"
                  class="videoexerisise2"
                  src={images.link}
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <div className="wrapper">
                  <Banner />
                </div>

                {/* <div className="bannerscreen">
                  {console.log(images)}
                  <>
                  <img src={images && baseurl + images.image} />
                </div> */}
                <div className="boxes" style={styles.boxes}>
                  {/* {Package && Package.membership === "Trial Plan" ? null : (

                  )} */}
                  {sub === "false" ? null : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Link to="/learningportal">
                        <div className="box eqalboxmobile" style={styles.box}>
                          <img
                            style={styles.imgrem}
                            src={LearningPortal}
                            alt=""
                          />
                          <p style={styles.pp}>Learning Portal</p>
                        </div>
                      </Link>

                      <Link to="/dailytraker">
                        <div
                          className="box eqalboxmobile"
                          style={styles.box}
                          onClick={clickHandler}
                        >
                          <img
                            style={styles.imgrem}
                            src={DailyTrackerImg}
                            alt=""
                          />
                          <p style={styles.pp}>Daily Tracker</p>
                        </div>
                      </Link>
                    </div>
                  )}
                  <div
                    className="bouter"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Link to="/content">
                      {" "}
                      <div className="bbox eqalboxmobile" style={styles.box}>
                        <img style={styles.imgrem} src={Labour} alt="" />
                        <p style={styles.pp}>knowledge base</p>
                      </div>
                    </Link>
                    <Link to="/plan_list">
                      
                      <div className="bouter">
                        <div className="bbox eqalboxmobile" style={styles.box}>
                          <img style={styles.imgrem} src={Labour} alt="" />
                          <p style={styles.pp}>Course</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

const styles = {
  outerhome: { height: "600px", overflowY: "scroll", scrollbarWidth: "none" },
  wrapper: {
    padding: "0px",
    width: "90%",
    margin: "auto",
    // background: "#471358",
    borderRadius: "10px",
  },
  imgrem: {
    width: "4.5rem",
    marginTop: "5%",
  },
  images: {
    // display: "flex",
    alignItem: "center",
  },

  boxes: {
    padding: "11px",

    width: "80%",

    margin: "auto",
  },

  box: {
    height: "120px",
    width: "250px",
    background: "#ffffff1c 0% 0% no-repeat padding-box",
    margin: "20px 0px",
    borderRadius: "10px",
    cursor: "pointer",
  },

  pp: {
    background: "#f93580",
    margin: 0,
    fontSize: "18px",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
  },
};
