import React, { useState, useEffect } from "react";
import axios from "axios";
import "./facebook.css";
import icon from "./logo.png";

function FacebookTimeline() {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Token ${token}`,
    };
    axios
      .get("/customer/get-free-content/", {
        headers,
      })
      .then((response) => {
        setPosts(response.data.data);
        console.log('data',response.data)
      })
      .catch((error) => {
        
      });
  }, []);

  return (
    <div className="timeline">
      {posts.map((post) => (
        <div key={post.id} className="timeline-post">
          <div className="timeline-post-header">
            <img src={icon} alt="profile" />
            <div className="timeline-post-info">
              <h3>{post.name}</h3>
              <p></p>
            </div>
          </div>
          {post.video_url && (
            <div className="timeline-post-video">
              <iframe
                width="560"
                height="315"
                class="videoexerisise2"
                src={post.video_url}
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
              {/* <iframe
                title="video"
                src={post.video_url}
                frameBorder="0"
                allow="autoplay; encrypted-media"
                allowFullScreen
              ></iframe> */}
            </div>
          )}
          <p className="timeline-post-text">{post.crew}</p>
          {/* <div className="timeline-post-actions">
            <button>Like</button>
            <button>Comment</button>
            <button>Share</button>
          </div> */}
        </div>
      ))}
    </div>
  );
}

export default FacebookTimeline;
