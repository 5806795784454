import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { getAllExercise } from "../../../../helpers/service";
import UpdateCustomizedExercise from "./update_customized_exercise";
import ExersieTrackerUpdate from "./update_customized_exercise";

export default function ExerciseTracker(props) {
  var customer = props.customer;
  console.log(props);
  const [data, setData] = useState([]);
  const [CaloriesData, setCaloriesData] = useState([]);
  const [update, setUpdate] = useState(false);

  React.useEffect(() => {
    if (!!customer) {
      const request = fetchApi();

      return () => {
        request.cancel();
      };
    }
  }, [customer]);

  function updateOpen() {
    setUpdate(true);
  }
  function openUpdater() {
    setUpdate(true);
  }
  //   useEffect(() => {
  //     // if (localStorage.getItem("token") === null) {
  //     //   localStorage.clear();
  //     //   //window.location = "/clogin";
  //     // }
  //     const config = {
  //       headers: {
  //         Authorization: `token ` + localStorage.getItem("doc_token"),
  //       },
  //     };
  //remove this date after setting up the admin pannel

  const fetchApi = () => {
    const request = getAllExercise();

    request
      .api({
        params: {
          customer,
        },
      })
      .then((res) => {
        //   .then((res) => {
        console.log(res);
        const data = res.exercises.concat(res.calories);
        const maindata = data.concat(res.customExericises);

        const groupedByDate = maindata.reduce((exercises, exercise) => {
          if (exercise.date in exercises) {
            exercises[exercise.date].push(exercise);
          } else {
            exercises[exercise.date] = [exercise];
          }
          return exercises;
        }, {});

        console.log(groupedByDate);
        const sortedOnDate = Object.values(groupedByDate).sort((arr1, arr2) => {
          const ymdDate1 = arr1[0].date.split("-").reverse().join("-");
          const ymdDate2 = arr2[0].date.split("-").reverse().join("-");
          if (ymdDate1 < ymdDate2) {
            return 1;
          } else if (ymdDate1 > ymdDate2) {
            return -1;
          }
          return 0;
        });
        console.log(sortedOnDate);
        setData(sortedOnDate);
      });
    return request;
  };

  return (
    <>
      {update ? <ExersieTrackerUpdate userid={customer} /> : null}
      <div>
        {/* <button style={styles.sbtn} onClick={openUpdater}>
          Update Customised Exercise2
        </button> */}
        <div className="scrollerbar">
          <table
            style={{
              width: "100%",

              width: "41.6rem",
              borderSpacing: "0 1em",
              borderCollapse: "collapse",
            }}
          >
            <thead style={{ borderBottom: "5px solid #5c0048" }}>
              <tr
                style={{
                  background:
                    "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
                }}
                className="font-blue"
              >
                <th style={styles.th}>Date</th>
                <th style={styles.th}>Exercises</th>
                <th style={styles.th}>Calories</th>
              </tr>
            </thead>

            <tbody>
              {data.map((ele) => {
                console.log(ele);
                return (
                  <tr
                    style={{
                      background:
                        " 0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
                    }}
                    key={ele.id}
                  >
                    <td style={styles.tdFirst}> {ele[0].date}</td>
                    <td style={styles.td}>
                      {ele.map((ele) => {
                        return <div>{ele.exercise}</div>;
                      })}
                    </td>
                    <td style={styles.td}>
                      {ele.map((ele) => {
                        return <div>{ele.value}</div>;
                      })}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

const styles = {
  th: {
    fontSize: "17px",
    whiteSpace: "nowrap",
    padding: "5px 10px",
    margin: "10px 0px",
  },

  td: {
    background:
      "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
    borderLeft: "1px solid #fff",
    padding: "5px 10px",
    textAlign: "left",
    fontSize: "12px",
    border: "1px solid black",
  },

  tdFirst: {
    padding: "5px 10px",
    fontSize: "12px",
    border: "1px solid black",
  },

  sbtn: {
    border: "none",
    margin: "5px 0px",
    color: "#fff",
    background: "#E81A7F",
    padding: "8px",
    fontWeight: "bold",
    cursor: "pointer",
  },
};
