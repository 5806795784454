

export default function Tablelist(){
return (

<div style={styles.card}>
<div style={styles.inner}>
<p><strong>All Doctors List</strong></p>
<hr style={{ color:'#fff' }}/>
<table>
<thead>
<tr>
<td>Name</td>
<td>Age</td>
<td>Hospital</td>
<td>Ref ID</td>
<td>Experience</td>
<td>Profile</td>
<td>Account Status</td>
</tr>
</thead>
<tbody>
<tr>
<td>Dr Avinash</td>
<td>26</td>
<td>KIMS</td>
<td>113265</td>
<td>11yr</td>
<td><button>Details</button></td>
<td><select>
<option>Active</option>
<option>Disabled</option>
</select></td>
</tr>

</tbody>
</table>
</div>
</div>

);

}

const styles = {
card:{
display:'flex',
justifyContent:'space-between',
background:'#4c003d',
padding:'20px',
borderRadius:'10px',
margin:'10px'
},
inner:{
textDecoration:'none',
color:'#fff'
}

}


