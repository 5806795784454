import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Link } from "react-router-dom";
//import "react-tabs/style/react-tabs.css";
import Dietracker from "./tabs_com/diet_tracker";
import Medicinetracker from "./tabs_com/medicine_tracker";
import Exercisetracker from "./tabs_com/exercise_tracker";
import Symptomstracker from "./tabs_com/symptoms_tracker";
import Activitytracker from "./tabs_com/activity_tracker";
import Contractioncounter from "./tabs_com/contraction_counter";
import Medicaldetail from "./tabs_com/medical_detail";
var date;
class Clist extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isClose: true };
    this.closeTabBox = this.closeTabBox.bind(this);
    date = this.props.dataFromParent;
  }

  closeTabBox() {
    this.setState({ isClose: false });
  }

  render() {
    return (
      <>
        {this.state.isClose ? (
          <div className="multiple-tab  clint_calander" style={styles.card}>
            <i
              onClick={this.closeTabBox}
              className="fa fa-close"
              style={styles.closebtn}
            ></i>
            <div style={styles.inner}>
              <Tabs>
                <TabList
                  style={{
                    paddingLeft: "0px",
                    listStyle: "none",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Tab>
                    <strong style={styles.tabnav}>Diet Tracker</strong>
                  </Tab>
                  <Tab>
                    <strong style={styles.tabnav}>Medicine Tracker</strong>
                  </Tab>
                  <Tab>
                    <strong style={styles.tabnav}>Exercise Tracker</strong>
                  </Tab>
                  <Tab>
                    <strong style={styles.tabnav}>Symptoms Tracker</strong>
                  </Tab>
                  <Tab>
                    <strong style={styles.tabnav}>Activity Tracker</strong>
                  </Tab>
                  <Tab>
                    <strong style={styles.tabnav}>Contraction Counter</strong>
                  </Tab>
                  <Tab>
                    <strong style={styles.tabnav}>Medical Details</strong>
                  </Tab>
                </TabList>

                <TabPanel>
                  <Dietracker dataFromParent={date} />
                  {/* <Link
                    to={{
                      pathname: `/cdiet/${date}`,
                      state: { value: date },
                    }}
                  > */}
                  <Link
                    to={{
                      pathname: `/cdiet`,
                      state: { value: date },
                    }}
                  >
                    <button onClick={this.closeTabBox}>edit</button>
                  </Link>
                </TabPanel>

                <TabPanel>
                  <Medicinetracker dataFromParent={date} />
                </TabPanel>

                <TabPanel>
                  <Exercisetracker dataFromParent={date} />
                </TabPanel>

                <TabPanel>
                  <Symptomstracker dataFromParent={date} />
                </TabPanel>

                <TabPanel>
                  <Activitytracker dataFromParent={date} />
                </TabPanel>

                <TabPanel>
                  <Contractioncounter dataFromParent={date} />
                </TabPanel>

                <TabPanel>
                  <Medicaldetail dataFromParent={date} />
                </TabPanel>
              </Tabs>
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

const styles = {
  card: {
    display: "flex",
    justifyContent: "space-between",
    background: "rgba(17, 25, 40, 0.53)",

    backdropFilter: "blur(16px) saturate(180%)",
    padding: "20px",

    borderRadius: "10px",
    width: "76%",
    margin: "10px",
    position: "absolute",
    left: "290px",
    zIndex: 10,
    height: "auto",
  },

  inner: {
    textDecoration: "none",
    width: "100%",
    color: "#fff",
  },

  tabnav: {
    cursor: "pointer",
    fontSize: "14.3px",
    background: "none",
    whiteSpace: "nowrap",
    padding: "20px 10px",
  },

  closebtn: {
    position: "absolute",
    right: "0px",
    top: "-20px",
    cursor: "pointer",
  },
};

export default Clist;
