import React, { useEffect } from "react";
// import { getAllActivityData } from "../../../../helpers/service";
import axios from "axios";
import { DateTime } from "luxon";
// import { useParams } from "react-router";

/**
 * https://stackoverflow.com/questions/71536156/display-custom-data-not-worrking-react-js
 */

export default function ActivityTracker({ id }) {
  //   var userid = props.userid1;
  const [data, setData] = React.useState({});

  const [columns, setColumns] = React.useState([]);

  useEffect(() => {
    //   const config = {
    //     headers: {
    //       Authorization: `token ` + localStorage.getItem("doc_token"),
    //     },
    //   };

    // axios;
    axios
      .get(`customer/get-all-activity-data/`, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
        params: {
          customer: id,
        },
      })

      .then((response) => {
        if (response) {
          // if (response.data.Predefined) {
          const modules = response.data.Predefined.reduce((obj, item) => {
            if (!obj.data && !obj.mainModules) {
              obj.data = {};
              obj.mainModules = [];
            }

            if (!obj.data[item.date]) {
              obj.data[item.date] = {};
            }

            if (!obj.data[item.date][item.mainModule]) {
              obj.data[item.date][item.mainModule] = [];
            }

            obj.data[item.date][item.mainModule] =
              obj.data[item.date][item.mainModule].concat(item);

            if (obj.mainModules.indexOf(item.mainModule) < 0) {
              obj.mainModules = obj.mainModules.concat(item.mainModule);
            }

            return obj;
          }, {});

          const customs = response.data.Custom.reduce((obj, item) => {
            if (!obj[item.date]) {
              obj[item.date] = {
                customs: [],
              };
            }

            obj[item.date].customs = obj[item.date].customs.concat(item);

            return obj;
          }, {});

          const data = Object.entries(modules.data || {}).reduce(
            (obj, [date, data]) => ({
              ...obj,
              [date]: { data },
            }),
            {}
          );

          const data2 = Object.entries(customs || {}).reduce(
            (obj, [date, data]) => ({
              ...obj,
              [date]: {
                ...obj[date],
                ...data,
              },
            }),
            data
          );

          const sortedData = Object.fromEntries(
            Object.entries(data2).sort(([dateA], [dateB]) =>
              DateTime.fromFormat(dateB, "dd-MM-yyyy")
                .toISODate()
                .localeCompare(
                  DateTime.fromFormat(dateA, "dd-MM-yyyy").toISODate()
                )
            )
          );

          setData(sortedData);

          if (modules) {
            setColumns(modules.mainModules);
          }
        }
        // }
      });

    // return request;
  }, []);

  const Row = ([date, item], index) => {
    return (
      <tr
        className=""
        style={{
          background:
            "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
        }}
        key={date || index}
      >
        <td style={styles.tdFirst}>{date}</td>

        {columns.map((each, i) => (
          <td key={i} style={styles.td}>
            {item.data &&
              item.data[each] &&
              item.data[each].map((itemByModule, id) => (
                <span key={id}>
                  {itemByModule && itemByModule.submodule}
                  <br />
                </span>
              ))}
          </td>
        ))}

        <td style={styles.td}>
          {item.customs &&
            item.customs.map((each, i) => (
              <span key={i}>
                {each && each.activity}
                <br />
              </span>
            ))}
        </td>
      </tr>
    );
  };

  // React.useEffect(() => {
  //   if (!!customer) {
  //     const request = fetchApi();

  //     return () => {
  //       request.cancel();
  //     };
  //   }
  // }, [customer]);

  return (
    <div className="scrollerbar">
      <table
        style={{
          width: "100%",
          borderSpacing: "0px 1em",
          borderCollapse: "collapse",
        }}
      >
        <thead style={{ borderBottom: "5px solid #5c0048" }}>
          <tr className=" font-blue">
            <th style={styles.th}>Dates</th>
            {columns ? (
              columns.map((each) => (
                <th style={styles.th} key={each}>
                  {each}
                </th>
              ))
            ) : (
              <th>no Data</th>
            )}
            <th style={styles.th}>Custom</th>
          </tr>
        </thead>

        <tbody>{Object.entries(data).map(Row)}</tbody>
      </table>
    </div>
  );
}

const styles = {
  th: {
    fontSize: "17px",
    whiteSpace: "nowrap",
    background:
      "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
    padding: "5px 10px",
    margin: "10px 0px",
  },

  td: {
    border: "1px solid black",
    padding: "5px 10px",
    fontSize: "12px",
    background:
      "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
    textAlign: "left",
    lineHeight: "20px",
  },

  tdFirst: { border: "1px solid black", padding: "5px 10px", fontSize: "12px" },
};

// import React, { useState } from "react";
// import { useEffect } from "react";
// import axios from "axios";
// import { useParams } from "react-router";
// export default function ActivityTracker(props) {
//   const [data, setData] = useState([]);
//   const [heading, setHeading] = useState([]);
//   const { id } = useParams();
//   var userid = props.userid1;
//   console.log(userid.id);

//   useEffect(() => {
//     const config = {
//       headers: {
//         Authorization: `token ` + localStorage.getItem("doc_token"),
//       },
//     };
//     //remove this date after setting up the admin pannel

//     axios
//       .get(
//         "/customer/get-all-activity-data/?customer=" + userid.id,
//         config

//         // config
//       )
//       //   useEffect(() => {
//       //     axios
//       //       .get(`https://shebirth.herokuapp.com/customer/get-all-activity-data/`, {
//       //         headers: {
//       //           Authorization: `Token ` + sessionStorage.getItem("token"),
//       //         },
//       //         params: {
//       //           customer: id,
//       //         },
//       //       })
//       .then((res) => {
//         const maindata = res.data.Predefined.concat(res.data.Custom);
//         const groupedByDate = maindata.reduce((exercises, exercise) => {
//           if (exercise.date in exercises) {
//             exercises[exercise.date].push(exercise);
//           } else {
//             exercises[exercise.date] = [exercise];
//           }
//           return exercises;
//         }, {});
//         const groupedBymaindata = res.data.Predefined.reduce(
//           (exercises, exercise) => {
//             if (exercise.mainModule in exercises) {
//               exercises[exercise.mainModule].push(exercise);
//             } else {
//               exercises[exercise.mainModule] = [exercise];
//             }
//             return exercises;
//           },
//           {}
//         );

//         const sortedOnDate = Object.values(groupedByDate).sort((arr1, arr2) => {
//           if (arr1[0].date < arr2[0].date) {
//             return -1;
//           } else if (arr1[0].date > arr2[0].date) {
//             return 1;
//           }
//           return 0;
//         });

//         const sortedOnmainmodule = Object.values(groupedBymaindata).sort(
//           (arr1, arr2) => {
//             if (arr1[0].date < arr2[0].date) {
//               return -1;
//             } else if (arr1[0].date > arr2[0].date) {
//               return 1;
//             }
//             return 0;
//           }
//         );
//         setHeading(sortedOnmainmodule);

//         setData(sortedOnDate);
//       });
//   }, []);

//   return (
//     <div className="table-cover">
//       <table
//         style={{
//           width: "100%",
//           borderCollapse: "seprate",
//           width: "41.6rem",
//           borderSpacing: "0 1em",
//         }}
//       >
//         <thead style={{ borderBottom: "5px solid #5c0048" }}>
//           <tr
//             style={{
//               background:
//                 "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
//             }}
//           >
//             <th style={styles.th}>Date</th>
//             {heading.map((ele) => {
//               return <th style={styles.th}>{ele[0].mainModule}</th>;
//             })}

//             <th style={styles.th}>Custom Module</th>
//           </tr>
//         </thead>

//         <tbody>
//           {data.map((ele) => {
//             console.log(ele);
//             return (
//               <tr
//                 style={{
//                   background:
//                     "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
//                 }}
//                 key={ele.id}
//               >
//                 <td style={styles.tdFirst}> {ele[0].date}</td>
//                 <td style={styles.td}>
//                   {ele.map((ele) => {
//                     return (
//                       <div>
//                         {ele.mainModule == "main module 1" && ele.submodule}
//                       </div>
//                     );
//                   })}
//                 </td>
//                 <td style={styles.td}>
//                   {ele.map((ele) => {
//                     return (
//                       <div>{ele.mainModule == "bonding" && ele.submodule}</div>
//                     );
//                   })}
//                 </td>
//                 <td style={styles.td}>
//                   {ele.map((ele) => {
//                     return (
//                       <div>
//                         {ele.mainModule == "new main module 1" && ele.submodule}
//                       </div>
//                     );
//                   })}
//                 </td>
//                 <td style={styles.td}>
//                   {ele.map((ele) => {
//                     return (
//                       <div>
//                         {ele.mainModule == "new main module" && ele.submodule}
//                       </div>
//                     );
//                   })}
//                 </td>
//                 <td style={styles.td}>
//                   {ele.map((ele) => {
//                     return (
//                       <div>
//                         {ele.mainModule == "new main module 2" && ele.submodule}
//                       </div>
//                     );
//                   })}
//                 </td>
//                 <td style={styles.td}>
//                   {ele.map((ele) => {
//                     return <div>{ele.activity && ele.submodule}</div>;
//                   })}
//                 </td>
//               </tr>
//             );
//           })}
//         </tbody>
//       </table>
//     </div>
//   );
// }

// const styles = {
//   th: {
//     fontSize: "17px",
//     whiteSpace: "nowrap",
//     padding: "5px 10px",
//     margin: "10px 0px",
//   },

//   td: {
//     borderLeft: "1px solid #fff",
//     padding: "5px 10px",
//     fontSize: "12px",
//     textAlign: "left",
//     lineHeight: "20px",
//   },

//   tdFirst: {
//     padding: "5px 10px",
//     fontSize: "12px",
//   },
// };
