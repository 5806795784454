import React from "react";
import Sidebar from "../components/sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faUser, faBars } from "@fortawesome/free-solid-svg-icons";
import Toggler from "../toggle";
import ClientProfile from "../tog_profile";
//import Client from './components/clients';
//import Doctor from './components/doctors';
// import Table from "./components/clients_table";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Bar2 from "./defaultanalatics";
import TotalClients from "../components/clients";
import TotalClientsAllTime from "../components/alltime_clients";
// import Clients from "./components/client_lists";
import axios from "axios";
import { Redirect } from "react-router";

export default class Overview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isToggleOn: true,
      list: [],
      userdetails: [],
      count: "",
      isProfile: true,
      redirect: null,
      searchval: "",
      ccountmonth: "",
    };
    this.handleClick = this.handleClick.bind(this);
    this.clickedOutside = this.clickedOutside.bind(this);
    this.profileHandler = this.profileHandler.bind(this);
  }

  componentDidMount() {
    const token = localStorage.getItem("token");
    if (!token) {
      this.setState({ redirect: "/clogin" });
    }

    axios
      .get("consultant/consultant-dashboard-details/", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        this.setState({ list: res.data.all_clients });
        this.setState({ count: res.data.total_clients });
        this.setState({ ccountmonth: res.data.clients_this_month });
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get("/login-user-data/", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        this.setState({ userdetails: res.data });
        // this.setState({ count: res.data.total_clients });
        // this.setState({ ccountmonth: res.data.clients_this_month });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // componentDidMount() {
  //   const token = localStorage.getItem("token");
  //   if (!token) {
  //     this.setState({ redirect: "/clogin" });
  //   }

  //   axios
  //     .get("/login-user-data/", {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Accept: "application/json",
  //         Authorization: `Token ${localStorage.getItem("token")}`,
  //       },
  //     })
  //     .then((res) => {
  //       this.setState({ userdetails: res.data });
  //       // this.setState({ count: res.data.total_clients });
  //       // this.setState({ ccountmonth: res.data.clients_this_month });
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }
  profileHandler() {
    this.setState({ isProfile: !this.state.isProfile });
  }

  handleClick() {
    this.setState((prevState) => ({
      isToggleOn: !prevState.isToggleOn,
    }));
  }

  clickedOutside(e) {
    if (this.isWap && !this.isWap.contains(e.target)) {
      this.setState({ isToggleOn: true });
      this.setState({ isProfile: true });
    }
  }

  render() {
    function openMenu() {
      document.querySelector(".sidebar").classList.toggle("show");
    }

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    return (
      <div className="App">
        <Sidebar />
        <header className="overview">
          <div
            className="top-header grandiant"
            style={{ paddingRight: "10%", textAlign: "right" }}
          >
            {" "}
            <div>{this.state.isToggleOn ? null : <Toggler />}</div>
            <FontAwesomeIcon
              onClick={this.handleClick}
              style={{ marginRight: "1rem", cursor: "pointer" }}
              icon={faBell}
            />
            <FontAwesomeIcon
              onClick={this.profileHandler}
              style={{ cursor: "pointer" }}
              icon={faUser}
            />{" "}
            {this.state.userdetails.firstname}
          </div>
          {this.state.isProfile ? null : <ClientProfile />}{" "}
          <div style={{ width: "89%", display: "flex", paddingLeft: "10px" }}>
            <span onClick={openMenu} className="menu">
              <FontAwesomeIcon
                // onClick={this.profileHandler}
                style={{ marginRight: "20px" }}
                icon={faBars}
                className="menu-icon"
              />
            </span>

            <span style={{ whiteSpace: "nowrap" }}>Consultants</span>
            <div style={{ padding: "4px", width: "100%" }}>
              <hr style={{ background: "white", color: "white" }} />
            </div>
          </div>
          <div className="mwrapper">
            <div className="mleft">
              {/* <div style={styles.card} className="search-form">
                <div style={styles.inner}>
                  <p style={{ textAlign: "left" }}>Search Clients</p>
                  <FontAwesomeIcon
                    style={{ marginRight: "-22px" }}
                    icon={faSearch}
                  />
                  <input
                    style={styles.input}
                    onChange={(e) => {
                      this.setState({ searchval: e.target.value });
                    }}
                    type="Search"
                  />
                </div>
              </div> */}
              <Bar2 />
            </div>
            <div className="mright">
              <div
                style={{
                  fontWeight: "bold",
                  textAlign: "left",
                  paddingLeft: "10px",
                }}
              ></div>
              {/* client this month */}
              <TotalClients ccountmonth={this.state.ccountmonth} />
              <TotalClientsAllTime totalClients={this.state.count} />
              {/* <div style={styles.client_list}>
                <strong>Clients</strong>
              </div>
              <Clients /> */}
            </div>
          </div>
        </header>
      </div>
    );
  }
}

const styles = {
  card: {
    display: "flex",
    justifyContent: "space-between",
    background: "#ffffff1c 0% 0% no-repeat padding-box",
    padding: "20px",
    borderRadius: "10px",
    margin: "10px",
  },
  inner: {
    textDecoration: "none",
    color: "#fff",
  },

  input: {
    width: "40rem",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderBottomColor: "1px solid #fff",
    background: "transparent",
    padding: "8px",
    marginLeft: "30px",
    outline: "none",
    color: "white",
  },
};
