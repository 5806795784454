import axios from "axios";
import qs from "qs";
import Submodules from "./submodules";
import { useState, useEffect } from "react";
export default function Learning_form() {
  const [stage, setStage] = useState("stage1");
  const [Traker, setTraker] = useState("exercise");
  const [exersice, setexersice] = useState("");
  const [Refresh, setRefresh] = useState(0);
  console.log(exersice);
  const [Items, setItems] = useState([]);
  const [submodules, setsubmodules] = useState(false);
  const [Submoduleid, setSubmoduleid] = useState();
  const [Activity, setActivity] = useState([]);
  const [ALLActivity, setAllActivity] = useState([]);

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (!token) {
      this.setState({ redirect: "/admin/login" });
    }

    axios
      .get(`admin-panel/all-excercises-per-stage/`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Token ${sessionStorage.getItem("token")}`,
        },
        params: {
          stage: stage,
        },
      })
      .then((res) => {
        setItems(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`admin-panel/all-activity-main-modules-per-stage/`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Token ${sessionStorage.getItem("token")}`,
        },
        params: {
          stage: stage,
        },
      })
      .then((res) => {
        setActivity(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [stage, Refresh]);

  const handleChange = (e) => {
    console.log(e);
    setStage(e);

    /// console.log(1);
  };
  const getActivity = (e) => {
    setAllActivity({ ...ALLActivity, [e.target.name]: e.target.value });
  };

  const submitnewActivity = (e) => {
    const Data = {
      ...ALLActivity,
      id: e,
    };
    const headers = {
      Authorization: `Token ${sessionStorage.getItem("token")}`,
    };

    console.log(e);
    axios
      .patch(
        "admin-panel/edit-activity-main-module/",
        Data,

        {
          headers: headers,
        }
      )

      .then(() => {
        alert("success submited");
        setRefresh(Refresh + 1);
      })
      .catch((error) => {
        alert(error);
      });
  };

  const handleTraker = (e) => {
    console.log(e);
    setTraker(e);

    /// console.log(1);
  };

  const getNewexersise = (e) => {
    setexersice(e.target.value);

    /// console.log(1);
  };
  const submitnewexersise = (e) => {
    const Data = {
      id: e,
      exercise: exersice,
    };
    const headers = {
      Authorization: `Token ${sessionStorage.getItem("token")}`,
    };

    // console.log(e);
    axios
      .patch("admin-panel/edit-exercise/", Data, {
        headers: headers,
      })

      .then(() => {
        alert("success submited");
        setRefresh(Refresh + 1);
      })
      .catch((error) => {
        alert(error);
      });
  };

  const submitValue = (e) => {
    const Data = {
      id: e,
      //   // stage: "stage9",
      //   notes: notes,
    };
    const headers = {
      Authorization: `Token ${sessionStorage.getItem("token")}`,
    };

    // console.log(e);
    axios
      .patch("admin-panel/toggle-exercise/", Data, {
        headers: headers,
      })

      .then(() => {
        // setincrement([...increment,res.data]) console.log(Data);
        //console.log("submited ");
        alert("success submited");
        //console.log(Data);
        setRefresh(Refresh + 1);
        //window.location.reload();
      })
      .catch((error) => {
        alert(error);

        //console.log(Data);
        // console.log("handlesubmit error for blog ", error);
      });
  };

  const toggleactivitymain = (e) => {
    const Data = {
      id: e,
      //   // stage: "stage9",
      //   notes: notes,
    };
    const headers = {
      Authorization: `Token ${sessionStorage.getItem("token")}`,
    };

    // console.log(e);
    axios
      .patch("admin-panel/toggle-activity-main-module/", Data, {
        headers: headers,
      })

      .then(() => {
        // setincrement([...increment,res.data]) console.log(Data);
        //console.log("submited ");
        alert("success submited");
        //console.log(Data);
        setRefresh(Refresh + 1);
        //window.location.reload();
      })
      .catch((error) => {
        alert(error);

        //console.log(Data);
        // console.log("handlesubmit error for blog ", error);
      });
  };

  const opensubmodules = (e) => {
    setsubmodules(!submodules);
    setSubmoduleid(e);
  };

  //     .catch((error) => {
  //       alert("error occured when updating");
  //       //console.log(Data);
  //       // console.log("handlesubmit error for blog ", error);
  //     });

  //   console.log(Data);
  // };

  return (
    <>
      {submodules ? <Submodules subid={Submoduleid} /> : null}
      <form className="learning-form">
        <div style={{ margin: "0px" }}>
          <table className="learning-table-form">
            <tr>
              <td style={styles.td}>Select Tracker</td>
              <td>
                <select
                  onChange={(e) => handleTraker(e.target.value)}
                  // value={selectedValue}
                  required
                  // onChange={(e) => setPeriod(e.target.value)}
                  // value={optionsState}
                  style={styles.select}
                >
                  <option style={styles.option} value="exercise">
                    Exercise
                  </option>
                  <option style={styles.option} value="activity">
                    Activity
                  </option>
                </select>
              </td>
              <td style={styles.td}>Select Period</td>
              <td>
                <select
                  onChange={(e) => handleChange(e.target.value)}
                  // onChange={handleChange}
                  // value={selectedValue}
                  required
                  // onChange={(e) => setPeriod(e.target.value)}
                  // value={optionsState}
                  style={styles.select}
                >
                  <option style={styles.option} value="stage1">
                    0-4 weeks
                  </option>
                  <option style={styles.option} value="stage2">
                    4-8 weeks
                  </option>
                  <option style={styles.option} value="stage3">
                    8-12 weeks
                  </option>
                  <option style={styles.option} value="stage4">
                    12-16 weeks
                  </option>
                  <option style={styles.option} value="stage5">
                    16-20 weeks
                  </option>
                  <option style={styles.option} value="stage6">
                    20-24 weeks
                  </option>
                  <option style={styles.option} value="stage7">
                    24-28 weeks
                  </option>
                  <option style={styles.option} value="stage8">
                    28-32 weeks
                  </option>
                  <option style={styles.option} value="stage9">
                    32-36 weeks
                  </option>
                  <option style={styles.option} value="stage10">
                    36-40 weeks
                  </option>
                </select>
              </td>
            </tr>
          </table>

          <div className="editform gradiantblur">
            <p>Modules</p>
            <hr />
            {Traker == "exercise" ? (
              <table>
                {Items.map((itm, key) => (
                  <tr>
                    <td>
                      <input
                        style={styles.textfild}
                        type="text"
                        defaultValue={itm.name}
                        onChange={(e) => getNewexersise(e)}
                      />
                    </td>{" "}
                    <td>
                      <button
                        type="button"
                        onClick={() => submitnewexersise(itm.id)}
                      >
                        Edit
                      </button>
                    </td>
                    <td>
                      {itm.disabled == false ? (
                        <button
                          type="button"
                          className="pinkbutton"
                          onClick={() => submitValue(itm.id)}
                        >
                          Enable
                        </button>
                      ) : (
                        <button
                          className="greenbutton"
                          type="button"
                          onClick={() => submitValue(itm.id)}
                        >
                          Disabled
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </table>
            ) : (
              <>
                {console.table(Activity)}
                <table className="activityeditform">
                  <tr>
                    <th>
                      <small className="headertable">Main module</small>
                    </th>{" "}
                    <th>
                      <small className="headertable">Url</small>
                    </th>
                    <th>
                      <small className="headertable">description</small>
                    </th>
                    <th>
                      <small className="headertable">Update </small>
                    </th>
                    <th>
                      <small className="headertable">Sub modules</small>
                    </th>
                    <th>
                      <small className="headertable">Status</small>
                    </th>
                  </tr>
                  {Activity.map((itm, key) => (
                    <tr>
                      <td>
                        <input
                          type="text"
                          name="name"
                          defaultValue={itm.name}
                          onChange={(e) => getActivity(e)}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="url"
                          defaultValue={itm.url}
                          onChange={(e) => getActivity(e)}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="description"
                          defaultValue={itm.description}
                          onChange={(e) => getActivity(e)}
                        />
                      </td>
                      <td>
                        <button
                          type="button"
                          onClick={() => submitnewActivity(itm.id)}
                        >
                          edit
                        </button>
                      </td>
                      <td>
                        <button
                          type="button"
                          onClick={() => opensubmodules(itm.id)}
                        >
                          Edit Sub modules
                        </button>
                      </td>

                      <td>
                        {itm.disabled == false ? (
                          <button
                            type="button"
                            className="pinkbutton"
                            onClick={() => toggleactivitymain(itm.id)}
                          >
                            Enable
                          </button>
                        ) : (
                          <button
                            className="greenbutton"
                            type="button"
                            onClick={() => toggleactivitymain(itm.id)}
                          >
                            Disabled
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </table>
              </>
            )}
          </div>
        </div>
      </form>
    </>
  );
}

const styles = {
  td: {
    textAlign: "left",
    borderBottom: "4px",
  },
  select: {
    width: "13.5rem",
    textAlign: "center",
    padding: "6px",
    border: "1px solid #fff",
    background: "transparent",
    color: "#fff",
  },
  textfild: { color: "#fff" },
  input: {
    border: "1px solid #fff",
    padding: "6px",
    borderRadius: "3px",
    background: "transparent",
    color: "#fff",
  },
  upload: {
    width: "13.5rem",
    textAlign: "center",
    padding: "6px",
    border: "1px solid #fff",
    background: "transparent",
    color: "#fff",
    borderRadius: "3px",
  },
  button1: {
    background: "#e81a7f",
    padding: "10px 80px",
    border: "1px solid #e81a7f",
    width: "230px",
  },
  option: {
    background: "#e81a7f",

    border: "1px solid #e81a7f",
  },
};
