import AMan from "../../img/man.jpg";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
export default function Summery(props) {
  // console.log("dsdfsfs", props.userid);
  const [userdata, setuserdata] = useState({});
  const [summery, setsummery] = useState();
  const [summeryold, setsummeryold] = useState();
  function handleSubmit(e) {
    e.preventDefault();

    const Data = {
      appointmentID: props.userid,
      summary: summery,
    };

    const headers = {
      Authorization: `token ` + localStorage.getItem("doc_token"),
    };

    axios
      .post("appointments/add-summary/", Data, {
        headers: headers,
      })
      .then(() => {
        alert("submited ");
      })
      .catch((error) => {
        alert("error occoured ", error);
      });
  }

  function handleChange(e) {
    setsummery(e.target.value);
  }

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("doc_token"),
      },
    };
    //remove this date after setting up the admin pannel

    axios
      .get(
        "appointments/get-summary-doctor/?appointmentID=" + props.userid,
        config
      )

      .then((res) => {
        setsummeryold(res.data.summary);
        setuserdata(res.data.client_details);
      });
  }, []);

  return (
    <>
      <div style={styles.card} className="gradiantblur">
        <strong style={styles.cardheader}>summary</strong>
        <div style={styles.inner}>
          <div className="width-85" style={styles.upcom}>
            <img src={userdata.profile_img} alt="" style={styles.manpic} />
            <div style={{ textAlign: "left" }}>
              <strong>
                {userdata.client_firstname} {userdata.client_lastname}
              </strong>
              <br />
              <small style={styles.info}>
                <span>Age</span> <span> {userdata.age} </span>
                <br />
                <hr style={styles.hr} />
                <span>{userdata.mobile}</span>
                <hr style={styles.hr} />
              </small>
            </div>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div style={{ padding: "20px" }}>
            <div style={styles.disc} className="gradiantblur">
              <textarea
                className="gradiantblur"
                style={{ width: "100%", height: "200px", color: "white" }}
                defaultValue={summeryold}
                onChange={handleChange}
              ></textarea>
            </div>
          </div>
          <button className="pinkbutton" type="submit">
            Send Summary To Client
          </button>
        </form>
      </div>
    </>
  );
}

const styles = {
  card: {
    display: "flex",
    justifyContent: "space-between",
    // backdropFilter: "blur(71px) brightness(-15%) opacity(0.5)",
    padding: "0px",
    borderRadius: "10px",
    margin: "10px",
    flexDirection: "column",
  },

  inner: {
    textDecoration: "none",
    color: "#fff",
  },

  info: {
    fontSize: "12px",
  },

  hr: {
    color: "#fff",
  },

  cardheader: {
    // background: "#55005C",
    textAlign: "center",
    padding: "10px 8px",
    width: "auto",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    borderBottom: "1px solid gray",
  },

  manpic: {
    width: "80px",
    height: "80px",
    borderRadius: "50%",
    marginRight: "10px",
  },

  upcom: {
    display: "flex",
    justifyContent: "space-between",
    width: "20rem",
    padding: "30px",
  },

  disc: {
    borderRadius: "10px",
    padding: "10px",
    textAlign: "left",
    // background: "#A460B4",
  },
};
