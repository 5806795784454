import React, { useState } from 'react';
import './cal_window.css'
const FoodCalorieTable = () => {
  const [showTable, setShowTable] = useState(false);

  const foodCalList = [
    { food: "Small guava", serving: "1", calories: "32cal" },
    { food: "Small orange", serving: "1", calories: "37cal" },
    { food: "Small pear", serving: "1", calories: "37cal" },
    { food: "Small mango", serving: "1", calories: "42cal" },
    { food: "Small apple", serving: "1", calories: "62cal" },
    { food: "Small peach", serving: "1", calories: "40cal" },
    { food: "Sweet lime", serving: "1", calories: "27cal" },
    { food: "Banana", serving: "1", calories: "110cal" },
    { food: "Watermelon", serving: "few slices", calories: "20cal" },
    { food: "Litchis", serving: "few slices", calories: "53cal" },
    { food: "Melon", serving: "1 bowl", calories: "23cal" },
    { food: "Strawberries", serving: "1 bowl", calories: "25cal" },
    { food: "Pomegranate", serving: "1 bowl", calories: "55cal" },
    { food: "Cherries", serving: "1 bowl", calories: "60cal" },
    { food: "Grapes", serving: "1 bowl", calories: "60cal" },
    { food: "Bread", serving: "1", calories: "45cal" },
    { food: "Small poori", serving: "1", calories: "75cal" },
    { food: "Roti", serving: "1", calories: "100cal" },
    { food: "Parantha", serving: "1", calories: "150cal" },
    { food: "Aloo parantha", serving: "1", calories: "170cal" },
    { food: "Pav", serving: "1", calories: "180cal" },
    { food: "Naan", serving: "1", calories: "262cal" },
    { food: "Butter Naan", serving: "1", calories: "310cal" },
    { food: "Plain milk", serving: "1 cup", calories: "60cal" },
    { food: "Milk with added flavours", serving: "1 cup" },
    { food: "Fruit juice", serving: "1 cup", calories: "120cal" },
    { food: "Tender coconut", serving: "1 cup", calories: "15cal" },
    { food: "Soup", serving: "1 bowl", calories: "75cal" },
    { food: "Cold drink", serving: "1 bottle", calories: "90cal" },
    { food: "Milkshake", serving: "1 bottle", calories: "200cal" },
    { food: "Papad", serving: "1", calories: "45cal" },
    { food: "Idli", serving: "1", calories: "100cal" },
    { food: "Plain dosa", serving: "1", calories: "120cal" },
    { food: "Masala dosa", serving: "1", calories: "250cal" },
    { food: "Vermicelli", serving: "100 gm", calories: "333cal" },
    { food: "Ragi", serving: "100 gm", calories: "320cal" },
    { food: "Quinoa", serving: "100 gm", calories: "328cal" },
    { food: "Tofu", serving: "100 gm", calories: "76cal" },
    { food: "Raita", serving: "1 serving", calories: "20cal" },
    { food: "Sugar", serving: "1 spoon", calories: "30cal" },
    { food: "Salad", serving: "1 plate", calories: "100cal" },
    { food: "Vegetable Rice", serving: "1 plate", calories: "200cal" },
    { food: "Boiled Rice", serving: "1 cup", calories: "120cal" },
    { food: "Fried Rice", serving: "1 cup", calories: "150cal" },
    { food: "Cereal or oats with milk", serving: "1 cup", calories: "150cal" },
    { food: "Sambar", serving: "1 cup", calories: "150cal" },
    { food: "Curd", serving: "1 cup", calories: "100cal" },
    { food: "Any lentils or dhal", serving: "1 cup", calories: "150cal" },
    { food: "Egg", serving: "1", calories: "75cal" },
    { food: "Boiled egg", serving: "1", calories: "80cal" },
    { food: "Fried egg", serving: "1", calories: "110cal" },
    { food: "Omelette", serving: "1", calories: "120cal" },
    { food: "Meat", serving: "1 plate", calories: "450cal" },
    { food: "Mutton biryani", serving: "1 cup", calories: "225cal" },
    { food: "Chicken biryani", serving: "1 cup", calories: "225cal" },
    { food: "Fried chicken", serving: "1 serving", calories: "200cal" },
    { food: "Chicken curry", serving: "1 serving", calories: "225cal" },
    { food: "Tandoori Chicken", serving: "1 serving", calories: "260cal" },
    { food: "Butter chicken", serving: "1 serving", calories: "490cal" },
    { food: "Chicken tikka masala", serving: "1 serving", calories: "457cal" },
  ];
  

  const handleClick = () => {
    setShowTable(!showTable);
  };
  const handleClickclose = () => {
    setShowTable(false);
  };


  return (
    <div>
      <button onClick={handleClick} style={{fontSize:'10px'}} >ml of food</button>
      {showTable && (
        <div className="modal">
          <div className="modal-content">
            <button onClick={handleClickclose}>
              X
            </button>
            <table>
              <thead>
                <tr  >
                  <th>Food</th>
                  <th>Serving Size</th>
                  <th>Calories</th>
                </tr>
              </thead>
              <tbody>
                {foodCalList.map((item, index) => (
                  <tr key={index}>
                    <td>{item.food}</td>
                    <td>{item.serving}</td>
                    <td>{item.calories}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default FoodCalorieTable;
