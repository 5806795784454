import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router";
export default function MedicalDetail(props) {
  const [medicaldata, setMedicaldata] = useState([]);
  const { id } = useParams();
  useEffect(() => {
    axios
      .get(`customer/medical-get-all/`, {
        headers: {
          Authorization: `Token ` + sessionStorage.getItem("token"),
        },
        params: {
          customer: props.userid1,
        },
      })
      .then((res) => {
        setMedicaldata(res.data.investigation_data);
      });
  }, []);

  return (
    <table style={{ width: "100%", borderCollapse: "collapse" }}>
      <thead>
        <tr style={{ background: "#ffffff1c 0% 0% no-repeat padding-box" }}>
          <th style={styles.th}>Date</th>
          <th style={styles.th}>Hb</th>
          <th style={styles.th}>Hb normal</th>
          <th style={styles.th}>ICT</th>
          <th style={styles.th}>ICT normal</th>
          <th style={styles.th}>Urine R/E</th>
          <th style={styles.th}>Urine R/E normal</th>
          <th style={styles.th}>Urine C/S</th>
          <th style={styles.th}>Urine C/S normal</th>
          <th style={styles.th}>vdrl</th>
          <th style={styles.th}>vdrl normal</th>
          <th style={styles.th}>HIV</th>
          <th style={styles.th}>HIV normal</th>
          <th style={styles.th}>RBS first trimester</th>
          <th style={styles.th}>RBS first trimester normal</th>
          <th style={styles.th}>OGCT 2nd trimester - if raised</th>
          <th style={styles.th}>OGCT 2nd trimester - if raised normal</th>
          <th style={styles.th}>OGTT+</th>
          <th style={styles.th}>OGTT+ normal</th>
          <th style={styles.th}>hcv</th>
          <th style={styles.th}>hcv normal</th>
          <th style={styles.th}>creatine</th>
          <th style={styles.th}>creatine normal</th>
          <th style={styles.th}>double marker</th>
          <th style={styles.th}>double marker normal</th>
          <th style={styles.th}>tft </th>
          <th style={styles.th}>tft normal</th>
          <th style={styles.th}>tft description+</th>
          <th style={styles.th}>others</th>
          <th style={styles.th}>others normal</th>
          <th style={styles.th}>others description</th>
          <th style={styles.th}>scan</th>
        </tr>
      </thead>

      <tbody>
        {medicaldata.map((ele) => {
          return (
            <tr>
              <td style={styles.tdFirst}>{ele.date}</td>
              <td style={styles.tdFirst}>{ele.hb_value}</td>
              <td style={styles.tdFirst}>{ele.hb_normal}</td>
              <td style={styles.tdFirst}>{ele.ict_value}</td>
              <td style={styles.tdFirst}>{ele.ict_normal}</td>
              <td style={styles.tdFirst}>{ele.urineRE_value}</td>
              <td style={styles.tdFirst}>{ele.urineRE_normal}</td>
              <td style={styles.tdFirst}>{ele.urineCS_value}</td>
              <td style={styles.tdFirst}>{ele.urineCS_normal}</td>
              <td style={styles.tdFirst}>{ele.vdrl_value}</td>
              <td style={styles.tdFirst}>{ele.vdrl_normal}</td>
              <td style={styles.tdFirst}>{ele.hiv_value}</td>
              <td style={styles.tdFirst}>{ele.hiv_normal}</td>
              <td style={styles.tdFirst}>{ele.rbs_first_trimester_value}</td>
              <td style={styles.tdFirst}>{ele.rbs_first_trimester_normal}</td>
              <td style={styles.tdFirst}>{ele.ogct_second_trimester_value}</td>
              <td style={styles.tdFirst}>{ele.ogct_second_trimester_normal}</td>
              <td style={styles.tdFirst}>{ele.ogtt_second_trimester_value}</td>
              <td style={styles.tdFirst}>{ele.ogtt_second_trimester_normal}</td>
              <td style={styles.tdFirst}>{ele.hcv_value}</td>
              <td style={styles.tdFirst}>{ele.hcv_normal}</td>
              <td style={styles.tdFirst}>{ele.creatine_value}</td>
              <td style={styles.tdFirst}>{ele.creatine_normal}</td>
              <td style={styles.tdFirst}>{ele.double_marker_value}</td>
              <td style={styles.tdFirst}>{ele.double_marker_normal}</td>
              <td style={styles.tdFirst}>{ele.tft_value}</td>
              <td style={styles.tdFirst}>{ele.tft_normal}</td>
              <td style={styles.tdFirst}>{ele.tft_description}</td>
              <td style={styles.tdFirst}>{ele.others_value}</td>
              <td style={styles.tdFirst}>{ele.others_normal}</td>
              <td style={styles.tdFirst}>{ele.others_description}</td>
              <td style={styles.tdFirst}>{ele.scan}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

const styles = {
  th: {
    fontSize: "17px",
    whiteSpace: "nowrap",
    padding: "5px 10px",
    margin: "10px 0px",
  },

  td: {
    borderLeft: "1px solid #fff",
    padding: "5px 10px",
    fontSize: "12px",
    textAlign: "left",
    lineHeight: "20px",
  },

  tdFirst: {
    padding: "5px 10px",
    fontSize: "12px",
  },
};
