import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

class Addconsultant extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: true,
      name: "",
      lastname: "",
      email: "",
      location: "",
      password: "",
      mobile: "",
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState({ isOpen: false });
    //window.location ='/login';
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (
      this.state.email &&
      this.state.name &&
      this.state.lastname &&
      this.state.mobile &&
      this.state.location &&
      this.state.password
    ) {
      const headers = {
        ///digiat
        "api-key": "aU60ViOC.02hdAGZ0gy4avx0oBCkhErSJK55anMuC",

        //heroku
        // "api-key": "g7eWwFm9.va3HxJiPD1OzMs5I1UntS2gAwXj99Kuq",};
      };
      const data = {
        email: this.state.email,
        password: this.state.password,
        location: this.state.location,
        sales: true,
        lastname: this.state.lastname,
        mobile: this.state.mobile,
        firstname: this.state.name,
      };
      // axios({
      //   method: "POST",
      //   url: "consultant-registration/",
      //   Authorization: `Token ${sessionStorage.getItem("token")}`,
      //   // headers: { "Api-Key": `aU60ViOC.02hdAGZ0gy4avx0oBCkhErSJK55anMuC` },
      //   data: data,
      //   // email: this.state.email,
      //   // password: this.state.password,
      //   // location: this.state.location,
      //   // consultant: true,
      //   // firstname: this.state.name,
      // });

      const config = {
        headers: {
          Authorization: `Token ${sessionStorage.getItem("token")}`,
        },
      };
      axios
        .post("consultant-registration/", data, config)

        .then((res) => {
          alert("Account Registered");
        })
        .catch((err) => {
          alert(JSON.stringify(err.response.data));
        });
    } else {
      alert("All Fields Required!");
    }
  };

  render() {
    return (
      <>
        {this.state.isOpen ? (
          <div style={styles.model}>
            <div style={styles.formmaker} className="glassblur">
              <i style={{ marginLeft: "100%" }}>
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  onClick={this.handleClick}
                />
              </i>
              <h2>Add New Consultant </h2>
              <form onSubmit={this.handleSubmit}>
                <div style={styles.plate}>
                  <div>
                    <p>
                      first Name{" "}
                      <input
                        style={styles.formcontrol}
                        type="text"
                        onChange={(e) => {
                          this.setState({ name: e.target.value });
                        }}
                      />
                    </p>
                    <p>
                      last Name{" "}
                      <input
                        style={styles.formcontrol}
                        type="text"
                        onChange={(e) => {
                          this.setState({ lastname: e.target.value });
                        }}
                      />
                    </p>
                    <p>
                      User Name
                      <input
                        style={styles.formcontrol}
                        type="text"
                        onChange={(e) => {
                          this.setState({ email: e.target.value });
                        }}
                      />
                    </p>
                  </div>

                  <div>
                    <p>
                      Location{" "}
                      <input
                        style={styles.formcontrol}
                        type="text"
                        onChange={(e) => {
                          this.setState({ location: e.target.value });
                        }}
                      />
                    </p>
                    <p>
                      Password{" "}
                      <input
                        style={styles.formcontrol}
                        type="text"
                        onChange={(e) => {
                          this.setState({ password: e.target.value });
                        }}
                      />
                    </p>{" "}
                    <p>
                      mobile
                      <input
                        style={styles.formcontrol}
                        type="tel"
                        onChange={(e) => {
                          this.setState({ mobile: e.target.value });
                        }}
                      />
                    </p>
                  </div>
                </div>
                <button style={styles.btn} className="pinkbutton" type="submit">
                  Submit
                </button>
              </form>
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

const styles = {
  model: {
    position: "fixed",
    top: "0",
    bottom: "0",
    right: "0",
    left: "0",
  },

  formmaker: {
    margin: "22% 10% 20% 21.3%",
    width: "48.8%",
    // background: "rgb(76, 0, 61) none repeat scroll 0% 0%",
    padding: "10px",
    borderRadius: "10px",
  },

  plate: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "16px",
    textAlign: "right",
  },

  formcontrol: {
    marginLeft: "15px",
    background: "transparent",
    color: "#fff",
    border: "1px solid #fff",
    padding: "5px",
  },

  btn: {
    padding: "10px",
    color: "#fff",
    // background: "red",
    // border: "1px solid red",
  },
};

export default Addconsultant;
