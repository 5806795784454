import React, { useEffect } from "react";
// import { getAllActivityData } from "../../../../helpers/service";
import axios from "axios";
import { DateTime } from "luxon";
// import { useParams } from "react-router";

/**
 * https://stackoverflow.com/questions/71536156/display-custom-data-not-worrking-react-js
 */

export default function ActivityTracker(props) {
  var userid = props.userid1;
  const [data, setData] = React.useState({});

  const [columns, setColumns] = React.useState([]);

  useEffect(() => {
    //   const config = {
    //     headers: {
    //       Authorization: `token ` + localStorage.getItem("doc_token"),
    //     },
    //   };

    axios
      .get(`customer/get-all-activity-data/`, {
        headers: {
          Authorization: `Token ` + sessionStorage.getItem("token"),
        },
        params: {
          customer: props.userid1,
        },
      })

      .then((response) => {
        if (response) {
          // if (response.data.Predefined) {
          const modules = response.data.Predefined.reduce((obj, item) => {
            if (!obj.data && !obj.mainModules) {
              obj.data = {};
              obj.mainModules = [];
            }

            if (!obj.data[item.date]) {
              obj.data[item.date] = {};
            }

            if (!obj.data[item.date][item.mainModule]) {
              obj.data[item.date][item.mainModule] = [];
            }

            obj.data[item.date][item.mainModule] =
              obj.data[item.date][item.mainModule].concat(item);

            if (obj.mainModules.indexOf(item.mainModule) < 0) {
              obj.mainModules = obj.mainModules.concat(item.mainModule);
            }

            return obj;
          }, {});

          const customs = response.data.Custom.reduce((obj, item) => {
            if (!obj[item.date]) {
              obj[item.date] = {
                customs: [],
              };
            }

            obj[item.date].customs = obj[item.date].customs.concat(item);

            return obj;
          }, {});

          const data = Object.entries(modules.data || {}).reduce(
            (obj, [date, data]) => ({
              ...obj,
              [date]: { data },
            }),
            {}
          );

          const data2 = Object.entries(customs || {}).reduce(
            (obj, [date, data]) => ({
              ...obj,
              [date]: {
                ...obj[date],
                ...data,
              },
            }),
            data
          );

          const sortedData = Object.fromEntries(
            Object.entries(data2).sort(([dateA], [dateB]) =>
              DateTime.fromFormat(dateB, "dd-MM-yyyy")
                .toISODate()
                .localeCompare(
                  DateTime.fromFormat(dateA, "dd-MM-yyyy").toISODate()
                )
            )
          );

          setData(sortedData);

          if (modules) {
            setColumns(modules.mainModules);
          }
        }
        // }
      });

    // return request;
  }, []);

  const Row = ([date, item], index) => {
    return (
      <tr className="gradiantblur" key={date || index}>
        <td style={styles.tdFirst}>{date}</td>

        {columns &&
          columns.map((each, i) => (
            <td key={i} style={styles.td}>
              {item.data &&
                item.data[each] &&
                item.data[each].map((itemByModule, id) => (
                  <span key={id}>
                    {itemByModule && itemByModule.submodule}
                    <br />
                  </span>
                ))}
            </td>
          ))}

        <td style={styles.td}>
          {item.customs &&
            item.customs.map((each, i) => (
              <span key={i}>
                {each && each.activity}
                <br />
              </span>
            ))}
        </td>
      </tr>
    );
  };

  // React.useEffect(() => {
  //   if (!!customer) {
  //     const request = fetchApi();

  //     return () => {
  //       request.cancel();
  //     };
  //   }
  // }, [customer]);

  return (
    <table style={{ width: "100%", borderCollapse: "collapse" }}>
      <thead style={{ borderBottom: "5px solid #5c0048" }}>
        <tr className="gradiantblur">
          <th style={styles.th}>Dates</th>
          {columns ? (
            columns.map((each) => (
              <th style={styles.th} key={each}>
                {each}
              </th>
            ))
          ) : (
            <div>no Data</div>
          )}
          <th style={styles.th}>Custom</th>
        </tr>
      </thead>

      <tbody>{Object.entries(data).map(Row)}</tbody>
    </table>
  );
}

const styles = {
  th: {
    fontSize: "17px",
    whiteSpace: "nowrap",
    padding: "5px 10px",
    margin: "10px 0px",
  },

  td: {
    borderLeft: "1px solid #fff",
    padding: "5px 10px",
    fontSize: "12px",
    textAlign: "left",
    lineHeight: "20px",
  },

  tdFirst: {
    padding: "5px 10px",
    fontSize: "12px",
  },
};
