import Tab from './tabs';

export default function Tablelist({id}){

return (

<Tab id = {id}/>

);

}




