import axios from "axios";
import qs from "qs";
import { useState, useEffect } from "react";
import React from "react";
import showPwdImg from "./show-password.svg";
import hidePwdImg from "./hide-password.svg";
import slogo from "../img/sukhprasavamlogo.png";
import { messaging } from "../../firebase";
import { getToken } from "firebase/messaging";
import { Link } from "react-router-dom";

const Form = () => {
  const [isUser, setIsUser] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [items, setItems] = useState([]);
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [fcmToken2, setFcmToken] = useState("");

  useEffect(() => {
    const getFcmToken = async () => {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        const token = await getToken(messaging, {
          vapidKey:
            "BInjEWhqUD-JZMVN1ClioA8GrKKfcJtg-OQFOigst3agDUVw0LGqxkYu55dir95S4sv8kXZgwpvXqZ9_ZfzfVbM",
        });
        setFcmToken(token);
        console.log("Token Gen", token);
        // Send this token  to server ( db)
      } else if (permission === "denied") {
        console.log(permission);
      }
    };

    getFcmToken();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const fcm = String(fcmToken2);
    console.log(fcm);

    const data = {
      email: username,
      password: password,
      fcm_token: fcm,
    };

    try {
      const response = await axios.post("login/", qs.stringify(data), {
        headers: { "content-type": "application/x-www-form-urlencoded" },
      });

      

      if (response.data.client) {
        console.log(fcmToken2);
        console.log(response.data.client);
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("subscibtion", response.data.has_subscription);
        window.location = "/intailedit";
      } else if (response.data.sales == true) {
        localStorage.setItem("sales", response.data.token);

        localStorage.setItem("token", response.data.token);
        window.location = "/csteam";
      } else if (response.data.consltant == true) {
        localStorage.setItem("token", response.data.token);
        window.location = "/consultant";
      } else if (response.data.doctor == true) {
        localStorage.setItem("doc_token", response.data.token);
        window.location = "/doctor";
      } else if (response.data.dad == true) {
        sessionStorage.setItem("dad", response.data.token);
        window.location = "/dad";
      } else if (response.data.hospitalManager == true) {
        sessionStorage.setItem("hospitalManager", response.data.token);
        window.location = "/hmanager";
      } else {
        alert("User with the email not found!");
        console.log("this is error");
      }
    } catch (error) {
      alert(JSON.stringify(error.response.data));
    }
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSignup = () => {
    window.location = "/csignup";
    // Implement signup logic
  };
  console.log(fcmToken2);

  return (
    <>
      <div
        className="form-wrapper"
        style={{
          display: "flex",
          flexDirection: "column",
          color: "#fff",
          padding: "20px",
          width: "40%",
          borderRadius: "20px",

          boxShadow: "rgb(0 0 0 / 16%) 12px 10px 60px",
          backdropFilter: "blur(39px)",
          background: "rgba(0, 0, 0, 0.16)",
          // boxShadow: "12px 10px 60px #00000029",
          // backdropFilter: "blur(39px)",
          // WebkitBackdropFilter: "blur(39px)",
          // background: "#00000029",
        }}
      >
        <img className="marginauto pc-view-hidden" src={slogo} />
        <h3 className="smart">Lets Get Started</h3>
        <form
          className="login-mainform"
          style={{
            display: "flex",
            flexDirection: "column",
            lineHeight: "70px",
          }}
          onSubmit={handleSubmit}
        >
          <input
            value={username}
            onChange={handleUsernameChange}
            style={styles.input}
            type="text"
            placeholder="Username"
          />
          <div className="pwd-container">
            <input
              value={password}
              onChange={handlePasswordChange}
              style={styles.input}
              type={isRevealPwd ? "text" : "password"}
              placeholder="Password"
            />
            <img
              title={isRevealPwd ? "Hide password" : "Show password"}
              src={isRevealPwd ? hidePwdImg : showPwdImg}
              onClick={() => setIsRevealPwd(!isRevealPwd)}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <small>
                <input type="checkbox" />
                <span>Remember Me</span>
              </small>
            </div>
            <div>
              <Link to="/forget">
                <small>Forgot Password</small>
              </Link>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <input
              type="submit"
              value="Login"
              style={{
                background:
                  "transparent linear-gradient(266deg, #FF006C 0%, #293247 100%) 0% 0% no-repeat padding-box",
                width: "102px",
                height: "32px",
                border: "none",
                color: "#fff",
                boxShadow: "0px 9px 10px #00000029",
                borderRadius: "4px",
                cursor: "pointer",
              }}
            />
          </div>
          <div>
            Dont have an Account?{" "}
            <a href="/csignup">
              <u>register</u>
            </a>
          </div>
        </form>
      </div>
    </>
  );
};

export default Form;

const styles = {
  input: {
    background: "none",
    padding: "10px",
    color: "#fff",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
  },
};
