import React from "react";
import Sidebar from "../components/sidebar";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faBell, faUser } from "@fortawesome/free-solid-svg-icons";
import { faBars, faBell, faUser } from "@fortawesome/free-solid-svg-icons";
import ClientProfile from "../toggle_profile";
import Toggler from "../toggle";
import Tab from "./tabs";
/*import Tlist from './components/traker_by_date/tabs';*/
// import Sidebar from "./components/sidebar";
import ClientsUpdateTable from "../components/clients_update_table";
import ClientNoupdates from "../components/clients_no_updates";
import TClients from "../components/clients";
import AlltimeClients from "../components/alltime_clients";

export default class Overview extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isToggleOn: true, items4: [] };
    this.handleClick = this.handleClick.bind(this);
    this.clickedOutside = this.clickedOutside.bind(this);
    this.profileHandler = this.profileHandler.bind(this);
  }

  handleClick() {
    this.setState((prevState) => ({
      isToggleOn: !prevState.isToggleOn,
    }));
  }
  componentDidMount() {
    document.addEventListener("mousedown", this.clickedOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.clickedOutside);
  }
  clickedOutside(e) {
    if (this.isWap && !this.isWap.contains(e.target)) {
      this.setState({ isToggleOn: true });
      this.setState({ isProfile: true });
    }
  }

  profileHandler() {
    this.setState({ isProfile: !this.state.isProfile });
  }

  componentDidMount() {
    if (localStorage.getItem("sales") === null) {
      localStorage.clear();
      window.location = "/clogin";
    }
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("sales"),
      },
    };

    axios.get("sales/sales-dashboard-details/", config).then((res) => {
      console.log(res.data);
      this.setState({ items4: res.data });
      this.setState({ details: res.data.details });
    });
  }

  render() {
    function openMenu() {
      document.querySelector(".sidebar").classList.toggle("show");
    }
    return (
      <div className="App">
        <Sidebar />
        <header className="overview">
          <div
            ref={this.setWrap}
            style={{ padding: "2% 10%", textAlign: "right" }}
            className="grandiant"
          >
            {" "}
            <div>{this.state.isToggleOn ? null : <Toggler />}</div>
            <span>
              <FontAwesomeIcon
                onClick={this.handleClick}
                style={{ marginRight: "1rem", cursor: "pointer" }}
                icon={faBell}
              />
            </span>
            <span>
              <FontAwesomeIcon
                onClick={this.profileHandler}
                style={{ cursor: "pointer" }}
                icon={faUser}
              />
            </span>{" "}
            {this.state.items4.firstname} {this.props.childern}
            {this.state.isProfile ? null : <ClientProfile />}
          </div>

          <div style={{ width: "89%", display: "flex", paddingLeft: "10px" }}>
            <span onClick={openMenu} className="menu">
              <FontAwesomeIcon
                style={{ marginRight: "20px" }}
                icon={faBars}
                className="menu-icon"
              />
            </span>
            <span style={{ whiteSpace: "nowrap" }}>Daily tracker</span>
            <div style={{ padding: "4px", width: "100%" }}>
              <hr style={{ background: "white", color: "white" }} />
            </div>
          </div>
          <div className="mwrapper">
            <div className="mleft">
              <Tab />
            </div>
          </div>
        </header>
      </div>
    );
  }
}
