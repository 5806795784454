import React from "react";
import Sidebar from "./../components/sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faUser, faBars } from "@fortawesome/free-solid-svg-icons";
import Toggler from "./toggle";
import Tlist from "./components/table_list";
import { Redirect } from "react-router";
import ClientProfile from "../toggleprofile";

export default class Overview extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isToggleOn: true, redirect: null, isProfile: true };
    this.profileHandler = this.profileHandler.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.setwRef = this.setwRef.bind(this);
    this.clickedOutside = this.clickedOutside.bind(this);
  }

  handleClick() {
    this.setState((prevState) => ({
      isToggleOn: !prevState.isToggleOn,
    }));
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.clickedOutside);

    const token = sessionStorage.getItem("token");
    if (!token) {
      this.setState({ redirect: "/admin/login" });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.clickedOutside);
  }

  setwRef(node) {
    this.wapRef = node;
  }

  clickedOutside(e) {
    if (this.wapRef && !this.wapRef.contains(e.target)) {
      return this.setState({ isToggleOn: true });
    }
  }
  profileHandler() {
    this.setState({ isProfile: !this.state.isProfile });
  }
  render() {
    function openMenu() {
      document.querySelector(".sidebar").classList.toggle("show");
    }

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    return (
      <div className="App">
        <Sidebar />
        <header className="overview">
          {this.state.isToggleOn ? null : <Toggler />}
          <p style={{ paddingRight: "10%", textAlign: "right" }}>
            {" "}
            <div>{this.state.isToggleOn ? null : <Toggler />}</div>
            <span ref={this.setwRef}>
              <FontAwesomeIcon
                onClick={this.handleClick}
                style={{ marginRight: "1rem", cursor: "pointer" }}
                icon={faBell}
              />
              {this.props.childern}
            </span>
            <FontAwesomeIcon
              style={{ cursor: "pointer" }}
              icon={faUser}
              onClick={this.profileHandler}
            />{" "}
            Admin
          </p>

          <div style={{ width: "90%", display: "flex", paddingLeft: "10px" }}>
            <span onClick={openMenu} className="menu">
              <FontAwesomeIcon
                style={{ marginRight: "20px" }}
                icon={faBars}
                className="menu-icon"
              />
            </span>
            {this.state.isProfile ? null : <ClientProfile />}
            <span>Overview</span>
            <div style={{ padding: "4px", width: "100%" }}>
              <hr style={{ background: "white", color: "white" }} />
            </div>
          </div>

          <div style={{ paddingRight: "8.5%" }}>
            <Tlist id={this.props.id} />
          </div>
        </header>
      </div>
    );
  }
}
