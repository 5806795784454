export default function Dcstatus(){
return (

<div style={styles.card}>
<div style={styles.left}>
<div style={{ display:'flex' }}>
<div style={{ padding:'10px' }}>
<h2 style={styles.counter}>15</h2>
</div>
<div style={{ padding:'10px', borderLeft:'1px solid #fff' }}>
<p><strong style={{ whiteSpace:'nowrap', fontSize:'16px' }}>Active Clients</strong>
</p>
</div>
</div>
</div>
<div style={styles.right}>
<div style={{ display:'flex' }}>
<div style={{ padding:'10px' }}>
<h2 style={styles.counter}>16</h2>
</div>
<div style={{ padding:'10px', borderLeft:'1px solid #fff' }}>
<p><strong style={{ whiteSpace:'nowrap', fontSize:'16px' }}>Disabled doctors</strong></p>
</div>
</div>
</div>

</div>

);

}

const styles = {
card:{
display:'flex',
flexDirection:'column',
background:'#4c003d',
width:'14.8rem',
height:'7rem',
padding:'10px',
borderRadius:'10px',
margin:'10px',
lineHeight:'1px',
},

left:{
padding:'0px',
height:'3.5rem',
lineHeight:'1px',
borderBottom:'1px solid #fff'
},
right:{
padding:'0px',
height:'3.5rem',
lineHeight:'1px',
marginTop:'-20px'
},
counter:{
color:'#fff',
}

}


