import { useState } from "react";

import ClientDailyTracker from "./traker_by_date/table_list";
import ClientProfile from "./client_profile";
import SearchClients from "./search";

export default function Tablelist() {
  const [tracker, setTracker] = useState(false);
  const [profile, setProfile] = useState(false);

  function openDailyTracker() {
    setTracker(!tracker);
  }

  function openProfile() {
    setProfile(!profile);
  }

  return (
    <>
      {profile ? <ClientProfile /> : null}

      <div>
        {tracker ? <ClientDailyTracker /> : null}

        <div style={styles.card}>
          <div style={styles.inner}>
            <p>
              <strong>All Clients This Month</strong>
            </p>
            <hr style={{ color: "#fff" }} />
            <table>
              <thead>
                <tr>
                  <td>Name</td>
                  <td>Age</td>
                  <td>Location</td>
                  <td>Due Date</td>
                  <td>Current Week</td>
                  <td>Daily Tracker Details</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={styles.usernav} onClick={openProfile}>
                    Laxshmi
                  </td>
                  <td>26</td>
                  <td>Kachi</td>
                  <td>01-08-2021</td>
                  <td>8th week</td>
                  <td>
                    <button onClick={openDailyTracker}>Details</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

const styles = {
  card: {
    display: "flex",
    justifyContent: "space-between",
    background: "#4c003d",
    padding: "20px",
    borderRadius: "10px",
    margin: "10px",
  },
  inner: {
    textDecoration: "none",
    color: "#fff",
  },

  usernav: {
    background: "#4c005d",
    padding: "5px 10px",
    cursor: "pointer",
  },
};
