import React from 'react';

export default function SymptomsTracker(){

return (

<table style={{ width:'100%', borderCollapse:'collapse' }}>
<thead style={{ borderBottom:'5px solid #5c0048' }}>
<tr style={{ background:'#a23698' }}>
<th style={ styles.th} >Date</th>
<th style={ styles.th} >Symptoms</th>
<th style={ styles.th} >Weight</th>
<th style={ styles.th} >Blood Sugar Level</th>
<th style={ styles.th} >Blood PressureLevel</th>
<th style={ styles.th} >Last Week Report</th>
</tr>
</thead>

<tbody>
<tr style={{ background:'#a23698' }}>
<td style={styles.tdFirst}>02/08/2021</td>
<td style={styles.td}>Nausea <br/> Backpain <br/>
Constipation <br/>
Tringling Or Aching Breasts <br/>
Motion Sickness
</td>
<td style={styles.td}>86kg</td>
<td style={styles.td}>120</td>
<td style={styles.td}>85</td>
<td style={styles.td}>According to last week symptoms, you have had lots of vomiting which may lead to dehydration or could be a sign of dehydration.  </td>

</tr>


</tbody>


</table>


);

}


const styles = {

th:{
fontSize:'17px',
whiteSpace:'nowrap',
padding:'5px 10px',
margin:'10px 0px',
},

td:{
borderLeft:'1px solid #fff',
padding:'5px 10px',
fontSize:'12px',
textAlign:'left',
lineHeight:'20px'
},

tdFirst:{
padding:'5px 10px',
fontSize:'12px'
},



}






