import axios from "axios";
import { Fragment, useEffect, useState } from "react"

function Kickform(){
    const [time,settime] = useState()
    const [date,setdate]=useState()
    const [count,setcount]=useState(0)

    useEffect(()=>{
        

    },[]);

    function submit(){
        const formattedDate=formatDate(date)
        const data={
            date:formattedDate,
            time:time,
            kick_count:count,
        }
        
        const config = {
            headers: {
              Authorization: `token ` + localStorage.getItem("token"),
            },
          };
      
            
        axios.post('update_baby_kick_count/',data,config)
        .then(alert('done'))

        
    }
    function formatDate(inputDate) {
        const parts = inputDate.split('-');
        if (parts.length === 3) {
            const [year, month, day] = parts;
            return `${year}-${month}-${day}`;
        } else {
            return inputDate; 
        }
    }
return <Fragment>
    <div style={styles.card}>

   <label style={styles.label} >date:</label>
   <input name="date" value={date} onChange={(e)=>setdate(e.target.value)}
    style={styles.input} type="date"/><br></br><br></br>
   <label style={styles.label}>time:</label>
   <input name="time" value={time} onChange={(e)=>settime(e.target.value)}
    style={styles.input} type="time"/><br></br><br></br>
   <label style={styles.label}>count</label>
   <input name="count" value={count} onChange={(e)=>setcount(e.target.value)}
    style={styles.input} type="number"/><br></br>

        <button onClick={submit} style={styles.button}>Add</button>
    </div>
</Fragment>
}

const styles = {
    button:{
        backgroundColor:"#2196f3",
        color:"white",
        borderRadius:'5px',
        padding:'8px 14px',
        marginTop:'9px',
    },
    label:{

        float:"left",
    },
    input:{
        background:"#ffffff1c 0% 0%",
        border:"2px solid white",
        color:"white",
        width:'250px',
        height:'40px',
        borderRadius:'15px'
    },
    card: {
      // display: "flex",
      justifyContent: "space-between",
      background: "#ffffff1c 0% 0% no-repeat padding-box",
      padding: "20px",
      borderRadius: "10px",
      margin: "10px",
    },
  
    inner: {
      textDecoration: "none",
      color: "#fff",
    },
  };
export default Kickform