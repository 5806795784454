import React from "react";
import { Link, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import {
  faCog,
  faThLarge,
  faUserMd,
  faBriefcaseMedical,
  faSms,
  // faAndroid,
  faQrcode,
  faCalendar,
} from "@fortawesome/free-solid-svg-icons";
import { faAndroid, faAppStore, faApple } from "@fortawesome/free-brands-svg-icons";
// import { faAndroid } from "@fortawesome";
import Logo from "../../logo.png";
import { faMessage } from "@fortawesome/free-regular-svg-icons";

export default class Sidebar extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    // if (localStorage.getItem("token") === null) {
    //   localStorage.clear();
    //   window.location = "/clogin";
    // } else {
      const config = {
        headers: {
          Authorization: `token ` + localStorage.getItem("token"),
        },
      };
      axios.get("customer/customer-dashboard-details/", config).then((res) => {
        if (res.data.has_subscription === false) {
          window.location = "/price";
        }
      });
    }
  // }
  render() {
    function hideBar() {
      var sidebar = document.querySelector(".sidebar");
      sidebar.classList.remove("show");
      sidebar.classList.add("hide");
    }

    function reloder() {
      // window.location.reload();
    }
    return (
      <div className="sidebar" style={styles.header}>
        {console.log("sidebarprops read ", this.props.siderbarcolor)}
        <a class="close-icon" href="#" onClick={hideBar}>
          &#10006;
        </a>
        <Link to="/postdash">
          <img src={Logo} alt="" className="mainlogo" />
        </Link>
        <button className="backButton" onClick={() => window.history.back()}>
          Back
        </button>
        <ul style={styles.lists}>
          {this.props.siderbarcolor ? (
            <NavLink
              activeClassName="active3"
              className="porpssidebar"
              exact={true}
              to="/postdash"
            >
              <li style={styles.listItemActive}>
                <div>
                  <FontAwesomeIcon
                    icon={faThLarge}
                    style={{ marginRight: "1rem" }}
                  />
                </div>
                <div> Dashboard</div>
              </li>
            </NavLink>
          ) : (
            <NavLink activeClassName="active3" exact={true} to="/postdash">
              <li style={styles.listItemActive}>
                <div>

                  <FontAwesomeIcon
                    icon={faThLarge}
                    style={{ marginRight: "1rem" }}
                  />
                </div>
                <div> Dashboard </div>
              </li>
            </NavLink>
          )}
          <NavLink to="/mydoct" activeClassName="active3">
            {/* <li style={styles.listItem}></li> */}
            <li style={styles.listItem}>
              <div>
                <FontAwesomeIcon icon={faUserMd} style={{ marginRight: "1rem" }} />
              </div>
              <div>My doctor</div>
            </li>
          </NavLink>
          <NavLink activeClassName="active3" to="postdoctor" onClick={reloder()}>
            <li style={styles.listItem}>
              <div>
                <FontAwesomeIcon
                  icon={faBriefcaseMedical}
                  style={{ marginRight: "1rem" }}
                />
              </div>
              <div> Doctor</div>
            </li>
          </NavLink>

          {/* <li style={styles.listItem}>
            <div>
              <i
                className="fa fa-product-hunt"
                style={{ marginRight: "1rem" }}
              ></i>
            </div>
            <div> Products</div>
          </li> */}
          
          <NavLink to="#" activeClassName="active3">
            <li style={styles.listItem}>
              <div>
                <FontAwesomeIcon
                  icon={faMessage}
                  style={{ marginRight: "1rem" }}
                />
              </div>
              <div>Messages</div>
            </li>
          </NavLink>
        </ul>

        <div
          className="hide-mob"
          style={{
            position: "absolute",
            bottom: "3rem",
            left: "30px",
            width: "15%",
          }}
        >
          <p style={{ fontSize: "14px" }}>
            {" "}
            to enjoy services provided by shebirth on mobile,
            download our mobile app
          </p>
          <div>
            <button
              style={{
                color: "#fff",
                textDecoration: "none",
                background: "transparent",
                border: "none",
              }}
            >
              <a href="https://play.google.com/store/apps/details?id=com.smartbirth.shebirth">
                <FontAwesomeIcon icon={faAndroid} />
              </a>
              <a style={{marginLeft:'16px',fontSize:'30px'}} href="https://apps.apple.com/in/app/shebirth-sukh-prasavam/id6461416949">
                <FontAwesomeIcon icon={faApple} />
              </a>
              {/* <FontAwesomeIcon icon={faCog} style={{ marginRight: "1rem" }} /> */}
              {/* Settings */}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  header: {
    // background: "linear-gradient(25deg, #3a51a7, #7f1f54)",
    background:
      " 0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
    padding: "2rem",
    // minHeight: "100%",
    // height: "100vh",
    width: "20%",
    margin: "0",
    color: "white",
  },
  lists: {
    marginTop: "5rem",
    color: "#fff",
    paddingLeft: "0",
    listStyle: "none",
  },
  listItem: {
    // background: "transparent",
    padding: "10px 50px",
    borderRadius: "10px",
    cursor: "pointer",
    whiteSpace: "nowrap",
    textAlign: "initial",
    display: "flex",
  },
  listItemActive: {
    // background: "#6a0f4b",
    padding: "10px 50px",
    borderRadius: "10px",
    cursor: "pointer",
    display: "flex",
  },
};
