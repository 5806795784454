import React from "react";
import axios from "axios";
import qs from "qs";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import AdminLoginpost from "../../postdel/admin/login/admin_login";
// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyDDrqVCZ7k8JvJMAFcT8QoIcZB5Dd2qBlk",
  authDomain: "shebirth-56315.firebaseapp.com",
  projectId: "shebirth-56315",
  storageBucket: "shebirth-56315.appspot.com",
  messagingSenderId: "863574233401",
  appId: "1:863574233401:web:25894efddd5fc5805058e8",
  measurementId: "G-RYDPEVQEH0",
};
const app = initializeApp(firebaseConfig);

const messaging = getMessaging(app);

export default class AdminLogin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      massage: "",
      email: "",
      password: "",
      isAdmin: false,
      fcm_token2: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.emailChange = this.emailChange.bind(this);
    this.passChange = this.passChange.bind(this);
  }

  handleSubmit(e) {
    let currentToken; //
    getToken(messaging, {
      vapidKey:
        "BInjEWhqUD-JZMVN1ClioA8GrKKfcJtg-OQFOigst3agDUVw0LGqxkYu55dir95S4sv8kXZgwpvXqZ9_ZfzfVbM",
    })
      .then((currentToken) => {
        this.setState({ fcm_token2: currentToken });
        console.log("FCM token:", currentToken);
      })
      .catch((error) => {
        console.log("Failed to generate FCM token:", error);
      });
    console.log(this.state.fcm_token2);
    const record = {
      email: this.state.email,
      password: this.state.password,
      fcm_token: this.state.fcm_token2,
    };

    const options = {
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      data: qs.stringify(record),
      // url: "https://sukhprasavam.shebirth.com/login/",

      url: "login/",
    };

    axios(options)
      .then((res) => {
        console.log(res.data);

        if (res.data.error) {
          this.setState({ message: res.data.error[0] });
        }

        if (res.data.password) {
          this.setState({ message: res.data.password[0] });
        }

        if (res.data.admin) {
          sessionStorage.setItem("token", res.data.token);
          window.location = "/admin";
        }
      })
      .catch(function (error) {
        //console.log('Show error notification!')
        return Promise.reject(error);
      });
    e.preventDefault();
  }

  emailChange(e) {
    this.setState({
      email: e.target.value,
    });
  }

  passChange(e) {
    this.setState({
      password: e.target.value,
    });
  }

  render() {
    return (
      <>
      <h1 style={{textAlign:'center',color:'white'}}>Admin login</h1>
      <div style={styles.box}>

        {this.state.isAdmin
          ? function () {
              window.location = "/admin";
            }
          : null}
        <div style={styles.outer}>
          <form style={styles.inner} onSubmit={this.handleSubmit}>
            <h3 style={{color:'white',textAlign:'center'}} >Antenatal</h3>
            <span style={{ color: "red" }}>
              {this.state.message ? this.state.message : null}
            </span>
            <input
              type="email"
              placeholder="Email"
              value={this.state.email}
              onChange={this.emailChange}
            />
            <br />
            <input
              type="password"
              placeholder="password"
              value={this.state.password}
              onChange={this.passChange}
            />
            <br />
            <button style={{marginTop:'10px'}} type="submit">Login</button>
          </form>
        </div>
            </div>
        <AdminLoginpost/>
      </>
    );
  }
}

const styles = {
  outer: {
    
  
  },

  inner: {
   padding:'20px'
  },
  box:{
    border:'2px solid black',
    width:'250px',
    margin:'auto',
    marginTop:'10vh',
    borderRadius:'20px',
    background: "#ffffff1c 0% 0% no-repeat padding-box",

  }
};
