import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

export default function Salesteam({ count = 0 }) {
  return (
    <div style={styles.card} className="lightgradient2 mobilemarginauto">
      <div style={styles.left}>
        <p>
          <strong style={{ whiteSpace: "nowrap", fontSize: "16px" }}>
            Total Clients All Time
          </strong>
          <hr style={{ color: "#fff" }} />
        </p>
      </div>
      <div style={styles.right}>
        <h2 style={styles.counter}>{count}</h2>
        <Link to="/csteam">
          <button style={styles.link} className="lightgradient2">
            More <FontAwesomeIcon icon={faChevronCircleRight} />
          </button>
        </Link>
      </div>
    </div>
  );
}

const styles = {
  card: {
    display: "flex",
    flexDirection: "column",
    // backdropFilter: "blur(31px) brightness(-15%) opacity(.3)",
    width: "13.5rem",
    height: "10rem",
    padding: "20px",
    borderRadius: "10px",
    margin: "10px",
    lineHeight: "10px",
  },
  link: {
    fontSize: "13px",
    textDecoration: "none",
    color: "#fff",
    // background: "#5a0f2c",
    padding: "10px",
    borderRadius: "10px",
    border: "none",
    cursor: "pointer",
  },

  left: {
    padding: "0px",
  },
  right: {
    padding: "0px",
  },
  counter: {
    margin: "20px auto",
    color: "#fff",
  },
};
