import React from "react";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import "../tabs.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faComment } from "@fortawesome/free-solid-svg-icons";
//import EditPersonalDetail from './innercom/edit_personal';

// export default function support_tabs() {
//   return (
//     <div>support_tabs</div>
//   )
// }

export default function Support_tabs() {
  const [consultants, setconsultants] = useState([]);
  const [Sales, setSales] = useState([]);
  // this.state = {
  //   isSummery: false,
  //   userid: "",
  //   isShedule: false,
  //   consultants: [],
  //   completed: [],
  //   rejected: [],
  //   username: "",
  // };
  // this.openchat = this.openchat.bind(this);
  // this.openShedule = this.openShedule.bind(this);

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("token"),
      },
    };

    axios.get("messages/all-consultants-list/", config).then((res) => {
      console.log(res.data);
      setconsultants(res.data.details);
    });
    axios
      .get("messages/all-sales-team", config)
      .then((res) => {
        // console.log(res.data);
        setSales(res.data.details);
      })

      .catch((err) => {
        // err.response.status;
        // if (err.response.status == 404) {
        //   console.log("data not found.");
        // }
        console.log(err.message);
      });
  }, []);

  function openchat(id) {
    console.log(id);
    // setUserId(id);
    // setClient(!client);
  }

  const formatDate = (dateString) => {
    const currentDate = new Date();
    const parsedDate = new Date(
      dateString.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3")
    );

    if (
      currentDate.getDate() === parsedDate.getDate() &&
      currentDate.getMonth() === parsedDate.getMonth() &&
      currentDate.getFullYear() === parsedDate.getFullYear()
    ) {
      // It's today's date, return only time
      return parsedDate.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
    } else {
      // It's a different date, return formatted date in dd/mm/yyyy format
      return parsedDate.toLocaleDateString("en-GB"); // Set locale to get dd/mm/yyyy format
    }
  };

  const formatDate3 = (dateString) => {
    const currentDate = new Date();
    const parsedDate = new Date(
      dateString.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3")
    );

    if (
      currentDate.getDate() === parsedDate.getDate() &&
      currentDate.getMonth() === parsedDate.getMonth() &&
      currentDate.getFullYear() === parsedDate.getFullYear()
    ) {
      // It's today's date, return only time
      return parsedDate.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
    } else {
      // It's a different date, return formatted date in dd/mm/yyyy format
      return parsedDate.toLocaleDateString("en-GB"); // Set locale to get dd/mm/yyyy format
    }
  };

  return (
    <>
      <div className="appointment-card" style={styles.card}>
        <div style={styles.inner}>
          <>
            <Tabs>
              <TabList style={styles.tablist2}>
                <Tab>
                  <strong style={{ padding: "10px", cursor: "pointer" }}>
                    Buddy
                  </strong>
                </Tab>

                {/* <Tab>
                    <strong style={{ padding: "10px", cursor: "pointer" }}>
                      Doctor
                    </strong>
                  </Tab> */}
                <Tab>
                  <strong style={{ padding: "10px", cursor: "pointer" }}>
                    Consultant
                  </strong>
                </Tab>
              </TabList>

              <TabPanel className="scrooler" style={{ maxHeight: "450px" }}>
                <table
                  style={{
                    overflow: "scroll",
                    borderCollapse: "seperate",
                    borderSpacing: "0rem 1rem",
                  }}
                >
                  <tbody>
                    {/* Completed appointment from endpoint */}
                    {Sales.sort((a, b) => {
                      const dateA = new Date(a.custom_date);
                      const dateB = new Date(b.custom_date);
                      return dateB - dateA;
                    }).map((e, index) => (
                      <tr style={styles.tr} className="gradiantblur">
                        &nbsp; &nbsp;{" "}
                        <td style={styles.tolink}>
                          {e.profile_pic !=
                          "https://sukhprasavam.shebirth.com/media//ProfilePic/default.jpg" ? (
                            <img
                              style={{ maxWidth: "65px" }}
                              className="avatar"
                              src={e.profile_pic}
                              alt=""
                            />
                          ) : e.profile_pic ==
                            "https://sukhprasavam.shebirth.com/media//ProfilePic/default.jpg" ? (
                            <button
                              className="avatar aquamarine "
                              style={{ maxWidth: "65px", border: "none" }}
                            ></button>
                          ) : (
                            <button
                              className="avatar aquamarine "
                              style={{ maxWidth: "65px", border: "none" }}
                            ></button>
                          )}
                          {/* {e.profile_pic ? (
                            <img src={e.profile_pic} className="avatar" />
                          ) : (
                            <FontAwesomeIcon
                              style={{ fontSize: "2rem" }}
                              icon={faUser}
                            />
                          )}{" "} */}
                          &nbsp;&nbsp;&nbsp;
                          {e.firstname}
                          <br />
                        </td>
                        {/* <Link
                          to={{
                            pathname: "/cdoctors/detiled/" + personData.id,
                            state: { id: personData.id, data: personData },
                          }}
                          onClick={OpenInner}
                        >
                          <button style={styles.book}>Book Appointment</button>
                        </Link> */}
                        <td> last message {formatDate(e.custom_date)}</td>
                        <td>
                          {" "}
                          <Link
                            to={{
                              pathname: "/directchat/" + e.id,
                            }}
                          >
                            <td>
                              <FontAwesomeIcon icon={faComment} />
                            </td>
                          </Link>
                        </td>
                        {/* faMessageLines */}
                        {/* <td>
                            <button
                              id={e.id}
                              // onClick={this.openSummery}
                              onClick={() =>
                                this.openSummery(e.id, e.clientName)
                              }
                            >
                              Summary
                            </button>
                          </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </TabPanel>

              <TabPanel className="scrooler" style={{ maxHeight: "450px" }}>
                <table
                  style={{
                    borderCollapse: "seperate",
                    borderSpacing: "0rem 1rem",
                  }}
                >
                  <tbody>
                    {console.log(consultants)}
                    {consultants &&
                      consultants
                        .sort((a, b) => {
                          const dateA = new Date(a.custom_date);
                          const dateB = new Date(b.custom_date);
                          return dateB - dateA;
                        })
                        .map((e) => (
                          <tr style={styles.tr} className="gradiantblur">
                            &nbsp; &nbsp;{" "}
                            <td style={styles.tolink}>
                              {e.profile_pic !=
                              "https://sukhprasavam.shebirth.com/media//ProfilePic/default.jpg" ? (
                                <img
                                  style={{ maxWidth: "65px" }}
                                  className="avatar"
                                  src={e.profile_pic}
                                  alt=""
                                />
                              ) : e.profile_pic ==
                                "https://sukhprasavam.shebirth.com/media//ProfilePic/default.jpg" ? (
                                <button
                                  className="avatar aquamarine "
                                  style={{ maxWidth: "65px", border: "none" }}
                                ></button>
                              ) : (
                                <button
                                  className="avatar aquamarine "
                                  style={{ maxWidth: "65px", border: "none" }}
                                ></button>
                              )}
                              &nbsp;&nbsp;&nbsp;
                              {e.name}
                            </td>
                            <td> last message {formatDate3(e.custom_date)}</td>
                            <td>
                              <Link
                                to={{
                                  pathname: "/directchat/" + e.id,
                                }}
                              >
                                <td>
                                  <FontAwesomeIcon icon={faComment} />
                                </td>
                              </Link>
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
              </TabPanel>
            </Tabs>
          </>
        </div>
      </div>
    </>
  );
}

const styles = {
  tolink: { float: "left", marginLeft: "5%" },
  tablist2: { width: "100%" },
  card: {
    height: "500px",
    // display: "flex",
    // justifyContent: "space-between",
    //background:'#9038a3',
    background:
      "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
    //backdropFilter: "blur(31px) brightness(-15%) opacity(.3)",
    padding: "20px",
    borderRadius: "10px",
    margin: "10px",
  },

  inner: {
    textDecoration: "none",
    color: "#fff",
  },

  info: {
    fontSize: "12px",
  },

  editbtn: {
    border: "1px solid #fff",
    background: "transparent",
    padding: "5px 10px",
    color: "#fff",
    cursor: "pointer",
    borderRadius: "7px",
  },

  manpic: {
    width: "80px",
    height: "80px",
    borderRadius: "50%",
    marginRight: "10px",
  },

  rbtn: {
    background: "#e81a7f",
    color: "#fff",
    padding: "7px 40px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },

  upcom: {
    marginBottom: "2rem",
    display: "flex",
    justifyContent: "space-between",
    width: "auto",
    background: "#9038a3",
    padding: "30px",
  },

  sbtn: {
    background: "#E81A7F",
    border: "1px solid #E81A7F",
  },

  tr: {
    // background: "#772068",
  },
};

// export default Appointment;
