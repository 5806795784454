import React from "react";
import PropTypes from "prop-types";
import Sidebar from "../components/sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faUser, faBars } from "@fortawesome/free-solid-svg-icons";
import Toggler from "../toggle";
import TogProfile from "./tog_profile";
//import Client from './components/clients';
import Hospital from "../components/hospitals";
import Salesteams from "../components/salesteams";
import Editprofile from "./editprofile/clienteditprofile";
import Consultants from "../components/consultants";
import Dcstatus from "../components/dcstatus";
import Tabs from "./components/tabs";
import axios from "axios";
import { Redirect } from "react-router";
export default class Overview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isToggleOn: true,
      onProfile: true,
      redirect: null,
      list: [],
      count: "",
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleProfile = this.handleProfile.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    const token = sessionStorage.getItem("token");
    if (!token) {
      this.setState({ redirect: "/admin/login" });
    }

    axios
      .get("admin-panel/admin-dashboard-details/", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Token ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        this.setState({ count: res.data.counts });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  handleClick() {
    this.setState((prevState) => ({
      isToggleOn: !prevState.isToggleOn,
    }));
  }

  handleProfile() {
    this.setState((prevState) => ({
      onProfile: !prevState.onProfile,
    }));
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      return this.setState({ onProfile: true });
    }
  }

  render() {
    function openMenu() {
      document.querySelector(".sidebar").classList.toggle("show");
    }
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    return (
      <div className="App">
        <Sidebar />
        <header className="overview">
          <p style={{ paddingRight: "10%", textAlign: "right" }}>
            {" "}
            <div>{this.state.isToggleOn ? null : <Toggler />}</div>
            <span>
              <FontAwesomeIcon
                onClick={this.handleClick}
                style={{ marginRight: "1rem", cursor: "pointer" }}
                icon={faBell}
              />
            </span>
            <span ref={this.setWrapperRef}>
              <FontAwesomeIcon
                style={{ cursor: "pointer" }}
                icon={faUser}
                onClick={this.handleProfile}
              />{" "}
              {this.props.children} Admin
            </span>{" "}
            <div>{this.state.onProfile ? null : <TogProfile />}</div>
            {this.state.onProfile ? null : <TogProfile />}
          </p>

          <div style={{ width: "89%", display: "flex", paddingLeft: "10px" }}>
            <span onClick={openMenu} className="menu">
              <FontAwesomeIcon
                style={{ marginRight: "20px" }}
                icon={faBars}
                className="menu-icon"
              />
            </span>

            <span style={{ whiteSpace: "nowrap" }}>Clients</span>
            <div style={{ padding: "4px", width: "100%" }}>
              <hr style={{ background: "white", color: "white" }} />
            </div>
          </div>
          <div className="mwrapper">
            <div className="mleft">
              <Editprofile id={this.props.id} />

              {/* <Tabs id={this.props.id} /> */}
            </div>
            <div className="mright">
              <Hospital totalHospitals={this.state.count.totalHospitals} />
              <div
                style={{
                  fontWeight: "bold",
                  textAlign: "left",
                  paddingLeft: "10px",
                }}
              ></div>

              <Salesteams totalSalesTeam={this.state.count.totalSalesTeam} />
              <Consultants totalConsultant={this.state.count.totalConsultant} />
              <Dcstatus
                activeClients={this.state.count.activeClients}
                disabledDoctors={this.state.count.disabledDoctors}
              />
            </div>
          </div>
        </header>
      </div>
    );
  }
}

Overview.propTypes = {
  children: PropTypes.element.isRequired,
};
