import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Dietracker from "./tabs_com/diet_tracker";
import Medicinetracker from "./tabs_com/medicine_tracker";
import Exercisetracker from "./tabs_com/exercise_tracker";
import Symptomstracker from "./tabs_com/symptoms_tracker";
import Activitytracker from "./tabs_com/activity_tracker";
import Contractioncounter from "./tabs_com/contraction_counter";
import Medicaldetail from "./tabs_com/medical_detail";

class Clist extends React.Component {
  constructor(props) {
    super(props);
    console.log("dsdsdsdsdsdsdsdsdsdsdsds", props);
    this.state = { isClose: true, userid1: { id: props.userid } };
    this.closeTabBox = this.closeTabBox.bind(this);
  }

  closeTabBox() {
    this.setState({ isClose: false });
  }

  render() {
    return (
      <>
        {this.state.isClose ? (
          <div
            className="tabopen takerfull backdropersafari"
            style={styles.card}
          >
            <i
              onClick={this.closeTabBox}
              className="fa fa-close"
              style={styles.closebtn}
            ></i>
            <div className="w100" style={styles.inner}>
              <Tabs>
                <TabList
                  style={{
                    width: "100%",
                    paddingLeft: "0px",
                    listStyle: "none",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Tab>
                    <strong style={styles.tabnav}>
                      Diet Tracker {console.log(this.props.userId)}
                    </strong>
                  </Tab>
                  <Tab>
                    <strong style={styles.tabnav}>Medicine Tracker</strong>
                  </Tab>
                  <Tab>
                    <strong style={styles.tabnav}>Exercise Tracker</strong>
                  </Tab>
                 
                  <Tab>
                    <strong style={styles.tabnav}>Activity Tracker</strong>
                  </Tab>
                 
                  {/* <Tab>
                    <strong style={styles.tabnav}>Medical Details</strong>
                  </Tab> */}
                </TabList>

                <TabPanel>
                  <Dietracker userid1={this.props.userId} />
                </TabPanel>

                <TabPanel>
                  <Medicinetracker userid1={this.props.userId} />
                </TabPanel>

                <TabPanel>
                  <Exercisetracker userid1={this.props.userId} />
                </TabPanel>

                <TabPanel>
                  <Symptomstracker userid1={this.props.userId} />
                </TabPanel>

                <TabPanel>
                  <Activitytracker userid1={this.props.userId} />
                </TabPanel>

                <TabPanel>
                  <Contractioncounter userid1={this.props.userId} />
                </TabPanel>

                <TabPanel>
                  <Medicaldetail userid1={this.props.userId} />
                </TabPanel>
              </Tabs>
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

const styles = {
  card: {
    display: "flex",
    justifyContent: "space-between",
    // background: "#4c003d",
    backdropFilter: "blur(30px)",

    background:
      "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
    padding: "20px",
    borderRadius: "10px",
    margin: "10px",
    // position: "absolute",
    position: "fixed",
    top: "10%" /* Center vertically */,
    left: "8%",
    // transform: "translate(-10rem, -6rem)",
    zIndex: 10,
    height: "100%",
    width: "75%",
    overflow: "overlay",
  },

  inner: {
    width: "100%",

    textDecoration: "none",
    color: "#fff",
  },

  tabnav: {
    cursor: "pointer",
    fontSize: "14.3px",
    whiteSpace: "nowrap",
    padding: "20px 10px",
  },

  closebtn: {
    position: "absolute",
    right: "8px",
    color: "black",
    top: "2px",
  },
};

export default Clist;
