import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBolt, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import React, { useState, useEffect } from "react";
import axios from "axios";

export default function ContractionCounter(props) {
  const [lenth, setlenth] = useState([]);
  const [activeCurrentIndex, setActiveCurrentIndex] = useState();
  const [predefined, setpredefined] = useState([]);
  var pikerdate = props.dataFromParent;
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("token"),
      },
    };

    axios
      .get("customer/get-contraction/?date=" + pikerdate, config)
      .then((res) => {
        console.log(res.data);
        //     let filteredData = [];
        //     let filteredData2 = [];

        //     res.data.custom.forEach((e) => {
        //       if (e.completed === true) filteredData.push(e);
        //     });

        //     // res.data.predefined.sub_module.forEach((e) => {
        //     //   if (e.completed === true) filteredData2.push(e);
        //     // });
        //     // setsymtoms(filteredData);
        //     setcustom(filteredData);
        setpredefined(res.data);

        //     // setsymptomsWithIputs(res.data.symptomsWithIputs);
      });
  }, []);

  let mapped = predefined.reduce((r, a) => {
    r[a.date] = [...(r[a.date] || []), a];
    return r;
  }, {});

  return (
    <div className="accordion">
      {Object.values(mapped).map((item, index) => (
        //{predefined.map(({ date }) => (
        <div className="accordion-item">
          <div
            className="accordion-title"
            onClick={() => setActiveCurrentIndex(item[0].date)}
          >
            <div>{item[0].date}</div> <div>{item[0].time}</div>{" "}
            <div>painscale</div>
            <div>
              <FontAwesomeIcon
                icon={faBolt}
                style={{ marginRight: "1rem", color: "red" }}
              />
              {item.length}
              <FontAwesomeIcon
                icon={faChevronDown}
                style={{ marginLeft: "5rem", cursor: "pointer" }}
              />
              {/* {isActive ? (
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    style={{ marginLeft: "5rem", cursor: "pointer" }}
                  />
                ) : (
                  "-"
                )} */}
            </div>
          </div>
          {activeCurrentIndex === item[0].date && (
            <div className="accordion-content">
              <table>
                <thead>
                  <tr>
                    <th>time</th> <th>contraction</th> <th>pain scale</th>{" "}
                    <th>interval</th>
                  </tr>
                </thead>
                {item.map((e) => {
                  return (
                    <tr>
                      <td> {e.time}</td> <td>{e.contraction}</td>{" "}
                      <td> {e.painScale}</td> <td>{e.interval}</td>
                    </tr>
                  );
                })}
              </table>
            </div>
          )}
        </div>
      ))}
    </div>
    // <table style={{ width: "100%", borderCollapse: "collapse" }}>
    //   <thead style={{ borderBottom: "5px solid #5c0048" }}>
    //     <tr style={{ background: "#a23698" }}>
    //       <th style={styles.th}>Date</th>
    //       <th style={styles.th}>Time</th>
    //       <th style={styles.th}>Contraction</th>
    //     </tr>
    //   </thead>

    //   <tbody>
    //     <tr style={{ background: "#a23698" }}>
    //       <td style={styles.tdFirst}>{pikerdate}</td>
    //       <td style={styles.td}>14:36</td>
    //       <td style={styles.td}>
    //         {" "}
    //         <FontAwesomeIcon
    //           icon={faBolt}
    //           style={{ marginRight: "1rem", color: "red" }}
    //         />{" "}
    //         4{" "}
    //         <FontAwesomeIcon
    //           icon={faChevronDown}
    //           style={{ marginLeft: "5rem", cursor: "pointer" }}
    //         />
    //       </td>
    //     </tr>
    //   </tbody>
    // </table>
  );
}

const styles = {
  th: {
    fontSize: "17px",
    whiteSpace: "nowrap",
    padding: "5px 10px",
    margin: "10px 0px",
  },

  td: {
    borderLeft: "1px solid #fff",
    padding: "5px 10px",
    fontSize: "12px",
    textAlign: "center",
    lineHeight: "20px",
  },

  tdFirst: {
    padding: "5px 10px",
    fontSize: "12px",
  },
};
