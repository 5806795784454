import {
  faCog,
  faSms,
  faThLarge,
  faCalendar,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../../logo.png";
import { NavLink } from "react-router-dom"; // Import NavLink

export default class Sidebar extends React.Component {
  render() {
    function hideBar() {
      var sidebar = document.querySelector(".sidebar");
      sidebar.classList.remove("show");
      sidebar.classList.add("hide");
    }
    return (
      <div className="sidebar" style={styles.header}>
        <a className="close-icon" href="#" onClick={hideBar}>
          &#10006;
        </a>
        <Link to="/postsales">
          {" "}
          {/* Change anchor to NavLink */}
          <img src={Logo} alt="" className="mainlogo" />
        </Link>
        <button className="backButton" onClick={() => window.history.back()}>
          Back
        </button>
        <ul style={styles.lists}>
          <NavLink to="/postsales" activeClassName="active3">
            <li style={styles.listItemActive}>
              <FontAwesomeIcon
                icon={faThLarge}
                style={{ marginRight: "1rem" }}
              />{" "}
              Overview
            </li>
          </NavLink>
          <NavLink to="/dsgsdg" activeClassName="active3">
            <li style={styles.listItem}>
              <FontAwesomeIcon icon={faSms} style={{ marginRight: "1rem" }} />{" "}
              Message
            </li>
          </NavLink>
          <NavLink to="/sales/appointmentsasdfasfa" activeClassName="active3">
            <li style={styles.listItem}>
              <FontAwesomeIcon
                icon={faCalendar}
                style={{ marginRight: "1rem" }}
              />{" "}
              Appointments
            </li>
          </NavLink>
          {/* Commented out code */}
          {/* <NavLink to="/cslearn">
            <li style={styles.listItem}>
              <i className="fa fa-upload"></i> Learning Module
            </li>
          </NavLink> */}
        </ul>
      </div>
    );
  }
}
const styles = {
  header: {
    background:
      " 0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
    // background: "linear-gradient(25deg, #3a51a7, #7f1f54)",
    padding: "2rem",
    minHeight: "100%",
    width: "20%",
    margin: "0",
    color: "white",
  },
  lists: {
    marginTop: "5rem",
    color: "#fff",
    paddingLeft: "0",
    listStyle: "none",
  },
  listItem: {
    // background: "transparent",
    padding: "10px 50px",
    borderRadius: "10px",
    cursor: "pointer",
    display: "flex",
  },

  listItemActive: {
    // background: "#6a0f4b",
    padding: "10px 50px",
    borderRadius: "10px",
    cursor: "pointer",
    display: "flex",
  },

  setbtn: {
    color: "#fff",
    textDecoration: "none",
    background: "transparent",
    border: "none",
  },
};
