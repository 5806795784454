import React, { useState } from "react";
import axios from "axios";

export default function ExampleComponent() {
  const [name, setName] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [crew, setCrew] = useState("");

  const handleSubmit = async (e) => {
    const headers = {
      Authorization: `Token ${sessionStorage.getItem("token")}`,
    };

    e.preventDefault();
    try {
      const response = await axios.post(
        "https://sukhprasavam.shebirth.com/admin-panel/free-content/",
        {
          name: name,
          video_url: videoUrl,
          crew: crew,
        },
        {
          headers: headers,
        }
      );
      alert(response);
      console.log('success',response.data)
      // handle successful response
    } catch (error) {
      alert('errror',error);
      console.log('error',error);
      // handle error
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "28px",
      }}
    >
      <label htmlFor="name" style={{ fontWeight: "bold" }}>
        Name:
      </label>
      <input
        type="text"
        id="name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        style={{
          padding: "5px",
          border: "1px solid #ccc",
          borderRadius: "5px",
        }}
      />

      <label htmlFor="videoUrl" style={{ fontWeight: "bold" }}>
        Video URL:
      </label>
      <input
        type="text"
        id="videoUrl"
        value={videoUrl}
        onChange={(e) => setVideoUrl(e.target.value)}
        style={{
          padding: "5px",
          border: "1px solid #ccc",
          borderRadius: "5px",
        }}
      />

      <label htmlFor="crew" style={{ fontWeight: "bold" }}>
        Description:
      </label>
      <textarea
        id="crew"
        value={crew}
        onChange={(e) => setCrew(e.target.value)}
        style={{
          padding: "5px",
          border: "1px solid #ccc",
          borderRadius: "5px",
        }}
      />

      <button className="pinkbutton" type="submit">
        Submit
      </button>
    </form>
  );
}
