import { faSort, faSortDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import ClientProfile from "./client_profile";
import moment from "moment";
import SearchClients from "./search";
import ClientDailyTracker from "./traker_by_update/tabs_com/select_date";
const queryString = require("query-string");

export default function Tablelist({
  data = [],
  handleSearch,
  handleSortBy,
  searchText,
  sortBy,
  weekOptions = {},
}) {
  const [tracker, setTracker] = useState(null);
  const [customer, setid] = useState();
  const [isdate, setDateSelect] = useState(false);
  const [date, setDate] = useState(new Date());
  // const [count, setcount] = useState(0);
  const [profile, setProfile] = useState(false);
  let count = 0;
  const openDailyTracker = (customer) => () => {
    setTracker(customer);
  };
  const dateSelect = (e) => () => {
    setid(e);
    setDateSelect(!isdate);
  };

  const closeDailyTracker = () => {
    setTracker(null);
  };
  const handleDateChange = (value, e) => {
    setDate(value);
  };

  const openProfile = (customer) => () => {
    setProfile(customer);
  };

  const closeProfile = () => {
    setProfile(null);
  };

  const Row = (item, index) => {
    // Check if the membership is "Trial Plan"
    if (item.membership === "Trial Plan" || item.membership === "No Plans") {
      return null; // If it is "Trial Plan," return null to skip rendering the row
    }
    count++;
    return (
      <tr key={item.id || index} style={styles.tr} className="lightgradient2">
        <td>
          {" "}
          <td>{count}</td>
        </td>
        <td style={styles.usernav} onClick={openProfile(item.id)}>
          {`${!!item.firstName ? item.firstName : ""}`.trim()}
        </td>
        <td>{item.age}</td>
        <td>{item.location}</td>
        <td>{item.dueDate}</td>
        <td style={styles.docName}>
          {`${!!item.doctorFirstName ? "Dr. " + item.doctorFirstName : ""} ${
            !!item.doctorLastName ? item.doctorLastName : ""
          }`.trim()}
        </td>
        <td>
          <button style={styles.sbtn} onClick={dateSelect(item.id)}>
            Update
          </button>
        </td>
      </tr>
    );
  };

  return (
    <>
      {profile ? (
        <ClientProfile profileId={profile} closeProfile={closeProfile} />
      ) : null}

      <div>
        {isdate ? (
          <ClientDailyTracker
            onChange={handleDateChange}
            value={date}
            query={queryString.stringify({
              date: moment(date).format("YYYY-MM-DD"),
              tab: "diet",
              customer,
            })}
          />
        ) : null}
        <SearchClients onChange={handleSearch} value={searchText} />
        <div style={styles.sortit} className="lightgradient2">
          <span style={{ fontSize: "12px" }}>
            {" "}
            <FontAwesomeIcon icon={faSort} /> Sort By:{" "}
            <select
              className="weekselctor"
              value={sortBy}
              onChange={handleSortBy}
              style={styles.option}
            >
              <option value="week" selected>
                Week
              </option>
              {Object.keys(weekOptions).map((range) => (
                <option value={range}>{range}</option>
              ))}
            </select>
          </span>
        </div>
        <div style={styles.card} className="lightgradient2">
          <div style={styles.inner} className="table-cover">
            <p className="margin-toper">
              <strong>No Updates from clients</strong>
            </p>
            <hr style={{ color: "#fff" }} />
            <table style={styles.table}>
              <thead>
                <tr className="lightgradient2">
                  <td>Slno</td>
                  <td>Name</td>
                  <td>Age</td>
                  <td>Location</td>
                  <td>Due Date</td>
                  <td>Doctor</td>
                  <td>Daily Tracker Details</td>
                </tr>
              </thead>
              <tbody>{data.map(Row)}</tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

const styles = {
  card: {
    // display: "flex",
    justifyContent: "space-between",
    // backdropFilter: "blur(31px) brightness(-15%) opacity(.3)",
    padding: "0px",
    borderRadius: "10px",
    margin: "10px",
  },
  inner: {
    textDecoration: "none",
    color: "#fff",
  },

  table: {
    borderCollapse: "seperate",
    borderSpacing: "0px 10px",
    width: "42.5rem",
  },

  tr: {
    // background: "#761F58",
  },

  usernav: {
    // background: "#4c005d",
    padding: "5px 10px",
    cursor: "pointer",
  },
  sbtn: {
    background: "#E81A7F",
    border: "1px solid #E81A7F",
  },

  sortit: {
    // background: "rgb(133, 55, 138) none repeat scroll 0% 0%",
    margin: "10px",
    borderRadius: "10px",
    textAlign: "left",
    padding: "10px",
  },

  option: {
    background: "transparent",
    border: "none",
    // appearance: "none",
    // color: "#000",
  },
  docName: {
    paddingLeft: 12,
  },
};
