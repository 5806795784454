import React from "react";
import axios from "axios";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useState } from "react";
import moment from "moment";
export default function Medicaldata(props) {
  const [data, setdata] = useState();
  const { id } = useParams();
  console.log(props);

  const [dateState, setDateState] = useState(new Date());

  // setsymptom(props);
  function handleCheckClick(symptom2) {
    var datepiker = moment(dateState).format("YYYY-MM-DD");
    console.log(id);
    console.log(symptom2);
    const headers = {
      Authorization: `token ` + localStorage.getItem("sales"),
    };
    const Data = {
      customer: id,
      symptom: symptom2,
      date: datepiker,
      positive: true,
    };
    axios.patch("admin-panel/submit-critical-symptom/", Data, {
      headers: headers,
    });

    console.log(id);
  }
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("sales"),
      },
    };

    axios
      .get("admin-panel/get-critical-symptoms/?customer=" + id, config)
      .then((res) => {
        console.log(res.data);
        setdata(res.data);
        // setMessage(res.data.messages);
      });
  }, []);
  return (
    <div style={styles.card} className="lightgradient2">
      Other Symptoms
      <hr />
      <div>
        {console.log(data)}
        {data &&
          data.map((personData, key) => {
            return (
              <span className="trnew trin2">
                <td>
                  <input
                    className="invinsiveinput"
                    data-id={personData.id}
                    type="checkbox"
                    ids={personData.id}
                    checked={personData && personData.positive}
                    onChange={(e) => handleCheckClick(personData.id)}
                    defaultChecked={personData && personData.positive}
                    // onChange={this.handleCheckClick}
                  />
                  {/* <label for={personData.id}>{personData.name}</label> */}
                </td>
                <td className="tdoneline">{personData.name}</td>
              </span>
            );
          })}
      </div>
    </div>
  );
}
const styles = {
  card: {
    // display: "flex",
    justifyContent: "space-between",
    // backdropFilter: "blur(31px) brightness(-15%) opacity(.3)",
    padding: "20px",
    overflow: "hidden",
    borderRadius: "10px",
    margin: "10px",
  },
};
