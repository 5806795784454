export default function Toggle(){

return (
<>
<ul style={style.list}>
<li style={style.listItem}>Profile updated..</li>
<li style={style.listItem}>New Messages</li>
</ul>
</>
);

}


const style = {
list:{
height:'10rem', 
borderRadius:'10px', 
boxShadow:'2px 2px 1px #333',
fontSize:'12px', 
padding:'10px', 
background:'linear-gradient(90deg, #b53899 60%, #3a51a7)', 
textAlign:'left', 
listStyle:'none', 
position:'absolute', 
right:'15%', 
top:'6%',
width:'260px'
},

listItem:{
padding:'5px',
borderBottom:'1px solid #fff'

}

}
