import React from "react";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBolt, faChevronDown } from "@fortawesome/free-solid-svg-icons";
function Vacines() {
  const [predefined, setpredefined] = useState([]);
  const [activeCurrentDate, setActiveCurrentDate] = React.useState(null);
  const setActiveCurrentIndex = (date) => () => {
    if (!!activeCurrentDate && date === activeCurrentDate) {
      setActiveCurrentDate(null);
    } else {
      setActiveCurrentDate(date);
    }
  };

  return (
    <>
      <div className="lightgradient width-60">
        <h6>Vaccinations</h6>
        <div className="accordion croll">
          <div className="accordion-item">
            <div
              className="display-flex
                font-blue"
              style={{ justifyContent: "space-around", fontSize: "18px" }}
              //   onClick={setActiveCurrentIndex(item[0].date)}
              //   onClick={() => setActiveCurrentIndex(item[0].date)}
            >
              {/* <div>{item[0].date}</div> <div>{item[0].time}</div>{" "} */}
              <span style={{ color: "white" }}>At Birth: 05Apr-11Apr21</span>
              <span>
                Vaccinated
                <FontAwesomeIcon
                  icon={faChevronDown}
                  style={{
                    marginLeft: "5rem",
                    cursor: "pointer",
                    color: "white",
                  }}
                />
              </span>
            </div>

            <div className="accordion-content">
              <table>
                <thead>
                  {/* <tr>
                    <th>time</th> <th>contraction</th> <th>pain scale</th>{" "}
                    <th>interval</th>
                  </tr> */}
                </thead>
                <tr>{/* <td>c</td> <td>ffv</td> */}</tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Vacines;
