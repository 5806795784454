import moment from "moment"

export const filterForTableByJsonKey = (array = [], jsonKey) => {

    if (!jsonKey) { return {} }

    return array.reduce((obj, item) => {

        if (!obj[item.date]) {
            obj[item.date] = {}
        };

        let b = obj[item.date]

        b[item[jsonKey].toLowerCase().split(" ").join("_")] = {
            ...item
        }

        return obj
    }, {})


}

export const filterObjectByKeyAsDate = (object = {}) => {
    return Object.keys(object).sort(function (a, b) {
        return moment(b, 'YYYY-MM-DD').toDate() - moment(a, 'YYYY-MM-DD').toDate();
    }).reduce(
        (obj, key) => {
            obj[key] = object[key];
            return obj;
        },
        {}
    )
}