import Image from "../../img/small_image.jpeg";
import { useEffect, useState } from "react";
import DailyTracker from "../traker_by_date2/table_list";
import axios from "axios";
import { useHistory } from "react-router-dom";
export default function PersonalDetail(props) {
  const [tracker, setTracker] = useState(false);
  const [Patient, setPatient] = useState([]);
  const [Patientdetails, setPatientdetails] = useState([]);
  var userid = props.userid1;
  const history = useHistory();

  console.log(props.userid1);
  function openTracker() {
    setTracker(!tracker);
  }
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("doc_token"),
      },
    };

    axios
      .get("doctor/patient-details/?customer=" + userid, config)
      .then((res) => {
        // console.log(JSON.stringify(res.data));
        setPatient(res.data.customer);
        setPatientdetails(res.data.details);
      });
  }, []);
  const callststus = (e) => {
    // e.preventDefault();
    console.log("hello");
    console.log(e);
    history.push(`/callstatus/${e} `);
    window.location.reload();
  };
  const analaitics = (e) => {
    // e.preventDefault();

    history.push(`/defaultanalatics/${e} `);
    window.location.reload();
  };

  return (
    <>
      {console.log(props.userid1)}
      {tracker ? <DailyTracker userId={props.userid1} /> : null}
      <table className="personal-detail outerPerson">
        <tr className="imageflex">
          <td>
            {Patient.profile_pic ? (
              <img
                src={Patient.profile_pic}
                height="75px"
                width="75px"
                style={{ borderRadius: "50%" }}
              />
            ) : (
              <img
                src={Image}
                height="75px"
                width="75px"
                style={{ borderRadius: "50%" }}
                alt=""
              />
            )}
            <br />
            {/* <span style={styles.money}>$600</span> */}
          </td>
          <td style={styles.col}>
            <strong style={{ fontSize: "20px" }}>
              {Patient.firstname} {Patient.lastname}
            </strong>
            <br />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <small
                style={{ padding: "3px 0px", borderBottom: "1px solid #fff" }}
              >
                Username:{" "}
                <span style={{ marginLeft: "10px" }}>
                  {Patient.firstname} {Patient.lastname}
                </span>
              </small>
              <small
                style={{ padding: "3px 0px", borderBottom: "1px solid #fff" }}
              >
                Female | {Patientdetails.age}{" "}
                <span style={{ marginLeft: "10px" }}>
                  {Patientdetails.mobile}
                </span>
              </small>
              <br />
            </div>
          </td>

          <td style={styles.col}>
            {/* <span style={styles.ftext} onClick={() => callststus(Patient.id)}>
              Call Status
            </span> */}
            <br />
          </td>
          {/* <td style={styles.col}>
            <span style={styles.ftext} onClick={() => analaitics(Patient.id)}>
              Analytics
            </span>
          </td> */}
        </tr>
        <tr>
          <td className="removetd"></td>
          <td style={styles.co}>Full name</td>
          <td style={styles.co}>
            {Patient.firstname} {Patient.lastname}
          </td>
        </tr>
        <tr>
          <td className="removetd"></td>
          <td style={styles.co}>Age</td>
          <td style={styles.co}>{Patientdetails.age}</td>
        </tr>
        <tr>
          <td className="removetd"></td>
          <td style={styles.co}>Email</td>
          <td style={styles.co} className="texttransform">
            {Patient.email}
          </td>
        </tr>
        <tr>
          <td className="removetd"></td>
          <td style={styles.co}>Address</td>

          <td style={styles.co}>{Patientdetails.address}</td>
        </tr>
        <tr>
          <td className="removetd"></td>
          <td style={styles.co}>Mobile</td>

          <td style={styles.co}>{Patient.mobile}</td>
        </tr>
        {/* <tr>
          <td></td>
          <td style={styles.co}>Individual ID proof with photo</td>
          <td>
            <input type="text" value="Individual id proof of photo" />
          </td>
        </tr> */}
        <tr>
          <td className="removetd"></td>
          <td style={styles.co}>Husband Name</td>

          <td style={styles.co}>{Patientdetails.husband}</td>
        </tr>
        <tr>
          <td className="removetd"></td>
          <td style={styles.co}>Your Job</td>

          <td style={styles.co}>{Patientdetails.job}</td>
        </tr>
      </table>
    </>
  );
}

const styles = {
  dprofile: {
    background: "rgb(76, 0, 61) none repeat scroll 0% 0%",
    width: "41.3rem",
    borderRadius: "10px",
    padding: "10px",
  },

  titlePlate: {
    padding: "10px",
    color: "#fff",
  },
  flex: {
    display: "flex",
    justifyContent: "space-around",
    padding: "10px",
  },
  col: {
    padding: "10px",
    textAlign: "left",
  },
  co: {
    padding: "10px",
    textAlign: "left",
    // border: "1px solid #85388E",
    // background: "#85388E",
    background:
      " 0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
  },
  money: {
    color: "#fff",
  },

  btn: {
    padding: "3px",
    background: "#85388E",
    border: "none",
  },

  fee: {
    padding: "5px",
    background: "transparent",
    outline: "1px solid #85388E",
  },

  ftext: {
    fontSize: "12px",
    padding: "5px",

    background:
      " 0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
    marginRight: "2rem",
    outline: "1px solid #fff",
    cursor: "pointer",
  },
};
