import React from "react";
import axios, { post } from "axios";
import Calendar from "react-calendar";
import moment from "moment";
import { Link } from "react-router-dom";

import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// import "./calendar.css";
//import axios from "axios";

// import React from 'react'

// export default function medicaldata() {
//   return (
//     <div>medicaldata</div>
//   )
// }

export default function Medicaldata() {
  const { id } = useParams();

  //   console.log();
  const [item, setitem] = useState();
  var todays = new Date();

  const [native, setNative] = useState();
  const onNativeChange = (e) => {
    console.log(e.target.value);
    setNative(e.target.value);
  };

  useEffect(() => {
    const token = localStorage.getItem("sales");
    if (!token) {
      window.location = "/login";
    }

    axios
      .get(`/customer/get-medical-form-data`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `token ` + localStorage.getItem("sales"),
        },
        params: {
          customer: id,
          date: native,
        },
      })
      .then((res) => {
        setitem(res.data);
      });
  }, [native]);

  //   constructor(props) {
  //     super(props);

  // this.state = {
  //   medicineOpen: true,
  //   medicineReady: true,
  //   size: "auto",
  //   allValues: {
  //     AppointmentDate: "",
  //     Date: "",
  //     ScanDate: "",
  //     question: "",
  //     bp: "",
  //     ultraSound: "",
  //     BloodReport: "",
  //     AntenatalCharts: "",
  //     Weight: "",
  //   },
  //   files: {
  //     ultraSound: null, // <- these keys should match the 'name' of the inputs
  //     BloodReport: null,
  //     AntenatalCharts: null,
  //   },
  //   image_file: null,
  //   image_preview: "",
  //   file: null,
  // };
  // this.onsubmbit = this.onsubmbit.bind(this);
  // this.onChange = this.onChange.bind(this);
  // // this.fileUpload = this.fileUpload.bind(this);

  // this.closeMedicine = this.closeMedicine.bind(this);
  //   }

  //   changeHandler = (e) => {
  //     this.setState({
  //       allValues: { ...this.state.allValues, [e.target.name]: e.target.value },
  //     });
  //   };

  //   onChange(e) {
  //     let files = this.state.files;
  //     files[e.target.name] = e.target.files[0];

  //     this.setState({
  //       files: files,
  //     });

  //     console.log(e.target.files[1]);
  //console.log(e.target.files[0]);
  //let image_as_base64 = URL.createObjectURL(e.target.files[0]);

  //let image_as_files = e.target.files[0];

  // this.setState({
  //   image_file: {
  //     ...this.state.image_file,
  //     [e.target.name]: e.target.files[0],
  //   },
  //   //image_preview: image_as_base64,
  //   //   image_file: image_as_files,
  // });
  //this.setState({ file: e.target.files[0] });
  //   }
  //   onChangeImages = (e) => {
  //     this.setState({ images: e.target.files });
  //   };

  //   fileUpload(file) {
  //     const url = "https://shebirth.herokuapp.com/customer/medical-ADD/";
  //     const formData = new FormData();
  //     formData.append("ScanReport", file);
  //     const config = {
  //       headers: {
  //         Authorization: `token ` + localStorage.getItem("token"),
  //         "content-type": "multipart/form-data",
  //       },
  //     };
  //     console.log(file);
  //     return post(url, formData, config);
  //   }

  //   onsubmbit = (e) => {
  //     e.preventDefault();
  // if (this.state.image_file !== null) {
  // console.log(this.state.allValues.Weight);

  // let formData = new FormData();
  // if (this.state.files.ultraSound && this.state.files) {
  //   formData.append("ultraSound ", this.state.files.ultraSound);
  // }
  // if (this.state.files.BloodReport && this.state.files) {
  //   formData.append("bloodReport", this.state.files.BloodReport);
  // }
  // if (this.state.files.AntenatalCharts && this.state.files) {
  //   formData.append("antenatalChart", this.state.files.AntenatalCharts);
  // }
  // if (this.state.allValues.AppointmentDate && this.state.allValues) {
  //   formData.append("appointmentDate", this.state.allValues.AppointmentDate);
  // }
  // if (this.state.allValues.Date && this.state.allValues) {
  //   formData.append("date", this.state.allValues.Date);
  // }
  // if (this.state.allValues.ScanDate && this.state.allValues) {
  //   formData.append("scantDate", this.state.allValues.ScanDate);
  // }
  // if (this.state.allValues.question && this.state.allValues) {
  //   formData.append("question", this.state.allValues.question);
  // }
  // formData.append("bp", this.state.allValues.bp);

  // formData.append("weight", this.state.allValues.Weight);

  // const Data = {
  //   name: this.state.allValues.AppointmentDate,
  //   // module: "exercise",
  // };
  // formData.append("bloodReport", this.state.image_file.bloodReport);
  // formData.append("scanReport", this.state.image_file);
  // the image field name should be similar to your api endpoint field name
  // in my case here the field name is customFile

  //     axios
  //       .post(
  //         "customer/medical-ADD/",
  //         formData,

  //         {
  //           headers: {
  //             Authorization: `token ` + localStorage.getItem("token"),
  //             "Content-type": "multipart/form-data",
  //           },
  //         }
  //       )
  //       .then((res) => {
  //         alert("Update Success");
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   };

  // this.fileUpload(this.state.file).then((response) => {
  //   console.log(response.data);
  // });
  // var formData = new FormData();
  // var imagefile = document.querySelector("#file");
  // formData.append("scanReport", imagefile.files[0]);
  // console.log(imagefile);
  // const headers = {
  //   Authorization: `token ` + localStorage.getItem("token"),
  // };
  // axios
  //   .post("https://shebirth.herokuapp.com/customer/medical-ADD/", formData, {
  //     headers: headers,
  //   })
  //   .then(() => {
  //     alert("Medicine was submitted");
  //   })
  //   .catch((error) => {
  //     alert("Cannot add Medicine again");
  //   });

  //shebirth.herokuapp.com/customer/medical-ADD

  //   changeDate(e) {
  //  setdateer(moment(dateState).format("YYYY-MM-DD"));
  //setTracker(!show);
  //  this.setState({ dateState: e.target.value });
  // setdateer(e);
  //   }
  //   closeMedicine() {
  //     this.setState({ medicineOpen: !this.state.medicineOpen });
  //   }

  //   render() {
  return (
    <>
      {/* {console.log(DateState)} */}
      {/* {this.state.medicineOpen ? ( */}

      <div className="module-wrap" style={styles.module}>
        {/* <div className="medical-calendar">
              <Calendar
                onChange={this.changeDate}
                //onClickDay={this.clickTracker}
                value={this.state.dateState}
              />
            </div> */}

        <div className="tabler" style={{ textAlign: "left" }}>
          <div className="flexspace flexspacemob">
            <h4>Select Date</h4>

            <input
              type="date"
              className="calanderbutton smallerdown gradiantblur"
              name="Date"
              value={native}
              style={styles.cutumbuttons}
              onChange={(e) => onNativeChange(e)}
              //   onChange=()=>{changeHandler}
            />
          </div>
          <div className="flexspace flexspacemob">
            <h4> Appointment with doctor</h4>
            <button
              type="disabled"
              className="pupleblurede smallerdown gradiantblur"
              // onChange={this.changeHandler}
              //   disabled
              style={styles.cutumbuttons}
            >
              {item && item.appointmentDate}
            </button>
          </div>
          <div className="flexspace flexspacemob">
            <h4>Next Scan Date</h4>
            <button
              type="disabled"
              style={styles.cutumbuttons}
              className="pupleblurede smallerdown gradiantblur"
              // onChange={this.changeHandler}
              //   disabled
            >
              {item && item.scantDate}
            </button>
          </div>
          <h4 style={{ fontSize: "16px" }}>Question to ask doctor</h4>
          <div
            className="gradiantblur"
            name="question"
            // onChange={this.changeHandler}

            style={{ fontSize: "12px", width: "100%", height: "300px" }}
          >
            {item && item.question}
          </div>
          <div className="flexspace flexspacemob">
            <h4>Ultrasound scan report</h4>
            <a
              target="_blank"
              style={styles.linktext}
              href={item && item.ultraSound}
              className="pinkbutton6 smallerdown"
            >
              download
            </a>
          </div>
          <div className="flexspace flexspacemob">
            <h4>Download Blood Report</h4>
            <a
              target="_blank"
              style={styles.linktext}
              href={item && item.bloodReport}
              className="pinkbutton6 smallerdown"
            >
              download
            </a>
          </div>
          <div className="flexspace flexspacemob">
            <h4>Download Antenatal Charts</h4>
            <a
              target="_blank"
              style={styles.linktext}
              href={item && item.antenatalChart}
              className="pinkbutton6 smallerdown"
            >
              download
            </a>
          </div>
          <div className="flexspace flexspacemob">
            <h4>Todays BP</h4>
            <button
              type="disabled"
              className="pupleblurede smallerdown"
              // onChange={this.changeHandler}
              //   disabled
              style={styles.cutumbuttons}
            >
              {item && item.bp}
            </button>
          </div>
          <div className="flexspace flexspacemob">
            <h4>Enter Todays Weight</h4>
            <button
              type="disabled"
              className="pupleblurede smallerdown"
              // onChange={this.changeHandler}
              //   disabled
              style={styles.cutumbuttons}
            >
              {item && item.weight}
            </button>
          </div>
        </div>

        <br />
      </div>

      {/* ) : null} */}
    </>
  );
}

const styles = {
  module: {
    position: "relative",
    //background: "#ffffff1c 0% 0% no-repeat padding-box",
    margin: "0px 30px",
    borderRadius: "10px",
    padding: "5px 10px",
    height: "30rem",
    overflowY: "scroll",
    scrollbarWidth: "none",
  },
  txt: {
    textAlign: "left",
  },
  linktext: { fontSize: "16px" },
  btn: {
    border: "1px solid #974cae",
    padding: "5px 30px",
    background: "#974cae",
    fontSize: "12px",
  },

  cutumbuttons: {
    width: "116px",
    background: "#ffffff1c 0% 0% no-repeat padding-box",
    border: "none",
    color: "white",
    padding: "1%",
  },
  sbtn: {
    // background: "linear-gradient(90deg, #3a51a7 30%, #b53899)",
    padding: "10px 30px",
    border: "none",
    borderRadius: "10px",
    color: "#fff",
    cursor: "pointer",
    margin: "10px",
  },
};
