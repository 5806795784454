import React from "react";
import { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { Link } from "react-router-dom";
import ball from "./image/baseball-ball.png";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faBaseball } from "@fortawesome/free-solid-svg-icons";

import moment from "moment";
import "./timeline.css";
import axios from "axios";
function Timline() {
  const [Items, setItems] = useState([]);
  const [remadisss, setremadisss] = useState();
  const [remadi, setremadi] = useState(false);
  const [babyDetails, setbabyDetails] = useState();
  const [Symptom, setSymptom] = useState();
  const initialDateState = moment().format("DD-MM-YYYY");
  const [DateState, setDateState] = useState(initialDateState);
  function clickTracker(e) {
    setDateState(moment(e).format("DD-MM-YYYY"));
    // setTracker(!show);
    // setDateState(e);
    console.log(e);
  }
  function closeTabBox() {
    setremadi(false);
  }
  function openModel(symptom) {
    setremadi(true);
    setSymptom(symptom);
    // alert(`Opening model for symptom: ${symptom}`);
  }
  useEffect(() => {
    // console.log(sessionStorage.getItem("dad"));
    axios
      .get(`dad-dashboard/`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Token ${sessionStorage.getItem("dad")}`,
        },
      })
      .then((res) => {
        // setItems(res.data.details.postive_symptoms);
        setbabyDetails(res.data.details.babyDetails);

        // setCount((Count = Count + 1));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [DateState]);

  useEffect(() => {
    axios
      .get(`/dad-dashboard-pos?date=${DateState}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Token ${sessionStorage.getItem("dad")}`,
        },
      })
      .then((res) => {
        setItems(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [DateState]);
  useEffect(() => {
    console.log(Symptom);
    const config = {
      headers: {
        Authorization: `token ` + sessionStorage.getItem("dad"),
      },
    };
    axios.get(`/symptom-remedy-pos?symptom=${Symptom}`, config).then((res) => {
      console.log(res.data);
      setremadisss(res.data);
    });
  }, [Symptom]);
  return (
    <>
      {/* {console.log(remadisss.remedy)} */}
      {remadi ? (
        <div style={styles.card} className="lightgradient">
          <i
            onClick={() => closeTabBox()}
            className="fa fa-close"
            style={styles.closebtn}
          ></i>
          <div className="pinkbutton2">Remedies</div>
          <h6 style={{ textAlign: "initial" }}>{Symptom}</h6>
          {console.log(remadisss)}
          <li style={styles.li}>{remadisss.remedy}</li>
          <br />
          <button className="pinkbutton2">buy package</button>
        </div>
      ) : null}
      <div
        className="display-flex  flex-pc"
        // style={{ display: "flex", justifyContent: "space-around" }}
      >
        <div
          style={{ width: "260px" }}
          className="res-calendar  display-pc-nun"
        >
          <Calendar
            className={["c1", "c2"]}
            // onChange={changeDate}
            onClickDay={clickTracker}
            // value={dateState}
          />
        </div>
        <br />
        <br />
        <br />
        <br />
        <div id="" className="sectionClass display-pc-nun">
          <div className="row ">
            <div className="fullWidth eight columns">
              <div className="cbp_tmlabel2 wow fadeInRight animated">
                week {babyDetails && babyDetails.week}
                <br />
                {babyDetails && babyDetails.description}
              </div>

              <br />
              <br />
              <span className="todaysdate"> {DateState}</span>

              <div className="cbp_tmlabel wow fadeInRight animated  gradiantblur">
                unfortunately MOM is experiencing :{console.log(Items)}
                {/* {Items &&
                  Items.filter((person) => person.date == DateState).map(
                    (personData, key) => {
                      return (
                        <>
                          {(personData.symptom, "dadad")}
                          {console.log(personData.symptom, "dad")},
                        </>
                      );
                    }
                  )} */}
                <br />
                <br />
                <br />
                <br />
                <br />
                <Link to="/remadies">
                  <button className="pinkbutton">Remedies</button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div id="workexperience" className="sectionClass  display-mob-nun">
          <div className="row ">
            <div className="fullWidth eight columns">
              <ul className="cbp_tmtimeline">
                <li>
                  <div className="cbp_tmicon cbp_tmicon-phone">
                    <img src={ball} />
                  </div>
                  <div className="cbp_tmlabel2 wow fadeInRight animated">
                    week {babyDetails && babyDetails.week}
                    <br />
                    {babyDetails && babyDetails.description}
                  </div>
                </li>
                <li>
                  <div className="cbp_tmicon2 cbp_tmicon-phone">
                    <i className="fa fa-calendar"></i>
                  </div>
                  {/* <span className="todaysdate"> {DateState}</span> */}
                  {/* {console.log("Filtered Items:", Items)} */}
                  <div className="cbp_tmlabel wow fadeInRight animated  gradiantblur">
                    <input
                      className="calanderbutton"
                      type="date"
                      onChange={(e) => clickTracker(e.target.value)}
                    />
                    {Items.postive_symptoms &&
                    Items.postive_symptoms[0] ===
                      "Sorry, we have no data for this date" ? (
                      <div>{Items.postive_symptoms[0]}</div>
                    ) : (
                      Items.postive_symptoms &&
                      Items.postive_symptoms.map((personData, key) => (
                        <div
                          className="pinkbutton"
                          key={key}
                          onClick={() => openModel(personData.symptom)}
                        >
                          {personData.symptom}
                        </div>
                      ))
                    )}
                  </div>
                </li>
                <li>
                  <div className="cbp_tmicon2 cbp_tmicon-mail">
                    <i className="fa fa-calendar"></i>
                  </div>
                  <div className="cbp_tmlabel3 wow fadeInRight animated"></div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          style={{ width: "260px" }}
          className="res-calendar display-mob-nun"
        >
          <Calendar
            className={["c1", "c2"]}
            // onChange={changeDate}
            onClickDay={clickTracker}
            // value={dateState}
          />
        </div>
      </div>
    </>
  );
}

export default Timline;
const styles = {
  card: {
    // display: "flex",
    position: "fixed",
    padding: "1%",
    width: "45%",
    right: "27%",
    zIndex: "5",
    padding: "1%",
    zIndex: "5",
    // width: "47%",
    justifyContent: "space-between",
    // background: "#4c003d",
    // padding: "20px",
    borderRadius: "10px",
    margin: "10px",

    background: "rgba( 255, 255, 255, 0.2 );",
    boxShadow: " 0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
    backdropFilter: "blur( 20px )",

    borderRadius: "10px",
    border: "1px solid rgba( 255, 255, 255, 0.18 )",
  },
  closebtn: {
    position: "absolute",
    right: "0",
    top: "-3px",
  },
};
