import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import ClientDailyTracker from "./traker_by_date/table_list";
import ClientProfile from "./client_profile";

export default function AlltimeClientsTable({ clientDetails, searchval }) {
  const [tracker, setTracker] = useState(false);
  const [profile, setProfile] = useState(false);
  const [clientid, setClientid] = useState("");
  const [selectedWeekRange, setSelectedWeekRange] = useState("");

  function openDailyTracker(id) {
    setTracker(!tracker);
    setClientid(id);
  }

  function openProfile(id) {
    setProfile(!profile);
    setClientid(id);
  }

  return (
    <>
      {profile ? <ClientProfile id={clientid} /> : null}

      <div>
        {tracker ? <ClientDailyTracker id={clientid} /> : null}

        <div style={styles.card}>
          <div style={styles.inner}>
            <div className="sort-container clinetsort-container display-flex ">
              <span style={{ fontSize: "12px" }}>
                <FontAwesomeIcon icon={faSort} /> Sort By:{" "}
                <select
                  className="docoptions"
                  style={styles.option}
                  onChange={(e) => setSelectedWeekRange(e.target.value)}
                >
                  <option value="">Week</option>
                  <option value="0-4">0-4</option>
                  <option value="4-8">4-8</option>
                  <option value="8-12">8-12</option>
                  <option value="12-16">12-16</option>
                  <option value="16-20">16-20</option>
                  <option value="20-24">20-24</option>
                  <option value="24-28"> 24-28</option>
                  <option value="28-32">28-32</option>
                  <option value="32-36">32-36</option>
                  <option value="36-40">36-40</option>
                  <option value="40+">40+ </option>
                  {/* Add other week ranges */}
                </select>
              </span>
            </div>
            <p>
              <strong>All Clients This Month </strong>
            </p>
            <hr style={{ color: "#fff" }} />
            <div className="formobile lightgradient">
              <table className="nospace">
                <thead>
                  <tr className="gradiantblur ">
                    <td>Name</td>
                    <td>Age</td>
                    <td>Location</td>
                    <td>Due Date</td>
                    <td>Current Week</td>
                    <td>Daily Tracker Details</td>
                  </tr>
                </thead>
                <tbody>
                  {clientDetails &&
                    clientDetails
                      .filter((ele) => {
                        const weekMatches =
                          !selectedWeekRange ||
                          (ele.week >=
                            parseInt(selectedWeekRange.split("-")[0], 10) &&
                            ele.week <=
                              parseInt(selectedWeekRange.split("-")[1], 10));

                        if (searchval) {
                          const nameMatches =
                            ele.firstName
                              .toLowerCase()
                              .includes(searchval.toLowerCase()) ||
                            ele.lastName
                              .toLowerCase()
                              .includes(searchval.toLowerCase());
                          return weekMatches && nameMatches;
                        } else {
                          return weekMatches;
                        }
                      })
                      .map((ele) => {
                        return (
                          <tr key={ele.id} className="gradiantblur ">
                            <td
                              style={styles.usernav}
                              onClick={() => openProfile(ele.id)}
                            >
                              {" "}
                              {ele.firstName} {ele.lastName}
                            </td>
                            <td>{ele.age}</td>
                            <td>{ele.location}</td>
                            <td>{ele.dueDate}</td>
                            <td>{ele.week}</td>
                            <td>
                              <button onClick={() => openDailyTracker(ele.id)}>
                                Details
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const styles = {
  card: {
    // display: "flex",
    justifyContent: "space-between",
    // background: "#4c003d",
    padding: "20px",
    borderRadius: "10px",
    margin: "10px",
  },
  inner: {
    textDecoration: "none",
    color: "#fff",
  },

  usernav: {
    // background: "#4c005d",
    padding: "5px 10px",
    cursor: "pointer",
  },
  red: { background: "red", border: "1px solid red", padding: "8% 30%" },
  green: { background: "green", border: "1px solid green", padding: "8% 30%" },
  yellow: {
    background: "yellow",
    border: "1px solid yellow",
    padding: "8% 30%",
  },
};
