// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
// import { useState } from "react";
// import Salesmodel from "./mod/add_hospital";
// export default function Hospital() {
//   const [isOpen, setisOpen] = useState(false);

//   function clickModel() {
//     setisOpen({ isOpen: !isOpen });
//   }
//   return (
//     <>
//       {isOpen ? <Salesmodel /> : null}
//       <div style={styles.card}>
//         <div style={styles.left}>
//           <p>
//             <strong style={{ whiteSpace: "nowrap", fontSize: "16px" }}>
//               Total Hospitals
//             </strong>
//             <hr style={{ color: "#fff" }} />
//           </p>
//         </div>

//         <div style={styles.right}>
//           <h2 style={styles.counter}>9</h2>
//           <button style={styles.link} onClick={() => clickModel()}>
//             Add Now <FontAwesomeIcon icon={faChevronCircleRight} />
//           </button>
//         </div>
//       </div>
//     </>
//   );
// }

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import Modelconsultant from "./mod/add_consultant";
import Hospital from "./mod/add_hospital";
export default class hospital extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isOpen: false };
    this.Clickhandler = this.Clickhandler.bind(this);
  }

  Clickhandler() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  render() {
    return (
      <>
        {this.state.isOpen ? <Hospital /> : null}
        <div style={styles.card} className="lightgradient2">
          <div style={styles.left}>
            <p>
              <strong style={{ whiteSpace: "nowrap", fontSize: "16px" }}>
                Total Hospitals
              </strong>
              <hr style={{ color: "#fff" }} />
            </p>
          </div>
          <div style={styles.right}>
            <h2 style={styles.counter}>{this.props.totalHospitals}</h2>

            <button
              style={styles.link}
              onClick={this.Clickhandler}
              className="lightgradient2"
            >
              Add Now <FontAwesomeIcon icon={faChevronCircleRight} />
            </button>
          </div>
        </div>
      </>
    );
  }
}

const styles = {
  card: {
    display: "flex",
    flexDirection: "column",

    // backdropFilter: "blur(31px) brightness(-15%) opacity(.4)",

    width: "13.5rem",
    height: "10rem",
    padding: "20px",
    borderRadius: "10px",
    margin: "10px",
    lineHeight: "10px",
  },
  link: {
    fontSize: "13px",
    textDecoration: "none",
    color: "#fff",
    background: "#5a0f2c",
    padding: "10px",
    borderRadius: "10px",
    border: "none",
    cursor: "pointer",
  },

  left: {
    padding: "0px",
  },
  right: {
    padding: "0px",
  },
  counter: {
    lineHeight: "1px",
    color: "#fff",
  },
};
