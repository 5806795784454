import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const Searchclients = ({ onChange, value = "" }) => {
  const [showResults, setShowResults] = React.useState(true);
  const hideShow = () => setShowResults(false);

  return (
    <div className="" style={styles.card}>
      <div style={styles.inner}>
        <p style={{ textAlign: "left" }}>Search Clients</p>
        <form style={{ display: "flex" }}>
          {showResults ? (
            <FontAwesomeIcon style={{ marginRight: "-22px" }} icon={faSearch} />
          ) : null}

          <input
            value={value}
            onChange={onChange}
            onFocus={hideShow}
            style={styles.input}
            type="search"
          />
        </form>
      </div>
    </div>
  );
};

const styles = {
  card: {
    display: "flex",
    justifyContent: "space-between",
    background: "#ffffff1c 0% 0% no-repeat padding-box",
    padding: "20px",
    borderRadius: "10px",
    margin: "10px",
  },
  inner: {
    textDecoration: "none",
    color: "#fff",
  },

  input: {
    width: "52rem",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderBottom: "1px solid #fff",
    background: "transparent",
    padding: "8px",
    color: "aliceblue",
  },
};

export default Searchclients;
