import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router";
export default function MedicalDetail({ id }) {
  const [medicaldata, setMedicaldata] = useState([]);

  useEffect(() => {
    axios
      .get(`customer/medical-get-all/`, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
        params: {
          customer: id,
        },
      })
      .then((res) => {
        setMedicaldata(res.data.investigation_data);
      });
  }, []);
  console.log(medicaldata);
  return (
    <div className="scrollerbar">
      <table
        style={{
          width: "100%",
          borderSpacing: "0px 1em",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr
            style={{
              background:
                "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
            }}
            className="font-blue"
          >
            <th style={styles.th}>Date</th>
            <th style={styles.th}>Hb</th>
            <th style={styles.th}>Hb normal</th>
            <th style={styles.th}>ICT</th>
            <th style={styles.th}>ICT normal</th>
            <th style={styles.th}>Urine R/E</th>
            <th style={styles.th}>Urine R/E normal</th>
            <th style={styles.th}>Urine C/S</th>
            <th style={styles.th}>Urine C/S normal</th>
            <th style={styles.th}>vdrl</th>
            <th style={styles.th}>vdrl normal</th>
            <th style={styles.th}>HIV</th>
            <th style={styles.th}>HIV normal</th>
            <th style={styles.th}>RBS first trimester</th>
            <th style={styles.th}>RBS first trimester normal</th>
            <th style={styles.th}>OGCT 2nd trimester - if raised</th>
            <th style={styles.th}>OGCT 2nd trimester - if raised normal</th>
            <th style={styles.th}>OGTT+</th>
            <th style={styles.th}>OGTT+ normal</th>
            <th style={styles.th}>hcv</th>
            <th style={styles.th}>hcv normal</th>
            <th style={styles.th}>creatine</th>
            <th style={styles.th}>creatine normal</th>
            <th style={styles.th}>double marker</th>
            <th style={styles.th}>double marker normal</th>
            <th style={styles.th}>tft </th>
            <th style={styles.th}>tft normal</th>
            <th style={styles.th}>tft description+</th>
            <th style={styles.th}>others</th>
            <th style={styles.th}>others normal</th>
            <th style={styles.th}>others description</th>
            <th style={styles.th}>scan</th>
          </tr>
        </thead>

        <tbody>
          {medicaldata.map((ele) => {
            return (
              <tr
                style={{
                  background:
                    "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
                }}
              >
                <td style={styles.tdFirst}>{ele.date}</td>
                <td style={styles.td}>{ele.hb_value}</td>
                <td style={styles.td}>{ele.hb_normal}</td>
                <td style={styles.td}>{ele.ict_value}</td>
                <td style={styles.td}>{ele.ict_normal}</td>
                <td style={styles.td}>{ele.urineRE_value}</td>
                <td style={styles.td}>{ele.urineRE_normal}</td>
                <td style={styles.td}>{ele.urineCS_value}</td>
                <td style={styles.td}>{ele.urineCS_normal}</td>
                <td style={styles.td}>{ele.vdrl_value}</td>
                <td style={styles.td}>{ele.vdrl_normal}</td>
                <td style={styles.td}>{ele.hiv_value}</td>
                <td style={styles.td}>{ele.hiv_normal}</td>
                <td style={styles.td}>{ele.rbs_first_trimester_value}</td>
                <td style={styles.td}>{ele.rbs_first_trimester_normal}</td>
                <td style={styles.td}>{ele.ogct_second_trimester_value}</td>
                <td style={styles.td}>{ele.ogct_second_trimester_normal}</td>
                <td style={styles.td}>{ele.ogtt_second_trimester_value}</td>
                <td style={styles.td}>{ele.ogtt_second_trimester_normal}</td>
                <td style={styles.td}>{ele.hcv_value}</td>
                <td style={styles.td}>{ele.hcv_normal}</td>
                <td style={styles.td}>{ele.creatine_value}</td>
                <td style={styles.td}>{ele.creatine_normal}</td>
                <td style={styles.td}>{ele.double_marker_value}</td>
                <td style={styles.td}>{ele.double_marker_normal}</td>
                <td style={styles.td}>{ele.tft_value}</td>
                <td style={styles.td}>{ele.tft_normal}</td>
                <td style={styles.td}>{ele.tft_description}</td>
                <td style={styles.td}>{ele.others_value}</td>
                <td style={styles.td}>{ele.others_normal}</td>
                <td style={styles.td}>{ele.others_description}</td>
                <td style={styles.td}>{ele.scan}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

const styles = {
  th: {
    fontSize: "17px",
    whiteSpace: "nowrap",
    padding: "5px 10px",
    margin: "10px 0px",
  },

  td: {
    border: "1px solid black",
    padding: "5px 10px",
    fontSize: "12px",
    textAlign: "left",
    lineHeight: "20px",
    height: "15rem",
  },
  td: {
    border: "1px solid black",
    padding: "5px 10px",
    fontSize: "12px",
    background:
      "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
    textAlign: "left",
    lineHeight: "20px",
    // height: "15rem",
  },
  tdFirst: {
    padding: "5px 10px",
    textAlign: "left",
    fontSize: "12px",
  },
};
