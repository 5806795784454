import React from "react";
import "../tabs.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
/*import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';*/
//import EditPersonalDetail from './innercom/edit_personal';
import AMan from "../img/man.jpg";
import axios from "axios";
import ASummery from "./innercom/open_summery";
import Reshedule from "./booking";
import Axios from "axios";

class Appointment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSummery: false,
      isShedule: false,
      upcoming: [],
      id: "",
      comleted: [],
      waiting:[],
      
    };
    this.openSummery = this.openSummery.bind(this);
    this.openShedule = this.openShedule.bind(this);
  }

  openSummery(e) {
    this.setState({ isSummery: !this.state.isSummery, id: e });
  }

  openShedule(e) {
    
    this.setState({ isShedule: !this.state.isShedule, id: e });
  }
  componentDidMount() {
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("token"),
      },
    };

    axios.get("appointments/upcoming-appointments/", config).then((res) => {
      // console.log("upcoming", res.data);
      // console.log(res.data);
      //   res.data;
      this.setState({ upcoming: res.data });
    });
    axios.get("appointments/completed-appointment/", config).then((res) => {
      // console.log("complted", res.data);
      //   console.log("api called");
      //   res.data;
      this.setState({ comleted: res.data });
    });
    axios.get('appointments/clients-appointment-payments/',config).then((res)=>{
      this.setState({ waiting :res.data});
    });
    
  }

  render() {
    return (
      <>
        {/* {console.log(this.state.id)} */}
        {this.state.isShedule ? <Reshedule user={this.state.id} /> : null}
        {this.state.isSummery ? (
          <ASummery user={this.state.id} />
        ) : (
          <div style={styles.card}>
            <div style={styles.inner}>
              <Tabs>
                <TabList>
                  <Tab>
                    <strong style={{ padding: "10px", cursor: "pointer" }}>
                      Upcoming
                    </strong>
                  </Tab>
                    <Tab>
                      Waiting
                    </Tab>
                  <Tab>
                    <strong style={{ padding: "10px", cursor: "pointer" }}>
                      Completed
                    </strong>
                  </Tab>
                </TabList>

                <TabPanel>
                  <div className="appointment-tabs " style={styles.innerscroll}>
                    {this.state.upcoming.map((e) => (
                      <div style={styles.upcom}>
                        <div>
                          {e.doctor_profile_pic ? (
                            <img
                              src={e.doctor_profile_pic}
                              alt=""
                              style={styles.manpic}
                            />
                          ) : (
                            <img src={AMan} alt="" style={styles.manpic} />
                          )}
                          <div>
                            <div style={styles.info}>
                              <strong style={{ fontSize: "16px" }}>
                                Dr. {e.doctor_firstname} {e.doctor_lastname}
                              </strong>
                              <span> {e.doctor_qualification}</span>
                              <span>
                                {e.doctor_experience} years of experience
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="appointment-inner">
                          <div className="aleft">
                            <div
                              style={{
                                textAlign: "left",
                                padding: "5px",
                                fontSize: "18px",
                                background:
                                  "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
                              }}
                            >
                              <strong>Slot -{e.time}</strong>
                              <br />
                              <strong>Date - {e.date}</strong>
                            </div>
                          </div>
                        </div>
                        <div style={{ margin: "auto 0" }}>
                          <div style={styles.r2btn}>
                            {console.log(e.meeting_url)}
                            <a
                              href={e.meeting_url}

                              // onClick={() => this.openShedule(e.id)}
                              //   onClick={this.openShedule}
                            >
                              <button className="sbtn" style={styles.rbtn}>
                                join
                              </button>
                            </a>
                          </div>
                          <div
                            className="reshedulediv"
                            // style={{ textAlign: "right", marginRight: "5%" }}
                          >
                            <button
                              className="sbtn"
                              style={styles.rbtn}
                              onClick={() => this.openShedule(e.id)}
                              //   onClick={this.openShedule}
                            >
                              Reshedule
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </TabPanel>
                            <TabPanel>
                            <div className="appointment-tabs " style={styles.innerscroll}>
                    {this.state.waiting.map((e) => (
                      <div style={styles.upcom}>
                        <div>
                          {e.profile_img ? (
                            <img
                              src={e.profile_img}
                              alt=""
                              style={styles.manpic}
                            />
                          ) : (
                            <img src={AMan} alt="" style={styles.manpic} />
                          )}
                          <div>
                            <div style={styles.info}>
                              <strong style={{ fontSize: "16px" }}>
                                Dr.{e.doctor_firstname}.{e.doctor_lastname}
                              </strong>
                              
                            </div>
                          </div>
                        </div>
                        <div className="appointment-inner">
                          <div className="aleft">
                            <div
                              style={{
                                textAlign: "left",
                                padding: "5px",
                                fontSize: "18px",
                                background:
                                  "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
                              }}
                            >
                              <span>patment - {e.payment}</span>
                              <br />
                              <strong>Status - waiting</strong>
                            </div>
                          </div>
                        </div>
                        <div style={{ margin: "auto 0" }}>
                          <div style={styles.r2btn}>
                          
                            
                          </div>
                          <div
                            className="reshedulediv"
                            // style={{ textAlign: "right", marginRight: "5%" }}
                          >
                            
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                            </TabPanel>
                <TabPanel>
                  <div className="appointment-tabs " style={styles.innerscroll}>
                    {this.state.comleted.map((e) => (
                      <div style={styles.upcom}>
                        <div>
                          {e.doctor_profile_pic ? (
                            <img
                              src={e.doctor_profile_pic}
                              alt=""
                              style={styles.manpic}
                            />
                          ) : (
                            <img src={AMan} alt="" style={styles.manpic} />
                          )}

                          <div style={styles.info}>
                            <strong style={{ fontSize: "16px" }}>
                              Dr. {e.doctor}{" "}
                            </strong>
                            <span> {e.qualification}</span>
                            <span>{e.experience} years of experience</span>
                          </div>
                        </div>
                        <div className="appointment-inner">
                          <div className="aleft">
                            <div
                              style={{
                                textAlign: "left",
                                padding: "5px",
                                fontSize: "18px",
                                background:
                                  "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
                              }}
                            >
                              <strong>Slot -{e.time}</strong>
                              <br />
                              <strong>Date - {e.date}</strong>
                            </div>
                          </div>
                        </div>
                        <div style={{ textAlign: "right", margin: "auto 0" }}>
                          <button
                            className="sbtn"
                            style={styles.sbtn}
                            onClick={() => this.openSummery(e.id)}
                            //   onClick={this.openSummery}
                          >
                            Summary
                          </button>
                        </div>
                      </div>
                    ))}
                    {/* <div style={styles.upcom}>
                      <img src={AMan} alt="" style={styles.manpic} />
                      <div className="appointment-inner">
                        <div className="aleft">
                          <div>
                            <strong>Dr. Avinash Nambihar</strong>
                            <br />
                            <small style={styles.info}>
                              Paediatrician <span>11 years of experience</span>{" "}
                            </small>
                            <br />
                          </div>
                          <div
                            style={{
                              textAlign: "left",
                              padding: "5px",
                              background: "#651c6a",
                            }}
                          >
                            <strong>Slot -9.00-9.30</strong>
                            <br />
                            <strong>Date - 09/08/2021</strong>
                          </div>
                        </div>

                        <div style={{ textAlign: "right" }}>
                          <button
                            className="sbtn"
                            style={styles.sbtn}
                            onClick={this.openSummery}
                          >
                            Summery
                          </button>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        )}
      </>
    );
  }
}

const styles = {
  r2btn: { margin: "15px" },
  card: {
    // display: "flex",
    justifyContent: "space-between",
    //background:'#9038a3',
    background: "transparent",
    height: "500px",
    // overflowX: "scroll",
    padding: "20px",
    borderRadius: "10px",
    margin: "10px",
  },

  inner: {
    textDecoration: "none",
    color: "#fff",
  },
  innerscroll: { overflowX: "scroll", height: "470px" },

  info: {
    display: "grid",
    fontSize: "12px",
  },

  editbtn: {
    border: "1px solid #fff",
    background: "transparent",
    padding: "5px 10px",
    color: "#fff",
    cursor: "pointer",
    borderRadius: "7px",
  },

  manpic: {
    width: "80px",
    height: "80px",
    borderRadius: "50%",
    marginRight: "10px",
  },

  rbtn: {
    background: "#e81a7f",
    color: "#fff",
    // padding: "7px 40px",

    width: "100px",

    border: "none",

    margin: "10px",

    borderRadius: "5px",
    cursor: "pointer",
    // right: "24%",
    /* top: 20px; */
    // bottom: "15%",
    height: "30px",
    margin: "auto",
    // position: "absolute",
  },
  rbtn2: {
    background: "#e81a7f",
    color: "#fff",
    padding: "0px 40px",
    fontSize: "14px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  sbtn: {
    background: "#00ACC8",
    color: "#fff",
    padding: "7px 40px",
    fontSize: "14px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  // sbtn2: {
  //   background: "#00ACC8",
  //   color: "#fff",
  //   padding: "0px 40px",
  //   border: "none",
  //   borderRadius: "5px",
  //   cursor: "pointer",
  // },

  upcom: {
    marginBottom: "2rem",
    display: "flex",
    justifyContent: "space-between",
    width: "auto",
    background: "#ffffff1f",
    padding: "30px",
  },
};

export default Appointment;
