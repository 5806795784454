import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
export default function MedicineTracker(props) {
  const [data, setData] = useState([]);
  const [medicinexc, setmedicine] = useState([]);
  const [medicine2, setmedicine2] = useState([]);
  const [medicine3, setmedicine3] = useState([]);
  const [medicine4, setmedicine4] = useState([]);
  const [medicine5, setmedicine5] = useState([]);
  const [medicine6, setmedicine6] = useState([]);
  var userid = props.userid1;
  console.log(userid);
  useEffect(() => {
    // if (localStorage.getItem("token") === null) {
    //   localStorage.clear();
    //   //window.location = "/clogin";
    // }
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("doc_token"),
      },
    };
    //remove this date after setting up the admin pannel

    axios
      .get(
        "customer/full-medicine-details/?customer=" + userid.id,
        config

        // config
      )
      //   .then((res) => {

      .then((res) => {
        const groupedByDate = res.data.reduce((meals, meal) => {
          if (meal.date in meals) {
            meals[meal.date].push(meal);
          } else {
            meals[meal.date] = [meal];
          }
          return meals;
        }, {});
        const sortedOnDate = Object.values(groupedByDate).sort((arr1, arr2) => {
          const ymdDate1 = arr1[0].date.split("-").reverse().join("-");
          const ymdDate2 = arr2[0].date.split("-").reverse().join("-");
          if (ymdDate1 < ymdDate2) {
            return 1;
          } else if (ymdDate1 > ymdDate2) {
            return -1;
          }
          return 0;
        });
        setData(sortedOnDate);
      });
  }, []);

  // const morningbefore = res.data.find(
  //   (o) => o.MedicationTime === "morning before food"
  // );
  // const morningafter = res.data.find(
  //   (o) => o.MedicationTime === "morning after food"
  // );

  // const afternoonbefore = res.data.find(
  //   (o) => o.MedicationTime === "afternoon before food"
  // );

  // const afternoonafter = res.data.find(
  //   (o) => o.MedicationTime === "afternoon after food"
  // );

  // const nightafter = res.data.find(
  //   (o) => o.MedicationTime === "night after food"
  // );

  // const nightbefore = res.data.find(
  //   (o) => o.MedicationTime === "night before food"
  // );
  ////////////////////..........................................................................
  // let filteredData = [];
  // //console.log(morningbefore.Medicines);
  // morningbefore.Medicines.forEach((e) => {
  //   filteredData.push(e);
  // });
  // setmedicine(filteredData);
  // ////////////////////...............................................................
  // let filteredData2 = [];

  // morningafter.Medicines.forEach((e) => {
  //   filteredData2.push(e);
  // });
  // setmedicine2(filteredData2);
  // ////////////////////...............................................................
  // let filteredData3 = [];

  // afternoonbefore.Medicines.forEach((e) => {
  //   filteredData3.push(e);
  // });
  // setmedicine3(filteredData3);
  // ////////////////////...............................................................
  // let filteredData4 = [];

  // afternoonafter.Medicines.forEach((e) => {
  //   filteredData4.push(e);
  // });
  // setmedicine4(filteredData4);
  // ////////////////////...............................................................
  // let filteredData5 = [];

  // nightafter.Medicines.forEach((e) => {
  //   filteredData5.push(e);
  // });
  // setmedicine5(filteredData5);
  ////////////////////...............................................................
  // let filteredData6 = [];
  // console.log(nightbefore);
  // nightbefore.forEach((e) => {
  //   filteredData6.push(e);
  // });
  // setmedicine6(filteredData6);
  // let obj = res.data.Diet.find((o) => o.mealName === "lunch");
  // let breakfast = res.data.Diet.find((o) => o.mealName === "breakfast");
  // let earlymorning1 = res.data.Diet.find(
  //   (o) => o.mealName === "early morning"
  // );
  // let dinner = res.data.Diet.find((o) => o.mealName === "dinner");
  // let afternoonsnack = res.data.Diet.find(
  //   (o) => o.mealName === "afternoon snack"
  // );
  // let middaysnack = res.data.Diet.find(
  //   (o) => o.mealName === "mid day snack"
  // );
  // let dinnerdrink = res.data.Diet.find(
  //   (o) => o.mealName === "dinner drink"
  // );

  // setearlymoring(earlymorning1);
  // setDinner(dinner);
  // setafternoon(afternoonsnack);
  // setmidday(middaysnack);
  // setdinnerdrink(dinnerdrink);
  // //  setlunch(res.data.Diet[6].food);
  // setbreakfast(breakfast);
  // setlunch(obj);
  // setlunchtime(res.data.Diet[6].time);
  //       });
  //   }, []);

  return (
    <div className="scrollerbar">
      <table style={{ width: "100%", borderSpacing: "0 1em" }}>
        <thead style={{ borderBottom: "5px solid #5c0048" }}>
          <tr
            style={{ background: "#ffffff1c 0% 0% no-repeat padding-box" }}
            className="font-blue"
          >
            <th style={styles.th}>Date</th>
            <th style={styles.th}>
              Morning Before
              <br /> Food
            </th>
            <th style={styles.th}>
              Morning after
              <br /> Food
            </th>
            <th style={styles.th}>
              Afternoon Before
              <br /> Food
            </th>
            <th style={styles.th}>
              Adternoon After
              <br /> Food
            </th>
            <th style={styles.th}>
              Night Before
              <br /> Food
            </th>
            <th style={styles.th}>
              Night After
              <br /> Food
            </th>
          </tr>
        </thead>

        {data.map((arr) => {
          let morningbefore = arr.filter(
            (o) => o.medicationTime === "morning before food"
          );
          let morningafter = arr.filter(
            (o) => o.medicationTime === "morning after food"
          );
          let afternoonbefore = arr.filter(
            (o) => o.medicationTime === "afternoon before food"
          );
          let afternoonafter = arr.filter(
            (o) => o.medicationTime === "afternoon after food"
          );
          // let nightafter = arr.find((o) => o.medicationTime === "afternoon snack");
          const nightafter = arr.filter(
            (o) => o.medicationTime === "night after food"
          );
          var nightbefore = arr.filter(
            (o) => o.medicationTime === "night before food"
          );
          //   let nightbefore = [];
          //   let filteredData6 = [];
          //   nightbefore = arr.find(
          //     (o) => o.medicationTime === "night before food"
          //   );
          //   {
          // console.log("dsds", nightbefore);
          //   }
          //   //   nightbefore.Medicines.forEach((e) => {
          //   //     filteredData6.push(e);
          // setmedicine6(nightbefore);
          //   //   });
          return (
            <tbody key={arr[0].date} style={styles.tbodys}>
              <tr
                style={{
                  background: "#ffffff1c 0% 0% no-repeat padding-box",
                }}
              >
                <td style={styles.tdFirst}> {arr[0].date}</td>

                <td style={styles.td}>
                  {morningbefore.map((personData, index) => {
                    return <div key={index}>{personData.medicine}</div>;
                  })}
                </td>

                <td style={styles.td}>
                  {morningafter.map((personData, index) => {
                    return <div key={index}>{personData.medicine}</div>;
                  })}
                </td>

                <td style={styles.td}>
                  {afternoonbefore.map((personData, index) => {
                    return <div key={index}>{personData.medicine}</div>;
                  })}
                </td>

                <td style={styles.td}>
                  {afternoonafter.map((personData, index) => {
                    return <div key={index}>{personData.medicine}</div>;
                  })}
                </td>

                <td style={styles.td}>
                  {nightbefore.map((personData, index) => {
                    return <div key={index}>{personData.medicine}</div>;
                  })}
                </td>

                <td style={styles.td}>
                  {nightafter.map((personData, index) => {
                    return <div key={index}>{personData.medicine}</div>;
                  })}
                </td>
                {/* <tr>
            <td style={styles.td}>
              {medicine2.map((personData, index) => {
                return <div key={index}>{personData.name}</div>;
              })}
            </td>
            <td style={styles.td}>
              {medicine3.map((personData, index) => {
                return <div key={index}>{personData.name}</div>;
              })}
            </td>
            <td style={styles.td}>
              {medicine4.map((personData, index) => {
                return <div key={index}>{personData.name}</div>;
              })}
            </td>
            <td style={styles.td}>
              {console.log(medicine6)}

              {medicine6.map((personData, index) => {
                return <div key={index}>{personData.name}</div>;
              })}
            </td>
            <td style={styles.td}>
              {medicine5.map((personData, index) => {
                return <div key={index}>{personData.name}</div>;
              })}
            </td> */}
              </tr>
            </tbody>
          );
        })}
      </table>
    </div>
  );
}

const styles = {
  // tbodys: { borderBottom: " 5px solid rgb(92, 0, 72)" },
  th: {
    fontSize: "17px",
    whiteSpace: "nowrap",
    padding: "5px 10px",
    margin: "10px 0px",
  },
  inputstyle: {
    background: "#ffffff1c 0% 0% no-repeat padding-box",
    color: "white",
    border: "none",
  },
  td: {
    border: "1px solid rgb(0 0 0)",
    // borderLeft: "1px solid #fff",
    background:
      "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
    padding: "5px 10px",
    textAlign: "left",
    fontSize: "12px",
  },

  tdFirst: {
    border: "1px solid black",
    padding: "5px 10px",
    fontSize: "12px",
  },
};
