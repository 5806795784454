import React, { useState, createContext } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import TrackerByDate from "./traker_by_date/tabs";

export default function Calender() {
  // const [value] = useState(new Date());
  const [show, setTracker] = useState(false);
  const [dateer, setdateer] = useState();
  const [dateState, setDateState] = useState(new Date());
  const changeDate = (e) => {
    setDateState(e);
    // setdateer(moment(dateState).format("YYYY-MM-DD"));
  };

  //console.log(dateState);
  var pikerdate = moment(dateer).format("YYYY-MM-DD");
  //console.log(dateState);
  function clickTracker(e) {
    //  setdateer(moment(dateState).format("YYYY-MM-DD"));
    setTracker(!show);
    setdateer(e);
  }

  return (
    <>
      {show ? <TrackerByDate dataFromParent={pikerdate} /> : null}
      <div style={{ width: "260px" }} className="res-calendar">
        <Calendar
          className={["c1", "c2"]}
          onChange={changeDate}
          onClickDay={clickTracker}
          value={dateState}
        />
      </div>
    </>
  );
}
