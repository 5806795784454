import React from "react";
// import { filterForTableByJsonKey } from "../../../../helpers/functions";
// import { getAllDietDetails } from "../../../../helpers/service";
//import DietTrackerUpdate from './update_customized_diet';

export default function DietTracker({}) {
  // const [data, setData] = React.useState({});

  // const fetchApi = () => {
  //   const request = getAllDietDetails();

  //   request
  //     .api({
  //       params: {
  //         id: customer,
  //       },
  //     })
  //     .then((response) => {
  //       if (response) {
  //         setData(filterForTableByJsonKey(response, "mealName"));
  //       }
  //     });

  //   return request;
  // };

  // const Row = ([date, item], index) => {
  //   return (
  //     <tr
  //       key={date}
  //       style={{
  //         background:
  //           "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
  //       }}
  //     >
  //       <td style={styles.tdFirst}>{date}</td>
  //       <td style={styles.td}>
  //         {item.early_morning?.food} <br /> {item.early_morning?.time}
  //       </td>
  //       <td style={styles.td}>
  //         {item.breakfast?.food} <br /> {item.breakfast?.time}
  //       </td>
  //       <td style={styles.td}>
  //         {item.mid_day_snack?.food} <br /> {item.mid_day_snack?.time}
  //       </td>
  //       <td style={styles.td}>
  //         {item.lunch?.food} <br /> {item.lunch?.time}
  //       </td>
  //       <td style={styles.td}>
  //         {item.afternoon_snack?.food} <br /> {item.afternoon_snack?.time}
  //       </td>
  //       <td style={styles.td}>
  //         {item.dinner?.food} <br /> {item.dinner?.time}
  //       </td>
  //       <td style={styles.td}>
  //         {item.dinner_drink?.food} <br /> {item.dinner_drink?.time}
  //       </td>
  //     </tr>
  //   );
  // };

  // React.useEffect(() => {
  //   if (!!customer) {
  //     const request = fetchApi();

  //     return () => {
  //       request.cancel();
  //     };
  //   }
  // }, [customer]);

  return (
    <>
      <div className="outer">
        <div style={styles.width}>
          <table>
            <tr style={styles.td}>
              <th>Date</th>
              <th>Exercises</th>
            </tr>
            <tr>
              <td>02/08/2021</td>
              <td>Exercise A</td>
            </tr>
            <tr>
              <td></td>
              <td>Exercise A</td>
            </tr>
            <tr>
              <td></td>
              <td>Exercise A</td>
            </tr>
            <tr>
              <td></td>
              <td>Exercise A</td>
            </tr>
          </table>
        </div>

        <div style={styles.width}>
          <table>
            <tr>
              <td>02/08/2021</td>
              <td>Exercise A</td>
            </tr>
            <tr>
              <td></td>
              <td>Exercise A</td>
            </tr>
            <tr>
              <td></td>
              <td>Exercise A</td>
            </tr>
            <tr>
              <td></td>
              <td>Exercise A</td>
            </tr>
          </table>
        </div>

        <div style={styles.width}>
          <table>
            <tr>
              <td>02/08/2021</td>
              <td>Exercise A</td>
            </tr>
            <tr>
              <td></td>
              <td>Exercise A</td>
            </tr>
            <tr>
              <td></td>
              <td>Exercise A</td>
            </tr>
            <tr>
              <td></td>
              <td>Exercise A</td>
            </tr>
          </table>
        </div>
      </div>
    </>
  );
}

const styles = {
  width: {
    marginTop: "5%",
    width: "50%",
    margin: "auto",
    background:
      "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
  },
  th: {
    fontSize: "17px",
    whiteSpace: "nowrap",
    padding: "5px 10px",
    margin: "10px 0px",
  },

  td: {
    // padding: "5px 0px",
    fontSize: "16px",
    background:
      "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
  },

  tdFirst: {
    padding: "5px 10px",
    fontSize: "12px",
  },

  sbtn: {
    border: "none",
    margin: "5px 0px",
    color: "#fff",
    background: "#E81A7F",
    padding: "8px",
    fontWeight: "bold",
    cursor: "pointer",
  },
};
