//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown, faSort } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import axios from "axios";
import DailyTracker from "./traker_by_date/table_list";
import { useHistory } from "react-router-dom";
import SearchClients from "./search";
import Analsistab from "./analasis_tabs";

import ClientProfile from "./client_profile";

export default function AllClientsTable() {
  const [i, seti] = useState(0);
  const [cal, setcal] = useState([]);
  const [users, setUsers] = useState([]);
  const [userId, setUserId] = useState();
  const [category, setCategory] = useState(null);
  const [showResults, setShowResults] = React.useState(true);
  const hideShow = () => setShowResults(false);
  const [search, setSearch] = useState(null);
  const [shedule, setShedule] = useState(false);
  const [client, setClient] = useState(false);
  const [traker, settraker] = useState(false);
  const [background, setBackground] = useState("white");
  const history = useHistory();
  const findgraph = (e) => {
    // e.preventDefault();


    history.push(`/analatics/${e} `);
    window.location.reload();
  };

  const openCriticality = (e) => {
    // e.preventDefault();


    history.push(`/criticality/${e} `);
    window.location.reload();
  };
  const callststus = (e) => {
    // e.preventDefault();


    history.push(`/callstatus/${e} `);
    window.location.reload();
  };
  // const suggestions = [];
  // showSerch.forEach(function (data) {
  //   suggestions.push(data);
  // });
  //console.log(search);
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("postdoc"),
      },
    };

    axios
      .get("http://127.0.0.1:8000/doctor/view-my-patients/", config)
      .then((res) => {
        getUsers(res.data.customers);
        //console.log(res.data);0
      })
      .catch((err) => {
        console.log(err.message);
      });
    axios.get("doctor/cal_user", config)
      .then((e) => {
        setcal(e.data)
      })
  }, []);

  function handleBackground(e) {
    setBackground(e.target.value);
  }

  function openAnalasis(id) {
    // setUserId(id);
    history.push(`/Analtics/${id}`);
    // setShedule(!shedule);
  }

  function openClinetProfile(id) {
    setUserId(id);
    setClient(!client);
  }
  function openDailytraker(id) {
    setUserId(id);

    settraker(!traker);
  }

  const openGraph = (id) => {
    history.push(`/Sysgraph/${id}`);
  };
  const getUsers = (userList) => {
    setUsers(userList);
  };

  // const byCategory = (user, category) => {
  //   if (category === "0-4") {
  //     var lowerweek = 0;
  //     var upperweek = 4;
  //     return user.currentWeek <= upperweek;
  //   } else if (category === "4-8") {
  //     var lowerweek = 4;
  //     var upperweek = 8;
  //     return user.currentWeek <= upperweek && user.currentWeek >= lowerweek;
  //   } else if (category === "8-12") {
  //     var lowerweek = 8;
  //     var upperweek = 12;
  //     return user.currentWeek <= upperweek && user.currentWeek >= lowerweek;
  //   } else if (category === "12-16") {
  //     var lowerweek = 12;
  //     var upperweek = 16;
  //     return user.currentWeek <= upperweek && user.currentWeek >= lowerweek;
  //   } else if (category === "16-20") {
  //     var lowerweek = 16;
  //     var upperweek = 20;
  //     return user.currentWeek <= upperweek && user.currentWeek >= lowerweek;
  //   } else if (category === "20-24") {
  //     var lowerweek = 20;
  //     var upperweek = 24;
  //     return user.currentWeek <= upperweek && user.currentWeek >= lowerweek;
  //   } else if (category === "24-28") {
  //     var lowerweek = 24;
  //     let upperweek = 28;
  //     return user.currentWeek <= upperweek && user.currentWeek >= lowerweek;
  //   } else if (category === "28-32") {
  //     var lowerweek = 4;
  //     var upperweek = 8;
  //     return user.currentWeek <= upperweek && user.currentWeek >= lowerweek;
  //   } else if (category === "32-36") {
  //     var lowerweek = 32;
  //     var upperweek = 36;
  //     return user.currentWeek <= upperweek && user.currentWeek >= lowerweek;
  //   } else if (category === "36-40") {
  //     var lowerweek = 36;
  //     var upperweek = 40;
  //     console.log(category);
  //     return user.currentWeek <= upperweek && user.currentWeek >= lowerweek;
  //   } else if (category === "40+") {
  //     var lowerweek = 40;
  //     var upperweek = 40;
  //     console.log(category);
  //     return user.currentWeek > upperweek;
  //   } else return user;
  // };

  const bySearch = (user, search) => {
    if (search) {
      // console.log(search);
      if (user && user.firstname) {
        return user.firstname.toLowerCase().includes(search.toLowerCase());
      }
    } else return user;
  };

  const filteredList = (users, search) => {
    return users
      // .filter((user) => byCategory(user, category))
      .filter((user) => bySearch(user, search));
  };

  return (
    <>
      {shedule ? <Analsistab userId={userId} /> : null}
      {client ? <ClientProfile userId={userId} /> : null}
      {traker ? <DailyTracker userId={userId} /> : null}
      <div className="note-container lightgradient" style={styles.card}>
        <div className="search-form" style={styles.inner}>
          <p style={{ textAlign: "left" }}>Search Clients</p>
          <form style={{ display: "flex" }}>
            {showResults ? (
              <FontAwesomeIcon
                style={{ marginRight: "-22px" }}
                icon={faSearch}
              />
            ) : null}

            <input
              onFocus={hideShow}
              // onChange={findDoc}
              onChange={(e) => setSearch(e.target.value)}
              style={styles.input}
              type="Search"
            />
          </form>
          {/* <div style={{ textAlign: "left" }}>{hunt ? hunt.firstname : ""}</div> */}
        </div>
      </div>
      {/* <SearchClients onChange={(e) => setSearch(e.target.value)} /> */}
      {/* <div
        className="sort-container clinetsort-container "
        style={styles.sortit}
      >
        <span style={{ fontSize: "12px" }}>
          {" "}
          <FontAwesomeIcon icon={faSort} /> Sort By:{" "}
          <select
            className="docoptions"
            style={styles.option}
            onChange={(e) => setCategory(e.target.value)}
          >
            <option>Week</option>
            <option value="0-4">0-4</option>
            <option value="4-8">4-8</option>
            <option value="8-12">8-12</option>
            <option value="12-16">12-16</option>
            <option value="16-20">16-20</option>
            <option value="20-24">20-24</option>
            <option value="24-28"> 24-28</option>
            <option value="28-32">28-32</option>
            <option value="32-36">32-36</option>
            <option value="36-40">36-40</option>
            <option value="40+">40+ </option>
          </select>
          <FontAwesomeIcon icon={faSortDown} />{" "}
        </span>
      </div> */}
      <div className="all-clients-table height-600 " style={styles.card}>
        <div style={styles.inner}>
          <p>
            <strong>All Clients Lists</strong>
          </p>
          <hr style={{ color: "#fff" }} />

          <div className="table-cover">
            <table
              style={{
                borderCollapse: "seprate",
                width: "41.6rem",
                borderSpacing: "0 1em",
              }}
            >
              <thead>
                <tr style={styles.tr}>
                  <td>Profile pic</td>
                  <td>Name</td>
                  <td>Age</td>
                  <td>Join date</td>
                  <td>calories</td>

                  {/* <td>Location</td> */}
                  <td>Due Date</td>
                  {/* <td>Doctor</td> */}
                  <td>Daily Tracker</td>
                  <td>Criticality Triage</td>
                  <td>call status</td>
                </tr>
              </thead>
              <tbody>

                {filteredList(users, search).map((user) => (

                  // {" "}
                  // {/* {patients
                  //   ? patients.map((e) => ( */}
                  <tr style={styles.tr}>
                    <td onClick={() => openClinetProfile(user.id)}>
                      {user.profile_pic !=
                        "https://sukhprasavam.shebirth.com/media//ProfilePic/default.jpg" ? (
                        <img className="avatar" src={user.profile_pic} alt="" />
                      ) : user.profile_pic ==
                        "https://sukhprasavam.shebirth.com/media//ProfilePic/default.jpg" ? (
                        <button
                          className="avatar aquamarine "
                          style={{ maxWidth: "65px", border: "none" }}
                        ></button>
                      ) : (
                        <button
                          className="avatar aquamarine "
                          style={{ maxWidth: "65px", border: "none" }}
                        ></button>
                      )}
                    </td>
                    <td>{user.firstname}</td>
                    <td>{user.age}</td>
                    <td>{user.dateJoined}</td>
                    <td>
                      {cal.map((entry) => entry.find((c) => c.customer_id === user.id)?.calorie).join(", ") || 0}
                    </td>





                    <td>{user.dueDate}</td>

                    <td>
                      <button
                        onClick={() => openDailytraker(user.id)}
                        style={styles.sbtn}
                      >
                        Detail
                      </button>
                    </td>

                    <td>
                      {user.criticality == "high risk" ? (
                        <button
                          onClick={() => openAnalasis(user.id)}
                          style={styles.red}
                        ></button>
                      ) : user.criticality == "stable" ? (
                        <button
                          onClick={() => openAnalasis(user.id)}
                          style={styles.green}
                        ></button>
                      ) : (
                        <button
                          onClick={() => openAnalasis(user.id)}
                          style={styles.yellow}
                        ></button>
                      )}
                    </td>
                    <td>
                      <button
                        onClick={() => callststus(user.id)}
                        style={styles.sbtn}
                      >
                        Call status{" "}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

const styles = {
  red: { background: "red", border: "1px solid red", padding: "8% 30%" },
  green: { background: "green", border: "1px solid green", padding: "8% 30%" },
  yellow: {
    background: "yellow",
    border: "1px solid yellow",
    padding: "8% 30%",
  },
  card: {
    // display: "flex",
    // justifyContent: "space-between",
    backgroundColor: "#ffffff1c 0% 0% no-repeat padding-box",
    // padding: "20px",
    // borderRadius: "10px",
    // margin: "10px",
  },
  // inner: {
  //   textDecoration: "none",
  //   color: "#fff",
  // },

  input: {
    width: "40rem",
    borderTop: "none",
    borderLeft: "none",
    color: "white",
    borderRight: "none",
    borderBottom: "1px solid rgb(255 255 255 / 39%)",
    background: "transparent",
    padding: "8px",
  },
  card: {
    display: "flex",
    justifyContent: "space-between",
    // backdropFilter: "blur(31px) brightness(-15%) opacity(.3)",
    padding: "0px",
    borderRadius: "10px",
    margin: "10px",
  },
  inner: {
    textDecoration: "none",
    color: "#fff",
    width: "100%",
    margin: "10px 10px 5% 10px",

  },

  sortit: {
    background: "#ffffff1c 0% 0% no-repeat padding-box",
    margin: "10px",
    borderRadius: "10px",
    textAlign: "left",
    padding: "10px",
  },

  option: {
    // background: "#ffffff1c 0% 0% no-repeat padding-box",
    background: "0% 0% no-repeat padding-box padding-box rgb(255 255 255 / 0%)",
    border: "none",
    appearance: "none",
    padding: "1%",
    color: "#fff",
  },

  tolink: {
    background: "#60124D",
    textDecoration: "none",
    padding: "5px",
    color: "#fff",
  },

  sbtn: {
    // background: "#E81A7F",
    // border: "1px solid #E81A7F",
    // padding: "5% 25%",
  },

  tr: {
    margin: "10px auto",
    background: "#ffffff1c 0% 0% no-repeat padding-box",
    // background: "#772068",
    cursor: "pointer",
  },
};
