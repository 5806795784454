import React from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faBook,
  faCalendarCheck,
  faCreditCard,
  faSignOutAlt,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

//import ProfileDetail from './components/profile_tabs';

export default class Togprofile extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isProfile: true, items: [], profile: [], customer: [] };

    this.setWapRef = this.setWapRef.bind(this);
    this.clickedOutside = this.clickedOutside.bind(this);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.clickedOutside);
  }

  setWapRef(node) {
    this.wapRef = node;
  }

  clickedOutside(e) {
    if (this.wapRef && !this.wapRef.contains(e.target)) {
      this.setState({ isProfile: false });
    }
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.clickedOutside);
  }

  logOut = (e) => {
    e.preventDefault();
    sessionStorage.clear();
    window.location = "/admin";
  };
  render() {
    //console.log(this.state.profile);

    return (
      <>
        {this.state.isProfile ? (
          <div ref={this.setWapRef}>
            {this.props.children}

            <ul style={style.list} className="profile">
              <li style={style.listItem}>
                <FontAwesomeIcon
                  icon={faSignOutAlt}
                  style={{ transform: "rotate(180deg)" }}
                />
                <span>
                  <strong
                    onClick={this.logOut}
                    style={{ marginLeft: "5px", cursor: "pointer" }}
                  >
                    Logout
                  </strong>
                </span>
              </li>
            </ul>
          </div>
        ) : null}
      </>
    );
  }
}

const style = {
  list: {
    height: "13rem",
    borderRadius: "10px",
    boxShadow: "2px 2px 1px #333",
    fontSize: "12px",
    padding: "10px",
    background: "linear-gradient(90deg, #b53899 60%, #3a51a7)",
    textAlign: "left",
    listStyle: "none",
    position: "absolute",
    right: "8.6%",
    top: "7%",
    width: "170px",
    zIndex: "5",
  },

  listItem: {
    padding: "5px",
    borderBottom: "1px solid #fff",
  },
};
