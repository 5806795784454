import { faBell, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { getThisMonthClient } from "../../helpers/service";
import TotalClientsAllTime from "../components/alltime_clients";
import TotalClients from "../components/clients";
import Clients from "../components/client_lists";
//import Client from './components/clients';
//import Doctor from './components/doctors';
import MonthlyClientTable from "../components/monthly_clients_table";
import Sidebar from "../components/sidebar";
import Toggler from "../toggle";

export default class Overview extends React.Component {
  constructor(props) {
    super(props);

    this.fetchData = getThisMonthClient();

    this.state = {
      isToggleOn: true,
      details: {},
      searchText: "",
      filteredTableData: [],
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleSearch = (e) => {
    if (!!this.state.details.totalPatients_details) {
      this.setState((prevState) => ({
        ...prevState,
        filteredTableData: this.state.details.totalPatients_details.filter(
          (each) =>
            (!!each.firstName &&
              String(each.firstName)
                .toLowerCase()
                .includes(e.target.value.toLowerCase())) ||
            (!!each.age &&
              String(each.age)
                .toLowerCase()
                .includes(e.target.value.toLowerCase())) ||
            (!!each.location &&
              String(each.location)
                .toLowerCase()
                .includes(e.target.value.toLowerCase())) ||
            (!!each.dueDate &&
              String(each.dueDate)
                .toLowerCase()
                .includes(e.target.value.toLowerCase())) ||
            (!!each.doctorFirstName &&
              String(each.doctorFirstName)
                .toLowerCase()
                .includes(e.target.value.toLowerCase())) ||
            (!!each.doctorLastName &&
              String(each.doctorLastName)
                .toLowerCase()
                .includes(e.target.value.toLowerCase()))
        ),
        searchText: e.target.value,
      }));
    }
  };

  componentDidMount() {
    // this.fetchSalesDashboardDetails.api().then((response) => {
    //   if (response) {
    //     this.setState(prevState => ({
    //       ...prevState,
    //       details: response,
    //       filteredTableData: response.totalPatients_details
    //     }));
    //   }
    // })
  }

  componentWillUnmount() {
    // Cancels the API while cleanup
    this.fetchSalesDashboardDetails.cancel();
  }

  handleClick() {
    this.setState((prevState) => ({
      isToggleOn: !prevState.isToggleOn,
    }));
  }

  render() {
    return (
      <div className="App">
        <Sidebar />
        <header className="overview">
          <p style={{ paddingRight: "10%", textAlign: "right" }}>
            {" "}
            <div>{this.state.isToggleOn ? null : <Toggler />}</div>
            <FontAwesomeIcon
              onClick={this.handleClick}
              style={{ marginRight: "1rem", cursor: "pointer" }}
              icon={faBell}
            />
            <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faUser} /> Alex
          </p>

          <div style={{ width: "89%", display: "flex", paddingLeft: "10px" }}>
            <span style={{ whiteSpace: "nowrap" }}>Clients</span>
            <div style={{ padding: "4px", width: "100%" }}>
              <hr style={{ background: "white", color: "white" }} />
            </div>
          </div>
          <div className="mwrapper">
            <div className="mleft">
              <MonthlyClientTable />
            </div>
            <div className="mright">
              <div
                style={{
                  fontWeight: "bold",
                  textAlign: "left",
                  paddingLeft: "10px",
                }}
              ></div>
              <TotalClients />
              <TotalClientsAllTime />
              <div style={styles.client_list}>
                <strong>Clients</strong>
              </div>
              <Clients />
            </div>
          </div>
        </header>
      </div>
    );
  }
}

const styles = {
  client_list: {
    textTransform: "uppercase",
    textAlign: "left",
    padding: "0px 10px",
    fontSize: "16px",
  },
};
