import React from "react";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";

import { useState, useEffect } from "react";
import axios from "axios";
import ImageModal from "./ImageModal";
import { Link } from "react-router-dom";

export default function Banner() {
  const [Traker, setTraker] = useState("antenatal");
  const [SelectedFile, setSelectedFile] = useState();
  const [Items, setItems] = useState();
  const [Count, setCount] = useState(0);
  const [base, setbase] = useState("https://sukhprasavam.shebirth.com");
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const openModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const deleteImage = (e) => {
    const headers = {
      Authorization: `token ${sessionStorage.getItem("token")}`,
    };
    console.log(headers);
    const Data = {
      id: e,
      //   // stage: "stage9",
      //   notes: notes,
    };

    axios
      .delete("https://sukhprasavam.shebirth.com/banner/", {
        headers: headers,
        data: Data,
      })
      .then(() => {
        alert("success submited");
        // setRefresh(Refresh + 1);
      })
      .catch((error) => {
        alert(error);
      });
    // setTraker(e);

    /// console.log(1);
  };

  //   console.log(base + Items.image);
  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    // setIsSelected(true);
  };
  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (!token) {
      this.setState({ redirect: "/admin/login" });
    }

    axios
      .get(`banner/`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Token ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setItems(res.data.data);
        setCount((Count = Count + 1));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [Count]);
  return (
    <div>
      <div style={styles.card} className="editform gradiantblur">
        <div style={styles.inner}>
          <table className="learning-table-form">
            {Items &&
              Items.map((ele) => {
                // {this.state.list &&
                //   this.state.list.map((ele) => {
                return (
                  <tr>
                    <td style={styles.td}>
                      {/* {base + ele.image} */}
                      <img
                        className="avatar2"
                        src={base + ele.image}
                        onClick={() => openModal(base + ele.image)} // Open modal when image is clicked
                      />
                    </td>
                    <td>
                      {" "}
                      <button
                        style={styles.invisble2}
                        onClick={(e) => deleteImage(ele.id)}
                        // onClick={deleteImage(ele.id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                );
              })}
          </table>
        </div>
      </div>{" "}
      {showModal && (
        <ImageModal imageUrl={selectedImage} onClose={closeModal} />
      )}
    </div>
  );
}
const styles = {
  mibutton: {
    background: "#ffffff1c 0% 0% no-repeat padding-box",
    color: "white",
    textTransform: "inherit",

    borderRadius: "16px",
  },
  card: {
    display: "flex",
    justifyContent: "space-between",
    background: "#ffffff1c 0% 0% no-repeat padding-box",
    padding: "20px",
    borderRadius: "10px",
    margin: "10px",
  },
  inner: {
    textDecoration: "none",
    color: "#fff",
  },
  invisble2: { background: "none", border: "1px solid black" },

  input: {
    width: "40rem",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderBottomColor: "1px solid #fff",
    background: "transparent",
    padding: "8px",
    marginLeft: "30px",
    outline: "none",
    color: "white",
  },
};
