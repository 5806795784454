import { faBars, faBell, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { getSalesDashboardDetails } from "../../helpers/service";
import AlltimeClients from "../components/alltime_clients";
import axios from "axios";
import TClients from "../components/clients";
import Medicaldata from "./medicaldata";
import ClientNoupdates from "../components/clients_no_updates";
/*import Tlist from './components/traker_by_date/tabs';*/
// import ClientsTable from "./components/clients_table";
import Sidebar from "../components/sidebar";
import Toggler from "../toggle";
import ClientProfile from "../toggle_profile";
export default class Overview extends React.Component {
  constructor(props) {
    super(props);

    this.fetchSalesDashboardDetails = getSalesDashboardDetails();

    this.state = {
      isToggleOn: true,
      isProfile: true,
      details: {},
      searchText: "",
      sortBy: "week",
      items4: [],
      filteredTableData: [],
    };
    this.clickedOutside = this.clickedOutside.bind(this);
    this.profileHandler = this.profileHandler.bind(this);

    // this.handleClick = this.handleClick.bind(this);
  }
  clickedOutside(e) {
    if (this.isWap && !this.isWap.contains(e.target)) {
      this.setState({ isToggleOn: true });
      this.setState({ isProfile: true });
    }
  }
  profileHandler() {
    this.setState({ isProfile: !this.state.isProfile });
  }
  componentDidMount() {
    if (localStorage.getItem("sales") === null) {
      localStorage.clear();
      window.location = "/clogin";
    }
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("sales"),
      },
    };

    axios.get("sales/sales-dashboard-details/", config).then((res) => {
      console.log(res.data);
      this.setState({ item: res.data });
      this.setState({ details: res.data.details });
    });
  }

  componentDidMount() {
    if (!localStorage.getItem("token")) {
      localStorage.clear();
      window.location = "/clogin";
      return;
    }

    this.fetchSalesDashboardDetails.api().then((response) => {
      if (response) {
        this.setState((prevState) => ({
          ...prevState,
          details: response,
          filteredTableData: response.totalPatients_details,
        }));
      }
    });
  }

  componentWillUnmount() {
    // Cancels the API while cleanup
    this.fetchSalesDashboardDetails.cancel();
  }

  //   handleClick() {
  //     this.setState((prevState) => ({
  //       ...prevState,
  //       isToggleOn: !prevState.isToggleOn,
  //     }));
  //   }

  render() {
    function openMenu() {
      document.querySelector(".sidebar").classList.toggle("show");
    }
    return (
      <div className="App">
        <Sidebar />
        <header className="overview">
          <div
            ref={this.setWrap}
            style={{ padding: "2% 10%", textAlign: "right" }}
          >
            {" "}
            {/* <div>{this.state.isToggleOn ? null : <Toggler />}</div> */}
            <span>
              <FontAwesomeIcon
                onClick={this.handleClick}
                style={{ marginRight: "1rem", cursor: "pointer" }}
                icon={faBell}
              />
            </span>
            <span>
              <FontAwesomeIcon
                onClick={this.profileHandler}
                style={{ cursor: "pointer" }}
                icon={faUser}
              />
            </span>{" "}
            {
              <span>
                {`${
                  !!this.state.details.firstname
                    ? this.state.details.firstname
                    : ""
                } ${
                  !!this.state.details.lastname
                    ? this.state.details.lastname
                    : ""
                }`.trim()}
              </span>
            }
            {this.state.items4.firstname} {this.props.childern}
            {this.state.isProfile ? null : <ClientProfile />}
          </div>

          <div style={{ width: "89%", display: "flex", paddingLeft: "10px" }}>
            <span onClick={openMenu} className="menu">
              <FontAwesomeIcon
                style={{ marginRight: "20px" }}
                icon={faBars}
                className="menu-icon"
              />
            </span>
            <span style={{ whiteSpace: "nowrap" }}>Overview</span>
            <div style={{ padding: "4px", width: "100%" }}>
              <hr style={{ background: "white", color: "white" }} />
            </div>
          </div>
          <div className="mwrapper">
            <div className="mleft">
              <Medicaldata />
            </div>
            <div className="mright">
              <ClientNoupdates
                count={this.state.details.noUpdateFromClientsCount}
              />
              <TClients count={this.state.details.this_month_patients_count} />
              <AlltimeClients count={this.state.details.total_patients_count} />
            </div>
          </div>
        </header>
      </div>
    );
  }
}
