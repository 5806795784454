import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import axios from "axios";

export default function MonthlyPatients() {
  const [patients, setPatients] = useState();

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("doc_token"),
      },
    };

    axios.get("doctor/doctor-dashboard-details/", config).then((res) => {
      //console.log(JSON.stringify(res.data));
      setPatients(res.data.clientsThisMonth);
    });
  });

  return (
    <>
      <div style={styles.card} className="counter-card lightgradient">
        <div className="leftbox">
          <p>Total Patients This Month</p>
          {/* <button style={styles.link}>
          More <FontAwesomeIcon icon={faChevronCircleRight} />
        </button> */}
        </div>
        <div className="minus-margin">
          <h2>{patients}</h2>
        </div>
      </div>
    </>
  );
}

const styles = {
  card: {
    display: "flex",
    justifyContent: "space-between",
    // backdropFilter: "blur(31px) brightness(-15%) opacity(.3)",
    width: "20rem",
    padding: "20px",
    //borderRadius: "10px",
    // margin: "10px",
  },
  link: {
    fontSize: "13px",
    textDecoration: "none",
    color: "#fff",
    background: "rgb(46 17 55)",
    padding: "10px",
    borderRadius: "10px",
    display: "block",
    width: "100px",
    border: "none",
    cursor: "pointer",
  },

  left: {
    padding: "10px",
  },
  right: {
    padding: "10px",
    borderLeft: "1px solid #fff",
  },
  counter: {
    color: "#fff",
  },
};
