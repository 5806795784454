import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Dietracker from "./tabs_com/diet_tracker";
import Medicinetracker from "./tabs_com/medicine_tracker";
import Exercisetracker from "./tabs_com/exercise_tracker";
import Symptomstracker from "./tabs_com/symptoms_tracker";
import Activitytracker from "./tabs_com/activity_tracker";
import Contractioncounter from "./tabs_com/contraction_counter";
import Medicaldetail from "./tabs_com/medical_detail";
var date;
class Clist extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isClose: true };
    this.closeTabBox = this.closeTabBox.bind(this);
    date = this.props.dataFromParent;
    {
      console.log(this.props);
    }
  }

  closeTabBox() {
    this.setState({ isClose: false });
  }

  render() {
    return (
      <>
        {this.state.isClose ? (
          <div style={styles.card} className="hmdaily_tracker">
            <i
              onClick={this.closeTabBoxReload}
              className="fa fa-close"
              style={styles.closebtn}
            ></i>
            <div style={styles.inner}>
              <Tabs>
                <TabList
                  style={{
                    paddingLeft: "0px",
                    listStyle: "none",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Tab>
                    <strong style={styles.tabnav}>Diet Tracker</strong>
                  </Tab>
                  <Tab>
                    <strong style={styles.tabnav}>Medicine Tracker</strong>
                  </Tab>
                  <Tab>
                    <strong style={styles.tabnav}>Exercise Tracker</strong>
                  </Tab>
                  <Tab>
                    <strong style={styles.tabnav}>Symptoms Tracker</strong>
                  </Tab>
                  <Tab>
                    <strong style={styles.tabnav}>Activity Tracker</strong>
                  </Tab>
                  <Tab>
                    <strong style={styles.tabnav}>Contraction Counter</strong>
                  </Tab>
                  <Tab>
                    <strong style={styles.tabnav}>Medical Details</strong>
                  </Tab>
                </TabList>

                <TabPanel>
                  <Dietracker dataFromParent={date} />

                </TabPanel>

                <TabPanel>
                  <Medicinetracker />
                </TabPanel>

                <TabPanel>
                  <Exercisetracker />
                </TabPanel>

                <TabPanel>
                  <Symptomstracker />
                </TabPanel>

                <TabPanel>
                  <Activitytracker />
                </TabPanel>

                <TabPanel>
                  <Contractioncounter />
                </TabPanel>

                <TabPanel>
                  <Medicaldetail />
                </TabPanel>
              </Tabs>
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

const styles = {
  card: {
    display: "flex",
    justifyContent: "space-between",
    background: "#4c003d",
    padding: "20px",
    borderRadius: "10px",
    margin: "10px",
    position: "absolute",
    left: "290px",
    zIndex: 10,
    height: "20rem",
    width:'57rem',
    overflowX:'scroll'
  },

  inner: {
    textDecoration: "none",
    color: "#fff",
  },

  tabnav: {
    cursor: "pointer",
    fontSize: "14.3px",
    whiteSpace: "nowrap",
    padding: "20px 10px",
  },

  closebtn: {
    position: "absolute",
    right: "0px",
    top: "-20px",
    cursor: "pointer",
  },
};

export default Clist;
