import { faBolt, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { getAllDateContractions } from "../../../../helpers/service";

export default function ContractionCounter({ customer }) {
  const [data, setData] = React.useState({});

  const [activeCurrentDate, setActiveCurrentDate] = React.useState(null);

  const fetchApi = () => {
    const request = getAllDateContractions();

    request
      .api({
        params: {
          customer,
        },
      })
      .then((response) => {
        if (response) {
          setData(
            response.reduce((obj, item) => {
              if (!obj[item.date]) {
                obj[item.date] = [];
              }

              let initalData = obj[item.date];

              obj[item.date] = initalData.concat({ ...item });

              return obj;
            }, {})
          );
        }
      });

    return request;
  };

  const toggleAccrodian = (date) => () => {
    if (!!activeCurrentDate && date === activeCurrentDate) {
      setActiveCurrentDate(null);
    } else {
      setActiveCurrentDate(date);
    }
  };

  const Row = (item, index) => {
    return (
      <tr key={item.id || index}>
        <td> {item.formated_time}</td>
        <td>{item.contraction}</td>
        <td> {item.pain_scale}</td>
        <td>{item.formated_interval}</td>
      </tr>
    );
  };

  const Accordian = ([date, item], index) => {
    return (
      <div key={date || index} className="accordion-item ">
        <div
          style={{ marginTop: "5px", border: "1px solid black" }}
          className="accordion-title lightgradient2 font-blue"
          onClick={toggleAccrodian(date)}
        >
          <div>{date}</div> <div>{item.time}</div> <div>painscale</div>
          <div>
            <FontAwesomeIcon
              icon={faBolt}
              style={{ marginRight: "1rem", color: "red" }}
            />
            {item.length}
            <FontAwesomeIcon
              icon={faChevronDown}
              style={{ marginLeft: "5rem", cursor: "pointer" }}
            />
          </div>
        </div>
        {activeCurrentDate === date && (
          <div className="accordion-content ">
            <table>
              <thead>
                <tr>
                  <th>time</th> <th>contraction</th> <th>pain scale</th>{" "}
                  <th>interval</th>
                </tr>
              </thead>
              {item.map(Row)}
            </table>
          </div>
        )}
      </div>
    );
  };

  React.useEffect(() => {
    if (!!customer) {
      const request = fetchApi();

      return () => {
        request.cancel();
      };
    }
  }, [customer]);

  return <div className="accordion">{Object.entries(data).map(Accordian)}</div>;
}

const styles = {
  th: {
    fontSize: "17px",
    whiteSpace: "nowrap",
    padding: "5px 10px",
    margin: "10px 0px",
  },

  td: {
    borderLeft: "1px solid #fff",
    padding: "5px 10px",
    fontSize: "12px",
    textAlign: "center",
    lineHeight: "20px",
  },

  tdFirst: {
    padding: "5px 10px",
    fontSize: "12px",
  },
};
