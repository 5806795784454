import Tab from "./tabs";

export default function Tablelist(props) {
  var id = props.userId;
  console.log(props);
  return <Tab id={id} />;
}

// import Tab from './tabs';

// export default function Tablelist(){
// return (

// <Tab/>

// );

// }
