import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";

export default function SymptomsTracker(props) {
  var userid = props.userid1;
  const [data, setData] = useState([]);
  const [CaloriesData, setCaloriesData] = useState([]);
  const [update, setUpdate] = useState(false);

  function updateOpen() {
    setUpdate(true);
  }

  useEffect(() => {
    // if (localStorage.getItem("token") === null) {
    //   localStorage.clear();
    //   //window.location = "/clogin";
    // }
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("doc_token"),
      },
    };
    //remove this date after setting up the admin pannel

    axios
      .get(
        "customer/get-all-dates-symptoms/?customer=" + userid.id,
        config

        // config
      )
      //
      .then((res) => {
        const apidata = res.data.symptoms.concat(res.data.symptoms_with_text);
        const maindata = apidata.concat(res.data.custom_symptoms);
        const groupedByDate = maindata.reduce((symptoms, symptom) => {
          if (symptom.date in symptoms) {
            symptoms[symptom.date].push(symptom);
          } else {
            symptoms[symptom.date] = [symptom];
          }
          return symptoms;
        }, {});

        console.log(groupedByDate);
        const sortedOnDate = Object.values(groupedByDate).sort((arr1, arr2) => {
          if (arr1[0].date < arr2[0].date) {
            return -1;
          } else if (arr1[0].date > arr2[0].date) {
            return 1;
          }
          return 0;
        });
        console.log(sortedOnDate);
        setData(sortedOnDate);
      });
  }, []);
  return (
    <table
      style={{
        width: "100%",
        borderCollapse: "seprate",
        width: "41.6rem",
        borderSpacing: "0 1em",
      }}
    >
      <thead style={{ borderBottom: "5px solid #5c0048" }}>
        <tr
          style={{
            background:
              "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
          }}
          className="font-blue"
        >
          <th style={styles.th}>Date</th>
          <th style={styles.th}>Symptoms</th>
          <th style={styles.th}>Other Symptoms</th>
          {/* <th style={styles.th}>Weight</th> */}
          <th style={styles.th}>Blood Sugar Level</th>
          <th style={styles.th}>Blood PressureLevel</th>
          <th style={styles.th}>Last Week Report</th>
        </tr>
      </thead>

      <tbody>
        {data.map((ele) => {
          return (
            <tr
              style={{
                background:
                  "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
              }}
              key={ele.date}
            >
              <td style={styles.tdFirst}> {ele[0].date}</td>
              <td style={styles.td}>
                <>
                  {ele.map((e) => {
                    return (
                      <>
                        <div>{e.symptom}</div>
                        <div>{e.others}</div>
                      </>
                    );
                  })}
                </>
              </td>
              <td style={styles.td}>
                {ele.map((e) => {
                  return (
                    <>
                      <div>{e.others}</div>
                    </>
                  );
                })}
              </td>
              <td style={styles.td}>
                {ele.map((ele) => {
                  return <div>{ele.bloodSugar}</div>;
                })}
              </td>
              <td style={styles.td}>
                {ele.map((ele) => {
                  return <div>{ele.bloodPressure}</div>;
                })}
              </td>

              <td style={styles.td}>
                {ele.map((ele) => {
                  return <div>{ele.report}</div>;
                })}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

const styles = {
  th: {
    fontSize: "17px",
    whiteSpace: "nowrap",
    padding: "5px 10px",
    margin: "10px 0px",
  },

  td: {
    borderLeft: "1px solid #fff",
    padding: "5px 10px",
    background:
      "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
    fontSize: "12px",
    textAlign: "left",
    lineHeight: "20px",
  },

  tdFirst: {
    padding: "5px 10px",
    fontSize: "12px",
  },
};
