import Calendar from "react-calendar";
import { useState } from "react";
import { Link } from "react-router-dom";
export default function CalendarSelect({ onChange, value, query }) {
  const [isdate, setDateSelect] = useState(true);
  function closeDailyTracker() {
    setDateSelect(!isdate);
  }
  return (
    <>
      {isdate ? (
        <div style={styles.dateshow} className=" datemobile">
          <i
            onClick={closeDailyTracker}
            className="fa fa-close"
            style={styles.closebtn}
          ></i>
          <span>Select Date</span>
          <hr style={{ color: "#fff" }} />

          <Calendar
            value={value}
            onChange={onChange}
            className="react-calendar c1 c2"
          />
          <Link
            to={{
              pathname: "/update_traker",
              search: query,
            }}
          >
            <button style={styles.ubtn}>Update</button>
          </Link>
        </div>
      ) : null}
    </>
  );
}

const styles = {
  dateshow: {
    position: "fixed",
    width: "300px",
    background: "#975DAB",
    // transform: "translate(25rem, 2rem)",
    padding: "10px 30px",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },

  ubtn: {
    border: "none",
    margin: "5px 0px",
    color: "#fff",
    background: "#E81A7F",
    padding: "5px 20px",
    fontWeight: "bold",
    cursor: "pointer",
  },
  closebtn: {
    position: "absolute",
    right: "0px",
    top: "-5px",
    cursor: "pointer",
  },
};
