import React, { useState } from "react";
import Calendar from "react-calendar";
import "../../client/doctor/components/booking.css";
import sun from "../../client/doctor/img/sun.png";
import sunset from "../../client/doctor/img/sunset.png";
import axios from "axios";
import moon from "../../client/doctor/img/moon.png";
import sunrise from "../../client/doctor/img/sunrise.png";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
export default function Booking(props) {
  var doctorid = props.user;
  console.log(doctorid);
  const [time, settime] = useState();
  const [isClose, setisclose] = useState(true);
  const [dateState, setDateState] = useState(new Date());
  const changeDate = (e) => {
    setDateState(e);
  };

  function closeTabBox() {
    setisclose(false);
  }

  function clickedtime(e) {
    var time = e.target.value;
    settime(time);
    console.log(e.target.value);
  }
  function booking() {
    var datepiker = moment(dateState).format("YYYY-MM-DD");
    console.log(datepiker);
    const data = {
      date: datepiker,
      time: time,
      appointmentID: doctorid,
    };

    const headers = {
      Authorization: `token ` + localStorage.getItem("token"),
    };
    axios
      .patch("appointments/reschedule-appointment/", data, {
        headers: headers,
      })
      .then(() => {
        alert("appintment booked for " + datepiker + " at " + time);
      })
      .catch((error) => {
        alert(JSON.stringify(error.response.data));
      });
  }
  return (
    <>
      {isClose ? (
        <div className="multiple-tab mobilebooking" style={styles.tabview}>
          <i
            onClick={closeTabBox}
            className="fa fa-close"
            style={styles.closebtn}
          ></i>
          <div className="callanderandtime" style={styles.gridstyle}>
            <div
              // style={{}}
              style={styles.calendarstyle}
              className="res-calendar  containcallander"
            >
              <Calendar
                className={["c1", "c2"]}
                value={dateState}
                minDate={new Date()}
                onChange={changeDate}
              />
            </div>
            <div style={styles.calendarstyle} className="">
              <Tabs style={styles.headinglist}>
                <TabList
                  style={{
                    paddingLeft: "0px",
                    listStyle: "none",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  // style={styles.headinglist}
                >
                  <Tab style={styles.headinglist}>
                    <img style={{ height: "" }} src={sunrise} alt="" />
                  </Tab>
                  <Tab style={styles.headinglist}>
                    <img style={{ height: "" }} src={sun} alt="" />
                  </Tab>
                  <Tab style={styles.headinglist}>
                    <img style={{ height: "" }} src={sunset} alt="" />
                  </Tab>
                  <Tab style={styles.headinglist}>
                    <img style={{ height: "" }} src={moon} alt="" />
                  </Tab>
                </TabList>

                <TabPanel className="tabcss" style={styles.tabpannel}>
                  <button
                    className="timeslect"
                    onClick={clickedtime}
                    value="9:00"
                    style={styles.button}
                  >
                    9:00am
                  </button>
                  <button
                    className="timeslect"
                    onClick={clickedtime}
                    value="9:30"
                    style={styles.button}
                  >
                    9:30am
                  </button>
                  <button
                    className="timeslect"
                    onClick={clickedtime}
                    value="10:00"
                    style={styles.button}
                  >
                    10:00am
                  </button>
                  <button
                    className="timeslect"
                    onClick={clickedtime}
                    value="10:30"
                    style={styles.button}
                  >
                    10:30am
                  </button>
                  <button
                    className="timeslect"
                    onClick={clickedtime}
                    value="11:00"
                    style={styles.button}
                  >
                    11:00am
                  </button>
                  <button
                    className="timeslect"
                    onClick={clickedtime}
                    value="11:30"
                    style={styles.button}
                  >
                    11:30am
                  </button>
                  <button
                    className="timeslect"
                    onClick={clickedtime}
                    value="12:00"
                    style={styles.button}
                  >
                    12:00pm
                  </button>
                </TabPanel>

                <TabPanel className="tabcss" style={styles.tabpannel}>
                  <button
                    className="timeslect"
                    onClick={clickedtime}
                    value="12:30"
                    style={styles.button}
                  >
                    12:30pm
                  </button>
                  <button
                    className="timeslect"
                    onClick={clickedtime}
                    value="13:00"
                    style={styles.button}
                  >
                    1:00pm
                  </button>
                  <button
                    className="timeslect"
                    onClick={clickedtime}
                    value="13:30"
                    style={styles.button}
                  >
                    1:30pm
                  </button>
                  <button
                    className="timeslect"
                    onClick={clickedtime}
                    value="14:00"
                    style={styles.button}
                  >
                    2:00pm
                  </button>
                  <button
                    className="timeslect"
                    onClick={clickedtime}
                    value="14:30"
                    style={styles.button}
                  >
                    2:30pm
                  </button>
                  <button
                    className="timeslect"
                    onClick={clickedtime}
                    value="15:00"
                    style={styles.button}
                  >
                    3:00pm
                  </button>
                  <button
                    className="timeslect"
                    onClick={clickedtime}
                    value="15:30"
                    style={styles.button}
                  >
                    3:30pm
                  </button>
                  <button
                    className="timeslect"
                    onClick={clickedtime}
                    value="16:00"
                    style={styles.button}
                  >
                    4:00pm
                  </button>
                  <button
                    className="timeslect"
                    onClick={clickedtime}
                    value="16:30"
                    style={styles.button}
                  >
                    4:30pm
                  </button>
                  <button
                    className="timeslect"
                    onClick={clickedtime}
                    value="17:00"
                    style={styles.button}
                  >
                    5:00pm
                  </button>
                  <button
                    className="timeslect"
                    onClick={clickedtime}
                    value="17:30"
                    style={styles.button}
                  >
                    5:30pm
                  </button>
                  <button
                    className="timeslect"
                    onClick={clickedtime}
                    value="18:00"
                    style={styles.button}
                  >
                    6:00pm
                  </button>
                </TabPanel>

                <TabPanel className="tabcss" style={styles.tabpannel}>
                  <button
                    className="timeslect"
                    onClick={clickedtime}
                    value="18:30"
                    style={styles.button}
                  >
                    6:30pm
                  </button>
                  <button
                    className="timeslect"
                    onClick={clickedtime}
                    value="19:00"
                    style={styles.button}
                  >
                    7:00pm
                  </button>
                  <button
                    className="timeslect"
                    onClick={clickedtime}
                    value="19:30"
                    style={styles.button}
                  >
                    7:30pm
                  </button>
                  <button
                    className="timeslect"
                    onClick={clickedtime}
                    value="20:00"
                    style={styles.button}
                  >
                    8:00pm
                  </button>
                </TabPanel>

                <TabPanel className="tabcss" style={styles.tabpannel}>
                  <button
                    className="timeslect"
                    onClick={clickedtime}
                    value="20:30"
                    style={styles.button}
                  >
                    8:30pm
                  </button>
                  <button
                    className="timeslect"
                    onClick={clickedtime}
                    value="21:00"
                    style={styles.button}
                  >
                    9:00pm
                  </button>
                </TabPanel>
              </Tabs>
            </div>
            {/* //{" "}
            <p>
              // Current selected date is //{" "}
              <b>{moment(dateState).format("MMMM Do YYYY")}</b>
              //{" "}
            </p> */}
          </div>

          <div>
            <p
              style={{
                fontSize: "16px",
                background:
                  "linear-gradient(to right, rgb(133 38 104), rgb(101 28 106)",
                padding: "15px",
              }}
            >
              I have read and understood the Terms & Conditions of usage of
              Shebirth and consent to the same. I am voluntarily availing of the
              services provided on this platform. I am fully aware that I will
              not be undergoing a physical examination by a physician prior to a
              physician recommending medical tests and/or treatment and/or the
              prescribing of OTC drugs. I am also aware that the consultation on
              Shebirth Application does not preclude my rights to visit a
              physician and opt for a physical examination at any point in time
              and I am free at any time during the consultation to request for
              the same.
            </p>
          </div>

          {time ? (
            <button onClick={booking} className="bookbutton">
              Book Appointment
            </button>
          ) : null}
        </div>
      ) : null}
    </>
  );
}
const styles = {
  button: {
    background: " #6b1572",
    border: " 1px solid #6b1572",
    padding: "1%",
    margin: "2px",
  },
  gridstyle: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    width: "100%",
  },
  closebtn: { float: "right" },
  headinglist: {
    // bordeRadius: "18%",

    borderRadius: "18%",
    // width: "337px",

    fontSize: "16px",
    // background: " rgb(133 38 104)",
  },
  calendarstyle: {
    width: "260px",
    marginLeft: "5%",
    borderRadius: "5%",
    background: "linear-gradient(to right, rgb(133 38 104), rgb(101 28 106)",
  },
  tabview: {
    position: "absolute",
    padding: "1%",
    zIndex: "5",
    width: "47%",
    backdropFilter: "blur(23px) saturate(127%)",
    background: "#ffffff1c 0% 0% no-repeat padding-box",

    //background: "#5b397f",
  },
  tabpannel: {
    // height: "230px",
    //overflowX: "hidden",
    border: "linear-gradient(to right, rgb(133 38 104), rgb(101 28 106)",
    // background: "linear-gradient(to right, rgb(133 38 104), rgb(101 28 106)",
  },
};
