export default function Dcstatus({ activeClients, disabledDoctors }) {
  return (
    <div style={styles.card} className="lightgradient2">
      <div style={styles.left}>
        <div style={{ display: "flex" }}>
          <div style={{ padding: "10px" }}>
            <h2 style={styles.counter}>{activeClients}</h2>
          </div>
          <div style={{ padding: "10px" }}>
            <p>
              <strong style={{ whiteSpace: "nowrap", fontSize: "16px" }}>
                Active Clients
              </strong>
            </p>
          </div>
        </div>
      </div>
      <div style={styles.right}>
        <div style={{ display: "flex" }}>
          <div style={{ padding: "10px" }}>
            <h2 style={styles.counter}>{disabledDoctors}</h2>
          </div>
          <div style={{ padding: "10px" }}>
            <p>
              <strong style={{ whiteSpace: "nowrap", fontSize: "16px" }}>
                Disabled doctors
              </strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

const styles = {
  card: {
    display: "flex",
    flexDirection: "column",

    // backdropFilter: "blur(31px) brightness(-15%) opacity(.3)",

    width: "14.8rem",
    height: "8rem",
    padding: "10px",
    borderRadius: "10px",
    margin: "10px",
    lineHeight: "1px",
  },

  left: {
    padding: "0px",
    height: "4rem",
    lineHeight: "1px",
    borderBottom: "1px solid #fff",
  },
  right: {
    padding: "0px",
    height: "4rem",
    lineHeight: "1px",
  },
  counter: {
    color: "#fff",
  },
};
