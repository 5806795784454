import React from "react";
import moment from "moment";
import axios from "axios";
export default class Addmedicine extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isDone: true, allValues: "" };
    this.medicineClose = this.medicineClose.bind(this);
  }

  medicineClose() {
    this.setState({ isDone: false });
  }
  changeHandler = (e) => {
    this.setState({ ...this.allValues, [e.target.name]: e.target.value });
  };

  submitValue = (e) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);

    var object = {};
    formData.forEach(function (value, key) {
      object[key] = value;
    });
    var json = JSON.stringify(object);

    formData.append("data", json);

    const headers = {
      Authorization: `token ` + window.localStorage.getItem("postclient"),
    };
    axios
      .post("http://127.0.0.1:8000/customer/medicine-POST/", formData, {
        headers: headers,
      })
      .then((res) => {
        alert("Medicine was submitted");
  
      })
      .catch((error) => {
        alert("Cannot add Medicine again");
        console.log('error',error)
      
      
      });
  };

  render() {
    return (
      <>
        {this.state.isDone ? (
          <div
            className="medicine-module 
lightgradient
"
            style={styles.module}
          >
            <h2 style={{ margin: "0px", fontSize: "16px" }}>
              Add New Medicine
            </h2>
            <hr style={{ color: "#fff" }} />
            <form onSubmit={this.submitValue}>
              <table
                style={{ borderCollapse: "seperate", borderSpacing: "0em 1em" }}
              >
                <tr style={styles.tr} className="gradiantblur">
                  <td style={styles.tdstyle}>Morning Before Food</td>
                  <td style={styles.tdstyle}>
                    <input
                      type="text"
                      name="medicationTime"
                      style={styles.label}
                      onChange={this.changeHandler}
                      defaultValue="morning before food"
                      hidden
                    />
                    <input
                      type="text"
                      name="morning before food"
                      style={styles.label}
                      className="calanderbutton"
                      onChange={this.changeHandler}
                      value={this.state.morningbefore}
                    />
                  </td>
                  <td style={styles.tdstyle}>
                    <button type="submit">Add</button>
                  </td>
                </tr>
              </table>
            </form>

            <form onSubmit={this.submitValue}>
              <table
                style={{ borderCollapse: "seperate", borderSpacing: "0em 1em" }}
              >
                <tr style={styles.tr} className="gradiantblur">
                  <td style={styles.tdstyle}>Morning After Food</td>
                  <td style={styles.tdstyle}>
                    <input
                      type="text"
                      name="medicationTime"
                      style={styles.label}
                      onChange={this.changeHandler}
                      defaultValue="morning after food"
                      hidden
                    />
                    <input
                      type="text"
                      name="morning after food"
                      className="calanderbutton"
                      style={styles.label}
                      onChange={this.changeHandler}
                      value={this.state.morningafter}
                    />
                  </td>
                  <td style={styles.tdstyle}>
                    <button type="submit">Add</button>
                  </td>
                </tr>
              </table>
            </form>

            <form onSubmit={this.submitValue}>
              <table
                style={{ borderCollapse: "seperate", borderSpacing: "0em 1em" }}
              >
                <tr style={styles.tr} className="gradiantblur">
                  <td style={styles.tdstyle}>Afternoon Before Food</td>
                  <td style={styles.tdstyle}>
                    <input
                      type="text"
                      name="medicationTime"
                      style={styles.label}
                      onChange={this.changeHandler}
                      defaultValue="afternoon before food"
                      hidden
                    />
                    <input
                      type="text"
                      name="afternoon before food"
                      className="calanderbutton"
                      style={styles.label}
                      onChange={this.changeHandler}
                      value={this.state.afternoonbefore}
                    />
                  </td>
                  <td style={styles.tdstyle}>
                    <button type="submit">Add</button>
                  </td>
                </tr>
              </table>
            </form>

            <form onSubmit={this.submitValue}>
              <table
                style={{ borderCollapse: "seperate", borderSpacing: "0em 1em" }}
              >
                <tr style={styles.tr} className="gradiantblur">
                  <td style={styles.tdstyle}>Afternoon After Food</td>
                  <td style={styles.tdstyle}>
                    <input
                      type="text"
                      name="medicationTime"
                      style={styles.label}
                      onChange={this.changeHandler}
                      defaultValue="afternoon after food"
                      hidden
                    />
                    <input
                      type="text"
                      name="afternood after food"
                      className="calanderbutton"
                      style={styles.label}
                      onChange={this.changeHandler}
                      value={this.state.afternoonafter}
                    />
                  </td>
                  <td style={styles.tdstyle}>
                    <button type="submit">Add</button>
                  </td>
                </tr>
              </table>
            </form>

            <form onSubmit={this.submitValue}>
              <table
                style={{ borderCollapse: "seperate", borderSpacing: "0em 1em" }}
              >
                <tr style={styles.tr} className="gradiantblur">
                  <td style={styles.tdstyle}>Night Before Food</td>
                  <td style={styles.tdstyle}>
                    <input
                      type="text"
                      name="medicationTime"
                      style={styles.label}
                      onChange={this.changeHandler}
                      defaultValue="night before food"
                      hidden
                    />
                    <input
                      type="text"
                      name="night befor food"
                      className="calanderbutton"
                      style={styles.label}
                      onChange={this.changeHandler}
                      value={this.state.NightBefore}
                    />
                  </td>
                  <td style={styles.tdstyle}>
                    <button type="submit">Add</button>
                  </td>
                </tr>
              </table>
            </form>

            <form onSubmit={this.submitValue}>
              <table
                style={{ borderCollapse: "seperate", borderSpacing: "0em 1em" }}
              >
                <tr style={styles.tr} className="gradiantblur">
                  <td style={styles.tdstyle}>Night After Food</td>
                  <td style={styles.tdstyle}>
                    <input
                      type="text"
                      name="medicationTime"
                      style={styles.label}
                      onChange={this.changeHandler}
                      defaultValue="night after food"
                      hidden
                    />
                    <input
                      type="text"
                      name="night after food"
                      className="calanderbutton"
                      style={styles.label}
                      onChange={this.changeHandler}
                      value={this.state.nightafter}
                    />
                  </td>
                  <td style={styles.tdstyle}>
                    <button type="submit">Add</button>
                  </td>
                </tr>
              </table>
            </form>

            <button
              style={styles.button}
              className="pinkbutton"
              onClick={this.medicineClose}
            >
              Done
            </button>
          </div>
        ) : null}
      </>
    );
  }
}

const styles = {
  tdstyle: { width: "50%" },
  module: {
    padding: "10px 0px",
    // position: "absolute",
    // background: "#ad6db0",
    borderRadius: "10px",
    width: "52rem",
  },
  label: { textAlign: "center" },
  tr: {
    background: "#86337d",
    // textAlign: "left",
  },
  button: {
    // background: "linear-gradient(90deg, #3a51a7 30%, #b53899)",
    border: "none",
    color: "#fff",
    padding: "10px 30px",
    cursor: "pointer",
    borderRadius: "10px",
  },
};
