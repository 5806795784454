import React from "react";
import axios from "axios";
import Calendar from "react-calendar";
// import ACalendar from "./calendar";
import moment from "moment";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "./slot-tabs.css";

export default class Reshedule extends React.Component {
  constructor(props) {
    super(props);
    var userid = props.userid;
    this.state = {
      isClose: false,
      userid1: { id: props.userid },
      time: null,
      dateState: new Date(),
    };
    this.closeReshedule = this.closeReshedule.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  changeDate = (e) => {
    //this.setState(e);
    this.setState({ dateState: e });
  };

  clickedtime = (e, someParameter) => {
    // var time = e.target.value;
    this.setState({ time: e.target.value });
    console.log(e.target.value);
  };
  handleSubmit(event) {
    //console.log(this.state.dateState);
    var datepiker = moment(this.state.dateState).format("YYYY-MM-DD");
    var timepiker = this.state.time;
    console.log(datepiker);
    const Data = {
      appointmentID: this.state.userid1.id,
      date: datepiker,
      time: this.state.time,
      // notes: notes,
    };
    const headers = {
      //"Content-Type": "application/x-www-form-urlencoded",

      Authorization: `token ` + localStorage.getItem("doc_token"),

      //Authorization: "JWT fefege...",
    };

    axios
      .patch("appointments/reschedule-appointment/", Data, {
        headers: headers,
      })
      .then(() => {
        // setincrement([...increment,res.data])
        alert("Resheduled appointment to  " + datepiker + " at " + timepiker);
        window.location.reload();
      })
      .catch((error) => {
        console.log("error in resheduling ", error);
      });

    // event.preventDefault();
    // alert("notes was submitted");
    // event.preventDefault();
  }

  componentDidMount() {
    var data = {
      Authorization: localStorage.getItem("doc_token"),
    };
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("doc_token"),
      },
      params: {
        doctor: localStorage.getItem("doc_id"),
      },
      data: data,
    };
    // console.log(config);

    axios
      .get("appointments/full-appointment/", config)
      .then((res) => {
        console.log(res.data);
        this.setState({ approved: res.data.Approved });
        this.setState({ completed: res.data.Completed });
        this.setState({ rejected: res.data.Rejected });
      })
      .catch((err) => {
        // err.response.status;
        // if (err.response.status == 404) {
        //   console.log("data not found.");
        // }
        console.log(err.message);
      });
  }
  closeReshedule() {
    this.setState({ isClose: true });
  }

  render() {
    return (
      <>
        {this.state.isClose ? null : (
          <div className="shedule-card" style={styles.cover}>
            <div style={styles.close} onClick={this.closeReshedule}>
              <i className="fa fa-close"></i>
            </div>
            <div style={styles.header}>
              <strong>Reschedule Appointment</strong>
            </div>
            <div className="reshedule-sepr" style={styles.sepr}>
              {/* <ACalendar /> */}
              {/* <Calendar
                className={["c1", "c2"]}
                value={this.state.dateState}
                minDate={new Date()}
                onChange={this.changeDate}
              /> */}
              {/* {console.log(this.state.dateState)} */}
              <div style={{ padding: "10px" }}>
                <div className="slott" style={styles.slot}>
                  <small>slots</small>
                  {/*===============  Slots  =================== */}
                  <div className="slot-tabs">
                    <Tabs>
                      <TabList>
                        <Tab>
                          {" "}
                          <i className="fa fa-sun-o"></i>
                        </Tab>
                        <Tab>
                          {" "}
                          <i className="fa fa-star-o"></i>
                        </Tab>
                        <Tab>
                          {" "}
                          <i className="fa fa-star"></i>
                        </Tab>
                        <Tab>
                          <i className="fa fa-moon-o"></i>
                        </Tab>
                      </TabList>

                      <TabPanel>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "8%",
                          }}
                        >
                          <button
                            onClick={(e) => {
                              this.clickedtime(e);
                            }}
                            // onClick={this.clickedtime}
                            value="9:00"
                            style={styles.sm}
                          >
                            9:00 Am
                          </button>
                          <button
                            onClick={(e) => {
                              this.clickedtime(e);
                            }}
                            // onClick={this.clickedtime}
                            value="9:30"
                            style={styles.sm}
                          >
                            9:30 Am
                          </button>
                          <button
                            onClick={(e) => {
                              this.clickedtime(e);
                            }}
                            // onClick={this.clickedtime}
                            value="10:00"
                            style={styles.sm}
                          >
                            10:00 Am
                          </button>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "8%",
                          }}
                        >
                          <button
                            onClick={(e) => {
                              this.clickedtime(e);
                            }}
                            // onClick={this.clickedtime}
                            value="10:30"
                            style={styles.sm}
                          >
                            10:30 Am
                          </button>
                          <button
                            onClick={(e) => {
                              this.clickedtime(e);
                            }}
                            // onClick={this.clickedtime}
                            value="11:00"
                            style={styles.sm}
                          >
                            11:00 Am
                          </button>
                          <button
                            onClick={(e) => {
                              this.clickedtime(e);
                            }}
                            // onClick={this.clickedtime}
                            value="11:30"
                            style={styles.sm}
                          >
                            11:30 Am
                          </button>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            marginBottom: "8%",
                          }}
                        >
                          <button
                            onClick={(e) => {
                              this.clickedtime(e);
                            }}
                            // onClick={this.clickedtime}
                            value="12:00"
                            style={styles.sm}
                          >
                            12:00 pm
                          </button>
                          <button
                            onClick={(e) => {
                              this.clickedtime(e);
                            }}
                            // onClick={this.clickedtime}
                            value="12:30"
                            style={styles.sm}
                          >
                            12:30 pm
                          </button>
                          {/* <button
                            onClick={(e) => {
                              this.clickedtime(e);
                            }}
                            // onClick={this.clickedtime}
                            value="9:30am"
                            style={styles.sm}>1:00 pm</button> */}
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "8%",
                          }}
                        >
                          <button
                            onClick={(e) => {
                              this.clickedtime(e);
                            }}
                            // onClick={this.clickedtime}
                            value="13:00"
                            style={styles.sm}
                          >
                            1:00pm
                          </button>
                          <button
                            onClick={(e) => {
                              this.clickedtime(e);
                            }}
                            // onClick={this.clickedtime}
                            value="13:30"
                            style={styles.sm}
                          >
                            1:30 pm
                          </button>
                          <button
                            onClick={(e) => {
                              this.clickedtime(e);
                            }}
                            // onClick={this.clickedtime}
                            value="14:00"
                            style={styles.sm}
                          >
                            2:00 pm
                          </button>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "8%",
                          }}
                        >
                          <button
                            onClick={(e) => {
                              this.clickedtime(e);
                            }}
                            // onClick={this.clickedtime}
                            value="14:30"
                            style={styles.sm}
                          >
                            2:30 pm
                          </button>
                          <button
                            onClick={(e) => {
                              this.clickedtime(e);
                            }}
                            // onClick={this.clickedtime}
                            value="15:00"
                            style={styles.sm}
                          >
                            3:00 pm
                          </button>
                          <button
                            onClick={(e) => {
                              this.clickedtime(e);
                            }}
                            // onClick={this.clickedtime}
                            value="15:30"
                            style={styles.sm}
                          >
                            3:30 pm
                          </button>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            marginBottom: "8%",
                          }}
                        >
                          <button
                            onClick={(e) => {
                              this.clickedtime(e);
                            }}
                            // onClick={this.clickedtime}
                            value="16:00"
                            style={styles.sm}
                          >
                            4:00 pm
                          </button>
                          <button
                            onClick={(e) => {
                              this.clickedtime(e);
                            }}
                            // onClick={this.clickedtime}
                            value="16:30"
                            style={styles.sm}
                          >
                            4:30 pm
                          </button>
                          {/* <button
                            onClick={(e) => {
                              this.clickedtime(e);
                            }}
                            // onClick={this.clickedtime}
                            value="9:30am"
                            style={styles.sm}>5:00 pm</button> */}
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "8%",
                          }}
                        >
                          <button
                            onClick={(e) => {
                              this.clickedtime(e);
                            }}
                            // onClick={this.clickedtime}
                            value="17:00"
                            style={styles.sm}
                          >
                            5:00 pm
                          </button>
                          <button
                            onClick={(e) => {
                              this.clickedtime(e);
                            }}
                            // onClick={this.clickedtime}
                            value="17:30"
                            style={styles.sm}
                          >
                            5:30 pm
                          </button>
                          <button
                            onClick={(e) => {
                              this.clickedtime(e);
                            }}
                            // onClick={this.clickedtime}
                            value="18:00"
                            style={styles.sm}
                          >
                            6:00 pm
                          </button>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "8%",
                          }}
                        >
                          <button
                            onClick={(e) => {
                              this.clickedtime(e);
                            }}
                            // onClick={this.clickedtime}
                            value="18:30"
                            style={styles.sm}
                          >
                            6:30 pm
                          </button>
                          <button
                            onClick={(e) => {
                              this.clickedtime(e);
                            }}
                            // onClick={this.clickedtime}
                            value="19:00 pm"
                            style={styles.sm}
                          >
                            7:00 pm
                          </button>
                          <button
                            onClick={(e) => {
                              this.clickedtime(e);
                            }}
                            // onClick={this.clickedtime}
                            value="19:30"
                            style={styles.sm}
                          >
                            7:30 pm
                          </button>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "8%",
                          }}
                        >
                          <button
                            onClick={(e) => {
                              this.clickedtime(e);
                            }}
                            // onClick={this.clickedtime}
                            value="20:00"
                            style={styles.sm}
                          >
                            8:00 pm
                          </button>
                          <button
                            onClick={(e) => {
                              this.clickedtime(e);
                            }}
                            // onClick={this.clickedtime}
                            value="20:30"
                            style={styles.sm}
                          >
                            8:30 pm
                          </button>
                          <button
                            onClick={(e) => {
                              this.clickedtime(e);
                            }}
                            // onClick={this.clickedtime}
                            value="21:00"
                            style={styles.sm}
                          >
                            9:00 pm
                          </button>
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "8%",
                          }}
                        >
                          <button
                            onClick={(e) => {
                              this.clickedtime(e);
                            }}
                            // onClick={this.clickedtime}
                            value="21:30"
                            style={styles.sm}
                          >
                            9:30 pm
                          </button>
                          {/* <button
                            onClick={(e) => {
                              this.clickedtime(e);
                            }}
                            // onClick={this.clickedtime}
                            value="9:30am"
                            style={styles.sm}>9:30 Am</button>
                          <button
                            onClick={(e) => {
                              this.clickedtime(e);
                            }}
                            // onClick={this.clickedtime}
                            value="9:30am"
                            style={styles.sm}>10:00 Am</button> */}
                        </div>
                        {/* <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "8%",
                          }}
                        >
                          <button
                            onClick={(e) => {
                              this.clickedtime(e);
                            }}
                            // onClick={this.clickedtime}
                            value="9:30am"
                            style={styles.sm}>10:30 Am</button>
                          <button
                            onClick={(e) => {
                              this.clickedtime(e);
                            }}
                            // onClick={this.clickedtime}
                            value="9:30am"
                            style={styles.sm}>11:00 Am</button>
                          <button
                            onClick={(e) => {
                              this.clickedtime(e);
                            }}
                            // onClick={this.clickedtime}
                            value="9:30am"
                            style={styles.sm}>11:30 Am</button>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "8%",
                          }}
                        >
                          <button
                            onClick={(e) => {
                              this.clickedtime(e);
                            }}
                            // onClick={this.clickedtime}
                            value="9:30am"
                            style={styles.sm}>12:00 pm</button>
                          <button
                            onClick={(e) => {
                              this.clickedtime(e);
                            }}
                            // onClick={this.clickedtime}
                            value="9:30am"
                            style={styles.sm}>12:30 pm</button>
                          <button
                            onClick={(e) => {
                              this.clickedtime(e);
                            }}
                            // onClick={this.clickedtime}
                            value="9:30am"
                            style={styles.sm}>1:00 pm</button>
                        </div> */}
                      </TabPanel>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
            {/* p cover */}
            {this.state.time ? (
              <button onClick={this.handleSubmit} style={styles.sbtn}>
                Shedule
              </button>
            ) : null}
          </div>
        )}
      </>
    );
  }
}

const styles = {
  header: {
    padding: "1rem",
    borderBottom: "1px solid #fff",
  },
  cover: {
    width: "40.5rem",
    position: "absolute",
    background: "#AB519A",
    zIndex: "6",
    borderRadius: "10px",
    left: "23%",
  },

  close: {
    position: "absolute",
    top: "-25px",
    right: "-10px",
    color: "#333",
    borderRadius: "50%",
    background: "#fff",
    width: "30px",
    height: "30px",
    cursor: "pointer",
  },

  sepr: {
    display: "flex",
    justifyContent: "space-between",
  },

  slot: {
    padding: "10px",
    width: "260px",
    background: "#4C003D",
    borderRadius: "10px",
    overflowX: "hidden",
    height: "260px",
  },

  sm: {
    fontSize: "12px",
    padding: "5px",
    color: "white",
    marginBottom: "5px",
    background: "#13002F",
    borderRadius: "5px",
  },

  p: {
    textAlign: "left",
    padding: "10px",
    background: "#661851",
    fontSize: "14px",
    borderRadius: "10px",
  },

  sbtn: {
    background: "#E81A7F",
    color: "#fff",
    padding: "7px 40px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    marginBottom: "1rem",
  },
};
