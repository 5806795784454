

export default function MedicalDetail(){

return (

<table style={{ width:'100%', borderCollapse:'collapse' }}>
<thead>
<tr style={{ background:'#a23698' }}>
<th style={ styles.th} >Date</th>
<th style={ styles.th} >Hb</th>
<th style={ styles.th} >Urine R/E</th>
<th style={ styles.th} >Urine C/S</th>
<th style={ styles.th} >vdrl</th>
<th style={ styles.th} >HIV</th>
<th style={ styles.th} >RBS first trimester</th>
<th style={ styles.th} >OGCT 2nd trimester - if raised</th>
<th style={ styles.th} >OGTT+</th>
</tr>
</thead>

<tbody>
<tr>
<td style={styles.tdFirst}> </td>
<td style={styles.td}>  </td>
<td style={styles.td}>  </td>
<td style={styles.td}>  </td>
<td style={styles.td}>  </td>
<td style={styles.td}>  </td>
<td style={styles.td}>  </td>
<td style={styles.td}>  </td>
<td style={styles.td}>  </td>
</tr>


</tbody>


</table>


);

}


const styles = {

th:{
fontSize:'17px',
whiteSpace:'nowrap',
padding:'5px 10px',
margin:'10px 0px',
},

td:{
borderLeft:'1px solid #fff',
padding:'5px 10px',
fontSize:'12px',
textAlign:'left',
lineHeight:'20px',
height:'100rem'
},

tdFirst:{
padding:'5px 10px',
fontSize:'12px'
},



}






