import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBolt, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router";
export default function ContractionCounter({ id }) {
  const [contractiondata, setContractiondata] = useState([]);
  const [activeCurrentDate, setActiveCurrentDate] = React.useState(null);
  const setActiveCurrentIndex = (date) => () => {
    if (!!activeCurrentDate && date === activeCurrentDate) {
      setActiveCurrentDate(null);
    } else {
      setActiveCurrentDate(date);
    }
  };
  useEffect(() => {
    axios
      .get(`customer/all-dates-contractions/`, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
        params: {
          customer: id,
        },
      })
      .then((res) => {
        console.log(res.data);
        setContractiondata(res.data);
      });
  }, []);
  let mapped = contractiondata.reduce((r, a) => {
    r[a.date] = [...(r[a.date] || []), a];
    return r;
  }, {});
  return (
    <>
      <div className="scrollerbar">
        <div className="accordion croll">
          {Object.values(mapped).map((item, index) => (
            //{predefined.map(({ date }) => (
            <div className="accordion-item">
              <div
                style={{ border: "1px solid black" }}
                className="accordion-title lightgradient2  font-blue"
                onClick={setActiveCurrentIndex(item[0].date)}
                //   onClick={() => setActiveCurrentIndex(item[0].date)}
              >
                <div>{item[0].date}</div> <div>{item[0].time}</div>{" "}
                <div>painscale</div>
                <div>
                  <FontAwesomeIcon
                    icon={faBolt}
                    style={{ marginRight: "1rem", color: "red" }}
                  />
                  {item.length}
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    style={{ marginLeft: "5rem", cursor: "pointer" }}
                  />
                  {/* {isActive ? (
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    style={{ marginLeft: "5rem", cursor: "pointer" }}
                  />
                ) : (
                  "-"
                )} */}
                </div>
              </div>
              {activeCurrentDate === item[0].date && (
                <div className="accordion-content ">
                  <table>
                    <thead>
                      <tr>
                        <th>time</th> <th>contraction</th> <th>pain scale</th>{" "}
                        <th>interval</th>
                      </tr>
                    </thead>
                    {item.map((e) => {
                      return (
                        <tr>
                          <td> {e.formated_time}</td>{" "}
                          <td>{e.contraction} sec</td> <td> {e.pain_scale}</td>{" "}
                          <td>{e.formated_interval}</td>
                        </tr>
                      );
                    })}
                  </table>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

const styles = {
  th: {
    fontSize: "17px",
    whiteSpace: "nowrap",
    padding: "5px 10px",
    margin: "10px 0px",
  },

  td: {
    borderLeft: "1px solid #fff",
    padding: "5px 10px",
    fontSize: "12px",
    textAlign: "center",
    lineHeight: "20px",
  },

  tdFirst: {
    padding: "5px 10px",
    fontSize: "12px",
  },
};
