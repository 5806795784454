import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import ContractionCounter from "./innercom/contraction_counter";
import ContractionRecord from "./innercom/contraction_records";

export default class ContractionTabs extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isDisplay: true };
    this.clickHandler = this.clickHandler.bind(this);
  }

  clickHandler() {
    this.setState({ isDisplay: !this.state.isDisplay });
  }

  render() {
    return (
      <>
        {this.state.isDisplay ? (
          <div className="homeheight500 lightgradient" style={styles.card}>
            <div style={styles.inner}>
              <Tabs>
                <TabList
                  style={{
                    width: "100%",
                    listStyle: "none",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Tab>
                    <strong style={{ padding: "10px", cursor: "pointer" }}>
                      Contraction Counter
                    </strong>
                  </Tab>
                  <Tab>
                    <strong style={{ padding: "10px", cursor: "pointer" }}>
                      Records
                    </strong>
                  </Tab>
                </TabList>

                <TabPanel>
                  <ContractionCounter />
                </TabPanel>

                <TabPanel style={styles.cdiet}>
                  <ContractionRecord />
                </TabPanel>
              </Tabs>
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

const styles = {
  card: {
    display: "flex",
    justifyContent: "space-between",
    //background:'#3E0055',
    // backdropFilter: "blur(36px) saturate(58%)",
    borderRadius: "10px",
    margin: "0px 30px",
  },

  inner: {
    textDecoration: "none",
    color: "#fff",
    width: "100%",
  },

  cdiet: {
    height: "28rem",
    overflowY: "scroll",
    scrollbarWidth: "none",
    boxSizing: "content-box",
  },
  subbtn: {
    background: "linear-gradient(90deg, #3a51a7 30%, #b53899)",
    padding: "10px 30px",
    border: "none",
    borderRadius: "10px",
    color: "#fff",
    cursor: "pointer",
  },
};
