import axios from "axios";
import qs from "qs";
import { useState, useEffect } from "react";

export default function Trackerform() {
  const [toggle, settoggle] = useState(false);
  const [updatetoggle, setupdatetoggle] = useState(false);
  const [stage, setstage] = useState();
  const [Updatemodule, setUpdatemodule] = useState([]);
  const [selectedValue, setSelectedValue] = useState("Exercise");
  const [Exerurl, setExerurl] = useState("");
  const [ExName, setExName] = useState("");
  const [subactname, setsubactname] = useState("");
  const [actname, setactname] = useState("");
  const [subacturl, setsubacturl] = useState("");
  const [subactdis, setsubactdis] = useState("");
  const [updatervalue, setupdatervalue] = useState("new");
  const [existingmodule, setexistingmodule] = useState("");

  const handleexisting = (e) => {
    console.log(e.target.value);
    setexistingmodule(e.target.value);
  };
  const handleUpdate = (e) => {
    setupdatervalue(e.target.value);
    if (updatetoggle === true) {
      setupdatetoggle(false);
    } else {
      setupdatetoggle(true);
    }
  };
  const handlestage = (e) => {
    setstage(e.target.value);
  };
  const handleChange = (e) => {
    // console.log(e.target.value);
    setSelectedValue(e.target.value);
    if (toggle === true) {
      settoggle(false);
    } else {
      settoggle(true);
    }

    /// console.log(1);
  };
  useEffect(() => {
    if (stage) {
      //console.log(stage);
      var stagepiker = stage;
    } else {
      var stagepiker = "stage1";
    }
    const config = {
      headers: {
        Authorization: `Token ${sessionStorage.getItem("token")}`,
      },
    };

    axios
      .get("customer/get-activity-main-modules/?stage=" + stagepiker, config)
      .then((res) => {
        // var somevariable = res;
        // setdata((data) => {
        //   return [...data, somevariable];
        // });
        //console.log(res.data);
        setUpdatemodule(res.data);
        // setWeight(res.data.babyDetails.weigth);
        // setlength(res.data.babyDetails.length);
        // setbabyDetails(res.data.babyDetails);
        // setDescribtion(res.data.babyDetails.description);
      });
  }, [stage]);

  const submitValue = (e) => {
    console.log(actname);

    if (existingmodule != "") {
      var update = "True";
      var mainModule = existingmodule;
    } else {
      var update = "False";
      var mainModule = actname;
    }
    if (subacturl) {
      var url = subacturl;
    } else {
      var url = Exerurl;
    }
    //   mainModule: existingmodule,
    //   mainModule: actname,

    e.preventDefault();
    const Data = {
      tracker: selectedValue,
      stage: stage,
      mainModule: mainModule,
      subModuleName: subactname,
      update: update,
      url: url,
      description: subactdis,

      exercise: ExName,
      //   faq: freqentquestions,
    };

    const headers = {
      Authorization: `Token ${sessionStorage.getItem("token")}`,
    };
    axios
      .post("customer/add-acivity-excersice-modules/", Data, {
        headers: headers,
      })
      .then(() => {
        alert("activity was submitted");
        // window.location.reload();
      })
      .catch((error) => {
        alert(JSON.stringify(error.response.data));
      });
  };

  return (
    <>
      <div style={{ margin: "0px" }}>
        <form>
          <table className="learning-table-form">
            <tr>
              <td style={styles.td}>Select Tracker</td>
              <td>
                <select
                  onChange={handleChange}
                  value={selectedValue}
                  required
                  style={styles.select}
                >
                  <option style={styles.option} value="Exercise">
                    Exercise
                  </option>
                  <option style={styles.option} value="Activity">
                    Activity
                  </option>
                </select>
              </td>
            </tr>
            <tr>
              <td style={styles.td}>Select Period</td>
              <td>
                <select
                  style={styles.select}
                  onChange={handlestage}
                  value={stage}
                  required
                >
                  <option style={styles.option} value="stage1">
                    0-4 weeks
                  </option>
                  <option style={styles.option} value="stage2">
                    4-8 weeks
                  </option>
                  <option style={styles.option} value="stage3">
                    8-12 weeks
                  </option>
                  <option style={styles.option} value="stage4">
                    12-16 weeks
                  </option>
                  <option style={styles.option} value="stage5">
                    16-20 weeks
                  </option>
                  <option style={styles.option} value="stage6">
                    20-24 weeks
                  </option>
                  <option style={styles.option} value="stage7">
                    24-28 weeks
                  </option>
                  <option style={styles.option} value="stage8">
                    28-32 weeks
                  </option>
                  <option style={styles.option} value="stage9">
                    32-36 weeks
                  </option>
                  <option style={styles.option} value="stage10">
                    36-40 weeks
                  </option>
                </select>
              </td>
            </tr>

            {toggle === false ? (
              <>
                <tr>
                  <td style={styles.td}>Enter Exercise Name</td>
                  <td>
                    <input
                      onChange={(e) => setExName(e.target.value)}
                      style={styles.input}
                      type="text"
                      style={styles.input}
                      type=""
                    />
                  </td>
                </tr>
                <tr>
                  <td style={styles.td}>Enter Exercise Url</td>
                  <td>
                    <input
                      onChange={(e) => setExerurl(e.target.value)}
                      style={styles.input}
                      type="text"
                    />
                  </td>
                </tr>
              </>
            ) : (
              <>
                <tr>
                  <td style={styles.td}>Add New</td>
                  <td>
                    <select
                      onChange={handleUpdate}
                      value={updatervalue}
                      style={styles.select}
                    >
                      <option style={styles.option} value="new">
                        Add New
                      </option>
                      <option style={styles.option} value="update">
                        Update Existing
                      </option>
                    </select>
                  </td>
                </tr>
                {updatetoggle === false ? null : (
                  <tr>
                    <td style={styles.td}>Update Existing</td>
                    <td>
                      <select
                        style={styles.select}
                        onChange={handleexisting}
                        value={existingmodule}
                      >
                        {Updatemodule.map((personData) => {
                          return (
                            <option
                              style={styles.option}
                              value={personData.name}
                            >
                              {personData.name}
                            </option>
                          );
                        })}
                      </select>
                    </td>
                  </tr>
                )}
                {updatetoggle ? null : (
                  <tr>
                    <td style={styles.td}>Enter Activity Name</td>
                    <td>
                      <input
                        onChange={(e) => setactname(e.target.value)}
                        style={styles.input}
                        type=""
                      />
                    </td>
                  </tr>
                )}
                <tr>
                  <td style={styles.td}>Enter Sub Activity Name</td>
                  <td>
                    <input
                      onChange={(e) => setsubactname(e.target.value)}
                      style={styles.input}
                      type=""
                    />
                  </td>
                </tr>
                <tr>
                  <td style={styles.td}>Enter Sub Activity Url</td>
                  <td>
                    <input
                      onChange={(e) => setsubacturl(e.target.value)}
                      style={styles.input}
                      type=""
                    />
                  </td>
                </tr>
                <tr>
                  <td style={styles.td}>Enter Sub Activity Description</td>
                  <td>
                    <input
                      onChange={(e) => setsubactdis(e.target.value)}
                      style={styles.input}
                      type=""
                    />
                  </td>
                </tr>{" "}
              </>
            )}

            <tr>
              <td></td>
              <td>
                <button
                  onClick={submitValue}
                  type="submit"
                  style={{ padding: "5px 20px" }}
                >
                  Submit
                </button>
              </td>
            </tr>
          </table>
        </form>
      </div>
    </>
  );
}

const styles = {
  option: { background: "#67136aa6" },
  td: {
    textAlign: "left",
    borderBottom: "4px",
  },
  select: {
    width: "13.5rem",
    textAlign: "center",
    padding: "6px",
    border: "1px solid #fff",
    background: "transparent",
    color: "#fff",
  },
  input: {
    border: "1px solid #fff",
    padding: "6px",
    borderRadius: "3px",
    background: "transparent",
    color: "#fff",
  },
};
