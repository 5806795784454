import axios from "axios";
import React, { useState, useEffect } from "react";
export default function EditPersonal(props) {
  const { id } = props;

  const [data, setdata] = useState([]);
  const [details, setdetails] = useState([]);
  const [doc, setdoc] = useState([]);
  const [image, setImage] = useState();
  const [referal, setreferal] = useState([]);
  const [allValues, setAllValues] = useState();

  const changeHandler = (e) => {
    setAllValues({ ...allValues, [e.target.name]: e.target.value });
  };
  const FileHandler = (e) => {
    setImage(e.target.files[0]);
  };
  const submitValue = (e) => {
    e.preventDefault();
    console.log(image);
    const formData = new FormData();
    if (image != undefined) {
      formData.append("Idproof", image);
    }
    if (allValues != null) {
      Object.entries(allValues).forEach(([key, value]) =>
        formData.append(key, value)
      );
    }
    console.log(allValues);
    console.log(id);
    const config = {
      headers: {
        Authorization: `Token ${sessionStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };

    axios
      .patch(`/admin-update-customer-data/?user_id=${id}`, formData, config)
      .then(() => {
        alert("updated data");
      })
      .catch((error) => {
        alert(JSON.stringify(error.response.data));
      });
    // console.log(allValues);
  };

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Token ${sessionStorage.getItem("token")}`,
      },
    };

    axios
      .get(`customer-profile/`, {
        headers: {
          Authorization: `Token ${sessionStorage.getItem("token")}`,
        },
        params: {
          customer: id,
        },
      })
      .then((res) => {
        // console.log(res.data.details);
        
        setdata(res.data.customer);
        setdetails(res.data.details);
        console.log('data',res.data);
        setdoc(res.data.details.Doctor_Details);
        
      });
  }, []);

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Token ${sessionStorage.getItem("token")}`,
      },
    };

    axios
      .get(`admin-panel/doctor-details/`, {
        headers: {
          Authorization: `Token ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        // console.log(res.data.details);
        setreferal(res.data);
        // setdetails(res.data.details);
      });
  }, []);

  return (
    <>
      <div style={styles.wrapper}>
        <form className="personal-edit-form padding-0" style={styles.form}>
          <span className="flex-1001">
            <label style={{ fontSize: "16px" }}>Name</label>
            {/* <div style={styles.helper}> */}
            <input
              name="firstname"
              onChange={changeHandler}
              style={styles.input}
              defaultValue={data.firstname}
              type="text"
              placeholder="Johan"
            />
          </span>
          <span className="flex-1001">
            <label style={{ fontSize: "16px" }}>last name</label>
            <input
              name="lastname"
              onChange={changeHandler}
              style={styles.input}
              defaultValue={data.lastname}
              type="text"
              placeholder="doe"
            />
            {/* </div> */}
          </span>
          <span className="flex-1001">
            <label style={{ fontSize: "16px" }}>Age</label>
            <input
              name="age"
              onChange={changeHandler}
              style={styles.input}
              defaultValue={details.age}
              type="text"
              placeholder="25"
            />{" "}
          </span>
          <span className="flex-1001">
            <label style={{ fontSize: "16px" }}>Email</label>
            <input
              name="email"
              onChange={changeHandler}
              style={styles.input}
              defaultValue={data.email}
              type="text"
              placeholder="Alexa@gmail.com"
            />{" "}
          </span>
          <span className="flex-1001">
            <label style={{ fontSize: "16px" }}>Referal code </label>
            {console.log(doc.referalId_Name)}
            <select
              className="select-option"
              style={styles.input3}
              name="referalId"
              onChange={changeHandler}
              defaultValue={doc.referalId_Name}
            >
              <option defaultValue hidden>
                {doc.user_name} - {doc.referalId_Name} - {doc.user_email}
              </option>
              <option value="None">no doctor</option>
              {referal.map((code, index) => (
                <option key={index} value={code.id}>
                  {code.user_name} - {code.referalId_Name} - {code.user_email}
                </option>
              ))}
            </select>
          </span>
          <span className="flex-1001">
            <label style={{ fontSize: "16px" }}>Address</label>
            <input
              name="address"
              onChange={changeHandler}
              style={styles.input}
              defaultValue={details.address}
              type="text"
              placeholder="address "
            />{" "}
          </span>
          <span className="flex-1001">
            <label style={{ fontSize: "16px" }}>Location</label>
            <input
              name="location"
              onChange={changeHandler}
              style={styles.input}
              defaultValue={details.location}
              type="text"
              placeholder="location"
            />{" "}
          </span>
          <span className="flex-1001">
            <label style={{ fontSize: "16px" }}>Mobile</label>
            <input
              name="mobile"
              onChange={changeHandler}
              style={styles.input}
              defaultValue={data.mobile}
              type="text"
              placeholder="XXXXXXXXX"
            />{" "}
          </span>
          <span className="flex-1001">
            <label style={{ fontSize: "16px" }}>id proof</label>

            <label style={styles.input2} for="file">
              {details.Idproof ? details.Idproof : "Choose a file"}
            </label>
          </span>
          <span className="flex-1001">
            <label style={{ fontSize: "16px" }}>Husbands name</label>
            <input
              name="husband"
              onChange={changeHandler}
              style={styles.input}
              defaultValue={details.husband}
              type="text"
              placeholder="Husband Name"
            />{" "}
          </span>{" "}
          <span className="flex-1001">
            <label style={{ fontSize: "16px" }}>Your job</label>
            <input
              name="job"
              onChange={changeHandler}
              style={styles.input}
              defaultValue={details.job}
              type="text"
              placeholder="your job"
            />{" "}
          </span>
          <div className="nobackground">
            <input
              onClick={submitValue}
              style={styles.ebtn}
              className="pinkbutton"
              type="submit"
              value="Update"
            />
          </div>
          <input
            type="file"
            name="file"
            id="file"
            onChange={FileHandler}
            className="inputfile"
            style={{ background: "rgb(139 72 143 / 0%)" }}
          />
        </form>
      </div>
    </>
  );
}

const styles = {
  wrapper: {
    padding: "5px",
  },

  form: {
    // position: "absolute",
    // height: "274px",

    // overflowY: "scroll",
    scrollbarWidth: "none",
    // zIndex: 10,
    // width: "400px",
    display: "flex",
    flexDirection: "column",
    top: "18rem",
    right: "25rem",
    // background: "#9038a3",
    // background: "#ffffff1c 0% 0% no-repeat padding-box",
    padding: "20px",
  },

  date: {
    border: "none",
    color: "#fff",
    outline: "none",
    background: "transparent",
  },

  fileP: {
    width: "80%",
    display: "block",
  },

  file: {
    border: "none",
    background: "transparent",
    float: "right",
  },

  datehelp: {
    marginBottom: "20px",
    padding: "8px",
    textAlign: "left",
    fontSize: "12px",
    background: "#530e73",
    color: "#fff",
  },

  helper: {
    background: "#530e73",
    display: "flex",
    padding: "5px",
    textAlign: "left",
    marginBottom: "1rem",
    borderBottom: "1px solid #fff",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
  },

  filehelp: {
    marginBottom: "20px",
    padding: "8px",
    textAlign: "left",
    fontSize: "12px",
    background: "#530e73",
    color: "#fff",
    display: "flex",
  },

  input: {
    marginBottom: "20px",
    // background: "#530e73",
    width: "25%",
    background:
      "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
    color: "#fff",
    padding: "5px",
    borderBottom: "1px solid #fff",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
  },
  input3: {
    marginBottom: "20px",
    // background: "#530e73",
    width: "36%",
    background:
      "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
    color: "#fff",
    padding: "5px",
    borderBottom: "1px solid #fff",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
  },

  input2: {
    marginBottom: "20px",
    // background: "#530e73",
    width: "25%",
    background:
      "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
    color: "#fff",
    padding: "5px",
    borderBottom: "1px solid #fff",
    borderTop: "none",
    borderLeft: "none",
    textAlign: "left",
    fontSize: "14px",
    borderRight: "none",
  },

  ebtn: {
    marginBottom: "2rem",
    // background: "linear-gradient(90deg, #3a51a7 30%, #b53899)",
    padding: "10px 30px",
    border: "none",
    borderRadius: "10px",
    color: "#fff",
    cursor: "pointer",
  },
  input6: {
    background:
      "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
  },
};
