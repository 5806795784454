import axios from "axios";
import React, { useState, useEffect } from "react";

export default function Greet() {
  const [data, setdata] = useState([]);

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("doc_token"),
      },
    };

    axios.get("doctor/doctor-profile/", config).then((res) =>
      //console.log(JSON.stringify(res.data));
      setdata(res.data)
    );
  }, []);

  return (
    <div
      className="lightgradient"
      style={{
        padding: "1rem 5rem",
        textAlign: "left",
        lineHeight: "10px",
        // backdropFilter: "blur(31px) brightness(-15%) opacity(.3)",
        // backdropFilter: "blur(71px) brightness(-15%) opacity(0.5)",
      }}
    >
      <p style={style.font1}>
        <strong style={style.getsize}>
          Hello, <i style={style.getsize2}>Dr {data.firstname}</i>
        </strong>

        {/* You
        are in
      </p>
      <p>
        {/* Data is not available for week and day */}
        {/* <strong style={style.getsize}>week {data.week}</strong> and{" "}
        <strong style={style.getsize}> {data.daysLeft}</strong> days left */}
      </p>{" "}
    </div>
  );
}

const style = {
  getsize: {
    fontSize: "22px",
  },
  getsize2: {
    fontSize: "16px",
    margin: "0",
  },
  font1: { display: "flex" },
};
