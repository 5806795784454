import React from "react";
import axios from "axios";
import Sidebar from "../components/sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faBars, faUser } from "@fortawesome/free-solid-svg-icons";
import Toggler from "../toggle";
import DirectChat from "./directchat";
import ClientProfile from "../tog_profile";
//import DailyTrackerTabs from './components/daily_tracker';

// import Home from "./components/home";
import Calendar from "../components/calendar";
import Experts from "../components/experts";
import Greet from "../components/greet";

export default class Overview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isToggleOn: true,
      isMenu: true,
      chatid: "",
      isProfile: true,
      items: [],
      details: [],
    };
    this.handleClick = this.handleClick.bind(this);
    this.setWrap = this.setWrap.bind(this);
    this.clickedOutside = this.clickedOutside.bind(this);
    this.profileHandler = this.profileHandler.bind(this);
    this.openMenu = this.openMenu.bind(this);
  }

  handleClick() {
    this.setState((prevState) => ({
      isToggleOn: !prevState.isToggleOn,
    }));
  }

  openMenu() {
    this.setState({ isMenu: !this.state.isMenu });
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.clickedOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.clickedOutside);
  }

  profileHandler() {
    this.setState({ isProfile: !this.state.isProfile });
  }

  setWrap(node) {
    this.isWap = node;
  }

  clickedOutside(e) {
    if (this.isWap && !this.isWap.contains(e.target)) {
      this.setState({ isToggleOn: true });
      this.setState({ isProfile: true });
    }
  }

  componentDidMount() {
    this.setState({ chatid: this.props.match.params.id });

    if (localStorage.getItem("token") === null) {
      localStorage.clear();
      window.location = "/clogin";
    }
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("token"),
      },
    };

    axios.get("customer-profile/", config).then((res) => {
      console.log(res.data);
      this.setState({ items: res.data.customer });
      this.setState({ details: res.data.details });
    });
  }

  render() {
    function openMenu() {
      document.querySelector(".sidebar").classList.toggle("show");
    }

    return (
      <div className="App">
        <Sidebar />
        <header className="overview ">
          <div
            className="top-header grandiant"
            ref={this.setWrap}
            style={{ padding: "2% 10%", textAlign: "right" }}
          >
            <span onClick={openMenu} className="menu">
              <FontAwesomeIcon
                style={{ marginRight: "20px" }}
                icon={faBars}
                className="menu-icon"
              />
            </span>
            <div>{this.state.isToggleOn ? null : <Toggler />}</div>
            <span className="iconsofgradiant">
              <FontAwesomeIcon
                className="iconsofprofile"
                // onClick={this.handleClick}
                style={{ marginRight: "1rem", cursor: "pointer" }}
                icon={faBell}
              />
            </span>
            <span className="iconsofgradiant">
              {console.log("rderftwrtfwrf", this.state.details.profile_img)}
              {/* {this.state.details.profile_img ? (
                <img
                  style={{ cursor: "pointer" }}
                  onClick={this.profileHandler}
                  src={this.state.items.profile_img}
                  // alt="Avatar"
                  class="avatar"
                />
              ) : ( */}
              <FontAwesomeIcon
                className="iconsofprofile"
                onClick={this.profileHandler}
                style={{ cursor: "pointer" }}
                icon={faUser}
              />
              {/* )} */}
            </span>
            {this.state.items.firstname} {this.props.childern}
            {this.state.isProfile ? null : <ClientProfile />}{" "}
          </div>

          <div
            className="top-header"
            style={{ width: "89%", display: "flex", paddingLeft: "10px" }}
          ></div>
          <div className="mwrapper">
            <div className="mleft">
              <div
                style={{
                  display: "flex",
                  padding: "3% 1%",
                }}
              >
                <span style={{ whiteSpace: "nowrap" }}>Support Team</span>
                <div style={{ padding: "4px", width: "100%" }}>
                  <hr style={{ background: "white", color: "white" }} />
                </div>
              </div>

              <DirectChat chatid={this.state.chatid} />
              <div
                className="mright clander_clint1 calandermobile pc-view-hidden "
                style={{ marginLeft: "1%" }}
              >
                <p style={{ fontSize: "14px" }}>
                  SELECT DATE FOR DAILY TRACKER DETAILS
                </p>
                <Calendar />
                <div
                  style={{
                    fontWeight: "bold",
                    textAlign: "left",
                    paddingLeft: "10px",
                  }}
                >
                  {/* <small style={{ textTransform: "uppercase" }}>Experts</small> */}
                </div>
                {/* <Experts /> */}
              </div>
            </div>
            <div
              className="mright clander_clint1 calanderpc  "
              style={{ marginLeft: "1%" }}
            >
              <p style={{ fontSize: "14px" }}>
                SELECT DATE FOR DAILY TRACKER DETAILS
              </p>
              <Calendar />
              <div
                style={{
                  fontWeight: "bold",
                  textAlign: "left",
                  paddingLeft: "10px",
                }}
              >
                {/* <small style={{ textTransform: "uppercase" }}>Experts</small> */}
              </div>
              {/* <Experts /> */}
            </div>
          </div>
        </header>
      </div>
    );
  }
}
