import React from "react";
import { Overlay } from "react-bootstrap";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Diapering from "./tabs_com/diapering";
import Dietracker from "./tabs_com/diet_tracker";
import ExerciseTracker from "./tabs_com/exercise_tracker";
import Sleeppattern from "./tabs_com/sleeppattern";
import Medcine from "./tabs_com/medicine";
import Vaccines from "./tabs_com/vacines";

class Clist extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { customerId, closeDailyTracker } = this.props;

    return (
      <>
        {/* {!!customerId ? ( */}
        <div
          style={styles.card}
          className="multiple-tab dailytabmobile prupleblur width-85 takerfull"
        >
          <i
            onClick={closeDailyTracker}
            className="fa fa-close"
            style={styles.closebtn}
          ></i>
          <div style={styles.inner} className="w100">
            <Tabs className="selectedtaber">
              <TabList
                style={{
                  width: "100%",
                  paddingLeft: "0px",
                  listStyle: "none",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Tab>
                  <strong style={styles.tabnav}>Breastfeeding</strong>
                </Tab>
                <Tab>
                  <strong style={styles.tabnav}>Food chart</strong>
                </Tab>
                <Tab>
                  <strong style={styles.tabnav}>Exercise</strong>
                </Tab>
                <Tab>
                  <strong style={styles.tabnav}>Diapering</strong>
                </Tab>
                <Tab>
                  <strong style={styles.tabnav}>Sleep Pattern</strong>
                </Tab>
                <Tab>
                  <strong style={styles.tabnav}>Medicines</strong>
                </Tab>
                <Tab>
                  <strong style={styles.tabnav}>Vaccines</strong>
                </Tab>
              </TabList>
              <TabPanel>
                <Dietracker customer={customerId} />
              </TabPanel>
              <TabPanel>
                <Dietracker customer={customerId} />
              </TabPanel>
              <TabPanel>
                <ExerciseTracker customer={customerId} />
              </TabPanel>{" "}
              <TabPanel>
                <Diapering customer={customerId} />
              </TabPanel>{" "}
              <TabPanel>
                <Sleeppattern customer={customerId} />
              </TabPanel>{" "}
              <TabPanel>
                <Medcine customer={customerId} />
              </TabPanel>{" "}
              <TabPanel>
                <Vaccines customer={customerId} />
              </TabPanel>{" "}
            </Tabs>
          </div>
        </div>
        {/* ) : null} */}
      </>
    );
  }
}

const styles = {
  card: {
    display: "flex",
    justifyContent: "space-between",
    // background: "#4c003d",
    padding: "20px",
    borderRadius: "10px",
    margin: "10px",
    // position: "absolute",
    // transform: "translate(-10rem, -6rem)",
    zIndex: 10,
    height: "20rem",
    overflow: "overlay",
  },

  inner: {
    textDecoration: "none",
    color: "#fff",
  },

  tabnav: {
    cursor: "pointer",
    fontSize: "14.3px",
    whiteSpace: "nowrap",
    padding: "20px 10px",
  },

  closebtn: {
    position: "absolute",
    right: "0px",
    top: "-5px",
    cursor: "pointer",
  },
};

export default Clist;
