import axios from "axios";
import qs from "qs";
import React from "react";
import { Link } from "react-router-dom";

export default class Form extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isUser: false, username: "", password: "", items: [] };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.usernameChange = this.usernameChange.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();
    const Data = { email: this.state.username };
    const headers = {
      Authorization: `token ` + localStorage.getItem("token"),
    };
    axios
      .post("api/password_reset/", Data)
      .then(() => {
        alert("password reset link  sent to  mail ");
      })
      .catch((error) => {
        alert("cannot reset password");
      }); //   .then((res) => {
    //     localStorage.setItem("LoginToken", res.data.token);
    //   });
    /*.catch(error=>{console.log(error)});*/
  }

  usernameChange(e) {
    this.setState({ username: e.target.value });
  }

  // passwordChange(e) {
  //   this.setState({ password: e.target.value });
  // }
  signup = (e) => {
    // e.preventDefault();
    // localStorage.clear();
    window.location = "/clogin";
  };

  docsignup = (e) => {
    // e.preventDefault();
    // localStorage.clear();
    window.location = "/doc_signup";
  };

  render() {
    return (
      <>
        <div
          className="form-wrapper"
          style={{
            display: "flex",
            flexDirection: "column",
            color: "#fff",
            padding: "20px",
            width: "40%",
            borderRadius: "20px",

            boxShadow: "rgb(0 0 0 / 16%) 12px 10px 60px",
            backdropFilter: "blur(39px)",
            background: "rgba(0, 0, 0, 0.16)",
            // boxShadow: "12px 10px 60px #00000029",
            // backdropFilter: "blur(39px)",
            // WebkitBackdropFilter: "blur(39px)",
            // background: "#00000029",
          }}
        >
          <h3 className="smart">Lets Get Started</h3>
          {this.state.error == true ? <h1>hello</h1> : <h1></h1>}
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              lineHeight: "70px",
            }}
            onSubmit={this.handleSubmit}
          >
            <input
              value={this.state.username}
              onChange={this.usernameChange}
              style={styles.input}
              type="text"
              placeholder="Enter your  Email"
            />

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                {/* <small style={{ display: "flex", paddingTop: "2px" }}>
                  <input type="checkbox" />
                  <span>Remember Me</span>
                </small> */}
              </div>
              <div>
                {/* <Link to="/forget">
                  <small>Forgot Password</small>
                </Link> */}
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "8%",
              }}
            >
              <input
                style={{
                  background:
                    "transparent linear-gradient(266deg, #FF006C 0%, #293247 100%) 0% 0% no-repeat padding-box",
                  width: "116px",
                  height: "32px",
                  border: "none",
                  color: "#fff",
                  boxShadow: "0px 9px 10px #00000029",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
                type="submit"
                value="Reset Password"
              />
            </div>
            <Link to="/clogin">
              <small>Already have an account?</small>
            </Link>
            <div
              style={{
                textAlign: "center",
              }}
            >
              {/* <button
                onClick={this.docsignup}
                style={{
                  padding: "1.5%",
                  background: "none",
                  border: "1px solid #fff",
                  color: "#fff",
                  width: "116px",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                Doctor Sign Up
              </button> */}
            </div>
          </form>
        </div>
      </>
    );
  }
}

const styles = {
  input: {
    background: "none",
    padding: "10px",
    color: "#fff",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
  },
};
