import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown, faSort } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import SearchClients from "./search";
import ResheduleDialog from "./reshedule_appointment";
import { useEffect } from "react";
import axios from "axios";
export default function RequestApprovalTablepost() {
  const [shedule, setShedule] = useState(false);
  const [ID, setID] = useState("");
  const [showSerch, setShowSerch] = useState([]);
  function reject(id) {
    const Data = {
      appointmentID: id,

      // notes: notes,
    };
    const headers = {
      //"Content-Type": "application/x-www-form-urlencoded",

      Authorization: `token ` + localStorage.getItem("doc_token"),

      //Authorization: "JWT fefege...",
    };

    axios
      .post("appointments/reject-appointment/", Data, {
        headers: headers,
      })
      .then(() => {
        // setincrement([...increment,res.data])
        alert("Rejected appointment");
        window.location.reload();
      })
      .catch((error) => {
        console.log("error in sheduling ", error);
      });
  }

  function openShedule(id) {
    setShedule(!shedule);
    setID(id);
  }
  const config = {
    headers: {
      Authorization: `token ` + localStorage.getItem("doc_token"),
    },
  };

  useEffect(() => {
    axios.get("doctor/todays-appointments/", config).then((res) => {
      const data = res.data;
      // pnName(data.data);

      //  var stringdata = data;
      setShowSerch(data);

      console.log(data);
    });
    //  setShowSerch(data);
  }, []);

  return (
    <>
      {shedule ? <ResheduleDialog userid={ID} /> : null}

      <div style={styles.card} className="gradiantblur">
        <div style={styles.inner}>
          <p>
            <strong> Todays Appointment</strong>
          </p>
          <hr style={{ color: "#fff" }} />

          <table
            style={{ borderCollapse: "collapse", width: "41.6rem" }}
            className="tablecroll"
          >
            <thead>
              <tr>
                <td>Name</td>
                <td>Location</td>
                <td>Date</td>
                <td>Approved Slot</td>
                <td>Join</td>
                <td> Reject</td>
                <td>Schedule Consultation</td>
              </tr>
            </thead>
            <tbody>
              {console.log(showSerch)}
              {showSerch.map((user) => (
                <tr style={styles.tr}>
                  <td style={styles.tolink}>
                    {" "}
                    {user.firstname} {user.lastname}
                  </td>
                  <td>{user.location}</td>
                  <td>{user.date}</td>
                  <td>{user.time}</td>
                  <td>
                    <a
                      href={user.meeting_url}
                      style={styles.sbtn2}

                      //   onClick={openShedule}
                    >
                      Join
                    </a>
                  </td>{" "}
                  <td>
                    <button
                      style={styles.sbtn}
                      //   onClick={openShedule}
                      onClick={() => reject(user.id)}
                    >
                      Reject
                    </button>
                  </td>
                  <td>
                    <button
                      style={styles.sbtn}
                      onClick={() => openShedule(user.id)}
                      // onClick={openShedule}
                    >
                      Reschedule Slot
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

const styles = {
  card: {
    display: "flex",
    justifyContent: "space-between",
    // background: "#4c003d",
    padding: "0px",
    borderRadius: "10px",
    margin: "10px",
    background:'rgba(0,0,0,.4)',
  },
  inner: {
    width: "100%",
    textDecoration: "none",
    color: "#fff",
  },

  sortit: {
    background: "rgb(133, 55, 138) none repeat scroll 0% 0%",
    margin: "10px",
    borderRadius: "10px",
    textAlign: "left",
    padding: "10px",
  },

  option: {
    background: "transparent",
    border: "none",
    appearance: "none",
    color: "#fff",
  },

  tolink: {
    // background: "#60124D",
    textDecoration: "none",
    padding: "5px",
    color: "#fff",
  },

  sbtn: {
    padding: "4px 48px",
    minWidth: "127px",
    background: "#E81A7F",
    border: "1px solid #E81A7F",
  },
  sbtn2: {
    background: "#E81A7F",
    border: "1px solid #E81A7F",
    padding: " 3% 20%",
    padding: "2px 48px",
    minWidth: "127px",
    borderRadius: "4px",
  },

  tr: {
    // background: "#772068",
  },
};
