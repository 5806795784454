import React from "react";
import Image from "../img/small_image.jpeg";
import axios from "axios";

export default class DoctorProfile extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isClose: true, isProfile: true, list: [], hospital: [] };
    this.clickHandler = this.clickHandler.bind(this);
    this.closeTabBox = this.closeTabBox.bind(this);
  }

  componentDidMount() {
    axios
      .get(`http://127.0.0.1:8000/doctor/doctor-profile/`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Token ${localStorage.getItem("admin_token")}`,
        },
        params: {
          doctor: this.props.id,
        },
      })
      .then((res) => {
        this.setState({ list: res.data });
      });
    axios
      .get(`/admin-panel/all-hospitals/`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Token ${sessionStorage.getItem("token")}`,
        },
        params: {
          user: this.props.id,
        },
      })
      .then((re) => {
        console.log(re.data);
        this.setState({ hospital: re.data });
      });
  }

  clickHandler() {
    this.setState({ isProfile: false });
  }
  closeTabBox() {
    this.setState({ isClose: true });
  }
  updateStatus(id) {
    const data = {
      doctor: this.props.id,
      manager: id,
    };
    axios
      .post("/hospital/assign-hospital-manager/", data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Token ${sessionStorage.getItem("token")}`,
        },
      })
      // axios
      //   .post(`/hospital/assign-hospital-manager/`, {
      //     headers: {
      //       "Content-Type": "application/json",
      //       Accept: "application/json",
      //       Authorization: `Token ${sessionStorage.getItem("token")}`,
      //     },
      //     data: data,
      //   })
      .then((res) => {
        console.log(res);
      });
  }

  render() {
    return (
      <>
        {this.state.isClose ? (
          <div style={styles.dprofile}>
            <i
              onClick={this.closeTabBox}
              className="fa fa-close"
              style={styles.closebtn}
            ></i>
            <div style={styles.titlePlate}>
              <strong>Doctor Profile</strong>
            </div>

            <table>
              <tr>
                <td>
                  {this.state.list.profile_full_url !=
                  "https://sukhprasavam.shebirth.com/media//ProfilePic/default.jpg" ? (
                    <img
                      style={{ maxWidth: "65px" }}
                      className="avatar"
                      src={this.state.list.profile_full_url}
                      alt=""
                    />
                  ) : this.state.list.profile_full_url ==
                    "https://sukhprasavam.shebirth.com/media//ProfilePic/default.jpg" ? (
                    <button
                      className="avatar aquamarine "
                      style={{ maxWidth: "65px", border: "none" }}
                    ></button>
                  ) : (
                    <button
                      className="avatar aquamarine "
                      style={{ maxWidth: "65px", border: "none" }}
                    ></button>
                  )}
                  {/* <img
                    src={Image}
                    height="75px"
                    width="75px"
                    style={{ borderRadius: "50%" }}
                    alt=""
                  /> */}
                  <br />
                  <span style={styles.money}>
                    {" "}
                    {`Rs. ${this.state.list.price}`}
                  </span>
                </td>
                <td style={styles.col}>
                  <strong
                    style={{ fontSize: "20px" }}
                  >{`Dr. ${this.state.list.firstname} ${this.state.list.lastname}`}</strong>
                  <br />
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <small
                      style={{
                        padding: "3px 0px",
                        borderBottom: "1px solid #fff",
                      }}
                    >
                      Email : <span>{this.state.list.email}</span>
                    </small>
                    <small
                      style={{
                        padding: "3px 0px",
                        borderBottom: "1px solid #fff",
                      }}
                    >
                      Password : <span>{this.state.list.password}</span>
                    </small>
                    <br />
                  </div>
                </td>

                <td style={styles.col}>
                  <p>Select Hospital Manager </p>
                  <select
                    style={{
                      fontSize: "10px",
                      backdropFilter: "blur(10px)",
                      backgroundColor: "rgba(255, 255, 255, 0.14)",

                      color: "#fff",
                    }}
                    onChange={(e) => this.updateStatus(e.target.value)}
                  >
                    {this.state.hospital.map((ele) => {
                      return (
                        <option
                          style={{ background: "#a23698" }}
                          key={ele.id}
                          value={ele.id}
                        >
                          {ele.name}
                        </option>
                      );
                    })}
                  </select>
                  <br />
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <small
                      style={{
                        padding: "3px 0px",
                        borderBottom: "1px solid #fff",
                      }}
                    >
                      Contact Number : <span>{this.state.list.mobile}</span>
                    </small>
                    <small
                      style={{
                        padding: "3px 0px",
                        borderBottom: "1px solid #fff",
                      }}
                    >
                      Years Of Experience :{" "}
                      <span>{this.state.list.experience}</span>
                    </small>
                  </div>
                </td>
              </tr>
              <tr>
                <td></td>
                <td style={styles.co}>Full Name</td>
                <td>
                  <input
                    type="text"
                    value={`Dr. ${this.state.list.firstname} ${this.state.list.lastname}`}
                  />
                </td>
              </tr>
              <tr>
                <td></td>
                <td style={styles.co}>Speciality</td>
                <td>
                  <input type="text" value={this.state.list.speciality} />
                </td>
              </tr>
              <tr>
                <td></td>
                <td style={styles.co}>gender</td>
                <td>
                  <input type="text" value={this.state.list.gender} />
                </td>
              </tr>
              <tr>
                <td></td>
                <td style={styles.co}>Qualification</td>
                <td>
                  <input type="text" value={this.state.list.qualification} />
                </td>
              </tr>
              <tr>
                <td></td>
                <td style={styles.co}>Medical council required?</td>
                <td>
                  <input type="text" value={this.state.list.medicalCouncil} />
                </td>
              </tr>
              <tr>
                <td></td>
                <td style={styles.co}>Area of interest</td>
                <td>
                  <input type="text" value={this.state.list.interests} />
                </td>
              </tr>
              <tr>
                <td></td>
                <td style={styles.co}>Refrence Code</td>
                <td>
                  <input type="text" value={this.state.list.referalId} />
                </td>
              </tr>
              <tr>
                <td></td>
                <td style={styles.co}>Hospital/Hospital working</td>
                <td>
                  <input type="Apple" value={this.state.list.hospitals} />
                </td>
              </tr>
              <tr>
                <td></td>
                <td style={styles.co}>Place of Work</td>
                <td>
                  <input type="text" value={this.state.list.placeOfWork} />
                </td>
              </tr>
              <tr>
                <td></td>
                <td style={styles.co}>
                  Are you willing to give Online consultation for your Patient?
                </td>
                <td>
                  <input
                    type="text"
                    value={this.state.list.onlineConsultation}
                  />
                </td>
              </tr>
              <tr>
                <td></td>
                <td style={styles.col}>
                  <button onClick={this.clickHandler}>Close</button>
                </td>
                {/* <td>
                  <input type="submit" value="Update" />
                </td> */}
              </tr>
            </table>
          </div>
        ) : null}
      </>
    );
  }
}

const styles = {
  dprofile: {
    position: "absolute",
    backdropFilter: "blur(20px)",
    backgroundColor: "rgba(255, 255, 255, 0.14)",
    // background: "rgb(76, 0, 61) none repeat scroll 0% 0%",
    top: "2%",
    left: "22.4%",
    width: "41.3rem",
    borderRadius: "10px",
    padding: "10px",
  },
  closebtn: {
    position: "absolute",
    right: "8px",
    top: "2px",
  },
  titlePlate: {
    padding: "10px",
    color: "#fff",
  },
  flex: {
    display: "flex",
    justifyContent: "space-around",
    padding: "10px",
  },
  col: {
    padding: "10px",
    textAlign: "left",
  },
  co: {
    padding: "10px",
    textAlign: "left",
    border: "1px solid #b53899",
    backdropFilter: "blur(10px)",
    backgroundColor: "rgba(255, 255, 255, 0.14)",
  },
  money: {
    color: "#fff",
  },
};
