import React from "react";
import "../../App.css";
import LoginGirl from "../../client/img/login_girl.png";
// import Form from "./form";
// import Form2 from "./form2";
// import Form3 from "./form3";
import Form from "./signupform";
import slogo from "../../client/img/sukhprasavamlogo.png";
import { Steps, Step } from "react-step-builder";

// const Navigation = (props) => {
//   return (
//     <>
//       {props.current == "3" ||
//       props.current == "2" ||
//       props.current == "1" ? null : (
//         <div
//           style={{
//             top: "725px",
//             right: "500px",
//             padding: "1%",
//             position: "absolute",
//             display: "flex",
//             flexDirection: "column",
//           }}
//         >
//           <input
//             style={{
//               background:
//                 "transparent linear-gradient(266deg, #FF006C 0%, #293247 100%) 0% 0% no-repeat padding-box",
//               width: "102px",
//               height: "32px",
//               border: "none",

//               color: "#fff",
//               boxShadow: "0px 9px 10px #00000029",
//               borderRadius: "4px",
//               cursor: "pointer",
//             }}
//             onClick={props.next}
//             type="button"
//             value="Next"
//           />

//           <small style={{ color: "rgb(255, 255, 255)", lineHeight: "70px" }}>
//             Term of use. Privacy policy
//           </small>
//         </div>
//       )}
//     </>
//   );
// };
// const config = {
//   navigation: {
//     component: Navigation, // a React component with special props provided automatically
//     location: "after", // or before
//   },
// };

export default class ClientLogin extends React.Component {
  // Form2 = (props) => {
  //   if (this.props.state.firstname == undefined && this.props.current == "2") {
  //     alert("first name cannot be empty");
  //     window.location.reload();
  //   } else {
  //     console.log("eerrr");
  //   }
  // };
  render() {
    return (
      <>
        <div
          className="newbg"
          style={{
            padding: "10% 0%",
          }}
        >
          <div style={styles.flex} className="clintsignuup">
            <div
              className="text-center"
              style={{
                color: "#fff",
              }}
            >
              <img src={LoginGirl} alt="" />
              <div style={styles.content} className="flexdisplay flexsign">
                {/* <h1 className="margin50">Launching </h1> */}
                <img className="slogo" src={slogo} />
                {/* <small>Who recommands only 15% c-sector rate in a nation</small> */}
                {/* <small style={{ textTransform: "capitalize", fontSize: "20px" }}>
                {" "}
                pleasing birth without fear
              </small> */}
              </div>
              {/* <small>Who recommands only 15% c-sector rate in a nation</small> */}
            </div>
            <Form />
            {/* <Steps config={config}>
              <Step component={Form} />
              <Step component={Form2} />
              <Step component={Form3} />
            </Steps> */}
          </div>
        </div>
      </>
    );
  }
}

const styles = {
  flex: {
    display: "flex",
    justifyContent: "space-between",
    width: "70%",
    margin: "auto",
  },
};
