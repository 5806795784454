import React from "react";
import axios, { post } from "axios";
import Calendar from "react-calendar";
import moment from "moment";
import "./calendar.css";
//import axios from "axios";
export default class MedicalDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items5: [],
      medicineOpen: true,
      today: "",
      dateState: [],
      medicineReady: true,
      size: "auto",
      allValues: {
        AppointmentDate: "",
        Date: "",
        ScanDate: "",
        question: "",
        bp: "",
        ultraSound: "",
        BloodReport: "",
        AntenatalCharts: "",
        Weight: "",
      },
      files: {
        ultraSound: null, // <- these keys should match the 'name' of the inputs
        BloodReport: null,
        AntenatalCharts: null,
      },
      //   image_file: null,
      image_preview: "",
      file: null,
    };
    this.onsubmbit = this.onsubmbit.bind(this);
    this.onChange = this.onChange.bind(this);
    // this.fileUpload = this.fileUpload.bind(this);

    this.closeMedicine = this.closeMedicine.bind(this);
  }

  changeHandler = (e) => {
    this.setState({
      allValues: { ...this.state.allValues, [e.target.name]: e.target.value },
    });
  };

  onChange(e) {
    let files = this.state.files;
    files[e.target.name] = e.target.files[0];

    this.setState({
      files: files,
    });

    console.log(e.target.files[1]);
    //console.log(e.target.files[0]);
    //let image_as_base64 = URL.createObjectURL(e.target.files[0]);

    //let image_as_files = e.target.files[0];

    // this.setState({
    //   image_file: {
    //     ...this.state.image_file,
    //     [e.target.name]: e.target.files[0],
    //   },
    //   //image_preview: image_as_base64,
    //   //   image_file: image_as_files,
    // });
    //this.setState({ file: e.target.files[0] });
  }
  //   onChangeImages = (e) => {
  //     this.setState({ images: e.target.files });
  //   };

  //   fileUpload(file) {
  //     const url = "https://shebirth.herokuapp.com/customer/medical-ADD/";
  //     const formData = new FormData();
  //     formData.append("ScanReport", file);
  //     const config = {
  //       headers: {
  //         Authorization: `token ` + localStorage.getItem("token"),
  //         "content-type": "multipart/form-data",
  //       },
  //     };
  //     console.log(file);
  //     return post(url, formData, config);
  //   }

  dateHandler = (e) => {
    var dateselected = e.target.value;

    var date = moment(dateselected).format("YYYY-MM-DD");
    console.log(dateselected);
    this.setState({ dateState: date }, this.getData.bind(this));
  };
  componentWillMount() {
    console.log(this.state.allValues.Date);
    // var someDate = new Date();
    // let date = moment(this.state.dateState).format("YYYY-MM-DD");
    this.getData();
  }

  getData() {
    var date = moment(this.state.dateState).format("YYYY-MM-DD");
    this.setState({ today: date });
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("token"),
      },
    };

    axios
      .get("customer/get-medical-form-data?date=" + date, config)
      .then((res) => {
        this.setState({ items5: res.data });
      });
  }

  onsubmbit = (e) => {
    e.preventDefault();
    // if (this.state.image_file !== null) {
    console.log(this.state.dateState);

    let formData = new FormData();
    if (this.state.files.ultraSound && this.state.files) {
      formData.append("ultraSound ", this.state.files.ultraSound);
    }
    if (this.state.files.BloodReport && this.state.files) {
      formData.append("bloodReport", this.state.files.BloodReport);
    }
    if (this.state.files.AntenatalCharts && this.state.files) {
      formData.append("antenatalChart", this.state.files.AntenatalCharts);
    }
    if (this.state.allValues.AppointmentDate && this.state.allValues) {
      formData.append("appointmentDate", this.state.allValues.AppointmentDate);
    }
    if (this.state.dateState && this.state.dateState) {
      formData.append("date", this.state.dateState);
    }
    if (this.state.allValues.ScanDate && this.state.allValues) {
      formData.append("scantDate", this.state.allValues.ScanDate);
    }
    if (this.state.allValues.question && this.state.allValues) {
      formData.append("question", this.state.allValues.question);
    }
    formData.append("bp", this.state.allValues.bp);

    formData.append("weight", this.state.allValues.Weight);

    // const Data = {
    //   name: this.state.allValues.AppointmentDate,
    //   // module: "exercise",
    // };
    // formData.append("bloodReport", this.state.image_file.bloodReport);
    // formData.append("scanReport", this.state.image_file);
    // the image field name should be similar to your api endpoint field name
    // in my case here the field name is customFile

    axios
      .post(
        "customer/medical-ADD/",
        formData,

        {
          headers: {
            Authorization: `token ` + localStorage.getItem("token"),
            "Content-type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        alert("Update Success");
        // window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
    // }
  };

  // this.fileUpload(this.state.file).then((response) => {
  //   console.log(response.data);
  // });
  // var formData = new FormData();
  // var imagefile = document.querySelector("#file");
  // formData.append("scanReport", imagefile.files[0]);
  // console.log(imagefile);
  // const headers = {
  //   Authorization: `token ` + localStorage.getItem("token"),
  // };
  // axios
  //   .post("https://shebirth.herokuapp.com/customer/medical-ADD/", formData, {
  //     headers: headers,
  //   })
  //   .then(() => {
  //     alert("Medicine was submitted");
  //   })
  //   .catch((error) => {
  //     alert("Cannot add Medicine again");
  //   });

  //shebirth.herokuapp.com/customer/medical-ADD

  changeDate(e) {
    //  setdateer(moment(dateState).format("YYYY-MM-DD"));
    //setTracker(!show);
    //  this.setState({ dateState: e.target.value });
    // setdateer(e);
  }
  closeMedicine() {
    this.setState({ medicineOpen: !this.state.medicineOpen });
  }

  render() {
    return (
      <>
        {this.state.medicineOpen ? (
          <form onSubmit={this.onsubmbit}>
            <div className="module-wrap" style={styles.module}>
              {/* <div className="medical-calendar">
              <Calendar
                onChange={this.changeDate}
                //onClickDay={this.clickTracker}
                value={this.state.dateState}
              />
            </div> */}

              <div style={{ textAlign: "left" }}>
                <h4>Select Date</h4>
                <input
                  type="date"
                  className="calanderbutton"
                  name="Date"
                  style={styles.cutumbuttons}
                  onChange={this.dateHandler}
                  defaultValue={this.state.today}
                />
                <h4> Appointment with doctor</h4>
                <input
                  style={styles.cutumbuttons}
                  type="date"
                  className="calanderbutton"
                  name="AppointmentDate"
                  onChange={this.changeHandler}
                  defaultValue={this.state.items5.appointmentDate}
                />
                <h4>Next Scan Date</h4>
                <input
                  type="date"
                  className="calanderbutton"
                  style={styles.cutumbuttons}
                  name="ScanDate"
                  onChange={this.changeHandler}
                  defaultValue={this.state.items5.scantDate}
                />
                <h4>Question to ask doctor</h4>
                <textarea
                  className="gradiantblur"
                  name="question"
                  onChange={this.changeHandler}
                  defaultValue={this.state.items5.question}
                  style={{ fontSize: "12px", width: "100%", height: "300px" }}
                ></textarea>
                <h4>Ultrasound scan report</h4>
                <span style={{ display: "flex" }}>
                  <input
                    style={styles.cutumbuttons}
                    type="file"
                    id="file2"
                    // style={styles.cutumbuttons}
                    name="ultraSound"
                    // accept="application/pdf"
                    onChange={this.onChange}
                    // defaultValue={this.state.items5.ultraSound}
                  />
                  <p for="file2" style={{ fontSize: "12px" }}>
                    {this.state.items5.ultra_sound_filename
                      ? this.state.items5.ultra_sound_filename
                      : "Choose a file"}
                  </p>
                </span>
                <h4>Upload Blood Report</h4>
                <span style={{ display: "flex" }}>
                  <input
                    style={styles.cutumbuttons}
                    type="file"
                    name="BloodReport"
                    onChange={this.onChange}
                    // value={this.state.allValues.BloodReport}
                  />

                  <p for="file2" style={{ fontSize: "12px" }}>
                    {this.state.items5.blood_report_filename
                      ? this.state.items5.blood_report_filename
                      : "Choose a file"}
                  </p>
                </span>
                <h4>Upload Antenatal Charts</h4>
                <span style={{ display: "flex" }}>
                  <input
                    style={styles.cutumbuttons}
                    type="file"
                    name="AntenatalCharts"
                    onChange={this.onChange}
                    // value={this.state.allValues.AntenatalCharts}
                  />
                  <p for="file2" style={{ fontSize: "12px" }}>
                    {this.state.items5.antenatal_chart_filename
                      ? this.state.items5.antenatal_chart_filename
                      : "Choose a file"}
                  </p>
                </span>
                <h4>Enter Today BP</h4>
                <input
                  className="calanderbutton"
                  style={styles.cutumbuttons}
                  type="number"
                  name="bp"
                  placeholder="0 mmHg"
                  onChange={this.changeHandler}
                  defaultValue={this.state.items5.bp}
                />
                <h4>Enter Todays Weight</h4>
                <input
                  className="calanderbutton"
                  style={styles.cutumbuttons}
                  type="number"
                  name="Weight"
                  placeholder="0 kg"
                  onChange={this.changeHandler}
                  defaultValue={this.state.items5.weight}
                />
              </div>

              <br />

              <div>
                <button
                  type="submit"
                  style={styles.sbtn}
                  className="pinkbutton"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        ) : null}
      </>
    );
  }
}

const styles = {
  module: {
    position: "relative",
    //background: "#ffffff1c 0% 0% no-repeat padding-box",
    margin: "0px 30px",
    borderRadius: "10px",
    padding: "5px 10px",
    height: "30rem",
    overflowY: "scroll",
    scrollbarWidth: "none",
  },
  txt: {
    textAlign: "left",
  },
  btn: {
    border: "1px solid #974cae",
    padding: "5px 30px",
    background: "#974cae",
    fontSize: "12px",
  },

  cutumbuttons: {
    // background: "#6f2b68",
    border: "none",
    color: "white",
    padding: "1%",
  },
  sbtn: {
    // background: "linear-gradient(90deg, #3a51a7 30%, #b53899)",
    padding: "10px 30px",
    border: "none",
    borderRadius: "10px",
    color: "#fff",
    cursor: "pointer",
    margin: "10px",
  },
};
