import "./accodtion.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBolt, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import React, { useState, useEffect } from "react";
import axios from "axios";
export default function ContractionCounter() {
  const [isActive, setIsActive] = useState(false);
  // const [activeCurrentIndex, setActiveCurrentIndex] = useState();
  const [predefined, setpredefined] = useState([]);
  const [activeCurrentDate, setActiveCurrentDate] = React.useState(null);
  const setActiveCurrentIndex = (date) => () => {
    if (!!activeCurrentDate && date === activeCurrentDate) {
      setActiveCurrentDate(null);
    } else {
      setActiveCurrentDate(date);
    }
  };
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("token"),
      },
    };

    axios.get("customer/all-dates-contractions/", config).then((res) => {
      console.log(res.data);
      //     let filteredData = [];
      //     let filteredData2 = [];

      //     res.data.custom.forEach((e) => {
      //       if (e.completed === true) filteredData.push(e);
      //     });

      //     // res.data.predefined.sub_module.forEach((e) => {
      //     //   if (e.completed === true) filteredData2.push(e);
      //     // });
      //     // setsymtoms(filteredData);
      //     setcustom(filteredData);
      setpredefined(res.data);

      //     // setsymptomsWithIputs(res.data.symptomsWithIputs);
    });
  }, []);

  let mapped = predefined.reduce((r, a) => {
    r[a.date] = [...(r[a.date] || []), a];
    return r;
  }, {});
  //const decoratedOnClick = useAccordionToggle(eventKey, onClick);
  // function smallestToBiggest(a, b) {
  //   return a.formated_time - b.formated_time;
  // }
  // quizList.sort(biggestToSmallest);
  return (
    <>
      <div className="accordion croll">
        {Object.values(mapped).map((item, index) => (
          //{predefined.map(({ date }) => (
          <div className="accordion-item">
            <div
              className="accordion-title  lightgradient2 font-blue"
              onClick={setActiveCurrentIndex(item[0].date)}
              //   onClick={() => setActiveCurrentIndex(item[0].date)}
            >
              <div>{item[0].date}</div> <div>{item[0].time}</div>{" "}
              <div>painscale</div>
              <div>
                <FontAwesomeIcon
                  icon={faBolt}
                  style={{ marginRight: "1rem", color: "red" }}
                />
                {item.length}
                <FontAwesomeIcon
                  icon={faChevronDown}
                  style={{ marginLeft: "5rem", cursor: "pointer" }}
                />
                {/* {isActive ? (
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    style={{ marginLeft: "5rem", cursor: "pointer" }}
                  />
                ) : (
                  "-"
                )} */}
              </div>
            </div>
            {activeCurrentDate === item[0].date && (
              <div className="accordion-content">
                <table>
                  <thead>
                    <tr>
                      <th>time</th> <th>contraction</th> <th>pain scale</th>{" "}
                      <th>interval</th>
                    </tr>
                  </thead>
                  {item.map((e) => {
                    return (
                      <tr>
                        <td> {e.formated_time}</td> <td>{e.contraction} sec</td>{" "}
                        <td> {e.pain_scale}</td> <td>{e.formated_interval}</td>
                      </tr>
                    );
                  })}
                </table>
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
}
