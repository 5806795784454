import axios from "axios";
import React, { useState, useEffect } from "react";

export default function Greet() {
  const [data, setdata] = useState([]);

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("token"),
      },
    };

    axios.get("customer/customer-dashboard-details/", config).then((res) => {
      console.log(res.data.firstname);
      setdata(res.data);
      localStorage.setItem('dashboard_data',JSON.stringify(res.data))
    });
  }, []);

  return (
    <>
      <div
        className="greet"
        style={{ padding: "1rem 5rem", textAlign: "left", lineHeight: "10px" }}
      >
        <p>
          Hi <strong style={style.getsize}>{data.firstname}</strong> You are in
        </p>
        <p>
          <strong style={style.getsize}>week {data.week}</strong> and{" "}
          <strong style={style.getsize}> {data.daysLeft}</strong> days left
        </p>
      </div>

      <div
        className="greet_mobile"
        style={{ padding: "1rem 5rem", textAlign: "left", lineHeight: "10px" }}
      >
        <p className="greet_p">
          Hi {data.firstname} You are in week {data.week} and {data.daysLeft}{" "}
          days left
        </p>
      </div>
    </>
  );
}

const style = {
  getsize: {
    fontSize: "2rem",
  },
};
