import React from "react";
import { withRouter } from "react-router";
import "../../App.css";
import LoginGirl from "../img/login_girl.png";
import Form from "./form";
import "../login/login.css";

export default class ClientLogin extends React.Component {
  render() {
    return (
      <>
        <div className="newbg">
          <div
            style={{
              padding: "10% 0%",
            }}
          >
            <div className="login-wrapper" style={styles.flex}>
              <div
                className="loginimage"
                style={{
                  color: "#fff",
                }}
              >
                <img src={LoginGirl} alt="" style={styles.imagep} />
              </div>

              <Form />
            </div>
            <div style={styles.content}>
              <h1 className="margin50  fontsize50">sukh prasavam</h1>
              <h2> Pleasing birth without fear</h2>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const styles = {
  content: {
    color: "white",
    textAlign: "center",
  },

  imagep: { width: "100%" },
  flex: {
    display: "flex",
    justifyContent: "space-between",
    width: "70%",
    margin: "auto",
  },
};
