import React from "react";
import Doctorprofile from "./doctorprofile";
import axios from "axios";

export default class Tablelist extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isDialog: true, background: "#fff", list: [] };
    this.handleClick = this.handleClick.bind(this);
    this.colorChange = this.colorChange.bind(this);
    this.updateStatus = this.updateStatus.bind(this);
  }

  componentDidMount() {
    axios
      .get("admin-panel/all-hospitals/", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Token ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        this.setState({ list: res.data });
      });
  }

  updateStatus(id) {
    fetch(`https://sukhprasavam.shebirth.com/activateOrDeactivate/`, {
      body: JSON.stringify({ id: id }),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Token ${sessionStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        alert(res.Success);
      });
  }

  colorChange() {
    const color = this.state.background === "#fff" ? "red" : "#fff";

    this.setState({ background: color });
  }

  handleClick() {
    this.setState({
      isDialog: !this.state.isDialog,
    });
  }

  render() {
    return (
      <div style={styles.card} className="lightgradient2">
        <div style={styles.inner}>
          <p>
            <strong>Hospital manager List</strong>
          </p>
          <hr style={{ color: "#fff" }} />
          <table style={{ borderSpacing: " 0px 1em" }}>
            <thead>
              <tr>
                <td>Name</td>
                <td>Location</td>
                <td>Username</td>
                <td>password</td>

                <td>Account Status</td>
              </tr>
            </thead>
            <tbody>
              {this.state.list &&
                this.state.list
                  .filter((ele) => {
                    if (this.props.searchval) {
                      // console.log(search);
                      if (ele && ele.name) {
                        return `${ele.name}`
                          .toLowerCase()
                          .includes(this.props.searchval.toLowerCase());
                      }
                    } else return ele;
                  })
                  .map((ele) => {
                    return (
                      <tr key={ele.user} className="trfortable">
                        <td>{ele.name}</td>
                        <td>{ele.location}</td>
                        <td>{ele.email}</td>

                        <td>{ele.passwordString}</td>
                        <td>
                          <select
                            onChange={this.colorChange}
                            onChange={() => this.updateStatus(ele.user)}
                            style={{
                              background: this.state.background,
                              border: "none",
                            }}
                          >
                            {ele.accountStatus ? (
                              <>
                                <option>Enable</option>{" "}
                                <option>Disabled</option>
                              </>
                            ) : (
                              <>
                                <option>Disabled</option>
                                <option>Enable</option>
                              </>
                            )}
                          </select>
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </table>
          {this.state.isDialog ? null : <Doctorprofile />}
        </div>
      </div>
    );
  }
}

const styles = {
  card: {
    // display: "flex",
    justifyContent: "space-between",
    // backdropFilter: "blur(31px) brightness(-15%) opacity(.3)",
    padding: "20px",
    borderRadius: "10px",
    margin: "10px",
  },
  inner: {
    textDecoration: "none",
    color: "#fff",
  },
};
