import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
export default function Criticality() {
  const { id } = useParams();
  const [predefined, setpredefined] = useState([]);
  var pikerdate = moment().format("YYYY-MM-DD");

  //   const findgraph = (e) => {
  //     // e.preventDefault();
  //     console.log("hello");
  //     console.log(e);
  //     history.push(`/analatics/${e} `);
  //     window.location.reload();
  //   };
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    };

    axios
      .get(`analytics/client-criticalities/?customer=` + id, config)
      .then((res) => {
        console.log(res.data);
        //     let filteredData = [];
        //     let filteredData2 = [];

        //     res.data.custom.forEach((e) => {
        //       if (e.completed === true) filteredData.push(e);
        //     });

        //     // res.data.predefined.sub_module.forEach((e) => {
        //     //   if (e.completed === true) filteredData2.push(e);
        //     // });
        //     // setsymtoms(filteredData);
        //     setcustom(filteredData);
        setpredefined(res.data);

        //     // setsymptomsWithIputs(res.data.symptomsWithIputs);
      });
  }, []);

  let mapped = predefined.reduce((r, a) => {
    r[a.date] = [...(r[a.date] || []), a];
    return r;
  }, {});
  return (
    <div className="lightgradient">
      <div className="all-clients-table" style={styles.card}>
        <div style={styles.inner}>
          <p>
            <strong>criticality status</strong>
          </p>
          <hr style={{ color: "#fff" }} />

          <div className="table-cover">
            <table
              style={{
                borderCollapse: "seprate",
                width: "41.6rem",
                borderSpacing: "0 1em",
              }}
            >
              <thead>
                <tr style={styles.tr}>
                  <td>Date</td>
                  <td>Criticality</td>
                  {/* <td>Complications</td> */}
                </tr>
              </thead>
              <tbody>
                {predefined.map((user) => (
                  <tr style={styles.tr}>
                    <td>{user.date}</td>
                    <td>
                      {user.criticallity == "high risk" ? (
                        <Link
                          to={{
                            pathname:
                              "/conanalatics/" + user.date + "/login" + user.id,
                          }}
                        >
                          <button
                            // onClick={() => findgraph(user.id)}
                            style={styles.red}
                          ></button>
                        </Link>
                      ) : user.criticallity == "stable" ? (
                        <Link
                          to={{
                            pathname:
                              "/conanalatics/" + user.date + "/login" + user.id,
                          }}
                        >
                          <button
                            //   onClick={() => findgraph(user.id)}
                            style={styles.green}
                          ></button>
                        </Link>
                      ) : (
                        <Link
                          to={{
                            pathname:
                              "/conanalatics/" + user.date + "/login" + user.id,
                          }}
                        >
                          <button
                            //   onClick={() => findgraph(user.id)}
                            style={styles.yellow}
                          ></button>
                        </Link>
                      )}
                    </td>
                    {/* <td>
                      <button>view</button>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
const styles = {
  red: { background: "red", border: "1px solid red", padding: "8% 30%" },
  green: { background: "green", border: "1px solid green", padding: "8% 30%" },
  yellow: {
    background: "yellow",
    border: "1px solid yellow",
    padding: "8% 30%",
  },
  card: {
    // display: "flex",
    // justifyContent: "space-between",
    backgroundColor: "#ffffff1c 0% 0% no-repeat padding-box",
    // padding: "20px",
    // borderRadius: "10px",
    // margin: "10px",
  },
  // inner: {
  //   textDecoration: "none",
  //   color: "#fff",
  // },

  input: {
    width: "40rem",
    borderTop: "none",
    borderLeft: "none",
    color: "white",
    borderRight: "none",
    borderBottom: "1px solid rgb(255 255 255 / 39%)",
    background: "transparent",
    padding: "8px",
  },
  card: {
    display: "flex",
    justifyContent: "space-between",
    // backdropFilter: "blur(31px) brightness(-15%) opacity(.3)",
    padding: "0px",
    borderRadius: "10px",
    margin: "10px",
  },
  inner: {
    textDecoration: "none",
    color: "#fff",
    width: "100%",
    margin: "10px 10px 5% 10px",
  },

  sortit: {
    background: "#ffffff1c 0% 0% no-repeat padding-box",
    margin: "10px",
    borderRadius: "10px",
    textAlign: "left",
    padding: "10px",
  },

  option: {
    background: "#ffffff1c 0% 0% no-repeat padding-box",
    border: "none",
    appearance: "none",
    padding: "1%",
    color: "#fff",
  },

  tolink: {
    background: "#60124D",
    textDecoration: "none",
    padding: "5px",
    color: "#fff",
  },

  sbtn: {
    // background: "#E81A7F",
    // border: "1px solid #E81A7F",
    padding: "5% 25%",
  },

  tr: {
    margin: "10px auto",
    background: "#ffffff1c 0% 0% no-repeat padding-box",
    // background: "#772068",
    cursor: "pointer",
  },
};
