import FullScreenSlider from "./slider";
import './FullScreenSlider.css'
import { Fragment } from "react";
import About from "./about";
function Landingpage(){


    return <Fragment>

     <FullScreenSlider/>
     <About/>
    </Fragment>
}

export default Landingpage;