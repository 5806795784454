import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

const initialState = {
  idaddProducts: "",
};

const Searchclients = () => {
  const [showResults, setShowResults] = React.useState(true);

  const [poName, pnName] = React.useState(initialState);
  const [showSerch, setShowSerch] = React.useState([]);
  const [detail, setDetail] = useState(false);
  const [inputValue, setInputValue] = React.useState("");
  const [filteredSuggestions, setFilteredSuggestions] = React.useState([]);
  const [selectedSuggestion, setSelectedSuggestion] = React.useState(0);
  const [displaySuggestions, setDisplaySuggestions] = React.useState(false);
  //const [suggestions, setSuggestions] = useState([]);
  const history = useHistory();

  const finddoctor3 = (e) => {
    // e.preventDefault();
    history.push(`/detiled/${e} `);
    window.location.reload();
  };

  const suggestions = [];
  showSerch.forEach(function (data) {
    suggestions.push(data);
  });
  // showSerch.forEach(function (data) {
  //   var data = data.firstname;
  //   suggestions.push(data);
  // });
  //fetching firstname to search doctor
  // var cuurent_id = data.id;
  // //pnName(data);

  // var data[] = data.id;
  //setInputValue(value);

  // suggestions(data);
  // });
  // var jsonContent = JSON.stringify(showSerch);
  // //
  // //var strung = JSON.stringify(showSerch.firstname);
  // console.log(suggestions);

  const onChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    setShowResults(false);

    //console.log(strung.substring(1, strung.length - 1));
    // console.log(JSON.stringify(suggestions));
    //   var suggestions = suggestions.substring(1, suggestions.length - 1);
    // newObj = suggestions;
    //console.log(suggestions);
    //setFilteredSuggestions({ ...poName, idAddProducts: idAddProducts });

    const filteredSuggestions = suggestions.filter(
      (suggestion) =>
        suggestion.firstname
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()) ||
        suggestion.id.toString().toLowerCase().includes(value.toLowerCase())
    );

    // const filteredSuggestions = suggestions.filter((suggestion) =>
    //   suggestion.toString().toLowerCase().includes(value.toLowerCase())
    // );

    setFilteredSuggestions(filteredSuggestions);
    setDisplaySuggestions(true);
  };

  // const onSelectSuggestion = (index) => {
  //   setSelectedSuggestion(index);
  //   setInputValue(filteredSuggestions[index]);
  //   setFilteredSuggestions([]);
  //   setDisplaySuggestions(false);
  // };

  const SuggestionsList = (props) => {
    //console.log(props);

    const {
      suggestions,
      inputValue,

      onSelectSuggestion,
      displaySuggestions,
      selectedSuggestion,
    } = props;

    if (inputValue && displaySuggestions) {
      if (suggestions.length > 0) {
        return (
          <ul className="suggestions-list" style={styles.ulstyle}>
            {suggestions.map((suggestion, index) => {
              console.log(suggestions);
              //const isSelected = selectedSuggestion === index;
              // const classname = `suggestion ${isSelected ? "selected" : ""}`;
              return (
                // {/* <Link
                //   style={{ textDecoration: "none" }}
                //   to={{
                //     pathname: `/detiled/${suggestion.id}`,
                //   }}
                // > */}
                // {/* <Link to={`/detiled/${suggestion.id}`}> */}
                <li
                  style={styles.listyle}
                  // onMouseOver={{ background: "yellow" }}
                  key={index}
                  //className={classname}
                  onClick={() => finddoctor3(suggestion.id)}
                >
                  {suggestion.firstname}
                </li>
                // {/* </Link> */}
              );
            })}
          </ul>
        );
      } else {
        return <div style={styles.listyle}>No suggestions available...</div>;
      }
    }
    return <></>;
  };

  // async function handleTable(idAddProducts) {
  //   //const id = poName.idaddProducts;

  //   console.log(idAddProducts);
  //   const res = await fetch(
  //     "https://shebirth.herokuapp.com/doctor/doctor-filter/?id=" + idAddProducts
  //   );
  //   const data = await res.json();
  //   pnName(data.data);
  //   var stringdata = data[0];
  //   console.log(stringdata);
  //   setShowSerch(stringdata);
  // }
  const config = {
    headers: {
      Authorization: `token ` + localStorage.getItem("token"),
    },
  };

  useEffect(() => {
    axios.get("admin-panel/all-doctors-list/", config).then((res) => {
      const data = res.data;
      // pnName(data.data);

      //  var stringdata = data;
      setShowSerch(data);

      // console.log(data);
    });
    //  setShowSerch(data);
  }, []);

  return (
    <>
      <div className="note-container" style={styles.card}>
        <div style={styles.inner}>
          <p className="serchdocp" style={{ textAlign: "left" }}>
            Search Doctors
          </p>
          <form className="search-form" style={{}}>
            {showResults ? (
              <FontAwesomeIcon
                style={{ marginRight: "-23px", width: "16px" }}
                icon={faSearch}
              />
            ) : null}
            <input
              onChange={onChange}
              value={inputValue}
              style={styles.input}
              type="Search"
            />
          </form>
          <SuggestionsList
            inputValue={inputValue}
            // onSelectSuggestion={onSelectSuggestion}

            displaySuggestions={displaySuggestions}
            suggestions={filteredSuggestions}
          />
          {/* <Link
            to={{
              pathname: `/detiled/5`,
            }}
          >
            <buttonn>hello</buttonn>
          </Link> */}
        </div>
      </div>
    </>
  );
};

const styles = {
  card: {
    // display: "flex",
    justifyContent: "space-between",
    background: "#ffffff1c 0% 0% no-repeat padding-box",
    padding: "20px",

    margin: "10px",
  },
  inner: {
    textDecoration: "none",
    color: "#fff",
  },

  input: {
    width: "100%",
    borderTop: "none",
    borderLeft: "none",
    borderColor: "white",
    borderRight: "none",
    borderBottomColor: "1px solid #fff",
    background: "transparent",
    padding: "8px",
  },
  ulstyle: {
    width: "100%",
    textAlign: "left",
    listStyleType: "none",
    padding: 0,
  },
  listyle: {
    background: "#f0f8ff8a",
    padding: "1%",
    //  zindex: 10
  },
};

export default Searchclients;
