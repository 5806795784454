import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";

export default function TodayAppointments() {
  const [todyAppointment, setAppointments] = useState();
  const [appointments, setappointList] = useState([]);

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("doc_token"),
      },
    };

    axios.get("doctor/doctor-dashboard-details/", config).then((res) => {
      // console.log(JSON.stringify(res.data));
      setAppointments(res.data.todaysAppointmentsCount);
      setappointList(res.data.latestConsultation);
    });
  }, []);

  return (
    <div className="doctorcards lightgradient" style={styles.card}>
      <div style={styles.left}>
        <p>
          <strong style={{ whiteSpace: "nowrap", fontSize: "16px" }}>
            Todays Appointments
          </strong>
          <hr style={{ color: "#fff" }} />
        </p>
      </div>
      <div style={styles.right}>
        <div style={styles.innerflex}>
          <h2 style={styles.counter}>{todyAppointment}</h2>

          <div style={styles.rstyle}>
            {appointments.map((user) => (
              <>
                <div style={styles.rinner}>
                  <span style={{ fontSize: "14px" }}>Consultation</span>
                  <br />

                  <small style={styles.small}>{user.firstname}</small>
                  <small style={styles.small}>{user.time}</small>
                </div>
              </>
            ))}
          </div>
        </div>

        <Link to="/dappointment_today">
          <button style={styles.link} className="lightgradient">
            More <FontAwesomeIcon icon={faChevronCircleRight} />
          </button>
        </Link>
      </div>
    </div>
  );
}

const styles = {
  card: {
    display: "flex",
    flexDirection: "column",
    //background:'#4c003d',
    // backdropFilter: "blur(71px) brightness(-15%) opacity(.5)",
    width: "13.5rem",
    height: "13rem",
    padding: "5px 20px",
    borderRadius: "10px",
    marginLeft: "35%",
    marginTop: "10px",
    lineHeight: "10px",
  },
  link: {
    fontSize: "13px",
    textDecoration: "none",
    color: "#fff",
    // background: "rgb(121 56 120 / 76%)",
    padding: "10px",
    borderRadius: "10px",
    border: "none",
    cursor: "pointer",
    marginTop: "1rem",
  },

  left: {
    padding: "0px",
  },
  right: {
    padding: "0px",
  },
  counter: {
    color: "#fff",
    fontSize: "5rem",
    marginTop: "2rem",
  },

  label: {
    fontSize: "12px",
  },

  innerflex: {
    display: "flex",
    justifyContent: "space-between",
    height: "80px",
  },

  rstyle: {
    padding: "3px",
    display: "flex",
    flexDirection: "column",
  },

  rinner: {
    // background: "#764692",
    padding: "2px 20px",
    margin: "2px 0px",
    lineHeight: "12px",
    // borderRadius: "4px",
    borderBottom: "1px solid white",
  },

  small: {
    fontSize: "12px",
    margin: "0px 3px",
  },
};
