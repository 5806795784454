import React from "react";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";

import { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
export default function Banner() {
  const [Traker, setTraker] = useState("antenatal");
  const [SelectedFile, setSelectedFile] = useState();
  const [Items, setItems] = useState();
  const [Count, setCount] = useState(0);
  const [base, setbase] = useState("https://sukhprasavam.shebirth.com");
  const [showModal, setShowModal] = useState(false);
  const [selectedVideoId, setSelectedVideoId] = useState("");

  const openModal = (videoId) => {
    setSelectedVideoId(videoId);
    setShowModal(true);
  };

  const closeModal = () => {
    setSelectedVideoId("");
    setShowModal(false);
  };

  const deleteImage = (e) => {
    const headers = {
      Authorization: `token ${sessionStorage.getItem("token")}`,
    };
    console.log(headers);
    const Data = {
      id: e,
      //   // stage: "stage9",
      //   notes: notes,
    };
    axios
      .delete("https://sukhprasavam.shebirth.com/video/", {
        headers: headers,
        data: Data,
      })
      .then(() => {
        alert("success submited");
        // setRefresh(Refresh + 1);
      });
  };
  //   console.log(base + Items.image);
  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    // setIsSelected(true);
  };
  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (!token) {
      this.setState({ redirect: "/admin/login" });
    }

    axios
      .get(`video/`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Token ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setItems(res.data.data);
        setCount((Count = Count + 1));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [Count]);
  return (
    <div>
      <div style={styles.card} className="editform gradiantblur">
        <div style={styles.inner}>
          <table className="learning-table-form">
            {Items &&
              Items.map((ele) => {
                // {this.state.list &&
                //   this.state.list.map((ele) => {
                return (
                  <tr>
                    <td style={styles.td}>
                      {/* {base + ele.image} */}
                      <p>
                        {/* <a
                          href={`https://www.youtube.com/embed/${ele.link}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => {
                            e.preventDefault(); // Prevent the default link behavior
                            openModal(ele.link); // Open the video in the modal
                          }}
                        >
                          {ele.link}
                        </a> */}

                        <iframe
                          width="560"
                          height="315"
                          class="videoexerisise2"
                          src={ele.link}
                          frameborder="0"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                      </p>{" "}
                    </td>
                    <td>
                      {" "}
                      <button
                        style={styles.invisble2}
                        onClick={(e) => deleteImage(ele.id)}
                        // onClick={deleteImage(ele.id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                );
              })}
          </table>
        </div>
      </div>
      {/* {showModal && (
        <div className="modalbody">
          <div className="modal-inner">
            <YouTube
              videoId={selectedVideoId}
              opts={{ width: "100%", height: "400px" }}
            />
            <span className="close-icon" onClick={closeModal}>
              <i className="material-icons">close</i>
            </span>
          </div>
        </div>
      )} */}
    </div>
  );
}
const styles = {
  mibutton: {
    background: "#ffffff1c 0% 0% no-repeat padding-box",
    color: "white",
    textTransform: "inherit",

    borderRadius: "16px",
  },
  card: {
    display: "flex",
    justifyContent: "space-between",
    background: "#ffffff1c 0% 0% no-repeat padding-box",
    padding: "20px",
    borderRadius: "10px",
    margin: "10px",
  },
  inner: {
    textDecoration: "none",
    color: "#fff",
  },
  invisble2: { background: "none", border: "1px solid black" },

  input: {
    width: "40rem",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderBottomColor: "1px solid #fff",
    background: "transparent",
    padding: "8px",
    marginLeft: "30px",
    outline: "none",
    color: "white",
  },
};
