import React from "react";
import "./tabs.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
/*import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';*/
//import EditPersonalDetail from './innercom/edit_personal';
import AMan from "../img/man.jpg";
import axios from "axios";
import ASummery from "./innercom/open_summery";
import Reshedule from "./booking";
import Axios from "axios";

class Appointment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSummery: false,
      isShedule: false,
      upcoming: [],
      id: "",
      comleted: [],
    };
    this.openSummery = this.openSummery.bind(this);
    this.openShedule = this.openShedule.bind(this);
  }

  openSummery(e) {
    this.setState({ isSummery: !this.state.isSummery, id: e });
  }

  openShedule(e) {
    // console.log(e);
    this.setState({ isShedule: !this.state.isShedule, id: e });
  }
  componentDidMount() {
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("token"),
      },
    };

    axios.get("http://127.0.0.1:8000/appointments/upcoming-appointments/", config).then((res) => {
      console.log("upcoming", res.data);
      //   console.log("api called");
      //   res.data;
      this.setState({ upcoming: res.data });
    });
    axios.get("http://127.0.0.1:8000/appointments/completed-appointment/", config).then((res) => {
      console.log("complted", res.data);
      //   console.log("api called");
      //   res.data;
      this.setState({ comleted: res.data });
    });
  }

  render() {
    return (
      <>
        {console.log(this.state.id)}
        {this.state.isShedule ? <Reshedule user={this.state.id} /> : null}
        {this.state.isSummery ? (
          <ASummery user={this.state.id} />
        ) : (
          <div style={styles.card}>
            <div style={styles.inner}>
              <Tabs>
                <TabList>
                  <Tab>
                    <strong style={{ padding: "10px", cursor: "pointer" }}>
                      Upcoming
                    </strong>
                  </Tab>
                  <Tab>
                    <strong style={{ padding: "10px", cursor: "pointer" }}>
                      Completed
                    </strong>
                  </Tab>
                </TabList>

                <TabPanel>
                  <div className="appointment-tabs ">
                    {this.state.upcoming.map((e) => (
                      <div style={styles.upcom} className="gradiantblur">
                        {e.doctor_profile_pic ? (
                          <img
                            src={e.doctor_profile_pic}
                            alt=""
                            style={styles.manpic}
                          />
                        ) : (
                          <img src={AMan} alt="" style={styles.manpic} />
                        )}
                        <div className="appointment-inner">
                          <div className="aleft">
                            <div>
                              <strong>
                                Dr. {e.doctor_firstname} {e.doctor_lastname}{" "}
                              </strong>
                              <br />
                              <small style={styles.info}>
                                {e.doctor_qualification}
                                <br />
                                <span>
                                  {e.doctor_experience} years of experience
                                </span>{" "}
                              </small>
                              <br />
                            </div>
                            <div
                              style={{
                                textAlign: "left",
                                padding: "5px",
                                background:
                                  "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
                              }}
                            >
                              <strong>Slot -{e.time}</strong>
                              <br />
                              <strong>Date - {e.date}</strong>
                            </div>
                          </div>
                          <div style={{}}>
                            <a
                              href={e.meeting_url}
                              className="sbtn"
                              style={styles.rbtn2}
                              // onClick={() => this.openShedule(e.id)}
                              //   onClick={this.openShedule}
                            >
                              join
                            </a>
                          </div>
                          <div
                            className="reshedulediv"
                            style={{ textAlign: "right", marginRight: "5%" }}
                          >
                            <button
                              className="sbtn"
                              style={styles.rbtn}
                              onClick={() => this.openShedule(e.id)}
                              //   onClick={this.openShedule}
                            >
                              Reshedule
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </TabPanel>

                <TabPanel>
                  <div className="appointment-tabs " style={styles.innerscroll}>
                    {this.state.comleted.map((e) => (
                      <div style={styles.upcom} className="gradiantblur">
                        {e.doctor_profile_pic ? (
                          <img
                            src={e.doctor_profile_pic}
                            alt=""
                            style={styles.manpic}
                          />
                        ) : (
                          <img src={AMan} alt="" style={styles.manpic} />
                        )}
                        <div className="appointment-inner">
                          <div className="aleft">
                            <div>
                              <strong>Dr. {e.doctor} </strong>
                              <br />
                              <small style={styles.info}>
                                {e.qualification}
                                <br />
                                <span>
                                  {e.experience} years of experience
                                </span>{" "}
                              </small>
                              <br />
                            </div>
                            <div
                              style={{
                                textAlign: "left",
                                padding: "5px",
                                background:
                                  "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
                              }}
                            >
                              <strong>Slot -{e.time}</strong>
                              <br />
                              <strong>Date - {e.date}</strong>
                            </div>
                          </div>

                          <div style={{ textAlign: "right" }}>
                            <button
                              className="sbtn"
                              style={styles.sbtn}
                              onClick={() => this.openSummery(e.id)}
                              //   onClick={this.openSummery}
                            >
                              summary
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                    {/* <div style={styles.upcom}>
                      <img src={AMan} alt="" style={styles.manpic} />
                      <div className="appointment-inner">
                        <div className="aleft">
                          <div>
                            <strong>Dr. Avinash Nambihar</strong>
                            <br />
                            <small style={styles.info}>
                              Paediatrician <span>11 years of experience</span>{" "}
                            </small>
                            <br />
                          </div>
                          <div
                            style={{
                              textAlign: "left",
                              padding: "5px",
                              background: "#651c6a",
                            }}
                          >
                            <strong>Slot -9.00-9.30</strong>
                            <br />
                            <strong>Date - 09/08/2021</strong>
                          </div>
                        </div>

                        <div style={{ textAlign: "right" }}>
                          <button
                            className="sbtn"
                            style={styles.sbtn}
                            onClick={this.openSummery}
                          >
                            Summery
                          </button>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        )}
      </>
    );
  }
}

const styles = {
  card: {
    // display: "flex",
    justifyContent: "space-between",
    //background:'#9038a3',
    background: "transparent",
    height: "500px",
    // overflowX: "scroll",
    padding: "20px",
    borderRadius: "10px",
    margin: "10px",
  },

  inner: {
    textDecoration: "none",
    color: "#fff",
  },
  innerscroll: { overflowX: "scroll", height: "470px" },

  info: {
    fontSize: "12px",
  },

  editbtn: {
    border: "1px solid #fff",
    background: "transparent",
    padding: "5px 10px",
    color: "#fff",
    cursor: "pointer",
    borderRadius: "7px",
  },

  manpic: {
    width: "80px",
    height: "80px",
    borderRadius: "50%",
    marginRight: "10px",
  },

  rbtn: {
    background: "#e81a7f",
    color: "#fff",
    padding: "7px 40px",
    border: "none",

    margin: "10px",

    borderRadius: "5px",
    cursor: "pointer",
    // right: "24%",
    /* top: 20px; */
    // bottom: "15%",
    margin: "auto",
    // position: "absolute",
  },
  rbtn2: {
    background: "#e81a7f",
    color: "#fff",
    padding: "0px 40px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  sbtn: {
    background: "#00ACC8",
    color: "#fff",
    padding: "7px 40px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  // sbtn2: {
  //   background: "#00ACC8",
  //   color: "#fff",
  //   padding: "0px 40px",
  //   border: "none",
  //   borderRadius: "5px",
  //   cursor: "pointer",
  // },

  upcom: {
    marginBottom: "2rem",
    display: "flex",
    justifyContent: "space-between",
    width: "auto",
    // background: "#9038a3",
    padding: "30px",
  },
};

export default Appointment;
