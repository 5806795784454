//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown, faSort } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import axios from "axios";
import DailyTracker from "./traker_by_date/table_list";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import SearchClients from "./search";
import ResheduleDialog from "./notes";
import ClientProfile from "./client_profile";

export default function AllClientsTable() {
  const { id } = useParams();
  const [calls, setcalls] = useState([]);
  const [userId, setUserId] = useState();
  const [notes, setnotes] = useState(null);
  const [showResults, setShowResults] = React.useState(true);
  const hideShow = () => setShowResults(false);
  const [search, setSearch] = useState(null);
  const [shedule, setShedule] = useState(false);
  const [client, setClient] = useState(false);
  const [traker, settraker] = useState(false);
  const [background, setBackground] = useState("white");
  const history = useHistory();

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("doc_token"),
      },
    };

    axios
      .get(`/sales/get-all-call-responses/?customer=` + id, config)

      .then((res) => {
        console.log(res.data);
        setcalls(res.data);
        //console.log(res.data);0
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  function handleBackground(e) {
    setBackground(e.target.value);
  }

  function openShedule() {
    setShedule(!shedule);
  }

  function openClinetProfile(id) {
    setUserId(id);
    setClient(!client);
  }
  function opennote(id) {
    setnotes(id || "no notes added today"); // Set the "notes" state to the clicked user's note, or 1 as a default

    settraker(!traker);
  }
  function closeNotes() {
    setnotes(null); // Set the "notes" state to null to hide the notes section
  }

  return (
    <>
      {notes ? (
        <div style={styles.card2} className="gradiantblur">
          <i
            className="fa fa-times close1"
            onClick={closeNotes}
            aria-hidden="true"
          ></i>

          <strong style={styles.cardheader}>Notes</strong>

          <form>
            <div style={{ padding: "20px" }}>
              <div style={styles.disc} className="gradiantblur">
                <textarea
                  className="gradiantblur"
                  style={{
                    width: "100%",
                    height: "200px",
                    color: "white",
                    backdropFilter: "blur(10px)",
                    backgroundColor: "rgba(255, 255, 255, 0.14)",
                  }}
                  defaultValue={notes}
                ></textarea>
              </div>
            </div>
          </form>
        </div>
      ) : null}
      <>
        <div className="all-clients-table" style={styles.card}>
          <div style={styles.inner}>
            <p>
              <strong>Call status of clients</strong>
            </p>
            <hr style={{ color: "#fff" }} />

            <div className="table-cover">
              <table
                style={{
                  borderCollapse: "separate",
                  width: "41.6rem",
                  borderSpacing: "0 1em",
                }}
              >
                <thead>
                  <tr style={styles.tr}>
                    <td>date</td>
                    <td>didn't call</td>
                    <td>didn't respond</td>
                    <td>called</td>
                    <td>notes</td>
                  </tr>
                </thead>
                <tbody>
                  {console.log(calls)}
                  {calls
                    .slice(0)
                    .reverse()
                    .map((user, index) => (
                      <tr key={index} style={styles.tr}>
                        <td>{user.date}</td>
                        <td>
                          <input
                            className="custom-checkbox"
                            type="checkbox"
                            checked={user.callResponse === "didn't called"}
                            disabled
                          />
                        </td>
                        <td>
                          <input
                            className="custom-checkbox"
                            type="checkbox"
                            checked={user.callResponse === "didn't respond"}
                            disabled
                          />
                        </td>
                        <td>
                          <input
                            className="custom-checkbox"
                            type="checkbox"
                            checked={user.callResponse === "called"}
                            disabled
                          />
                        </td>
                        <td>
                          <button
                            onClick={() => opennote(user.note)}
                            style={styles.sbtn}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    </>
  );
}

const styles = {
  red: { background: "red", border: "1px solid red", padding: "8% 30%" },
  green: { background: "green", border: "1px solid green", padding: "8% 30%" },
  yellow: {
    background: "yellow",
    border: "1px solid yellow",
    padding: "8% 30%",
  },
  card: {
    // display: "flex",
    // justifyContent: "space-between",
    backgroundColor: "#ffffff1c 0% 0% no-repeat padding-box",
    // padding: "20px",
    // borderRadius: "10px",
    // margin: "10px",
  },
  // inner: {
  //   textDecoration: "none",
  //   color: "#fff",
  // },

  input: {
    width: "40rem",
    borderTop: "none",
    borderLeft: "none",
    color: "white",
    borderRight: "none",
    borderBottom: "1px solid rgb(255 255 255 / 39%)",
    background: "transparent",
    padding: "8px",
  },
  card: {
    // display: "flex",
    justifyContent: "space-between",
    // backdropFilter: "blur(31px) brightness(-15%) opacity(.3)",
    padding: "0px",
    borderRadius: "10px",
    margin: "10px",
  },
  inner: {
    textDecoration: "none",
    color: "#fff",
    width: "100%",
    margin: "10px 10px 5% 10px",
  },

  sortit: {
    background: "#ffffff1c 0% 0% no-repeat padding-box",
    margin: "10px",
    borderRadius: "10px",
    textAlign: "left",
    padding: "10px",
  },

  option: {
    background: "#ffffff1c 0% 0% no-repeat padding-box",
    border: "none",
    appearance: "none",
    padding: "1%",
    color: "#fff",
  },

  tolink: {
    background: "#60124D",
    textDecoration: "none",
    padding: "5px",
    color: "#fff",
  },

  sbtn: {
    // background: "#E81A7F",
    // border: "1px solid #E81A7F",
    padding: "5% 25%",
  },

  tr: {
    margin: "10px auto",
    background: "#ffffff1c 0% 0% no-repeat padding-box",
    // background: "#772068",
    cursor: "pointer",
  },

  card2: {
    backdropFilter: "blur(16px)",
    backgroundColor: "rgba(255, 255, 255, 0.14)",

    display: "flex",
    position: "absolute",
    width: "650px",

    justifyContent: "space-between",

    padding: "0px",
    borderRadius: "10px",
    margin: "10px",
    flexDirection: "column",
    zIndex: "9",
  },

  inner: {
    textDecoration: "none",
    color: "#fff",
  },

  info: {
    fontSize: "12px",
  },

  hr: {
    color: "#fff",
  },

  cardheader: {
    // background: "#55005C",
    textAlign: "center",
    padding: "10px 8px",
    width: "auto",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    borderBottom: "1px solid gray",
  },

  manpic: {
    width: "80px",
    height: "80px",
    borderRadius: "50%",
    marginRight: "10px",
  },

  upcom: {
    display: "flex",
    justifyContent: "space-between",
    width: "20rem",
    padding: "30px",
  },

  disc: {
    borderRadius: "10px",
    padding: "10px",
    textAlign: "left",
    // background: "#A460B4",
  },
};
