import React from "react";
//import PropTypes from 'prop-types';
import axios from "axios";
import DietVideo from "../../videos/video.mp4";

export default class NaturalLearning extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: true, items: [], notes: [], video: [] };
    this.setWrap = this.setWrap.bind(this);
    this.clickedOutside = this.clickedOutside.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ notes: event.target.value });
    // console.log(this.state.notes);
  }

  handleSubmit(event) {
    console.log(this.state.video);
    //  consolethis.state.items.video;
    var notes = this.state.notes;
    const Data = {
      module: "NATURAL COPING",
      // stage: "stage9",
      notes: notes,
    };
    const headers = {
      //"Content-Type": "application/x-www-form-urlencoded",

      Authorization: `token ` + localStorage.getItem("token"),

      //Authorization: "JWT fefege...",
    };

    axios
      .post("learning/add-notes/", Data, {
        headers: headers,
      })
      .then(() => {
        // setincrement([...increment,res.data])
        console.log("submited ");
        //window.location.reload();
      })
      .catch((error) => {
        console.log("handlesubmit error for blog ", error);
      });

    event.preventDefault();
    alert("notes was submitted");
    event.preventDefault();
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.clickedOutside);

    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("token"),
      },
    };

    axios
      .get("learning/get-module-data/?module=NATURAL COPING", config)
      .then((res) => {
        console.log(res.data);
        this.setState({ items: res.data.note });
        this.setState({ video: res.data.video });
      });
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.clickedOutside);
  }

  setWrap(node) {
    this.isWrp = node;
  }

  clickedOutside(e) {
    if (this.isWrp && !this.isWrp.contains(e.target)) {
      this.setState({ isOpen: false });
    }
  }

  render() {
    return (
      <>
        <div>
          <div className="note-container" style={styles.cover}>
            <div style={styles.pcover}>
              <iframe
                width="1000px"
                height="600px"
                src={this.state.video.url}
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            {/* <video
                //src={this.state.video.url}
                width="600rem"
                height="auto"
                controls
              ></video> */}

            <h4>Add Your Notes Here</h4>
            <div>
              <form onSubmit={this.handleSubmit}>
                <div className="outerdivnotes">
                  <textarea
                    //style={styles.p}
                    onChange={this.handleChange}
                    defaultValue={this.state.items.notes}
                    style={styles.textstyle}
                  >
                    {this.state.items.notes}
                  </textarea>

                  <input className="pinkbutton" type="submit" value="Submit" />
                </div>
              </form>
            </div>

            <h4>Frequently Asked Questions</h4>

            <p style={styles.p}>{this.state.video.faq}</p>

            <h4>Related Post</h4>

            <p style={styles.p}>{this.state.video.relatedPost}</p>
          </div>
        </div>
      </>
    );
  }
}

const styles = {
  cover: {
    height: "600px",
    overflowY: "scroll",
    scrollbarWidth: "none",
    width: "600px",
    margin: "0px 30px",
    padding: "0px",
    //  background: "#ffffff1c 0% 0% no-repeat padding-box",
    textAlign: "left",
  },

  textstyle: {
    width: "99%",
    background: "none 0% 0% repeat scroll rgb(76 0 61 / 55%)",
    maxheight: "250px",
    minheight: "100px",
    margin: "0",
    fontSize: "12px",
    background: "none 0% 0% repeat scroll rgb(76 0 61 / 55%)",
    // background: "rgb(76, 0, 61) none repeat scroll 0% 0%",
    // padding: "1rem",
    border: "20px solid rgb(123 52 111 / 0%)",
    borderRadius: "5px",
  },

  p: {
    fontSize: "12px",
    background: "none 0% 0% repeat scroll rgb(76 0 61 / 55%)",
    // background: "rgb(76, 0, 61) none repeat scroll 0% 0%",
    padding: "1rem",
    border: "20px solid rgb(123 52 111 / 0%)",
    borderRadius: "5px",
  },
  pcover: {
    padding: "1rem",
    // background: "#7b346f",
  },
};
