import React from "react";
import AddActivities from "./innercom/add_activity";
import moment from "moment";
import { withAlert } from "react-alert";
import axios from "axios";
class ActivityTracker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activitiesOpen: true,
      dateState: [],
      activitiesReady: false,
      items: [],
      custom: [],
      today: "",
    };
    this.closeMedicine = this.closeMedicine.bind(this);
    this.addActivities = this.addActivities.bind(this);
    this.handleCheckClick = this.handleCheckClick.bind(this);
    // this.addMedicine = this.addMedicine.bind(this);
  }

  closeMedicine() {
    this.setState({ activitiesOpen: !this.state.activitiesOpen });
  }

  //   addMedicine() {
  //     this.setState({ medicineReady: !this.state.medicineReady });
  //   }

  addActivities() {
    this.setState({ activitiesReady: !this.state.activitiesReady });
  }
  dateHandler = (e) => {
    var dateselected = e.target.value;

    var date = moment(e.target.value).format("YYYY-MM-DD");
    console.log(date);
    this.setState({ dateState: date }, this.getData.bind(this));
  };

  //   async componentDidUpdate(prevProps, prevState){
  //   if(this.mounted && this.state.isSubmitted){
  //      const allData = await this.props.getData();
  //      this.setState({isSubmitted: false,
  //                   allData: allData.data })
  //   }

  // // componentDidMount(){
  // //    this.mounted=true
  // // }
  componentWillMount() {
    this.getData();
  }
  getData() {
    if (localStorage.getItem("token") === null) {
      localStorage.clear();
      window.location = "/clogin";
    }
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("token"),
      },
    };
    const date = moment(this.state.dateState).format("YYYY-MM-DD");
    this.setState({ today: date });
    axios
      .get("customer/get-activity-tasks/?date=" + date, config)
      .then((res) => {
        // let ytUrl = res.data.url;
        // // replace:
        // let newurl = ytUrl.replace("/watch?v=", "/embed/");
        //console.log(res.data.predefined.sub_module);
        // this.setState({ url: newurl });

        this.setState({ items: res.data.predefined });
        this.setState({ custom: res.data.custom });

        // this.setState({ custom: res.data.custom });
      });
  }

  handleCheckClick = (e, stateVal, i, j, alert) => {
    // console.log(index);
    //modifying the state based on chec value

    //
    let prevState = [...this.state[stateVal]];
    prevState[i].sub_module[j].completed = e.target.checked;
    // prevState[index].completed = e.target.checked;

    this.setState({ [stateVal]: prevState });
    var date = moment(this.state.dateState).format("YYYY-MM-DD");
    const { id, checked } = e.target.dataset;

    if (e.target.checked) {
      var checkbox = "True";
    } else {
      var checkbox = "False";
    }

    const Data = {
      completed: checkbox,
      date: date,
      id: id,
    };
    const headers = {
      Authorization: `token ` + localStorage.getItem("token"),
    };
    axios
      .post("customer/submit-activity/", Data, {
        headers: headers,
      })
      .then(() => {
        alert.show("Activity was submitted");
      })
      .catch((error) => {
        alert.show("Cannot add Activity again");
      });
  };

  custumhandleCheckClick = (e, stateVal, index, alert) => {
    //modifying the state based on chec value

    //
    let prevState = [...this.state[stateVal]];

    prevState[index].completed = e.target.checked;
    console.log(prevState);
    this.setState({ [stateVal]: prevState });
    var date = moment(this.state.dateState).format("YYYY-MM-DD");
    const { id, checked } = e.target.dataset;

    if (e.target.checked) {
      var checkbox = "True";
    } else {
      var checkbox = "False";
    }

    const Data = {
      completed: checkbox,
      date: date,
      activity: id,
    };
    const headers = {
      Authorization: `token ` + localStorage.getItem("token"),
    };
    axios
      .post("customer/submit-custom-activity/", Data, {
        headers: headers,
      })
      .then(() => {
        alert.show("Activity was submitted");
      })
      .catch((error) => {
        alert.show("Activity add symptom again");
      });
  };

  render() {
    const alert = this.props.alert;
    return (
      <>
        <div className="clientscroll homeheight500 ">
          <div className="module-wrapper" style={styles.module}>
            <h2
              style={{
                borderBottom: "1px solid #fff",
                padding: "10px",
                margin: "0px",
                fontSize: "24px",
                background: "#ffffff1c 0% 0% no-repeat padding-box",
              }}
            >
              Activity Tracker
            </h2>
            <div className="defult" style={styles.numorphismoneside}>
              <div className="activity">
                <span style={{ fontSize: "16px" }}> Enter Date</span>
                <input
                  type="date"
                  className="calanderbutton"
                  onChange={this.dateHandler}
                  defaultValue={this.state.today}
                  //Value={pureDate[1]}
                />
              </div>
            </div>
            <div className="defult" style={styles.numorphismoneside}>
              {console.log(this.state.items)}
              {this.state.items.map((personData, i) => {
                return (
                  <>
                    {" "}
                    <div className="activity">
                      <div className="inneractivity" style={styles.gridstyle}>
                        <div style={styles.txt}>
                          <h5>{personData.mainModule}</h5>
                          <div
                            style={{
                              width: "12rem",
                              background:
                                "#ffffff1c 0% 0% no-repeat padding-box",
                              padding: "10px",
                            }}
                          >
                            <p style={styles.pstyle}>Description</p>

                            <div
                              style={{
                                padding: "10px",
                                background: "rgb(126 65 118 / 75%)",
                              }}
                            >
                              <p style={styles.pstyle}>
                                {personData.description}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div style={{ padding: "10px" }}>
                          <div
                            className="aflex-inner"
                            style={styles.gridstyleinner}
                          >
                            {personData.sub_module.map((subModule, j) => {
                              return (
                                <div j={subModule.id}>
                                  <p style={styles.pstyle}>
                                    {" "}
                                    {subModule.subModuleName}
                                  </p>
                                  <input
                                    type="checkbox"
                                    data-id={subModule.id}
                                    onChange={(e) =>
                                      this.handleCheckClick(
                                        e,
                                        "items",
                                        i,
                                        j,
                                        alert
                                      )
                                    }
                                    checked={subModule.completed && subModule}
                                    // onChange={this.handleCheckClick}
                                  />
                                </div>
                              );
                            })}
                          </div>
                          <div className="activityUrl" style={styles.urlstyle}>
                            <a href={personData.url} target="_blank">
                              {personData.url}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
            <div style={styles.numorphism}>
              <div style={styles.txt}>
                <h5>User Defined Activities</h5>
              </div>
              <table style={{}}>
                <tr className="exereriscegrid">
                  {this.state.custom.map((personData, key) => {
                    return (
                      <>
                        <td key={personData.id}>{personData.name}</td>
                        <td>
                          <input
                            type="checkbox"
                            data-id={personData.id}
                            // checked={subModule.completed && subModule}
                            onChange={(e) =>
                              this.custumhandleCheckClick(
                                e,
                                "custom",
                                key,
                                alert
                              )
                            }
                            // onChange={this.custumhandleCheckClick}
                            checked={personData.completed && personData}
                          />
                        </td>
                      </>
                    );
                  })}
                </tr>
              </table>
            </div>
          </div>
          <br />

          {this.state.activitiesReady ? <AddActivities /> : null}
          <button
            onClick={this.addActivities}
            className="pinkbutton"
            style={{ cursor: "pointer" }}
          >
            Add User Defined Activities
          </button>
          {/* {this.state.activitiesReady ? <AddActivities /> : null}
            <span>
              {" "}
              onClick={this.addActivities} Add User Defined Activities
            </span> */}
          <hr style={{ color: "#fff" }} />
          <div>
            <button type="submit" className="pinkbutton" style={styles.sbtn}>
              Submit
            </button>
          </div>
        </div>
      </>
    );
  }
}
export default withAlert()(ActivityTracker);
const styles = {
  pstyle: { margin: "0", fontSize: "16px" },
  numorphismoneside: {
    borderRadius: "0px 0 50px 50px",
    /* background: #4c003d; */
    // padding: "2%",
    // background: "#ffffff1c 0% 0% no-repeat padding-box",
  },
  numorphism: {
    display: "grid",
    padding: "1rem",
    gridTemplateColumns: "2fr 5fr",
    // borderRadius: "50px",

    marginTop: "5%",

    padding: "2%",
    background: "#ffffff1c 0% 0% no-repeat padding-box",
  },
  urlstyle: {
    fontSize: "14px",
    overflow: "auto",
    width: "200px",
    background: " #9f539a",

    float: "right",
  },
  gridstyle: {
    display: "grid",

    gridTemplateColumns: "1fr 2fr",
  },

  gridstyleinner: {
    marginTop: "5%",
    display: "grid",

    gridTemplateColumns: "1fr 1fr 1fr 1fr",
  },
  module: {
    height: "550px",
    overflowY: "scroll",
    scrollbarWidth: "none",

    position: "relative",
    // background: "rgb(76, 0, 61) none repeat scroll 0% 0%",
    margin: "0px 30px",
    borderRadius: "10px",
    padding: "0px 0px 20px 0px",
  },
  txt: {
    textAlign: "left",
    margin: "auto",
  },
  btn: {
    border: "1px solid #974cae",
    padding: "5px 30px",
    background: "#9f539a",
    fontSize: "12px",
    marginTop: "1rem",
  },

  sbtn: {
    // background: "linear-gradient(90deg, #3a51a7 30%, #b53899)",
    padding: "10px 30px",
    border: "none",
    borderRadius: "10px",
    color: "#fff",
    cursor: "pointer",
    margin: "0px 5px",
  },
};
