import { withRouter } from "react-router";
import "../../App.css";
import LoginGirl from "../img/login_girl.png";
import Form from "./form";
import React, { useState } from "react";
import "./login.css";
import slogo from "../img/sukhprasavamlogo.png";
import { Helmet } from "react-helmet";

const ClientLogin = () => {
  const [showForm, setShowForm] = useState(false);

  const handleLogin = () => {
    setShowForm(true);
  };

  return (
    <>
      <Helmet>
        <title>Sukhprasavam</title>
        <meta
          name="description"
          content="Welcome to Sukh Prasavam-A Customised Pregnancy Support Care! We understand that every pregnancy is unique and may require individualized care and support. That's why we offer personalized services to meet your specific needs and help you have a healthy and safe  pregnancy calculator."
        />
        <meta
          name="keywords"
          content="things to avoid early pregnancy,pregnancy calculator, ovulation calculator, pregnancy symptoms, due date calculator, health, safe, Sukh Prasavam, Sukhprasavam"
        />
      </Helmet>
      <div className="newbg">
        <div style={{ padding: "10% 0%" }}>
          <div className="login-wrapper" style={styles.flex}>
            <div
              style={styles.content}
              className="flexdisplay mobile-view-hidden"
            >
              <h1 className="margin50 gilroy"> Launching </h1>
              <img className="slogo" src={slogo} />
            </div>
            <div
              className="loginimage"
              style={{
                color: "#fff",
              }}
            >
              <img src={LoginGirl} alt="" style={styles.imagep} />
            </div>
            {showForm ? (
              <>
                <Form />
              </>
            ) : (
              <>
                <div className="margin-auto">
                  {" "}
                  <button
                    type="button"
                    className="hidepc"
                    onClick={handleLogin}
                    style={{
                      background: "none",
                      border: "1px solid #fff",
                      color: "#fff",
                      width: "149px",
                      height: "37px",
                      borderRadius: "4px",
                      cursor: "pointer",
                      margin: "2%",
                    }}
                  >
                    Login
                  </button>
                </div>
                <div
                  className="form-wrapper"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    color: "#fff",
                    padding: "20px",
                    width: "60%",
                    borderRadius: "20px",
                    boxShadow: "rgb(0 0 0 / 16%) 12px 10px 60px",
                    backdropFilter: "blur(39px)",
                    background: "rgba(0, 0, 0, 0.16)",
                  }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {/* <h1 className="fontsize50" style={{ margin: "0" }}>
                    sukh prasavam
                  </h1>{" "}
                  <button
                    type="button"
                    style={{
                      background: "none",
                      border: "1px solid #fff",
                      color: "#fff",
                      width: "102px",
                      borderRadius: "4px",
                      cursor: "pointer",
                      margin: "2%",
                    }}
                    onClick={() => setShowForm(false)}
                  >
                    Back
                  </button>
                </div> */}
                    <h1 className="  fontsize50" style={{ margin: "0" }}>
                      sukh prasavam
                    </h1>{" "}
                    <button
                      className="hidemboile"
                      type="button"
                      onClick={handleLogin}
                      style={{
                        background: "none",
                        border: "1px solid #fff",
                        color: "#fff",
                        width: "102px",
                        borderRadius: "4px",
                        cursor: "pointer",
                        margin: "2%",
                        position: "relative",
                        top: "-85px",
                      }}
                    >
                      Login
                    </button>
                  </div>
                  <div />
                  <h2> pleasing birth without fear</h2>{" "}
                  <h6>Our customized pregnancy support services include:</h6>
                  <p>
                    1. <strong>Prenatal Education and Counseling:</strong>
                    <br />
                    We provide individualized prenatal education and counseling
                    sessions to help you prepare for labor and delivery,things
                    to avoid early pregnancy, breastfeeding, newborn care, and
                    postpartum recovery. We will work with you to create a
                    personalized plan that meets your specific needs and
                    preferences.
                  </p>
                  <p>
                    2. <strong>Nutrition and Exercise Counseling:</strong>
                    <br />
                    We offer personalized nutrition and exercise counseling to
                    help you maintain a healthy weight, manage pregnancy-related
                    conditions such as gestational diabetes and high blood
                    pressure, and promote fetal development. Our registered
                    dietitian and certified fitness professionals will work with
                    you to develop a customized plan that fits your lifestyle
                    and goals.
                  </p>
                  <p>
                    3. <strong>High-Risk Pregnancy Care:</strong>
                    <br />
                    If you have a high-risk pregnancy, we offer specialized care
                    and monitoring to ensure the best possible outcome for you
                    and your baby. Our team of experienced maternal-fetal
                    medicine specialists will work with you to develop a
                    personalized plan of care that meets your specific needs and
                    reduces the risks associated with your condition.
                  </p>
                  <p>
                    4. <strong>Emotional Support:</strong>
                    <br />
                    Pregnancy can be an emotional rollercoaster, and we
                    understand that sometimes you may need extra support. We
                    offer individual and group counseling sessions to help you
                    manage stress, anxiety, and depression during pregnancy and
                    postpartum. Our licensed mental health professionals will
                    work with you to develop coping strategies how to avoid c
                    section delivery,and provide emotional support throughout
                    your pregnancy journey.
                  </p>
                  <p>
                    5. <strong>Labor and Delivery Support:</strong> (Product
                    Under Development)
                    <br />
                    At our customised pregnancy support program, we are
                    committed to providing personalized care and support to help
                    you have a healthy and safe pregnancy. Contact us today to
                    learn more about our services and schedule a free
                    consultation with our team.
                  </p>
                </div>
              </>
            )}
          </div>
          {showForm ? (
            <>
              <div style={styles.content} className="pc-view-hidden">
                <h1 className="margin50 fontsize50">sukh prasavam</h1>
                <h2> pleasing birth without fear</h2>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default ClientLogin;

const styles = {
  content: {
    color: "white",
    // display: "flex",
    textAlign: "center",
    justifyContent: "center",
    margin: "30px auto",
  },

  imagep: { width: "100%" },
  flex: {
    display: "flex",
    justifyContent: "space-between",
    width: "70%",
    margin: "auto",
  },
};
