import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMugHot,
  faHamburger,
  faGlassMartini,
  faUtensils,
  faBlender,
  faCookie,
  faWineBottle,
  faArrowCircleDown,
  faArrowCircleLeft,
  faArrowRight,
  faRetweet,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Calendar from "../calender/calendar"
import "react-calendar/dist/Calendar.css";
import moment from "moment";

import { useForm } from "react-hook-form";
import React, { useState, useEffect, useCallback } from "react";
import Moment from "moment";
import { Breadcrumb } from "react-bootstrap";
import { responsiveFontSizes } from "@material-ui/core";
import FoodCalorieTable from "./cal_ml_details_button";
const qs = require("qs");
export default function Diettracker(props) {
  const [dataearly,setdataearly]=useState([]);
  const[databreak,setdatabreak]=useState([]);
  const[datalunch,setdatalunch]=useState([]);
  const[datadrink,setdatadrink]=useState([]);
  const[datadinner,setdatadinner]=useState([]);
  const[datamid,setdatamid]=useState([]);
  const[dataafter,setdataafter]=useState([]);
  const [placeholder, setplaceholder] = useState('')
  const [breakfastplaceholder, setbreadfastplaceholder] = useState('');
  const [lunchplaceholder, setlunchplaceholder] = useState('');
  const [midplaceholder, setmidplaceholder] = useState('');
  const [afterplaceholder, setafterplaceholer] = useState('');
  const [dinnerplaceholder, setdinnerplaceholder] = useState('');
  const [drinkplaceholder, setdrinkplaceholder] = useState('');
  const [search, setsearch] = useState('');
  const [calsum,setcalsum] = useState([]);
  const [breakfast, setbreakfast] = useState({});
  const [earlymoring, setearlymoring] = useState({});
  const [midday, setmidday] = useState({});
  const [afternoon, setafternoon] = useState({});
  const [Dinner, setDinner] = useState({});
  const [dinnerdrink, setdinnerdrink] = useState({});
  const [allValues, setAllValues] = useState({});
  const [lunch, setlunch] = useState({});
  const [alertbox,setalert]=useState(false);
  const[cal,setcal]=useState();

  const [value] = useState(new Date());
  const [show, setTracker] = useState(false);

  const [dateState, setDateState] = useState(new Date());

  var date = moment(dateState.date).format("YYYY-MM-DD");
  const changeDate = (e) => {
    setDateState(e);
  };

  const dateHandler = (e) => {
    setDateState({ ...dateState, [e.target.name]: e.target.value });
  };
  function Refresh(){
    getcalorie()
  }
  function changeHandler(e,b) {
    var p=b
    setAllValues({ ...allValues, [e.target.name]: e.target.value });
    let val = e.target.value;
    
    setsearch(val)
    axios.get('customer/cal-data?query=' + val).then(e => {
      
      
      if(p===1){
        setdataearly(e.data)
      }
      if(p===2){
        setdatabreak(e.data)
      }
      if(p===3){
        setdatamid(e.data)
      }
      if(p===4){
        setdatalunch(e.data)
      }
      if(p===5){
        setdataafter(e.data)
      }
      if(p===6){
        setdatadinner(e.data)
      }
      if(p===7){
        setdatadrink(e.data)
      }
   
    })



  };
  const timechangehandler = (e) => {
    setAllValues({ ...allValues, [e.target.name]: e.target.value });
  };
  // const handleSubmit = (event) => {};

  const submitValue = (e) => {
    e.preventDefault();
    //setAllValues({ ...allValues, [e.target.name]: e.target.value });

    //new.................................................................
    const formData = new FormData(e.currentTarget);

    var object = {};
    formData.forEach(function (value, key) {
      object[key] = value;

    });
    var json = JSON.stringify(object);
    //console.log(json);
    formData.append("data", json);

    const headers = {
      Authorization: `token ` + localStorage.getItem("postclient"),
    };

    axios
      .post("http://127.0.0.1:8000/customer/diet-tracker-add-POST/", formData, {
        headers: headers,
      })
      .then(() => {
        setalert(true);
        const timeout = setTimeout(() => {
          setalert(false);
        }, 3000);
    
        // Clean up the timeout to avoid memory leaks
        return () => clearTimeout(timeout);
        

      })
      .catch((error) => {
        alert(JSON.stringify(error.response.data));
      });
      
  };
  
  function getcalorie() {

    const headers = {
      Authorization: `token ` + localStorage.getItem("postclient"),
    };
    axios.get('http://127.0.0.1:8000/customer/cal-sum/', {
      headers: headers,
    })
      .then((re) => { setcalsum(re.data.total_calories) })
  }


  useEffect(() => {
    
    
    const headers = {
      Authorization: `token ` + localStorage.getItem("postclient"),
    };

    axios
      .get(
        "http://127.0.0.1:8000/customer/diet-tracker-GET/?date=" + date,
        {
          headers: headers,
        }

        // config
      )
      .then((res) => {
        console.log('data',res.data)

        let obj = res.data.Diet.find((o) => o.mealName === "lunch");
        let breakfast = res.data.Diet.find((o) => o.mealName === "breakfast");
        let earlymorning1 = res.data.Diet.find(
          (o) => o.mealName === "early morning"
        );
        let dinner = res.data.Diet.find((o) => o.mealName === "dinner");
        let afternoonsnack = res.data.Diet.find(
          (o) => o.mealName === "afternoon snack"
        );
        let middaysnack = res.data.Diet.find(
          (o) => o.mealName === "mid day snack"
        );
        let dinnerdrink = res.data.Diet.find(
          (o) => o.mealName === "dinner drink"
        );

        setearlymoring(earlymorning1);
        setDinner(dinner);
        setafternoon(afternoonsnack);
        setmidday(middaysnack);
        setdinnerdrink(dinnerdrink);

        setbreakfast(breakfast);
        setlunch(obj);
      });
  }, [date]);

  return (
    <>
    {alertbox && (
      <div style={{border:'5px solid #2C9D30',borderRadius:'15px',background:'#2C9D30'}}>updated &nbsp;  <span style={{fontSize:'25px',color:'red',cursor:'pointer',float:'right'}} onClick={()=>setalert(false)}>&times;</span></div>
    )}
      <form onSubmit={submitValue} style={styles.formcolor}>
        <table style={styles.tr} className="font1-13">
          <tr>
            <td>
              <p>Enter Date</p>
            </td>
            <td>
              <input
                className="calanderbutton"
                name="date"
                type="date"
                onChange={dateHandler}
                //Value={pureDate[1]}
                Value={date}
                placeholder="date"
                />
            </td>
          </tr>
          <tr>
            <td>
              <p>total calorie</p>
            </td>
            <td>
              <p>{calsum}</p><button onClick={Refresh}>refresh</button>
            </td>
      
          </tr>
          <tr>
          <td >how to add food/ml</td>
          <td><FoodCalorieTable/></td>
          </tr>
        </table>
                </form>

      <form onSubmit={submitValue} style={styles.formcolor}>
        <table className="tableoffood" style={styles.tr}>
          <tr>
            <td>
              <FontAwesomeIcon
                icon={faMugHot}
                style={styles.icon}
                className="iconoffood"
              />
            </td>
            <td className="tdoflabel" style={styles.td}>
              Early Morning Energy
            </td>
            <td>
              <input
                name="mealType"
                style={styles.invinput}

                Value="early morning"
                placeholder="Time"
                className="hiddentd"
                hidden
              />

              <input
                name="date"
                style={styles.invinput}
                Value={date}
                placeholder="Time"
                className="hiddentd"
                hidden
              />
            </td>
            <td>
              <label>Food</label>
              <input
                className="inputofdiet"
                name="food"
                type="text"
                onChange={(e) => {
                  changeHandler(e,1);
                  setplaceholder(e.target.value);
                  
                }}
                style={styles.label}
                value={placeholder}
                placeholder={earlymoring && earlymoring.food}




              />
              <div >
                <ul style={{ backgroundColor: '#D8D7D5', borderRadius: '4px', listStyle: 'none' }}>

                  {dataearly && Array.isArray(dataearly) && dataearly.map((suggestion) => (
                    <li
                      style={{ color: 'black', marginLeft: '-29px', borderBottom: '1px solid grey', cursor: 'pointer' }}
                      key={suggestion.id}  // Replace with the actual unique identifier for each suggestion
                      onClick={() => {
                        setearlymoring((prevEarlyMorning) => ({

                          food: suggestion.name,
                          calorie:parseInt(suggestion.cal),
                        }));

                        setplaceholder(suggestion.name)
                        setdataearly([]); // Clear the suggestions list
                      }}
                    >
                      {suggestion.name}
                    </li>

                  ))}
                </ul>
              </div>
              <label>quantity <span style={{ textTransform: 'lowercase' }}>in ml</span></label>
              <input
                className="inputofdiet"
                name="quantity"
                type="number"
                defaultValue={earlymoring && earlymoring.quantity}

                style={styles.label}

              />
              <br />
              <label>calorie</label>
              <input
                className="inputofdiet"
                name="calorie"
                type="number"
                Value={earlymoring && earlymoring.calorie}
                placeholder={earlymoring && earlymoring.calorie}

                style={styles.label}

              />
              <br />
              <label>Time</label>
              <input
                className="inputofdiet"
                name="time"
                // type="text"
                onChange={timechangehandler}
                style={styles.label}
                defaultValue={earlymoring && earlymoring.time}
                type="time"

              />
            </td>
            <td>
              <button style={styles.buttongreen} type="submit">
                Add
              </button>
            </td>
          </tr>
        </table>
      </form>
      <form onSubmit={submitValue} style={styles.formcolor}>
        <table className="tableoffood">
          <tr>
            <td>
              <FontAwesomeIcon
                icon={faHamburger}
                style={styles.icon}
                className="iconoffood"
              />
            </td>
            <td style={styles.td} className="tdoflabel">
              Breakfast
            </td>
            <td className="hiddentd">
              <input
                name="mealType"
                //onChange={changeHandler}
                style={styles.invinput}
                value="breakfast"
                placeholder="Time"
                className="hiddentd"
                hidden
              />
              <input
                name="date"
                //onChange={changeHandler}
                style={styles.invinput}
                Value={date}
                placeholder="Time"
                className="hiddentd"
                hidden
              />
            </td>
            <td>
              <label>Food</label>
              <input
                className="inputofdiet"
                name="food"
                type="text"
                onChange={(e) => {
                  changeHandler(e,2);
                  setbreadfastplaceholder(e.target.value);
                }}
                style={styles.label}
              value={breakfastplaceholder}
              placeholder={breakfast && breakfast.food}
             




              />
              <div >
                <ul style={{ backgroundColor: '#D8D7D5', borderRadius: '4px', listStyle: 'none' }}>

                  {databreak && Array.isArray(databreak) && databreak.map((suggestion) => (
                    <li
                      style={{ color: 'black', marginLeft: '-29px', borderBottom: '1px solid grey', cursor: 'pointer' }}
                      key={suggestion.id}  // Replace with the actual unique identifier for each suggestion
                      onClick={() => {
                        setbreakfast((prevEarlyMorning) => ({

                          food: suggestion.name,
                          calorie:parseInt(suggestion.cal),
                        }));

                        setbreadfastplaceholder(suggestion.name);
                        
                        setdatabreak([]); // Clear the suggestions list
                      }}
                    >
                      {suggestion.name}
                    </li>

                  ))}
                </ul>
              </div>
              <label>quantity  <span style={{ textTransform: 'lowercase' }}>in ml</span></label>
              <input
                className="inputofdiet"
                name="quantity"
                type="number"
                defaultValue={breakfast && breakfast.quantity}

                style={styles.label}

              />
              <br />
              <label>calorie</label>
              <input
                className="inputofdiet"
                name="calorie"
                type="number"
                Value={breakfast && breakfast.calorie}
                placeholder={breakfast && breakfast.calorie}

                style={styles.label}

              />
              <br />
              
              <label>Time</label>
              <input
                className="inputofdiet"
                name="time"
                style={styles.label}
                defaultValue={breakfast && breakfast.time}
                placeholder={breakfast && breakfast.time}
                onChange={timechangehandler}
                type="time"

              //   placeholder="Time"
              />
              <input
                name="date"
                //onChange={changeHandler}
                style={styles.invinput}
                Value={date}
                placeholder="Time"
                className="hiddentd"
                hidden
              />
            </td>
            <td>
              <button style={styles.buttongreen} type="submit">
                Add
              </button>
            </td>
          </tr>
        </table>
      </form>
      <form onSubmit={submitValue} style={styles.formcolor}>
        <table className="tableoffood">
          <tr>
            <td>
              <FontAwesomeIcon
                icon={faHamburger}
                style={styles.icon}
                className="iconoffood"
              />
            </td>
            <td style={styles.td} className="tdoflabel">
              Mid-Day Snack
            </td>
            <td>
              <input
                name="mealType"
                //onChange={changeHandler}
                style={styles.invinput}
                Value="mid day snack"
                placeholder="Time"
                className="hiddentd"
                hidden
              />
              <input
                name="date"
                //onChange={changeHandler}
                style={styles.invinput}
                Value={date}
                placeholder="Time"
                className="hiddentd"
                hidden
              />
            </td>
            <td>
              <label>Food</label>
              <input
                className="inputofdiet"
                name="food"
                type="text"
                onChange={(e) => {
                  changeHandler(e,3);
                  setmidplaceholder(e.target.value)
                }}
                style={styles.label}
                value={midplaceholder}
                placeholder={midday.food}


              />
              <div >
                <ul style={{ backgroundColor: '#D8D7D5', borderRadius: '4px', listStyle: 'none' }}>

                  {datamid && Array.isArray(datamid) && datamid.map((suggestion) => (
                    <li
                      style={{ color: 'black', marginLeft: '-29px', borderBottom: '1px solid grey', cursor: 'pointer' }}
                      key={suggestion.id}  // Replace with the actual unique identifier for each suggestion
                      onClick={() => {
                        setmidday((prevEarlyMorning) => ({

                          food: suggestion.name,
                          calorie:parseInt(suggestion.cal),
                        }));

                        setmidplaceholder(suggestion.name)
                        setdatamid([]); // Clear the suggestions list
                      }}
                    >
                      {suggestion.name}
                    </li>

                  ))}
                </ul>
              </div>
              <label>quantity <span style={{ textTransform: 'lowercase' }}> in ml</span></label>
              <input
                className="inputofdiet"
                name="quantity"
                type="number"
                defaultValue={midday && midday.quantity}

                style={styles.label}

              />
              <br />
              <label>calorie</label>
              <input
                className="inputofdiet"
                name="calorie"
                type="number"
                Value={midday && midday.calorie}
                placeholder={midday&& midday.calorie}

                style={styles.label}

              />
              <br />
              <label>Time</label>
              <input
                className="inputofdiet"
                name="time"
                style={styles.label}
                onChange={changeHandler}
                defaultValue={midday && midday.time}
                placeholder={midday && midday.time}
                type="time"
              />
            </td>
            <td>
              <button style={styles.buttongreen} type="submit">
                Add
              </button>
            </td>
          </tr>
        </table>
      </form>

      <form onSubmit={submitValue} style={styles.formcolor}>
        <table className="tableoffood">
          <tr>
            <td>
              <FontAwesomeIcon
                icon={faUtensils}
                style={styles.icon}
                className="iconoffood"
              />
            </td>
            <td style={styles.td} className="tdoflabel">
              Lunch
            </td>
            <td>
              <input
                name="mealType"
                //onChange={changeHandler}
                style={styles.invinput}
                defaultValue="lunch"
                placeholder="Time"
                className="hiddentd"
                hidden
              />
              <input
                name="date"
                //onChange={changeHandler}
                style={styles.invinput}
                Value={date}
                placeholder="Time"
                className="hiddentd"
                hidden
              />
            </td>
            <td>
              <label>Food</label>
              <input
                className="inputofdiet"
                name="food"
                type="text"
                onChange={(e) => {
                  changeHandler(e,4);
                  setlunchplaceholder(e.target.value)
                }}
                style={styles.label}
                value={lunchplaceholder}
                placeholder={lunch && lunch.food}



              />
              <div >
                <ul style={{ backgroundColor: '#D8D7D5', borderRadius: '4px', listStyle: 'none' }}>

                  {datalunch && Array.isArray(datalunch) && datalunch.map((suggestion) => (
                    <li
                      style={{ color: 'black', marginLeft: '-29px', borderBottom: '1px solid grey', cursor: 'pointer' }}
                      key={suggestion.id}  // Replace with the actual unique identifier for each suggestion
                      onClick={() => {
                        setlunch((prevEarlyMorning) => ({

                          food: suggestion.name,
                          calorie:parseInt(suggestion.cal),
                        }));

                        setlunchplaceholder(suggestion.name)
                        setdatalunch([]); // Clear the suggestions list
                      }}
                    >
                      {suggestion.name}
                    </li>

                  ))}
                </ul>
              </div>
              <label>quantity <span style={{ textTransform: 'lowercase' }}>in ml</span></label>
              <input
                className="inputofdiet"
                name="quantity"
                type="number"
                defaultValue={lunch && lunch.quantity}

                style={styles.label}

              />
              <br />
              <label>calorie</label>
              <input
                className="inputofdiet"
                name="calorie"
                type="number"
                Value={lunch && lunch.calorie}
                placeholder={lunch && lunch.calorie}

                style={styles.label}

              />
              <br />
              <label>Time</label>
              <input
                className="inputofdiet"
                name="time"
                style={styles.label}
                onChange={changeHandler}
                defaultValue={lunch && lunch.time}
                // placeholder={lunch.time}
                placeholder={lunch && lunch.time}
                type="time"
              />
            </td>
            <td>
              <button style={styles.buttongreen} type="submit">
                Add
              </button>
            </td>
          </tr>
        </table>
      </form>
      {/* start here/....................................................................... */}
      <form onSubmit={submitValue} style={styles.formcolor}>
        <table className="tableoffood">
          <tr>
            <td>
              <FontAwesomeIcon
                icon={faBlender}
                style={styles.icon}
                className="iconoffood"
              />
            </td>
            <td style={styles.td} className="tdoflabel">
              Afternoon Snack
            </td>
            <td>
              <input
                name="mealType"
                //onChange={changeHandler}
                style={styles.invinput}
                defaultValue="afternoon snack"
                placeholder="Time"
                className="hiddentd"
                hidden
              />
              <input
                name="date"
                //onChange={changeHandler}
                style={styles.invinput}
                Value={date}
                placeholder="Time"
                className="hiddentd"
                hidden
              />
            </td>
            <td>
              <label>Food</label>
              <input
                className="inputofdiet"
                name="food"
                type="text"
                onChange={(e) => {
                  changeHandler(e,5);
                  setafterplaceholer(e.target.value)
                }}
                style={styles.label}
                value={afterplaceholder}
                placeholder={afternoon && afternoon.food}



              />
              <div >
                <ul style={{ backgroundColor: '#D8D7D5', borderRadius: '4px', listStyle: 'none' }}>

                  {dataafter && Array.isArray(dataafter) && dataafter.map((suggestion) => (
                    <li
                      style={{ color: 'black', marginLeft: '-29px', borderBottom: '1px solid grey', cursor: 'pointer' }}
                      key={suggestion.id}  // Replace with the actual unique identifier for each suggestion
                      onClick={() => {
                        setafternoon((prevEarlyMorning) => ({

                          food: suggestion.name,
                          calorie:parseInt(suggestion.cal),
                        }));

                        setafterplaceholer(suggestion.name)
                        setdataafter([]); // Clear the suggestions list
                      }}
                    >
                      {suggestion.name}
                    </li>

                  ))}
                </ul>
              </div>
              <label>quantity <span style={{ textTransform: 'lowercase' }}> in ml</span></label>
              <input
                className="inputofdiet"
                name="quantity"
                type="number"
                defaultValue={afternoon && afternoon.quantity}

                style={styles.label}

              />
              <br />
              <label>calorie</label>
              <input
                className="inputofdiet"
                name="calorie"
                type="number"
                Value={afternoon && afternoon.calorie}
                placeholder={afternoon && afternoon.calorie}

                style={styles.label}

              />
              <br />
              {/* <td></td> */}
              <label>Time</label>
              <input
                className="inputofdiet"
                name="time"
                style={styles.label}
                onChange={changeHandler}
                defaultValue={afternoon && afternoon.time}
                placeholder={afternoon && afternoon.time}
                type="time"
              />
            </td>
            <td>
              <button style={styles.buttongreen} type="submit">
                Add
              </button>
            </td>
          </tr>
        </table>
      </form>
      <form onSubmit={submitValue} style={styles.formcolor}>
        <table className="tableoffood">
          <tr>
            <td>
              <FontAwesomeIcon
                icon={faCookie}
                style={styles.icon}
                className="iconoffood"
              />
            </td>
            <td style={styles.td} className="tdoflabel">
              Dinner
            </td>
            <td>
              <input
                name="mealType"
                //onChange={changeHandler}
                style={styles.invinput}
                defaultValue="dinner"
                placeholder="Time"
                className="hiddentd"
                hidden
              />
              <input
                name="date"
                //onChange={changeHandler}
                style={styles.invinput}
                Value={date}
                placeholder="Time"
                className="hiddentd"
                hidden
              />
            </td>
            <td>
              <label>Food</label>
              <input
                className="inputofdiet"
                name="food"
                type="text"
                onChange={(e) => {
                  changeHandler(e,6);
                  setdinnerplaceholder(e.target.value)
                }}
                style={styles.label}
                value={dinnerplaceholder}
                placeholder={Dinner && Dinner.food}



              />
              <div >
                <ul style={{ backgroundColor: '#D8D7D5', borderRadius: '4px', listStyle: 'none' }}>

                  {datadinner && Array.isArray(datadinner) && datadinner.map((suggestion) => (
                    <li
                      style={{ color: 'black', marginLeft: '-29px', borderBottom: '1px solid grey', cursor: 'pointer' }}
                      key={suggestion.id}  // Replace with the actual unique identifier for each suggestion
                      onClick={() => {
                        setDinner((prevEarlyMorning) => ({

                          food: suggestion.name,
                          calorie:parseInt(suggestion.cal),
                        }));

                        setdinnerplaceholder(suggestion.name)
                        setdatadinner([]); // Clear the suggestions list
                      }}
                    >
                      {suggestion.name}
                    </li>

                  ))}
                </ul>
              </div>
              <label>quantity  <span style={{ textTransform: 'lowercase' }}> in ml</span></label>
              <input
                className="inputofdiet"
                name="quantity"
                type="number"
                defaultValue={Dinner && Dinner.quantity}

                style={styles.label}

              />
              <br />
              <label>calorie</label>
              <input
                className="inputofdiet"
                name="calorie"
                type="number"
                Value={Dinner && Dinner.calorie}
                placeholder={Dinner && Dinner.calorie}

                style={styles.label}

              />
              <br />
              <label>Time</label>
              <input
                className="inputofdiet"
                name="time"
                style={styles.label}
                onChange={changeHandler}
                defaultValue={Dinner && Dinner.time}
                placeholder={Dinner && Dinner.time}
                type="time"
              />
            </td>
            <td>
              <button style={styles.buttongreen} type="submit">
                Add
              </button>
            </td>
          </tr>
        </table>
      </form>
      <form onSubmit={submitValue} style={styles.formcolor}>
        <table className="tableoffood">
          <tr>
            <td>
              <FontAwesomeIcon
                icon={faWineBottle}
                style={{ fontSize: "2rem", transform: "rotate(-45deg)" }}
                className="iconoffood"
              />
            </td>
            <td style={styles.td} className="tdoflabel">
              Dinner Drink
            </td>
            <td>
              <input
                name="mealType"
                onChange={changeHandler}
                style={styles.invinput}
                defaultValue="dinner drink"
                placeholder="Time"
                className="hiddentd"
                hidden
              />
              <input
                name="date"
                //onChange={changeHandler}
                style={styles.invinput}
                Value={date}
                placeholder="Time"
                className="hiddentd"
                hidden
              />
            </td>
            <td>
              <label>Food</label>
              <input
                className="inputofdiet"
                name="food"
                type="text"
                onChange={(e) => {
                  changeHandler(e,7);
                  setdrinkplaceholder(e.target.value)
                }}
                style={styles.label}
                value={drinkplaceholder}
                placeholder={dinnerdrink && dinnerdrink.food}



              />
              <div >
                <ul style={{ backgroundColor: '#D8D7D5', borderRadius: '4px', listStyle: 'none' }}>

                  {datadrink && Array.isArray(datadrink) && datadrink.map((suggestion) => (
                    <li
                      style={{ color: 'black', marginLeft: '-29px', borderBottom: '1px solid grey', cursor: 'pointer' }}
                      key={suggestion.id}  // Replace with the actual unique identifier for each suggestion
                      onClick={() => {
                        setdinnerdrink((prevEarlyMorning) => ({

                          food: suggestion.name,
                          calorie:parseInt(suggestion.cal),
                        }));

                        setdrinkplaceholder(suggestion.name)
                        setdatadrink([]); // Clear the suggestions list
                      }}
                    >
                      {suggestion.name}
                    </li>

                  ))}
                </ul>
              </div>
              <label>quantity  <span style={{ textTransform: 'lowercase' }}> in ml</span></label>
              <input
                className="inputofdiet"
                name="quantity"
                type="number"
                defaultValue={dinnerdrink && dinnerdrink.quantity}

                style={styles.label}

              />
              <br />
              <label>calorie</label>
              <input
                className="inputofdiet"
                name="calorie"
                type="number"
                Value={dinnerdrink && dinnerdrink.calorie}
                placeholder={dinnerdrink && dinnerdrink.calorie}

                style={styles.label}

              />
              <br />
              <label>Time</label>
              <input
                className="inputofdiet"
                name="time"
                style={styles.label}
                onChange={changeHandler}
                defaultValue={dinnerdrink && dinnerdrink.time}
                placeholder={dinnerdrink && dinnerdrink.time}
                type="time"
              />
            </td>
            <td>
              <button style={styles.buttongreen} type="submit">
                Add
              </button>
            </td>
          </tr>
        </table>
      </form>
    </>
  );
}

const styles = {
  buttongreen: { background: "#00ACC8", border: "none", borderRadius: 0 ,marginLeft:'9px'},
  icon: {
    fontSize: "2rem",
  },
  suggestions: {
    color: 'black',

  },
  tr: {
    borderCollapse: "separate",
    borderSpacing: "20px",
  },
  label: {
    fontSize: "8px",
    padding: "3px",
    width: "30px",
    display: "inline-block",
    background: "#fefe",
    color: "black",
  },
  table: { tableLayout: "fixed" },
  invinput: {
    fontSize: " 20px",
    background: "#4c003d",
    width: "1%",
    textAlign: "center",
    marginTop: "15px",
    border: "none",
  },
  td: {
    width: "100px",
  },
  t3: {
    width: "114px",
  },
  formcolor: {
    marginTop: "15px",
    background: "#ffffff1c 0% 0% no-repeat padding-box",
    marginLeft:'8px'
  },
};
