import { faSort, faSortDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
// import ClientProfile from "./client_profile";
import ClientProfile from "../editprofile/clienteditprofile";
import Analsistab from "./analasis_tab";
import moment from "moment";
import InvestigationReport from "./investigation_report";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import SearchClients from "./search";
import DateSelect from "../components/traker_by_update/tabs_com/select_date";
import ClientDailyTracker from "./traker_by_date/table_list";
import axios from "axios";
const queryString = require("query-string");
export default function Tablelist({
  data = [],
  handleSearch,
  handleSortBy,
  searchText,
  sortBy,
  weekOptions = {},
}) {
  const [tracker, setTracker] = useState(false);
  const [date, setDate] = useState(new Date());
  const [profile, setProfile] = useState(null);
  const [report, setReport] = useState(null);
  const [customer, setid] = useState();
  const [isdate, setDateSelect] = useState(false);
  const [shedule, setShedule] = useState(false);
  const [clientid, setClientid] = useState("");
  const [cal,setcal]=useState([]);
  const openProfile = (customer) => () => {
    setProfile(customer);
  };

  const closeProfile = () => {
    setProfile(null);
  };
  const history = useHistory();
  function openAnalasis(id) {
    setClientid(id);
    history.push(`/salesanalasis/${id}`);
    //  <Route path="/critical/:id" component={Pageanalasis} />;
    // setShedule(!shedule);
  }
  const handleDateChange = (value, e) => {
    setDate(value);
  };
  const openDailyTracker = (customer) => () => {
    setTracker(customer);
  };

  useEffect(()=>axios.get('sales/cal-for-allclient')
  .then((e)=>setcal(e.data))
  ,[])

  const closeDailyTracker = () => {
    setTracker(null);
  };
  const dateSelect = (e) => () => {
    //  function dateSelect(e) {
    setid(e);
    setDateSelect(!isdate);
  };
  const openReporter = (customer) => () => {
    setReport(customer);
  };

  const closeReporter = () => {
    setReport(null);
  };

  const Row = (item, index) => (
    <tr key={item.id || index} style={styles.tr} className="lightgradient2">
      
      {/* <td style={styles.usernav} onClick={openProfile(item.id)}>
        {`${!!item.firstName ? item.firstName : ""}`.trim()}
      </td> */}
      <td style={styles.usernav}>
        <Link
          to={`/sales/client/${item.id}`}
          // style={styles.tolink}
        >
          {`${!!item.firstName ? item.firstName : ""}`.trim()} {item.lastname}
        </Link>
      </td>
      {/* <td>{item.age}</td>
      <td>{item.location}</td> */}
      
      <td>{item.dueDate}</td>
      <td style={styles.docName}>
        {`${!!item.doctorFirstName ? "Dr. " + item.doctorFirstName : ""} ${
          !!item.doctorLastName ? item.doctorLastName : ""
        }`.trim()}
      </td>
      <td>
        <button onClick={openDailyTracker(item.id)}>Details</button>
      </td>
      <td>
        <button style={styles.sbtn} onClick={dateSelect(item.id)}>
          Update
        </button>
      </td>
      <td>
        <button style={styles.sbtn} onClick={openReporter(item.id)}>
          Update
        </button>
      </td>
      <td>
        {item.criticality == "high risk" ? (
          <button
            onClick={() => openAnalasis(item.id)}
            style={styles.red}
          ></button>
        ) : item.criticality == "stable" ? (
          <button
            onClick={() => openAnalasis(item.id)}
            style={styles.green}
          ></button>
        ) : (
          <button
            onClick={() => openAnalasis(item.id)}
            style={styles.yellow}
          ></button>
        )}
      </td>
      <td>{item.membership}</td>
      <td style={styles.col}>
        <Link to={`/callresponse/${item.id}`}>
          <button>Call response</button>
        </Link>
      </td>
    </tr>
  );

  return (
    <>
      {shedule ? <Analsistab userId={clientid} /> : null}
      {isdate ? (
        <DateSelect
          onChange={handleDateChange}
          value={date}
          query={queryString.stringify({
            date: moment(date).format("YYYY-MM-DD"),
            tab: "diet",
            customer,
          })}
        />
      ) : null}
      {report ? (
        <InvestigationReport
          customerId={report}
          closeReporter={closeReporter}
        />
      ) : (
        <div>
          {!!profile ? (
            <ClientProfile profileId={profile} closeProfile={closeProfile} />
          ) : null}

          <div>
            {tracker ? (
              <ClientDailyTracker
                customerId={tracker}
                closeDailyTracker={closeDailyTracker}
              />
            ) : null}
            <SearchClients onChange={handleSearch} value={searchText} />
            <div style={styles.sortit}>
              <span style={{ fontSize: "12px" }}>
                {" "}
                <FontAwesomeIcon icon={faSort} /> Sort By:{" "}
                <select
                  className="weekselctor"
                  value={sortBy}
                  onChange={handleSortBy}
                  style={styles.option}
                >
                  <option value="week" selected>
                    Week
                  </option>
                  {Object.keys(weekOptions).map((range) => (
                    <option value={range}>{range}</option>
                  ))}
                </select>
                {/* <FontAwesomeIcon icon={faSortDown} />{" "} */}
              </span>
            </div>
            <div className=" lightgradient2" style={styles.card}>
              <div style={styles.inner}>
                <p className="heading  margin-toper">
                  <strong>All Clients List</strong>
                </p>
                <hr style={{ color: "#fff" }} />
                <div className="table-cover table3 table-height">
                  <table style={styles.table}>
                    <thead>
                      <tr className="lightgradient2">
                        <td>Name</td>
                        {/* <td>Age</td> */}
                        {/* <td>Location</td> */}
                        
                        <td>Due Date</td>
                        <td>Doctor</td>
                        <td>Daily Tracker </td>
                        <td>Update Daily Tracker</td>
                        <td>Medical</td>
                        <td>Criticality</td>
                        <td>Membership</td>
                        <td> Call response</td>
                      </tr>
                    </thead>
                    <tbody>{data.map(Row)}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const styles = {
  card: {
    // display: "flex",
    justifyContent: "space-between",
    // backdropFilter: "blur(31px) brightness(-15%) opacity(.3)",
    padding: "0px",
    borderRadius: "10px",
    margin: "10px",
  },
  inner: {
    textDecoration: "none",
    color: "#fff",
  },

  table: {
    borderCollapse: "seperate",
    borderSpacing: "0px 10px",
    width: "42.5rem",
  },
  sortit: {
    background:
      "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
    margin: "10px",
    borderRadius: "10px",
    textAlign: "left",
    padding: "10px",
  },
  red: {
    background: "red",
    border: "1px solid red",
    minWidth: "96px",
    height: "24px",
  },
  green: {
    background: "green",
    border: "1px solid green",
    minWidth: "96px",
    height: "24px",
  },
  yellow: {
    background: "yellow",
    border: "1px solid yellow",
    minWidth: "96px",
    height: "24px",
  },
  tr: {
    // background: "#761F58",
  },

  usernav: {
    // background: "#4c005d",
    padding: "5px 10px",
    cursor: "pointer",
  },
  sbtn: {
    background: "#E81A7F",
    border: "1px solid #E81A7F",
  },
  docName: {
    paddingLeft: 12,
  },
};
