import React, { useEffect, useState } from "react";
import axios from "axios";

export default function Notification() {
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [visibleNotifications, setVisibleNotifications] = useState(5);
  const fetchData = () => {
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("token"),
      },
    };

    // Axios GET request
    axios
      .get("consultant/get-notifications/", config)
      .then((response) => {
        // Sort the data in descending order based on notification date
        const sortedData = response.data.notifications.sort(
          (a, b) => new Date(b.date) - new Date(a.date)
        );

        setCount(response.data.total_notifications);
        setData(sortedData);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  };

  useEffect(() => {
    fetchData(); // Initial data fetch

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLiClick = (id) => {
    // Axios POST request
    axios
      .post(`/consultant/mark-notification-as-seen?id=${id}`)
      .then((response) => {
        // Handle the response from the server as needed
        console.log("POST request successful", response.data);
        // Reload the data after the POST request is successful
        fetchData();
        // You can perform additional actions here if necessary
      })
      .catch((error) => {
        console.error("Error making POST request: ", error);
        // Handle errors here
      });
  };

  const showMoreNotifications = () => {
    setVisibleNotifications((prevVisible) => prevVisible + 5);
  };

  const formatDateTime = (dateTimeString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    return new Date(dateTimeString).toLocaleDateString(undefined, options);
  };

  const getCriticalityText = (criticality) => {
    if (criticality === 3) {
      return "Critical";
    } else if (criticality === 2) {
      return "Medium risk";
    } else {
      return "Unknown"; // Handle other cases as needed
    }
  };

  return (
    <div>
      <h2>Notifications ({count})</h2>
      <div className="notifcation_scroll">
        {" "}
        <ul className="notificationul ">
          {data.slice(0, visibleNotifications).map((notification) => (
            <li
              onClick={() => handleLiClick(notification.id)}
              key={notification.id}
              className={`notificationli ${
                notification.seen ? "notification-seen" : "notification-unseen"
              }`}
              //   className="notificationli"
            >
              <div>
                New {notification.notification_type} Notification from{" "}
                {notification.notification_from} on &nbsp;&nbsp;
                {formatDateTime(notification.date)}
              </div>
              <br />
              {notification.notification_type === "criticality" && (
                <div>
                  <strong>Criticality:</strong>{" "}
                  {getCriticalityText(
                    notification.notification_data.criticallity
                  )}
                </div>
              )}
              {notification.notification_type === "messages" && (
                <div>
                  <strong>Message:</strong> {notification.notification_data}
                </div>
              )}
              {/* Add more fields as needed */}
            </li>
          ))}
        </ul>
        {visibleNotifications < data.length && (
          <button className="notifcationbutton" onClick={showMoreNotifications}>
            Show More
          </button>
        )}
      </div>
    </div>
  );
}
