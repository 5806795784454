import React from "react";
import { withRouter } from "react-router";
import "../../App.css";
import LoginGirl from "../img/login_girl.png";
import Form from "./downloadapp_QR";

import slogo from "../img/sukhprasavamlogo.png";

export default class ClientLogin extends React.Component {
  render() {
    return (
      <>
        <div className="newbg">
          <div
            style={{
              padding: "10% 0%",
            }}
          >
            <div className="login-wrapper" style={styles.flex}>
              <div
                style={styles.content}
                className="flexdisplay mobile-view-hidden"
              >
                <h1 className="margin50 gilroy"> Launching </h1>

                <img className="slogo" src={slogo} />
                {/* <small>Who recommands only 15% c-sector rate in a nation</small> */}
                {/* <small style={{ textTransform: "capitalize", fontSize: "20px" }}>
                {" "}
                pleasing birth without fear
              </small> */}
              </div>
              <div
                className="loginimage"
                style={{
                  color: "#fff",
                }}
              >
                <img src={LoginGirl} alt="" style={styles.imagep} />
                <div style={styles.content} className=" pc-view-hidden">
                  <h3 className=" gilroy  fontsize-25">
                    Launching sukh prasavam
                  </h3>
                  <h2> pleasing birth without fear</h2>
                  {/* <img className="slogo" src={slogo} /> */}
                  {/* <small>Who recommands only 15% c-sector rate in a nation</small> */}
                  {/* <small style={{ textTransform: "capitalize", fontSize: "20px" }}>
                {" "}
                 
              </small> */}
                </div>
              </div>

              <Form />
            </div>
            {/* <div style={styles.content} className=" pc-view-hidden">
              <h1 className="margin50 gilroy fontsize50">
                Launching sukh prasavam
              </h1>
              <h2> pleasing birth without fear</h2>
            </div> */}
          </div>
        </div>
      </>
    );
  }
}

const styles = {
  content: {
    color: "white",
    // display: "flex",
    textAlign: "center",
    justifyContent: "center",
    margin: "30px auto",
  },

  imagep: { width: "100%" },
  flex: {
    display: "flex",
    justifyContent: "space-between",
    width: "70%",
    margin: "auto",
  },
};
