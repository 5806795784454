import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";

export default function CurrentStatus() {
  return (
    <div className="doctorcards lightgradient" style={styles.card}>
      <div style={styles.left}>
        <div style={{ display: "flex" }}>
          <div style={{ padding: "10px" }}>
            <h2 style={styles.counter}>&#8377;0</h2>
          </div>
          <div style={{ padding: "10px" }}>
            <p>
              <small style={{ whiteSpace: "nowrap", fontSize: "12px" }}>
                This month so far
              </small>
            </p>
          </div>
        </div>
      </div>
      <div style={styles.right}>
        <div style={{ display: "flex" }}>
          <div style={{ padding: "10px" }}>
            <h2 style={styles.counter}>&#8377;0</h2>
          </div>
          <div style={{ padding: "10px" }}>
            <p>
              <small style={{ whiteSpace: "nowrap", fontSize: "12px" }}>
                Previous month
              </small>
            </p>
          </div>
        </div>
        {/* <button onClick="" style={styles.link}>
          More <FontAwesomeIcon icon={faChevronCircleRight} />
        </button> */}
      </div>
    </div>
  );
}

const styles = {
  card: {
    display: "flex",
    flexDirection: "column",
    // backdropFilter: "blur(71px) brightness(-15%) opacity(.5)",
    width: "14.8rem",
    height: "12rem",
    padding: "10px",
    borderRadius: "10px",
    marginLeft: "35%",
    marginTop: "10px",
    lineHeight: "1px",
  },

  left: {
    padding: "0px",
    height: "4rem",
    lineHeight: "1px",
    borderBottom: "1px solid #fff",
  },
  right: {
    padding: "0px",
    height: "4rem",
    lineHeight: "1px",
  },
  counter: {
    color: "#fff",
  },

  link: {
    fontSize: "13px",
    textDecoration: "none",
    color: "#fff",
    background: "rgb(46 17 55)",
    padding: "10px",
    borderRadius: "10px",
    border: "none",
    cursor: "pointer",
  },
};
