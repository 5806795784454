import React from "react";
import img from "../img/man.jpg";
import "./chat.css";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
export default function Livechat(props) {
  const [inputField, setInputField] = useState();

  const [data, setdata] = useState();
  const [Message, setMessage] = useState([]);
  const [reload, setreload] = useState(0);
  console.log(props.id);

  const inputsHandler = (e) => {
    setInputField(e.target.value);
  };

  function sentchat(e) {
    e.preventDefault();

    console.log(inputField);

    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("doc_token"),
      },
    };
    const data = { receiver: props.id, message: inputField };
    axios
      .post("messages/send-message/", data, config)
      .then((res) => {
        console.log(res.receiverDetails);
        let reloder = 1;
        reloder = reload + reloder;
        setreload(reloder);
        document.getElementById("create-course-form").reset();
        // reloder = reload + reloder;
        console.log(reload);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("doc_token"),
      },
    };

    axios
      .get("messages/get-all-messages/?receiver=" + props.id, config)
      .then((res) => {
        console.log(res.receiverDetails);
        setdata(res.data.receiverDetails);
        setMessage(res.data.messages);
      });
  }, [props.id, reload]);

  //   handleSubmit(e) {
  //     e.preventDefault();
  //     const Data = { message: message };
  //     const headers = {
  //       Authorization: `token ` + localStorage.getItem("token"),
  //     };
  //     axios
  //       .post("api/password_reset/", Data)
  //       .then(() => {
  //         alert("password reset link  sent to  mail ");
  //       })
  //       .catch((error) => {
  //         alert("cannot reset password");
  //       }); //   .then((res) => {
  //     //     localStorage.setItem("LoginToken", res.data.token);
  //     //   });
  //     /*.catch(error=>{console.log(error)});*/
  // }

  const sortedMessages = [...Message].sort((a, b) => {
    const dateA = new Date(`${a.date} ${a.time}`);
    const dateB = new Date(`${b.date} ${b.time}`);
    return dateB - dateA; // Sort in descending order
  });
  return (
    <>
      <div className="mobileback">
        {" "}
        <div className="chat-avatar ">
          <div>
            {data &&
            data.image_url !=
              "https://sukhprasavam.shebirth.com/media//ProfilePic/default.jpg" ? (
              <img
                style={{ maxWidth: "65px" }}
                className="avatar"
                src={data && data.image_url}
                alt=""
              />
            ) : data &&
              data.image_url ==
                "https://sukhprasavam.shebirth.com/media//ProfilePic/default.jpg" ? (
              <button
                className="avatar aquamarine "
                style={{ maxWidth: "65px", border: "none" }}
              ></button>
            ) : (
              <button
                className="avatar aquamarine "
                style={{ maxWidth: "65px", border: "none" }}
              ></button>
            )}
          </div>
          {console.log(data)}
          <div className="alignstart">
            {data && data.name}
            <p className="margin-0"> {data && data.speciality}</p>
            <p className="margin-0"> &#10003; last message</p>
          </div>
          <div className="timefont">12:11 pm</div>
        </div>
        <div class="speech-wrapper gradiantblur">
          {sortedMessages.map((data) => (
            <div class="bubble">
              <div class="txt">
                <p class="name">{data.sender}</p>
                <p class="message">{data.message}</p>
                <span class="timestamp">{data.time}</span>
              </div>
              <div class="bubble-arrow"></div>
            </div>
          ))}
          {/* <div class="bubble alt">
            <div class="txt">
              <p class="name alt">
                +353 87 1234 567<span> ~ John</span>
              </p>
              <p class="message">
                Nice... this will work great for my new project.
              </p>
              <span class="timestamp">10:22 pm</span>
            </div>
            <div class="bubble-arrow alt"></div>
          </div> */}
        </div>{" "}
      </div>

      <div class="chat__form">
        <form id="create-course-form" onSubmit={sentchat}>
          <input
            className="chattype"
            id="text-message"
            onChange={inputsHandler}
            type="text"
            placeholder="Type your message here ..."
          />
          <button type="submit">Send</button>
        </form>
      </div>
    </>
  );
}
