import { withRouter } from "react-router";
import "../../App.css";
import LoginGirl from "../img/login_girl.png";
import Form from "./form";
import React, { useState } from "react";
import "./login.css";
import slogo from "../img/sukhprasavamlogo.png";
import { Helmet } from "react-helmet";

const Loginpostnatal = () => {
    const [showForm, setShowForm] = useState(true);

    const handleLogin = () => {
        setShowForm(true);
    };

    return (
        <>
            <Helmet>
                <title>Sukhprasavam</title>
                <meta
                    name="description"
                    content="Welcome to Sukh Prasavam-A Customised Pregnancy Support Care! We understand that every pregnancy is unique and may require individualized care and support. That's why we offer personalized services to meet your specific needs and help you have a healthy and safe  pregnancy calculator."
                />
                <meta
                    name="keywords"
                    content="things to avoid early pregnancy,pregnancy calculator, ovulation calculator, pregnancy symptoms, due date calculator, health, safe, Sukh Prasavam, Sukhprasavam"
                />
            </Helmet>
            <div className="newbg">
                <div style={{ padding: "10% 0%" }}>
                    <div className="login-wrapper" style={styles.flex}>
                        <div
                            style={styles.content}
                            className="flexdisplay mobile-view-hidden"
                        >
                            <h1 className="margin50 gilroy"> Launching </h1>
                            <img className="slogo" src={slogo} />
                        </div>
                        <div
                            className="loginimage"
                            style={{
                                color: "#fff",
                            }}
                        >
                            <img src={LoginGirl} alt="" style={styles.imagep} />
                        </div>
                        {showForm ? (
                            <>
                                <Form />
                            </>
                        ) : (
                            <>
                                <div className="margin-auto">
                                    {" "}
                                    <button
                                        type="button"
                                        className="hidepc"
                                        onClick={handleLogin}
                                        style={{
                                            background: "none",
                                            border: "1px solid #fff",
                                            color: "#fff",
                                            width: "149px",
                                            height: "37px",
                                            borderRadius: "4px",
                                            cursor: "pointer",
                                            margin: "2%",
                                        }}
                                    >
                                        Login
                                    </button>
                                </div>
                                <div
                                    className="form-wrapper"
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        color: "#fff",
                                        padding: "20px",
                                        width: "60%",
                                        borderRadius: "20px",
                                        boxShadow: "rgb(0 0 0 / 16%) 12px 10px 60px",
                                        backdropFilter: "blur(39px)",
                                        background: "rgba(0, 0, 0, 0.16)",
                                    }}
                                >
                                    <div
                                        style={{ display: "flex", justifyContent: "space-between" }}
                                    >
                                        {/* <h1 className="fontsize50" style={{ margin: "0" }}>
                    sukh prasavam
                  </h1>{" "}
                  <button
                    type="button"
                    style={{
                      background: "none",
                      border: "1px solid #fff",
                      color: "#fff",
                      width: "102px",
                      borderRadius: "4px",
                      cursor: "pointer",
                      margin: "2%",
                    }}
                    onClick={() => setShowForm(false)}
                  >
                    Back
                  </button>
                </div> */}
                                        <h1 className="  fontsize50" style={{ margin: "0" }}>
                                            sukh prasavam
                                        </h1>{" "}
                                        <button
                                            className="hidemboile"
                                            type="button"
                                            onClick={handleLogin}
                                            style={{
                                                background: "none",
                                                border: "1px solid #fff",
                                                color: "#fff",
                                                width: "102px",
                                                borderRadius: "4px",
                                                cursor: "pointer",
                                                margin: "2%",
                                                position: "relative",
                                                top: "-85px",
                                            }}
                                        >
                                            Login
                                        </button>
                                    </div>
                                    <div />
                                    <h2> pleasing birth without fear</h2>{" "}
                                    
                                </div>
                            </>
                        )}
                    </div>
                    {showForm ? (
                        <>
                            <div style={styles.content} className="pc-view-hidden">
                                <h1 className="margin50 fontsize50">sukh prasavam</h1>
                                <h2> pleasing birth without fear</h2>
                            </div>
                        </>
                    ) : null}
                </div>
            </div>
        </>
    );
};

export default Loginpostnatal;

const styles = {
    content: {
        color: "white",
        // display: "flex",
        textAlign: "center",
        justifyContent: "center",
        margin: "30px auto",
    },

    imagep: { width: "100%" },
    flex: {
        display: "flex",
        justifyContent: "space-between",
        width: "70%",
        margin: "auto",
    },
};
