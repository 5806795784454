import StartImg from "../../img/start.png";
import StopImg from "../../img/stop.png";
import green from "../../img/Group1.png";
import lightgreen from "../../img/Group2.png";
import yellow from "../../img/Group3.png";
import orange from "../../img/Group4.png";
import red from "../../img/Group5.png";
import axios from "axios";
import "./contration.css";
import { useEffect, useRef, useState } from "react";
import moment from "moment";
export default function ContractionCounter() {
  const [count, setCount] = useState(0);
  // const [timerState, setTimerState] = useState({});
  const [activate, setactivate] = useState(false);
  const [begin, setBegin] = useState(false);
  const [timerState, setTimerState] = useState({});
  const [stop, setstop] = useState();
  const [selectedValue, setSelectedValue] = useState(0);
  const [timestamp, setTimestamp] = useState(null);
  const intervalRef = useRef(null);

  const handleChange = (e) => {
    setSelectedValue(e.target.value);
    //this.setState({ currentStepIndex: e.currentTarget.value });
  };

  // const handleChange = (e) => {
  //   setSelectedValue(e.target.value);

  //   /// console.log(1);
  // };
  const handleClick = () => {
    setBegin((prev) => !prev);

    setTimerState({ ...timerState, start: moment().format("HH:mm:ss") });
  };

  const handleStop = () => {
    //console.log(prev);
    setactivate(true);
    setBegin((prev) => !prev);
    setstop({ ...stop, stop: new Date() });
  };

  useEffect(() => {
    if (begin) {
      setTimestamp(new Date().toString());
      intervalRef.current && clearInterval(intervalRef.current);

      const startTime = Date.now() - count;
      const intervalId = setInterval(() => {
        setCount(Date.now() - startTime);
      }, 1);
      intervalRef.current = intervalId;
    } else {
      intervalRef.current && clearInterval(intervalRef.current);
    }
  }, [begin]);

  const formatTimer = () => {
    const milliseconds = count % 1000;
    const seconds = Math.floor((count / 1000) % 60);
    const minutes = Math.floor(count / 60000);

    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}.${milliseconds.toString().padStart(3, "0")}`;
  };

  function calculate() {
    let scale = "no pain";

    if (selectedValue === "1") {
      scale = "no pain";
    } else if (selectedValue === "2") {
      scale = "mild";
    } else if (selectedValue === "3") {
      scale = "moderate";
    } else if (selectedValue === "4") {
      scale = "severe";
    } else if (selectedValue === "5") {
      scale = "worst pain";
    }

    const contractionDuration = count / 1000; // Divide by 1000 to convert milliseconds to seconds

    const Data = {
      painScale: scale,
      time: timerState.start,
      contraction: contractionDuration, // Use the timer value as contraction duration
      date: moment().format("YYYY-MM-DD"),
    };

    console.log("Data being sent:", Data);

    const headers = {
      Authorization: `token ` + localStorage.getItem("token"),
    };

    axios
      .post("customer/contraction/", Data, {
        headers: headers,
      })
      .then(() => {
        alert("Success Submited");
        window.location.reload();
      })
      .catch((error) => {
        alert("Error occurred when updating");
        window.location.reload();
      });
  }

  return (
    <>
      <div className="scoller">
        {/* {console.log(timerState)}
      {console.log(stop)} */}

        <h2>{formatTimer()} </h2>
        <div style={{ padding: "2rem 0rem" }}>
          <div className="contration" style={styles.dflex}>
            <div onClick={handleClick} style={{ cursor: "pointer" }}>
              <img style={styles.img} src={StartImg} alt="" />
              <br />

              <small>Start</small>
              {/* <button>Start</button> */}
            </div>
            <div className="innercounter">
              <input
                className="innercounter"
                style={{ width: "394px" }}
                onInput={handleChange}
                id="myinput"
                type="range"
                min="1"
                value={selectedValue}
                max="5"
                step="1"
                list="tick-list"
              />
              <div
                className="innercounter"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "394px",
                }}
              >
                <p>
                  {" "}
                  <img style={styles.img} src={green} alt="" />
                </p>{" "}
                <p>
                  {" "}
                  <img style={styles.img} src={lightgreen} alt="" />
                </p>{" "}
                <p>
                  {" "}
                  <img style={styles.img} src={yellow} alt="" />
                </p>{" "}
                <p>
                  {" "}
                  <img style={styles.img} src={orange} alt="" />
                </p>{" "}
                <p>
                  {" "}
                  <img style={styles.img} src={red} alt="" />
                </p>
              </div>
            </div>
            {/* <select
            style={styles.select}
            onChange={handleChange}
            value={selectedValue}
            required
          >
            <option style={styles.options} value="NO PAIN">
              Select Pain Scale
            </option>
            <option style={styles.options} value="NO PAIN">
              0 INO PAIN
            </option>
            <option style={styles.options} value="Mild">
              1-3 | MiLD
            </option>
            <option style={styles.options} value="MODERATE">
              4-6 | MODERATE
            </option>
            <option style={styles.options} value="SEVERE">
              7-9 | SEVERE
            </option>
            <option style={styles.options} value="WORST PAIN">
              10 | WORST PAIN
            </option>
          </select> */}
            <div onClick={handleStop} style={{ cursor: "pointer" }}>
              <img style={styles.img} src={StopImg} alt="" />
              <br />
              <small>Stop</small>
              {/* <button>Stop</button> */}
            </div>
          </div>
          {/* {timestamp} */}
          <button
            style={styles.sbtn}
            disabled={!timerState}
            onClick={calculate}
          >
            Calculate
          </button>
        </div>
      </div>
    </>
  );
}

const styles = {
  // options: { background: "#4045f3d6" },
  dflex: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 20px",
    alignItems: "center",
  },

  img: {
    height: "50px",
  },

  select: {
    background: "#7F45CC",
    border: "none",
    color: "#fff",
    height: "20px",
    padding: "3px",
    borderRadius: "10px",
  },

  sbtn: {
    background: "#1D8DB0",
    color: "#fff",
    border: "1px solid #1D8DB0",
    borderRadius: "8px",
    padding: "10px 30px",
  },
};
