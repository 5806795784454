import React from "react";
import { withAlert } from "react-alert";
import axios from "axios";
import AddMedicine from "./innercom/add_medicine";
import moment from "moment";
class Medicinetracker extends React.Component {
  // export default class Medicinetracker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      medicineOpen: true,
      medicineReady: true,
      morningafter: [],
      morningbefore: [],
      afternoonbefore: [],
      afternoonafter: [],
      dateState: [],
      nightafter: [],
      items: [],
      todaysdate: new Date(),
      today: "", // todaysdate: new Date(),

      //console.log(dateState);

      checked: [],
      allValues: "",
    };

    this.closeMedicine = this.closeMedicine.bind(this);
    this.addMedicine = this.addMedicine.bind(this);
    // this.handleCheck = this.handleCheck.bind(this);
    this.handleCheckClick = this.handleCheckClick.bind(this);
  }

  closeMedicine() {
    this.setState({ medicineOpen: !this.state.medicineOpen });
  }

  addMedicine() {
    this.setState({ medicineReady: !this.state.medicineReady });
  }
  dateHandler = (e) => {
    var dateselected = e.target.value;

    var date = moment(e.target.value).format("YYYY-MM-DD");
    console.log(date);
    this.setState({ dateState: date }, this.getData.bind(this));
  };
  componentWillMount() {
    // var someDate = new Date();
    // let date = moment(this.state.dateState).format("YYYY-MM-DD");
    this.getData();
  }

  getData() {
    var date = moment(this.state.dateState).format("YYYY-MM-DD");
    this.setState({ today: date });
    const config = {
      headers: {
        Authorization: `token ` +localStorage.getItem("postclient"),
      },
    };

    axios.get("http://127.0.0.1:8000/customer/medicine-GET/", config).then((res) => {
      let morningbefore = res.data.find(
        (o) => o.MedicationTime === "morning before food"
      );

      let morningafter = res.data.find(
        (o) => o.MedicationTime === "morning after food"
      );
      console.log(morningafter);
      let afternoonbefore = res.data.find(
        (o) => o.MedicationTime === "afternoon before food"
      );

      let afternoonafter = res.data.find(
        (o) => o.MedicationTime === "afternoon after food"
      );

      let nightafter = res.data.find(
        (o) => o.MedicationTime === "night after food"
      );

      let nightbefore = res.data.find(
        (o) => o.MedicationTime === "night before food"
      );
      this.setState({ morningafter: morningafter.Medicines });
      this.setState({ morningbefore: morningbefore.Medicines });
      this.setState({ afternoonbefore: afternoonbefore.Medicines });
      this.setState({ afternoonafter: afternoonafter.Medicines });
      this.setState({ nightafter: nightafter.Medicines });
      this.setState({ items: nightbefore.Medicines });
    });
  }

  handleCheckClick = (e, stateVal, index, alert) => {
    //modifying the state based on chec value
    let prevState = [...this.state[stateVal]];
    prevState[index].taken = e.target.checked;
    this.setState({ [stateVal]: prevState });
    var date = moment(this.state.dateState).format("YYYY-MM-DD");
    const { name, checked } = e.target.dataset;

    var checkbox = e.target.checked;

    console.log(checkbox);
    const Data = {
      taken: checkbox,
      date: date,
      medicine: name,
    };
    const headers = {
      Authorization: `token ` + localStorage.getItem("postclient"),
    };
    axios
      .patch("http://127.0.0.1:8000/customer/medicine-update/", Data, {
        headers: headers,
      })
      .then(() => {
        alert.show("Medicine was submitted!");
        // alert("Medicine was submitted");
      })
      .catch((error) => {
        alert.show(error);
        // alert("Cannot add Medicine again");
      });

    //  if (e.target.checked === "true") {
    //    console.log(name, checked);
    //  } else {
    //    console.log(name, checked);
    //  }
  };

  render() {
    const alert = this.props.alert;
    return (
      <>
        {this.state.medicineOpen ? (
          <div className="medicine-tracker" style={styles.module}>
            <h2
              className="lightgradient
"
              style={{
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
                padding: "10px 0px",
                fontSize: "24px",
                margin: "0px",
                // background: "#8c3186",
              }}
            >
              Medicine Tracker {this.state.today}{" "}
              {/* {console.log(this.state.today)} */}
              {/* {todays} */}
            </h2>
            <table
              style={{ borderCollapse: "seperate", borderSpacing: "0em 1em" }}
            >
              <tr style={styles.row}>
                <td>Enter Date</td>
                <td>
                  <input
                    className="calanderbutton"
                    type="date"
                    id="myDate"
                    onChange={this.dateHandler}
                    defaultValue={this.state.today}
                  />
                </td>
              </tr>
              <tr style={styles.row}>
                <td style={styles.txt1}>Morning Before Food</td>

                <td style={styles.gridrow} className="gridfr">
                  {this.state.morningbefore.map((itm, key) => {
                    return (
                      <>
                        {console.log(itm.taken)}
                        <td key={itm.id}>
                          <div style={styles.gridinner}>
                            <span style={styles.txt}>{itm.name}</span>
                            {console.log("reloaded")}
                            <input
                              data-name={itm.id}
                              type="checkbox"
                              // value={itm && itm.taken}
                              // data-checked={itm.taken}
                              checked={itm.taken}
                              onChange={(e) =>
                                this.handleCheckClick(
                                  e,
                                  "morningbefore",
                                  key,
                                  alert
                                )
                              }
                            />
                          </div>
                        </td>
                      </>
                    );
                  })}
                </td>
              </tr>
              {console.log(this.state.morningafter)}
              <tr style={styles.row}>
                <td style={styles.txt1}>Morning after Food</td>
                <td style={styles.gridrow} className="gridfr">
                  {this.state.morningafter.map((itm, key) => {
                    return (
                      <>
                        <td>
                          <div style={styles.gridinner}>
                            <span style={styles.txt}>{itm.name}</span>
                            <input
                              data-name={itm.id}
                              type="checkbox"
                              // data-checked={itm.taken}
                              value={itm && itm.taken}
                              checked={itm && itm.taken}
                              onChange={(e) =>
                                this.handleCheckClick(
                                  e,
                                  "morningafter",
                                  key,
                                  alert
                                )
                              }
                            />
                          </div>
                        </td>
                      </>
                    );
                  })}
                </td>
              </tr>

              <tr style={styles.row}>
                <td style={styles.txt1}>afternoon Before Food</td>
                <td style={styles.gridrow} className="gridfr">
                  {this.state.afternoonbefore.map((itm, key) => {
                    return (
                      <>
                        <td>
                          <div style={styles.gridinner}>
                            <span style={styles.txt}>{itm.name}</span>

                            <input
                              data-name={itm.id}
                              type="checkbox"
                              // data-checked={itm.taken}

                              checked={itm.taken && itm}
                              onChange={(e) =>
                                this.handleCheckClick(
                                  e,
                                  "afternoonbefore",
                                  key,
                                  alert
                                )
                              }
                            />
                          </div>
                        </td>
                      </>
                    );
                  })}
                </td>
              </tr>
              <tr style={styles.row}>
                <td style={styles.txt1}>afternoon after Food</td>
                <td style={styles.gridrow} className="gridfr">
                  {this.state.afternoonafter.map((itm, key) => {
                    return (
                      <>
                        <td>
                          <div style={styles.gridinner}>
                            <span style={styles.txt}>{itm.name}</span>
                            <input
                              data-name={itm.id}
                              type="checkbox"
                              // data-checked={itm.taken}
                              checked={itm.taken && itm}
                              onChange={(e) =>
                                this.handleCheckClick(
                                  e,
                                  "afternoonafter",
                                  key,
                                  alert
                                )
                              }
                            />
                          </div>
                        </td>
                      </>
                    );
                  })}
                </td>
              </tr>
              <tr style={styles.row}>
                <td style={styles.txt1}>Night Before Food</td>
                <td style={styles.gridrow} className="gridfr">
                  {this.state.items.map((itm, key) => {
                    return (
                      <>
                        <td>
                          <div style={styles.gridinner}>
                            <span style={styles.txt}>{itm.name}</span>
                            <input
                              data-name={itm.id}
                              type="checkbox"
                              // data-checked={itm.taken}
                              checked={itm.taken && itm}
                              onChange={(e) =>
                                this.handleCheckClick(e, "items", key, alert)
                              }
                            />
                          </div>
                        </td>
                      </>
                    );
                  })}
                </td>
              </tr>

              <tr style={styles.row}>
                <td style={styles.txt1}>Night After Food</td>
                <td style={styles.gridrow} className="gridfr">
                  {this.state.nightafter.map((itm, key) => {
                    return (
                      <>
                        <td>
                          <div style={styles.gridinner}>
                            <span style={styles.txt}>{itm.name}</span>
                            <input
                              data-name={itm.id}
                              type="checkbox"
                              // data-checked={itm.taken}
                              // value={itm.taken}
                              defaultChecked={itm.taken && itm}
                              onChange={(e) =>
                                this.handleCheckClick(
                                  e,
                                  "nightafter",
                                  key,
                                  alert
                                )
                              }
                            />
                          </div>
                        </td>
                      </>
                    );
                  })}
                </td>
              </tr>

              <tr></tr>
              <tr>
                <td></td>
                <td></td>
              </tr>
            </table>
            <div>
              {this.state.medicineReady ? null : <AddMedicine />}
              <div>
                <button
                  style={styles.sbtn}
                  onClick={this.addMedicine}
                  className="pinkbutton"
                >
                  Add medicine
                </button>
              </div>

              <div>{/* <button style={styles.sbtn}>Submit</button> */}</div>
            </div>
          </div>
        ) : null}
      </>
    );
  }
}
export default withAlert()(Medicinetracker);
const styles = {
  module: {
    height: "550px",
    overflowY: "scroll",
    scrollbarWidth: "none",

    // background: "rgb(76, 0, 61) none repeat scroll 0% 0%",
    margin: "0px auto",
    borderRadius: "10px",
    padding: "0px 0px",
    width: "94%",
  },
  txt: {
    textAlign: "left",
  },
  txt1: {
    textTransform: "capitalize",
    textAlign: "center",
  },
  row: {
    background: "#ffffff1c 0% 0% no-repeat padding-box",
    borderRight: "50px",
  },
  addmedicine: {
    padding: "5px 60px",
    background: "none",
    borderLeft: "none",
    borderTop: "none",
    borderRight: "none",
    borderRadius: "0px",
    borderBottom: "1px solid #fff",
    color: "#fff",
  },
  sbtn: {
    padding: "1rem 5rem",
    // border: "1px solid #6984B0",
    // background: "linear-gradient(90deg, #6984B0, #B16DD4)",
    margin: "1rem 0rem",
    color: "#fff",
    borderRadius: "10px",
    cursor: "pointer",
  },

  gridrow: {
    display: "grid",
    // gridTemplateColumns: "1fr 1fr",
  },

  gridinner: { display: "grid", gridTemplateColumns: "3fr 1fr" },
};
