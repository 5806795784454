import React from "react";
import Image from "../img/small_image.jpeg";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
//import DietTracker from '../img/balance_diet.png';
import "./custom.css";
import { Link } from "react-router-dom";
import axios from "axios";

class Clist extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customer: [],
      details: [],
      firstname: "",
      lastname: "",
      age: "",
      email: "",
      address: "",
      mobile: "",
      Idproof: "",
      husband: "",
      job: "",
    };
  }

  handleChange = (event) => {
    const { name, defaultValue } = event.target;
    this.setState({ [name]: defaultValue });
  };
  submitPersonalDetails = (event) => {
    event.preventDefault();
    console.log("Submitted Personal Details:", this.state);
  };
  // Function to submit medical details

  componentDidMount() {
    axios
      .get(`customer-profile/`, {
        headers: {
          Authorization: `Token ${sessionStorage.getItem("token")}`,
        },
        params: {
          customer: this.props.id,
        },
      })
      .then((res) => {
        console.log(res.data.customer);
        this.setState({ customer: res.data.customer });
        this.setState({ details: res.data.details });
      });
  }

  render() {
    // destructure data from states
    let { firstname, lastname, email, mobile, doctor, patient, profile_pic } =
      this.state.customer;
    let {
      address,
      Idproof,
      husband,
      job,
      age,
      marriedSince,
      babies_number,
      abortions,
      twins,
      diabetes,
      allergic_reaction,
      surgery,
      Menstruation,
      Menstruation_date,
      hereditory,
    } = this.state.details;

    return (
      <div style={styles.card} className="lightgradient2">
        <div style={styles.inner}>
          <Tabs>
            <TabList
              style={{
                width: "37rem",
                listStyle: "none",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Tab>
                <strong style={{ padding: "10px", cursor: "pointer" }}>
                  Personal Detail
                </strong>
              </Tab>
              <Tab>
                <strong style={{ padding: "10px", cursor: "pointer" }}>
                  Medical Detail
                </strong>
              </Tab>
            </TabList>

            <TabPanel>
              <table className="learning-table-form">
                <tr>
                  <td>
                    {profile_pic !=
                    "https://sukhprasavam.shebirth.com/media//ProfilePic/default.jpg" ? (
                      <img
                        style={{ maxWidth: "65px" }}
                        className="avatar"
                        src={profile_pic}
                        alt=""
                      />
                    ) : profile_pic ==
                      "https://sukhprasavam.shebirth.com/media//ProfilePic/default.jpg" ? (
                      <button
                        className="avatar aquamarine "
                        style={{ maxWidth: "65px", border: "none" }}
                      ></button>
                    ) : (
                      <button
                        className="avatar aquamarine "
                        style={{ maxWidth: "65px", border: "none" }}
                      ></button>
                    )}
                    {/* {profile_pic ? (
                      <img
                        src={profile_pic}
                        height="75px"
                        width="75px"
                        style={{ borderRadius: "50%" }}
                        alt=""
                      />
                    ) : (
                      <img
                        src={Image}
                        height="75px"
                        width="75px"
                        style={{ borderRadius: "50%" }}
                        alt=""
                      />
                    )} */}
                    <br />
                    <span style={styles.money}>{}</span>
                  </td>
                  <td style={styles.col}>
                    <strong style={{ fontSize: "20px" }}>
                      {firstname} {lastname}
                    </strong>
                    <br />
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <small
                        style={{
                          padding: "3px 0px",
                          borderBottom: "1px solid #fff",
                        }}
                      >
                        Username:
                        <span style={{ marginLeft: "10px" }}>{firstname}</span>
                      </small>
                      <small
                        style={{
                          padding: "3px 0px",
                          borderBottom: "1px solid #fff",
                        }}
                      >
                        Female | {age}
                        <span style={{ marginLeft: "10px" }}>{mobile}</span>
                      </small>
                      <br />
                    </div>
                  </td>

                  <td style={styles.col}>
                    <Link
                      to={`/admin/calllist/${this.props.id}`}
                      // style={styles.tolink}
                    >
                      <span style={styles.ftext}>call status</span>
                    </Link>

                    <br />
                  </td>
                </tr>
                <tr>
                  {" "}
                  <td></td>
                  <td>first name</td>
                  <input
                    type="text"
                    name="firstname"
                    defaultValue={firstname}
                    onChange={this.handleChange}
                  />
                </tr>{" "}
                <tr>
                  {" "}
                  <td></td>
                  <td style={styles.co}>last name</td>
                  <td>
                    <input
                      type="text"
                      name="lastname"
                      defaultValue={lastname}
                      onChange={this.handleChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td style={styles.co}>Age</td>
                  <td>
                    <input
                      name="firstname"
                      onChange={this.handleChange}
                      type="text"
                      defaultValue={age}
                    />
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td style={styles.co}>Email</td>
                  <td>
                    <input
                      name="firstname"
                      onChange={this.handleChange}
                      type="text"
                      defaultValue={email}
                    />
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td style={styles.co}>Address</td>
                  <td>
                    <input
                      name="firstname"
                      onChange={this.handleChange}
                      type="text"
                      defaultValue={address}
                    />
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td style={styles.co}>Mobile</td>
                  <td>
                    <input
                      name="firstname"
                      onChange={this.handleChange}
                      type="text"
                      defaultValue={mobile}
                    />
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td style={styles.co}>Individual ID proof with photo</td>
                  <td>
                    <input
                      name="firstname"
                      onChange={this.handleChange}
                      type="text"
                      defaultValue={Idproof}
                    />
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td style={styles.co}>Husband Name</td>
                  <td>
                    <input
                      name="firstname"
                      onChange={this.handleChange}
                      type="text"
                      defaultValue={husband}
                    />
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td style={styles.co}>Your Job</td>
                  <td>
                    <input
                      name="job"
                      onChange={this.handleChange}
                      type="text"
                      defaultValue={job}
                    />
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <button onClick={this.submitPersonalDetails}>Submit</button>
                  </td>
                </tr>
              </table>
            </TabPanel>

            <TabPanel>
              <table className="learning-table-form">
                <tr>
                  <td>Married Since</td>
                  <td>
                    <input
                      name="marriedSince"
                      onChange={this.handleChange}
                      type="text"
                      defaultValue={marriedSince}
                    />
                  </td>
                </tr>
                <tr>
                  <td>How Many Baby She Has?</td>
                  <td>
                    <input
                      name="babies_number"
                      onChange={this.handleChange}
                      type="text"
                      defaultValue={babies_number}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Any History Of Abortion?</td>
                  <td>
                    <input
                      name="abortions"
                      onChange={this.handleChange}
                      type="text"
                      defaultValue={abortions}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Any History Of Twins In Family?</td>
                  <td>
                    <input
                      name="twins"
                      onChange={this.handleChange}
                      type="text"
                      defaultValue={twins}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Any Family History Of Diabetes?</td>
                  <td>
                    <input
                      name="diabetes"
                      onChange={this.handleChange}
                      type="text"
                      defaultValue={diabetes}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Any History Of Allergic Reaction? If Yes Specify. </td>
                  <td>
                    <input
                      name="allergic_reaction"
                      onChange={this.handleChange}
                      type="text"
                      defaultValue={allergic_reaction}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Any History Surgery? If Yes Specify.</td>
                  <td>
                    <input
                      name="surgery"
                      onChange={this.handleChange}
                      type="text"
                      defaultValue={surgery}
                    />
                  </td>
                </tr>
                <tr>
                  <td>How Many Days Menstrual Period Cycle?</td>
                  <td>
                    <input
                      name="Menstruation"
                      onChange={this.handleChange}
                      type="text"
                      defaultValue={Menstruation}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Last Period Date</td>
                  <td>
                    <input
                      name="Menstruation_date"
                      onChange={this.handleChange}
                      type="text"
                      defaultValue={Menstruation_date}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Any Hereditary Health Related Complications?</td>
                  <td>
                    <input
                      name="hereditory"
                      onChange={this.handleChange}
                      type="text"
                      defaultValue={hereditory}
                    />
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <button onClick={() => this.submitMedicalDetails()}>
                      Submit
                    </button>
                  </td>
                </tr>
              </table>
            </TabPanel>
          </Tabs>
        </div>
      </div>
    );
  }
}

const styles = {
  card: {
    // display: "flex",
    justifyContent: "space-between",
    // background: "#4c003d",
    padding: "20px",
    borderRadius: "10px",
    margin: "10px",
  },

  inner: {
    textDecoration: "none",
    color: "#fff",
  },

  ftext: { border: "1px solid grey" },
};

export default Clist;
