import React from "react";
import "../../App.css";
// import './login.css';
import LoginGirl from "../img/login_girl.png";
import Form from "./form";

export default class ClientLogin extends React.Component {
  render() {
    return (
      <>
        <div
          style={{
            padding: "10% 0%",
          }}
        >
          <div className="login-wrapper" style={styles.flex}>
            <div
              style={{
                color: "#fff",
              }}
            >
              <img src={LoginGirl} alt="" />
              <h1>Launching Smart Labour</h1>
              <small>Who recommands only 15% c-sector rate in a nation</small>
            </div>

            <Form />
          </div>
        </div>
      </>
    );
  }
}

const styles = {
  flex: {
    display: "flex",
    justifyContent: "space-between",
    width: "70%",
    margin: "auto",
  },
};
