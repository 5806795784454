import React, { useState } from "react";
import axios from "axios";
import Calendar from "react-calendar";
import ACalendar from "./calendar";

import moment from "moment";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "../slot-tabs.css";
import { useHistory } from "react-router-dom";

export default function Reshedule(props) {
  const [open, setopen] = useState(false);
  const history = useHistory();

  //   constructor(props) {
  //     super(props);
  //     console.log(props);
  //     var userid = props.userId;
  //     this.state = {
  //       isClose: false,
  //       userid1: { id: props.userid },
  //       time: null,
  //       dateState: new Date(),
  //     };
  //     this.closeReshedule = this.closeReshedule.bind(this);
  //     this.handleSubmit = this.handleSubmit.bind(this);
  //     this.openCriticality = this.openCriticality.bind(this);
  //   }

  function openCriticality(e) {
    // e.preventDefault();
    console.log("hello");
    console.log(e);
    history.push(`/criticality/${e} `);
    // window.location.reload();
  }
  const analaitics = (e) => {
    // e.preventDefault();

    history.push(`/defaultanalatics/${e} `);
    //  window.location.reload();
  };

  function closeReshedule(e) {
    setopen(!open);
    // this.setState({ isClose: true });
  }

  //   render() {
  return (
    <>
      {open ? null : (
        <div className="shedule-card " style={styles.cover}>
          <div style={styles.close} onClick={closeReshedule}>
            <i className="fa fa-close"></i>
          </div>
          <div style={styles.header}>
            <strong>Analysis</strong>
          </div>
          <div className="reshedule-sepr" style={styles.sepr}>
            <button
              onClick={() => analaitics(props.userId)}
              style={styles.sbtn}
            >
              Analysis 1
            </button>
            <button
              onClick={() => openCriticality(props.userId)}
              style={styles.sbtn}
            >
              Analysis 2
            </button>{" "}
          </div>
        </div>
      )}
    </>
  );
}

const styles = {
  header: {
    padding: "1rem",
    borderBottom: "1px solid #fff",
  },
  cover: {
    width: "40.5rem",
    position: "fixed",
    backdropFilter: "blur(10px)",
    backgroundColor: "rgba(255, 255, 255, 0.14)",
    // background: "#AB519A",
    zIndex: "6",
    borderRadius: "10px",
    top: "50%",
    left: "auto",
  },

  close: {
    position: "absolute",
    top: "-25px",
    right: "-10px",
    color: "#333",
    borderRadius: "50%",
    background: "#fff",
    width: "30px",
    height: "30px",
    cursor: "pointer",
  },

  sepr: {
    display: "flex",
    height: "90px",
    justifyContent: "space-between",
  },

  slot: {
    padding: "10px",
    width: "260px",
    background: "#4C003D",
    borderRadius: "10px",
    overflowX: "hidden",
    height: "260px",
  },

  sm: {
    fontSize: "12px",
    padding: "5px",
    color: "white",
    marginBottom: "5px",
    background: "#13002F",
    borderRadius: "5px",
  },

  p: {
    textAlign: "left",
    padding: "10px",
    background: "#661851",
    fontSize: "14px",
    borderRadius: "10px",
  },

  sbtn: {
    background: "#E81A7F",
    color: "#fff",
    padding: "7px 40px",
    border: "none",
    margin: " auto ",
    borderRadius: "5px",
    cursor: "pointer",
    marginBottom: "1rem",
  },
};
