import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DocPic from "../img/doctor.png";
import InnerDetail from "./innerto_detail";
import "./paggination.css";
import axios from "axios";
export default function InnerSpecialities() {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [price, setprice] = useState();
  const [minVal, setMinVal] = useState();
  const [maxVal, setMaxVal] = useState();
  const [priceSortSelect, setPriceSortSelect] = useState("Price- Low to high");
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [selectedValue, setSelectedValue] = useState();
  const [language, setlanguage] = useState();

  //const [hasId, setHasId] = useState(false);
  const [detail, setDetail] = useState(false);
  const [data, setdata] = useState([]);
  const inipath = window.location.pathname;
  const id_path = inipath.split("/cdoctors/details");

  const path = inipath.split("/cdoctors/");
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  const [gender, setGender] = useState();
  const handlePaginate = (pageNumber) => setCurrentPage(pageNumber);
  //function experienceSelection() {}
  // console.log(path);
  // const handleGenderSelect = (e) => {
  //   setGender(e.target.value);
  // };

  // const handleExperienceSelect = (e) => {
  //   const val = e.target.value;
  //   if (val.includes("-")) {
  //     const [min, max] = val.split("-");
  //     setMinVal(min);
  //     setMaxVal(max);
  //   } else {
  //     const min = val.substring(0, val.indexOf("+"));
  //     setMinVal(min);
  //     setMaxVal(null);
  //   }
  // };

  // const handlePriceSortSelect = (e) => {
  //   setPriceSortSelect(e.target.value);
  // };
  const filteredDataBylanguage = data.filter((d) => {
    //console.log(d.languages);

    if (language) {
      //let newstring = d.languages.includes(language);
      // console.log(d.languages);
      // console.log(language);
      // console.log(d.languages.includes(language.toLowerCase()));
      return (
        d.languages.includes(language) ||
        d.languages.includes(language.toLowerCase())
      );
    }
    return true;
  });
  const filteredDataByGender = filteredDataBylanguage.filter((d) => {
    if (gender) {
      return d.gender === gender || d.gender === gender.toLowerCase();
    }
    return true;
  });

  const filteredDataByExp = filteredDataByGender.filter((d) => {
    let result = true;
    if (minVal > 0 || maxVal > 0) {
      if (minVal > 0) {
        result = result && d.experience >= minVal;
      }
      if (maxVal > 0) {
        result = result && d.experience < maxVal;
      }
    }
    return result;
  });

  const sortedData = filteredDataByExp.sort((firstEl, secondEl) => {
    if (priceSortSelect.trim() === "Price- Low to high") {
      return firstEl.price > secondEl.price ? 1 : -1;
    }
    return firstEl.price <= secondEl.price ? 1 : -1;
  });

  const currentPosts = sortedData.slice(indexOfFirstPost, indexOfLastPost);
  // useEffect(() => {
  //   const pathname = window.location.pathname;
  //   const doctorId = pathname.split("/login")[2];

  //   if (doctorId) {
  //     setHasId(true);
  //   } else {
  //     setHasId(false);
  //   }
  // }, []);

  const Post = ({ data, loading }) => {
    if (loading) {
      // return <Loader />
    }

    return (
      <div style={styles.container}>
        {data.map((personData, key) => {
          return (
            <div style={styles.fbox}>
              <div style={styles.fitem}>
                <left>
                  {personData.profile_full_url !=
                  "https://sukhprasavam.shebirth.com/media//ProfilePic/default.jpg" ? (
                    <img
                      style={{ maxWidth: "65px" }}
                      className="avatar"
                      src={personData.profile_full_url}
                      alt=""
                    />
                  ) : personData.profile_full_url ==
                    "https://sukhprasavam.shebirth.com/media//ProfilePic/default.jpg" ? (
                    <button
                      className="avatar aquamarine "
                      style={{ maxWidth: "65px", border: "none" }}
                    ></button>
                  ) : (
                    <img src={DocPic} alt="" />
                  )}
                  {/* {personData.profile_full_url ? (
                    <img
                      style={{ maxWidth: "65px" }}
                      src={personData.profile_full_url}
                      alt=""
                    />
                  ) : (
                    <img src={DocPic} alt="" />
                  )} */}
                  {/* <img src={DocPic} alt="" /> */}
                  <br />
                  {console.log(personData)}
                  <i className="fa fa-video-camera"></i>
                  <br />
                  <small>Online</small>
                </left>
                <right style={{ textAlign: "left", marginLeft: "6px" }}>
                  <strong>
                    Dr {personData.firstname}&nbsp;
                    {personData.lastname}
                  </strong>
                  <br />
                  <small>
                    {" "}
                    {personData.speciality} |{" "}
                    <small>{personData.experience} &nbsp;Year Experience</small>
                  </small>
                  <br />
                  <strong>You Pay</strong>
                  <br />
                  <strong>&#x20b9; {personData.price}</strong>
                  <br />
                  <small>
                    {personData.qualification} |{personData.location}|
                    {personData.gender} | {personData.languages}
                  </small>
                </right>
              </div>
              <Link
                to={{
                  pathname: "/cdoctors/detiled/" + personData.id,
                  state: { id: personData.id, data: personData },
                }}
                onClick={OpenInner}
              >
                <button style={styles.book}>Book Appointment</button>
              </Link>
            </div>
          );
        })}
      </div>
    );
  };

  useEffect(() => {
    const pathname = window.location.pathname;
    const doctorId = pathname.split("/login")[2];
    console.log("this is the ", doctorId);
    // if (doctorId) {
    //   setHasId(true);
    // } else {
    //   setHasId(false);
    // }
    console.log(id_path);
    const substring = "detiled";
    console.log(inipath.includes(substring));
    if (inipath.includes(substring) === true) {
      setDetail(true);
    }
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("token"),
      },
    };

    function getData() {
      setLoading(true);
      axios
        .get("doctor/doctor-filter/?speciality=" + path[1], config)
        .then((res) => {
          let filteredData2 = [];
          console.log(res.data);
          var somevariable = res;
          setdata((data) => {
            return [...data, somevariable];
          });
          // if (selectedValue === "5-10") {
          //   res.data.forEach((e) => {
          //     if (e.experience < 5 && e.experience > 10) filteredData2.push(e);
          //     // console.log(filteredData);
          //     setdata(filteredData2);
          //   });

          setdata(res.data);
          //   console.log("hello");
          // } else {
          //   console.log("worked");
          // }
          //setdata(res.data);

          // setWeight(res.data.babyDetails.weigth);
          // setlength(res.data.babyDetails.length);
          // setbabyDetails(res.data.babyDetails);
          // setDescribtion(res.data.babyDetails.description);
        });
      setLoading(false);
    }

    getData();
  }, []);

  function OpenInner() {
    setDetail(true);
  }

  const Pagination = ({ postsPerPage, totalPosts, paginate }) => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
      pageNumbers.push(i);
    }

    return (
      <nav>
        <ul className="paginationn">
          {pageNumbers.map((number) => {
            return (
              <li key={number} style={{ display: "inline-block" }}>
                <a
                  onClick={() => paginate(number)}
                  className={currentPage === number ? "active" : ""}
                >
                  {number}
                </a>
              </li>
            );
          })}
        </ul>
      </nav>
    );
  };

  const handlelanguageSelect = (e) => {
    setlanguage(e.target.value);
  };

  const handleGenderSelect = (e) => {
    setGender(e.target.value);
  };

  const handleExperienceSelect = (e) => {
    const val = e.target.value;

    if (val === "all") {
      setMinVal(null);
      setMaxVal(null);
    } else if (val.includes("-")) {
      const [min, max] = val.split("-");
      setMinVal(min);
      setMaxVal(max);
    } else {
      const min = val.substring(0, val.indexOf("+"));
      setMinVal(min);
      setMaxVal(null);
    }
  };

  const handlePriceSortSelect = (e) => {
    setPriceSortSelect(e.target.value);
  };

  return (
    <>
      {detail ? (
        <InnerDetail />
      ) : (
        <div>
          <h4 style={{ textAlign: "left", marginLeft: "10px" }}>
            Top Specialities <i className="fa fa-angle-right"></i> {path}{" "}
          </h4>
          <hr
            style={{
              margin: "30px 10px",
              background: "#fff",
              height: "1px",
              border: "none",
            }}
          />

          <div className="clientscroll">
            <div className="list-container" style={styles.options}>
              {/* short maker */}

              <div style={styles.inneropt}>
                <i style={styles.rotate} class="fa fa-exchange"></i>Sort By:
                <select style={styles.sel} onChange={handlePriceSortSelect}>
                  <option style={styles.optionsdrop} value="">
                    All
                  </option>
                  {/* <option style={styles.optionsdrop}> Availability</option>
                  <option style={styles.optionsdrop}> Nearby</option> */}
                  <option style={styles.optionsdrop}>
                    {" "}
                    Price- Low to high
                  </option>
                  <option style={styles.optionsdrop}>
                    {" "}
                    Price- High to low
                  </option>
                </select>{" "}
              </div>
              <div style={styles.inneropt} onChange={handleGenderSelect}>
                Gender:
                <select style={styles.sel}>
                  <option style={styles.optionsdrop} value="">
                    All
                  </option>
                  <option style={styles.optionsdrop}> Male</option>
                  <option style={styles.optionsdrop}> Female</option>
                </select>{" "}
              </div>
              <div style={styles.inneropt}>
                Experience:
                <select onChange={handleExperienceSelect} style={styles.sel}>
                  <option style={styles.optionsdrop} value="all">
                    All
                  </option>
                  <option style={styles.optionsdrop} value="0-5">
                    0-5
                  </option>
                  <option style={styles.optionsdrop} value="5-10">
                    5-10
                  </option>
                  <option style={styles.optionsdrop} value="10-15">
                    10-15
                  </option>
                  <option style={styles.optionsdrop} value="15+">
                    15+
                  </option>
                </select>{" "}
              </div>
              <div
                className="splang"
                style={styles.inneropt}
                onChange={handlelanguageSelect}
              >
                Language:
                <select style={styles.sel}>
                  <option style={styles.optionsdrop} value="">
                    All
                  </option>
                  <option style={styles.optionsdrop} value="English">
                    English
                  </option>
                  <option style={styles.optionsdrop} value="hindi">
                    Hindi
                  </option>
                  <option style={styles.optionsdrop} value="malayalam">
                    Malayalam
                  </option>
                  <option style={styles.optionsdrop} value="tamil">
                    Tamil
                  </option>
                  <option style={styles.optionsdrop} value="kannada">
                    Kannada
                  </option>
                </select>{" "}
              </div>
            </div>
            <Post data={currentPosts} loading={loading} />

            <div style={styles.pagination} className="pagination">
              <Pagination
                postsPerPage={postsPerPage}
                totalPosts={data.length}
                paginate={handlePaginate}
              />
              <div>
                <div className="spplate" style={styles.plate}>
                  {/* <strong>Paediatrics</strong> */}
                  {/* <hr style={{ color: "#fff" }} /> */}
                  <small></small>
                  <br />
                  <strong>You can consult a Paeditatrician if</strong>
                  <br />
                  <small>
                    Your child has an injury, infection, condition, etc. Your
                    child displays behavioural problems, functional
                    disabilities, etc.
                    {/* <Link to="">READ MORE</Link> */}
                  </small>
                </div>
              </div>{" "}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const styles = {
  inneropt: {
    fontSize: "12px",
  },

  rotate: {
    transform: "rotate(90deg)",
    fontSize: "15px",
  },

  options: {
    display: "flex",
    justifyContent: "space-between",
    margin: "10px",
    background: "#ffffff1c 0% 0% no-repeat padding-box",
    //background: "#903289",
    padding: "10px",
  },

  sel: {
    background: "none",
    border: "none",
    color: "#fff",
  },
  container: {
    padding: "30px",
  },
  optionsdrop: {
    background: "0% 0% no-repeat padding-box padding-box rgb(90 10 90)",
  },

  fbox: {
    margin: "20px",
    width: "260px",
    background: "#ffffff1c 0% 0% no-repeat padding-box",
    //background: "#913D97",
    padding: "0px",
    fontSize: "17px",
    borderRadius: "10px",
    float: "left",
  },

  fitem: {
    display: "flex",
    padding: "10px",
    minHeight: "160px",
    justifyContent: "space-between",
  },

  book: {
    border: "none",
    width: "100%",
    padding: "10px",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    background: "#E81A7F",
    cursor: "pointer",
    textTransform: "uppercase",
    color: "#fff",
  },

  pagination: {
    float: "right",
    marginTop: "3rem",
  },

  plate: {
    textAlign: "left",
    // background: "#4A014D",
    // backdropFilter: "blur(36px) saturate(61%)",
    borderRadius: "10px",
    marginTop: "1rem",
    color: "#f0f8ff00",
    padding: "10px",
    marginLeft: "10px",
    marginBottom: "20px",
  },
};
