
import React from 'react';

export default function NewInvestigation({
    open,
    closeDialog,
    addNewField = () => { }
}) {

    const [fieldName, setFieldName] = React.useState("")

    const handleInput = (e) => {
        setFieldName(e.target.value)
    }

    return (

        <>

            {!open ? null :

                <div style={styles.wrapper}>
                    <div><strong>Add New Investigation</strong></div>
                    <hr />
                    <table>
                        <tr>
                            <td>Investigation</td>
                            <td><input onChange={handleInput} style={styles.input} value={fieldName} type="text" /></td>
                            <td><button type="button" onClick={addNewField(fieldName)} >Add</button></td>
                        </tr>
                    </table>
                    <button style={styles.done} onClick={closeDialog}>Done</button>
                </div>

            }
        </>

    );


}


const styles = {

    wrapper: {
        position: 'fixed',
        background: '#643EAA',
        padding: '10px',
        bottom: '2rem',
        margin: '10px'
    },

    done: {
        background: '#9581D4',
        border: '1px solid #9581D4',
        color: '#fff',
        padding: '10px 20px',
        borderRadius: '8px',
        cursor: 'pointer'
    },

    input: {
        background: '#FFCCFF'

    }






};
