import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
export default function MedicineTracker(props) {
  return (
    <div className="scrollerbar">
      <span className=" " style={styles.flex}>
        <button style={styles.pinkbutton2}>Mom</button>{" "}
        <button style={styles.pinkbutton}>baby</button>
      </span>

      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead style={{ borderBottom: "5px solid #5c0048" }}>
          <tr style={{ background: "#ffffff1c 0% 0% no-repeat padding-box" }}>
            <th style={styles.th}>Date</th>
            <th style={styles.th}>
              Morning Before
              <br /> Food
            </th>
            <th style={styles.th}>
              Morning after
              <br /> Food
            </th>
            <th style={styles.th}>
              Afternoon Before
              <br /> Food
            </th>
            <th style={styles.th}>
              Afternoon After
              <br /> Food
            </th>
            <th style={styles.th}>
              Night Before
              <br /> Food
            </th>
            <th style={styles.th}>
              Night After
              <br /> Food
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            style={{ background: "#ffffff1c 0% 0% no-repeat padding-box" }}
          ></tr>
        </tbody>
      </table>
    </div>
  );
}

const styles = {
  flex: { display: "flex", justifyContent: "center", margin: "2%" },
  pinkbutton: {
    background: "#ff0090",
    border: "none",
    color: "white",
    fontSize: "20px",
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px",
  },
  pinkbutton2: {
    background: "white",
    fontSize: "20px",
    color: "#ff0090",
    border: "none",
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
  },
  th: {
    fontSize: "17px",
    whiteSpace: "nowrap",
    padding: "5px 10px",
    margin: "10px 0px",
  },

  td: {
    borderLeft: "1px solid #fff",
    padding: "5px 0px",
    fontSize: "12px",
  },

  tdFirst: {
    padding: "5px 10px",
    fontSize: "12px",
  },
};
