import React from "react";
import axios from "axios";
import PropTypes from "prop-types";
import Sidebar from "../components/sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faUser, faBars } from "@fortawesome/free-solid-svg-icons";
import Toggler from "../profile/toggle";
import ClientProfile from "../profile/tog_profile";
import InnerSpecialities from "./components/inner_specialities";
//import Client from './components/clients';
//import Doctor from './components/doctors';
//import Table from './components/doctor_table';
//import Dcstatus from './components/dcstatus';
import Searchdoctor from "./components/search";
import Calendar from "../components/calendar";
import Experts from "../components/experts";

import Specialities from "./components/specialities";

export default class Overviewdocpostnatal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isToggleOn: true,
      isProfile: true,
      items: [],
      sidebar: true,
    };
    this.handleClick = this.handleClick.bind(this);
    this.setWapRef = this.setWapRef.bind(this);
    this.clickedOutside = this.clickedOutside.bind(this);
    this.profileHandler = this.profileHandler.bind(this);
  }

  handleClick() {
    this.setState((prevState) => ({
      isToggleOn: !prevState.isToggleOn,
    }));
  }

  profileHandler() {
    this.setState({ isProfile: !this.state.isProfile });
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.clickedOutside);

    if (localStorage.getItem("postclient") === null) {
      localStorage.clear();
      window.location = "/loginpostnatal";
    }
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("postclient"),
      },
    };

    axios.get("customer/customer-dashboard-details/", config).then((res) => {
      console.log(res.data);
      this.setState({ items: res.data });
    });
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.clickedOutside);
  }

  setWapRef(node) {
    this.wapRef = node;
  }

  clickedOutside(e) {
    if (this.wapRef && !this.wapRef.contains(e.target)) {
      this.setState({ isToggleOn: true });
      this.setState({ isProfile: true });
    }
  }

  render() {
    function openMenu() {
      document.querySelector(".sidebar").classList.toggle("show");
    }

    return (
      <div className="App">
        <Sidebar />
        <header className="overview">
          <div
            //  ref={this.setWapRef}
            className="grandiant"
            style={{ padding: "2% 10%", textAlign: "right" }}
          >
            {" "}
            <span onClick={openMenu} className="menu">
              <FontAwesomeIcon
                style={{ marginRight: "20px" }}
                icon={faBars}
                className="menu-icon"
              />
            </span>
            <div>{this.state.isToggleOn ? null : <Toggler />}</div>
            <span className="iconsofgradiant">
              <FontAwesomeIcon
                className="iconsofprofile"
                onClick={this.handleClick}
                style={{ marginRight: "1rem", cursor: "pointer" }}
                icon={faBell}
              />
            </span>
            <span className="iconsofgradiant">
              <FontAwesomeIcon
                className="iconsofprofile"
                onClick={this.profileHandler}
                style={{ cursor: "pointer" }}
                icon={faUser}
              />
            </span>{" "}
            {this.state.items.firstname} {this.props.childern}
            {this.state.isProfile ? null : <ClientProfile />}{" "}
          </div>

          <div style={{ width: "89%", display: "flex", paddingLeft: "10px" }}>
            <span style={{ whiteSpace: "nowrap" }}></span>
            <div
              style={{
                padding: "4px",
                display: "block",
                fontSize: "14px",
                float: "right",
              }}
            >
              {/* <hr style={{ background: "white", color: "white" }} /> */}
            </div>
          </div>

          <div className="mwrapper">
            <div className="mleft">
              <div
                style={{
                  display: "flex",
                  padding: "3% 1%",
                }}
              >
                <span style={{ whiteSpace: "nowrap" }}>Doctors</span>
                <div style={{ padding: "4px", width: "100%" }}>
                  <hr style={{ background: "white", color: "white" }} />
                </div>
              </div>
              <Searchdoctor />
              <InnerSpecialities />
            </div>
            <div className="mright calnderin" style={{ marginLeft: "1%" }}>
              <div
                style={{
                  fontWeight: "bold",
                  textAlign: "left",
                  paddingLeft: "10px",
                }}
              ></div>
              <p style={{ fontSize: "14px" }}>
                SELECT DATE FOR DAILY TRAKER DETAILS
              </p>
              <Calendar />
              {/* <Experts /> */}
            </div>
          </div>
        </header>
      </div>
    );
  }
}

Overviewdocpostnatal.propTypes = {
  children: PropTypes.element,
};
const styles = {
  grandiantbar: {
    padding: "2% 10%",
    textAlign: "right",
    background:
      " 0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
  },
};
