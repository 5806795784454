import axios from "axios";
import qs from "qs";
import { useState, useEffect } from "react";
export default function Learning_form() {
  const [freqentquestions, setfreqentquestion] = useState();
  const [ModuleName, setModuleName] = useState("");
  const [ModuleURL, setModuleURL] = useState("");
  const [Period, optionsState] = useState("");
  const [Relatedpost, setRelatedpost] = useState("");
  const [selectedValue, setSelectedValue] = useState(0);
  const [inputVal, setInputVal] = useState("");

  console.log(ModuleName);
  const handleChange = (e) => {
    setSelectedValue(e.target.value);

    /// console.log(1);
  };
  function question(props) {
    console.log("thsis", props);
  }

  const submitValue = (e) => {
    console.log(ModuleName);
    e.preventDefault();
    const Data = {
      stage: selectedValue,

      module: ModuleName,
      url: ModuleURL,

      relatedPost: Relatedpost,
      faq: freqentquestions,
    };

    const headers = {
      Authorization: `token ` + localStorage.getItem("admin_token"),
    };

    axios
      .post("http://127.0.0.1:8000/learnit/add-videos/", Data, {
        headers: headers,
      })

      .then(() => {
        // setincrement([...increment,res.data]) console.log(Data);
        //console.log("submited ");
        alert("success submited");
        //console.log(Data);
        //window.location.reload();
      })

      .catch((error) => {
        alert("error occured when updating");
        //console.log(Data);
        // console.log("handlesubmit error for blog ", error);
      });

    console.log(Data);
  };

  return (
    <>
      <form className="learning-form">
        <div style={{ margin: "0px" }}>
          <table className="learning-table-form">
            <tr>
              <td style={styles.td}>Enter Module Name</td>
              <td>
                <select
                  onChange={(e) => setModuleName(e.target.value)}
                  // value={selectedValue}
                  // required
                  // onChange={(e) => setPeriod(e.target.value)}
                  // value={optionsState}
                  style={styles.select}
                >
                  <option style={styles.option} value="diet">
                    Diet
                  </option>
                  <option style={styles.option} value="SKILL SET">
                    Skill Set
                  </option>
                  <option style={styles.option} value="STAY FIT">
                    Stay Fit
                  </option>
                  <option style={styles.option} value="Relaxation">
                    Relaxation
                  </option>
                  <option style={styles.option} value="lactation">
                    Lactation
                  </option>
                  <option style={styles.option} value="DELIVERY PROCESS">
                    Delivery Process
                  </option>
                  <option style={styles.option} value="NATURAL COPING">
                    Natural Coping
                  </option>
                  <option style={styles.option} value="new born baby care">
                    New Born Baby Care
                  </option>
                  <option style={styles.option} value="PLANNING BIRTH">
                    Planning Birth{" "}
                  </option>
                </select>
                {/* <input
                  onChange={(e) => setModuleName(e.target.value)}
                  style={styles.upload}
                  type="text"
                ></input> */}
              </td>
            </tr>
            <tr>
              <td style={styles.td}>Select Sub Module If Any</td>
              <td>
                <select style={styles.select}>
                  <option>Select</option>
                </select>
              </td>
              <td style={styles.td}>Module URL</td>
              <td>
                <input
                  onChange={(e) => setModuleURL(e.target.value)}
                  style={styles.upload}
                  type="text"
                ></input>
              </td>
            </tr>

            <tr>
              <td></td>
              <td></td>
              <td style={styles.td}>Related post</td>
              <td>
                <input
                  onChange={(e) => setRelatedpost(e.target.value)}
                  style={styles.upload}
                  type="text"
                ></input>
              </td>
            </tr>

            <tr>
              <td></td>
              <td></td>
              <td style={styles.td}>Frequently Asked Questions</td>
              <td>
                <input
                  onChange={(e) => setfreqentquestion(e.target.value)}
                  style={styles.upload}
                  type="text"
                ></input>
              </td>
            </tr>
            {/* <tr>
            <td style={styles.td}>Add New/Update Existing</td>
            <td>
              <select style={styles.select}>
                <option>Select</option>
              </select>
            </td>
          </tr>
          <tr>
            <td style={styles.td}>Enter Activity/Exercise Name</td>
            <td>
              <input style={styles.input} type="" />
            </td>
          </tr>
          <tr>
            <td style={styles.td}>Enter Sub Activity/Exercise Name</td>
            <td>
              <input style={styles.input} type="" />
            </td>
          </tr>
          <tr>
            <td style={styles.td}>Enter Sub Activity/Exercise Url</td>
            <td>
              <input style={styles.input} type="" />
            </td>
          </tr>
          <tr>
            <td style={styles.td}>Enter Sub Activity/Exercise Description</td>
            <td>
              <input style={styles.input} type="" />
            </td>
          </tr> */}
            <tr>
              <td></td>
              <td>
                <button onClick={submitValue} style={styles.button1}>
                  Submit
                </button>
              </td>
            </tr>
          </table>
        </div>
      </form>
    </>
  );
}

const styles = {
  td: {
    textAlign: "left",
    borderBottom: "4px",
  },
  select: {
    width: "13.5rem",
    textAlign: "center",
    padding: "6px",
    border: "1px solid #fff",
    background: "transparent",
    color: "#fff",
    textTransform: "capitalize",
  },
  input: {
    border: "1px solid #fff",
    padding: "6px",
    borderRadius: "3px",
    background: "transparent",
    color: "#fff",
  },
  upload: {
    width: "13.5rem",
    textAlign: "center",
    padding: "6px",
    border: "1px solid #fff",
    background: "transparent",
    color: "#fff",
    borderRadius: "3px",
  },
  button1: {
    background: "#e81a7f",
    padding: "10px 80px",
    border: "1px solid #e81a7f",
    width: "230px",
  },
  option: {
    textTransform: "capitalize",
    background: "#e81a7f",

    border: "1px solid #e81a7f",
  },
};
