import React from "react";
import axios from "axios";

import img1 from "../symp/Group1123.png";
import img2 from "../symp/Group1124.png";

import img3 from "../symp/Group1125.png";
import img4 from "../symp/Group1126.png";
import AddMedicine from "./add_medicine";
import AddSymptom from "./add_symptom";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

export default class SymptomTracker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      medicineOpen: true,
      medicineReady: true,
      symptomReady: false,
      items: [],
      custum: [],
      symptomsWithIputs: [],
      allValues: {
        others: "",
        bloodSugar: "",
        bloodPressure: "",
      },
    };
    this.closeMedicine = this.closeMedicine.bind(this);
    this.addMedicine = this.addMedicine.bind(this);
    this.addSymptom = this.addSymptom.bind(this);
  }

  closeMedicine() {
    this.setState({ medicineOpen: !this.state.medicineOpen });
  }

  addMedicine() {
    this.setState({ medicineReady: !this.state.medicineReady });
  }

  addSymptom() {
    this.setState({ symptomReady: !this.state.symptomReady });
  }
  componentDidMount() {
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("sales"),
      },
    };
    const date = moment().format("YYYY-MM-DD");
    axios
      .get(
        "customer/Symptoms-GET/?customer=" +
          this.props.customer +
          "&date=" +
          this.props.date,
        config
      )
      .then((res) => {
        console.log(res.data);
        this.setState({ items: res.data.Symptoms });
        this.setState({ custum: res.data.customSymptom });
        this.setState({ symptomsWithIputs: res.data.symptomsWithIputs });
      });
  }

  custumhandleCheckClick = (e) => {
    var date = moment().format("YYYY-MM-DD");
    const { id, checked } = e.target.dataset;

    if (e.target.checked) {
      var checkbox = "True";
    } else {
      var checkbox = "False";
    }

    const Data = {
      positive: checkbox,
      date: date,
      customer: this.props.customer,
      symptom: id,
    };
    const headers = {
      Authorization: `token ` + localStorage.getItem("sales"),
    };
    axios
      .post("customer/submit-custom-symptom/", Data, {
        headers: headers,
      })
      .then(() => {
        alert("symptom was submitted");
      })
      .catch((error) => {
        alert("Cannot add symptom again");
      });

    //  if (e.target.checked === "true") {
    //    console.log(name, checked);
    //  } else {
    //    console.log(name, checked);
    //  }
  };

  handleCheckClick = (e) => {
    var date = moment().format("YYYY-MM-DD");
    const { id, checked } = e.target.dataset;

    if (e.target.checked) {
      var checkbox = "True";
    } else {
      var checkbox = "False";
    }

    const Data = {
      positive: checkbox,
      customer: this.props.customer,
      date: date,
      symptom: id,
    };
    const headers = {
      Authorization: `token ` + localStorage.getItem("sales"),
    };
    axios
      .post("customer/symptoms-submit/", Data, {
        headers: headers,
      })
      .then(() => {
        alert("symptom was submitted");
      })
      .catch((error) => {
        alert("Cannot add symptoms again");
      });

    //  if (e.target.checked === "true") {
    //    console.log(name, checked);
    //  } else {
    //    console.log(name, checked);
    //  }
  };
  changeHandler = (e) => {
    this.setState({
      allValues: { ...this.state.allValues, [e.target.name]: e.target.value },
    });
  };
  onsubmbit = (e) => {
    var pikerdate2 = moment().format("YYYY-MM-DD");
    e.preventDefault();

    //console.log(pikerdate2);
    const Data = {
      customer: this.props.customer,
      others: this.state.allValues.others,
      bloodSugar: this.state.allValues.bloodSugar,
      bloodPressure: this.state.allValues.bloodPressure,
      date: pikerdate2,
    };
    // console.log(this.state.allValues.others);
    // const Data = this.state.allValues;
    const headers = {
      Authorization: `token ` + localStorage.getItem("sales"),
    };
    axios
      .post("customer/submit-symptoms-with-input/", Data, {
        headers: headers,
      })
      .then(() => {
        alert("symptom was submitted");
      })
      .catch((error) => {
        alert("Cannot add symptoms again");
      });
  };

  render() {
    return (
      <>
        {/* {console.log(this.state.items.nausea)} */}
        {this.state.medicineOpen ? (
          <div className="symptom-container" style={styles.module}>
            {this.state.medicineReady ? null : <AddMedicine />}

            <h2
              className="lightgradient2"
              style={{
                borderBottom: "1px solid #fff",
                padding: "10px",
                margin: "0px",
                // background: "#6f1f6d",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
                fontSize: "26px",
              }}
            >
              Symptoms Tracker
            </h2>
            {/* <table>
              <tr>
                <div>
                  <FontAwesomeIcon icon={faSearch} /> Symtoms
                </div>
              </tr>
              <tr>
                <td></td>
              </tr>{" "}
              <tr>
                <td></td>
              </tr>
              <tr>
                {this.state.items.nausea}
                <td>
                  {this.state.items.nausea}
                  <input
                    type="checkbox"
                    defaultChecked={this.state.items.nausea}
                  />
                  <strong style={styles.font14}>Nausea</strong>
                </td>
                <td style={styles.left}>
                  {" "}
                  <strong style={styles.font14}>
                    <input
                      type="checkbox"
                      defaultChecked={this.state.items.vomiting}
                    />
                    Vomiting
                  </strong>{" "}
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    defaultChecked={this.state.items.backpain}
                  />
                  <strong style={styles.font14}>Backpain </strong>
                </td>
                <td style={styles.left}>
                  {" "}
                  <strong style={styles.font14}>
                    <input
                      type="checkbox"
                      defaultChecked={this.state.items.headache}
                    />
                    Headache
                  </strong>{" "}
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    defaultChecked={this.state.items.constipation}
                  />
                  <strong style={styles.font14}>Constipation</strong>{" "}
                </td>
                <td style={styles.left}>
                  <strong style={styles.font14}>
                    <input
                      type="checkbox"
                      defaultChecked={this.state.items.frequent_urination}
                    />
                    Frequent Urination
                  </strong>
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    defaultChecked={this.state.items.nausea}
                  />
                  <strong style={styles.font14}>Tingling and Aching Breasts</strong>{" "}
                </td>
                <td style={styles.left}>
                  <input
                    type="checkbox"
                    defaultChecked={this.state.items.nausea}
                  />{" "}
                  <strong style={styles.font14}>Bloating</strong>
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    defaultChecked={this.state.items.nausea}
                  />{" "}
                  <strong style={styles.font14}>Motion Sickness</strong>{" "}
                </td>
                <td style={styles.left}>
                  <input
                    type="checkbox"
                    defaultChecked={this.state.items.nausea}
                  />
                  <strong style={styles.font14}>High Blood pressure</strong>
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    defaultChecked={this.state.items.nausea}
                  />{" "}
                  <strong style={styles.font14}>Acne</strong>{" "}
                </td>
                <td style={styles.left}>
                  <input
                    type="checkbox"
                    defaultChecked={this.state.items.nausea}
                  />
                  <strong style={styles.font14}>Heartburn</strong>
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    defaultChecked={this.state.items.nausea}
                  />
                  <strong style={styles.font14}>Pregnency grow</strong>{" "}
                </td>
                <td style={styles.left}>
                  {" "}
                  <input
                    type="checkbox"
                    defaultChecked={this.state.items.nausea}
                  />
                  <strong style={styles.font14}>Dental Problem</strong>{" "}
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    defaultChecked={this.state.items.nausea}
                  />
                  <strong style={styles.font14}>Palpiataion</strong>{" "}
                </td>
                <td style={styles.left}>
                  <input
                    type="checkbox"
                    defaultChecked={this.state.items.nausea}
                  />
                  <strong style={styles.font14}>Giddiness</strong>
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    defaultChecked={this.state.items.nausea}
                  />
                  <strong style={styles.font14}>Blured Vision</strong>{" "}
                </td>
                <td style={styles.left}>
                  <input
                    type="checkbox"
                    defaultChecked={this.state.items.nausea}
                  />
                  <strong style={styles.font14}>Dizziness</strong>{" "}
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    defaultChecked={this.state.items.nausea}
                  />
                  <strong style={styles.font14}>Swilling</strong>{" "}
                </td>
                <td style={styles.left}>
                  <input
                    type="checkbox"
                    defaultChecked={this.state.items.nausea}
                  />{" "}
                  <strong style={styles.font14}>Stress</strong>
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    defaultChecked={this.state.items.nausea}
                  />{" "}
                  <strong style={styles.font14}>Froathy Urine</strong>
                </td>
                <td style={styles.left}>
                  <input
                    type="checkbox"
                    defaultChecked={this.state.items.nausea}
                  />
                  <strong style={styles.font14}>Anxiety</strong>{" "}
                </td>
              </tr>
            </table> */}

            <table style={styles.boxstyle}>
              <tr>
                {/* <div>
                  <FontAwesomeIcon icon={faSearch} /> Symtoms
                </div> */}
              </tr>
              <tr style={styles.trgrid}>
                {this.state.items.map((personData, index) => {
                  return (
                    <>
                      <td key={index}>
                        <input
                          data-id={personData.id}
                          type="checkbox"
                          defaultChecked={personData && personData.positive}
                          onChange={this.handleCheckClick}
                        />
                      </td>
                      <td>
                        <strong style={styles.font14}>
                          {" "}
                          {personData.name}
                        </strong>
                      </td>
                    </>
                  );
                })}

                {this.state.custum.map((personData, index) => {
                  return (
                    <>
                      <td key={index}>
                        <input
                          data-id={personData.id}
                          type="checkbox"
                          defaultChecked={personData && personData.positive}
                          onChange={this.custumhandleCheckClick}
                        />
                      </td>
                      <td>
                        <strong style={styles.font14}>
                          {" "}
                          {personData.name}
                        </strong>
                      </td>
                    </>
                  );
                })}
              </tr>
            </table>

            <form onSubmit={this.onsubmbit}>
              <div style={styles.flex}>
                <div
                  className="marign-5"
                  style={{
                    transform: "rotate(90deg)",
                    fontSize: "4rem",
                    padding: "15px",
                  }}
                >
                  <img src={img1} />
                  {/* <i className="fa fa-pencil"></i> */}
                </div>
                <div className="marign-5" style={{ padding: "10px" }}>
                  <strong style={styles.font14}>Enter your weight</strong>
                  <br />
                  <br />
                  <input
                    style={{ float: "left" }}
                    name="others"
                    onChange={this.changeHandler}
                    value={this.state.allValues.others}
                    type="text"
                    placeholder="Description"
                  />
                </div>
              </div>
              <div style={styles.flex}>
                <div
                  className="marign-5"
                  style={{ fontSize: "4rem", padding: "15px" }}
                >
                  <img src={img2} />
                  {/* <i className="fa fa-level-up"></i> */}
                </div>
                <div className="marign-5" style={{ padding: "10px" }}>
                  <strong style={styles.font14}>
                    Enter your blood sugar level
                  </strong>
                  <br />
                  <br />
                  <input
                    style={{ float: "left" }}
                    type="text"
                    name="bloodSugar"
                    onChange={this.changeHandler}
                    value={this.state.allValues.bloodSugar}
                  />
                </div>
              </div>
              <div style={styles.flex}>
                <div
                  className="marign-5"
                  style={{ fontSize: "4rem", padding: "15px" }}
                >
                  <img src={img3} />
                  {/* <i className="fa fa-area-chart"></i> */}
                </div>
                <div className="marign-5" style={{ padding: "10px" }}>
                  <strong style={styles.font14}>
                    Enter your blood pressure level
                  </strong>
                  <br />
                  <br />
                  <input
                    style={{ float: "left" }}
                    type="text"
                    name="bloodPressure"
                    onChange={this.changeHandler}
                    value={this.state.allValues.bloodPressure}
                  />
                </div>
              </div>
              <div style={styles.flex}>
                <div
                  className="marign-5"
                  style={{
                    transform: "rotate(90deg)",
                    fontSize: "4rem",
                    padding: "15px",
                  }}
                >
                  <img src={img4} />
                  {/* <i className="fa fa-pie-chart"></i> */}
                </div>
                <div className="marign-5" style={{ padding: "10px" }}>
                  <strong style={styles.font14}>Last week report</strong>
                  <br />
                  <br />
                  <small>
                    {this.state.symptomsWithIputs[0] &&
                      this.state.symptomsWithIputs[0].report}
                  </small>
                </div>
              </div>
              <br />

              <hr />

              <div>
                <button
                  type="submit"
                  className="pinkbutton"
                  style={styles.subbtn}
                >
                  Submit
                </button>
              </div>
            </form>

            {this.state.symptomReady ? (
              <AddSymptom id={this.props.customer} />
            ) : null}
            <button
              onClick={this.addSymptom}
              // style={{ cursor: "pointer" }}
              style={styles.subbtn}
              className="pinkbutton"
            >
              Add User Defined Symptoms
            </button>
          </div>
        ) : null}
      </>
    );
  }
}

const styles = {
  trgrid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
  },
  module: {
    position: "relative",
    // background: "rgb(76, 0, 61) none repeat scroll 0% 0%",
    background: "#ffffff1c 0% 0% no-repeat padding-box",
    // margin: "0px 30px",
    borderRadius: "10px",
    padding: "0px 0px 20px 0px",
    height: "30rem",
    overflowY: "scroll",
    scrollbarWidth: "thin",
    marginbottom: "10px",
  },
  txt: {
    textAlign: "left",
  },
  btn: {
    border: "1px solid #974cae",
    padding: "5px 30px",
    // background: "#974cae",
    fontSize: "12px",
  },
  font14: { fontSize: "16px" },
  left: {
    textAlign: "left",
  },
  boxstyle: {
    background: "#ffffff1c 0% 0% no-repeat padding-box",
    marginTop: "10px",
  },
  flex: {
    background: "#ffffff1c 0% 0% no-repeat padding-box",
    marginTop: "10px",
    display: "flex",
    //background: "#971cae",
  },

  subbtn: {
    // background: "linear-gradient(90deg, #3a51a7 30%, #b53899)",
    padding: "10px 30px",
    border: "none",
    borderRadius: "10px",
    color: "#fff",
    cursor: "pointer",
    margin: "10px",
  },
  subbtn2: {
    background: "linear-gradient(90deg, #3a51a7 30%, #b53899)",
    padding: "10px 30px",
    border: "none",
    borderRadius: "10px",
    color: "#fff",
    cursor: "pointer",
  },
};
