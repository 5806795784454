import React from "react";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { withRouter } from "react-router";
// import "../../App.css";
import axios from "axios";
import { useState } from "react";
import logo from "../../logo.png";
import "./pricing.css";
import one from "./1.png";
import two from "./2.png";
import zeo7 from "./zero13.png";
import three from "./3.png";
import four from "./4.png";
import zero from "./zero0.png";
import "./pricing.css";
import one1 from "./11.png";
import two1 from "./22.png";
import three1 from "./33.png";
import four1 from "./44.png";
// import Form from "./form";
// import "./login.css";
import m1 from "./m1.png";
import m2 from "./m2.png";
import m3 from "./m3.png";
import m4 from "./m4.png";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

const __DEV__ = document.domain === "localhost";

export default function Pricing() {
  const [email, setemail] = useState("Mehul");
  const [phone, setPhone] = useState("Mehul");
  const [predefined, setpredefined] = useState([]);
  const [AMount, setAMount] = useState();
  const [Plan, setPlan] = useState();
  const [data2, setdata] = useState();
  console.log("loged", phone);
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("token"),
      },
    };

    axios.get("payments/all-plans/", config).then((res) => {
      console.log(res.data);

      setpredefined(res.data);
    });

    axios.get("customer-profile/", config).then((res) => {
      console.log(res.data);
      setemail(res.data.customer.email);
      setPhone(res.data.customer.mobile);
    });
  }, []);

  useEffect(() => {
    if (AMount != null && Plan != null) {
      fetch("https://sukhprasavam.shebirth.com/payments/get-subscription/", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token ` + localStorage.getItem("token"),
        },

        body: JSON.stringify({
          amount: AMount,
          plan_id: Plan,
        }),
      })
        .then((t) => t.json())
        .then(async (data) => {
          console.log(data.sub_id, AMount);
          const options = {
            // key: __DEV__ ? "rzp_test_QtWaK2YSYm06J7" : "PRODUCTION_KEY",
            key: "rzp_live_4PoehB5TLhjdR8",
            currency: data.currency,
            amount: AMount,
            subscription_id: data.sub_id,
            name: "shebirth",
            description: "Thank you for subscribing to us",
            image: "/logo.png",
            handler: function (response) {
              if (response) {
                const config = {
                  headers: {
                    Authorization: `token ` + localStorage.getItem("token"),
                  },
                };
                axios
                  .post("payments/checkout/", response, config)
                  .then((res) => {
                    console.log(res.status === 200);
                    localStorage.clear();
                    window.location = "/download";
                    alert(response.razorpay_payment_id);
                    alert(response.razorpay_subscription_id);
                    alert(response.razorpay_signature);
                  });
              }
            },

            prefill: {
              // name: data2.firstname,
              email: email,
              contact: phone,
            },
          };
          // console.log(options);
          const paymentObject = new window.Razorpay(options);
          paymentObject.open();
        });
    }
  }, [AMount, Plan]);

  function Freeversion() {
    if (localStorage.getItem("token") === null) {
      localStorage.clear();
      window.location = "/clogin";
    } else {
      // const headers = {
      //   Authorization: `token ` + localStorage.getItem("token"),
      // };
      console.log(localStorage.getItem("token"));

      const headers = {
        Authorization: `token ` + localStorage.getItem("token"),
      };
      const Data = {
        customer: 5,
        //   // stage: "stage9",
      };
      axios
        .post("payments/free-subscription/", Data, {
          headers: headers,
        })
        // const headers = {
        //   Authorization: `token ` + localStorage.getItem("token"),
        // };
        // axios
        //   .post("payments/free-subscription", {
        //     headers: headers,
        //   })
        .then((res) => {
          window.location = "https://rzp.io/l/Jz6LtUyo";
          alert("free package activated");
          localStorage.clear();
          window.open(
            "https://sukhprasavam.com/download",
            "_blank" // <- This is what makes it open in a new window.
          );
          // window.location = "/download";
        });
    }
  }

  async function displayRazorpay(e) {
    if (localStorage.getItem("token") === null) {
      localStorage.clear();
      window.location = "/clogin";
    } else {
      const config = {
        headers: {
          Authorization: `token ` + localStorage.getItem("token"),
        },
      };
      axios.get("customer/customer-dashboard-details/", config).then((res) => {
        setdata(res.data);
      });
    }

    if (e == "one") {
      setAMount(1499);

      setPlan("plan_LAJlXRq557Flf4");
      // setPlan("plan_IYT0B55qoXxn0K");
      // const plan_id = "plan_IYT0B55qoXxn0K";
    } else if (e == "two") {
      setAMount(4444);
      setPlan("plan_IYT0TjZTcLDXU9");
      // const plan_id = "plan_IYT0B55qoXxn0K";
    } else if (e == "three") {
      setAMount(590000 / 100);
      setPlan("plan_IYT0pjYfoELu1K");
      // const plan_id = "plan_IYT0B55qoXxn0K";
    } else if (e == "four") {
      setAMount(7777);
      setPlan("plan_LAJlXRq557Flf4");
      // const plan_id = "plan_IYT0B55qoXxn0K";
    }
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    // const settings = {
    //   method: "POST",
    //   headers: {
    //     Authorization: `token ` + localStorage.getItem("token"),
    //   },
    // };

    console.log(AMount);
    // if (AMount != null && Plan != null) {
    //   const data = await fetch(
    //     "https://sukhprasavam.shebirth.com/payments/get-subscription/",
    //     {
    //       method: "post",
    //       headers: {
    //         Accept: "application/json",
    //         "Content-Type": "application/json",
    //         Authorization: `token ` + localStorage.getItem("token"),
    //       },

    //       body: JSON.stringify({
    //         amount: AMount,
    //         plan_id: Plan,
    //       }),
    //     }
    //   ).then((t) => t.json());
    //   console.log(data.sub_id, AMount);
    //   const options = {
    //     // key: __DEV__ ? "rzp_test_QtWaK2YSYm06J7" : "PRODUCTION_KEY",
    //     key: "rzp_live_4PoehB5TLhjdR8",
    //     currency: data.currency,
    //     amount: AMount,
    //     subscription_id: data.sub_id,
    //     name: "shebirth",
    //     description: "Thank you for subscribing to us",
    //     image: "/logo.png",
    //     handler: function (response) {
    //       if (response) {
    //         const config = {
    //           headers: {
    //             Authorization: `token ` + localStorage.getItem("token"),
    //           },
    //         };
    //         axios.post("payments/checkout/", response, config).then((res) => {
    //           console.log(res.status === 200);
    //           localStorage.clear();
    //           window.location = "/download";
    //           alert(response.razorpay_payment_id);
    //           alert(response.razorpay_subscription_id);
    //           alert(response.razorpay_signature);
    //         });
    //       }
    //     },
    //     prefill: {
    //       name: data2.firstname,
    //       email: "sdfdsjfh2@ndsfdf.com",
    //       phone_number: "9899999999",
    //     },
    //   };
    //   // console.log(options);
    //   const paymentObject = new window.Razorpay(options);
    //   paymentObject.open();
    // }
  }
  console.log(AMount);
  return (
    <>
      <div className="pc-view">
        <div className="text-center pc-view">
          <img src={logo} />
        </div>
        <div className="appover pc-view" id="DIV_1">
          <div id="DIV_9">
            <div id="DIV_10">
              <div id="DIV_11">
                <div id="DIV_12">
                  <div id="DIV_13">
                    <h1 id="H1_18">
                      Choose the plan that’s right for you and your family
                    </h1>
                  </div>
                  <hr />
                  <div id="DIV_19"></div>
                </div>
                <div id="DIV_33">
                  <div id="DIV_34">
                    <div id="DIV_35">
                      <span id="SPAN_36">Select the plan you'd like</span>
                      {/* <label for="planGrid_planChoice_0" id="LABEL_37">
                        <input
                          type="radio"
                          name="planChoice"
                          value="114120"
                          id="INPUT_38"
                        />
                        <span className="span5" id="SPAN_39">
                          Free
                        </span>
                      </label> */}
                      <label for="planGrid_planChoice_2" id="LABEL_43">
                        <input
                          type="radio"
                          name="planChoice"
                          value="10322"
                          id="INPUT_44"
                        />
                        <span id="SPAN_45" className="span2">
                          basic plan
                        </span>
                      </label>
                      <label for="planGrid_planChoice_1" id="LABEL_40">
                        <input
                          type="radio"
                          name="planChoice"
                          value="314001031"
                          id="INPUT_41"
                        />
                        <span id="SPAN_42" className="span1">
                          1 month package
                        </span>
                      </label>
                      <label for="planGrid_planChoice_2" id="LABEL_43">
                        <input
                          type="radio"
                          name="planChoice"
                          value="10322"
                          id="INPUT_44"
                        />
                        <span id="SPAN_45" className="span2">
                          preganacy class
                        </span>
                      </label>
                      <label for="planGrid_planChoice_3" id="LABEL_46">
                        <input
                          type="radio"
                          name="planChoice"
                          value="10341"
                          id="INPUT_47"
                        />
                        <span id="SPAN_48" className="span3">
                          {" "}
                          Full preganacy package
                        </span>
                      </label>
                    </div>
                  </div>
                  <table id="TABLE_49">
                    <caption id="CAPTION_50"></caption>
                    <tbody className="scrolleroftable" id="TBODY_51">
                      <tr id="TR_52">
                        <td id="TD_53"> 24*7 support</td>
                        {/* {/* <td id="TD_54">&#10004;</td> */}
                        <td id="TD_54">&#10004;</td>
                        <td id="TD_55">&#10004;</td>
                        <td id="TD_56">&#10060;</td>
                        <td id="TD_57">&#10004;</td>
                      </tr>
                      <tr id="TR_52">
                        <td id="TD_53"> week by week status</td>
                        {/* {/* <td id="TD_54">&#10004;</td> */}
                        <td id="TD_54">&#10004;</td>
                        <td id="TD_55">&#10004;</td>
                        <td id="TD_56">&#10060;</td>
                        <td id="TD_57">&#10004;</td>
                      </tr>
                      <tr id="TR_52">
                        <td id="TD_53"> get closely monitor by doctor</td>
                        {/* <td id="TD_54">&#10004;</td>*/}
                        <td id="TD_55">&#10004;</td>
                        <td id="TD_55">&#10004;</td>
                        <td id="TD_56">&#10060;</td>
                        <td id="TD_57">&#10004;</td>
                      </tr>
                      <tr id="TR_52">
                        <td id="TD_53">Early Complications Prediction</td>
                        {/* <td id="TD_54">Limited</td> */}
                        <td id="TD_54">Limited</td>
                        <td id="TD_55">Limited</td>
                        <td id="TD_56">&#10060;</td>
                        <td id="TD_57">&#10004;</td>
                      </tr>
                      <tr id="TR_52">
                        <td id="TD_53">Virtual Buddy</td>
                        <td id="TD_54">&#10004;</td>
                        <td id="TD_54">&#10004;</td>
                        <td id="TD_55">&#10004;</td>
                        {/* <td id="TD_56">&#10060;</td> */}
                        <td id="TD_57">&#10004;</td>
                      </tr>
                      <tr id="TR_52">
                        <td id="TD_53"> Exercise Package</td>
                        <td id="TD_54">Free</td>
                        <td id="TD_54">Free</td>
                        <td id="TD_55">Free</td>
                        {/* <td id="TD_56">&#10060;</td> */}
                        <td id="TD_57">Free</td>
                      </tr>
                      <tr id="TR_52">
                        <td id="TD_53"> Full Pregnancy Support</td>
                        <td id="TD_54">&#10060;</td>
                        <td id="TD_54">&#10060;</td>
                        <td id="TD_55">&#10060;</td>
                        {/* <td id="TD_56">&#10060;</td> */}
                        <td id="TD_57">&#10004;</td>
                      </tr>
                      <tr id="TR_52">
                        <td id="TD_53"> Accountability Partner</td>
                        <td id="TD_54">&#10060;</td>
                        <td id="TD_54">&#10060;</td>
                        <td id="TD_55">&#10060;</td>
                        {/* <td id="TD_56">&#10060;</td> */}
                        <td id="TD_57">Free</td>
                      </tr>
                      <tr id="TR_58   " className="bigertr">
                        <tr className="innertr4">
                          <td id="TD_53">Antenatal Classes</td>
                          <td id="TD_53">
                            diet and how to eat right during pregnancy
                          </td>
                          <td id="TD_53">meditation</td>
                          <td id="TD_53">Pregnancy Fitness</td>
                          <td id="TD_53">Fetal Development</td>
                          <td id="TD_53">addressing pregnancy concerns</td>
                          <td id="TD_53">
                            happy hormones and memorable pregnancy{" "}
                          </td>
                          <td id="TD_53">labor Management</td>

                          <td id="TD_53">Natural Pain Relieving Techniques</td>
                        </tr>
                        <tr className="inner2tr4">
                          <td id="TD_54">&#10060;</td>
                          <td id="TD_54">&#10004;</td>
                          <td id="TD_55">&#10004;</td>
                          {/* <td id="TD_56">&#10060;</td> */}
                          <td id="TD_57">&#10004;</td>
                        </tr>
                      </tr>
                      <tr id="TR_58">
                        <td id="TD_59">Breathing Techniques</td>
                        <td id="TD_60">
                          <div id="DIV_61"></div>
                        </td>
                        <td id="TD_62">
                          <div id="DIV_63"></div>
                        </td>
                        <td id="TD_64">
                          <div id="DIV_65"></div>
                        </td>
                        <td id="TD_66">
                          <div id="DIV_67"></div>
                        </td>
                      </tr>
                      <tr id="TR_58   " className="bigertr">
                        <tr className="innertr4">
                          <td id="TD_53">Postnatal Classes</td>
                          <td id="TD_53">postpartum fitness</td>
                          <td id="TD_53">
                            Preparation And Importance Of Breastfeeding
                          </td>
                          <td id="TD_53">new born care</td>
                          <td id="TD_53">baby sleep pattern setting</td>
                          <td id="TD_53">diapering</td>
                          <td id="TD_53">baby massage and bathing </td>
                          <td id="TD_53">baby brain stimulation</td>

                          <td id="TD_53">gentle parenting</td>
                        </tr>
                        <tr className="inner2tr4">
                          <td id="TD_54">&#10060;</td>
                          <td id="TD_54">&#10004;</td>
                          <td id="TD_55">&#10004;</td>
                          {/* <td id="TD_56">&#10060;</td> */}
                          <td id="TD_57">&#10004;</td>
                        </tr>
                      </tr>
                      <tr id="TR_58">
                        <td id="TD_59">Dads session</td>
                        <td id="TD_60">
                          <div id="DIV_61"></div>
                        </td>
                        <td id="TD_62">
                          <div id="DIV_63"></div>
                        </td>
                        <td id="TD_64">
                          <div id="DIV_65"></div>
                        </td>
                        <td id="TD_66">
                          <div id="DIV_67"></div>
                        </td>
                      </tr>
                      <tr id="TR_52">
                        <td id="TD_53"> Certification</td>
                        <td id="TD_54">&#10060;</td>
                        <td id="TD_54">&#10060;</td>
                        <td id="TD_55">&#10060;</td>
                        {/* <td id="TD_56">Free</td> */}
                        <td id="TD_57">Free</td>
                      </tr>
                      <tr id="TR_52">
                        <td id="TD_53">pregnancy journal</td>
                        <td id="TD_54">&#10060;</td>
                        <td id="TD_54">&#10060;</td>
                        <td id="TD_55">&#10060;</td>
                        {/* <td id="TD_56">Free</td> */}
                        <td id="TD_57">Free</td>
                      </tr>
                      <tr id="TR_52">
                        <td id="TD_53"> birth plan</td>
                        <td id="TD_54">&#10060;</td>
                        <td id="TD_54">&#10060;</td>
                        <td id="TD_55">&#10060;</td>
                        {/* <td id="TD_56">Free</td> */}
                        <td id="TD_57">Free</td>
                      </tr>
                      <tr id="TR_78">
                        <td id="TD_79"></td>
                        <td id="TD_80">
                          <div id="DIV_81" className="pinkbox">
                            <div id="DIV_84">Free</div>
                          </div>
                          <div id="DIV_85">
                            <img className="pricetag" src={zero} />
                          </div>
                          <div id="DIV_128">
                            <div id="DIV_85">
                              <img className="pricetag" src={zero} />
                            </div>
                          </div>

                          <button
                            onClick={() => Freeversion()}
                            // onClick={displayRazorpay}
                            // onClick={() => displayRazorpay("one")}
                            className="buybutton"
                          >
                            Buy Now
                          </button>
                        </td>

                        <td id="TD_106">
                          <div id="DIV_107">
                            <div id="DIV_81" className="pinkbox">
                              <div id="DIV_84">at just 49 Rupees/day</div>
                            </div>
                          </div>
                          <div id="DIV_111">
                            <div id="DIV_85">
                              <img className="pricetag" src={one} />
                            </div>
                          </div>
                          <div id="DIV_128">
                            <div id="DIV_85">
                              <img className="pricetag" src={one1} />
                            </div>
                          </div>
                          <button
                            className="buybutton2"
                            onClick={() => displayRazorpay("one")}
                            // onClick={() => Freeversion()}
                          >
                            Buy Now
                          </button>
                        </td>
                        <td id="TD_89">
                          <div id="DIV_90">
                            <div id="DIV_81" className="pinkbox">
                              <div id="DIV_84">at just 49 Rupees/day</div>
                            </div>
                          </div>
                          <div id="DIV_94">
                            <div id="DIV_85">
                              <img className="pricetag" src={two} />
                            </div>
                          </div>
                          <div id="DIV_128">
                            <div id="DIV_85">
                              <img className="pricetag" src={two1} />
                            </div>
                          </div>
                          <button
                            className="buybutton1"
                            // onClick={() => displayRazorpay("one")}
                            onClick={() => displayRazorpay("two")}
                          >
                            Buy Now
                          </button>
                          <br />
                          <br />
                          <br />
                          <a href="https://sukhprasavam.com/download">
                            already registered
                          </a>
                        </td>
                        {/* <td id="TD_106">
                          <div id="DIV_107">
                            <div id="DIV_81" className="pinkbox">
                              <div id="DIV_84">knowledge session of 20hrs</div>
                            </div>
                          </div>
                          <div id="DIV_111">
                            <div id="DIV_85">
                              <img className="pricetag" src={three} />
                            </div>
                          </div>
                          <div id="DIV_128">
                            <div id="DIV_85">
                              <img className="pricetag" src={three1} />
                            </div>
                          </div>
                          <button
                            className="buybutton2"
                            onClick={() => displayRazorpay("three")}
                          >
                            Buy Now
                          </button>
                        </td> */}
                        <td id="TD_123">
                          <div id="DIV_124">
                            <div id="DIV_81" className="pinkbox">
                              <div id="DIV_84d">at just 27 Rupees/day</div>
                            </div>
                          </div>
                          <div id="DIV_128">
                            <div id="DIV_85">
                              <img className="pricetag" src={four} />
                            </div>
                          </div>
                          <div id="DIV_128">
                            <div id="DIV_85">
                              <img className="pricetag" src={four1} />
                            </div>
                          </div>
                          <button
                            className="buybutton3"
                            onClick={() => displayRazorpay("four")}
                          >
                            Buy Now
                          </button>
                        </td>
                      </tr>{" "}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div id="DIV_178"></div>
        </div>
      </div>

      <div className="mobile-view">
        <OwlCarousel
          className="owl-theme"
          loop={true}
          nav={true}
          items={1}
          autoHeight={true}
          navText={[
            '<span class="arrow prev">‹</span>',
            '<span class="arrow next">›</span>',
          ]}
        >
          {/* <div className="item9">
            <div className="card9 lightgradient">
              <h1 className="skyblue-button p-15"> Free</h1>

              <button className="border-pinkbutton">Free</button>

              <ul style={{ listStyleType: "none" }} className="uli scroll-uli">
                <div>
                  <FontAwesomeIcon icon={faCheckCircle} style={styles.icon} />
                  <li> 24*7 support</li>
                </div>
                <div>
                  <FontAwesomeIcon icon={faCheckCircle} style={styles.icon} />
                  <li>week by week status</li>
                </div>
                <div>
                  <FontAwesomeIcon icon={faCheckCircle} style={styles.icon} />
                  <li> get closely monitor my doctor</li>
                </div>
                <div>
                  <FontAwesomeIcon icon={faCheckCircle} style={styles.icon} />
                  <li> limited early complication prediction</li>
                </div>
                <div>
                  <FontAwesomeIcon icon={faCheckCircle} style={styles.icon} />
                  <li> virtual buddy</li>
                </div>
                <div>
                  <FontAwesomeIcon icon={faCheckCircle} style={styles.icon} />
                  <li> free Exercise package</li>
                </div>
                <div>
                  <FontAwesomeIcon icon={faCheckCircle} style={styles.icon} />
                  <li> Antenatal Classes</li>
                </div>
                <div>
                  <li>Diet And How To Eat Right During Pregnancy</li>
                </div>
                <div>
                  <li> Meditation</li>
                </div>
                <div>
                  <li> Pregnancy Fitness</li>
                </div>
                <div>
                  <li> Fetal Development</li>
                </div>
                <div>
                  <li> Addressing Pregnancy Concerns</li>
                </div>
                <div>
                  <li> Happy Hormones And Memorable Pregnancy</li>
                </div>
                <div>
                  <li> Labour Management</li>
                </div>
                <div>
                  <li>Natural Pain Relieving Techniques</li>
                </div>
                <div>
                  <li> Breathing Techniques</li>
                </div>
                <div>
                  <FontAwesomeIcon icon={faCheckCircle} style={styles.icon} />
                  <li>Postnatal classes</li>
                </div>
                <div>
                  <li> Diet and how to eat right during pregnancy</li>
                </div>
                <div>
                  <li> postpartum care for mothers</li>
                </div>
                <div>
                  <li> preperation and importance of breastfeeding</li>
                </div>
                <div>
                  <li>new born care</li>
                </div>
                <div>
                  <li>baby sleep pattern setting</li>
                </div>
                <div>
                  <li> diapering</li>
                </div>
                <div>
                  <li>baby massage and bathing</li>
                </div>
                <div>
                  <li>baby brain stimulation</li>
                </div>
                <div>
                  <li>gentle parenting</li>
                </div>
                <div>
                  <li> dads session</li>
                </div>
                <div>
                  <FontAwesomeIcon icon={faCheckCircle} style={styles.icon} />
                  <li> free certification</li>
                </div>
                <div>
                  <FontAwesomeIcon icon={faCheckCircle} style={styles.icon} />
                  <li> free pregnancy journal</li>
                </div>
                <div>
                  <FontAwesomeIcon icon={faCheckCircle} style={styles.icon} />
                  <li> birth plan</li>
                </div>
              </ul>
              <p className="text-center innerprice">
                <button className="price-tag skyblue-button">
                  {" "}
                  <img className="pricetag" src={zeo7} />
                </button>
              </p>
            </div>
            <p className="text-center">
              <button
                className="buy-now skyblue-button"
                onClick={() => Freeversion()}
              >
                Buy Now
              </button>
            </p>
          </div> */}
          {/* <div className="item9">
            <div className="card9 lightgradient">
              <h1 className="skyblue-button p-15"> 1 month package</h1>

              <button className="border-pinkbutton">1 month package</button>

              <ul style={{ listStyleType: "none" }} className="uli scroll-uli">
                <div>
                  <FontAwesomeIcon icon={faCheckCircle} style={styles.icon} />
                  <li> 24*7 support</li>
                </div>
                <div>
                  <FontAwesomeIcon icon={faCheckCircle} style={styles.icon} />
                  <li>week by week status</li>
                </div>
                <div>
                  <FontAwesomeIcon icon={faCheckCircle} style={styles.icon} />
                  <li> get closely monitor my doctor</li>
                </div>
                <div>
                  <FontAwesomeIcon icon={faCheckCircle} style={styles.icon} />
                  <li> limited early complication prediction</li>
                </div>
                <div>
                  <FontAwesomeIcon icon={faCheckCircle} style={styles.icon} />
                  <li> virtual buddy</li>
                </div>
                <div>
                  <FontAwesomeIcon icon={faCheckCircle} style={styles.icon} />
                  <li> free Exercise package</li>
                </div>
                <div>
                  <FontAwesomeIcon icon={faCheckCircle} style={styles.icon} />
                  <li> Antenatal Classes</li>
                </div>
                <div>
                  <li>Diet And How To Eat Right During Pregnancy</li>
                </div>
                <div>
                  <li> Meditation</li>
                </div>
                <div>
                  <li> Pregnancy Fitness</li>
                </div>
                <div>
                  <li> Fetal Development</li>
                </div>
                <div>
                  <li> Addressing Pregnancy Concerns</li>
                </div>
                <div>
                  <li> Happy Hormones And Memorable Pregnancy</li>
                </div>
                <div>
                  <li> Labour Management</li>
                </div>
                <div>
                  <li>Natural Pain Relieving Techniques</li>
                </div>
                <div>
                  <li> Breathing Techniques</li>
                </div>
                <div>
                  <FontAwesomeIcon icon={faCheckCircle} style={styles.icon} />
                  <li>Postnatal classes</li>
                </div>
                <div>
                  <li> Diet and how to eat right during pregnancy</li>
                </div>
                <div>
                  <li> postpartum care for mothers</li>
                </div>
                <div>
                  <li> preperation and importance of breastfeeding</li>
                </div>
                <div>
                  <li>new born care</li>
                </div>
                <div>
                  <li>baby sleep pattern setting</li>
                </div>
                <div>
                  <li> diapering</li>
                </div>
                <div>
                  <li>baby massage and bathing</li>
                </div>
                <div>
                  <li>baby brain stimulation</li>
                </div>
                <div>
                  <li>gentle parenting</li>
                </div>
                <div>
                  <li> dads session</li>
                </div>
                <div>
                  <FontAwesomeIcon icon={faCheckCircle} style={styles.icon} />
                  <li> free certification</li>
                </div>
                <div>
                  <FontAwesomeIcon icon={faCheckCircle} style={styles.icon} />
                  <li> free pregnancy journal</li>
                </div>
                <div>
                  <FontAwesomeIcon icon={faCheckCircle} style={styles.icon} />
                  <li> birth plan</li>
                </div>
              </ul>
              <p className="text-center innerprice">
                <button className="price-tag skyblue-button">
                  {" "}
                  <img className="pricetag" src={m3} />
                </button>
              </p>
            </div>
            <p className="text-center">
              <button
                className="buy-now skyblue-button"
                onClick={() => displayRazorpay("one")}
              >
                Buy Now
              </button>
            </p>
          </div> */}

          <div className="item9">
            <div className="card9 lightgradient">
              <h1 className="skyblue-button p-15"> basic plan</h1>
              {/* <img src="jeans3.jpg" alt="Denim Jeans" className="w-100" /> */}
              <button className="border-pinkbutton"> basic plan</button>

              <ul style={{ listStyleType: "none" }} className="uli scroll-uli">
                <div>
                  <FontAwesomeIcon icon={faCheckCircle} style={styles.icon} />
                  <li> 24*7 support</li>
                </div>
                <div>
                  <FontAwesomeIcon icon={faCheckCircle} style={styles.icon} />
                  <li>week by week status</li>
                </div>
                <div>
                  <FontAwesomeIcon icon={faCheckCircle} style={styles.icon} />
                  <li> get closely monitor my doctor</li>
                </div>
                <div>
                  <FontAwesomeIcon icon={faCheckCircle} style={styles.icon} />
                  <li> limited early complication prediction</li>
                </div>
                <div>
                  <FontAwesomeIcon icon={faCheckCircle} style={styles.icon} />
                  <li> virtual buddy</li>
                </div>
                <div>
                  <FontAwesomeIcon icon={faCheckCircle} style={styles.icon} />
                  <li> free Exercise package</li>
                </div>
                <div>
                  <FontAwesomeIcon icon={faCheckCircle} style={styles.icon} />
                  <li> Antenatal Classes</li>
                </div>
                <div>
                  <li>Diet And How To Eat Right During Pregnancy</li>
                </div>
                <div>
                  <li> Meditation</li>
                </div>
                <div>
                  <li> Pregnancy Fitness</li>
                </div>
                <div>
                  <li> Fetal Development</li>
                </div>
                <div>
                  <li> Addressing Pregnancy Concerns</li>
                </div>
                <div>
                  <li> Happy Hormones And Memorable Pregnancy</li>
                </div>
                <div>
                  <li> Labour Management</li>
                </div>
                <div>
                  <li>Natural Pain Relieving Techniques</li>
                </div>
                <div>
                  <li> Breathing Techniques</li>
                </div>
                <div>
                  <FontAwesomeIcon icon={faCheckCircle} style={styles.icon} />
                  <li>Postnatal classes</li>
                </div>
                <div>
                  <li> Diet and how to eat right during pregnancy</li>
                </div>
                <div>
                  <li> postpartum care for mothers</li>
                </div>
                <div>
                  <li> preperation and importance of breastfeeding</li>
                </div>
                <div>
                  <li>new born care</li>
                </div>
                <div>
                  <li>baby sleep pattern setting</li>
                </div>
                <div>
                  <li> diapering</li>
                </div>
                <div>
                  <li>baby massage and bathing</li>
                </div>
                <div>
                  <li>baby brain stimulation</li>
                </div>
                <div>
                  <li>gentle parenting</li>
                </div>
                <div>
                  <li> dads session</li>
                </div>
                <div>
                  <FontAwesomeIcon icon={faCheckCircle} style={styles.icon} />
                  <li> free certification</li>
                </div>
                <div>
                  <FontAwesomeIcon icon={faCheckCircle} style={styles.icon} />
                  <li> free pregnancy journal</li>
                </div>
                <div>
                  <FontAwesomeIcon icon={faCheckCircle} style={styles.icon} />
                  <li> birth plan</li>
                </div>

                {/* <div>
                  <FontAwesomeIcon icon={faCheckCircle} style={styles.icon} />
                  <li> 24*7 support</li>
                </div>
                <div>
                  <FontAwesomeIcon icon={faCheckCircle} style={styles.icon} />
                  <li> 24*7 support</li>
                </div>
                <div>
                  <FontAwesomeIcon icon={faCheckCircle} style={styles.icon} />
                  <li> 24*7 support</li>
                </div> */}
              </ul>
              <p className="text-center innerprice">
                <button className="price-tag skyblue-button">
                  {" "}
                  <img className="pricetag" src={m4} />
                </button>
              </p>
            </div>
            <p className="text-center">
              <button
                className="buy-now skyblue-button"
                onClick={() => Freeversion()}
                // onClick={() => displayRazorpay("two")}
              >
                Buy Now
              </button>
              <br />
              <a href="https://sukhprasavam.com/download">already registered</a>
            </p>
          </div>
          <div className="item9">
            <div className="card9 lightgradient">
              <h1 className="orange-button p-15">Full Pregnancy Package</h1>
              {/* <img src="jeans3.jpg" alt="Denim Jeans" className="w-100" /> */}
              <button className="border-pinkbutton">
                At just 49 Rupees/day
              </button>

              <ul style={{ listStyleType: "none" }} className="uli">
                <div>
                  <FontAwesomeIcon icon={faCheckCircle} style={styles.icon} />
                  <li> 24*7 support</li>
                </div>
                <div>
                  <FontAwesomeIcon icon={faCheckCircle} style={styles.icon} />
                  <li>week by week status</li>
                </div>
                <div>
                  <FontAwesomeIcon icon={faCheckCircle} style={styles.icon} />
                  <li> get closely monitor my doctor</li>
                </div>
                <div>
                  <FontAwesomeIcon icon={faCheckCircle} style={styles.icon} />
                  <li> limited early complication prediction</li>
                </div>
                <div>
                  <FontAwesomeIcon icon={faCheckCircle} style={styles.icon} />
                  <li> virtual buddy</li>
                </div>
                <div>
                  <FontAwesomeIcon icon={faCheckCircle} style={styles.icon} />
                  <li> free Exercise package</li>
                </div>
                {/* <div>
                  <FontAwesomeIcon icon={faCheckCircle} style={styles.icon} />
                  <li> 24*7 support</li>
                </div>
                <div>
                  <FontAwesomeIcon icon={faCheckCircle} style={styles.icon} />
                  <li> 24*7 support</li>
                </div>
                <div>
                  <FontAwesomeIcon icon={faCheckCircle} style={styles.icon} />
                  <li> 24*7 support</li>
                </div> */}
              </ul>
              <p className="text-center innerprice">
                <button className="price-tag orange-button">
                  {" "}
                  <img className="pricetag" src={m1} />
                </button>
              </p>
            </div>
            <p className="text-center">
              <button
                className="buy-now orange-button"
                onClick={() => displayRazorpay("three")}
              >
                Buy Now
              </button>
            </p>
          </div>
        </OwlCarousel>
      </div>
    </>
  );
}

const styles = {
  content: {
    width: "70%",
    color: "white",
    // textAlign: "center",
  },
  icon: { color: "#79b9fb" },
  imagep: { width: "100%" },
  flex: {
    display: "flex",
    justifyContent: "space-between",
    width: "70%",
    margin: "auto",
  },
};
