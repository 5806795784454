import DietTrackerUpdate from "./update_customized_diet";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import Moment from "moment";
export default function DietTracker(props) {
  const [data, setData] = useState([]);
  const [update, setUpdate] = useState(false);
  
  var userid = props.userid1;
  //   const [breakfast, setbreakfast] = useState({});
  //   const [earlymoring, setearlymoring] = useState([]);
  //   const [midday, setmidday] = useState({});
  //   const [afternoon, setafternoon] = useState({});
  //   const [dinner, setDinner] = useState({});
  //   const [allValues, setAllValues] = useState({});
  //   const [lunch, setlunch] = useState({});
  //   const [dinnerdrink, setdinnerdrink] = useState({});

  function openUpdater() {
    setUpdate(true);
  }

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("doc_token"),
      },
    };
    //remove this date after setting up the admin pannel

    axios
      .get(
        "customer/all-dates-diet/?id=" + userid.id,
        config

        // config
      )
      .then((res) => {
        const groupedByDate = res.data.reduce((meals, meal) => {
          if (meal.date in meals) {
            meals[meal.date].push(meal);
          } else {
            meals[meal.date] = [meal];
          }
          return meals;
        }, {});
        const sortedOnDate = Object.values(groupedByDate).sort((arr1, arr2) => {
          // Convert DD-MM-YYYY into YYYY-MM-DD
          const ymdDate1 = arr1[0].date.split("-").reverse().join("-");
          const ymdDate2 = arr2[0].date.split("-").reverse().join("-");
          if (ymdDate1 < ymdDate2) {
            return 1;
          } else if (ymdDate1 > ymdDate2) {
            return -1;
          }
          return 0;
        });
        setData(sortedOnDate);
      });
  }, []);

  return (
    <>
      {update ? <DietTrackerUpdate userid={userid} /> : null}

      <button style={styles.sbtn} onClick={openUpdater}>
        Update Customised Diet
      </button>

      <div className="scrollerbar">
        <form>
          <table style={{ borderCollapse: "collapse" }}>
            <thead style={{ borderBottom: "5px solid #5c0048" }}>
              <tr
                style={{ background: "#ffffff1c 0% 0% no-repeat padding-box" }}
                className="font-blue"
              >
                <th style={styles.th}>Date</th>
                <th style={styles.th}>Early Morning Energy</th>
                <th style={styles.th}>Breakfast</th>
                <th style={styles.th}>Mid-Day Snack</th>
                <th style={styles.th}>lunch</th>
                <th style={styles.th}>Afternoon Snack</th>
                <th style={styles.th}>Dinner</th>
                <th style={styles.th}>Dinner Drink</th>
              </tr>
            </thead>

            {data.map((arr) => {
              let lunch = arr.find((o) => o.mealName === "lunch");
              let breakfast = arr.find((o) => o.mealName === "breakfast");
              let earlymorning = arr.find(
                (o) => o.mealName === "early morning"
              );
              let dinner = arr.find((o) => o.mealName === "dinner");
              let afternoon = arr.find((o) => o.mealName === "afternoon snack");
              let midday = arr.find((o) => o.mealName === "mid day snack");
              let dinnerdrink = arr.find((o) => o.mealName === "dinner drink");
              return (
                <tbody key={arr[0].date}>
                  <tr
                    style={{
                      background: "#ffffff1c 0% 0% no-repeat padding-box",
                    }}
                  >
                    <td style={styles.tdFirst}> {arr[0].date}</td>
                    <td style={styles.td}>
                      <input
                        name="mealType"
                        style={styles.inputstyle}
                        defaultValue={earlymorning && earlymorning.food}
                        placeholder={earlymorning && earlymorning.food}
                      />
                      <br />{" "}
                      <input
                        name="mealType"
                        style={styles.inputstyle}
                        defaultValue={earlymorning && earlymorning.time}
                        placeholder={earlymorning && earlymorning.time}
                      />
                    </td>

                    <td style={styles.td}>
                      <input
                        name="mealType"
                        style={styles.inputstyle}
                        defaultValue={breakfast && breakfast.food}
                        placeholder={breakfast && breakfast.food}
                      />
                      <br />{" "}
                      <input
                        name="mealType"
                        style={styles.inputstyle}
                        defaultValue={breakfast && breakfast.time}
                        placeholder={breakfast && breakfast.time}
                      />
                    </td>
                    <td style={styles.td}>
                      <input
                        name="mealType"
                        style={styles.inputstyle}
                        defaultValue={midday && midday.food}
                        placeholder={midday && midday.food}
                      />{" "}
                      <br />{" "}
                      <input
                        name="mealType"
                        style={styles.inputstyle}
                        defaultValue={midday && midday.time}
                        placeholder={midday && midday.time}
                      />
                    </td>
                    <td style={styles.td}>
                      <input
                        name="mealType"
                        style={styles.inputstyle}
                        defaultValue={lunch && lunch.food}
                        placeholder={lunch && lunch.food}
                      />{" "}
                      <br />
                      <input
                        name="mealType"
                        style={styles.inputstyle}
                        defaultValue={lunch && lunch.time}
                        placeholder={lunch && lunch.time}
                      />
                    </td>
                    <td style={styles.td}>
                      <input
                        name="mealType"
                        style={styles.inputstyle}
                        defaultValue={afternoon && afternoon.food}
                        placeholder={afternoon && afternoon.food}
                      />{" "}
                      <br />
                      <input
                        name="mealType"
                        style={styles.inputstyle}
                        defaultValue={afternoon && afternoon.time}
                        placeholder={afternoon && afternoon.time}
                      />
                    </td>
                    <td style={styles.td}>
                      <input
                        name="mealType"
                        style={styles.inputstyle}
                        defaultValue={dinner && dinner.food}
                        placeholder={dinner && dinner.food}
                      />
                      <br />
                      <input
                        name="mealType"
                        style={styles.inputstyle}
                        defaultValue={dinner && dinner.time}
                        placeholder={dinner && dinner.time}
                      />
                    </td>
                    <td style={styles.td}>
                      <input
                        name="mealType"
                        style={styles.inputstyle}
                        defaultValue={dinnerdrink && dinnerdrink.food}
                        placeholder={dinnerdrink && dinnerdrink.food}
                      />
                      <br />
                      <input
                        name="mealType"
                        style={styles.inputstyle}
                        defaultValue={dinnerdrink && dinnerdrink.time}
                        placeholder={dinnerdrink && dinnerdrink.time}
                      />
                    </td>
                  </tr>
                </tbody>
              );
            })}
          </table>

          {/* <button onClick={submitValue(pikerdate)}>Edit</button> */}
        </form>
      </div>
    </>
  );
}

const styles = {
  th: {
    fontSize: "17px",
    whiteSpace: "nowrap",
    padding: "5px 10px",
    margin: "10px 0px",
  },
  inputstyle: {
    background: "#ffffff1c 0% 0% no-repeat padding-box",
    // color: "white",
    color: "black",
    border: "none",
  },
  td: {
    border: "1px solid rgb(0 0 0)",
    // borderLeft: "1px solid #fff",
    padding: "5px 10px",
    textAlign: "left",
    fontSize: "12px",
  },

  tdFirst: {
    padding: "5px 10px",
    border: "1px solid black",
    fontSize: "12px",
  },

  sbtn: {
    border: "none",
    margin: "5px 0px",
    color: "#fff",
    background: "#E81A7F",
    padding: "8px",
    fontWeight: "bold",
    cursor: "pointer",
  },
};
