import React from "react";
import { getMedicineDetails } from "../../../../helpers/service";

export default function MedicineTracker({ customer }) {
  const [data, setData] = React.useState({});

  const filterForTable = (array = []) => {
    return array.reduce((obj, item) => {
      if (!obj[item.date]) {
        obj[item.date] = {};
      }

      let dataByDate = obj[item.date];
      let keyName = item["medicationTime"].toLowerCase().split(" ").join("_");

      if (!dataByDate[keyName]) {
        dataByDate[keyName] = [];
      }

      let medicateTimeData = dataByDate[keyName];

      medicateTimeData.push({
        ...item,
      });

      return obj;
    }, {});
  };

  const fetchApi = () => {
    const fetchMedical = getMedicineDetails();

    fetchMedical
      .api({
        params: {
          customer,
        },
      })
      .then((response) => {
        if (response) {
          setData(filterForTable(response, "medicationTime"));
        }
      });

    return fetchMedical;
  };

  const Row = ([date, item], index) => {
    return (
      <tr
        key={date}
        style={{
          background:
            "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
        }}
      >
        <td style={styles.tdFirst}>{date}</td>
        <td style={styles.td}>
          {item.morning_before_food &&
            item.morning_before_food.map((each) => (
              <span key={each.id}>
                {each.medicine}
                <br />
                {each.time}
              </span>
            ))}
          <br />
        </td>
        <td style={styles.td}>
          {item.afternoon_before_food &&
            item.afternoon_before_food.map((each) => (
              <span key={each.id}>
                {each.medicine}
                <br />
                {each.time}
              </span>
            ))}
          <br />
        </td>
        <td style={styles.td}>
          {item.afternoon_after_food &&
            item.afternoon_after_food.map((each) => (
              <span key={each.id}>
                {each.medicine}
                <br />
                {each.time}
              </span>
            ))}
          <br />
        </td>
        <td style={styles.td}>
          {item.night_before_food &&
            item.night_before_food.map((each) => (
              <span key={each.id}>
                {each.medicine}
                <br />
                {each.time}
              </span>
            ))}
          <br />
        </td>
        <td style={styles.td}>
          {item.night_after_food &&
            item.night_after_food.map((each) => (
              <span key={each.id}>
                {each.medicine}
                <br />
                {each.time}
              </span>
            ))}
        </td>
      </tr>
    );
  };

  React.useEffect(() => {
    if (!!customer) {
      const request = fetchApi();

      return () => {
        request.cancel();
      };
    }
  }, [customer]);

  return (
    <div className="scrollerbar">
      <table
        style={{
          width: "100%",
          borderSpacing: "0px 1em",
          borderCollapse: "collapse",
        }}
      >
        <thead style={{ borderBottom: "5px solid #5c0048" }}>
          <tr
            style={{ background: "#ffffff1c 0% 0% no-repeat padding-box" }}
            className="font-blue"
          >
            <th style={styles.th}>Date</th>
            <th style={styles.th}>
              Morning Before
              <br /> Food
            </th>
            <th style={styles.th}>
              Afternoon Before
              <br /> Food
            </th>
            <th style={styles.th}>
              Afternoon After
              <br /> Food
            </th>
            <th style={styles.th}>
              Night Before
              <br /> Food
            </th>
            <th style={styles.th}>
              Night After
              <br /> Food
            </th>
          </tr>
        </thead>

        <tbody>{Object.entries(data).map(Row)}</tbody>
      </table>
    </div>
  );
}

const styles = {
  th: {
    fontSize: "17px",
    whiteSpace: "nowrap",
    padding: "5px 10px",
    margin: "10px 0px",
  },

  td: {
    border: "1px solid black",
    padding: "5px 0px",
    fontSize: "12px",
    background:
      "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",

    textAlign: "left",
  },

  tdFirst: {
    padding: "5px 10px",
    border: "1px solid black",
    fontSize: "12px",
  },
};
