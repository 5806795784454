import React from "react";
import axios from "axios";
import AddMedicine from "./add_medicine";
import moment from "moment";
export default class Medicinetracker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      medicineOpen: true,
      medicineReady: true,
      morningafter: [],
      morningbefore: [],
      afternoonbefore: [],
      afternoonafter: [],
      nightafter: [],
      items: [],
      checked: [],
      allValues: "",
    };
    this.closeMedicine = this.closeMedicine.bind(this);
    this.addMedicine = this.addMedicine.bind(this);
  }

  closeMedicine() {
    this.setState({ medicineOpen: !this.state.medicineOpen });
  }

  addMedicine() {
    this.setState({ medicineReady: !this.state.medicineReady });
  }

  componentDidMount() {
    console.log(this.props);
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("sales"),
      },
    };

    axios
      .get(
        "customer/medicine-GET/?customer=" +
          this.props.customer +
          "&date=" +
          this.props.date,
        config
      )
      .then((res) => {
        let morningbefore = res.data.find(
          (o) => o.MedicationTime === "morning before food"
        );

        let morningafter = res.data.find(
          (o) => o.MedicationTime === "morning after food"
        );

        let afternoonbefore = res.data.find(
          (o) => o.MedicationTime === "afternoon before food"
        );

        let afternoonafter = res.data.find(
          (o) => o.MedicationTime === "afternoon after food"
        );

        let nightafter = res.data.find(
          (o) => o.MedicationTime === "night after food"
        );

        let nightbefore = res.data.find(
          (o) => o.MedicationTime === "night before food"
        );
        this.setState({ morningafter: morningafter.Medicines });
        this.setState({ morningbefore: morningbefore.Medicines });
        this.setState({ afternoonbefore: afternoonbefore.Medicines });
        this.setState({ afternoonafter: afternoonafter.Medicines });
        this.setState({ nightafter: nightafter.Medicines });
        this.setState({ items: nightbefore.Medicines });
      });
  }

  handleCheckClick = (e) => {
    var date = moment().format("YYYY-MM-DD");
    const { name, checked } = e.target.dataset;

    if (e.target.checked) {
      var checkbox = "True";
    } else {
      var checkbox = "False";
    }

    const Data = {
      taken: checkbox,
      date: date,
      customer: this.props.customer,
      medicine: name,
    };
    const headers = {
      Authorization: `token ` + localStorage.getItem("sales"),
    };
    axios
      .patch("customer/medicine-update/", Data, {
        headers: headers,
      })
      .then(() => {
        alert("Medicine was submitted");
      })
      .catch((error) => {
        alert("Cannot add Medicine again");
      });

    //  if (e.target.checked === "true") {
    //    console.log(name, checked);
    //  } else {
    //    console.log(name, checked);
    //  }
  };

  render() {
    return (
      <>
        {this.state.medicineOpen ? (
          <div className="medicine-tracker" style={styles.module}>
            <h2
              className="lightgradient2"
              style={{
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
                padding: "10px 0px",
                margin: "0px",
                fontSize: "26px",
                // background: "#8c3186",
              }}
            >
              Update Medicine Tracker
            </h2>
            <table
              style={{ borderCollapse: "seperate", borderSpacing: "0em 1em" }}
            >
              <tr style={styles.row}>
                <td style={styles.txt}>Morning Before Food</td>
                <td style={styles.gridrow}>
                  {this.state.morningbefore.map((itm) => {
                    return (
                      <>
                        <td>
                          <div style={styles.gridinner}>
                            <span style={styles.txt}>{itm.name}</span>
                            <input
                              style={styles.inputcheck}
                              data-name={itm.id}
                              type="checkbox"
                              // data-checked={itm.taken}
                              defaultChecked={itm.taken && itm}
                              onChange={this.handleCheckClick}
                            />
                          </div>
                        </td>
                      </>
                    );
                  })}
                </td>
              </tr>

              <tr style={styles.row}>
                <td style={styles.txt}>Morning after Food</td>
                <td style={styles.gridrow}>
                  {this.state.morningafter.map((itm) => {
                    return (
                      <>
                        <td>
                          <div style={styles.gridinner}>
                            <span style={styles.txt}>{itm.name}</span>
                            <input
                              style={styles.inputcheck}
                              data-name={itm.id}
                              type="checkbox"
                              // data-checked={itm.taken}
                              defaultChecked={itm.taken && itm}
                              onChange={this.handleCheckClick}
                            />
                          </div>
                        </td>
                      </>
                    );
                  })}
                </td>
              </tr>

              <tr style={styles.row}>
                <td style={styles.txt}>afternoon Before Food</td>
                <td style={styles.gridrow}>
                  {this.state.afternoonbefore.map((itm) => {
                    return (
                      <>
                        <td>
                          <div style={styles.gridinner}>
                            <span style={styles.txt}>{itm.name}</span>
                            <input
                              style={styles.inputcheck}
                              data-name={itm.id}
                              type="checkbox"
                              // data-checked={itm.taken}
                              defaultChecked={itm.taken && itm}
                              onChange={this.handleCheckClick}
                            />
                          </div>
                        </td>
                      </>
                    );
                  })}
                </td>
              </tr>
              <tr style={styles.row}>
                <td style={styles.txt}>afternoon after Food</td>
                <td style={styles.gridrow}>
                  {this.state.afternoonafter.map((itm) => {
                    return (
                      <>
                        <td>
                          <div style={styles.gridinner}>
                            <span style={styles.txt}>{itm.name}</span>
                            <input
                              style={styles.inputcheck}
                              data-name={itm.id}
                              type="checkbox"
                              // data-checked={itm.taken}
                              defaultChecked={itm.taken && itm}
                              onChange={this.handleCheckClick}
                            />
                          </div>
                        </td>
                      </>
                    );
                  })}
                </td>
              </tr>
              <tr style={styles.row}>
                <td style={styles.txt}>Night Before Food</td>
                <td style={styles.gridrow}>
                  {this.state.items.map((itm) => {
                    return (
                      <>
                        <td>
                          <div style={styles.gridinner}>
                            <span style={styles.txt}>{itm.name}</span>
                            <input
                              style={styles.inputcheck}
                              data-name={itm.id}
                              type="checkbox"
                              // data-checked={itm.taken}
                              defaultChecked={itm.taken && itm}
                              onChange={this.handleCheckClick}
                            />
                          </div>
                        </td>
                      </>
                    );
                  })}
                </td>
              </tr>

              <tr style={styles.row}>
                <td style={styles.txt}>Night After Food</td>
                <td style={styles.gridrow}>
                  {this.state.nightafter.map((itm) => {
                    return (
                      <>
                        <td>
                          <div style={styles.gridinner}>
                            <span style={styles.txt}>{itm.name}</span>
                            <input
                              style={styles.inputcheck}
                              data-name={itm.id}
                              type="checkbox"
                              // data-checked={itm.taken}
                              defaultChecked={itm.taken && itm}
                              onChange={this.handleCheckClick}
                            />
                          </div>
                        </td>
                      </>
                    );
                  })}
                </td>
              </tr>

              <tr></tr>
              <tr>
                <td></td>
                <td></td>
              </tr>
            </table>
            {this.state.medicineReady ? null : (
              <AddMedicine id={this.props.customer} />
            )}
            <div>
              <div>
                <button
                  className="pinkbutton"
                  style={styles.sbtn}
                  onClick={this.addMedicine}
                >
                  Add Medicine
                </button>
              </div>
              <div>{/* <button style={styles.sbtn}>Submit</button> */}</div>
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

const styles = {
  module: {
    // background: "rgb(76, 0, 61) none repeat scroll 0% 0%",
    margin: "0px auto",
    borderRadius: "10px",
    padding: "0px 0px",
    width: "100%",
  },
  inputcheck: { maxHeight: "13px" },
  txt: {
    textAlign: "left",
  },
  row: {
    background: "#ffffff1c 0% 0% no-repeat padding-box",
    borderRight: "50px",
  },
  addmedicine: {
    padding: "5px 60px",
    background: "none",
    borderLeft: "none",
    borderTop: "none",
    borderRight: "none",
    borderRadius: "0px",
    borderBottom: "1px solid #fff",
    color: "#fff",
  },
  sbtn: {
    padding: "1rem 5rem",
    // border: "1px solid #6984B0",
    // background: "linear-gradient(90deg, #6984B0, #B16DD4)",
    margin: "1rem 0rem",
    color: "#fff",
    borderRadius: "10px",
    cursor: "pointer",
  },

  gridrow: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
  },

  gridinner: { display: "grid", gridTemplateColumns: "3fr 1fr" },
};
