import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useParams } from "react-router-dom";
import { withRouter } from "react-router";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";

import "./newprice.css";

const __DEV__ = document.domain === "localhost";

export default function Pricing() {
  const { id } = useParams();
  const [email, setEmail] = useState("Mehul");
  const [phone, setPhone] = useState("Mehul");
  const [memberId, setMemberId] = useState();
  const [clientId, setClientId] = useState(id);
  const [smemebership, setmemebership] = useState([]);

  const handlePaymentSuccess = () => {
    // Perform any actions or function calls after successful payment
    console.log("Payment successful!");

    // Call your custom function here
    // myFunction();
  };

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Token ${sessionStorage.getItem("hospitalManager")}`,
      },
    };

    axios.get("/payments/hospital_get_memberships/", config).then((res) => {
      console.log(res.data);
      setmemebership(res.data);
    });

    axios.get("customer-profile/", config).then((res) => {
      console.log(res.data);
      setEmail(res.data.customer.email);
      setClientId(res.data.customer.id);
      setPhone(res.data.customer.mobile);
    });
  }, []);

  const handleButtonClick = async (id) => {
    setMemberId(id);
    let price;

    if (id === 1) {
      price = 3999;
    } else if (id === 2) {
      price = 9999;
    } else if (id === 3) {
      price = 24999;
    }

    const ui = uuidv4();
    console.log(memberId);
    try {
      const config = {
        headers: {
          Authorization: `Token ${sessionStorage.getItem("hospitalManager")}`,
        },
      };
      const payload = {
        membership_id: id,
        user_id: clientId,
        uid: ui,
      };

      const res = await axios.post(
        "/payments/hospital_purchase_memberships/",
        payload,
        config
      );

      console.log(res.data);

      // If this code executes, it means the request was successful
      // You can proceed with the payment gateway integration here
      // window.location = "/clogin"; // Redirect to "/clogin" after successful payment
    } catch (error) {
      console.error(error);
      // Handle the error here
    }

    const options = {
      key: __DEV__ ? "rzp_live_ZzpnKBVjhdD60I" : "rzp_live_ZzpnKBVjhdD60I",
      amount: price * 100,
      name: "SheBirth",
      description: ui,
      prefill: {
        email,
        contact: phone,
      },
    };

    const loadRazorpayScript = async () => {
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );
      if (res) {
        const razorpay = new window.Razorpay(options);
        razorpay.on("payment.success", function (response) {
          handlePaymentSuccess();
        });
        razorpay.on("payment.error", function (response) {
          alert("Payment unsuccessful!");
        });
        razorpay.open();
      } else {
        console.log("Failed to load Razorpay script.");
      }
    };

    loadRazorpayScript();
  };

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };
  return (
    <>
      <div>
        <h1 className="h1x">Package Options {id}</h1>
        <div className="card-containerx">
          <div className="cardx">
            <h3 className="h3x">Bundle of Joy</h3>
            <h4 className="h4x">1430/month</h4>
            <ul className="ulx">
              <li>Daily Online Exercise Classes</li>
              <li>Week by week Status</li>
              <li>Antenatal Classes Live</li>
              <li>Dict and Psychologist Support (3 'Consultation Each)</li>
              <li>Pregnancy Buddy, Accountability</li>
              <li>Partner (Weekly Once)</li>
              <li>Birth Plan Support</li>
              <li>24/7 Support</li>
              <li>Dads App</li>
            </ul>

            <button className="buttonx" onClick={() => handleButtonClick(2)}>
              RS 9999/-(Per Pregnancy)
            </button>
          </div>

          <div className="cardx">
            <h3 className="h3x">Born With a Silver Spoon</h3>
            <h4 className="h4x"> RS 3571 /month</h4>
            <ul className="ulx">
              <li>Daily Online Exercise Classes</li>
              <li>Week by week Status</li>
              <li>Live Antenatal Classes</li>
              <li>Diet and Psychologist Support - Totally Customised</li>
              <li>Pregnancy Buddy, Accountability</li>
              <li>Partner - No Limits</li>
              <li>Birth Plan Support</li>
              <li>24x7 Support</li>
              <li>Dads App</li>
              <li>
                2-Day Theatre based Event for the Couple including 2 days Lunch
                Physical Event @ Luxurious Hotel
              </li>
              <li>
                Baby Shower(Guest can be accomodated @Additional Charges),
                Fashion Show.Maternity Photoshoot
              </li>
            </ul>
            <button className="buttonx" onClick={() => handleButtonClick(3)}>
              RS 24999/-(Per Pregnancy)
            </button>
          </div>

          <div className="cardx">
            <h3 className="h3x">Little one Package </h3>
            <h4 className="h4x">3999+Gst extra</h4>
            <ul className="ulx">
              <li>Recorded Pregnancy Guide Class</li>
              <li>Diet Guide</li>
              <li>Physiotherapy Guide</li>
              <li>Virtual Pregnancy Buddy</li>
            </ul>

            <button className="buttonx" onClick={() => handleButtonClick(1)}>
              RS 3999 +Gst extra
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

const styles = {
  content: {
    width: "70%",
    color: "white",
    // textAlign: "center",
  },
  icon: { color: "#79b9fb" },
  imagep: { width: "100%" },
  flex: {
    display: "flex",
    justifyContent: "space-between",
    width: "70%",
    margin: "auto",
  },
};
