import React from "react";
import moment from "moment";
import axios from "axios";
export default class Addmedicine extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isDone: true, allValues: "" };
    this.medicineClose = this.medicineClose.bind(this);
  }

  medicineClose() {
    this.setState({ isDone: false });
  }
  changeHandler = (e) => {
    this.setState({ ...this.allValues, [e.target.name]: e.target.value });
  };

  submitValue = (e) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);
    const id = this.props.id;

    // Add the id to the formData
    formData.append("customer", id);
    var object = {};
    formData.forEach(function (value, key) {
      object[key] = value;
    });
    var json = JSON.stringify(object);

    formData.append("data", json);

    const headers = {
      Authorization: `token ` + localStorage.getItem("sales"),
    };
    axios
      .post("customer/medicine-POST/", formData, {
        headers: headers,
      })
      .then(() => {
        alert("Medicine was submitted");
      })
      .catch((error) => {
        alert("Cannot add Medicine again");
      });
  };

  render() {
    return (
      <>
        {/* {this.props} */}
        {this.state.isDone ? (
          <div className="medicine-module lightgradient" style={styles.module}>
            <h2 style={{ margin: "0px" }}>Add New Medicine</h2>
            <hr style={{ color: "#fff" }} />
            <form onSubmit={this.submitValue}>
              <table
                style={{ borderCollapse: "seperate", borderSpacing: "0em 1em" }}
              >
                <tr style={styles.tr} className="inputofdiet gradiantblur">
                  <td style={styles.tdstyle}>Morning Before Food</td>
                  <td style={styles.tdstyle}>
                    <input
                      className="calanderbutton"
                      type="text"
                      name="medicationTime"
                      style={styles.label}
                      onChange={this.changeHandler}
                      defaultValue="morning before food"
                      hidden
                    />
                    <input
                      className="calanderbutton"
                      type="text"
                      name="name"
                      style={styles.label}
                      onChange={this.changeHandler}
                      value={this.state.morningbefore}
                    />
                  </td>
                  <td style={styles.tdstyle}>
                    <button type="submit">Add</button>
                  </td>
                </tr>
              </table>
            </form>

            <form onSubmit={this.submitValue}>
              <table
                style={{ borderCollapse: "seperate", borderSpacing: "0em 1em" }}
              >
                {" "}
                {console.log(this.props.id)}
                <tr style={styles.tr} className="inputofdiet gradiantblur">
                  <td style={styles.tdstyle}>Morning After Food</td>
                  <td style={styles.tdstyle}>
                    <input
                      className="calanderbutton"
                      type="text"
                      name="medicationTime"
                      style={styles.label}
                      onChange={this.changeHandler}
                      defaultValue="morning after food"
                      hidden
                    />
                    <input
                      className="calanderbutton"
                      type="text"
                      name="name"
                      style={styles.label}
                      onChange={this.changeHandler}
                      value={this.state.morningafter}
                    />
                  </td>
                  <td style={styles.tdstyle}>
                    <button type="submit">Add</button>
                  </td>
                </tr>
              </table>
            </form>

            <form onSubmit={this.submitValue}>
              <table
                style={{ borderCollapse: "seperate", borderSpacing: "0em 1em" }}
              >
                <tr style={styles.tr} className="inputofdiet gradiantblur">
                  <td style={styles.tdstyle}>Afternoon Before Food</td>
                  <td style={styles.tdstyle}>
                    <input
                      className="calanderbutton"
                      type="text"
                      name="medicationTime"
                      style={styles.label}
                      onChange={this.changeHandler}
                      defaultValue="afternoon before food"
                      hidden
                    />
                    <input
                      className="calanderbutton"
                      type="text"
                      name="name"
                      style={styles.label}
                      onChange={this.changeHandler}
                      value={this.state.afternoonbefore}
                    />
                  </td>
                  <td style={styles.tdstyle}>
                    <button type="submit">Add</button>
                  </td>
                </tr>
              </table>
            </form>

            <form onSubmit={this.submitValue}>
              <table
                style={{ borderCollapse: "seperate", borderSpacing: "0em 1em" }}
              >
                <tr style={styles.tr} className="inputofdiet gradiantblur">
                  <td style={styles.tdstyle}>Afternoon After Food</td>
                  <td style={styles.tdstyle}>
                    <input
                      className="calanderbutton"
                      type="text"
                      name="medicationTime"
                      style={styles.label}
                      onChange={this.changeHandler}
                      defaultValue="afternoon after food"
                      hidden
                    />
                    <input
                      className="calanderbutton"
                      type="text"
                      name="name"
                      style={styles.label}
                      onChange={this.changeHandler}
                      value={this.state.afternoonafter}
                    />
                  </td>
                  <td style={styles.tdstyle}>
                    <button type="submit">Add</button>
                  </td>
                </tr>
              </table>
            </form>

            <form onSubmit={this.submitValue}>
              <table
                style={{ borderCollapse: "seperate", borderSpacing: "0em 1em" }}
              >
                <tr style={styles.tr} className="inputofdiet gradiantblur">
                  <td style={styles.tdstyle}>Night Before Food</td>
                  <td style={styles.tdstyle}>
                    <input
                      className="calanderbutton"
                      type="text"
                      name="medicationTime"
                      style={styles.label}
                      onChange={this.changeHandler}
                      defaultValue="night before food"
                      hidden
                    />
                    <input
                      className="calanderbutton"
                      type="text"
                      name="name"
                      style={styles.label}
                      onChange={this.changeHandler}
                      value={this.state.NightBefore}
                    />
                  </td>
                  <td style={styles.tdstyle}>
                    <button type="submit">Add</button>
                  </td>
                </tr>
              </table>
            </form>

            <form onSubmit={this.submitValue}>
              <table
                style={{ borderCollapse: "seperate", borderSpacing: "0em 1em" }}
              >
                <tr style={styles.tr} className="inputofdiet gradiantblur">
                  <td style={styles.tdstyle}>Night After Food</td>
                  <td style={styles.tdstyle}>
                    <input
                      className="calanderbutton"
                      type="text"
                      name="medicationTime"
                      style={styles.label}
                      onChange={this.changeHandler}
                      defaultValue="night after food"
                      hidden
                    />
                    <input
                      className="calanderbutton"
                      type="text"
                      name="name"
                      style={styles.label}
                      onChange={this.changeHandler}
                      value={this.state.nightafter}
                    />
                  </td>
                  <td style={styles.tdstyle}>
                    <button type="submit">Add</button>
                  </td>
                </tr>
              </table>
            </form>

            <button className="pinkbutton" onClick={this.medicineClose}>
              Done
            </button>
          </div>
        ) : null}
      </>
    );
  }
}

const styles = {
  tdstyle: { width: "50%" },
  module: {
    padding: "10px 0px",
    position: "relative",
    // background: "#ad6db0",
    borderRadius: "10px",
    // width: "52rem",
  },

  tr: {
    textAlign: "left",
  },
  button: {
    background: "linear-gradient(90deg, #3a51a7 30%, #b53899)",
    border: "none",
    color: "#fff",
    padding: "10px 30px",
    cursor: "pointer",
    borderRadius: "10px",
  },
};
