import React from "react";
import axios from "axios";
//import { Redirect } from 'react-router-dom';
import Sidebar from "./components/sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faUser, faBars } from "@fortawesome/free-solid-svg-icons";
import Toggler from "./toggle";

import ClientProfile from "./tog_profile";
//import DailyTrackerTabs from './components/daily_tracker';

//================ Importing Components ======
import Home from "./components/home";
import Greet from "./components/greet";
import ApprovalRequest from "./components/approval_requests";
import TodayAppointment from "./components/today_appointments";
import CurrentStatus from "./components/current_status";

export default class DoctorOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isToggleOn: true, isProfile: true, token: "", items: [] };
    this.handleClick = this.handleClick.bind(this);
    this.setWrap = this.setWrap.bind(this);
    this.clickedOutside = this.clickedOutside.bind(this);
    this.profileHandler = this.profileHandler.bind(this);
  }

  handleClick() {
    this.setState((prevState) => ({
      isToggleOn: !prevState.isToggleOn,
    }));
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.clickedOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.clickedOutside);
  }

  profileHandler() {
    this.setState({ isProfile: !this.state.isProfile });
  }

  setWrap(node) {
    this.isWap = node;
  }

  clickedOutside(e) {
    if (this.isWap && !this.isWap.contains(e.target)) {
      this.setState({ isToggleOn: true });
      this.setState({ isProfile: true });
    }
  }

  componentDidMount() {
    this.setState({ token: localStorage.getItem("doc_token") });

    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("doc_token"),
      },
    };
    console.log(config)

    axios.get("doctor/doctor-profile/", config).then((res) => {
      console.log(JSON.stringify(res.data));
      this.setState({ items: res.data });
    });

  }

  render() {
    function openMenu() {
      document.querySelector(".sidebar").classList.toggle("show");
    }

    if (localStorage.getItem("doc_token") === null) {
      localStorage.clear();
      window.location = "/clogin";
    }

    return (
      <>
        <div className="App">
          <Sidebar />
          <header className="overview">
            <div
              className="grandiant"
              ref={this.setWrap}
              style={{ padding: "2% 10%", textAlign: "right" }}
            >
              {" "}
              <span onClick={openMenu} className="menu">
                <FontAwesomeIcon
                  style={{ marginRight: "20px" }}
                  icon={faBars}
                  className="menu-icon"
                />
              </span>
              <div>{this.state.isToggleOn ? null : <Toggler />}</div>
              <span className="iconsofgradiant">
                <FontAwesomeIcon
                  className="iconsofprofile"
                  onClick={this.handleClick}
                  style={{ marginRight: "1rem", cursor: "pointer" }}
                  icon={faBell}
                />
              </span>
              <span className="iconsofgradiant">
                <FontAwesomeIcon
                  className="iconsofprofile"
                  onClick={this.profileHandler}
                  style={{ cursor: "pointer" }}
                  icon={faUser}
                />
              </span>{" "}
              {this.state.items.firstname} {this.props.childern}
              {this.state.isProfile ? null : <ClientProfile />}{" "}
            </div>

            <div
              style={{ display: "flex", paddingLeft: "10px" }}
              className="toperpc"
            >
              <span style={{ whiteSpace: "nowrap" }}>Overview</span>
              <div style={{ padding: "4px", width: "100%" }}>
                <hr
                  style={{ background: "white", color: "white" }}
                  className="hr-80"
                />
              </div>
            </div>
            <div className="mwrapper">
              <div className="mleft docmright">
                <Greet />
                <Home />
              </div>
              <div className="mright ">
                <ApprovalRequest />
                <TodayAppointment />
                <CurrentStatus />
              </div>
            </div>
          </header>
        </div>
      </>
    );
  }
}
