import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBolt, faChevronDown } from '@fortawesome/free-solid-svg-icons'

export default function ContractionCounter(){

return (

<table style={{ width:'100%', borderCollapse:'collapse' }}>
<thead style={{ borderBottom:'5px solid #5c0048' }}>
<tr style={{ background:'#a23698' }}>
<th style={ styles.th} >Date</th>
<th style={ styles.th} >Time</th>
<th style={ styles.th} >Contraction</th>
</tr>
</thead>

<tbody>
<tr style={{ background:'#a23698' }}>
<td style={styles.tdFirst}>June 7</td>
<td style={styles.td}>14:36</td>
<td style={styles.td}> <FontAwesomeIcon icon={faBolt}  style={{ marginRight:'1rem', color:'red' }}/> 4 <FontAwesomeIcon icon={faChevronDown}  style={{ marginLeft:'5rem', cursor:'pointer' }}/></td>

</tr>


</tbody>


</table>


);

}


const styles = {

th:{
fontSize:'17px',
whiteSpace:'nowrap',
padding:'5px 10px',
margin:'10px 0px',
},

td:{
borderLeft:'1px solid #fff',
padding:'5px 10px',
fontSize:'12px',
textAlign:'center',
lineHeight:'20px'
},

tdFirst:{
padding:'5px 10px',
fontSize:'12px'
},



}






