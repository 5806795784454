import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import Consultantmodule from "./mod/add_consultant";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
export default function Client() {
  const [count, setcount] = useState();
  const [count2, setcount2] = useState();
  useEffect(() => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Token ${sessionStorage.getItem("token")}`,
      },
    };

    axios
      .get("admin-panel/subscriptions-tab/", config)
      .then((res) => {
        setcount(res.data.BornWithSilverSpoon);
        setcount2(res.data.TrialPlan);

        //console.log(res.data);0
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  return (
    <div style={styles.card} className="lightgradient2">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ padding: "10px" }}>
          <p>
            <strong
              style={{
                whiteSpace: "nowrap",
                fontSize: "16px",
                lineHeight: "1",
              }}
            >
              Born With Silver
              <br /> Spoon
            </strong>
          </p>
        </div>
        <div style={{ padding: "10px", borderLeft: "1px solid #fff" }}>
          {" "}
          <h2 style={styles.counter}>{count}</h2>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ padding: "10px" }}>
          <p>
            <strong
              style={{ whiteSpace: "nowrap", fontSize: "16px", margin: "8px" }}
            >
              Trial Plan
            </strong>
          </p>
        </div>
        <div style={{ padding: "10px", borderLeft: "1px solid #fff" }}>
          {" "}
          <h2 style={styles.counter}>{count2}</h2>
        </div>
      </div>

      {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ padding: "10px" }}>
          <p>
            <strong style={{ whiteSpace: "nowrap", fontSize: "16px" }}>
              Full Pregnancy Package
            </strong>
          </p>
        </div>
        <div style={{ padding: "10px", borderLeft: "1px solid #fff" }}>
          <h2 style={styles.counter}>{count}</h2>
        </div>
      </div> */}
    </div>
  );
}

const styles = {
  card: {
    display: "flex",
    flexDirection: "column",
    // backdropFilter: "blur(31px) brightness(-15%) opacity(.3)",
    width: "13.5rem",
    height: "12rem",
    padding: "20px",
    borderRadius: "10px",
    margin: "10px",
    lineHeight: "10px",
  },
  link: {
    fontSize: "13px",
    textDecoration: "none",
    color: "#fff",
    background: "#5a0f2c",
    padding: "10px",
    borderRadius: "10px",
    border: "none",
    cursor: "pointer",
  },

  left: {
    padding: "0px",
  },
  right: {
    padding: "0px",
  },
  counter: {
    color: "#fff",
  },
};
