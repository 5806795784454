import React from "react";
import { getSymptomsDetails } from "../../../../helpers/service";

export default function SymptomsTracker({ customer }) {
  const [data, setData] = React.useState({});

  const filterData = (response = {}) => {
    // symptoms_with_text , symptoms

    const symptoms = [...response.symptoms, ...response.custom_symptoms].reduce(
      (obj, item) => {
        if (!obj[item.date]) {
          obj[item.date] = {
            symptoms: [],
          };
        }

        const dataByDate = obj[item.date];

        dataByDate.symptoms.push({
          ...item,
        });

        return obj;
      },
      {}
    );

    const symptoms_with_text = response.symptoms_with_text.reduce(
      (obj, item) => {
        obj[item.date] = {
          ...item,
        };

        return obj;
      },
      {}
    );

    const dataTogether = Object.keys(symptoms_with_text).reduce((obj, date) => {
      if (symptoms[date]) {
        obj[date] = {
          ...symptoms[date],
          symptoms_with_text: {
            ...symptoms_with_text[date],
          },
        };
      } else {
        obj[date] = {
          symptoms_with_text: {
            ...symptoms_with_text[date],
          },
        };
      }

      return obj;
    }, []);

    return {
      ...symptoms,
      ...dataTogether,
    };
  };

  const fetchApi = () => {
    const request = getSymptomsDetails();

    request
      .api({
        params: {
          customer,
        },
      })
      .then((response) => {
        if (response) {
          setData(filterData(response));
        }
      });

    return request;
  };

  const Row = ([date, item], index) => {
    return (
      <tr
        key={date}
        style={{
          background:
            "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
        }}
      >
        <td style={styles.tdFirst}>{date}</td>
        <td style={styles.td}>
          {item.symptoms &&
            item.symptoms.map((each, i) => {
              return (
                <span key={i}>
                  {each.symptom}
                  <br />
                </span>
              );
            })}
        </td>
        <td style={styles.td}>{item.symptoms_with_text?.others || ""}</td>
        <td style={styles.td}>{item.symptoms_with_text?.bloodSugar || ""}</td>
        <td style={styles.td}>
          {item.symptoms_with_text?.bloodPressure || ""}
        </td>
        <td style={styles.td}>{item.symptoms_with_text?.report || ""}</td>
      </tr>
    );
  };

  React.useEffect(() => {
    if (!!customer) {
      const request = fetchApi();

      return () => {
        request.cancel();
      };
    }
  }, [customer]);

  return (
    <div className="scrollerbar">
      <table
        style={{
          width: "100%",
          borderSpacing: "0px 1em",
          borderCollapse: "collapse",
        }}
      >
        <thead style={{ borderBottom: "5px solid #5c0048" }}>
          <tr
            style={{ background: "#ffffff1c 0% 0% no-repeat padding-box" }}
            className="font-blue"
          >
            <th style={styles.th}>Date</th>
            <th style={styles.th}>Symptoms</th>
            <th style={styles.th}>Other Symptoms</th>
            <th style={styles.th}>Blood Sugar Level</th>
            <th style={styles.th}>Blood Pressure Level</th>
            <th style={styles.th}>Last Week Report</th>
          </tr>
        </thead>

        <tbody>{Object.entries(data).map(Row)}</tbody>
      </table>
    </div>
  );
}

const styles = {
  th: {
    fontSize: "17px",
    whiteSpace: "nowrap",
    padding: "5px 10px",
    margin: "10px 0px",
  },

  td: {
    border: "1px solid black",
    borderLeft: "1px solid #fff",
    padding: "5px 10px",
    fontSize: "12px",
    background:
      "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",

    textAlign: "left",
    lineHeight: "20px",
  },

  tdFirst: { border: "1px solid black", padding: "5px 10px", fontSize: "12px" },
};
