import React from "react";
// import Image from "../img/small_image.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
export default class DoctorProfile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isProfile: true,
      items: [],
      allValues: {},
      passwordChange: {},
    };
    this.clickHandler = this.clickHandler.bind(this);
  }

  clickHandler() {
    this.setState({ isProfile: false });
  }

  changeHandler = (e) => {
    this.setState({
      allValues: { ...this.state.allValues, [e.target.name]: e.target.value },
    });
  };
  changePassword = (e) => {
    this.setState({
      passwordChange: {
        ...this.state.passwordChange,
        [e.target.name]: e.target.value,
      },
    });
  };
  onChange(e) {
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("postdoc"),
      },
    };

    this.setState({ files: e.target.files[0] });
    // let files = this.state.files;
    // files[e.target.name] = e.target.files[0];

    const formData = new FormData();
    // if (image != undefined) {
    // console.log(this.state.files);
    formData.append("profile_img", e.target.files[0]);
    // }
    // if (allValues != null) {
    //   Object.entries(allValues).forEach(([key, value]) =>
    //     formData.append(key, value)
    //   );
    // }
    // e.target.files[0];

    // this.setState({
    axios.patch("profile-update/", formData, config).then(() => {
      alert("updated data");
      window.location.reload();
    });
    //   files: files,
    // });
  }
  submitPassword = (e) => {
    e.preventDefault();
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("doc_token"),
      },
    };
    axios
      .patch("password-change/", this.state.passwordChange, config)
      .then(() => {
        alert("updated data");
        window.location.reload();
      })
      .catch((error) => {
        alert(JSON.stringify(error.response.data));
      });
  };
  onsubmbit = (e) => {
    e.preventDefault();
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("doc_token"),
      },
    };
    axios
      .patch("profile-update/", this.state.allValues, config)
      .then(() => {
        alert("updated data");
        // window.location.reload();
      })
      .catch((error) => {
        alert(error);
      });

    //console.log(allValues);
  };

  componentDidMount() {
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("doc_token"),
      },
    };

    axios.get("doctor/doctor-profile/", config).then((res) => {
      console.log(res.data);
      this.setState({ items: res.data });
    });
  }

  render() {
    return (
      <>
        {this.state.isProfile ? (
          <div className="doctor-profile gradiantblur" style={styles.dprofile}>
            <div style={styles.titlePlate}>
              <strong>My Profile</strong>
            </div>
            <form>
              <table>
                <tr>
                  <td>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {/* {console.log(this.state.items.profile_img)} */}
                      {this.state.items.profile_full_url ? (
                        <img
                          src={this.state.items.profile_full_url}
                          className="avatar"
                        />
                      ) : (
                        // <img src={Image} className="avatar" />
                        <span></span>
                      )}
                      <input
                        type="file"
                        id="files"
                        name="profile_img"
                        onChange={(e) => this.onChange(e)}
                        style={{ display: "none" }}
                      />
                      <label
                        for="files"
                        style={{
                          fontSize: "12px",
                          width: "66px",
                          marginTop: "5px",
                          textAlign: "center",
                          border: "1px solid white",
                          borderRadius: "5px",
                        }}
                      >
                        Select file
                      </label>
                    </div>
                    {/* <img
                      src={this.state.items.profile_img}
                      height="75px"
                      width="75px"
                      style={{ borderRadius: "50%" }}
                      alt=""
                    /> */}
                    <br />
                    <span style={styles.money}>
                      &#8377;{this.state.items.price}
                    </span>
                  </td>
                  <td style={styles.col}>
                    <strong style={{ fontSize: "20px" }}>
                      Dr. {this.state.items.firstname}
                    </strong>
                    <br />
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <small
                        style={{
                          padding: "3px 0px",
                          borderBottom: "1px solid #fff",
                        }}
                      >
                        Email : <span>{this.state.items.email}</span>
                      </small>
                      <small
                        style={{
                          padding: "3px 0px",
                          borderBottom: "1px solid #fff",
                        }}
                      >
                        Password :
                        <input
                          type="password"
                          name="password"
                          onChange={this.changePassword}
                        />
                        <button onClick={this.submitPassword}>Change </button>
                        {/*  <button style={styles.btn}>Change</button> */}
                      </small>
                      <br />
                    </div>
                  </td>

                  <td style={styles.col}>
                    <span style={styles.ftext} className="lightgradient">
                      Your Consulting Fee
                    </span>{" "}
                    <input
                      style={styles.fee}
                      type="text"
                      name="price"
                      onChange={this.changeHandler}
                      defaultValue={this.state.items.price}
                    />
                    {/* <strong style={styles.fee}>{this.state.items.price}</strong> */}
                    <br />
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <small
                        style={{
                          marginTop: "5px",
                          padding: "3px 0px",
                          borderBottom: "1px solid #fff",
                        }}
                      >
                        Contact Number : <span>{this.state.items.mobile}</span>
                      </small>
                      <small
                        style={{
                          padding: "3px 0px",
                          borderBottom: "1px solid #fff",
                        }}
                      >
                        Years Of Experience :{" "}
                        <span>{this.state.items.experience} Yr</span>
                      </small>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td style={styles.co}>Full name</td>
                  <td>
                    <input
                      type="text"
                      name="firstname"
                      onChange={this.changeHandler}
                      defaultValue={this.state.items.firstname}
                    />
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td style={styles.co}>Gender</td>
                  <td>
                    <select
                      className="transparentSelect"
                      name="gender"
                      onChange={this.changeHandler}
                      // defaultValue={this.state.items.gender}
                      required
                      style={styles.select}
                    >
                      <option value={this.state.items.gender} selected hidden>
                        {this.state.items.gender}
                      </option>
                      <option style={styles.option} value="male">
                        Male
                      </option>
                      <option style={styles.option} value="female">
                        Female
                      </option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td style={styles.co}>Speciality</td>
                  <td>
                    <input
                      name="speciality"
                      type="text"
                      onChange={this.changeHandler}
                      defaultValue={this.state.items.speciality}
                    />
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td style={styles.co}>Qualification</td>
                  <td>
                    <input
                      name="qualification"
                      type="text"
                      onChange={this.changeHandler}
                      defaultValue={this.state.items.qualification}
                    />
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td style={styles.co}>Medical council required?</td>
                  <td>
                    <input
                      name="medicalCouncil"
                      type="text"
                      onChange={this.changeHandler}
                      defaultValue={this.state.items.medicalCouncil}
                    />
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td style={styles.co}>Area of interest</td>
                  <td>
                    <input
                      name="interests"
                      type="text"
                      onChange={this.changeHandler}
                      defaultValue={this.state.items.interests}
                    />
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td style={styles.co}>Reference Code</td>
                  <td>
                    <input
                      name="referalId"
                      type="text"
                      onChange={this.changeHandler}
                      defaultValue={this.state.items.referalId}
                    />
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td style={styles.co}>Hospital/Hospital working</td>
                  <td>
                    <input
                      name="hospitals"
                      type="text"
                      onChange={this.changeHandler}
                      defaultValue={this.state.items.hospitals}
                    />
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td style={styles.co}>Place of Work</td>
                  <td>
                    <input
                      name="location"
                      type="text"
                      onChange={this.changeHandler}
                      defaultValue={this.state.items.location}
                    />
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td style={styles.co}>
                    Are you willing to give Online consultation for your
                    Patient?
                  </td>
                  <td>
                    <input
                      name="onlineConsultation"
                      type="text"
                      onChange={this.changeHandler}
                      defaultValue={this.state.items.onlineConsultation}
                    />
                  </td>
                </tr>
              </table>
            </form>
            <input
              onClick={this.onsubmbit}
              style={styles.ebtn}
              className="pinkbutton"
              type="submit"
              value="Submit"
            />
          </div>
        ) : null}
      </>
    );
  }
}

const styles = {
  ebtn: {
    padding: "2% 9%",
    borderRadius: "5px",
    // border:
    //   "1px solid linear-gradient(90deg, rgb(58, 81, 167) 30%, rgb(181, 56, 153))",
    background: "#e81a7f",
  },
  dprofile: {
    
    // backdropFilter: "blur(31px) brightness(-15%) opacity(.3)",
    width: "41.3rem",
    borderRadius: "10px",
    padding: "10px",
    margin: "auto",
  },

  titlePlate: {
    padding: "10px",
    color: "#fff",
  },
  flex: {
    display: "flex",
    justifyContent: "space-around",
    padding: "10px",
  },
  col: {
    padding: "10px",
    textAlign: "left",
  },
  option: { background: "#85388E" },
  co: {
    padding: "10px",
    textAlign: "left",

    background:
      " 0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
  },
  money: {
    color: "#fff",
  },

  btn: {
    padding: "3px",
    background: "#85388E",
    border: "none",
  },

  fee: {
    padding: "5px",
    background: "transparent",
    outline: "1px solid #85388E",
    color: "white",
    display: "block",
    width: "8rem",
  },

  ftext: {
    fontSize: "12px",
    padding: "5px",
    // background: "#85388E",
    marginRight: "2rem",
    display: "block",
    outline: "1px solid #85388E",
    width: "8rem",
  },
};
