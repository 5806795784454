import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

export default function ClientList() {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [selectedDateRange, setSelectedDateRange] = useState([null, null]);

  const formatDateString = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `admin-panel/customers-under-doctor-marketing/?doctor_id=${id}`,
          {
            headers: {
              Authorization: `Token ${sessionStorage.getItem("token")}`,
            },
          }
        );
        const sortedData = response.data.sort((a, b) => {
          const dateA = new Date(a.user_dateJoined);
          const dateB = new Date(b.user_dateJoined);
          return dateB - dateA;
        });

        setData(sortedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id]);

  const handleDateFilter = () => {
    const [startDate, endDate] = selectedDateRange;
    if (!startDate || !endDate) {
      return data; // Return all data if no date range is selected
    }

    const filteredData = data.filter((ele) => {
      const joinDate = new Date(ele.user_dateJoined);
      return startDate <= joinDate && joinDate <= endDate;
    });
    return filteredData;
  };

  const handleCalendarChange = (date) => {
    setSelectedDateRange(date);
  };

  const handleReset = () => {
    setSelectedDateRange([null, null]);
  };

  return (
    <div style={styles.card} className="lightgradient2">
      <div style={styles.inner}>
        <p>
          <strong>Client list </strong>
        </p>
        <div style={{ width: "260px" }} className="res-calendar">
          <Calendar
            className={["c1", "c2"]}
            onChange={handleCalendarChange}
            value={selectedDateRange}
            selectRange
          />
          <div />
          <button className="pinkbutton" onClick={handleDateFilter}>
            Filter
          </button>
          <button className="pinkbutton" onClick={handleReset}>
            Reset
          </button>
        </div>
        <hr style={{ color: "#fff" }} />
        <table style={{ borderSpacing: "0px 1em" }}>
          <thead>
            <tr>
              <td>Name</td>
              <td>Email</td>
              <td>Membership Name</td>
              <td>Joined Date</td>
            </tr>
          </thead>
          <tbody>
            {data &&
              handleDateFilter().map((ele) => (
                <tr key={ele.id} className="trfortable">
                  <td onClick={() => this.handleClick(ele.id)}>
                    {ele.user_first_name} {ele.user_last_name}
                  </td>
                  <td>{ele.user_email}</td>
                  <td>
                    {ele &&
                      ele.purchased_membership[0]?.membership?.membership_name}
                  </td>
                  <td>{formatDateString(ele.user_dateJoined)}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

const styles = {
  card: {
    // display: "flex",
    justifyContent: "space-between",
    // background: "#4c003d",
    padding: "20px",
    borderRadius: "10px",
    margin: "10px",
  },
  inner: {
    textAlign: "-webkit-center",
    textDecoration: "none",
    color: "#fff",
  },
};
