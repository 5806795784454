import { useState } from "react";
import { Link } from "react-router-dom";
import Dietician from "../img/ditician.png";
import Psycologist from "../img/psycologist.png";
import Obstretician from "../img/obstretician.png";
import Paediatrician from "../img/paediatrician.png";
import Phychiathrist from "../img/phychiathrist.png";
import DoctorCarousel from "./doc_carousel";
import InnerSpecialities from "./inner_specialities";

export default function Specialities() {
  const data = [
    {
      id: "3",
      title: "obstretician",
      image: Obstretician,
      caption: "For children's health care",
    },

    {
      id: "4",
      title: "pediatrician",
      image: Paediatrician,
      caption: "For children's health care",
    },

    {
      id: "5",
      title: "psychiatrist",
      image: Phychiathrist,
      caption: "For children's health care",
    },
    {
      id: "1",
      title: "deitician",
      image: Dietician,
      caption: "For children's health care",
    },

    {
      id: "2",
      title: "Psychologist",
      image: Psycologist,
      caption: "For children's health care",
    },
  ];

  const [item, setItem] = useState(false);

  function openItem() {
    setItem(true);
  }

  return (
    <>
      {/* {item ? (
        <InnerSpecialities />
      ) : ( */}
      <div>
        {/* <DoctorCarousel /> */}
        <div style={styles.wrapper}>
          <h4>Top Specialities</h4>
          <hr style={styles.hr} />
          <div className="spflex spflexmob" style={styles.spflex}>
            {data.map((e) => (
              <Link
                to={"/cdoctorspost/" + e.title}
                onClick={openItem}
                className="eachitem"
              >
                <div id="item" style={styles.fitem} key={e.id}>
                  <h5 style={styles.title}>{e.title}</h5>
                  <img style={styles.img} src={e.image} alt="" />
                  <div style={styles.caption}>
                    <small>{e.caption}</small>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
      {/* )} */}
    </>
  );
}

const styles = {
  wrapper: {
    textAlign: "left",
    padding: "10px 20px",
    lineHeight: "10px",
  },

  spflex: {
    display: "flex",
    justifyContent: "space-between",
    zIndex: "3",
    position: "relative",
  },

  fitem: {
    textAlign: "center",
    padding: "10%",
    background:
      "0% 0% no-repeat padding-box padding-box rgba(255, 255, 255, 0.11)",
    // background: "#874282",
    borderRadius: "10px",
    height: "100px",
    width: "100px",
    cursor: "pointer",
  },
  hr: {
    display: "block",
    height: "1px",
    border: "0",
    borderTop: "1px solid grey",

    margin: "1em 0; padding: 0",
  },
  title: {
    fontSize: "12px",
    marginTop: "10px",
  },

  img: {
    marginTop: "-20px",
  },

  caption: {
    fontSize: "10px",
  },
};
