import axios from "axios";
import { useEffect, useState } from "react";
export default function PersonalDetail({ id }) {
  const [details, setDetails] = useState("");
  useEffect(() => {
    axios
      .get(`customer-profile/`, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
        params: {
          customer: id,
        },
      })
      .then((res) => {
        console.log(res);

        setDetails(res.data.details);
      });
  }, []);

  // destructure data from states

  let {
    marriedSince,
    babies_number,
    abortions,
    twins,
    diabetes,
    allergic_reaction,
    surgery,
    Menstruation,
    Menstruation_date,
    hereditory,
    no_of_babies_pregnant_with,
    doctor_final_visit,
    prescription,
  } = details;

  return (
    <table>
      <tr>
        <td></td>
        <td style={styles.co}>Married Since</td>
        <td className=" cpersonaldeatils ">
          <input style={styles.co1} type="text" value={marriedSince} />
        </td>
      </tr>
      <tr>
        <td></td>
        <td style={styles.co}>How many Baby She has?</td>
        <td className=" cpersonaldeatils ">
          <input style={styles.co1} type="text" value={babies_number} />
        </td>
      </tr>
      <tr>
        <td></td>
        <td style={styles.co}>Any History of Abortion?</td>
        <td className=" cpersonaldeatils ">
          <input style={styles.co1} type="text" value={abortions} />
        </td>
      </tr>
      <tr>
        <td></td>
        <td style={styles.co}>Any history of twins in family?</td>
        <td className=" cpersonaldeatils ">
          <input style={styles.co1} type="text" value={twins} />
        </td>
      </tr>
      <tr>
        <td></td>
        <td style={styles.co}>Any family history of Diabetes?</td>
        <td className=" cpersonaldeatils ">
          <input style={styles.co1} type="text" value={diabetes} />
        </td>
      </tr>
      <tr>
        <td></td>
        <td style={styles.co}>
          Any History of allergic reaction? If any specify.
        </td>
        <td className=" cpersonaldeatils ">
          <input style={styles.co1} type="text" value={allergic_reaction} />
        </td>
      </tr>
      <tr>
        <td></td>
        <td style={styles.co}>
          Any History of Surgery? If yes please Specify.
        </td>
        <td className=" cpersonaldeatils ">
          <input style={styles.co1} type="Apple" value={surgery} />
        </td>
      </tr>
      <tr>
        <td></td>
        <td style={styles.co}>How many days menstrual cycle?</td>
        <td className=" cpersonaldeatils ">
          <input style={styles.co1} type="text" value={Menstruation} />
        </td>
      </tr>
      <tr>
        <td></td>
        <td style={styles.co}>Last period Date</td>
        <td className=" cpersonaldeatils ">
          <input style={styles.co1} type="text" value={Menstruation_date} />
        </td>
      </tr>
      <tr>
        <td></td>
        <td style={styles.co}>Any Heridity Related Complications?</td>
        <td className=" cpersonaldeatils ">
          <input style={styles.co1} type="text" value={hereditory} />
        </td>
      </tr>
      <tr>
        <td></td>
        <td style={styles.co}>
          How many baby are you pregnent with currently?
        </td>
        <td className=" cpersonaldeatils ">
          <input
            style={styles.co1}
            type="text"
            value={no_of_babies_pregnant_with}
          />
        </td>
      </tr>
      <tr>
        <td></td>
        <td style={styles.co}>When did you visit doctor finally?</td>
        <td className=" cpersonaldeatils ">
          <input style={styles.co1} type="text" value={doctor_final_visit} />
        </td>
      </tr>
      <tr>
        <td></td>
        <td style={styles.co}>
          Upload latest prescription report or any blood report that clearly
          indicates that you are pregnent?
        </td>
        <td className=" cpersonaldeatils ">
          <input style={styles.co1} type="text" value={prescription} />
        </td>
      </tr>
    </table>
  );
}

const styles = {
  dprofile: {
    background: "rgb(76, 0, 61) none repeat scroll 0% 0%",
    width: "41.3rem",
    borderRadius: "10px",
    padding: "10px",
  },
  co1: {
    border: "none",
    color: "white",
    background: "0% 0% no-repeat padding-box padding-box rgb(255 255 255 / 0%)",
  },
  titlePlate: {
    padding: "10px",
    color: "#fff",
  },
  flex: {
    display: "flex",
    justifyContent: "space-around",
    padding: "10px",
  },
  col: {
    padding: "10px",
    textAlign: "left",
  },
  co: {
    padding: "10px",
    textAlign: "left",
    // border: "1px solid #85388E",
    // background: "#85388E",
  },
  money: {
    color: "#fff",
  },

  btn: {
    padding: "3px",
    background: "#85388E",
    border: "none",
  },

  fee: {
    padding: "5px",
    background: "transparent",
    outline: "1px solid #85388E",
  },

  ftext: {
    fontSize: "12px",
    padding: "5px",
    background: "#85388E",
    marginRight: "2rem",
  },
};
