import React from "react";
import Sidebar from "../components/sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faUser } from "@fortawesome/free-solid-svg-icons";
import Toggler from "../toggleprofile";
import Client from "../components/clients";
//import Doctor from './components/doctors';
import axios from "axios";
import Table from "../components/allclient_table";
import Dcstatus from "../components/dcstatus";
import Searchdoctor from "../components/search";
import Hospitals from "../components/hospitals";
import Hospital from "../components/hospital";
import Salesteams from "../components/salesteams";
import Consultants from "../components/consultants";

export default class Overview extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isToggleOn: true, items: [] };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState((prevState) => ({
      isToggleOn: !prevState.isToggleOn,
    }));
  }
  componentDidMount() {
    if (sessionStorage.getItem("hospitalManager") === null) {
      sessionStorage.clear();
      window.location = "/clogin";
    }
    const config = {
      headers: {
        Authorization: `token ` + sessionStorage.getItem("hospitalManager"),
      },
    };

    axios.get("login-user-data/", config).then((res) => {
      console.log(res.data);
      this.setState({ items: res.data });
      // this.setState({ details: res.data.details });
    });
  }
  render() {
    return (
      <div className="App">
        <Sidebar />
        <header className="overview">
          <p style={{ paddingRight: "10%", textAlign: "right" }}>
            {" "}
            <div>{this.state.isToggleOn ? null : <Toggler />}</div>
            <FontAwesomeIcon
              style={{ marginRight: "1rem", cursor: "pointer" }}
              icon={faBell}
            />
            <FontAwesomeIcon
              onClick={this.handleClick}
              style={{ cursor: "pointer" }}
              icon={faUser}
            />{" "}
            {this.state.items.firstname}
          </p>

          <div style={{ width: "89%", display: "flex", paddingLeft: "10px" }}>
            <span style={{ whiteSpace: "nowrap" }}>Overview</span>
            <div style={{ padding: "4px", width: "100%" }}>
              <hr style={{ background: "white", color: "white" }} />
            </div>
          </div>
          <div style={{ width: "89%" }}>
            <div className="mleft">
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <Client />
                <Hospital />
              </div>
              {/* <Searchdoctor /> */}
              <Table />
            </div>
          </div>
        </header>
      </div>
    );
  }
}
