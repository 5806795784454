import React from "react";
// import DailyTracker from "../../components/          /traker_by_date/table_list";
import Doctorprofile from "./doctorprofile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import Dcstatus from "./dcstatus";
import { faSortDown, faSort } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Dailytraker from "../../tracker/components/tabs";
import { useState } from "react";
export default function Doctor_tablenew({ clientDetails, searchval }) {
  const [users, setUsers] = useState([]);
  const [userId, setUserId] = useState();
  const [category, setCategory] = useState(null);
  const [showResults, setShowResults] = React.useState(true);
  const hideShow = () => setShowResults(false);
  const [search, setSearch] = useState(null);
  const [shedule, setShedule] = useState(false);
  const [client, setClient] = useState(false);
  const [selectedClient, setSelectedClient] = useState([]);
  const [traker, settraker] = useState(false);
  const [background, setBackground] = useState("red");
  const [defaultcolor, setdefaultcolor] = useState("red");
  const [menbers, setmenbers] = useState([]);
  //console.log(search);
  useEffect(() => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Token ${sessionStorage.getItem("token")}`,
      },
    };

    // Fetching clients list
    axios
      .get("admin-panel/admin-dashboard-details/", config)
      .then((res) => {
        console.log(res.data.clientDetails);
        getUsers(res.data.clientDetails);
      })
      .catch((err) => {
        console.log(err.message);
      });

    // Fetching membership plans
    axios
      .get("/payments/get_memberships/")
      .then((res) => {
        console.log(res.data.data);
        setmenbers(res.data.data);
        // Handle the membership plans data here
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  // function handleBackground(e) {
  //   console.log("cfcvrr");
  //   console.log(e.target.value);
  //   setBackground(e.target.value);
  // }

  function openShedule() {
    setShedule(!shedule);
  }

  const updateStatus = (id) => {
    let membid = id;
    const Data = { id: membid };
    console.log(membid);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Token ${sessionStorage.getItem("token")}`,
      },
    };
    axios
      .post("activateOrDeactivate/", Data, config)
      .then((res) => {
        console.log(res.data);

        //console.log(res.data);0
      })
      .catch((err) => {
        console.log(err.message);
      });
    // .then((response) => response.json())
    // .then((res) => {
    //   alert(res.Success);
    // });
  };

  const handleBackgroundx = (userId, planId) => {
    // Make POST request to change client membership

    const data = {
      customer: userId,
      membershipId: planId,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${sessionStorage.getItem("token")}`,
      },
    };

    axios
      .post("/admin-panel/change-client-membership/", data, config)
      .then((response) => {
        alert("updated");
        // Handle response data or perform additional actions
      })
      .catch((error) => {
        console.error(error);
        // Handle error or display error message
      });
  };

  const handleBackground =
    (userId) =>
    ({ target }) => {
      let value = Number(target.value);
      // var x = Number("1000");
      let membid = Number(userId);
      // you can use userId and value here.
      const Data = { membershipId: value, customer: membid };

      alert(value, userId);

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Token ${sessionStorage.getItem("token")}`,
        },
      };

      axios
        .post("admin-panel/change-client-membership/", Data, config)
        .then((res) => {
          console.log(res.data);

          //console.log(res.data);0
        })
        .catch((err) => {
          console.log(err.message);
        });
    };
  function openClinetProfile(id) {
    setUserId(id);
    setClient(!client);
  }
  function openDailytraker(id) {
    setUserId(id);
    console.log("idis", id);
    settraker(!traker);
  }
  const getUsers = (userList) => {
    setUsers(userList);
  };

  const byCategory = (user, category) => {
    console.log(user.week);
    if (category === "0-4") {
      var lowerweek = 0;
      var upperweek = 4;
      return user.week <= upperweek;
    } else if (category === "4-8") {
      var lowerweek = 4;
      var upperweek = 8;
      return user.week <= upperweek && user.week >= lowerweek;
    } else if (category === "8-12") {
      var lowerweek = 8;
      var upperweek = 12;
      return user.week <= upperweek && user.week >= lowerweek;
    } else if (category === "12-16") {
      var lowerweek = 12;
      var upperweek = 16;
      return user.week <= upperweek && user.week >= lowerweek;
    } else if (category === "16-20") {
      var lowerweek = 16;
      var upperweek = 20;
      return user.week <= upperweek && user.week >= lowerweek;
    } else if (category === "20-24") {
      var lowerweek = 20;
      var upperweek = 24;
      return user.week <= upperweek && user.week >= lowerweek;
    } else if (category === "24-28") {
      var lowerweek = 24;
      let upperweek = 28;
      return user.week <= upperweek && user.week >= lowerweek;
    } else if (category === "28-32") {
      var lowerweek = 4;
      var upperweek = 8;
      return user.week <= upperweek && user.week >= lowerweek;
    } else if (category === "32-36") {
      var lowerweek = 32;
      var upperweek = 36;
      return user.week <= upperweek && user.week >= lowerweek;
    } else if (category === "36-40") {
      var lowerweek = 36;
      var upperweek = 40;
      console.log(category);
      return user.week <= upperweek && user.week >= lowerweek;
    } else if (category === "40+") {
      var lowerweek = 40;
      var upperweek = 40;
      console.log(category);
      return user.week > upperweek;
    } else return user;
  };

  const bySearch = (user, search) => {
    if (search) {
      // console.log(search);
      if (user && user.firstname) {
        return user.firstname.toLowerCase().includes(search.toLowerCase());
      }
    } else return user;
  };
  const filteredList = (users, search) => {
    return users
      .filter((user) => byCategory(user, category))
      .filter((user) => bySearch(user, search));
  };
  return (
    <>
      {traker ? <Dailytraker userId={userId} /> : null}
      <div className="note-container lightgradient2" style={styles.card}>
        <div className="search-form" style={styles.inner}>
          <p style={{ textAlign: "left" }}>Search Clients</p>
          <form style={{ display: "flex" }}>
            {showResults ? (
              <FontAwesomeIcon
                style={{ marginRight: "-22px" }}
                icon={faSearch}
              />
            ) : null}

            <input
              onFocus={hideShow}
              // onChange={findDoc}
              onChange={(e) => setSearch(e.target.value)}
              style={styles.input}
              type="Search"
            />
          </form>
          {/* <div style={{ textAlign: "left" }}>{hunt ? hunt.firstname : ""}</div> */}
        </div>
      </div>

      <div className="  lightgradient2 weekerwidth" style={styles.card}>
        <span style={{ fontSize: "12px" }}>
          {" "}
          <FontAwesomeIcon icon={faSort} /> Sort By:{" "}
          <select
            // style={{}}
            className="docoptions"
            style={styles.option}
            onChange={(e) => setCategory(e.target.value)}
          >
            <option>week</option>
            <option value="0-4">0-4</option>
            <option value="4-8">4-8</option>
            <option value="8-12">8-12</option>
            <option value="12-16">12-16</option>
            <option value="16-20">16-20</option>
            <option value="20-24">20-24</option>
            <option value="24-28"> 24-28</option>
            <option value="28-32">28-32</option>
            <option value="32-36">32-36</option>
            <option value="36-40">36-40</option>
            <option value="40+">40+ </option>
            <FontAwesomeIcon icon={faSortDown} />{" "}
          </select>
        </span>
      </div>
      <div
        className="all-clients-table absulte lightgradient2"
        style={styles.card}
      >
        <div style={styles.inner}>
          <p>
            <strong>All Clients subscription status</strong>
          </p>
          <hr style={{ color: "#fff" }} />
          <div className="table-cover tablecover2">
            <table style={{ width: "100%" }}>
              <thead>
                <tr>
                  <td>Name</td>
                  <td>Age</td>
                  <td>Location</td>
                  <td>Due date</td>
                  <td>Doctor</td>
                  <td>Daily tracker</td>
                  <td>Account Status</td>
                  <td>Subscription</td>
                </tr>
              </thead>
              <tbody>
                {/* {clientDetails &&
                  clientDetails
                    .filter((user) => {
                      if (searchval) {
                        // console.log(search);
                        if (user && user.firstname) {
                          return `${user.firstname} ${user.lastname}`
                            .toLowerCase()
                            .includes(searchval.toLowerCase());
                        }
                      } else return user;
                    })
                    .map((user) => { */}
                {console.log(users)}
                {filteredList(users, search).map((user) => (
                  // return (

                  <tr>
                    <td>
                      <Link
                        to={`/admin/client/${user.id}`}
                        // style={styles.tolink}
                      >
                        {user.firstname} {user.lastname}
                      </Link>
                    </td>
                    <td>{user.age}</td>
                    <td>{user.location}</td>
                    <td>{user.dueDate}</td>
                    <td>
                      {user.doctor_firstname} {user.doctor_lastname}
                    </td>
                    <td>
                      <button
                        onClick={() => openDailytraker(user.id)}
                        style={styles.sbtn}
                      >
                        Detail
                      </button>
                      {/* <Link to={`/admin/user_tracker/${ele.id}`}>
                              <button>Details</button>
                            </Link> */}
                    </td>
                    <td>
                      <select
                        // onChange={() => handleColor()}
                        style={{
                          background: "#00acc8",
                          padding: "4px 20px",
                          borderRadius: "5px",
                          color: "white",
                          // border: "1px solid #00acc8",
                        }}
                        onChange={() => updateStatus(user.id)}
                      >
                        {user.is_active == true ? (
                          <>
                            <option>Active</option> <option>Disabled</option>
                          </>
                        ) : (
                          <>
                            <option>Disabled</option>
                            <option>Active</option>
                          </>
                        )}
                      </select>
                    </td>
                    <td>
                      <select
                        defaultValue={user.subscription}
                        style={{
                          background: defaultcolor,
                          color: "white",
                          textTransform: "capitalize",
                        }}
                        onChange={(e) =>
                          handleBackgroundx(user.id, parseInt(e.target.value))
                        }
                      >
                        <option value={user.subscription} default>
                          {user.subscription}
                        </option>
                        {menbers.map((plan) => (
                          <option key={plan.id} value={plan.id}>
                            {plan.membership_name}
                          </option>
                        ))}
                      </select>
                    </td>
                  </tr>
                  // );
                ))}
              </tbody>
            </table>
          </div>
          {/* {this.state.isDialog ? null : <Doctorprofile />} */}
        </div>
      </div>
    </>
  );
}

const styles = {
  inner: {
    textDecoration: "none",
    color: "#fff",
  },

  input: {
    width: "40rem",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderBottomColor: "1px solid #fff",
    background: "transparent",
    padding: "8px",
  },
  option: {
    background: "transparent",
    border: "none",
    // appearance: "none",
    color: "#fff",
  },

  orange: {
    background: "#fc6100",
    border: "1px solid #fc6100",
    padding: "8% 30%",
  },
  pink: { background: "green", border: "1px solid green", padding: "8% 30%" },
  yellow: {
    background: "#e1ad01",
    border: "1px solid #e1ad01",
    padding: "8% 30%",
  },
  blue: {
    background: "#00acc8",
    border: "1px solid #00acc8",
    padding: "8% 30%",
  },
  card: {
    display: "flex",
    justifyContent: "space-between",
    // backdropFilter: "blur(31px) brightness(-15%) opacity(.3)",
    padding: "20px",
    borderRadius: "10px",
    margin: "10px",
    width: "85.5%",
  },
  inner: {
    textDecoration: "none",
    color: "#fff",
    width: "100%",
  },
};
