import React from "react";
import { withRouter } from "react-router";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Activitytracker from "./tabs_com/activity_tracker";
import Contractioncounter from "./tabs_com/contraction_records";
import Dietracker from "./tabs_com/diet_traker";
import Exercisetracker from "./tabs_com/exercise_tracker";
import Medicaldetail from "./tabs_com/medical_dashboard";
import Medicinetracker from "./tabs_com/medicine_tracker";
import Symptomstracker from "./tabs_com/symptom_tracker";
const queryString = require("query-string");

class Clist extends React.Component {
  constructor(props) {
    super(props);

    this.tabs = {
      diet: 0,
      medicine: 1,
      exercise: 2,
      symptoms: 3,
      activity: 4,
      contraction: 5,
      medical: 6,
    };

    this.state = { isClose: true };
    this.closeTabBox = this.closeTabBox.bind(this);
  }

  closeTabBox() {
    this.setState({ isClose: false });
  }

  render() {
    const { location } = this.props;

    const query = queryString.parse(location.search);

    console.log("query", query);

    return (
      <>
        {this.state.isClose ? (
          <div
            style={styles.card}
            className="lightgradient2 tarakerupdatesales"
          >
            {/* <i
              onClick={this.closeTabBox}
              className="fa fa-close"
              style={styles.closebtn}
            ></i> */}
            <div style={styles.inner}>
              <Tabs defaultIndex={this.tabs[query.tab]}>
                <TabList
                  style={{
                    width: "100%",
                    paddingLeft: "0px",
                    listStyle: "none",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Tab>
                    <strong style={styles.tabnav}>Diet Tracker</strong>
                  </Tab>
                  <Tab>
                    <strong style={styles.tabnav}>Medicine Tracker</strong>
                  </Tab>
                  <Tab>
                    <strong style={styles.tabnav}>Exercise Tracker</strong>
                  </Tab>
                  <Tab>
                    <strong style={styles.tabnav}>Symptoms Tracker</strong>
                  </Tab>
                  <Tab>
                    <strong style={styles.tabnav}>Activity Tracker</strong>
                  </Tab>
                  {/* <Tab>
                    <strong style={styles.tabnav}>Contraction Counter</strong>
                  </Tab> */}
                  <Tab>
                    <strong style={styles.tabnav}>Medical Details</strong>
                  </Tab>
                </TabList>
                <TabPanel>
                  <Dietracker date={query.date} customer={query.customer} />
                </TabPanel>
                <TabPanel>
                  <Medicinetracker
                    date={query.date}
                    customer={query.customer}
                  />
                </TabPanel>
                <TabPanel>
                  <Exercisetracker
                    date={query.date}
                    customer={query.customer}
                  />
                </TabPanel>
                <TabPanel>
                  <Symptomstracker
                    date={query.date}
                    customer={query.customer}
                  />
                </TabPanel>
                <TabPanel>
                  <Activitytracker
                    date={query.date}
                    customer={query.customer}
                  />
                </TabPanel>
                {/* <TabPanel>
                  <Contractioncounter date={query.date} customer={query.customer} />
                </TabPanel> */}
                <TabPanel>
                  <Medicaldetail date={query.date} customer={query.customer} />
                </TabPanel>
              </Tabs>
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

const styles = {
  card: {
    display: "flex",
    justifyContent: "space-between",
    // background: "#4c003d",
    // padding: "20px",
    borderRadius: "10px",
    margin: "10px",
    position: "absolute",
    // transform: "translate(-10rem, -6rem)",
    zIndex: 10,
    overflow: "overlay",
    height: "75%",
    // width: "70%",
  },

  inner: {
    textDecoration: "none",
    color: "#fff",
    width: "100%",
  },

  tabnav: {
    cursor: "pointer",
    fontSize: "14.3px",
    whiteSpace: "nowrap",
    padding: "20px 10px",
  },

  closebtn: {
    position: "absolute",
    right: "0px",
    top: "-20px",
    cursor: "pointer",
  },
};

export default withRouter(Clist);
