

export default function ActivityTracker(){

return (

<table style={{ width:'100%', borderCollapse:'collapse' }}>
<thead style={{ borderBottom:'5px solid #5c0048' }}>
<tr style={{ background:'#a23698' }}>
<th style={ styles.th} >Date</th>
<th style={ styles.th} >Affirmation</th>
<th style={ styles.th} >Meditation</th>
<th style={ styles.th} >Breathing Techniques</th>
<th style={ styles.th} >Bonding</th>
<th style={ styles.th} >Activities</th>
</tr>
</thead>

<tbody>
<tr style={{ background:'#a23698' }}>
<td style={styles.tdFirst}>02/08/2021</td>
<td style={styles.td}>I am healthy <br/> I am happy <br/>
I give birth at comfort and at ease <br/>
</td>
<td style={styles.td}>Meditation</td>
<td style={styles.td}>Easy Stage <br/> Transition</td>
<td style={styles.td}>Talking to the baby <br/> Food names</td>
<td style={styles.td}> Story reading <br/> Walking </td>

</tr>


</tbody>


</table>


);

}


const styles = {

th:{
fontSize:'17px',
whiteSpace:'nowrap',
padding:'5px 10px',
margin:'10px 0px',
},

td:{
borderLeft:'1px solid #fff',
padding:'5px 10px',
fontSize:'12px',
textAlign:'left',
lineHeight:'20px'
},

tdFirst:{
padding:'5px 10px',
fontSize:'12px'
},



}






