import React from "react";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import qs from "qs";

export default class LoginForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isUser: false,
      username: "",
      password: "",
      items: [],
      token: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.usernameChange = this.usernameChange.bind(this);
    this.passwordChange = this.passwordChange.bind(this);
  }

  handleSubmit(e) {
    var data = {
      email: this.state.username,
      password: this.state.password,
    };

    axios({
      method: "POST",
      url: "login/",
      // url: " https://sukhprasavam.shebirth.com/login/",

      headers: {
        "content-type": "application/x-www-form-urlencoded",
        "api-key": "wvX2ssin.THCtLxyXLXvBw7Blx0nfG4C2x9RUIHRS",
      },
      data: qs.stringify(data),
    }).then(function (response) {
      // localStorage.setItem('LoginToken', response.data.token);
      //const status = response;
      console.log(response.data);

      //redirect logic
      if (response.data.doctor == true) {
        //this.setState({ error: 0 });
        localStorage.setItem("doc_token", response.data.token);
        localStorage.setItem("doc_id", response.data["doctor Id"]);
        window.location = "/doctor";
        this.setState({ token: response.data.token });
      } else {
        //this.setState({ items: response.data });
        alert("User with the email not found!");

        //console.log("this is error");
      }
    });

    //   .then((res) => {
    //     localStorage.setItem("LoginToken", res.data.token);
    //   });
    /*.catch(error=>{console.log(error)});*/

    e.preventDefault();
  }

  usernameChange(e) {
    this.setState({ username: e.target.value });
  }

  passwordChange(e) {
    this.setState({ password: e.target.value });
  }

  render() {
    return (
      <>
        {this.state.token ? (
          <Redirect to="/doctor" />
        ) : (
          <div
            className="form-wrapper"
            style={{
              display: "flex",
              flexDirection: "column",
              color: "#fff",
              padding: "20px",
              width: "40%",
              borderRadius: "20px",
              boxShadow: "12px 10px 60px #00000029",
              backdropFilter: "blur(39px)",
              WebkitBackdropFilter: "blur(39px)",
              background: "#00000029",
            }}
          >
            <h3>Lets Get Started</h3>
            {this.state.error == true ? <h1>hello</h1> : <h1></h1>}
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                lineHeight: "70px",
              }}
              onSubmit={this.handleSubmit}
            >
              <input
                value={this.state.username}
                onChange={this.usernameChange}
                style={styles.input}
                type="text"
                placeholder="Username"
              />
              <input
                value={this.state.password}
                onChange={this.passwordChange}
                style={styles.input}
                type="password"
                placeholder="Password"
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <small style={{ display: "flex", paddingTop: "2px" }}>
                    <input type="checkbox" />
                    <span>Remember Me</span>
                  </small>
                </div>
                <div>
                  <Link to="/forget">
                    <small>Forgot Password</small>
                  </Link>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <input
                  style={{
                    background:
                      "transparent linear-gradient(266deg, #FF006C 0%, #293247 100%) 0% 0% no-repeat padding-box",
                    width: "102px",
                    height: "32px",
                    border: "none",
                    color: "#fff",
                    boxShadow: "0px 9px 10px #00000029",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                  type="submit"
                  value="Login"
                />
                <button
                  style={{
                    background: "none",
                    border: "1px solid #fff",
                    color: "#fff",
                    width: "102px",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                >
                  <Link to="/doc_signup">Signup</Link>
                </button>
              </div>
            </form>
          </div>
        )}
      </>
    );
  }
}

const styles = {
  input: {
    background: "none",
    padding: "10px",
    color: "#fff",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    outline: "none",
  },
};
