import PersonPhoto from "../img/doctor.png";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useParams } from "react-router-dom";

import React, { useState, useEffect } from "react";
import InnerDetail from "./booking";
export default function Detail(props) {
  const location = useLocation();
  const [detail, setDetail] = useState(false);
  // const data3 = location.state.data;
  //const doctor_id = data3.id;
  const inipath = window.location.pathname;
  const path = inipath.split("/login");
  // const path2 = path.split("/cdoctors/");
  const [data3, setdata3] = useState([]);

  const { id } = useParams();
  // var idvalue = path[3];
  if (id % 1 == 0) {
    var idvalue = id;
  } else {
    var idvalue = path[3];
  }
  console.log(id);
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `token ` + localStorage.getItem("token"),
      },
    };
    //remove this date after setting up the admin pannel

    axios
      .get(
        "doctor/doctor-filter/?id=" + idvalue,
        config

        // config
      )
      .then((res) => {
        console.log(res.data);
        setdata3(res.data[0]);
      });
  }, []);

  //console.log(data3);
  function OpenInner() {
    setDetail(true);
  }
  return (
    <>
      {/* {detail ? (
        <InnerDetail />
      ) : (
        <> */}
      <h4 style={{ textAlign: "left", marginLeft: "10px" }}>
        Top Specialities <i className="fa fa-angle-right"></i> /doctor/Details
      </h4>
      <hr
        style={{
          margin: "30px 10px",
          background: "#fff",
          height: "1px",
          border: "none",
        }}
      />
      <div style={styles.wrapper} className="clientscroll">
        {/* begin header */}
        <div className="headerbooking" style={styles.pheader}>
          <div>
            <div className="innerheaderbooking" style={styles.pname}>
              <strong style={styles.namealigner}>
                Dr {data3.firstname} {data3.lastname}
              </strong>
              {/* <br className="breaker" />
              <strong style={styles.namealigner2}> {data3.lastname}</strong> */}
              {detail ? <InnerDetail dataFromParent={data3} /> : null}
              <button onClick={OpenInner} style={styles.abtn}>
                Book Appointment
              </button>
            </div>
            <hr style={styles.hr} />
            <div style={{ textAlign: "left", fontSize: "12px" }}>
              <span>
                {" "}
                {data3.speciality} | {data3.experience} years of Experience
              </span>{" "}
              <br />
              <span> &#x20b9;{data3.price}</span>
            </div>
            <hr style={styles.hr} />
          </div>

          <div>
            {data3.profile_full_url !=
            "https://sukhprasavam.shebirth.com/media//ProfilePic/default.jpg" ? (
              <img
                style={{ height: "100px" }}
                // className="avatar"
                src={data3.profile_full_url}
                alt=""
              />
            ) : data3.profile_full_url ==
              "https://sukhprasavam.shebirth.com/media//ProfilePic/default.jpg" ? (
              <button
                className="avatar aquamarine "
                style={{ maxWidth: "65px", border: "none" }}
              ></button>
            ) : (
              <button
                className="avatar aquamarine "
                style={{ maxWidth: "65px", border: "none" }}
              ></button>
            )}
            {/* {data3.profile_full_url ? (
              <img
                style={{ height: "100px" }}
                src={data3.profile_full_url}
                alt=""
              />
            ) : (
              <img style={{ height: "100px" }} src={PersonPhoto} alt="" />
            )} */}
            {/* <img style={{ height: "100px" }} src={PersonPhoto} alt="" /> */}
          </div>
        </div>
        {/* end header */}

        {/* begin detail  */}
        <div style={styles.iflex}>
          <div style={styles.innerflex}>
            <i className="fa fa-graduation-cap"></i>
            <strong> Education</strong>
            <br />
            <small> {data3.qualification}</small>
          </div>

          <div style={styles.innerflex}>
            <i className="fa fa-map-marker"></i>
            <strong> Location</strong>
            <br />
            <small>{data3.location}</small>
          </div>
        </div>
        <div style={styles.iflex}>
          <div style={styles.innerflex}>
            <i className="fa fa-user"></i>
            <strong> Registration Number</strong>
            <br />
            <small>{data3.councilRegNo}</small>
          </div>

          <div style={styles.innerflex}>
            <i className="fa fa-globe"></i>
            <strong> Languages</strong>
            <br />
            <small>English Malayalam</small>
          </div>
        </div>
        {/* end detail */}

        {/* <div style={{ textAlign: "left" }}>
          <strong>Timings</strong>
          <br />
          <div style={{ marginLeft: "8rem" }}>
            <small>Monday</small>
            <br />
            <small>No Slots Available</small>
            <br />
            <small>Tuesday</small>
            <br />
            <small>09:00AM - 01:00PM</small>
            <br />
            <small>Thrusday</small>
            <br />
            <small>9:00AM - 01:00PM</small>
            <br />
            <small>Friday</small>
            <small>09:00AM - 01:00PM</small>
            <br />
            <small>Saturday</small>
            <br />
            <small>No slots Available</small>
            <br />
            <small>Sunday</small>
            <br />
            <small>No slots Available</small>
          </div>
        </div> */}
      </div>
    </>
    // )}

    // </>
  );
}

const styles = {
  wrapper: {
    //  background: "#7F3286",
    background: "#ffffff1c 0% 0% no-repeat padding-box",
    margin: "10px",
    padding: "10px",
    // borderRadius: "10px",
  },

  tophead: {
    width: "30rem",
    display: "flex",
    justifyContent: "space-between",
  },

  pheader: {
    width: "30rem",
    display: "grid",
    gridGap: "30px",
    /* justify-content: space-between; */
    gridTemplateColumns: "1fr 1fr",
  },
  namealigner2: { display: "none" },

  pname: {
    width: "30rem",
    display: "grid",

    /* justify-content: space-between; */
    gridTemplateColumns: "4fr 3fr",
  },
  namealigner: {
    textAlign: "left",
    paddingLeft: "5%",
  },
  hr: {
    height: "1px",
    border: "none",
    background: "#fff",
  },

  iflex: {
    display: "flex",
    justifyContent: "space-between",
    textAlign: "left",
    width: "80%",
  },

  innerflex: {
    padding: "10px",
    height: "70px",
    width: "260px",
    fontSize: "16px",
  },

  abtn: {
    background: "#E81A7F",
    textTransform: "uppercase",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    padding: "10px",
    cursor: "pointer",
  },
};
